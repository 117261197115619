import fetch from 'isomorphic-unfetch';

// todo: USE E.G AXIOS FOR TIMEOUT!!!
const fetchData = (url, cookies) => {
  if (url.includes('/logincompleted')) {
    return Promise.resolve();
  }

  if (url.includes('/assets/') || url.includes('/plate/plate/')) {
    // eslint-disable-next-line no-console
    console.trace('api.js. Got assets url:', url);
  }

  if (
    url.includes('/cook/cook/') ||
    url.includes('/plate/plate/') ||
    url.includes('/cook/plate/') ||
    url.includes('/plate/cook/')
  ) {
    // eslint-disable-next-line no-console
    console.trace('Cycle detected', url);
    throw new Error(`Cycle detected: ${url}`);
  }

  if (url.includes('plateEnv=') && !url.includes('/cook')) {
    const tempUrl = new URL(url);
    const plateEnv = tempUrl.searchParams.get('plateEnv');

    if (plateEnv === 'prod') {
      tempUrl.hostname = 'www.svensktnaringsliv.se';
    } else if (plateEnv === 'stage') {
      tempUrl.hostname = 'stage-www.svensktnaringsliv.net';
    } else if (plateEnv === 'test') {
      tempUrl.hostname = 'test-www.svensktnaringsliv.net';
    } else if (plateEnv === 'localhost') {
      tempUrl.protocol = 'http://';
      tempUrl.hostname = 'localhost';
      tempUrl.port = '7071';
      tempUrl.pathname = tempUrl.pathname.replace('/plate/', '/api/plate/sn/');
    }
    tempUrl.searchParams.delete('plateEnv');
    // eslint-disable-next-line no-param-reassign
    url = tempUrl.href;
  }

  const headers = cookies ? { Cookie: `${cookies}` } : {};

  return fetch(url, {
    credentials: 'include',
    headers,
  })
    .then(response => {
      if (response.redirected) {
        const location = response.url.replace('/cook', '').replace('/plate', '');

        if (typeof window !== 'undefined') {
          window.location.replace(location);
        }
        return { redirected: true, follow: location };
      }
      if (response.status >= 400) {
        if (response.status !== 404) {
          console.error(`Could not fetch url: ${url}. Status:  ${response.status}`);
        }
        if (response.status === 403) {
          return response.json().then(response403 => {
            return { status: 'error', code: response.status, body: response403 };
          });
        }
        return { status: 'error', code: response.status };
      }
      return response.json();
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export { fetchData };
