// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MyPage-bsHeightFix-1yrE9,.MyPage-container-SOMia{height:100%}.MyPage-container-SOMia{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin-left:-34px;margin-top:-30px;padding-bottom:10px;width:calc(100% + 68px)}.MyPage-container-SOMia.MyPage-reverse-2xWiX{-ms-flex-direction:column-reverse;flex-direction:column-reverse}.MyPage-container-SOMia .MyPage-content-33gvn{padding:30px}@media (min-width:541px){.MyPage-container-SOMia{margin-left:-22px;width:calc(100% + 44px)}}@media (min-width:820px){.MyPage-container-SOMia{margin-left:0;padding-inline:5px;width:auto}.MyPage-container-SOMia,.MyPage-container-SOMia.MyPage-reverse-2xWiX{-ms-flex-direction:row;flex-direction:row}.MyPage-container-SOMia .MyPage-content-33gvn{margin-left:10px;max-width:698px;padding-top:0;padding-inline:20px;width:100%}}@media (min-width:1169px){.MyPage-container-SOMia{margin-left:0;padding-inline:56px;width:auto}.MyPage-container-SOMia,.MyPage-container-SOMia.MyPage-reverse-2xWiX{-ms-flex-direction:row;flex-direction:row}.MyPage-container-SOMia .MyPage-content-33gvn{margin-left:10px;padding-top:0;padding-inline:20px;width:100%}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"bsHeightFix": "MyPage-bsHeightFix-1yrE9",
	"container": "MyPage-container-SOMia",
	"reverse": "MyPage-reverse-2xWiX",
	"content": "MyPage-content-33gvn"
};
module.exports = exports;
