import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './MatrixListWithTextOnImage.css';
import Puff from '../../molecules/Puff';

const MatrixListWithTextOnImage = ({ articles }) => {
  return articles?.length > 0 ? (
    <div className={s.grid}>
      {articles.map(article => {
        return (
          <div className={s.item} key={article.id}>
            <Puff
              href={article.href}
              imageSrc={article.imageSrc}
              imageAlt={article.imageAlt}
              title={article.title}
              caption={article.caption}
            />
          </div>
        );
      })}
    </div>
  ) : null;
};

export default withStyles(s)(MatrixListWithTextOnImage);
