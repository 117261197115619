import React from 'react';
import history from 'utils/history';
import pushToDataLayer from 'utils/pushToDataLayer';
import { isExternalUrl } from 'utils/urlUtils';
import useGlobalState from '../../../zustand/zustand';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class Link extends React.Component {
  handleClick = event => {
    const { to, onClick, target, navigate, label } = this.props;
    if (onClick) {
      onClick(event);
    }

    if (isExternalUrl(to)) {
      pushToDataLayer('externalLinkClick', {
        linkSourcePageType: label,
        externalLinkUrl: `${event.target.innerText || event.target.dataset?.icon} [${to}]`,
      });
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    // If internal link, do internal SPA navigation
    if (
      target !== '_blank' &&
      !event.defaultPrevented &&
      to.indexOf('http://') === -1 &&
      to.indexOf('https://') === -1 &&
      to.indexOf('tel:') === -1 &&
      to.indexOf('.html/') === -1 &&
      !to.startsWith('#')
    ) {
      event.preventDefault(); // prevent full page reload
      // const scrollPosition = typeof window !== 'undefined' ? window.scrollY : undefined;
      history.push(to, { scrollPosition: 0 }); // do SPA navigation
      navigate(to);
    }

    // Scroll to anchor links.
    // This is normally handled by the browser but since our redux sagas controlls fetching of cook
    // and scrolling on the page when the location changes we need to hijack the browser behaviour and do this manually.
    if (to.startsWith('#')) {
      event.preventDefault();
      history.push(to);

      const headerHeight = document.querySelector('#app-header').offsetHeight;
      const isMenuOpen = useGlobalState.getState()?.pageHeader?.isFloating;

      const elem = document.querySelector(to);
      const yOffset = isMenuOpen ? -headerHeight : -10;
      const y = (elem?.getBoundingClientRect()?.top || 0) + window.pageYOffset + yOffset;

      if (y) {
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  render() {
    const { to, children, navigate, label, target: originalTarget, ...props } = this.props;
    const target = !originalTarget && isExternalUrl(to) ? '_blank' : originalTarget;

    return (
      <a
        href={to}
        target={target}
        {...props}
        onClick={this.handleClick}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      >
        {children}
      </a>
    );
  }
}

export default Link;
