// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StandardSectionPage-standardSectionPageContainer-2K4Fc .StandardSectionPage-stickyContainer-3THAR{position:relative}@media screen and (min-width:541px){.StandardSectionPage-standardSectionPageContainer-2K4Fc .StandardSectionPage-stickyContainer-3THAR{min-height:21rem}}.StandardSectionPage-alsoReadWrapper-GX7n2{background:#eff2f5;padding-bottom:24px;padding-top:40px}.StandardSectionPage-secondaryWrapper-3hT6e{padding-left:0;padding-right:0}@media (min-width:541px){.StandardSectionPage-secondaryWrapper-3hT6e{padding-left:56px;padding-right:56px}}@media (min-width:820px){.StandardSectionPage-secondaryWrapper-3hT6e{padding-left:0;padding-right:0}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"standardSectionPageContainer": "StandardSectionPage-standardSectionPageContainer-2K4Fc",
	"stickyContainer": "StandardSectionPage-stickyContainer-3THAR",
	"alsoReadWrapper": "StandardSectionPage-alsoReadWrapper-GX7n2",
	"secondaryWrapper": "StandardSectionPage-secondaryWrapper-3hT6e"
};
module.exports = exports;
