// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextArea-textArea-C78e7{background-color:#fff;border:1px solid #cfd4d8;color:#62717f;font-family:LabGrotesque-Regular,sans-serif;font-size:1rem;line-height:1.5rem;padding:13px 16px;width:100%}.TextArea-textArea-C78e7::-webkit-inner-spin-button{-webkit-appearance:none;appearance:none}.TextArea-error-3HaJ1 .TextArea-textArea-C78e7{border:1px solid #b12f26;border-bottom:0}.TextArea-errorMessage-1dxHR{background-color:#b12f26;border-bottom-left-radius:2px;border-bottom-right-radius:2px;color:#fff;font-family:LabGrotesque-Bold,sans-serif;font-size:.75rem;line-height:1.375rem;padding:8px 10px;text-transform:uppercase}.TextArea-infoCircle-l9ihk{font-size:14px;height:14px;margin-right:6px;width:auto}.TextArea-round-23yTO{border-radius:2px}.TextArea-textArea-C78e7.TextArea-gutterBottom-cCrHg{margin-bottom:1rem}.TextArea-textArea-C78e7:focus{border:1px solid #62717f;color:#33414e;outline:none}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"textArea": "TextArea-textArea-C78e7",
	"error": "TextArea-error-3HaJ1",
	"errorMessage": "TextArea-errorMessage-1dxHR",
	"infoCircle": "TextArea-infoCircle-l9ihk",
	"round": "TextArea-round-23yTO",
	"gutterBottom": "TextArea-gutterBottom-cCrHg"
};
module.exports = exports;
