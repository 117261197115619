/* eslint-disable prettier/prettier */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import Typography from '../../Typography';
import s from './QuoteArticleMain.css';

const QuoteArticleMain = ({ quote, name, workTitle, darkMode, classes, ...other }) => {
  const classNames = classnames(classes, s.quoteArticleMain);
  return (
    <div className={classNames} {...other}>
      <FontAwesomeIcon className={s.quoteArticleMainIcon} icon={faQuoteRight} />
      <div className={s.quoteArticleMainQuote}>
        <Typography variant="quoteArticleMain" center darkMode={darkMode}>
          {quote}
        </Typography>
      </div>
      <Typography variant="contentTypeDate" component="div" darkMode={darkMode}>
        {name}
        {workTitle && `, ${workTitle}`}
      </Typography>
    </div>
  );
};

export default withStyles(s)(QuoteArticleMain);
