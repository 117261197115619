// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormError-formError-IdzXQ{background-color:#f7eae9;border:1px solid #b12f26;border-radius:2px;padding:16px}.FormError-infoCircle-3AZjb{color:#b12f26;height:26px;margin-bottom:2px;margin-right:8px}.FormError-headline-d-x0V{-ms-flex-align:center;align-items:center;color:#b12f26;display:-ms-flexbox;display:flex;font-family:LabGrotesque-Bold,sans-serif;font-size:1.5rem;line-height:2.25rem;margin-bottom:12px}@media (min-width:541px){.FormError-formError-IdzXQ{padding:40px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"formError": "FormError-formError-IdzXQ",
	"infoCircle": "FormError-infoCircle-3AZjb",
	"headline": "FormError-headline-d-x0V"
};
module.exports = exports;
