import React from 'react';
import { Helmet } from 'react-helmet';

const TwitterTags = ({ card, site, title, url, description, image, imageWidth, imageHeight }) => (
  <Helmet>
    <meta property="twitter:card" content={card} />
    <meta property="twitter:site" content={site} />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:url" content={url} />
    {description && <meta property="twitter:description" content={description} />}
    {image && <meta property="twitter:image" content={image} />}
    {imageWidth && <meta property="twitter:image:width" content={imageWidth} />}
    {imageHeight && <meta property="twitter:image:height" content={imageHeight} />}
  </Helmet>
);

export default TwitterTags;
