import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, data => {
  try {
    return {
      orderable: pickFrom(data, 'context.fields.orderable'),
      webshopArticleNumber: pickFrom(data, 'context.fields.webshopArticleNumber'),
    };
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
