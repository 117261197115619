import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { COLOR_MAP } from '../../utils/constants';
import useCountDown from '../../../../../utils/hooks/useCountDown';
import Typography from '../../../../atoms/Typography';
import s from './CountDownBeam.css';

const CountDownBeam = ({ backgroundColor, countDownDate, showCountDown, text, textAppendix }) => {
  const { countdown } = useCountDown(new Date(countDownDate), {
    showShortSuffix: true,
  });

  return (
    <div
      className={s.countDownBeam}
      style={{
        backgroundColor: COLOR_MAP[backgroundColor]
          ? `rgb(${COLOR_MAP[backgroundColor]})`
          : COLOR_MAP.DEFAULT,
      }}
    >
      <Typography className={s.text} darkMode={backgroundColor === 'darkBlue' || backgroundColor === 'red'}>
        {text}{' '}
        {showCountDown && (
          <span>
            {countdown.days.value}
            {countdown.days.suffix} {countdown.hours.value}
            {countdown.hours.suffix} {countdown.minutes.value}
            {countdown.minutes.suffix} {countdown.seconds.value}
            {countdown.seconds.suffix}
          </span>
        )}
        {textAppendix && ` ${textAppendix}`}
      </Typography>
    </div>
  );
};

export default withStyles(s)(CountDownBeam);
