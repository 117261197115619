import {
  faFilePdf,
  faFilePowerpoint,
  faFileCsv,
  faFileDownload,
  faFileExcel,
  faFileSpreadsheet,
} from '@fortawesome/pro-light-svg-icons';

const getDocumentIcon = extension => {
  switch (extension.toLowerCase()) {
    case 'pdf':
      return faFilePdf;
    case 'ppt':
    case 'pptx':
      return faFilePowerpoint;
    case 'csv':
      return faFileCsv;
    case 'xlsx':
    case 'xlsm':
    case 'xltx':
    case 'xltm':
      return faFileExcel;
    case 'ods':
    case 'ots':
      return faFileSpreadsheet;
    default:
      return faFileDownload;
  }
};

export default getDocumentIcon;
