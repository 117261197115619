import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import DesktopExtraMenu from '../DesktopExtraMenu/DesktopExtraMenu';
import DesktopMainMenu from '../DesktopMainMenu/DesktopMainMenu';
import s from './DesktopHeader.css';

const DesktopHeader = () => {
  return (
    <div className={s.desktopHeader}>
      <div className={s.headerLogoWrapper}>
        <HeaderLogo className={s.headerLogo} />
      </div>
      <div className={s.menusWrapper}>
        <DesktopExtraMenu className={s.extraMenu} />
        <DesktopMainMenu className={s.mainMenu} />
      </div>
    </div>
  );
};

export default withStyles(s)(DesktopHeader);
