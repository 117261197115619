// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LiveEvent-liveEvent-2Ip_e{margin-bottom:2rem;margin-left:-1.5rem;margin-right:-1.5rem}@media (min-width:541px){.LiveEvent-liveEvent-2Ip_e{margin-left:0;margin-right:0}}.LiveEvent-liveEvent-2Ip_e .LiveEvent-videoWrapper-2-p5i{padding:56.25% 0 0;position:relative}.LiveEvent-liveEvent-2Ip_e .LiveEvent-videoWrapper-2-p5i iframe{height:100%;left:0;position:absolute;top:0;width:100%}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"liveEvent": "LiveEvent-liveEvent-2Ip_e",
	"videoWrapper": "LiveEvent-videoWrapper-2-p5i"
};
module.exports = exports;
