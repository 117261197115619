import React from 'react';
import Typography from 'components/atoms/Typography';
import pickFrom from 'utils/pickFrom';
import renderStorylineElement from 'utils/renderStorylineElement';
import htmlParser from 'utils/htmlParser';

const renderElement = (element, darkMode) => {
  // Create an id if there is none
  if (!element.id) {
    // eslint-disable-next-line no-param-reassign
    element.id = Math.random().toString(36).slice(2);
  }

  try {
    switch (element.type) {
      case 'headline':
        return (
          <Typography key={element.id} darkMode={darkMode} variant="h1">
            {htmlParser(pickFrom(element, 'fields.headline.html'))}
          </Typography>
        );
      case 'lead_text':
        return (
          <Typography key={element.id} darkMode={darkMode}>
            {htmlParser(pickFrom(element, 'fields.lead-text.html'))}
          </Typography>
        );
      default:
        return renderStorylineElement(element, darkMode);
    }
  } catch (error) {
    return undefined;
  }
};

export default renderElement;
