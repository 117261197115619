import React from 'react';
import classnames from 'classnames';
import Card from 'components/atoms/Card';
import VCard from 'components/molecules/VCard';
import Typography from 'components/atoms/Typography';
import getImages from 'utils/getImages';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SpeakerBox.css';

const Speakerbox = ({ classes, headline, speakers, ...other }) => {
  const classNames = classnames(s.speakerbox, classes);
  const sizes = getImages('SQUARE');
  return (
    <div className={classNames} {...other}>
      {headline && (
        <Typography variant="h2" forceVariant gutterBottom gutterTop>
          {headline}
        </Typography>
      )}
      <Card classes={s.eventCard}>
        {speakers?.length <= 0 && <Typography>Ej tillgängligt.</Typography>}
        {speakers?.length > 0 &&
          speakers.map(speaker => {
            return (
              <div key={speaker.id} className={s.eventItem}>
                <VCard
                  imageSrc={speaker.imageSrc !== '' ? sizes.toSrc(speaker.imageSrc, 'XS') : ''}
                  imageSrcSet={speaker.imageSrc !== '' ? sizes.toSrcSet(speaker.imageSrc, 'XS') : ''}
                  imageAlt={speaker.imageAlt}
                  name={speaker.name}
                  title={speaker.title}
                />
                <div className={s.eventItemSubItem}>
                  {speaker.description && (
                    <Typography variant="paragraphSmall">{speaker.description}</Typography>
                  )}
                </div>
              </div>
            );
          })}
      </Card>
    </div>
  );
};

export default withStyles(s)(Speakerbox);
