import React, { useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import Modal from 'components/organisms/Modal';
import CoworkerFiles from 'components/organisms/CoworkerFiles';
import Link from 'containers/atoms/LinkContainer';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import s from './ProfileCardWithModal.css';

const renderImage = (classes, component, src, srcSet, alt) => {
  return (
    <div className={classes}>
      {component ||
        (src && (
          <ResponsiveImage
            profileCard
            src={src}
            srcSet={srcSet}
            alt={alt}
            sizes="90px"
            width={66}
            height={66}
            lazyLoadWrapperHeight={66}
          />
        ))}
      {!component && !src && <FontAwesomeIcon icon={faUser} style={{ width: 27, height: 38 }} />}
    </div>
  );
};

const ProfileCardWithModal = ({
  classes,
  imageWrapperClasses,
  imageSrc,
  imageAlt,
  imageSrcSet,
  imageComponent,
  name,
  title,
  href,
  linkText,
  highResolutionImages,
  ...other
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const classNames = classnames(s.profileCardWithModal, classes);
  const imageWrapperClassNames = classnames(
    s.imageWrapper,
    imageWrapperClasses,
    !imageComponent && !imageSrc && s.noUser
  );
  return (
    <div className={classNames} {...other}>
      <Modal modalOpen={modalOpen} closeModal={() => setModalOpen(false)} label="Bilder och dokument">
        <CoworkerFiles highResolutionImages={highResolutionImages} />
      </Modal>
      {renderImage(imageWrapperClassNames, imageComponent, imageSrc, imageSrcSet, imageAlt)}
      <div>
        {name && !href && <div className={s.name}>{name}</div>}
        {name && href && (
          <div className={s.name}>
            <Link to={href} className={classnames(s.viewContacts, s.link)}>
              {name}
            </Link>
          </div>
        )}
        {title && <div className={s.title}>{title}</div>}
        {highResolutionImages && (
          <div>
            <Typography
              variant="linkSmall"
              component="button"
              className={s.viewContacts}
              onClick={() => setModalOpen(true)}
            >
              {linkText}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(s)(ProfileCardWithModal);
