/* eslint-disable dot-notation */
import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';

const IMAGE_KEY = 'BASE_LANDSCAPE';
const SIZES = {
  width: 1200,
  height: 628,
};

const extractImage = imageObj => {
  try {
    if (imageObj && imageObj.length > 0) {
      const image = imageObj[0];
      if (image.content && image.content.fields && image.content.fields[IMAGE_KEY]) {
        const imageData = image.content.fields[IMAGE_KEY];
        const { href_full: hrefFull } = imageData;
        if (hrefFull) {
          return {
            href: imageData.href_full?.replace(IMAGE_KEY, 'BASE_FACEBOOK'),
            ...SIZES,
          };
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, (data, hostname) => {
  try {
    const { context, microsite } = data;
    const { ogImage: ogImageObj, ogImage2: ogImage2Obj } = context;
    const micrositeOgImage = microsite !== null ? microsite?.ogImage : [];
    const defaultImage = {
      href: `${hostname}/fb-share-svensktnaringsliv.png`,
      width: 200,
      height: 200,
    };
    const image1 = extractImage(ogImageObj);
    const image2 = extractImage(ogImage2Obj);
    const micrositeImage = extractImage(micrositeOgImage);
    return image1 || image2 || micrositeImage || defaultImage;
  } catch (error) {
    return undefined;
  }
});
