// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ListArticles-showMoreEvents-SMFJp{margin:24px auto auto}.ListArticles-articleSmallText-30u6j{color:#62676f}.ListArticles-articleSmallText-30u6j,.ListArticles-articleSmallTextDarkMode-3qdkV{font-family:LabGrotesque-Bold,sans-serif;font-size:14px;line-height:22px;margin:auto;text-transform:uppercase}.ListArticles-articleSmallTextDarkMode-3qdkV{color:#fff}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"showMoreEvents": "ListArticles-showMoreEvents-SMFJp",
	"articleSmallText": "ListArticles-articleSmallText-30u6j",
	"articleSmallTextDarkMode": "ListArticles-articleSmallTextDarkMode-3qdkV"
};
module.exports = exports;
