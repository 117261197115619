import { connect } from 'react-redux';
import SearchResultPage from 'components/pages/SearchResultPage';
import { actions as locationActions } from 'ducks/location';
import getSearchQuery from 'selectors/getSearchQuery';
import getQueryString from 'selectors/getQueryString';
import getSearchMetadata from 'selectors/getSearchMetadata';
import getSearchResults from 'selectors/getSearchResults';
import getSearchFacets from 'selectors/getSearchFacets';

const mapStateToProps = state => {
  return {
    searchQuery: getSearchQuery(state),
    queryString: getQueryString(state),
    searchMetadata: getSearchMetadata(state),
    searchResults: getSearchResults(state),
    searchFacets: getSearchFacets(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    navigate: (url, scrollPosition) => dispatch(locationActions.set(url, scrollPosition)),
  };
};

const SearchResultPageContainer = connect(mapStateToProps, mapDispatchToProps)(SearchResultPage);

export default SearchResultPageContainer;
