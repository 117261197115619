import React, { useCallback, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faArrowUpRightFromSquare, faClose } from '@fortawesome/pro-regular-svg-icons';
import withStyles from 'isomorphic-style-loader/withStyles';
import Link from 'containers/atoms/LinkContainer';
import s from './NotificationBar.css';
import useUser from '../../../utils/hooks/useUser';
import useAuth from '../../../utils/hooks/useAuth';
import { isInFuture, isLessThan24HoursApart } from '../../../utils/dateUtil';
import Spinner from '../../atoms/Spinner/Spinner';
import useGlobalState from '../../../zustand/zustand';

const NotificationBar = () => {
  const roleRequiredForHandlingNotifications = ['CompanyAdmin'];
  const [isAdminOnActiveCompany, setIsAdminOnActiveCompany] = useState(false);
  const { isAuthenticated } = useAuth();
  const {
    userQuery,
    userQueryForExtraCompanyDetails,
    userQueryForCompanyInvoices,
    updateUserHiddenNotificationsMutation,
  } = useUser();
  let showBar = false;
  let barReason = '';
  const currentYear = new Date().getFullYear();
  const muDeadline = `${currentYear}-02-28T00:00:00`;
  const userPreferencesIsLocked = useGlobalState(state => state.userPreferencesLock.isLocked);
  const setUserPreferencesIsLocked = useCallback(
    useGlobalState(state => state.userPreferencesLock.setIsLocked),
    []
  );
  const companyDataIsLoading = useGlobalState(state => state.companyData.isLoading);
  const companyExtraDataIsLoading = useGlobalState(state => state.companyExtraData.isLoading);
  const companyInvoiceDataisLoading = useGlobalState(state => state.companyInvoiceData.isLoading);
  const userPreferencesIsLoading = useGlobalState(state => state.userPreferences.isLoading);
  const notificationBarRef = useRef(null);

  const handleCloseNotifications = async () => {
    setUserPreferencesIsLocked(true);
    const notification = {
      companyId: userQuery.data?.userData?.activeCompany?.id,
      notificationDate: new Date().toISOString(),
      type: barReason,
    };
    const n = userQuery?.data?.preferencesPayload?.preferences?.hiddenNotifications;
    const hiddenNotifications = n?.length > 0 ? [...n, notification] : [notification];
    await updateUserHiddenNotificationsMutation.mutate(hiddenNotifications);
    setUserPreferencesIsLocked(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      setIsAdminOnActiveCompany(
        roleRequiredForHandlingNotifications.some(val =>
          userQuery.data?.userData?.activeCompany?.roles.includes(val)
        )
      );
    }
  }, [userQuery.data]);

  const setHeight = () => {
    setTimeout(() => {
      const notificationsBar = document.getElementById('notification-bar');
      const menu = document.getElementById('mobile-menu');
      if (notificationsBar && menu) {
        const notificationsBarHeight = notificationsBar.offsetHeight;
        menu.style.top = `${notificationsBarHeight + 100}px`;
      }
    }, 100);
  };

  useEffect(() => {
    if (notificationBarRef.current) {
      setHeight();
    } else {
      const menu = document.getElementById('mobile-menu');
      if (menu) {
        menu.style.top = `${100}px`;
      }
    }
  }, [notificationBarRef.current]);

  const invoiceNotificationHasBeenHidden = () => {
    const notifications = userQuery?.data?.preferencesPayload?.preferences?.hiddenNotifications;
    if (!notifications || notifications?.length === 0) {
      return false;
    }
    const hiddenNotificationsForCompanyAndType = notifications.filter(
      n =>
        n.type === 'invoice' &&
        isLessThan24HoursApart(n.notificationDate, new Date()) &&
        n.companyId === userQuery.data?.userData?.activeCompany?.id
    );
    return hiddenNotificationsForCompanyAndType?.length > 0;
  };

  const muNotificationHasBeenHidden = () => {
    const notifications = userQuery?.data?.preferencesPayload?.preferences?.hiddenNotifications;
    if (!notifications || notifications?.length === 0) {
      return false;
    }
    const hiddenNotificationsForCompanyAndType = notifications.filter(
      n =>
        n.type === 'mu' &&
        isLessThan24HoursApart(n.notificationDate, new Date()) &&
        n.companyId === userQuery.data?.userData?.activeCompany?.id
    );
    return hiddenNotificationsForCompanyAndType?.length > 0;
  };

  // if (
  //   userQuery.isLoading ||
  //   userQuery.isFetching ||
  //   !isAuthenticated
  //   companyDataIsLoading ||
  //   companyInvoiceDataisLoading ||
  //   companyExtraDataIsLoading ||
  //   userPreferencesIsLoading
  // ) {
  //   return null;
  // }

  const invoices = userQueryForCompanyInvoices?.data?.overdueInvoices;
  const hasReportedMuPropertyExists =
    userQueryForExtraCompanyDetails?.data && 'hasReportedMuThisYear' in userQueryForExtraCompanyDetails.data;
  const hasReportedMuThisYear = userQueryForExtraCompanyDetails?.data?.hasReportedMuThisYear;

  if (isAdminOnActiveCompany && invoices?.length > 0 && !invoiceNotificationHasBeenHidden()) {
    showBar = true;
    barReason = 'invoice';
  } else if (
    isAdminOnActiveCompany &&
    hasReportedMuPropertyExists &&
    !hasReportedMuThisYear &&
    !isInFuture(muDeadline) &&
    !muNotificationHasBeenHidden()
  ) {
    showBar = true;
    barReason = 'mu';
  } else {
    showBar = false;
  }

  if (!showBar) {
    return null;
  }

  return (
    <div ref={notificationBarRef} className={s.container} id="notification-bar">
      <div>
        <div className={s.titleContainer}>
          <FontAwesomeIcon icon={faInfoCircle} className={s.infoCircle} />
          <div className={s.title}>
            {barReason === 'invoice' && 'Förfallen faktura'}
            {barReason === 'mu' && 'Inrapportering av medlemsuppgift'}
          </div>
        </div>
        {barReason === 'invoice' && (
          <div className={s.notificationText}>
            <span>
              {userQuery.data?.userData?.activeCompany?.name} har en eller flera fakturor som förfallit till
              betalning.{' '}
            </span>
            <Link to="https://medlemsportalen.svensktnaringsliv.se/" target="_blank" className={s.link}>
              Se alla fakturor i medlemsportalen.
            </Link>{' '}
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={s.externalLink} />
          </div>
        )}
        {barReason === 'mu' && (
          <div className={s.notificationText}>
            <span>
              {userQuery.data?.userData?.activeCompany?.name} har inte rapporterat in medlemsuppgifter.{' '}
            </span>
            <br />
            <span>Deadline {muDeadline.slice(0, 10)} </span>
            <Link to="https://www.medlemsuppgift.se/" target="_blank" className={s.link}>
              Hantera
            </Link>{' '}
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={s.externalLink} />
          </div>
        )}
      </div>
      {(companyDataIsLoading ||
        companyInvoiceDataisLoading ||
        companyExtraDataIsLoading ||
        userPreferencesIsLoading ||
        userPreferencesIsLocked) && (
        <div className={s.close}>
          <Spinner size="1x" />
        </div>
      )}
      {!companyDataIsLoading &&
        !companyInvoiceDataisLoading &&
        !companyExtraDataIsLoading &&
        !userPreferencesIsLoading &&
        !userPreferencesIsLocked && (
          <div onClick={handleCloseNotifications} className={s.close}>
            <FontAwesomeIcon icon={faClose} size="1x" />
          </div>
        )}
    </div>
  );
};

export default withStyles(s)(NotificationBar);
