// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Section-section-2DdTQ{--padding-default:2rem;--tilt-offset:clamp(20px,5vw,100px);--tilt-sibling-offset:calc(clamp(20px, 5vw, 100px)*-1 - 3px);-webkit-backface-visibility:hidden;backface-visibility:hidden;margin-top:-1px;padding-bottom:var(--padding-bottom,var(--padding-default));padding-top:var(--padding-top,var(--padding-default));position:relative}.Section-section-2DdTQ.Section-tiltedBottom-3LomB{-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - var(--tilt-offset)),0 100%);clip-path:polygon(0 0,100% 0,100% calc(100% - var(--tilt-offset)),0 100%);padding-bottom:calc(clamp(20px, 5vw, 75px) + var(--padding-bottom))}.Section-section-2DdTQ.Section-tiltedBottom-3LomB+.Section-section-2DdTQ{margin-top:var(--tilt-sibling-offset);padding-top:calc(clamp(50px, 5vw, 79px) + var(--padding-top))}.Section-section-2DdTQ.Section-hero-2Oifc{margin-top:var(--header-height-negative);padding-top:var(--header-height)}.Section-section-2DdTQ.Section-hero-2Oifc.Section-heroWithBreadcrumbs-1kDDl{margin-top:calc(var(--header-height-negative) - 54px);padding-top:calc(var(--header-height) + 4rem)}@media (min-width:541px){.Section-section-2DdTQ.Section-hero-2Oifc.Section-heroWithBreadcrumbs-1kDDl{margin-top:calc(var(--header-height-negative) - 48px);padding-top:calc(var(--header-height) + 5rem)}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"section": "Section-section-2DdTQ",
	"tiltedBottom": "Section-tiltedBottom-3LomB",
	"hero": "Section-hero-2Oifc",
	"heroWithBreadcrumbs": "Section-heroWithBreadcrumbs-1kDDl"
};
module.exports = exports;
