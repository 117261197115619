import React, { Fragment } from 'react';

// Use a function here and the `new` keyword when using it because the mapper depends on the options object
// e.g. if you send in plain as `true`, the fallback should just return the input while if plain is `false`,
// we want to return a react fragment.
function Options({ separator, lastSeparator, plain, mapper } = {}) {
  this.separator = separator ?? ',';
  this.lastSeparator = lastSeparator ?? 'och';
  this.plain = !!plain;
  this.mapper =
    mapper ?? (this.plain ? item => item : (item, i) => <Fragment key={`item-${i + 1}`}>{item}</Fragment>);
}

/**
 *
 * @param {array} items - The array of items ex `['John', 'Jane', 'Joe']`
 * @param {object} options - The options object: `{
 *  mapper: The callback mapping function for each element ex `(name) => <span key={name}>{name}</span>`. Leaving this argument out will default to a React Fragment.
 *  separator: The separator between each item ex `,`
 *  lastSeparator: The separator between the last two items ex `och`
 *  plain: Whether or not to return a string or a React Fragment. Useful when you want to use this function as plain text instead of react elements/components.
 * }`

 * @returns A text containing a comma-separatet list but with the `and` word between the last two items
 */
const sentencifyArray = (array, inputOptions = {}) => {
  const options = new Options(inputOptions);

  const result = array.map(options.mapper).reduce((arr, curr, i) => {
    if (i > 0) {
      const isLastItem = i === array.length - 1;
      const wrapper = options.plain ? (
        isLastItem ? (
          ` ${options.lastSeparator} `
        ) : (
          `${options.separator} `
        )
      ) : (
        <Fragment key={Math.random().toString()}>
          {isLastItem ? ` ${options.lastSeparator} ` : `${options.separator} `}
        </Fragment>
      );

      arr.push(wrapper);
    }
    arr.push(curr);
    return arr;
  }, []);

  return options.plain ? result.join('') : result;
};

export default sentencifyArray;
