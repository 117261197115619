// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PodBanner-podBannerSection-12GCY{-ms-flex-pack:center;-ms-flex-line-pack:center;align-content:center;background-color:#eff2f5;display:grid;grid-template-columns:1em 1fr 1em;justify-content:center;margin-top:-90px;padding-bottom:3rem}@media (min-width:820px){.PodBanner-podBannerSection-12GCY{grid-template-columns:1fr 6fr 1fr}}@media (min-width:1169px){.PodBanner-podBannerSection-12GCY{grid-template-columns:1fr minmax(500px,907px) 1fr}}.PodBanner-podBannerSection-12GCY .PodBanner-PodBannerBackground-3baUc{-ms-flex-pack:center;-ms-flex-line-pack:center;align-content:center;background:#ea8f12 url(/snOutlineWingsWhiteBg.png) 100% 100% no-repeat;background-size:cover;display:grid;grid-column-end:3;grid-column-start:2;justify-content:center;z-index:999}.PodBanner-podBannerSection-12GCY .PodBanner-PodBannerBackground-3baUc .PodBanner-PodBanner-2dOCo{padding:80px 32px 64px}.PodBanner-podBannerSection-12GCY .PodBanner-PodBannerBackground-3baUc .PodBanner-PodBanner-2dOCo .PodBanner-skattefragor-1hML3{font-family:LabGrotesque-Bold,sans-serif;font-size:2.5rem}.PodBanner-podBannerSection-12GCY .PodBanner-PodBannerBackground-3baUc .PodBanner-body-2Yxq_{margin-top:8px}@media (min-width:541px){.PodBanner-podBannerSection-12GCY .PodBanner-PodBannerBackground-3baUc .PodBanner-body-2Yxq_{font-size:1.125rem}}.PodBanner-podBannerSection-12GCY a{margin-top:32px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"podBannerSection": "PodBanner-podBannerSection-12GCY",
	"PodBannerBackground": "PodBanner-PodBannerBackground-3baUc",
	"PodBanner": "PodBanner-PodBanner-2dOCo",
	"skattefragor": "PodBanner-skattefragor-1hML3",
	"body": "PodBanner-body-2Yxq_"
};
module.exports = exports;
