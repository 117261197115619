import React, { createContext, useRef } from 'react';
import { selectors as locationSelectors } from 'ducks/location';
import { selectors as cookSelectors, actions as cookActions } from 'ducks/cook';
import { useDispatch, useSelector } from 'react-redux';
import sentryLogger from '../utils/sentryLogger';

export const cookDataContext = createContext();

const MAX_FETCH_INTERVAL = 4000;
const MAX_FETCH_COUNT = 100;

const CookDataProvider = ({ children }) => {
  const isFetching = useSelector(cookSelectors.isFetching);
  const dataPath = useSelector(locationSelectors.getDataPath);
  const data = useSelector(cookSelectors.getData);
  const dispatch = useDispatch();
  const fetchCount = useRef(0);
  const fetchTimestamp = useRef(0);

  if (fetchCount.current >= MAX_FETCH_COUNT) {
    sentryLogger(
      `Cook data was fetched too many times (${fetchCount.current} times, limit: ${MAX_FETCH_COUNT})`
    );
  }

  const fetchData = () => {
    dispatch(cookActions.fetchData(dataPath));
    fetchCount.current += 1;
    fetchTimestamp.current = Date.now();

    logger.log('fetchers', 'Cook was fetched');
  };

  const refetchDataIfConditionsMet = () => {
    const isFetchTimestampValid =
      fetchTimestamp.current === 0 || Date.now() - fetchTimestamp.current > MAX_FETCH_INTERVAL;
    const isFetchCountValid = fetchCount.current <= MAX_FETCH_COUNT;

    const shouldRefetch = !isFetching && isFetchTimestampValid && isFetchCountValid;

    if (shouldRefetch) {
      fetchData();
    }
  };

  const values = {
    data,
    fetchData,
    isFetching,
    fetchCount: fetchCount.current,
    fetchTimestamp: fetchTimestamp.current,
    refetchDataIfConditionsMet,
  };

  return <cookDataContext.Provider value={values}>{children}</cookDataContext.Provider>;
};

export default CookDataProvider;
