import React from 'react';
import ButtonSecondary from 'components/atoms/buttons/ButtonSecondary';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';

const RenderLink = ({ type, to, mobile = false, children }) => {
  const variant = mobile ? 'linkNoUnderline' : 'link';
  let href = to;
  let target = '';

  switch (type) {
    case 'Phone':
      href = `tel:${to}`;
      target = '_blank';
      break;
    case 'Email':
      href = `mailto:${to}`;
      target = '_blank';
      break;
    case 'ExternalLink':
      target = '_blank';
      break;
    default:
      break;
  }

  return type === 'InternalLink' ? (
    <div>
      <Typography variant={variant} component={Link} to={href}>
        {!mobile && children}
        {mobile && (
          <ButtonSecondary block gutterTop>
            {children}
          </ButtonSecondary>
        )}
      </Typography>
    </div>
  ) : (
    <div>
      <Typography variant={variant} component="a" href={href} target={target}>
        {!mobile && children}
        {mobile && (
          <ButtonSecondary block gutterTop>
            {children}
          </ButtonSecondary>
        )}
      </Typography>
    </div>
  );
};

export default RenderLink;
