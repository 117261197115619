import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ModifyButton.css';

const ModifyButton = ({ children, classes, borderLeft, borderRight, ...other }) => {
  const classNames = classnames(
    s.modifyButton,
    borderLeft && s.borderLeft,
    borderRight && s.borderRight,
    classes
  );
  return (
    <button className={classNames} type="button" {...other}>
      {children}
    </button>
  );
};

export default withStyles(s)(ModifyButton);
