import { connect } from 'react-redux';
import SearchModal from 'components/organisms/SearchModal';
import { actions as locationActions } from 'ducks/location';

const mapStateToProps = undefined;
const mapDispatchToProps = dispatch => {
  return {
    navigate: url => dispatch(locationActions.set(url)),
  };
};

const SearchModalContainer = connect(mapStateToProps, mapDispatchToProps)(SearchModal);

export default SearchModalContainer;
