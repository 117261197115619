import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import getImages from 'utils/getImages';
import bootstrapGrid from 'style/bootstrap-grid.css';
import VCard from 'components/molecules/VCard';
import BootstrapWrapper from '../BootstrapWrapper/BootstrapWrapper';
import s from './WritersList.css';
import Typography from '../../../atoms/Typography/Typography';

const WritersList = ({ writers, blogUniqueName }) => {
  return (
    <div
      className={classnames(s.writersList, {
        [s.kompetensforsorjning]: blogUniqueName === 'kompetensforsorjning',
      })}
    >
      <div className={bootstrapGrid.container}>
        <div className={bootstrapGrid.row}>
          <BootstrapWrapper>
            <Typography variant="h2" className={s.heading}>
              Skribenter
            </Typography>

            <div className={s.writers}>
              {writers.map(writer => {
                const sizes = getImages('SQUARE');
                const imageSrc = writer?.image?.href;
                const imageAlt = writer?.image?.altText;

                const id = writer?.id || Math.random().toString(36).slice(2);

                return (
                  <VCard
                    key={id}
                    imageSrc={imageSrc && sizes.toSrc(imageSrc, 'S')}
                    imageSrcSet={imageSrc && sizes.toSrcSet(imageSrc)}
                    imageAlt={imageAlt && imageAlt}
                    name={`${writer.surName}, ${writer.firstName}`}
                    title={writer.description}
                    classes={s.writer}
                    nameLink={writer.profileLink}
                    imageLink={writer.profileLink}
                    // imageWrapperClasses={s.imageWrapper}
                    nameWrapperClasses={s.nameWrapper}
                    // titleWrapperClasses={s.titleWrapper}
                  />
                );
              })}
            </div>
          </BootstrapWrapper>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(WritersList);
