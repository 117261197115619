import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';
import clientifyUrl from '../utils/clientifyUrl';

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      const latestRemisses = pickFrom(data, 'latestRemisses');
      return latestRemisses?.map(remiss => {
        const href = pickFrom(remiss, 'href');
        const fileRaw = pickFrom(remiss, 'document[0]');

        const file = {
          title: pickFrom(fileRaw, 'content.title'),
          downloadLink: pickFrom(fileRaw, 'content.binary.href'),
          fileExtension: pickFrom(fileRaw, 'content.binary.extension'),
        };

        return {
          id: pickFrom(remiss, 'id'),
          title: pickFrom(remiss, 'title'),
          href: clientifyUrl(href),
          publishedDate: pickFrom(remiss, 'published'),
          contentType: pickFrom(remiss, 'contentType'),
          document: file,
        };
      });
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
