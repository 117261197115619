// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".QuoteArticleMain-quoteArticleMain-1C82r{-ms-flex-pack:center;-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;justify-content:center;margin-bottom:24px;margin-top:24px;text-align:center}@media (min-width:820px){.QuoteArticleMain-quoteArticleMain-1C82r{margin-bottom:32px;margin-top:32px}}@media (min-width:1169px){.QuoteArticleMain-quoteArticleMain-1C82r{margin-bottom:40px;margin-top:40px}}.QuoteArticleMain-quoteArticleMainQuote-h5dk7 q{max-width:404px}.QuoteArticleMain-quoteArticleMainIcon-3oPEr{color:#dee4ea;font-size:37px;height:37px;margin-bottom:.75rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"quoteArticleMain": "QuoteArticleMain-quoteArticleMain-1C82r",
	"quoteArticleMainQuote": "QuoteArticleMain-quoteArticleMainQuote-h5dk7",
	"quoteArticleMainIcon": "QuoteArticleMain-quoteArticleMainIcon-3oPEr"
};
module.exports = exports;
