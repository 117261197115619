import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import s from './Slider.css';

const Slider = ({
  label,
  hideLabel = false,
  className,
  min,
  max,
  value,
  onChange,
  disabled,
  withTextInput,
  textInputComponent,
}) => {
  const id = label.toLowerCase().replaceAll(/[åä]/g, 'a').replaceAll(/[ö]/g, 'o').replaceAll(/\s/g, '');

  return (
    <div
      className={classnames(s.slider, {
        [className]: !!className,
      })}
    >
      {!hideLabel && <label htmlFor={withTextInput ? `${id}-input` : `${id}-slider`}>{label}</label>}
      {withTextInput && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {textInputComponent ? (
            textInputComponent({ value, disabled, className: s.textInput, id: `${id}-input` })
          ) : (
            <input
              id={`${id}-input`}
              className={s.textInput}
              type="number"
              disabled={disabled}
              value={value}
              onChange={onChange}
            />
          )}
        </>
      )}
      <input
        style={{
          background: `linear-gradient(to right, var(--track-color-left) 0%, var(--track-color-left) ${
            ((value - min) / (max - min)) * 100
          }%, var(--track-color-right) ${
            ((value - min) / (max - min)) * 100
          }%, var(--track-color-right) 100%)`,
        }}
        className={s.sliderInput}
        disabled={disabled}
        onChange={onChange}
        value={value}
        type="range"
        name={id}
        id={`${id}-slider`}
        min={min}
        max={max}
      />
    </div>
  );
};

export default withStyles(s)(Slider);
