import { connect } from 'react-redux';
import PageCanonicalLink from 'components/atoms/PageCanonicalLink/PageCanonicalLink';
import getCanonicalLink from 'selectors/getCanonicalLink';

const mapStateToProps = state => ({
  link: getCanonicalLink(state),
});

const mapDispatchToProps = undefined;

const PageCanonicalLinkContainer = connect(mapStateToProps, mapDispatchToProps)(PageCanonicalLink);

export default PageCanonicalLinkContainer;
