// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CountDownBalk-balkInformation-bMjBq{margin-top:.75rem}.CountDownBalk-textBeforeCountDown-bRQVb{margin-right:.325rem}.CountDownBalk-countDown-3wQ9O,.CountDownBalk-textBeforeCountDown-bRQVb{word-wrap:break-word;font-size:1rem;line-height:1em}.CountDownBalk-countDown-3wQ9O{display:inline-block}.CountDownBalk-countDownRight--GhXj{margin-right:.325rem}.CountDownBalk-textAfterCountDown-2AKS8{word-wrap:break-word;font-size:1rem;line-height:1em}@media screen and (max-width:820px){.CountDownBalk-textBeforeCountDown-bRQVb{font-size:.875rem;margin-right:.25rem}.CountDownBalk-countDown-3wQ9O,.CountDownBalk-textAfterCountDown-2AKS8{font-size:.875rem}}@media screen and (max-width:541px){.CountDownBalk-countDown-3wQ9O,.CountDownBalk-textBeforeCountDown-bRQVb{font-size:.8125rem}.CountDownBalk-textAfterCountDown-2AKS8{display:none;font-size:.8125rem}}@media screen and (max-width:320px){.CountDownBalk-countDown-3wQ9O,.CountDownBalk-textAfterCountDown-2AKS8,.CountDownBalk-textBeforeCountDown-bRQVb{font-size:.75rem}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"balkInformation": "CountDownBalk-balkInformation-bMjBq",
	"textBeforeCountDown": "CountDownBalk-textBeforeCountDown-bRQVb",
	"countDown": "CountDownBalk-countDown-3wQ9O",
	"countDownRight": "CountDownBalk-countDownRight--GhXj",
	"textAfterCountDown": "CountDownBalk-textAfterCountDown-2AKS8"
};
module.exports = exports;
