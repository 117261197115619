import React, { useState, useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Balk from 'components/atoms/Balk';
import Typography from 'components/atoms/Typography';
import s from './CountDownBalk.css';

const CountDownBalk = ({
  backgroundColor = '',
  beamCountDownDate = '',
  isShowBeamCountDown,
  beamText = '',
  beamTextAfterCountDown = '',
}) => {
  const countdownDate = new Date(beamCountDownDate).getTime();
  const istextColorValue = !!(
    backgroundColor === 'orange' ||
    backgroundColor === 'red' ||
    backgroundColor === 'steel' ||
    backgroundColor === 'darkBlue'
  );

  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const setNewTime = () => {
    const currentTime = new Date().getTime();
    const distanceToDate = countdownDate - currentTime;

    if (countdownDate) {
      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      days = `${days}`;
      if (numbersToAddZeroTo.includes(hours)) {
        hours = `${hours}`;
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `${minutes}`;
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `${seconds}`;
      }
      setState({ days, hours, minutes, seconds });
    }

    if (isShowBeamCountDown === true) {
      if (distanceToDate < 0) {
        setState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }
  };
  useEffect(() => {
    setInterval(() => setNewTime(), 1000);
  }, []);
  return (
    <Balk color={backgroundColor}>
      <Typography variant="h5" darkMode={istextColorValue}>
        <p className={s.balkInformation}>
          <span className={s.textBeforeCountDown}> {beamText}</span>
          {isShowBeamCountDown === true && (
            <div className={s.countDown}>
              <span className={s.countDownRight}>{state.days}d</span>
              <span className={s.countDownRight}>{state.hours}h</span>
              <span className={s.countDownRight}>{state.minutes}m</span>
              <span className={s.countDownRight}>{state.seconds}s</span>
            </div>
          )}

          <span className={s.textAfterCountDown}> {beamTextAfterCountDown}</span>
        </p>
      </Typography>
    </Balk>
  );
};

export default withStyles(s)(CountDownBalk);
