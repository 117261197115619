import React, { useState } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import history from 'utils/history';
import renderStorylineElement from 'utils/renderStorylineElement';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import boostrapGrid from 'style/bootstrap-grid.css';
import { useSelector } from 'react-redux';
import Checkbox from 'components/atoms/Checkbox';
import SearchBar from 'components/molecules/SearchBar';
import Modal from 'components/organisms/Modal';
import useTranslate from '../../../../utils/hooks/useTranslate';
import useNavigate from '../../../../utils/hooks/useNavigate';
import FilterSelectedBlock from '../FilterSelectedBox/FilterSelectedBlock';
import FilterBlock from '../FilterBlock/FilterBlock';
import getBlogSearchPage from '../../../../selectors/getBlogSearchPage';
import s from './BlogSearchPage.css';
import BlogPostList from '../BlogPostList/BlogPostList';
import PaginationButtons from '../PaginationButtons/PaginationButtons';
import YouHaveSearchedFor from '../YouHaveSearchedFor/YouHaveSearchedFor';
import ButtonPrimaryAlt from '../../../atoms/buttons/ButtonPrimaryAlt';
import Translate from '../../../../containers/atoms/TranslateContainer';

const buildSearchString = (basePath, searchTerm, filters) => {
  let query = `${basePath}?q=`;
  if (searchTerm) {
    query += encodeURIComponent(searchTerm);
  }
  Object.keys(filters).forEach(key => {
    if (filters[key] && key !== 'q') {
      filters[key].forEach(val => {
        query += `&${key}=${encodeURIComponent(val)}`;
      });
    }
  });
  return query;
};

const getItem = (itemsString, items) => {
  let text = '';

  for (let i = 0; i < items.length; i++) {
    text += `&${itemsString}=${items[i]}`;
  }
  return text;
};

const BlogSearchPage = () => {
  const {
    blogName,
    groupedStorylineTree,
    searchQuery,
    queryString,
    searchFacets,
    pathWithoutParams,
    searchResults,
    pagination,
  } = useSelector(getBlogSearchPage);
  const navigate = useNavigate();
  const { headGroup, contentGroup } = groupedStorylineTree;
  const [searchPhrase, setSearchPhrase] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const translate = useTranslate();

  const [queryStrings, setQueryStrings] = useState({
    ...queryString,
    start: undefined,
  });

  const triggerFilter = filters => {
    const searchPath = buildSearchString(pathWithoutParams, searchQuery?.q, filters);
    history.push(searchPath, { scrollPosition: window.scrollY }); // do SPA navigation
    navigate(searchPath, window.scrollY);
  };

  const selectFilter = (filterId, value) => {
    const newQueryString = {
      ...queryString,
      start: undefined,
    };
    if (!newQueryString[filterId]) {
      newQueryString[filterId] = [];
    }

    newQueryString[filterId].push(value);
    setQueryStrings(newQueryString);
    triggerFilter(newQueryString);
  };

  const unselectFilter = (filterId, filterValue) => {
    const newQueryString = {
      ...queryString,
      start: undefined,
    };
    newQueryString[filterId] = newQueryString[filterId].filter(val => val !== filterValue);
    setQueryStrings(newQueryString);
    triggerFilter(newQueryString);
  };

  const clearFilters = () => {
    setQueryStrings({});
    triggerFilter({});
  };

  const handleSubmit = e => {
    e.preventDefault();
    const searchPath = searchPhrase
      ? `${pathWithoutParams}?q=${encodeURIComponent(searchPhrase)}`
      : pathWithoutParams;
    history.push(searchPath, { scrollPosition: 0 });
    navigate(searchPath);
  };

  const handleSubmitWithFilter = e => {
    e.preventDefault();
    let searchPath = `${pathWithoutParams}?q=*`;
    if (searchPhrase) {
      searchPath = searchPath.replace('?q=*', `?q=${encodeURIComponent(searchPhrase)}`);
    }

    if (queryStrings.issue) {
      searchPath += getItem('issue', queryStrings.issue);
    }
    if (queryStrings.profile) {
      searchPath += getItem('profile', queryStrings.profile);
    }
    if (queryStrings.subject) {
      searchPath += getItem('subject', queryStrings.subject);
    }
    if (queryStrings.year) {
      searchPath += getItem('year', queryStrings.year);
    }

    history.push(searchPath, { scrollPosition: 0 }); // do SPA navigation
    navigate(searchPath);
  };

  const resetSearch = () => {
    setSearchPhrase('');
    history.push(pathWithoutParams, { scrollPosition: 0 }); // do SPA navigation
    navigate(pathWithoutParams);
  };

  const hasFilters =
    !!queryString && !!(queryString.issue || queryString.profile || queryString.subject || queryString.year);

  return (
    <div className={s.blogSearchPage}>
      <div className={classnames(boostrapGrid.container)}>
        <div className={classnames(boostrapGrid.row)}>
          <div className={getBootstrapColumns('20,2', '16,4', '13,1')}>
            <Modal
              modalOpen={isModalOpen}
              closeModal={() => setIsModalOpen(!isModalOpen)}
              label={translate('LIST_PAGE.FILTER_SEARCH')}
            >
              <FilterBlock
                searchQuery={searchQuery?.q?.[0]}
                facets={searchFacets}
                filters={queryString || searchQuery}
                selectFilter={selectFilter}
                unselectFilter={unselectFilter}
                clearFilters={clearFilters}
                mobileMode
                closeModal={() => setIsModalOpen(false)}
              />
            </Modal>
            {headGroup && headGroup.map(renderStorylineElement)}
            {contentGroup && contentGroup.map(renderStorylineElement)}
            <YouHaveSearchedFor searchQuery={searchQuery} resetSearch={resetSearch} />

            <div className={s.searchWrapper}>
              <SearchBar
                autoComplete="off"
                classes={s.searchBar}
                onSubmit={!isChecked ? handleSubmit : handleSubmitWithFilter}
                placeholder={`Sök i hela bloggen ${blogName}`}
                searchButton
                searchTerm={searchPhrase}
                handleChange={e => setSearchPhrase(e.target.value)}
              />
              <ButtonPrimaryAlt classes={s.filterButton} onClick={() => setIsModalOpen(true)}>
                <Translate>LIST_PAGE.FILTER</Translate>
              </ButtonPrimaryAlt>
            </div>

            {hasFilters && (
              <div className={s.filterWrapperMobile}>
                <Checkbox
                  id="blogSearchPageFilterCheckboxMobile"
                  className={s.checkboxMobile}
                  label={translate('LIST_PAGE.KEEP_ACTIVE_FILTERS_ON_SEARCH')}
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />

                <FilterSelectedBlock
                  searchQuery={searchQuery?.q?.[0]}
                  facets={searchFacets}
                  filters={queryString || searchQuery}
                  selectFilter={selectFilter}
                  unselectFilter={unselectFilter}
                  clearFilters={clearFilters}
                  mobileMode
                />
              </div>
            )}

            <BlogPostList blogPosts={searchResults} showDividers />
            <PaginationButtons
              prevUrl={pagination.prev}
              nextUrl={pagination.next}
              prevLabel="Föregående"
              nextLabel="Nästa"
            />
          </div>
          <aside className={classnames(getBootstrapColumns('22,1', '22,1', '8,2'), s.sideContent)}>
            {hasFilters && (
              <div className={s.filterSelectedBlockWrapper}>
                <FilterSelectedBlock
                  searchQuery={searchQuery?.q?.[0]}
                  facets={searchFacets}
                  filters={queryString || searchQuery}
                  selectFilter={selectFilter}
                  unselectFilter={unselectFilter}
                  clearFilters={clearFilters}
                  mobileMode={false}
                />

                <Checkbox
                  id="blogSearchPageFilterCheckbox"
                  className={s.checkbox}
                  label={translate('LIST_PAGE.KEEP_ACTIVE_FILTERS_ON_SEARCH')}
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
              </div>
            )}

            <div className={s.filterBlockWrapper}>
              <FilterBlock
                searchQuery={searchQuery?.q?.[0]}
                facets={searchFacets}
                filters={queryString || searchQuery}
                selectFilter={selectFilter}
                unselectFilter={unselectFilter}
                clearFilters={clearFilters}
              />
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(BlogSearchPage);
