import { call, select, put, takeLatest } from 'redux-saga/effects';
import * as types from './types';
import * as selectors from './selectors';
import * as actions from './actions';
import * as api from './api';

function* fetchData() {
  if (typeof window !== 'undefined') {
    const path = yield select(selectors.getPath);

    try {
      try {
        window.stop();
      } catch (exception) {
        document.execCommand('Stop');
      }
      const data = yield call(api.fetchData, path);
      if (data.status === 'error') {
        yield put(actions.fetchFailed(data));
      } else if (!data?.redirected) {
        yield put(actions.fetchSuccess(data));
      }
    } catch (error) {
      console.error(error);
      yield put(actions.fetchFailed({ status: 'error', code: 500 }));
    }
  }
}

function* saga() {
  yield takeLatest(types.FETCH, fetchData);
}

export default saga;
