import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import useVFSResult from '../../../utils/hooks/useVFSResult';
import s from './VFSResult2.css';
import Typography from '../../atoms/Typography';
import { calcAntalValfardtjanst, VALFARDSTJANSTER } from '../../../utils/vfsUtils';
import TempusifiedValfardstjanst from '../../atoms/TempusifiedValfardtjanst/TempusifiedValfardtjanst';
import sentencifyArray from '../../../utils/sentencifyArray';

const VFSResult2 = ({ darkMode }) => {
  const { isValid, vfsData } = useVFSResult();

  if (!isValid) {
    return null;
  }

  const { companyName, lonekostnaderPerAr, bolagsskattPerAr, totalSkatt } = vfsData;

  const resultsToRender = [
    VALFARDSTJANSTER.polis,
    VALFARDSTJANSTER.forskoleplats,
    VALFARDSTJANSTER.gymnasielarare,
    VALFARDSTJANSTER.underskoterskaIHemtjanst,
  ]
    .map(valfardstjanst => ({
      valfardstjanst,
      nr: calcAntalValfardtjanst(lonekostnaderPerAr, bolagsskattPerAr, valfardstjanst, totalSkatt),
    }))
    .filter(item => item.nr > 0)
    .map(item => (
      <TempusifiedValfardstjanst
        number={calcAntalValfardtjanst(lonekostnaderPerAr, bolagsskattPerAr, item.valfardstjanst, totalSkatt)}
        valfardstjanst={item.valfardstjanst}
      />
    ));

  return (
    <div className={s.vfsResult2}>
      <Typography darkMode={darkMode}>
        Ni säkerställer {sentencifyArray(resultsToRender, { lastSeparator: 'eller' })}.
      </Typography>

      <Typography darkMode={darkMode}>
        Det är viktigt att fler förstår vilken betydelse {companyName} gör för välfärden. Vi tjänar alla på
        att det går bra för företagen i Sverige.
      </Typography>

      <Typography darkMode={darkMode}>
        Företagen, välfärdsskaparna, gör skillnad. Tack för att ni finns.
      </Typography>
    </div>
  );
};

export default withStyles(s)(VFSResult2);
