// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InDepthItem-inDepthItem-148J3{display:block;margin-bottom:14px}.InDepthItem-inDepthItemLink-6EUJW{-ms-flex-align:start;align-items:flex-start;color:#33414e;display:-ms-flexbox;display:flex;font-family:LabGrotesque-Medium,sans-serif;font-size:1.125rem;line-height:1.625rem;text-decoration:none}@media (min-width:541px){.InDepthItem-inDepthItemLink-6EUJW{font-size:1.125rem}}.InDepthItem-inDepthItemLink-6EUJW:hover{text-decoration:underline}.InDepthItem-darkMode-L2m0C .InDepthItem-inDepthItemLink-6EUJW{color:#fff}.InDepthItem-inDepthItemLink-6EUJW .InDepthItem-inDepthItemIcon-iaJHq{color:#ea8f12;height:18px;margin:4px 12px 0 0;width:16px}.InDepthItem-inDepthItemLink-6EUJW .InDepthItem-inDepthItemExternalIcon-3YiE0{color:#ea8f12;height:14px;margin:6px 0 2px 8px;width:14px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"inDepthItem": "InDepthItem-inDepthItem-148J3",
	"inDepthItemLink": "InDepthItem-inDepthItemLink-6EUJW",
	"darkMode": "InDepthItem-darkMode-L2m0C",
	"inDepthItemIcon": "InDepthItem-inDepthItemIcon-iaJHq",
	"inDepthItemExternalIcon": "InDepthItem-inDepthItemExternalIcon-3YiE0"
};
module.exports = exports;
