import { connect } from 'react-redux';
import CalendarPage from 'components/pages/CalendarPage';
import { actions as locationActions } from 'ducks/location';
import getSearchQuery from 'selectors/getSearchQuery';
import getQueryString from 'selectors/getQueryString';
import getSearchMetadata from 'selectors/getSearchMetadata';
import getSearchFacets from 'selectors/getSearchFacets';
import getEventSearchResults from 'selectors/getEventSearchResults';
import getDataPathWithoutParams from '../../selectors/getDataPathWithoutParams';
import getGroupedStorylineTree from '../../selectors/getGroupedStorylineTree';

const mapStateToProps = state => {
  return {
    searchQuery: getSearchQuery(state),
    queryString: getQueryString(state),
    searchMetadata: getSearchMetadata(state),
    searchFacets: getSearchFacets(state),
    searchResults: getEventSearchResults(state),
    pathWithoutParams: getDataPathWithoutParams(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    navigate: (url, scrollPosition) => dispatch(locationActions.set(url, scrollPosition)),
  };
};

const CalendarPageContainer = connect(mapStateToProps, mapDispatchToProps)(CalendarPage);

export default CalendarPageContainer;
