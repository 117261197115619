import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import generateSizes from 'utils/generateSizes';
import getImages from 'utils/getImages';
import Carousel from '../Carousel/Carousel';
import ResponsiveImage from '../../atoms/ResponsiveImage/ResponsiveImage';
import Typography from '../../atoms/Typography';
import s from './ImageCarousel.css';

const ImageCarousel = ({ items }) => {
  const carouselItems = items.map(item => {
    const imageSizes = generateSizes({ lg: '834px', sm: '74vw', default: '100vw' });

    return (
      <div key={item.id}>
        <ResponsiveImage
          srcSet={getImages('LANDSCAPE').toSrcSet(item.href)}
          src={getImages('LANDSCAPE').toSrc(item.href, 'M')}
          sizes={imageSizes}
          alt={item.altText}
        />
        <Typography variant="imageCaption" component="div" classes={s.imageCarouselCaption}>
          {item.caption}
          {item.photographer && (
            <span className={s.imageCarouselCaptionPhoto}> Foto: {item.photographer}</span>
          )}
        </Typography>
      </div>
    );
  });

  return (
    <div className={s.carousel}>
      <Carousel items={carouselItems} showItemNumber />
    </div>
  );
};

export default withStyles(s)(ImageCarousel);
