import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

const Spinner = ({ color = 'black', className, size = '1x' }) => {
  return (
    <FontAwesomeIcon
      className={classnames({
        [className]: !!className,
      })}
      icon={faLoader}
      color={color}
      spin
      size={size}
    />
  );
};

export default Spinner;
