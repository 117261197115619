import React from 'react';
import SoundBlock from '../../atoms/SoundBlock/SoundBlock';
import VideoBlock from '../../atoms/VideoBlock/VideoBlock';
import pickFrom from '../../../utils/pickFrom';
import InfogramBlock from '../../atoms/InfogramBlock';

const EmbedBlock = ({ element }) => {
  const uri = pickFrom(element, 'fields.uri.rawText');

  const isSound = uri.includes('soundcloud');
  const isVideo = uri.includes('vimeo') || uri.includes('youtube');
  const isInfogram = uri.includes('infogram');

  if (isSound) return <SoundBlock uri={uri} />;
  if (isInfogram) return <InfogramBlock uri={uri} />;
  if (isVideo) {
    const chatStartDate = pickFrom(element, 'fields.vimeoChatStartDate.rawText');
    const chatEndDate = pickFrom(element, 'fields.vimeoChatEndDate.rawText');

    const chatDate = {
      start: chatStartDate ? new Date(pickFrom(element, 'fields.vimeoChatStartDate.rawText')) : null,
      end: chatEndDate ? new Date(pickFrom(element, 'fields.vimeoChatEndDate.rawText')) : null,
    };

    const caption = pickFrom(element, 'fields.caption.rawText');
    const showVimeoChat = !!pickFrom(element, 'fields.showVimeoChat');
    const inHeadGroup = element.type === 'headEmbed';

    return (
      <VideoBlock
        chatDate={chatDate}
        caption={caption}
        uri={uri}
        showVimeoChat={showVimeoChat}
        inHeadGroup={inHeadGroup}
      />
    );
  }

  return null;
};

export default EmbedBlock;
