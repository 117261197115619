import { getEnvironment } from './getEnvironment';

// CUE-3470
// Note regarding the scopes:
// We need to define a custom scope, because otherwise Azure B2C won't return an accessToken.
// And the caching logic used by the MSAL method acquireTokenSilent doesn't work without an accessToken.
// We use the client id as the scope, since that will always be a valid scope that results in an accessToken.
// See:
//  https://svensktnaringsliv.jira.com/browse/CUE-3470
//  https://learn.microsoft.com/en-us/azure/active-directory-b2c/access-tokens#openid-connect-scopes

const ENV_MAP = {
  localhost: {
    msalConfig: {
      auth: {
        clientId: '83bf3f09-94f9-4257-918d-9e24249dde36',
        authority:
          'https://snmob2ctest.b2clogin.com/snmob2ctest.onmicrosoft.com/b2c_1a_signup_signin-localonly',
        knownAuthorities: ['snmob2ctest.b2clogin.com'],
        jwksUri:
          'https://snmob2ctest.b2clogin.com/snmob2ctest.onmicrosoft.com/b2c_1a_signup_signin-localonly/discovery/v2.0/keys',
        redirectUri: 'http://localhost:3000/logincompleted',
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        // loggerOptions: {
        //   loggerCallback: (level, message) => {
        //     console.info('[MSAL]', level, message);
        //   },
        //   piiLoggingEnabled: true,
        // },
      },
    },
    loginRequest: {
      scopes: ['83bf3f09-94f9-4257-918d-9e24249dde36'],
    },
    graphConfig: {
      graphMeEndpoint: 'https://graph.microsoft.com',
    },
  },
  test: {
    msalConfig: {
      auth: {
        clientId: '83bf3f09-94f9-4257-918d-9e24249dde36',
        authority:
          'https://snmob2ctest.b2clogin.com/snmob2ctest.onmicrosoft.com/b2c_1a_signup_signin-localonly',
        knownAuthorities: ['snmob2ctest.b2clogin.com'],
        jwksUri:
          'https://snmob2ctest.b2clogin.com/snmob2ctest.onmicrosoft.com/b2c_1a_signup_signin-localonly/discovery/v2.0/keys',
        redirectUri: 'https://test-www.svensktnaringsliv.net/logincompleted',
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    },
    loginRequest: {
      scopes: ['83bf3f09-94f9-4257-918d-9e24249dde36'],
    },
    graphConfig: {
      graphMeEndpoint: 'https://graph.microsoft.com',
    },
  },
  stage: {
    msalConfig: {
      auth: {
        clientId: '83bf3f09-94f9-4257-918d-9e24249dde36',
        authority:
          'https://snmob2ctest.b2clogin.com/snmob2ctest.onmicrosoft.com/b2c_1a_signup_signin-localonly',
        knownAuthorities: ['snmob2ctest.b2clogin.com'],
        jwksUri:
          'https://snmob2ctest.b2clogin.com/snmob2ctest.onmicrosoft.com/b2c_1a_signup_signin-localonly/discovery/v2.0/keys',
        redirectUri: 'https://stage-www.svensktnaringsliv.net/logincompleted',
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    },
    loginRequest: {
      scopes: ['83bf3f09-94f9-4257-918d-9e24249dde36'],
    },
    graphConfig: {
      graphMeEndpoint: 'https://graph.microsoft.com',
    },
  },
  prod: {
    msalConfig: {
      auth: {
        clientId: 'd1d3cd56-a4e3-4147-a895-9c3b34f703bc',
        authority:
          'https://sso.svensktnaringsliv.se/snmob2cprd.onmicrosoft.com/b2c_1a_signup_signin-localonly',
        knownAuthorities: ['sso.svensktnaringsliv.se'],
        jwksUri:
          'https://sso.svensktnaringsliv.se/snmob2cprd.onmicrosoft.com/b2c_1a_signup_signin-localonly/discovery/v2.0/keys',
        redirectUri: 'https://www.svensktnaringsliv.se/logincompleted',
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    },
    loginRequest: {
      scopes: ['d1d3cd56-a4e3-4147-a895-9c3b34f703bc'],
    },
    graphConfig: {
      graphMeEndpoint: 'https://graph.microsoft.com',
    },
  },
};

const environment = getEnvironment();

export const { msalConfig } = ENV_MAP[environment] || {};
export const { loginRequest } = ENV_MAP[environment] || {};
export const { graphConfig } = ENV_MAP[environment] || {};
