// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserCompaniesList-userCompaniesList-2nEQ1{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;gap:16px;padding:16px 20px 16px 0}@media (min-width:1169px){.UserCompaniesList-userCompaniesList-2nEQ1{gap:8px;padding:0}.UserCompaniesList-userCompaniesList-2nEQ1 .UserCompaniesList-spinner-3tB48{color:#33414e}}.UserCompaniesList-userCompaniesList-2nEQ1 select{background-color:#fff;border:1px solid #dee4ea;border-radius:0;color:#33414e;padding:4px;-webkit-transition:opacity .3s;transition:opacity .3s;width:100%}.UserCompaniesList-userCompaniesList-2nEQ1 select:disabled{opacity:.6}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"userCompaniesList": "UserCompaniesList-userCompaniesList-2nEQ1",
	"spinner": "UserCompaniesList-spinner-3tB48"
};
module.exports = exports;
