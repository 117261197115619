// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonSuccess-buttonSuccess-1nn0s{-ms-flex-align:center;-ms-flex-pack:center;align-items:center;background-color:#30b079;border:none;border-radius:2px;color:#fff;cursor:pointer;display:-ms-flexbox;display:flex;font-family:LabGrotesque-Bold,sans-serif;justify-content:center;padding:16px 32px;text-align:center;text-decoration:none;-webkit-transition:background-color .2s ease-in-out;transition:background-color .2s ease-in-out;white-space:nowrap}.ButtonSuccess-spinner-3B_WS{color:#fff}.ButtonSuccess-buttonSuccess-1nn0s:hover{background:#36c286}.ButtonSuccess-buttonSuccess-1nn0s:active{background:#30b079}.ButtonSuccess-buttonSuccess-1nn0s:disabled,.ButtonSuccess-buttonSuccess-1nn0s:disabled:active,.ButtonSuccess-buttonSuccess-1nn0s:disabled:hover{background-color:#a0a0a0;color:#f1f1f1;cursor:default}.ButtonSuccess-buttonSuccess-1nn0s.ButtonSuccess-block-22nCS{width:100%}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"buttonSuccess": "ButtonSuccess-buttonSuccess-1nn0s",
	"spinner": "ButtonSuccess-spinner-3B_WS",
	"block": "ButtonSuccess-block-22nCS"
};
module.exports = exports;
