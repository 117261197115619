import { useCookies } from 'react-cookie';

const useFeatureFlags = () => {
  const [cookies] = useCookies();

  const cookieValue = cookies['sn-features'];
  const features = cookieValue ? cookieValue.split('~') : [];

  const featureFlags = features.reduce((acc, current) => {
    acc[current] = true;
    return acc;
  }, {});

  return featureFlags;
};

export default useFeatureFlags;
