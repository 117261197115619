// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Carousel-carousel-GB6jR{display:grid;margin-left:-2px;margin-right:-2px;position:relative}.Carousel-carousel-GB6jR .Carousel-inner-twm01{overflow:hidden}.Carousel-carousel-GB6jR .Carousel-inner-twm01 .Carousel-innerInner-1dLAF{display:-ms-flexbox;display:flex}.Carousel-carousel-GB6jR .Carousel-inner-twm01 .Carousel-innerInner-1dLAF .Carousel-item-20PYZ{-ms-flex-negative:0;flex-shrink:0;padding:0 2px;width:100%}", ""]);
// Exports
exports.locals = {
	"carousel": "Carousel-carousel-GB6jR",
	"inner": "Carousel-inner-twm01",
	"innerInner": "Carousel-innerInner-1dLAF",
	"item": "Carousel-item-20PYZ"
};
module.exports = exports;
