import React, { useState } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/pro-light-svg-icons';
import { stringToVariant as stringToLabelVariant } from 'components/atoms/Label';
import renderStorylineElement from 'utils/renderStorylineElement';
import getImages from 'utils/getImages';
import LogoSvg from 'assets/svg/SN_Logo.svg';
import getDocumentIcon from 'utils/getDocumentIcon';
import { formatDateWithYear } from 'utils/dateUtil';
import Link from 'containers/atoms/LinkContainer';
import pickFrom from 'utils/pickFrom';
import VCard from 'components/molecules/VCard';
import PressContactCard from 'components/molecules/PressContactCard';
import ProfileCardWithModal from 'components/organisms/ProfileCardWithModal';
import EventBox from 'components/molecules/EventBox';
import LatestArticle from 'components/molecules/LatestArticle';
import boostrapGrid from 'style/bootstrap-grid.css';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import s from './PressRoomPage.css';

const getImage = item => ({
  image: item?.teaserImage?.[0] ?? item?.coverImage?.[0],
  format: item?.teaserImage?.[0] ? 'BASE_LANDSCAPE' : 'BASE_FREEFORM',
  mobileFormat: item?.teaserImage[0] ? 'SQUARE' : 'FREEFORM',
});

const relatedItemsToShow = 6;

const renderPressContactCard = viewport => {
  const classNames = classnames(
    s.infoVcard,
    viewport === 'desktop' && s.desktop,
    viewport === 'mobile' && s.mobile
  );
  return (
    <PressContactCard
      imageComponent={<FontAwesomeIcon icon={faNewspaper} style={{ width: 27, height: 38 }} />}
      name={<Typography variant="h5">Pressjouren</Typography>}
      title="08-553 430 01"
      classes={classNames}
      imageWrapperClasses={s.infoVcardImageWrapper}
    />
  );
};

const renderArticle = article => {
  const classNames = classnames(article?.contentType === 'remiss' ? s.remiss : s.report);
  return (
    <div className={classNames}>
      {article?.title && article?.href && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link to={article?.href} className={s.titleLink}>
          <Typography variant="h5">{article.title}</Typography>
        </Link>
      )}
      {article?.title && !article?.href && <Typography variant="h5">{article.title}</Typography>}
      {article?.document?.downloadLink && (
        <Typography
          classes={s.subtitle}
          component="a"
          target="_blank"
          href={article.document.downloadLink}
          download
        >
          <FontAwesomeIcon
            icon={getDocumentIcon(article.document.fileExtension)}
            className={s.fileIcon}
            style={{ width: 10, height: 14, marginRight: 4 }}
          />
          {article.document.fileExtension.toUpperCase()}
          {article.publishedDate && ` – ${formatDateWithYear(article.publishedDate)}`}
        </Typography>
      )}
    </div>
  );
};

const renderRightColumn = (pressImageProfiles, latestReports, latestRemisses) => {
  return (
    <div className={s.rightCol}>
      <div className={classnames(s.section, s.desktop)}>
        <Typography variant="caption" classes={s.caption}>
          Presskontakt
        </Typography>
        {renderPressContactCard('desktop')}
      </div>

      {pressImageProfiles && (
        <div className={classnames(s.section, s.stretch)}>
          <Typography variant="caption" classes={s.caption}>
            Pressbilder
          </Typography>
          {pressImageProfiles.map(profile => {
            const src = pickFrom(profile, 'images.images.BASE.LANDSCAPE.href_full');
            const alt = pickFrom(profile, 'images.altText');
            const imageSizes = getImages('SQUARE');
            return (
              <ProfileCardWithModal
                key={profile?.id}
                imageSrc={src && imageSizes.toSrc(src, 'S')}
                imageAlt={alt && alt}
                name={<Typography variant="h5">{profile?.name}</Typography>}
                title={profile?.assignment}
                href={profile?.href}
                linkText="Se bilder"
                highResolutionImages={profile?.resolutionImages}
                classes={s.infoVcard}
                imageWrapperClasses={s.infoVcardImageWrapper}
              />
            );
          })}
        </div>
      )}

      <div className={s.buttonWrapper}>
        <ButtonSecondaryGhost classes={s.button} component="a" href="/om_oss/ledamoter/">
          Se fler i styrelsen och ledningen
        </ButtonSecondaryGhost>
        <ButtonSecondaryGhost classes={s.button} component="a" href="/medarbetare/">
          Se alla medarbetare
        </ButtonSecondaryGhost>
      </div>

      <div className={classnames(s.section, s.stretch)}>
        <Typography variant="caption" classes={s.caption}>
          Logotyp och grafisk profil
        </Typography>
        <VCard
          imageComponent={<img src={LogoSvg} width="50" height="50" alt="Svenskt Näringsliv logo" />}
          name={<Typography variant="h5">Logotyp och grafisk profil</Typography>}
          href="/om_oss/grafisk-profil/"
          linkText="Läs mer och ladda ner"
          classes={s.infoVcard}
          imageWrapperClasses={classnames(s.infoVcardImageWrapper, s.logotype)}
        />
      </div>

      {latestReports?.length > 0 && (
        <div className={s.section}>
          <Typography variant="caption" classes={s.caption}>
            Senaste rapporterna
          </Typography>
          {latestReports.slice(0, 3).map(report => (
            <React.Fragment key={report.id}>{renderArticle(report)}</React.Fragment>
          ))}
          <Link variant="linkSmall" to="/material/?contenttype=report" className={s.link}>
            Se alla rapporter
          </Link>
        </div>
      )}

      {latestRemisses?.length > 0 && (
        <div className={s.section}>
          <Typography variant="caption" classes={s.caption}>
            Senaste remissvaren
          </Typography>
          {latestRemisses.slice(0, 3).map(remiss => (
            <React.Fragment key={remiss.id}>{renderArticle(remiss)}</React.Fragment>
          ))}
          <Link variant="linkSmall" to="/material/?contenttype=remiss" className={s.link}>
            Se alla remissvar
          </Link>
        </div>
      )}
    </div>
  );
};

const PressRoomPage = ({
  upcomingEvents,
  pressImageProfiles,
  pressReleases,
  latestReports,
  latestRemisses,
  groupedStorylineTree,
}) => {
  const [latestToShow, setLatestToShow] = useState(relatedItemsToShow);
  return (
    <div className={s.pressRoomPage}>
      <div className={classnames(boostrapGrid.container)}>
        <div className={classnames(boostrapGrid.row)}>
          <div className={getBootstrapColumns('22,1', '13,1', '13,1')}>
            <div className={s.leftCol}>
              {groupedStorylineTree.headGroup &&
                groupedStorylineTree.headGroup.map(el => renderStorylineElement(el, false))}
              {groupedStorylineTree.contentGroup &&
                groupedStorylineTree.contentGroup.map(el => renderStorylineElement(el, false))}
              {renderPressContactCard('mobile')}
              {upcomingEvents?.length > 0 &&
                upcomingEvents.map(event => {
                  return (
                    <EventBox
                      key={event?.id}
                      classes={s.eventBox}
                      href={event.href}
                      contentType={event?.fields?.eventType}
                      hideTimeInformation={event?.fields?.hideTimeInformation === 'true'}
                      headline={event?.title}
                      from={event?.fields?.startDate && new Date(event?.fields?.startDate)}
                      to={event?.fields?.endDate && new Date(event?.fields?.endDate)}
                      location={event?.fields?.eventTown}
                      implementationType={event?.fields?.implementationType}
                      deadlineDate={event?.fields?.deadlineDate && new Date(event?.fields?.deadlineDate)}
                      labelVariant={stringToLabelVariant(event?.fields?.eventStatus)}
                    />
                  );
                })}
              {pressReleases?.length > 0 &&
                pressReleases
                  .slice(0, latestToShow)
                  .map(({ id, contentType, title, summery, date, href, images, ...rest }) => {
                    const { image, format, mobileFormat } = getImage(rest);

                    const imageHref = image?.content?.fields?.[format]?.href_full;
                    const imageAlt = image?.content?.fields?.alttext;
                    const imageWidth =
                      format === 'BASE_LANDSCAPE'
                        ? image?.content?.fields?.[format]?.sourceWidth ||
                          image?.content?.fields?.[format]?.width
                        : image?.content?.originalPictureSize?.width;
                    const imageHeight =
                      format === 'BASE_LANDSCAPE'
                        ? image?.content?.fields?.[format]?.sourceHeight ||
                          image?.content?.fields?.[format]?.height
                        : image?.content?.originalPictureSize?.height;
                    const sizes = getImages(format.slice('BASE_'.length));
                    const sizesMobile = getImages(mobileFormat);

                    return (
                      <LatestArticle
                        key={id}
                        href={href}
                        articleType={contentType}
                        headline={title}
                        day={date}
                        text={summery}
                        imageSrc={imageHref && sizes.toSrc(imageHref, 'M')}
                        imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
                        imageSizes="190px"
                        imageMobileSrc={imageHref && sizes.toSrc(imageHref, 'XS')}
                        imageMobileSrcSet={imageHref && sizesMobile.toSrcSet(imageHref)}
                        imageMobileSizes="90px"
                        imageAlt={imageAlt}
                        imageWidth={imageWidth}
                        imageHeight={imageHeight}
                      />
                    );
                  })}
              {pressReleases.length > latestToShow && (
                <ButtonSecondaryGhost
                  key={`show-more-${latestToShow}`}
                  classes={s.showMoreArticles}
                  onClick={() => setLatestToShow(latestToShow + relatedItemsToShow)}
                >
                  Visa fler
                </ButtonSecondaryGhost>
              )}
            </div>
          </div>
          <div className={getBootstrapColumns(null, '9,1', '8,2')}>
            <div className={s.rightColDesktop}>
              <div className={s.rightColBg}>
                {renderRightColumn(pressImageProfiles, latestReports, latestRemisses)}
              </div>
            </div>
          </div>
        </div>
        <div className={s.rightColMobile}>
          <div className={s.rightColBg}>
            <div className={classnames(boostrapGrid.row)}>
              <div className={getBootstrapColumns('22,1')}>
                {renderRightColumn(pressImageProfiles, latestReports, latestRemisses)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(PressRoomPage);
