/* eslint-disable react/no-array-index-key */
import React from 'react';
import SrOnly from 'components/atoms/SrOnly/SrOnly';
import withStyles from 'isomorphic-style-loader/withStyles';
import { motion } from 'framer-motion';
import s from './DotNav.css';

const DotNav = ({ length, currentIndex, navigateTo, darkMode }) => {
  return (
    <div className={s.dotNav}>
      {Array.from({ length }).map((_, i) => {
        const isActive = i === currentIndex;
        const activeBgColor = '#ea8f12';
        const inactiveBgColor = darkMode ? '#fff' : '#aaa';

        return (
          <button key={i} type="button" onClick={() => navigateTo(i)}>
            <motion.span
              className={s.dot}
              animate={{
                opacity: isActive ? 1 : 0.8,
                backgroundColor: isActive ? activeBgColor : inactiveBgColor,
                scale: isActive ? 1.5 : 1,
              }}
              transition={{ duration: 0.3 }}
            />
            <SrOnly>Sida {i + 1}</SrOnly>
          </button>
        );
      })}
    </div>
  );
};

export default withStyles(s)(DotNav);
