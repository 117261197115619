import { connect } from 'react-redux';
import SubscriptionPage from 'components/pages/SubscriptionPage';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getNewsletters from 'selectors/getNewsletters';

const mapStateToProps = state => {
  return {
    groupedStorylineTree: getGroupedStorylineTree(state),
    newsletters: getNewsletters(state),
  };
};

const mapDispatchToProps = undefined;

const SubscriptionPageContainer = connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);

export default SubscriptionPageContainer;
