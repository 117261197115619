import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Card.css';

const Card = ({ classes, noPadding, children, ...other }) => {
  const classNames = classnames(s.card, classes, !noPadding && s.padding);
  return (
    <div className={classNames} {...other}>
      {children}
    </div>
  );
};

export default withStyles(s)(Card);
