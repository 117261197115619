// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TabletPortraitFooter-tabletPortraitFooter-2JlUK{display:none;padding:56px}.TabletPortraitFooter-tabletPortraitFooter-2JlUK .TabletPortraitFooter-lowerContentSocial-20uER{margin-top:25px}.TabletPortraitFooter-tabletPortraitFooter-2JlUK .TabletPortraitFooter-lowerContent-296tq{margin-top:20px}.TabletPortraitFooter-tabletPortraitFooter-2JlUK .TabletPortraitFooter-moreNewsRow-1MMV1{margin-top:30px}.TabletPortraitFooter-tabletPortraitFooter-2JlUK .TabletPortraitFooter-lowerContentPublisher-3i7lb{margin-top:6px}@media (min-width:541px){.TabletPortraitFooter-tabletPortraitFooter-2JlUK{display:block}}@media (min-width:820px){.TabletPortraitFooter-tabletPortraitFooter-2JlUK{display:none}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"tabletPortraitFooter": "TabletPortraitFooter-tabletPortraitFooter-2JlUK",
	"lowerContentSocial": "TabletPortraitFooter-lowerContentSocial-20uER",
	"lowerContent": "TabletPortraitFooter-lowerContent-296tq",
	"moreNewsRow": "TabletPortraitFooter-moreNewsRow-1MMV1",
	"lowerContentPublisher": "TabletPortraitFooter-lowerContentPublisher-3i7lb"
};
module.exports = exports;
