const SN_DOMAINS = [
  'www.svensktnaringsliv.se',
  'stage-www.svensktnaringsliv.net',
  'test-www.svensktnaringsliv.net',
  'prod-svensktnaringsliv.azurewebsites.net',
  'stage-svensktnaringsliv.azurewebsites.net',
  'test-svensktnaringsliv.azurewebsites.net',
  'localhost',
];

const getUrlIfConditionMatches = (url, condition) => {
  try {
    const isAlreadyUrl = typeof urlOrPath === 'object' && url.host;
    if (isAlreadyUrl || /^https?:\/\//.test(url)) {
      const tempUrl = isAlreadyUrl ? url : new URL(url);
      if (condition(tempUrl)) {
        return tempUrl;
      }
    }
  } catch (error) {
    console.error('Could not process url:', url);
    console.error(error);
  }
  return undefined;
};

export const getUrlIfSnDomain = url => {
  return getUrlIfConditionMatches(url, u => SN_DOMAINS.includes(u.host));
};

export const getUrlIfExternal = url => {
  return getUrlIfConditionMatches(url, u => !SN_DOMAINS.includes(u.host));
};

export const isExternalUrl = url => {
  return !!getUrlIfExternal(url);
};
