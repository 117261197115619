import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import RelatedItem from 'components/molecules/RelatedItem';
import Translate from '../../../containers/atoms/TranslateContainer';
import s from './RelatedContent.css';

const RelatedContent = ({ classes, items = [], ...other }) => {
  const classNames = classnames(s.relatedContent, classes);
  return (
    <div className={classNames} {...other}>
      <Typography variant="caption">
        <Translate>RELATED_CONTENT.FURTHER_READING</Translate>
      </Typography>
      <div className={s.itemsWrapper}>
        {items.map((item, index) => (
          <RelatedItem key={item.primaryLink} noBorder={index === 0} data={item} />
        ))}
      </div>
    </div>
  );
};

export default withStyles(s)(RelatedContent);
