// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Checkbox-checkbox-2sodi,.Checkbox-label-2zHhi{cursor:pointer}.Checkbox-checkbox-2sodi{opacity:0;position:absolute}.Checkbox-checkbox-2sodi+label{display:block;padding-left:30px;position:relative}label.Checkbox-gutterBottom-3LEmD{margin-bottom:20px}.Checkbox-checkbox-2sodi+label:before{border:1px solid #cfd4d8;border-radius:2px;content:\"\";display:inline-block;height:21px;left:0;margin-right:10px;position:absolute;top:3px;vertical-align:text-top;width:21px}.Checkbox-checkbox-2sodi:checked+label:before{background-image:url(/check.svg);background-position:50%;background-repeat:no-repeat;background-size:14px;border:1px solid #30b079}.Checkbox-checkbox-2sodi:active+label:before,.Checkbox-checkbox-2sodi:focus+label:before{border:1px solid #33414e}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"checkbox": "Checkbox-checkbox-2sodi",
	"label": "Checkbox-label-2zHhi",
	"gutterBottom": "Checkbox-gutterBottom-3LEmD"
};
module.exports = exports;
