import { createSelector } from 'reselect';
import { faBullhorn, faCommentAlt, faLifeRing } from '@fortawesome/pro-light-svg-icons';

export default createSelector(() => {
  return {
    heroBox: {
      headline: 'Välkommen till Svenskt Näringsliv',
      preamble:
        'Som medlem i Svenskt Näringsliv står ditt företag starkare. Du kan ge dina medarbetare bra villkor och försäkringar. Du får råd, kontakter och trygghet i konflikter genom din branschorganisation. Tillsammans blir vår röst starkare för näringslivets intressen.',
      author: 'Jan-Olof Jacke, vd',
    },

    leftMenu: {
      enabled: false,
      title: 'Medlemskap',
      items: [
        {
          id: 0,
          linkText: 'Bli medlem',
          href: '/medlem/intresseanmalan/',
        },
        {
          id: 1,
          linkText: 'Lönestatistik',
          href: '/sakomraden/lonestatistik/',
        },
        {
          id: 2,
          linkText: 'Lönerapportering',
          href: '/sakomraden/lonestatistik/lonerapportering-lonestatistik_1147444.html',
        },
        {
          id: 3,
          linkText: 'Medlemsavgift',
          href: '/medlem/medlemsavgiften-till-svenskt-naringsliv_1001582.html',
        },
        {
          id: 4,
          linkText: 'Medlemsorganisationer',
          href: '/medlemsorganisationer/',
        },
      ],
    },

    stories: [
      {
        id: 0,
        image: {
          href: '/medlem/images/paverkan_1143403.html/alternates/BASE_LANDSCAPE/paverkan.jpg',
          alttext: 'Påverkan',
        },
        title: 'Påverkan',
        icon: faBullhorn,
        infoTexts: [
          { id: 0, text: 'Vi finns i 21 regioner och påverkar beslutsfattare över hela landet.' },
          { id: 1, text: 'Våra experter driver ett aktivt påverkansarbete mot riksdag, regering och EU.' },
          { id: 2, text: 'Genom vårt kontor i Bryssel bevakar och påverkar vi beslut som fattas inom EU.' },
        ],
        link: '/medlem/paverkan/',
      },
      {
        id: 1,
        image: {
          href: '/medlem/images/samverkan_1143404.html/alternates/BASE_LANDSCAPE/samverkan.jpg',
          alttext: 'Samverkan',
        },
        title: 'Samverkan',
        icon: faCommentAlt,
        infoTexts: [
          { id: 0, text: 'Svenskt Näringsliv förhandlar villkoren i de kollektivavtalade försäkringarna' },
          {
            id: 1,
            text: 'Syftet med vår samordning i avtalsrörelser är att stärka företagens konkurrenskraft.',
          },
          { id: 2, text: 'Möjlighet till skadeersättning vid fackliga stridsåtgärder' },
        ],
        link: '/medlem/samverkan/',
      },
      {
        id: 2,
        image: {
          href: '/medlem/images/forsakringar_1143405.html/alternates/BASE_LANDSCAPE/medlem.jpg',
          alttext: 'Försäkringar',
        },
        title: 'Försäkringar',
        icon: faLifeRing,
        infoTexts: [
          {
            id: 0,
            text: 'Branschanpassade kollektivavtal ger tillgång till förmånliga försäkringslösningar',
          },
          { id: 1, text: 'Medlemskap ger rabatt på försäkringspremien hos Fora och Collectum.' },
          { id: 2, text: 'Ökad trygghet för dina medarbetare vid behov av omställning' },
        ],
        link: '/medlem/forsakringar/',
      },
    ],

    keyFigures: {
      headline: 'Som medlemsföretag i Svenskt Näringsliv gör du din röst hörd',
      texts: [
        {
          id: 0,
          text: '50 arbetsgivar- och branschorganisationer har valt att vara en del av Svenskt Näringsliv. Tillsammans samlar vi 60 000 medlemsföretag med tillsammans nästan 2 miljoner medarbetare. Det ger oss en unik bredd och kraft i arbetet med att stärka konkurrenskraften för företagen.',
        },
        {
          id: 1,
          text: 'Varje medlemsorganisation erbjuder företag medlemskap med tillgång till branschanpassade kollektivavtal och/eller branschspecifik påverkan inom det egna området. Svenskt Näringsliv ingår som en del av medlemskapet.',
        },
      ],
    },

    memberBox: {
      headline: 'Bli medlem',
      texts: [
        {
          id: 0,
          text: 'Branschanpassade kollektivavtal gör det lätt att göra rätt i förhållandet till dina medarbetare',
        },
        {
          id: 1,
          text: 'Tillgång till mycket förmånliga pensionslösningar och försäkringar för alla medarbetare',
        },
        {
          id: 2,
          text: 'Möjlighet att vara med och påverka företagsklimatet i din kommun och landet som helhet',
        },
        {
          id: 3,
          text: 'Dialoger med beslutsfattare både lokalt och nationellt via våra nätverk och mötesplatser',
        },
      ],
    },

    basicInfo: {
      sections: [
        {
          id: 0,
          headline: 'Våra regioner',
          text: 'Genom vår närvaro i 21 regioner har vi nära till våra medlemsföretag och kan erbjuda medlemsnytta. Närvaron lokalt ger oss bättre förutsättningar att arbeta närmare politiker, beslutsfattare och media för att påverka och bilda opinion i hela Sverige.',
          link: {
            linkText: 'Hitta ditt regionkontor',
            internalHref: '/regioner/',
            externalHref: undefined,
          },
        },
        {
          id: 1,
          headline: 'Vårt EU-kontor',
          text: 'Genom vår närvaro i Bryssel bevakar och påverkar vi beslutsfattandet inom EU. Vi arbetar också för ett konkurrenskraftigt Europa genom BusinessEurope – vår motsvarighet på EU-nivå och Europas ledande näringslivsorganisation.',
          link: {
            linkText: 'Läs mer',
            internalHref: '/eu-kontoret/',
            externalHref: undefined,
          },
        },
        {
          id: 2,
          headline: 'Lokalt företagsklimat',
          text: 'Det lokala företagsklimatet ges en plats i debatten genom att vi varje år låter drygt 30 000 företagare i Sverige ge sina synpunkter på företagsklimatet i sin kommun. Resultatet av undersökningen och rankingen skapar förutsättningar för dialog och utveckling i Sveriges alla 290 kommuner.',
          link: {
            linkText: 'Läs mer på foretagsklimat.se',
            internalHref: undefined,
            externalHref: 'https://www.foretagsklimat.se/',
          },
        },
        {
          id: 3,
          headline: 'Företag skapar Sveriges välfärd',
          text: 'Vår gemensamma välfärd vilar på företagare och deras medarbetare. Välfärdsskaparna handlar om att fler ska förstå företagens betydelse för välfärden. Genom att räkna ut ditt företags välfärdsskapande kan du vara med och sprida sambandet mellan företagande och välfärd via sociala medier eller direkt till dina medarbetare.',
          link: {
            linkText: 'Läs mer på valfardsskaparna.se',
            internalHref: undefined,
            externalHref: 'https://valfardsskaparna.se/',
          },
        },
        {
          id: 4,
          headline: 'Avtalat',
          text: 'Avtalat är namnet på det nya bolag som Svenskt Näringsliv tillsammans med LO och PTK har bildat. Avtalats uppgift är att utan kostnad ge råd och information om kollektivavtalad tjänstepension och försäkring till privata företag med kollektivavtal och deras anställda.',
          link: {
            linkText: 'Läs mer på avtalat.se',
            internalHref: undefined,
            externalHref: 'https://www.avtalat.se',
          },
        },
      ],
    },
    fPlus: {
      sections: [
        {
          id: 0,
          logoRight: true,
          headline: 'Tidningen Näringslivet',
          text: 'Tidningen Näringslivet är Svenskt Näringslivs digitala nyhetstjänst för alla som är intresserade av företagande, ekonomi, arbetsmarknad och näringspolitik.',
          link: {
            linkText: 'Besök Tidningen Näringslivet',
            internalHref: undefined,
            externalHref: 'https://www.tn.se',
          },
        },
        {
          id: 1,
          logoRight: false,
          headline: 'Dagliga nyhetsbrev',
          text: 'Med vårt nyhetsbrev Nytt från Svenskt Näringsliv följer du debatten om näringslivets förutsättningar och läser mer om lösningar och möjligheter.',
          link: {
            linkText: 'Anmäl dig till våra nyhetsbrev',
            internalHref: '/nyhetsbrev/',
            externalHref: undefined,
          },
        },
        {
          id: 2,
          logoRight: false,
          headline: 'Lönerapportering',
          text: 'Tillsammans hämtar Svenskt Näringsliv och medlemsorganisationerna årligen in lönestatistik. Denna statistik har alla medlemsföretag tillgång till och den kan användas för att underlätta arbetet vid nyanställning, lönesamtal eller i kontakter med fackliga organisationer. Statistiken är bred och kvalitetssäkrad och finns fördelad på yrken, regioner och branscher via ett webbaserat verktyg tillgänglig alla dagar hela året. Ett stöd för dig som arbetsgivare.',
          link: {
            linkText: 'Rapportera lönestatistik',
            internalHref: '/sakomraden/lonestatistik/lonerapportering-lonestatistik_1147444.html',
            externalHref: undefined,
          },
        },
      ],
    },
  };
});
