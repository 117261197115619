import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import getPageId from 'selectors/getPageId';
import getEventStartAndEndDate from 'selectors/getEventStartAndEndDate';
import getVimeoData from 'selectors/getVimeoData';
import getImages from '../utils/getImages';

const getLiveEventForArticleSelector = createSelector(
  cookSelectors.getData,
  locationSelectors.getPathname,
  locationSelectors.getHostname,
  getPageId,
  getEventStartAndEndDate,
  getVimeoData,
  cookSelectors.getEdited,
  (cookData, pathname, hostname, pageId, eventStartAndEndDateISO, vimeoData, articleLastModifiedCook) => {
    // Polling endpoint url
    const url = new URL(hostname + pathname);

    url.searchParams.append('jsonData', 'freshnessStatus');
    const pollingEndpointUrl = url.href;

    const backgroundImageSrc =
      cookData?.context?.videoBackgroundImage?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full;
    const backgroundImage = backgroundImageSrc
      ? getImages('LANDSCAPE').toSrc(backgroundImageSrc, 'L')
      : undefined;

    const { vimeoUrl, chatUrl } = vimeoData;

    const isVimeoTest = !!cookData?.context?.fields?.isVimeoTestEvent;

    return {
      pollingEndpointUrl,
      pageId,
      eventStartDateISO: eventStartAndEndDateISO.startDate,
      eventEndDateISO: eventStartAndEndDateISO.endDate,
      backgroundImage,
      vimeoUrl,
      chatUrl,
      showChatStoryElementSetting: 'inherit',
      articleLastModifiedCook,
      isVimeoTest,
    };
  }
);

const getLiveEventForStorylineElementSelector = element => {
  return createSelector(() => {
    const relationData = element?.children?.[0]?.relation;
    const articleLastModifiedCook = relationData?.edited;

    const pollingEndpointUrl = `${relationData.href}?jsonData=freshnessStatus`;
    const pageId = relationData.id;
    const eventStartDateISO = relationData.fields.startDate;
    const eventEndDateISO = relationData.fields.endDate;

    const showChatStoryElementSetting = element?.fields?.showVimeoChat?.rawText || 'inherit';

    const backgroundImageSrc =
      relationData?.videoBackgroundImage?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full;
    const backgroundImage = backgroundImageSrc
      ? getImages('LANDSCAPE').toSrc(backgroundImageSrc, 'L')
      : undefined;

    const { vimeoVideoUrl, vimeoLiveEventUrl } = relationData.fields;

    let vimeoUrl;
    if (vimeoVideoUrl) {
      const vimeoId = vimeoVideoUrl?.split('/').pop();
      vimeoUrl = `https://player.vimeo.com/video/${vimeoId}`;
    } else if (vimeoLiveEventUrl) {
      vimeoUrl = `${vimeoLiveEventUrl}/embed`;
    }

    // Removes the protocol from the url
    vimeoUrl = vimeoUrl?.replace(/https?:/, '') || '';

    const vimeoEventId = vimeoLiveEventUrl?.split('/').pop(); // id for chat url
    const chatUrl = vimeoEventId ? `//vimeo.com/event/${vimeoEventId}/chat` : undefined;

    return {
      pollingEndpointUrl,
      pageId,
      eventStartDateISO,
      eventEndDateISO,
      backgroundImage,
      vimeoUrl,
      chatUrl,
      showChatStoryElementSetting,
      articleLastModifiedCook,
      isVimeoTest: false, // This can only be checked on live event articles and not in a storyline element
    };
  });
};

export default (isStorylineElement, element) => {
  if (isStorylineElement) {
    return getLiveEventForStorylineElementSelector(element);
  }
  return getLiveEventForArticleSelector;
};
