// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InDepthList-inDepthList-3Z1mz{list-style-type:none;margin:auto;max-width:1065px;padding:0}.InDepthList-layoutTypeGrid-3xrnr{grid-column-gap:1em;-webkit-column-gap:1em;-moz-column-gap:1em;column-gap:1em;display:grid;grid-template-columns:repeat(auto-fit,minmax(300px,1fr))}@media (min-width:1169px){.InDepthList-layoutTypeColumns-3R9kt{-webkit-column-count:2;-moz-column-count:2;column-count:2}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"inDepthList": "InDepthList-inDepthList-3Z1mz",
	"layoutTypeGrid": "InDepthList-layoutTypeGrid-3xrnr",
	"layoutTypeColumns": "InDepthList-layoutTypeColumns-3R9kt"
};
module.exports = exports;
