/* eslint-disable import/no-import-module-exports */
import 'isomorphic-unfetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Hydrate, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CookiesProvider } from 'react-cookie';
import AppplicationContainer from 'containers/AppplicationContainer';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import configureStore from './store/configureStore';
import { sentryInitClient } from './utils/sentryLogger';
import { ClientDebugLogger, noopDebugLogger } from './utils/debugLogger';
import { msalConfig } from './utils/authConfig';
import getCurrentEnv from './utils/getCurrentEnv';
import queryClient from './store/queryClient';
import CookDataProvider from './providers/CookDataProvider';
import { LoginLoader } from './components/organisms/LoginLoader/LoginLoader';
import pushToDataLayer from './utils/pushToDataLayer';

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback(event => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS
  ) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

// Handle the redirect flows
msalInstance
  .handleRedirectPromise()
  .then(response => {
    if (response?.account?.idTokenClaims?.sub) {
      pushToDataLayer('login', {
        userId: response?.account?.idTokenClaims?.sub,
      });
    }
    if (response?.authority) {
      msalInstance.ssoSilent({ authority: response.authority });
    }
  })
  .catch(err => {
    console.info('handleRedirectFlowError');
    console.error(err);
  });

sentryInitClient();

const currentEnv = getCurrentEnv();

// Set logger to the window object so it's reachable from the browsers console
window.logger =
  currentEnv === 'production'
    ? noopDebugLogger
    : new ClientDebugLogger({
        availableFeatures: ['fetchers', 'testFeature', 'sentryLogger'],
        environments: {
          localhost: true,
          stage: true,
        },
      });

const SHOW_REACT_QUERY_DEVTOOLS = true;

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

// Enables critical path CSS rendering
// https://github.com/kriasoft/isomorphic-style-loader
const insertCss = (...styles) => {
  // eslint-disable-next-line no-underscore-dangle
  const removeCss = styles.map(x => x._insertCss());
  return () => {
    removeCss.forEach(f => f());
  };
};

const store = configureStore(window.App.state);

// eslint-disable-next-line no-underscore-dangle
const dehydratedState = window.__REACT_QUERY_STATE__;

if (window.location.pathname === '/logincompleted') {
  ReactDOM.hydrate(<LoginLoader />, document.getElementById('app'), () => {
    const elem = document.getElementById('css');
    if (elem) elem.parentNode.removeChild(elem);
  });
} else {
  ReactDOM.hydrate(
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        <MsalProvider instance={msalInstance}>
          <CookiesProvider>
            <Provider store={store}>
              <CookDataProvider>
                <AppplicationContainer insertCss={insertCss} />
                {SHOW_REACT_QUERY_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} />}
              </CookDataProvider>
            </Provider>
          </CookiesProvider>
        </MsalProvider>
      </Hydrate>
    </QueryClientProvider>,
    document.getElementById('app'),
    () => {
      const elem = document.getElementById('css');
      if (elem) elem.parentNode.removeChild(elem);
    }
  );
}

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept();
}
