import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ImageNumber.css';

const ImageNumber = ({ currentImageNumber, numberOfImages }) => {
  return (
    <span className={classnames(s.container)}>
      {currentImageNumber}/{numberOfImages}
    </span>
  );
};

export default withStyles(s)(ImageNumber);
