const parseVideoChat = (url = '') => {
  let currentType;
  let oneTimeVideoChatId = '';
  let oneTimePrivateLink = '';
  let recurringVideoChatId = '';
  let recurringPrivateLink = '';
  if (url.includes('event')) {
    currentType = 'recurring';
    const parseUrl = url.split('event');
    const parseUrlPart = parseUrl[1];
    if (parseUrlPart.includes('/')) {
      const parseId = parseUrlPart.split('/');
      recurringVideoChatId = parseId[1];
      if (parseId[2] !== undefined) {
        recurringPrivateLink = parseId[2];
      }
    }
  } else {
    currentType = 'onetime';
    if (url.includes('/')) {
      const parseId = url.split('/');
      oneTimeVideoChatId = parseId[3];
      if (parseId[4] !== undefined) {
        oneTimePrivateLink = parseId[4];
      }
    }
  }

  return {
    type: currentType,
    onetimevideochatid: oneTimeVideoChatId,
    onetimeprivatelink: oneTimePrivateLink,
    recurringvideochatid: recurringVideoChatId,
    recurringprivatelink: recurringPrivateLink,
  };
};

export default parseVideoChat;
