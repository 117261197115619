import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import renderStorylineElement from 'utils/renderStorylineElement';
import Link from 'containers/atoms/LinkContainer';
import DarkMode from 'components/templates/DarkMode';
import getImages from 'utils/getImages';
import FacebookNumbers from 'components/molecules/FacebookNumbers';
import QuoteBox from 'components/molecules/QuoteBox/QuoteBox';
import VCard from 'components/molecules/VCard';
import Typography from 'components/atoms/Typography';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import boostrapGrid from 'style/bootstrap-grid.css';
import HighlightedArticle from 'components/molecules/HighlightedArticleLarge';
import FocusAreaList from 'components/organisms/FocusAreaList';
import TnlvFeed from 'components/organisms/TnlvFeed';
import generateSizes from 'utils/generateSizes';
import pickFrom from 'utils/pickFrom';
import exists from 'utils/exists';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import OGShareTags from 'components/molecules/OGShareTags';
import OtherSitesList from './components/OtherSitesList';
import useTranslation from '../../../utils/hooks/useTranslation';
import EventsWebinars from '../../organisms/EventsWebinars/EventsWebinars';
import LatestReports from '../../organisms/LatestReports/LatestReports';
import { getFlexibleSection, getMetadata } from '../FlexibleSection/duck/selectors';
import Carousel from '../../organisms/Carousel/Carousel';
import s from './HomePage.css';
import MatrixListWithTextOnImage from '../../organisms/MatrixListWithTextOnImage/MatrixListWithTextOnImage';
import FlexibleContent from '../FlexibleSection/components/FlexibleContent/FlexibleContent';

const HomePage = ({
  groupedStorylineTree,
  darkModeType,
  selectedEvents,
  latestWebinars,
  latestAndSelectedReports,
  focusAreaListItems,
  heroImage,
  showSpecial,
  topStories,
  quoteSlideshowData,
  expertAreaData,
  expertOpinionHeadline,
  keyFiguresData,
  puffzData,
  facebookCount,
}) => {
  const translation = useTranslation();
  const metadata = useSelector(getMetadata);
  const flexibleSectionHeroAvsnitt = useSelector(getFlexibleSection('hero'));
  const homePageClasses = showSpecial ? classnames(s.homePage, s.heroSpecialTopMargin) : s.homePage;

  return (
    <>
      <OGShareTags metadata={metadata} />
      {showSpecial &&
        flexibleSectionHeroAvsnitt?.length > 0 &&
        flexibleSectionHeroAvsnitt.map((avsnitt, i) => (
          <FlexibleContent
            key={avsnitt?.id}
            avsnitt={avsnitt}
            zIndex={flexibleSectionHeroAvsnitt.length - i}
          />
        ))}
      <div className={homePageClasses}>
        {!showSpecial && (
          <DarkMode
            darkMode
            darkModeType={darkModeType}
            imageHref={heroImage && heroImage.href}
            offset="xlarge"
          >
            <div className={classnames(boostrapGrid.container)}>
              <div className={classnames(boostrapGrid.row)}>
                <div className={getBootstrapColumns('22,1', '20,2', '18,3')}>
                  <div className={s.contactList}>
                    {groupedStorylineTree.headGroup &&
                      groupedStorylineTree.headGroup.map(el => renderStorylineElement(el, true))}
                    {groupedStorylineTree.contentGroup &&
                      groupedStorylineTree.contentGroup.map(el => renderStorylineElement(el, true))}
                  </div>
                </div>
              </div>
            </div>
          </DarkMode>
        )}
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(boostrapGrid.row)}>
            <div className={classnames(getBootstrapColumns('22,1', '16,4'), s.topStoryContainer)}>
              <div className={s.topStories}>
                <div className={classnames(boostrapGrid.row)}>
                  {topStories.map((story, idx) => {
                    const small = idx !== 0;
                    const imageHref = pickFrom(story, 'images.images.BASE.LANDSCAPE.href_full');
                    const imageWidth = pickFrom(story, 'images.images.BASE.LANDSCAPE.width');
                    const imageHeight = pickFrom(story, 'images.images.BASE.LANDSCAPE.height');
                    const sizes = getImages('LANDSCAPE');
                    const imageSizes = small
                      ? generateSizes({ xl: '412px', md: '31vw', sm: 'calc(50vw - 68px)', default: '100vw' })
                      : generateSizes({
                          xl: '848px',
                          md: '68vw',
                          sm: 'calc(100vw - 112px)',
                          default: '100vw',
                        });
                    const eventType = story?.fields?.eventType === undefined ? '' : story?.fields?.eventType;
                    const event = story.contentType === 'event' ? eventType : story.contentType;
                    return (
                      <div
                        key={story.id}
                        className={getBootstrapColumns('24', '24', idx === 0 ? '24' : '12')}
                      >
                        <HighlightedArticle
                          id={story.id}
                          href={story.href}
                          imageSrc={
                            imageHref && (small ? sizes.toSrc(imageHref, 'M') : sizes.toSrc(imageHref, 'L'))
                          }
                          imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
                          imageWidth={imageWidth}
                          imageHeight={imageHeight}
                          small={small}
                          imageSizes={imageSizes}
                          contentType={event}
                          headline={story?.fields?.teaserTitle || story.title}
                          text={story?.fields?.teaser}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {translation.IS_ENGLISH && (
          <div className={classnames(boostrapGrid.container)}>
            <div className={classnames(boostrapGrid.row)}>
              <div className={getBootstrapColumns('22,1', '14,5', '12,6')}>
                <ButtonSecondaryGhost classes={s.showMoreEvents} component={Link} to="/english/news/">
                  News Archive
                </ButtonSecondaryGhost>
              </div>
            </div>
          </div>
        )}
        {expertAreaData?.length > 0 && (
          <div className={s.expertArea}>
            <div className={classnames(boostrapGrid.container)}>
              <div className={classnames(boostrapGrid.row)}>
                <div className={getBootstrapColumns('18,3', '16,4')}>
                  <Typography variant="contentTypeDate" classes={s.caption}>
                    {exists(expertOpinionHeadline) ? expertOpinionHeadline : 'Experten tycker'}
                  </Typography>
                  <Carousel
                    items={expertAreaData.map(expert => {
                      const imageHref = pickFrom(expert, 'images.images.BASE.LANDSCAPE.href_full');
                      const sizes = getImages('SQUARE');
                      return (
                        <div key={expert.id} className={s.expertSlide}>
                          <VCard
                            classes={s.vCard}
                            imageWrapperClasses={s.vCardImageWrapper}
                            imageSrc={imageHref && sizes.toSrc(imageHref, 'S')}
                            imageAlt={pickFrom(expert, 'images.altText', 'name')}
                            imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
                            titleLink={expert.href}
                            name={
                              <Typography variant="contentTypeDate" classes={s.expertName}>
                                {expert.name}
                              </Typography>
                            }
                            title={<Typography variant="h2">{expert.title}</Typography>}
                          />
                        </div>
                      );
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <LatestReports latestAndSelectedReports={latestAndSelectedReports} />

        <EventsWebinars selectedEvents={selectedEvents} latestWebinars={latestWebinars} darkBackground />
        {quoteSlideshowData?.length > 0 && (
          <div className={s.quoteArea}>
            <div className={classnames(boostrapGrid.container)}>
              <div className={classnames(boostrapGrid.row)}>
                <div className={getBootstrapColumns('18,3', '20,2', '16,4')}>
                  <div className={s.quoteBoxWrapper}>
                    <Typography variant="h2" className={s.quoteBoxHeadline} gutterTop>
                      Därför Svenskt Näringsliv
                    </Typography>
                    {quoteSlideshowData && (
                      <Carousel
                        items={quoteSlideshowData.map(quote => {
                          const imageHref = pickFrom(quote, 'images.images.BASE.LANDSCAPE.href_full');
                          const target = quote.internalUrl ? '_self' : '_blank';

                          return (
                            <QuoteBox
                              imageSrc={imageHref && imageHref}
                              imageAlt={pickFrom(quote, 'images.altText', 'name')}
                              quote={quote.quote}
                              linkText={quote.linkText}
                              internalUrl={quote.internalUrl}
                              externalUrl={quote.externalUrl}
                              target={target}
                              inSlideshow
                              offset
                              key={quote.id}
                            />
                          );
                        })}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {keyFiguresData?.length > 0 && (
          <div className={s.keyFigureContainer}>
            <div className={s.keyFigures}>
              {keyFiguresData?.map(obj => (
                <div className={s.keyFigure}>
                  <div className={s.number}>{obj.number.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')}</div>
                  <div className={s.text}>{obj.text}</div>
                </div>
              ))}
            </div>
            <div className={s.buttonWrapper}>
              <ButtonPrimaryAlt component={Link} to="/medlem/">
                Läs mer om medlemskap i Svenskt Näringsliv
              </ButtonPrimaryAlt>
            </div>
          </div>
        )}
        {puffzData?.length > 0 && (
          <div className={s.matrixListContainer}>
            <div className={s.inner}>
              <MatrixListWithTextOnImage articles={puffzData} />
            </div>
          </div>
        )}
        {translation.IS_SWEDISH && (
          <div className={s.tnlv}>
            <div className={classnames(boostrapGrid.container)}>
              <div className={classnames(boostrapGrid.row)}>
                <div className={getBootstrapColumns('22,1', '14,5', '12,6')}>
                  <TnlvFeed />
                </div>
              </div>
            </div>
          </div>
        )}
        {translation.IS_SWEDISH && exists(facebookCount) && (
          <div className={s.facebookContainer}>
            <div className={classnames(boostrapGrid.container)}>
              <div className={classnames(boostrapGrid.row)}>
                <div className={classnames(boostrapGrid.col)}>
                  <FacebookNumbers count={facebookCount} />
                </div>
              </div>
            </div>
          </div>
        )}
        {focusAreaListItems && focusAreaListItems.length > 0 && (
          <div className={s.focusAreaListContainer}>
            <div className={classnames(boostrapGrid.container)}>
              <FocusAreaList listItems={focusAreaListItems} />
            </div>
          </div>
        )}
        {translation.IS_SWEDISH && (
          <div className={s.focusAreaListContainer}>
            <div className={classnames(boostrapGrid.container)}>
              <OtherSitesList />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withStyles(s, boostrapGrid)(HomePage);
