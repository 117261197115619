// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeadImage-headImage-EnaOB{margin:1rem -24px 20px;overflow:hidden}@media (min-width:541px){.HeadImage-headImage-EnaOB{margin-bottom:40px;margin-left:0;margin-left:-2rem;margin-right:0;margin-right:-2rem;margin-top:40px;overflow:visible}}@media (min-width:820px){.HeadImage-headImage-EnaOB{margin-bottom:3rem;margin-left:-5rem;margin-right:-5rem}.HeadImage-headImage-EnaOB.HeadImage-twoColumnLayout-22Xu-{margin-bottom:3rem;margin-left:0;margin-right:0}}@media (min-width:1169px){.HeadImage-headImage-EnaOB.HeadImage-twoColumnLayout-22Xu-{margin-bottom:3rem;margin-left:-2rem;margin-right:-2rem}}.HeadImage-captionContainer-3gIG4{padding-left:24px;padding-right:24px}@media (min-width:541px){.HeadImage-captionContainer-3gIG4{padding-left:0;padding-right:0}}.HeadImage-headImage-EnaOB img{margin-bottom:8px}.HeadImage-headImage-EnaOB .HeadImage-caption-YbYKt{color:#33414e;margin-bottom:4px;margin-right:4px}.HeadImage-photographer-VUdnE{opacity:.8;white-space:nowrap}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"headImage": "HeadImage-headImage-EnaOB",
	"twoColumnLayout": "HeadImage-twoColumnLayout-22Xu-",
	"captionContainer": "HeadImage-captionContainer-3gIG4",
	"caption": "HeadImage-caption-YbYKt",
	"photographer": "HeadImage-photographer-VUdnE"
};
module.exports = exports;
