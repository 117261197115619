import { connect } from 'react-redux';
import PetitionPage from 'components/pages/PetitionPage';
import getPublishedDate from 'selectors/getPublishedDate';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getRelatedItems from 'selectors/getRelatedItems';
import getMetdata from 'selectors/metadata/getMetdata';
import getArticleAuthors from 'selectors/getArticleAuthors';
import getFields from 'selectors/getFields';
import getArticleTags from 'selectors/getArticleTags';
import getPageTitle from 'selectors/getPageTitle';
import getPageLabel from 'selectors/getPageLabel';

const mapStateToProps = state => {
  return {
    publishedDate: getPublishedDate(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
    fields: getFields(state),
    authors: getArticleAuthors(state),
    metadata: getMetdata(state),
    relatedItems: getRelatedItems(state),
    pageTitle: getPageTitle(state),
    pageLabel: getPageLabel(state),
    tags: getArticleTags(state),
  };
};

const mapDispatchToProps = undefined;

const TagPageContainer = connect(mapStateToProps, mapDispatchToProps)(PetitionPage);

export default TagPageContainer;
