export const getEnvironment = () => {
  // Server side
  if (process.env.ENVIRONMENT) {
    return process.env.ENVIRONMENT;
  }
  // Client side
  if (typeof window !== 'undefined') {
    return window.location.hostname.includes('localhost')
      ? 'localhost'
      : window.location.hostname.includes('test')
      ? 'test'
      : window.location.hostname.includes('stage')
      ? 'stage'
      : 'prod';
  }
  // Fallback to prod
  return 'prod';
};
