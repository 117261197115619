// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BackgroundMediaLayer-backgroundMediaLayer-30L9s{background-repeat:no-repeat;background-size:cover;height:100%;left:0;position:absolute;top:0;width:100%;z-index:-2}.BackgroundMediaLayer-backgroundMediaLayer-30L9s.BackgroundMediaLayer-image-3RQwE{background-position:50%;background-size:cover}.BackgroundMediaLayer-backgroundMediaLayer-30L9s.BackgroundMediaLayer-video-39uBF{height:calc(100% - 1px);-o-object-fit:cover;object-fit:cover}", ""]);
// Exports
exports.locals = {
	"backgroundMediaLayer": "BackgroundMediaLayer-backgroundMediaLayer-30L9s",
	"image": "BackgroundMediaLayer-image-3RQwE",
	"video": "BackgroundMediaLayer-video-39uBF"
};
module.exports = exports;
