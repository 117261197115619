import React from 'react';
import toast from 'react-hot-toast';
import Toast from './Toast';

export const renderToast = (message, type = 'success', duration = 5000) => {
  const dismiss = t => {
    toast.dismiss(t);
  };

  toast(t => <Toast dismiss={() => dismiss(t.id)} type={type} message={message} />, {
    duration,
    style: {
      border: 'none',
      boxShadow: 'none',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      color: 'inherit',
      backgroundColor: 'inherit',
      display: 'block',
    },
  });
};
