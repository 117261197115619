import { connect } from 'react-redux';
import { selectors as cookSelectors } from 'ducks/cook';
import ErrorPage from 'components/pages/ErrorPage';

const mapStateToProps = state => {
  return {
    error: cookSelectors.getError(state),
  };
};

const mapDispatchToProps = undefined;

const ErrorPageContainer = connect(mapStateToProps, mapDispatchToProps)(ErrorPage);

export default ErrorPageContainer;
