import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import _replace from 'lodash/fp/replace';
import pickFrom from 'utils/pickFrom';
import extractImages from 'utils/extractImages';

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, (data, hostname) => {
  if (data) {
    try {
      const replaceHostname = _replace(hostname, '');
      const quotesObj = pickFrom(data, 'context.deskedContent.imageQuotes');

      const quoteObj = quotesObj.map(obj => {
        const fields = pickFrom(obj, 'content.fields');
        const image = pickFrom(obj, 'content.image[0]');
        const imageTeaserOptionsObj = pickFrom(image, 'teaserOptions');
        const profileImage = pickFrom(obj, 'content.image');

        return {
          title: pickFrom(obj, 'content.title'),
          id: pickFrom(obj, 'content.id'),
          href: replaceHostname(pickFrom(obj, 'content.href')),
          quote: pickFrom(fields, 'quote'),
          externalUrl: pickFrom(fields, 'externalUrl'),
          internalUrl:
            pickFrom(fields, 'link[0].content.href') ||
            replaceHostname(pickFrom(obj, 'content.link[0].content.href')),
          linkText: pickFrom(fields, 'linkText'),
          images: profileImage && extractImages(profileImage),
          ...imageTeaserOptionsObj,
        };
      });
      return [...quoteObj];
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
