// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MatrixListWithTextOnImage-grid-34WGq{grid-gap:1rem;display:grid;gap:1rem;grid-template-columns:repeat(auto-fit,minmax(min(330px,100%),1fr))}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"grid": "MatrixListWithTextOnImage-grid-34WGq"
};
module.exports = exports;
