// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VariantSimple-variantSimple-U1hlE{border-left:3px solid #b12f26;margin-bottom:24px;margin-top:24px}@media (min-width:1169px){.VariantSimple-variantSimple-U1hlE.VariantSimple-alignRightOnDesktop-3b6Dm{-ms-flex-direction:column;flex-direction:column;margin-right:0;margin-top:7px;max-width:16.66667vw;position:absolute;right:-50px;-webkit-transform:translateX(100%);transform:translateX(100%)}}.VariantSimple-variantSimple-U1hlE .VariantSimple-textContainer-ii8Z2{padding-left:13px}.VariantSimple-variantSimple-U1hlE .VariantSimple-textContainer-ii8Z2 a{display:block}.VariantSimple-variantSimple-U1hlE .VariantSimple-externalIcon-13Nf3{color:inherit;height:12px;margin:0 0 2px 8px;width:12px}.VariantSimple-variantSimple-U1hlE.VariantSimple-darkMode-14Wu7{background-color:#62717f;border:none;padding:1rem}.VariantSimple-variantSimple-U1hlE.VariantSimple-darkMode-14Wu7 .VariantSimple-title-3R8JR{color:#faf9f7}.VariantSimple-variantSimple-U1hlE.VariantSimple-darkMode-14Wu7 .VariantSimple-arrowRight-3L5wB{color:#ea8f12;height:1.125rem;margin-right:.5rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"variantSimple": "VariantSimple-variantSimple-U1hlE",
	"alignRightOnDesktop": "VariantSimple-alignRightOnDesktop-3b6Dm",
	"textContainer": "VariantSimple-textContainer-ii8Z2",
	"externalIcon": "VariantSimple-externalIcon-13Nf3",
	"darkMode": "VariantSimple-darkMode-14Wu7",
	"title": "VariantSimple-title-3R8JR",
	"arrowRight": "VariantSimple-arrowRight-3L5wB"
};
module.exports = exports;
