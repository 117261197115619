import { connect } from 'react-redux';
import MembershipOrganizations from '../../components/organisms/MembershipOrganizations';
import getMemberOrganizations from '../../selectors/getMemberOrganizations';

const mapStateToProps = state => {
  return {
    memberOrganizationGroups: getMemberOrganizations(state),
  };
};

const mapDispatchToProps = undefined;

const MembershipOrganizationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipOrganizations);

export default MembershipOrganizationsContainer;
