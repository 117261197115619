// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonPrimary-buttonPrimary-1fX4l{background-color:#b12f26;border:none;border-radius:2px;color:#fff;cursor:pointer;display:block;font-family:LabGrotesque-Bold,sans-serif;padding:16px 32px;text-align:center;text-decoration:none;-webkit-transition:background-color .2s ease-in-out;transition:background-color .2s ease-in-out}.ButtonPrimary-buttonPrimary-1fX4l:hover{background:#bc4e46}.ButtonPrimary-buttonPrimary-1fX4l:active{background:#962720}.ButtonPrimary-buttonPrimary-1fX4l:disabled,.ButtonPrimary-buttonPrimary-1fX4l:disabled:active,.ButtonPrimary-buttonPrimary-1fX4l:disabled:hover{background-color:#a0a0a0;color:#f1f1f1;cursor:default}.ButtonPrimary-buttonPrimary-1fX4l.ButtonPrimary-block-1lSRU{width:100%}.ButtonPrimary-buttonPrimary-1fX4l.ButtonPrimary-gutterBottom-3VwwK{margin-bottom:1rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"buttonPrimary": "ButtonPrimary-buttonPrimary-1fX4l",
	"block": "ButtonPrimary-block-1lSRU",
	"gutterBottom": "ButtonPrimary-gutterBottom-3VwwK"
};
module.exports = exports;
