/* eslint-disable no-case-declarations */
import React, { useReducer } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
// import flattenDeep from 'lodash/flattenDeep';
import SelectField from '../../molecules/SelectField';
import Typography from '../../atoms/Typography';
import KeyValue from './KeyValue';
import map from './map.png';
import COUNTIES from './data.json';
import s from './CrimeRegionMap.css';

const sortAlphabetically = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const formatNumber = nr => new Intl.NumberFormat('sv-SE', { style: 'decimal' }).format(nr);

const allCounties = Object.entries(COUNTIES)
  .map(([county, val]) => ({
    name: county,
    expences: val.expences,
    numberOfCompanies: val.numberOfCompanies,
  }))
  .filter(c => c.name !== 'Hela Sverige')
  .sort(sortAlphabetically);

const ALL_COUNTIES = [
  {
    name: 'Hela Sverige',
    expences: COUNTIES['Hela Sverige'].expences,
    numberOfCompanies: COUNTIES['Hela Sverige'].numberOfCompanies,
  },
  ...allCounties,
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_REGION':
      const region = ALL_COUNTIES.find(c => c.name === action.payload) || ALL_COUNTIES['Hela Sverige'];

      return {
        ...state,
        selectedRegion: region.name,
        box1Value: region.expences,
        box2Value: formatNumber(region.numberOfCompanies),
      };
    default:
      return state;
  }
};

const initialState = {
  selectedRegion: 'Hela Sverige',
  box1Value: COUNTIES['Hela Sverige'].expences,
  box2Value: formatNumber(COUNTIES['Hela Sverige'].numberOfCompanies),
};

const CrimeRegionMap = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showWholeSweden = state.selectedRegion === 'Hela Sverige';

  const handleChangeRegion = e => {
    dispatch({ type: 'SET_REGION', payload: e.target.value });
  };
  return (
    <div className={s.crimeRegionMap}>
      <img className={s.map} src={map} alt="Karta över Sverige" width={1000} height={1333} />
      <div>
        <Typography className={s.heading} variant="h2">
          Så höga är företagens kostnader för brott i regionerna
        </Typography>
        <div className={s.selects}>
          <SelectField
            id="region"
            name="region"
            label="Välj region"
            value={state.selectedRegion}
            onChange={handleChangeRegion}
          >
            {ALL_COUNTIES.map(county => (
              <option key={county.name} value={county.name}>
                {county.name}
              </option>
            ))}
          </SelectField>
        </div>
        <div className={s.keyValues}>
          <KeyValue
            style={{ gridArea: 'b1' }}
            label={
              showWholeSweden
                ? 'Företagens kostnader för brott i hela Sverige'
                : 'Företagens kostnader för brott i regionen'
            }
            value={state.box1Value}
          />
          <KeyValue
            style={{ gridArea: 'b2' }}
            label={
              showWholeSweden
                ? 'Antal företag i hela Sverige med anställda'
                : 'Antal företag i region med anställda'
            }
            value={state.box2Value}
          />
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(CrimeRegionMap);
