import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import pickFrom from 'utils/pickFrom';
import _replace from 'lodash/fp/replace';

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, (data, hostname) => {
  const replaceHostname = _replace(hostname, '');

  if (data) {
    try {
      const coworkersObj = pickFrom(data, 'coworkers');
      const coworkers = coworkersObj.map(coworker => {
        const profileImageObj = pickFrom(coworker, 'profileImage[0]');
        const profileLink = pickFrom(coworker, 'href');
        const { firstName, surName } = coworker.fields;

        return {
          id: pickFrom(coworker, 'id'),
          profileLink: replaceHostname(profileLink),
          firstName,
          surName,
          role: pickFrom(coworker, 'fields.assignment'),
          image: {
            id: pickFrom(profileImageObj, 'content.id'),
            href: pickFrom(profileImageObj, 'content.fields.BASE_LANDSCAPE.href_full'),
            altText: pickFrom(profileImageObj, 'content.fields.alttext'),
          },
        };
      });

      return [...coworkers];
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }
  return undefined;
});
