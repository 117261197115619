import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Chat.css';

const Chat = ({ chatUrl, show }) => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  useEffect(() => {
    if (!show) {
      setIsIframeLoaded(false);
    }
  }, [show]);

  return (
    <AnimatePresence initial={false}>
      {show && (
        <motion.div
          className={s.chat}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: isIframeLoaded ? 1 : 0, height: isIframeLoaded ? 'auto' : 0 }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        >
          <div className={s.chatWrapper}>
            <iframe
              onLoad={() => setIsIframeLoaded(true)}
              title="title.chat"
              src={chatUrl}
              width="100%"
              height="100%"
              frameBorder="0"
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default withStyles(s)(Chat);
