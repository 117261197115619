/* eslint-disable css-modules/no-unused-class */
/* eslint-disable css-modules/no-undef-class */

import React, { useState } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import LatestArticle from 'components/molecules/LatestArticle';
import InDepthItem from 'components/molecules/InDepthItem';
import InDepthList from 'components/molecules/InDepthList';
import VCard from 'components/molecules/VCard';
import HighlightedArticle from 'components/molecules/HighlightedArticle';
import DarkMode from 'components/templates/DarkMode';
import renderStorylineElement from 'utils/renderStorylineElement';
import pickFrom from 'utils/pickFrom';
import getImages from 'utils/getImages';
import generateSizes from 'utils/generateSizes';
// eslint-disable-next-line css-modules/no-unused-class
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './TagPage.css';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';
import EventsWebinars from '../../organisms/EventsWebinars/EventsWebinars';

const getImage = item => ({
  image: item?.teaserImage?.[0] ?? item?.coverImage?.[0],
  format: item?.teaserImage?.[0] ? 'BASE_LANDSCAPE' : 'BASE_FREEFORM',
  mobileFormat: item?.teaserImage[0] ? 'SQUARE' : 'FREEFORM',
});

const Article = ({ article, small = false }) => {
  const imageHref = pickFrom(article, 'images.images.BASE.LANDSCAPE.href_full');
  const imageWidth =
    article?.images?.images?.BASE?.LANDSCAPE?.sourceWidth || article?.images?.images?.BASE?.LANDSCAPE?.width;
  const imageHeight =
    article?.images?.images?.BASE?.LANDSCAPE?.sourceHeight ||
    article?.images?.images?.BASE?.LANDSCAPE?.height;

  const sizes = getImages('LANDSCAPE');
  const imageSizes = small
    ? generateSizes({ xl: '412px', md: '31vw', sm: 'calc(50vw - 68px)', default: '100vw' })
    : generateSizes({ xl: '848px', md: '68vw', sm: 'calc(100vw - 112px)', default: '100vw' });
  const articleHref = article?.binary?.href || article.href;

  return (
    <HighlightedArticle
      key={article.href}
      href={articleHref}
      imageSrc={imageHref && (small ? sizes.toSrc(imageHref, 'M') : sizes.toSrc(imageHref, 'L'))}
      small={small}
      imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
      imageSizes={imageSizes}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      contentType={article.contentType}
      headline={article?.fields?.teaserTitle || article.title}
      text={pickFrom(article, 'fields.teaser')}
    />
  );
};

const TagPage = ({
  classes,
  headline,
  groupedStorylineTree,
  inDepthItems,
  latestArticles,
  contactPersons,
  heroImage,
  firstSelectedContent,
  restSelectedContent,
  darkMode = false,
  darkModeType,
  upcomingEvents,
  metadata,
  latestWebinars = [],
}) => {
  const [expanded, setExpanded] = useState(false);
  const [latestToShow, setLatestToShow] = useState(10);
  const classNames = classnames(s.tagPage, darkMode && s.darkMode, classes);

  if (!groupedStorylineTree) return undefined;
  const { headGroup, contentGroup } = groupedStorylineTree;

  return (
    <div className={classNames}>
      <OGShareTags metadata={metadata} />
      <DarkMode
        darkMode={darkMode}
        darkModeType={darkModeType}
        imageHref={heroImage && heroImage.href}
        offset={firstSelectedContent && 'xlarge'}
      >
        {headGroup.length === 0 && (
          <div className={classnames(boostrapGrid.container)}>
            <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
              <div
                className={classnames(
                  boostrapGrid['col-lg-16'],
                  boostrapGrid['offset-lg-4'],
                  boostrapGrid['col-md-18'],
                  boostrapGrid['offset-md-3']
                )}
              >
                <Typography variant="h1Big" classes={s.headline}>
                  {headline}
                </Typography>
              </div>
            </div>
          </div>
        )}
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(s.topRowWrapper, boostrapGrid.row)}>
            <div
              className={classnames(
                boostrapGrid['col-lg-16'],
                boostrapGrid['offset-lg-4'],
                boostrapGrid['col-md-18'],
                boostrapGrid['offset-md-3'],
                boostrapGrid['col-sm-16'],
                boostrapGrid['offset-sm-4']
              )}
            >
              <div className={s.leadWrapper}>
                {headGroup && headGroup.map(el => renderStorylineElement(el, darkMode))}
                {expanded && contentGroup && contentGroup.map(el => renderStorylineElement(el, darkMode))}
                {contentGroup?.length > 0 && (
                  <Typography
                    key={`toggle-${expanded}`}
                    component="button"
                    variant="link"
                    darkMode={darkMode}
                    classes={s.expandButton}
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? '- Fäll ihop' : '+ Läs mer'}
                  </Typography>
                )}
                {inDepthItems && (
                  <div className={s.inDepthWrapper}>
                    <Typography
                      variant="caption"
                      component="h3"
                      darkMode={darkMode}
                      gutterBottom
                      classes={s.caption}
                    >
                      Fördjupning
                    </Typography>
                    <InDepthList layoutType="columns">
                      {inDepthItems.map(inDepthItem => (
                        <InDepthItem
                          key={inDepthItem.id}
                          href={
                            inDepthItem.contentType === 'link' ? inDepthItem?.fields?.url : inDepthItem.href
                          }
                          target={inDepthItem.contentType === 'link' ? '_blank' : undefined}
                          darkMode={darkMode}
                        >
                          {inDepthItem.title}
                        </InDepthItem>
                      ))}
                    </InDepthList>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DarkMode>
      {firstSelectedContent && (
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
            <div
              className={classnames(
                boostrapGrid['col-lg-16'],
                boostrapGrid['offset-lg-4'],
                boostrapGrid['col-md-18'],
                boostrapGrid['offset-md-3']
              )}
            >
              <div className={s.articlesWrapper}>
                {firstSelectedContent && <Article article={firstSelectedContent} />}
                <div className={classnames(boostrapGrid.row)}>
                  {restSelectedContent?.length > 0 &&
                    restSelectedContent.map(article => (
                      <div key={article.id} className={classnames(boostrapGrid['col-sm-12'])}>
                        <Article article={article} small />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <EventsWebinars
        selectedEvents={upcomingEvents}
        latestWebinars={latestWebinars}
        darkBackground={contactPersons?.length > 0}
      />
      {contactPersons?.length > 0 && (
        <div className={s.contactWrapper}>
          <div className={s.contact}>
            <div className={classnames(boostrapGrid.container)}>
              <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
                <div
                  className={classnames(
                    boostrapGrid['col-lg-16'],
                    boostrapGrid['offset-lg-4'],
                    boostrapGrid['col-md-18'],
                    boostrapGrid['offset-md-3']
                  )}
                >
                  <h2 className={s.smallHeadline}>Kontaktpersoner</h2>
                  <div>
                    <div className={classnames(boostrapGrid.row)}>
                      {contactPersons &&
                        contactPersons.map(person => {
                          const imageHref = pickFrom(person, 'images.images.BASE.LANDSCAPE.href_full');
                          const sizes = getImages('SQUARE');
                          return (
                            <div key={person.name} className={classnames(boostrapGrid['col-md-12'])}>
                              <VCard
                                classes={s.contactCard}
                                imageSrc={imageHref && sizes.toSrc(imageHref, 'XS')}
                                imageAlt={pickFrom(person, 'images.altText', 'name')}
                                imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
                                name={person.name}
                                title={person.title}
                                href={person.href}
                                linkText="Se kontaktuppgifter"
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {latestArticles && Array.isArray(latestArticles) && (
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
            <div
              className={classnames(
                boostrapGrid['col-lg-16'],
                boostrapGrid['offset-lg-4'],
                boostrapGrid['col-md-18'],
                boostrapGrid['offset-md-3'],
                s.latestArticleWrapper
              )}
            >
              <h2 className={s.articleSmallHeadline}>Senaste artiklarna</h2>
              {latestArticles
                .slice(0, latestToShow)
                .map(({ id, contentType, title, summery, date, href, images, ...rest }) => {
                  const { image, format, mobileFormat } = getImage(rest);

                  const imageHref = image?.content?.fields?.[format]?.href_full;
                  const imageAlt = image?.content?.fields?.alttext;
                  const imageWidth =
                    format === 'BASE_LANDSCAPE'
                      ? image?.content?.fields?.[format]?.sourceWidth ||
                        image?.content?.fields?.[format]?.width
                      : image?.content?.originalPictureSize?.width;
                  const imageHeight =
                    format === 'BASE_LANDSCAPE'
                      ? image?.content?.fields?.[format]?.sourceHeight ||
                        image?.content?.fields?.[format]?.height
                      : image?.content?.originalPictureSize?.height;
                  const sizes = getImages(format.slice('BASE_'.length));
                  const sizesMobile = getImages(mobileFormat);

                  return (
                    <LatestArticle
                      key={id}
                      href={href}
                      articleType={contentType}
                      headline={title}
                      day={date}
                      text={summery}
                      imageSrc={imageHref && sizes.toSrc(imageHref, 'M')}
                      imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
                      imageSizes="190px"
                      imageMobileSrc={imageHref && sizes.toSrc(imageHref, 'XS')}
                      imageMobileSrcSet={imageHref && sizesMobile.toSrcSet(imageHref)}
                      imageMobileSizes="90px"
                      imageAlt={imageAlt}
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                    />
                  );
                })}
              {latestArticles.length > latestToShow && (
                <ButtonSecondaryGhost
                  key={`show-more-${latestToShow}`}
                  classes={s.showMoreEvents}
                  onClick={() => setLatestToShow(latestToShow + 10)}
                >
                  Visa fler
                </ButtonSecondaryGhost>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withStyles(boostrapGrid, s)(TagPage);
