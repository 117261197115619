import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { format } from 'date-fns';
import { enGB, sv } from 'date-fns/locale';
import s from './DateCalendar.css';
import useTranslation from '../../../utils/hooks/useTranslation';

const DateCalendar = ({ classes, darkMode, date: dateString, ...other }) => {
  const { IS_SWEDISH } = useTranslation();
  const date = new Date(dateString);
  const eventYear = format(date, 'yyyy', sv);
  const currentDate = new Date();
  const currentYear = format(currentDate, 'yyyy', sv);

  const classNames = classnames(s.dateCalendar, classes, darkMode && s.darkMode);

  return (
    <div className={classNames} {...other}>
      <div className={s.month}>
        {format(date, 'MMM', { locale: IS_SWEDISH ? sv : enGB }).replace('.', '')}
      </div>
      <div className={s.dayYear}>
        <div className={s.day}>{format(date, 'dd', { locale: IS_SWEDISH ? sv : enGB })}</div>
        {eventYear !== currentYear && (
          <div className={s.year}>{format(date, 'yyyy', { locale: IS_SWEDISH ? sv : enGB })}</div>
        )}
      </div>
    </div>
  );
};

export default withStyles(s)(DateCalendar);
