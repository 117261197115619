import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, data => {
  try {
    const contextType = pickFrom(data, 'resolution.context');
    const context = pickFrom(data, 'context', 'tagInfo');
    let elements = pickFrom(data, 'context.elements');
    let storyline = pickFrom(data, 'context.storyline');

    // for sections fallback to section info
    if (contextType === 'sec') {
      elements = pickFrom(data, 'context.deskedContent.sectionInfo[0].content.elements');
      storyline = pickFrom(data, 'context.deskedContent.sectionInfo[0].content.storyline');
    }
    if (contextType === 'tag') {
      elements = pickFrom(data, 'tagInfo[0].elements');
      storyline = pickFrom(data, 'tagInfo[0].storyline');
    }

    let sortedElements = [];
    if (elements) {
      if (storyline) {
        sortedElements = elements.slice().sort((a, b) => storyline.indexOf(a.id) - storyline.indexOf(b.id));
      }
    }
    // Temporary workaround to handle the fact that storyline is null for some articles. CUE-2136, CUE-1993
    if (!storyline) {
      let title = pickFrom(data, 'context.title');
      if (contextType === 'sec') {
        title = pickFrom(data, 'context.deskedContent.sectionInfo[0].content.title');
      }
      const mockId = '12345';
      sortedElements = [
        {
          __typename: '_StoryElement_Without_Children_Without_Relation',
          id: mockId,
          type: 'headline',
          fields: [
            {
              name: 'headline',
              __typename: 'StoryElementTextField',
              value: title,
              annotations: [],
            },
          ],
        },
      ];
      storyline = [mockId];
    }

    return {
      ...data,
      context: {
        ...context,
        storyline,
        elements: sortedElements,
      },
    };
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
