// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CrimeRegionMap-crimeRegionMap-3Hmcc{display:-ms-flexbox;display:flex;margin-bottom:1.9rem;margin-top:1.9rem}.CrimeRegionMap-crimeRegionMap-3Hmcc .CrimeRegionMap-heading-1xvEU{color:#b12f26}.CrimeRegionMap-crimeRegionMap-3Hmcc .CrimeRegionMap-map-13kU3{display:none;height:100%;width:30%}@media (min-width:541px){.CrimeRegionMap-crimeRegionMap-3Hmcc .CrimeRegionMap-map-13kU3{display:block}}.CrimeRegionMap-crimeRegionMap-3Hmcc .CrimeRegionMap-selects-1W2wu{margin-top:16px;max-width:600px}.CrimeRegionMap-crimeRegionMap-3Hmcc .CrimeRegionMap-keyValues-1vmaG{grid-gap:16px;display:grid;gap:16px;grid-template-areas:\"b1 b1\" \"b2 b2\";grid-template-columns:1fr 1fr;margin-top:24px;max-width:600px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"crimeRegionMap": "CrimeRegionMap-crimeRegionMap-3Hmcc",
	"heading": "CrimeRegionMap-heading-1xvEU",
	"map": "CrimeRegionMap-map-13kU3",
	"selects": "CrimeRegionMap-selects-1W2wu",
	"keyValues": "CrimeRegionMap-keyValues-1vmaG"
};
module.exports = exports;
