// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PageContent-pageContent-3nSQb{-ms-flex-positive:1;-ms-flex:1 0 auto;flex:1 0 auto;flex-grow:1;padding-top:32px;position:relative}.PageContent-pageContent-3nSQb.PageContent-whiteBg-2f5Qw{background-color:#fff}.PageContent-pageContent-3nSQb.PageContent-noPaddingTop-gtgDg{padding-top:0}.PageContent-pageContent-3nSQb.PageContent-showMediaQuery-3_nNB:after{background:#fff;border:1px solid #000;bottom:10px;color:#000;font-size:.75rem;padding:4px;position:fixed;right:10px;text-transform:uppercase;z-index:999999}@media (min-width:0){.PageContent-pageContent-3nSQb.PageContent-showMediaQuery-3_nNB:after{content:\"xxs\"}}@media (min-width:320px){.PageContent-pageContent-3nSQb.PageContent-showMediaQuery-3_nNB:after{content:\"xs\"}}@media (min-width:541px){.PageContent-pageContent-3nSQb.PageContent-showMediaQuery-3_nNB:after{content:\"sm\"}}@media (min-width:620px){.PageContent-pageContent-3nSQb.PageContent-showMediaQuery-3_nNB:after{content:\"620\"}}@media (min-width:820px){.PageContent-pageContent-3nSQb.PageContent-showMediaQuery-3_nNB:after{content:\"md\"}}@media (min-width:1169px){.PageContent-pageContent-3nSQb.PageContent-showMediaQuery-3_nNB:after{content:\"lg\"}}@media (min-width:1600px){.PageContent-pageContent-3nSQb.PageContent-showMediaQuery-3_nNB:after{content:\"xl\"}}@media (min-width:541px){.PageContent-pageContent-3nSQb{padding-top:56px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"pageContent": "PageContent-pageContent-3nSQb",
	"whiteBg": "PageContent-whiteBg-2f5Qw",
	"noPaddingTop": "PageContent-noPaddingTop-gtgDg",
	"showMediaQuery": "PageContent-showMediaQuery-3_nNB"
};
module.exports = exports;
