import { createSelector } from 'reselect';
import { selectors } from 'ducks/app';
import { SWEDISH, ENGLISH } from '../ducks/app/translations';

export default createSelector(selectors.getTranslation, selectors.getTranslations, (lang, translations) => {
  return {
    IS_SWEDISH: lang === SWEDISH,
    IS_ENGLISH: lang === ENGLISH,
    LANG: lang,
    translations,
  };
});
