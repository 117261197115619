/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import getImages from 'utils/getImages';
import VCard from 'components/molecules/VCard';
import s from './ContactPersonBlock.css';

const ContactPersonBlock = ({ persons, darkMode }) => {
  return (
    <div className={s.contactWrapper}>
      {persons &&
        persons.map(person => {
          const imageHref = person?.relation?.profileImage[0]?.content?.fields?.BASE_LANDSCAPE?.href_full;
          const sizes = getImages('SQUARE');
          let relativePath = '';
          const href = person?.relation?.href;
          const relativePathRegex = /\.[^/]+(.*)/g;
          const match = relativePathRegex.exec(href);
          if (match && match.length > 1) {
            relativePath = match[1];
          }

          return (
            <VCard
              key={person.id}
              classes={s.contactCard}
              darkMode={darkMode}
              imageSrc={imageHref && sizes.toSrc(imageHref, 'XS')}
              imageAlt={person?.relation?.profileImage[0]?.content?.fields?.alttext}
              imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
              name={person?.relation?.title}
              href={relativePath}
              linkText="Se kontaktuppgifter"
            />
          );
        })}
    </div>
  );
};

export default withStyles(s)(ContactPersonBlock);
