import React, { useEffect, useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from 'components/atoms/Typography';
import ShareBarContainer from 'containers/molecules/ShareBarContainer';
import renderEventStorylineElement from 'utils/renderEventStorylineElement';
import getImages from 'utils/getImages';
import getDocumentIcon from 'utils/getDocumentIcon';
import Tag from 'components/atoms/Tag';
import VCard from 'components/molecules/VCard';
import pickFrom from 'utils/pickFrom';
import boostrapGrid from 'style/bootstrap-grid.css';
import Translate from '../../../containers/atoms/TranslateContainer';
import useTranslate from '../../../utils/hooks/useTranslate';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';
import s from './EventPage.css';
import LiveEvent from './LiveEvent/LiveEvent';
import EventCard from '../../organisms/EventCard/EventCard';
import { EVENT_VIEWING_MODES } from './viewingModes';

const EventPage = ({
  classes,
  pageData,
  tags,
  groupedStorylineTree,
  pageTitle,
  metadata,
  actionMetadata,
  resetFields,
}) => {
  const [viewingMode, setViewingMode] = useState('');
  const [vimeoHasBeenReset, setVimeoHasBeenReset] = useState(false);
  const translate = useTranslate();
  const classNames = classnames(s.event, classes);
  const { headGroup, contentGroup } = groupedStorylineTree;
  const [visible, setVisible] = useState(true);

  const role = actionMetadata?.role || 'unauthenticated';
  const action = actionMetadata?.action;
  const actionForUnauthenticated = actionMetadata?.premiumContentPolicy?.roleToAction?.unauthenticated;
  const actionForGuest = actionMetadata?.premiumContentPolicy?.roleToAction?.guest;
  const limitedForGuest = actionForGuest === 'showLimited';

  useEffect(() => {
    if (action === 'showLimited' && role === 'unauthenticated' && !limitedForGuest) {
      setViewingMode(EVENT_VIEWING_MODES.UNAUTHENTICATED_VIEWING_GUEST_CONTENT);
    } else if (action === 'showLimited' && role === 'unauthenticated' && limitedForGuest) {
      setViewingMode(EVENT_VIEWING_MODES.UNAUTHENTICATED_VIEWING_MEMBER_CONTENT);
    } else if (action === 'showLimited' && role === 'guest' && limitedForGuest) {
      setViewingMode(EVENT_VIEWING_MODES.GUEST_VIEWING_MEMBER_CONTENT);
    } else {
      setViewingMode(EVENT_VIEWING_MODES.ALLOW_ALL);
    }
  }, [actionForUnauthenticated, actionForGuest, role]);

  useEffect(() => {
    if (viewingMode && viewingMode !== EVENT_VIEWING_MODES.ALLOW_ALL && resetFields?.length > 0) {
      if (resetFields.includes('vimeoVideoUrl') || resetFields.includes('vimeoLiveEventUrl')) {
        setVimeoHasBeenReset(true);
      } else {
        setVimeoHasBeenReset(false);
      }
    } else {
      setVimeoHasBeenReset(false);
    }
  }, [viewingMode, resetFields]);

  const rightContent = () => (
    <>
      <OGShareTags metadata={metadata} type="event" />
      <EventCard
        pageData={pageData}
        pageTitle={pageTitle}
        viewingMode={viewingMode}
        vimeoHasBeenReset={vimeoHasBeenReset}
      />

      {pageData?.contactPersons?.length > 0 && (
        <>
          <Typography component="div" bold gutterBottom light>
            {pageData.contactPersons.length > 1 ? 'Kontaktpersoner' : 'Kontaktperson'}
          </Typography>
          {pageData?.contactPersons?.map(person => {
            const sizes = getImages('SQUARE');
            const imageSrc = pickFrom(person, 'images.images.BASE.LANDSCAPE.href_full');
            const imageAlt = pickFrom(person, 'images.altText');

            return (
              <VCard
                key={person?.id}
                imageSrc={sizes.toSrc(imageSrc, 'S')}
                imageAlt={imageAlt}
                name={person?.name}
                title={person?.assignment}
                href={person?.profileLink}
                linkText={person?.name}
                classes={s.infoVcard}
              />
            );
          })}
        </>
      )}
      {pageData?.documents?.length > 0 && (
        <>
          <Typography component="div" gutterBottom light bold>
            <Translate>EVENT_PAGE.DOCUMENT</Translate>
          </Typography>
          {pageData?.documents?.map(document => {
            const fileExtension = pickFrom(document, 'fileExtension');
            const title = pickFrom(document, 'title');
            const downloadLink = pickFrom(document, 'downloadLink');
            return (
              <VCard
                key={document?.id}
                imageComponent={
                  <FontAwesomeIcon
                    icon={getDocumentIcon(fileExtension)}
                    className={s.fileIcon}
                    style={{ width: 27, height: 38 }}
                  />
                }
                name={title}
                downloadLink={downloadLink}
                linkText={translate('EVENT_PAGE.DOWNLOAD')}
                classes={s.infoVcard}
              />
            );
          })}
        </>
      )}
    </>
  );

  return (
    <div className={classNames}>
      <div className={classnames(s.topWrapper)}>
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(s.topRowWrapper, boostrapGrid.row)}>
            <div
              className={classnames(
                boostrapGrid['col-sm-16'],
                boostrapGrid['offset-sm-4'],
                boostrapGrid['offset-md-0'],
                boostrapGrid['col-md-14'],
                boostrapGrid['col-lg-13'],
                boostrapGrid['offset-lg-2']
              )}
            >
              <div className={s.leftContentWrapper}>
                <LiveEvent viewingMode={viewingMode} vimeoHasBeenReset={vimeoHasBeenReset} />
                <ScrollTrigger onEnter={() => setVisible(true)} onExit={() => setVisible(false)}>
                  <div className={s.scrollWrapper}>
                    <Translate>{`CONTENT_TYPE.${pageData?.eventType}`}</Translate>
                    {headGroup && headGroup.map(element => renderEventStorylineElement(element))}
                    <div className={classnames(visible === true ? s.fadeIn : s.fadeOut)}>
                      <ShareBarContainer event />
                    </div>
                    <div className={s.hideTablet}>{rightContent()}</div>
                    <ShareBarContainer visible={visible} hideMobile event />
                    {contentGroup && contentGroup.map(element => renderEventStorylineElement(element))}
                  </div>
                </ScrollTrigger>
                <div className={s.tagsContainer}>
                  {tags?.map(tag => {
                    const href = `/${tag?.tagUri}`;
                    return (
                      <Tag key={href} href={href}>
                        {tag?.name}
                      </Tag>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={classnames(boostrapGrid['col-md-10'], boostrapGrid['col-lg-7'])}>
              <div className={s.hideSmallMobile}>{rightContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(boostrapGrid, s)(EventPage);
