import _sortBy from 'lodash/sortBy';
import imageSizes from 'utils/imageSizes.json';

const imageSizesSorted = _sortBy(imageSizes, 'width');

const getImageDesc = imageProps => {
  let sizeDesc = `${imageProps.proportion}_${imageProps.size}`;
  if (imageProps.size === 'BASE') sizeDesc = `${imageProps.size}_${imageProps.proportion}`;
  if (imageProps.size === 'NEWSLETTER') sizeDesc = `${imageProps.proportion}${imageProps.size}`;
  return sizeDesc;
};

const sizes = imageSizesSorted.reduce((acc, val) => {
  if (!acc[val.proportion]) {
    acc[val.proportion] = {
      toSrcSet(url) {
        if (!url) {
          return undefined;
        }

        try {
          const urlSplit = url.split('/');
          const indexOfAlternates = urlSplit.indexOf('alternates');
          return Object.keys(this)
            .filter(key => typeof this[key] !== 'function')
            .map(key => {
              const imageProps = this[key];
              const sizeDesc = getImageDesc(imageProps);
              const newSplit = urlSplit.slice();
              newSplit[indexOfAlternates + 1] = sizeDesc;
              return `${newSplit.join('/')} ${imageProps.width}w`;
            })
            .join(', ');
        } catch (err) {
          console.warn(err);
          return undefined;
        }
      },
      toSrc(url, sizeKey) {
        if (!url) {
          return undefined;
        }

        try {
          const urlSplit = url.split('/');
          const indexOfAlternates = urlSplit.indexOf('alternates');

          // Return original url if there are no alternates in the url
          if (indexOfAlternates === -1) {
            return url;
          }

          const imageProps = this[sizeKey];
          const sizeDesc = getImageDesc(imageProps);
          const newSplit = urlSplit.slice();
          newSplit[indexOfAlternates + 1] = sizeDesc;
          return newSplit.join('/');
        } catch (err) {
          console.warn(err);
          return undefined;
        }
      },
    };
  }
  acc[val.proportion][val.size] = val;
  return acc;
}, {});

const getImages = proportion => {
  return sizes[proportion];
};

export default getImages;
