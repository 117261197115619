// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BlurryPlayer-container-2bQov{overflow:hidden;padding-top:56.25%;position:relative}.BlurryPlayer-container-2bQov .BlurryPlayer-bg-BDOIo{background:-webkit-gradient(linear,left bottom,left top,from(#2a3843),to(#62717f));background:linear-gradient(0deg,#2a3843,#62717f);left:0;width:100%}.BlurryPlayer-container-2bQov .BlurryPlayer-bg-BDOIo,.BlurryPlayer-container-2bQov .BlurryPlayer-icon-3B5LV{height:100%;position:absolute;top:0}.BlurryPlayer-container-2bQov .BlurryPlayer-icon-3B5LV{color:#fff;-webkit-filter:blur(5px);filter:blur(5px);left:auto;opacity:.05;right:-6%;width:60%}.BlurryPlayer-container-2bQov .BlurryPlayer-icon-3B5LV svg{height:100%!important;width:100%!important}.BlurryPlayer-container-2bQov .BlurryPlayer-content-3UrDx{-ms-flex-pack:center;-ms-flex-align:center;align-items:center;color:#fff;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;height:100%;justify-content:center;left:0;padding-inline:2rem;position:absolute;text-align:center;top:0;width:100%}.BlurryPlayer-container-2bQov .BlurryPlayer-link-azE4A{color:#fff;margin-bottom:1rem;text-decoration:underline}.BlurryPlayer-container-2bQov .BlurryPlayer-link-azE4A:hover{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"container": "BlurryPlayer-container-2bQov",
	"bg": "BlurryPlayer-bg-BDOIo",
	"icon": "BlurryPlayer-icon-3B5LV",
	"content": "BlurryPlayer-content-3UrDx",
	"link": "BlurryPlayer-link-azE4A"
};
module.exports = exports;
