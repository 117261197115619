import React from 'react';
import classnames from 'classnames';
import Typography from 'components/atoms/Typography';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Checkbox.css';

const Checkbox = ({ classes, id, label, gutterBottom, register, className, ...other }) => {
  const classNames = classnames(s.checkbox, gutterBottom && s.gutterBottom, classes);
  const classNamesLabel = classnames(s.label, gutterBottom && s.gutterBottom, { [className]: !!className });

  return (
    <>
      <input type="checkbox" id={id} className={classNames} ref={register} {...other} />
      <Typography component="label" id={`${id}-label`} classes={classNamesLabel} htmlFor={id}>
        {label}
      </Typography>
    </>
  );
};

export default withStyles(s)(Checkbox);
