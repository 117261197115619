const formatNumber = nr => {
  try {
    return new Intl.NumberFormat('sv-SE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(parseInt(nr, 10));
  } catch {
    console.error(`Could not format number ${nr}}. Returning '${nr}' as a string instead`);
    return nr.toString();
  }
};

export default formatNumber;
