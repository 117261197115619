import { COLOR_MAP, WIDTHS } from './constants';

const getAvsnittOptionKeyValue = (key, value) => {
  switch (key) {
    case 'backgroundColor':
      return [key, COLOR_MAP[value] ?? COLOR_MAP.DEFAULT];

    case 'width':
      return ['width', WIDTHS[value] ?? WIDTHS.DEFAULT];

    default:
      if (value === 'true') {
        return [key, true];
      }

      if (value === 'false') {
        return [key, false];
      }

      return [key, value];
  }
};

export default getAvsnittOptionKeyValue;
