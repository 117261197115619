// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FooterLinks-footerLinks-2ilr-{color:#fff;font-family:LabGrotesque-Regular,sans-serif;font-size:14px;line-height:23px}.FooterLinks-footerLinks-2ilr- a.FooterLinks-footerLink-3mBpV{color:#cfd4d8;display:block;margin-right:16px;margin-top:6px;text-decoration:underline;-webkit-transition:color .2s ease-in-out;transition:color .2s ease-in-out}@media (min-width:820px){.FooterLinks-footerLinks-2ilr- a.FooterLinks-footerLink-3mBpV{display:inline-block;margin-top:0}}.FooterLinks-footerLinks-2ilr- a.FooterLinks-footerLink-3mBpV:hover{color:#dee4ea;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"footerLinks": "FooterLinks-footerLinks-2ilr-",
	"footerLink": "FooterLinks-footerLink-3mBpV"
};
module.exports = exports;
