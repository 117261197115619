// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscriptionPage-headContentWrapper-2thk1{margin-bottom:30px}.SubscriptionPage-subscriptionWrapper-3d20x{margin-bottom:40px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"headContentWrapper": "SubscriptionPage-headContentWrapper-2thk1",
	"subscriptionWrapper": "SubscriptionPage-subscriptionWrapper-3d20x"
};
module.exports = exports;
