/* eslint-disable react/no-array-index-key */
/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import boostrapGrid from 'style/bootstrap-grid.css';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import SectionWrapper from 'components/molecules/SectionWrapper';
import StorylineBox from 'components/organisms/StorylineBox';
import s from './Section.css';

const Section = ({
  box1,
  box2,
  SectionTyp = '',
  textColorOne = '',
  textColorTwo = '',
  marginsTwo = '',
  marginsOne = '',
  backgroundColorOne = '',
  backgroundColorTwo = '',
  verticalAlignmentOne = '',
  horizontalAlignmentOne = '',
  verticalAlignmentTwo = '',
  horizontalAlignmentTwo = '',
  showOutlineLogo1,
  showOutlineLogo2,
  width = '',
  tiltedTop,
  tiltedBottom,
  topMargin = '',
  bottomMargin = '',
  srcOne = '',
  srcTypOne = '',
  srcTwo = '',
  srcTypTwo = '',
  bgOpacityOne,
  bgOpacityTwo,
}) => {
  const setBoxBootstrapColumnsValue = (box, valueForOnlyOneBox, valueForBoxOne, valueForBoxTwo) => {
    if (box === 'OnlyOneBox') {
      return valueForOnlyOneBox;
    }
    if (box === 'BoxOne') {
      return valueForBoxOne;
    }
    if (box === 'BoxTwo') {
      return valueForBoxTwo;
    }
    return '';
  };
  const setMdBoxBootstrapColumns = box => {
    if (width === 'extraWide') {
      return setBoxBootstrapColumnsValue(box, '24,0', '12,0', '12,0');
    }
    if (width === 'default') {
      return setBoxBootstrapColumnsValue(box, '20,2', '10,2', '10,0');
    }
    if (width === 'extraNarrow') {
      return setBoxBootstrapColumnsValue(box, '16,4', '8,4', '8,0');
    }
    return '';
  };

  const setLgBoxBootstrapColumns = box => {
    if (width === 'extraWide') {
      return setBoxBootstrapColumnsValue(box, '24,0', '12,0', '12,0');
    }
    if (width === 'default') {
      return setBoxBootstrapColumnsValue(box, '18,3', '9,3', '9,0');
    }
    if (width === 'extraNarrow') {
      return setBoxBootstrapColumnsValue(box, '12,6', '6,6', '6,0');
    }
    return '';
  };

  return (
    <div
      className={classnames({
        [s.micrositeHeroSection]: SectionTyp === 'heroSection',
        [s.micrositeSection]: SectionTyp === 'section',
      })}
    >
      <SectionWrapper
        box1={box1}
        box2={box2}
        tiltedTop={tiltedTop}
        tiltedBottom={tiltedBottom}
        topMargin={topMargin}
        bottomMargin={bottomMargin}
      >
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(boostrapGrid.row, s.row)}>
            {box1 && (
              <div
                className={getBootstrapColumns(
                  '24,0',
                  `${box2 ? setMdBoxBootstrapColumns('BoxOne') : setMdBoxBootstrapColumns('OnlyOneBox')}`,
                  `${box2 ? setLgBoxBootstrapColumns('BoxOne') : setLgBoxBootstrapColumns('OnlyOneBox')}`
                )}
              >
                <StorylineBox
                  backgroundTyp={backgroundColorOne}
                  horizontalAlignment={horizontalAlignmentOne}
                  verticalAlignment={verticalAlignmentOne}
                  contentGroup={box1?.contentGroup}
                  showOutlineLogo={showOutlineLogo1}
                  box={box1}
                  textColor={textColorOne}
                  margin={marginsOne}
                  src={srcOne}
                  srcTyp={srcTypOne}
                  bgOpacity={bgOpacityOne}
                />
              </div>
            )}

            {box2 && (
              <div
                className={getBootstrapColumns(
                  '24,0',
                  `${box1 ? setMdBoxBootstrapColumns('BoxTwo') : setMdBoxBootstrapColumns('OnlyOneBox')}`,
                  `${box1 ? setLgBoxBootstrapColumns('BoxTwo') : setLgBoxBootstrapColumns('OnlyOneBox')}`
                )}
              >
                <StorylineBox
                  backgroundTyp={backgroundColorTwo}
                  horizontalAlignment={horizontalAlignmentTwo}
                  verticalAlignment={verticalAlignmentTwo}
                  contentGroup={box2?.contentGroup}
                  showOutlineLogo={showOutlineLogo2}
                  box={box2}
                  textColor={textColorTwo}
                  margin={marginsTwo}
                  src={srcTwo}
                  srcTyp={srcTypTwo}
                  bgOpacity={bgOpacityTwo}
                />
              </div>
            )}
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};

export default withStyles(boostrapGrid, s)(Section);
