import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { format, isToday } from 'date-fns';
import { sv, enGB } from 'date-fns/locale';
import getTranslation from './getTranslation';

export default createSelector(cookSelectors.getData, getTranslation, (data, { LANG }) => {
  try {
    const dateToUse = data?.context?.published;

    if (dateToUse !== undefined && dateToUse !== null) {
      const date = new Date(dateToUse);
      return isToday(date)
        ? LANG === 'sv'
          ? 'Idag'
          : 'Today'
        : format(date, 'd MMMM yyyy', { locale: LANG === 'sv' ? sv : enGB });
    }
    return undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
