// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PlayButtonImage-imageWrapper-3AGoJ{position:relative}.PlayButtonImage-iconContainer-1z3Df{height:100%;position:absolute;width:100%}.PlayButtonImage-iconContainer-1z3Df,.PlayButtonImage-iconWrapper-3FPMm{-ms-flex-pack:center;-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;justify-content:center}.PlayButtonImage-iconWrapper-3FPMm{background-color:#fff;border-radius:50%;cursor:pointer;height:60px;opacity:.95;width:60px}.PlayButtonImage-icon-3qLLj{color:#ea8f12;font-size:40px;margin-left:5px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"imageWrapper": "PlayButtonImage-imageWrapper-3AGoJ",
	"iconContainer": "PlayButtonImage-iconContainer-1z3Df",
	"iconWrapper": "PlayButtonImage-iconWrapper-3FPMm",
	"icon": "PlayButtonImage-icon-3qLLj"
};
module.exports = exports;
