import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import _size from 'lodash/size';
import clientifyUrl from '../utils/clientifyUrl';

export default createSelector(cookSelectors.getData, data => {
  try {
    const indepth =
      data?.context?.indepth ||
      data?.tagInfo?.[0]?.indepth ||
      data?.context?.deskedContent?.selectedCurrentIssues;

    if (!(_size(indepth) > 0)) return undefined;

    return indepth.map(item => {
      const href = item?.content?.binary?.href || item?.content?.href || undefined;
      return {
        ...item.content,
        href: clientifyUrl(href),
      };
    });
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
