import { createSelector } from 'reselect';
import getPublishedDateBlogPost from 'selectors/getPublishedDateBlogPost';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getArticleAuthors from 'selectors/getArticleAuthors';
import getMetdata from 'selectors/metadata/getMetdata';
import getTags from 'selectors/getTags';
import getBlogName from 'selectors/getBlogName';
import getBlogUniqueName from 'selectors/getBlogUniqueName';
import getBlogHeroImage from 'selectors/getBlogHeroImage';
import getLatestBlogPosts from 'selectors/getLatestBlogPosts';
import getBlogPostPagination from 'selectors/getBlogPostPagination';

export default createSelector(
  getPublishedDateBlogPost,
  getGroupedStorylineTree,
  getArticleAuthors,
  getMetdata,
  getTags,
  getBlogName,
  getBlogUniqueName,
  getBlogHeroImage,
  getLatestBlogPosts,
  getBlogPostPagination,
  (
    publishedDate,
    groupedStorylineTree,
    authors,
    metadata,
    tags,
    blogName,
    blogUniqueName,
    heroImage,
    latestBlogPosts,
    blogPostPagination
  ) => {
    return {
      publishedDate,
      groupedStorylineTree,
      authors,
      metadata,
      tags,
      blogName,
      blogUniqueName,
      heroImage,
      latestBlogPosts,
      blogPostPagination,
    };
  }
);
