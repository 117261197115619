// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormSuccess-formSuccess-2Uopt{background-color:#eaf7f1;border:1px solid #30b079;border-radius:2px;padding:16px}.FormSuccess-infoCircle-3wa68{color:#30b079;height:26px;margin-bottom:2px;margin-right:8px}.FormSuccess-headline-38o-_{-ms-flex-align:center;align-items:center;color:#30b079;display:-ms-flexbox;display:flex;font-family:LabGrotesque-Bold,sans-serif;font-size:1.5rem;line-height:2.25rem;margin-bottom:12px}@media (min-width:541px){.FormSuccess-formSuccess-2Uopt{padding:40px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"formSuccess": "FormSuccess-formSuccess-2Uopt",
	"infoCircle": "FormSuccess-infoCircle-3wa68",
	"headline": "FormSuccess-headline-38o-_"
};
module.exports = exports;
