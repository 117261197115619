/* eslint-disable react/no-unknown-property */
import React, { useRef, useState, useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { useForm, useFieldArray } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Editor } from '@tinymce/tinymce-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import Typography from 'components/atoms/Typography';
import ButtonSuccess from 'components/atoms/buttons/ButtonSuccess/ButtonSuccess';
import ButtonPrimary from 'components/atoms/buttons/ButtonPrimary/ButtonPrimary';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import s from './ReviewForm.css';
import { getFrontEndOrigin } from '../../../../../utils/apiUtil';

const MAX_TOTAL_FILE_SIZE_IN_MB = 20;
const NOT_ALLOWED_FILE_TYPES = ['application/x-msdownload'];

const ReviewForm = ({ articleId, handleCancelClick }) => {
  const editorRef = useRef(null);
  const [sending, setSending] = useState(false);
  const [initialTextValue, setInitialTextValue] = useState('<p></p>');
  const [successfullySent, setSuccessfullySent] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileSizes, setFileSizes] = useState(0);
  const [fileTypesAllowed, setFileTypesAllowed] = useState(true);
  const [fileTypesErrorId, setFileTypesErrorId] = useState('');
  const localStorageKey = `article-${articleId}`;

  axiosRetry(axios, {
    retries: 3,
    retryDelay: () => 1000,
    retryCondition: e => e?.response?.status >= 500,
  });

  const { control, register, handleSubmit } = useForm();

  const { fields: fileFields, append, remove } = useFieldArray({ control, name: 'files' });

  const loadLocalStorageTextIfExists = () => {
    const textContentFromLocalStorage = window.localStorage.getItem(localStorageKey);
    if (textContentFromLocalStorage?.length > 0) {
      setInitialTextValue(textContentFromLocalStorage);
      window.localStorage.removeItem(localStorageKey);
    }
  };

  const saveLocalStorageText = textContent => {
    window.localStorage.setItem(localStorageKey, textContent);
  };

  const onSubmit = handleSubmit(async () => {
    if (!articleId) {
      toast.error('Artikelns articleId kunde inte laddas');
    } else if (editorRef.current.getContent()?.length === 0) {
      toast.error('Du måste skriva något i textrutan');
    } else {
      setSending(true);
      const endpointUrl = `${getFrontEndOrigin()}/backoffice/api/goa/reviewWithChanges/${articleId}?failIfNotLoggedIn=true`;
      const formDataPayload = new FormData();
      formDataPayload.append(
        'body',
        JSON.stringify({ reviewInput: { html: editorRef.current.getContent() } })
      );
      if (files.length > 0) {
        files.forEach((file, i) => formDataPayload.append(`file-${i + 1}`, file.attachment));
      }

      try {
        await axios.post(endpointUrl, formDataPayload, {
          headers: { 'content-type': 'multipart/form-data' },
          withCredentials: true,
        });
        setSending(false);
        toast.success('Dina ändringsförslag har nu skickats till redaktionen.');
        setSuccessfullySent(true);
      } catch (e) {
        saveLocalStorageText(editorRef.current.getContent());
        // eslint-disable-next-line no-console
        console.error(e);
        try {
          toast(
            () => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="paragraphSmall" gutterBottom>
                  Något gick fel. Prova att ladda om sidan. Vi har sparat texten du skrivit i bakgrunden men
                  för säkerhets skull kan du kopiera det du skrivit till ett separat Word-dokument.
                </Typography>
                <ButtonPrimaryAlt onClick={() => window.location.reload()}>Ladda om sidan</ButtonPrimaryAlt>
              </div>
            ),
            {
              duration: 10000,
            }
          );
          setSending(false);
        } catch (e2) {
          // eslint-disable-next-line no-console
          console.error(e2);
        }
      }
    }
  });

  useEffect(() => {
    loadLocalStorageTextIfExists();
  }, []);

  useEffect(() => {
    if (files.length > 0) {
      const totalSize = files
        .map(file => {
          setFileTypesAllowed(!NOT_ALLOWED_FILE_TYPES.includes(file?.attachment?.type));
          if (NOT_ALLOWED_FILE_TYPES.includes(file?.attachment?.type)) {
            setFileTypesErrorId(file?.id);
          }
          return file.attachment.size;
        })
        .reduce((a, b) => a + b);
      setFileSizes(totalSize);
    } else {
      setFileSizes(0);
      setFileTypesAllowed(true);
    }
  }, [files]);

  const onFileSelected = (e, fileId) => {
    if (e?.target?.files[0]) {
      const existingEntry = files.find(file => file.id === fileId);
      if (existingEntry) {
        const newFiles = files.map(file => {
          if (file.id === existingEntry.id) {
            return { id: file.id, attachment: e?.target?.files[0] };
          }
          return file;
        });
        setFiles(newFiles);
      } else {
        setFiles([...files, { id: fileId, attachment: e?.target?.files[0] }]);
      }
    }
  };

  const onAddFileClicked = () => {
    append({ attachment: null });
  };

  const onDeleteFileClicked = (index, fileId) => {
    const newFiles = files.filter(file => file.id !== fileId);
    setFiles(newFiles);
    remove(index);
  };

  return (
    <>
      {successfullySent && (
        <Typography variant="paragraphInfo">
          Dina ändringsförslag har nu skickats till redaktionen. Du kan nu stänga detta fönster.
        </Typography>
      )}
      {!successfullySent && (
        <>
          <form onSubmit={onSubmit} className={s.container}>
            <section className={s.textSection}>
              <Typography variant="h4">Faktagranskning</Typography>
              <Typography variant="paragraphSmall" gutterBottom>
                Beskriv vad du vill ändra i rutan nedan. Du kan även bifoga ett worddokument där du beskriver
                alla ändringar.
              </Typography>
              <Editor
                apiKey="mucmnqmc7vzm9kf3f0hnlntrh4im990r14jmb3m35mn210ze"
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                }}
                initialValue={initialTextValue}
                init={{
                  height: 325,
                  menubar: false,
                  plugins: ['lists', 'link', 'visualblocks'],
                  toolbar: 'h1 h2 h3 link bold bullist numlist ',
                  content_style: 'body { font-family:LabGrotesque-Regular, sans-serif; font-size:16px }',
                  language: 'sv_SE',
                }}
              />
            </section>
            <section className={s.filesSection}>
              <Typography variant="h4">Ladda upp filer</Typography>
              <Typography variant="paragraphSmall">
                Om du vill bifoga filer som har med ändringen av denna sida att göra kan du bifoga dem nedan.
              </Typography>
              {fileSizes >= MAX_TOTAL_FILE_SIZE_IN_MB * 1024 * 1024 && (
                <div className={s.fileError}>
                  <Typography variant="paragraphSmall" style={{ color: 'white' }}>
                    Filerna får inte överstiga {MAX_TOTAL_FILE_SIZE_IN_MB} Mb (Din storlek:{' '}
                    {(fileSizes / 1024 / 1024).toFixed(1)} Mb)
                  </Typography>
                </div>
              )}
              {!fileTypesAllowed && (
                <div className={s.fileError}>
                  <Typography variant="paragraphSmall" style={{ color: 'white' }}>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Otillåten filtyp
                  </Typography>
                </div>
              )}
              <div className={s.files}>
                {fileFields.map((file, i) => {
                  return (
                    <div
                      className={classnames(
                        s.fileCard,
                        fileTypesErrorId === file.id ? s.fileCardErrorBorder : null
                      )}
                      key={file.id}
                    >
                      <div className={s.fileName}>
                        <label htmlFor={`file${i}Subject`}>
                          <Typography variant="paragraphSmall" gutterBottomSmall>
                            {files[i]?.attachment?.name ?? `Fil ${i + 1}`}
                            {files[i]?.attachment?.size > 0
                              ? ` (${(files[i].attachment.size / 1024 / 1024).toFixed(2)} Mb)`
                              : ''}
                          </Typography>
                        </label>
                      </div>
                      <div className={s.fileInput}>
                        <input
                          onChange={e => onFileSelected(e, file.id)}
                          className={s.file}
                          type="file"
                          {...register(`files.${i}.attachment`)}
                          id={`files.${i}.attachment`}
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor={`files.${i}.attachment`}>Välj fil</label>
                      </div>
                      <button
                        className={s.close}
                        type="button"
                        aria-label="Ta bort"
                        onClick={() => onDeleteFileClicked(i, file.id)}
                      >
                        <FontAwesomeIcon icon={faClose} style={{ height: 14, color: '#33414e' }} />
                      </button>
                    </div>
                  );
                })}
              </div>
              <div>
                {fileFields.length === files.length && (
                  <ButtonPrimaryAlt onClick={onAddFileClicked}>
                    {fileFields.length === 0 ? 'Lägg till fil' : 'Lägg till en till fil'}
                  </ButtonPrimaryAlt>
                )}
              </div>
            </section>
          </form>
          <div className={s.btnContainer}>
            <ButtonSuccess
              disabled={fileSizes >= MAX_TOTAL_FILE_SIZE_IN_MB * 1024 * 1024 || !fileTypesAllowed}
              className={sending ? s.loading : ''}
              loading={sending}
              type="submit"
              onClick={onSubmit}
            >
              Skicka till redaktionen
            </ButtonSuccess>
            <ButtonPrimary onClick={handleCancelClick}>Avbryt</ButtonPrimary>
          </div>
        </>
      )}
    </>
  );
};

export default withStyles(s)(ReviewForm);
