import * as colors from 'style/variables.css';
import propsToAttributes from './propsToAttributes';

const getSNColorByCueColor = cueColor => {
  switch (cueColor) {
    case 'orange':
      return colors.orange;
    case 'red':
      return colors.red;
    case 'darkBlue':
      return colors.darkBlue;
    case 'steel':
      return colors.steel;
    case 'lightBlue':
      return colors.lightBlue;
    case 'creamyWhite':
      return colors.cream;
    case 'white':
      return '#FFFFFF';
    default:
      return '#000000';
  }
};

const generateHtmlElement = (text, annotation) => {
  switch (annotation.name) {
    case 'bold':
      return `<strong>${text}</strong>`;
    case 'internal_link':
    case 'inline_link': {
      const { fields, relation } = annotation.value;
      const linkProps = {
        target:
          (fields?.newWindow && '_blank') ||
          (annotation.name === 'inline_link' && fields?.newWindow === undefined && '_blank'),
      };
      const href =
        fields?.uri?.rawText ||
        (relation?.contentType === 'document' && relation?.binary?.href) ||
        relation?.href;
      if (href) {
        return `<a href="${href}" ${propsToAttributes(linkProps)}>${text}</a>`;
      }
      return text;
    }

    case 'strike':
      return `<del>${text}</del>`;
    case 'subscript':
      return `<sub>${text}</sub>`;
    case 'superscript':
      return `<sup>${text}</sup>`;
    case 'annotation_color': {
      const color = annotation?.value?.fields?.filter(item => item.name === 'color');
      if (color?.length > 0) {
        return `<span style="color: ${getSNColorByCueColor(color[0]?.value)};">${text}</span>`;
      }
      return text;
    }
    default:
      return text;
  }
};

export default generateHtmlElement;
