// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MinSidaMenu-menu-126_e,.MinSidaMenu-menu-126_e .MinSidaMenu-menuItem-1uhdm{background-color:#fbfaf8}.MinSidaMenu-menu-126_e .MinSidaMenu-menuItem-1uhdm{-ms-flex-align:center;align-items:center;border-bottom:1px solid #e7ebef;display:-ms-flexbox;display:flex;font-weight:700;gap:10px;height:60px;padding-left:30px}.MinSidaMenu-menu-126_e .MinSidaMenu-menuItem-1uhdm .MinSidaMenu-linkItem-1A2mz{color:#000}.MinSidaMenu-menu-126_e .MinSidaMenu-menuItem-1uhdm:hover{background-color:#f0f0ed;cursor:pointer}.MinSidaMenu-menu-126_e .MinSidaMenu-topMenu-3hJFP{display:none}@media (min-width:820px){.MinSidaMenu-menu-126_e{max-width:400px;min-width:300px}.MinSidaMenu-menu-126_e .MinSidaMenu-topMenu-3hJFP{display:block}.MinSidaMenu-menu-126_e .MinSidaMenu-companiesList-2QaZS{margin-left:10px;padding:0}}@media (min-width:1169px){.MinSidaMenu-menu-126_e .MinSidaMenu-companiesList-2QaZS{margin-left:0;padding:10px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"menu": "MinSidaMenu-menu-126_e",
	"menuItem": "MinSidaMenu-menuItem-1uhdm",
	"linkItem": "MinSidaMenu-linkItem-1A2mz",
	"topMenu": "MinSidaMenu-topMenu-3hJFP",
	"companiesList": "MinSidaMenu-companiesList-2QaZS"
};
module.exports = exports;
