import React, { useState } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import history from 'utils/history';
import Typography from 'components/atoms/Typography';
import Checkbox from 'components/atoms/Checkbox';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import SearchBar from 'components/molecules/SearchBar';
import SearchResultItem from 'components/molecules/SearchResultItem';
import Modal from 'components/organisms/Modal';
import pickFrom from 'utils/pickFrom';
import exists from 'utils/exists';
import firstValueInQueryObject from 'utils/firstValueInQueryObject';
import Link from 'containers/atoms/LinkContainer';
import boostrapGrid from 'style/bootstrap-grid.css';
import useTranslate from 'utils/hooks/useTranslate';
import FilterBlock from './components/FilterBlock';
import FilterSelectedBlock from '../ListPage/components/FilterSelectedBlock';
import s from './SearchResultPage.css';

const buildSearchString = (searchTerm, filters) => {
  let query = '/sokresultat/?q=';
  if (searchTerm) {
    query += encodeURIComponent(searchTerm);
  }
  Object.keys(filters).forEach(key => {
    if (filters[key] && key !== 'q') {
      filters[key].forEach(val => {
        query += `&${key}=${encodeURIComponent(val)}`;
      });
    }
  });
  return query;
};

const SearchResultPage = ({
  searchQuery,
  queryString,
  searchMetadata,
  searchResults,
  searchFacets,
  navigate,
}) => {
  const translate = useTranslate();

  const [state, setState] = useState({
    modalOpen: false,
    searchPhrase: '',
  });

  const [queryStrings, setQueryStrings] = useState({
    ...queryString,
    start: undefined,
  });
  const triggerFilter = filters => {
    const searchPath = buildSearchString(searchQuery.q, filters);
    history.push(searchPath, { scrollPosition: window.scrollY }); // do SPA navigation
    navigate(searchPath, window.scrollY);
  };

  const unselectFilter = (filterId, filterValue) => {
    const newQueryString = {
      ...queryString,
      start: undefined,
    };

    newQueryString[filterId] = newQueryString[filterId].filter(val => val !== filterValue);
    setQueryStrings(newQueryString);
    triggerFilter(newQueryString);
  };

  const selectFilter = (filterId, value) => {
    const newQueryString = {
      ...queryString,
      start: undefined,
    };
    if (!newQueryString[filterId]) newQueryString[filterId] = [];
    newQueryString[filterId].push(value);
    setQueryStrings(newQueryString);

    triggerFilter(newQueryString);
  };

  const replaceFilter = (filterId, value) => {
    const newQueryString = {
      ...queryString,
      start: undefined,
    };
    newQueryString[filterId] = [value];
    setQueryStrings(newQueryString);
    triggerFilter(newQueryString);
  };

  const setSearchPhrase = searchPhrase => {
    setState({
      ...state,
      searchPhrase,
    });
  };

  const clearFilters = () => {
    setQueryStrings({});

    triggerFilter({});
  };

  const getItem = (itemsString, items) => {
    let text = '';

    for (let i = 0; i < items.length; i++) {
      text += `&${itemsString}=${items[i]}`;
    }
    return text;
  };

  const handleSubmitWithFilter = e => {
    e.preventDefault();
    let searchPath = `/sokresultat/?q=*`;
    if (exists(state.searchPhrase)) {
      // searchPath += `?q=${encodeURIComponent(state.searchPhrase)}`;
      searchPath = searchPath.replace('?q=*', `?q=${encodeURIComponent(state.searchPhrase)}`);
    }
    if (queryStrings.contenttype !== undefined) {
      searchPath += getItem('contenttype', queryStrings.contenttype);
    }
    if (queryStrings.focusarea !== undefined) {
      searchPath += getItem('focusarea', queryStrings.focusarea);
    }
    if (queryStrings.year !== undefined) {
      searchPath += getItem('year', queryStrings.year);
    }
    if (queryStrings.issue !== undefined) {
      searchPath += getItem('issue', queryStrings.issue);
    }
    history.push(searchPath, { scrollPosition: 0 }); // do SPA navigation
    navigate(searchPath);
  };

  const handleSubmit = e => {
    e.preventDefault();
    let searchPath = `/sokresultat/`; // const scrollPosition = typeof window !== 'undefined' ? window.scrollY : undefined;
    if (exists(state.searchPhrase)) {
      searchPath += `?q=${encodeURIComponent(state.searchPhrase)}`;
    }
    history.push(searchPath, { scrollPosition: 0 }); // do SPA navigation
    navigate(searchPath);
  };

  const resetSearch = () => {
    setSearchPhrase('');
    history.push('/sokresultat/', { scrollPosition: 0 }); // do SPA navigation
    navigate('/sokresultat/');
  };

  const sortItem =
    firstValueInQueryObject(queryString, 'sort') || firstValueInQueryObject(searchQuery, 'sort');
  const [checked, setChecked] = useState(false);

  return (
    <div className={s.searchResultPage}>
      <div className={classnames(boostrapGrid.container)}>
        <div className={classnames(boostrapGrid.row)}>
          <div className={getBootstrapColumns('20,2', '16,4', '13,1')}>
            <Modal
              modalOpen={state.modalOpen}
              closeModal={() => setState({ ...state, modalOpen: false })}
              label="Filtrera sökresultat"
            >
              <FilterBlock
                searchQuery={searchQuery.q?.[0]}
                facets={searchFacets}
                filters={queryString || searchQuery}
                selectFilter={selectFilter}
                unselectFilter={unselectFilter}
                clearFilters={clearFilters}
                mobileMode
                closeModal={() => setState({ ...state, modalOpen: false })}
              />
            </Modal>
            <div className={s.mainContent}>
              <Typography variant="h2" component="h1" gutterBottom classes={s.pageHeadline}>
                {(() => {
                  const q = firstValueInQueryObject(searchQuery, 'q');
                  return q && q !== 'undefined' && q !== '*' ? (
                    <>
                      Sökresultat för ”{q}”
                      <button type="button" className={s.removeButton} onClick={resetSearch}>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className={s.removeIcon}
                          style={{ width: 20, height: 20 }}
                        />
                      </button>
                    </>
                  ) : (
                    'Sökresultat'
                  );
                })()}
              </Typography>
              <div className={s.searchWrapper}>
                <SearchBar
                  autoComplete="off"
                  classes={s.searchBar}
                  onSubmit={!checked ? handleSubmit : handleSubmitWithFilter}
                  placeholder="Sök"
                  searchButton
                  searchTerm={state.searchPhrase}
                  handleChange={e => setSearchPhrase(e.target.value)}
                />
                <ButtonPrimaryAlt
                  classes={s.filterButton}
                  onClick={() => setState({ ...state, modalOpen: true })}
                >
                  Filtrera
                </ButtonPrimaryAlt>
              </div>
              {queryString &&
                (queryString.focusarea ||
                  queryString.contenttype ||
                  queryString.issue ||
                  queryString.year) && (
                  <div className={s.checkboxMobile}>
                    <p>
                      <Checkbox
                        id="activeFilterCheckbox"
                        label={translate('LIST_PAGE.KEEP_ACTIVE_FILTERS_ON_SEARCH')}
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                      />
                    </p>
                  </div>
                )}

              <FilterSelectedBlock
                searchQuery={searchQuery.q?.[0]}
                facets={searchFacets}
                filters={queryString || searchQuery}
                selectFilter={selectFilter}
                unselectFilter={unselectFilter}
                clearFilters={clearFilters}
                mobileMode
              />
              <div className={s.sortItemsContainer}>
                <div>
                  <Typography variant="contentTypeDate" classes={s.resultHeadlines}>
                    TRÄFFAR:
                  </Typography>
                  {searchResults.length === 0 ? (
                    <span className={s.mediumFont}>{searchMetadata.resultCount}</span>
                  ) : (
                    <span className={s.mediumFont}>{`${
                      +firstValueInQueryObject(searchQuery, 'start') + 1
                    } - ${+firstValueInQueryObject(searchQuery, 'start') + searchResults.length} av ${
                      searchMetadata.resultCount
                    }`}</span>
                  )}
                </div>
                <div>
                  <Typography variant="contentTypeDate" classes={s.resultHeadlines}>
                    SORTERA:
                  </Typography>
                  {sortItem === 'score' ? (
                    <span className={classnames(s.mediumFont, s.sortItem)}>Relevans</span>
                  ) : (
                    <Typography
                      classes={s.sortItem}
                      component="button"
                      variant="linkSmall"
                      onClick={() => replaceFilter('sort', 'score')}
                    >
                      Relevans
                    </Typography>
                  )}
                  {sortItem === 'publishdate' ? (
                    <span className={classnames(s.mediumFont, s.sortItem)}>Datum</span>
                  ) : (
                    <Typography
                      classes={s.sortItem}
                      component="button"
                      variant="linkSmall"
                      onClick={() => replaceFilter('sort', 'publishdate')}
                    >
                      Datum
                    </Typography>
                  )}
                </div>
              </div>
              {searchResults?.map(item => {
                const imageHref = pickFrom(item.images, 'images.BASE.LANDSCAPE.href_full');
                const imageWidth =
                  item?.images?.images?.BASE?.LANDSCAPE?.sourceWidth ||
                  item?.images?.images?.BASE?.LANDSCAPE?.width;
                const imageHeight =
                  item?.images?.images?.BASE?.LANDSCAPE?.sourceHeight ||
                  item?.images?.images?.BASE?.LANDSCAPE?.height;

                const imageData = {
                  originalSize: pickFrom(item, 'images.originalSize'),
                  freeFormSourceWidth: pickFrom(item.images, 'images.BASE.FREEFORM.sourceWidth'),
                  freeFormSourceHeight: pickFrom(item.images, 'images.BASE.FREEFORM.sourceHeight'),
                };

                return (
                  <SearchResultItem
                    key={item.id}
                    href={item.href}
                    contentType={item?.fields?.eventType || item?.contentType}
                    title={item.title}
                    assignment={item.fields.assignment}
                    description={item.fields.description}
                    teaser={item.fields.teaser}
                    from={item.fields.startDate}
                    to={item.fields.endDate}
                    hideTimeInformation={item.fields.hideTimeInformation}
                    deadlineDate={item.fields.deadlineDate}
                    location={item.fields.eventTown}
                    published={item.published}
                    imageHref={imageHref}
                    imageAlt="Kompetens"
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                    binaryExtension={item?.binary?.extension}
                    binaryHref={item?.binary?.href}
                    imageData={imageData}
                  />
                );
              })}
              <div />
            </div>
            <div className={s.showMoreButtons}>
              {searchMetadata.previousPage && searchMetadata.previousPage !== 'undefined' && (
                <ButtonSecondaryGhost
                  key={`${searchMetadata.previousPage}-prevbutton`}
                  component={Link}
                  disabled={searchMetadata.previousPage === 'undefined'}
                  to={
                    searchMetadata.previousPage === 'undefined'
                      ? undefined
                      : `/sokresultat/${searchMetadata.previousPage}`
                  }
                  classes={s.showMoreButton}
                >
                  <FontAwesomeIcon icon={faChevronLeft} className={s.cheveronLeft} />
                  Föregående sida
                </ButtonSecondaryGhost>
              )}
              {searchMetadata.nextPage && searchMetadata.nextPage !== 'undefined' && (
                <ButtonSecondaryGhost
                  key={`${searchMetadata.nextPage}-nextbutton`}
                  component={Link}
                  disabled={searchMetadata.nextPage === 'undefined'}
                  to={
                    searchMetadata.nextPage === 'undefined'
                      ? undefined
                      : `/sokresultat/${searchMetadata.nextPage}`
                  }
                  classes={s.showMoreButton}
                >
                  Nästa sida
                  <FontAwesomeIcon icon={faChevronRight} className={s.cheveronRight} />
                </ButtonSecondaryGhost>
              )}
            </div>
          </div>

          <div className={classnames(getBootstrapColumns('22,1', '22,1', '8,2'))}>
            <div>
              <FilterSelectedBlock
                searchQuery={searchQuery.q?.[0]}
                facets={searchFacets}
                filters={queryString || searchQuery}
                selectFilter={selectFilter}
                unselectFilter={unselectFilter}
                clearFilters={clearFilters}
                mobileMode={false}
              />
            </div>
            {queryString &&
              (queryString.focusarea || queryString.contenttype || queryString.issue || queryString.year) && (
                <div className={s.checkboxDesktop}>
                  <p className={s.checkboxDesktopStyle}>
                    <Checkbox
                      id="activeFilterCheckbox"
                      label={translate('LIST_PAGE.KEEP_ACTIVE_FILTERS_ON_SEARCH')}
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    {/* <input onClick={() => setChecked(!checked)} checked={checked} type="checkbox" />
                  <span style={{ marginLeft: '5px' }}>{translate('LIST_PAGE.KEEP_ACTIVE_FILTERS_ON_SEARCH')}</span> */}
                  </p>
                </div>
              )}

            <div className={s.sideContent}>
              <FilterBlock
                searchQuery={searchQuery.q?.[0]}
                facets={searchFacets}
                filters={queryString || searchQuery}
                selectFilter={selectFilter}
                unselectFilter={unselectFilter}
                clearFilters={clearFilters}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(SearchResultPage);
