// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SpeakerBox-speakerbox-1tgJ1{margin-bottom:1.5rem}.SpeakerBox-speakerbox-1tgJ1 .SpeakerBox-eventCard-3Gok7{padding:24px}.SpeakerBox-speakerbox-1tgJ1 .SpeakerBox-eventItem-1xqsj .SpeakerBox-eventItemSubItem-37UEW{margin-top:6px}@media (min-width:820px){.SpeakerBox-speakerbox-1tgJ1 .SpeakerBox-eventItem-1xqsj .SpeakerBox-eventItemSubItem-37UEW{margin-left:114px}}.SpeakerBox-speakerbox-1tgJ1 .SpeakerBox-eventItem-1xqsj:not(:first-child){padding-top:24px}.SpeakerBox-speakerbox-1tgJ1 .SpeakerBox-eventItem-1xqsj:not(:last-child){border-bottom:1px solid #dee4ea;padding-bottom:24px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"speakerbox": "SpeakerBox-speakerbox-1tgJ1",
	"eventCard": "SpeakerBox-eventCard-3Gok7",
	"eventItem": "SpeakerBox-eventItem-1xqsj",
	"eventItemSubItem": "SpeakerBox-eventItemSubItem-37UEW"
};
module.exports = exports;
