/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import VariantSimple from './VariantSimple/VariantSimple';
import VariantMatrix from './VariantMatrix/VariantMatrix';
import MatrixListWithTextOnImage from '../../organisms/MatrixListWithTextOnImage/MatrixListWithTextOnImage';
import clientifyUrl from '../../../utils/clientifyUrl';
import ArticleCarousel from '../../organisms/ArticleCarousel/ArticleCarousel';

const RelatedArticles = ({ classes, articles, fields = {}, darkMode }) => {
  const filteredArticles = articles.filter(art => art !== undefined);

  if (filteredArticles.length === 0) {
    return null;
  }

  const title = fields?.title?.rawText || 'Läs också';
  const variant = fields?.variant?.rawText || 'simpleLinkList';

  if (variant === 'simpleLinkList' || variant === 'simpleLinkListAlwaysInBody') {
    return (
      <VariantSimple
        darkMode={darkMode}
        variant={variant}
        title={title}
        classes={classes}
        articles={articles}
      />
    );
  }

  if (variant === 'matrixList') {
    return <VariantMatrix darkMode={darkMode} title={title} classes={classes} articles={articles} />;
  }

  if (variant === 'matrixListTextOnImage') {
    const articlesData = articles.map(article => ({
      id: article.id,
      href: clientifyUrl(article.href),
      imageSrc: article?.relation?.teaserImage?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full,
      imageAlt: article?.relation?.teaserImage?.[0]?.content?.fields?.alttext,
      title: article.headline,
      caption: null,
    }));

    return <MatrixListWithTextOnImage articles={articlesData} />;
  }

  if (variant === 'redSlider' || variant === 'blueGrayGradientSlider') {
    return <ArticleCarousel articles={articles} bg={variant} />;
  }

  return null;
};

export default RelatedArticles;
