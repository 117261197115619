import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import getImages from 'utils/getImages';
import generateSizes from 'utils/generateSizes';
import defaultDesktop from './default_dekstop.jpg';
import defaultMobile from './default_mobile.jpg';
import s from './AlsoReadItem.css';

const AlsoReadItem = ({
  classes,
  href,
  headline,
  imageSrc,
  imageAlt = '',
  imageWidth,
  imageHeight,
  ...other
}) => {
  const classNames = classnames(s.alsoReadItem, classes);
  const sizes = getImages('LANDSCAPE');
  const sizesMobile = getImages('SQUARE');
  const imageSizes = generateSizes({ lg: '209px', md: '27vw', default: '37vw' });
  const imageSizesMobile = generateSizes({ default: '90px' });
  const defaultImageWidth = 380;
  const defaultImageHeight = 213;

  let widthHeight;
  if (imageWidth && imageHeight) {
    widthHeight = {
      width: imageSrc ? imageWidth : defaultImageWidth,
      height: imageSrc ? imageHeight : defaultImageHeight,
    };
  } else if (!imageSrc) {
    widthHeight = { width: defaultImageWidth, height: defaultImageHeight };
  } else {
    widthHeight = {};
  }

  return (
    <Link to={href} className={classNames} {...other}>
      <div className={s.desktop}>
        <div className={s.imageWrapper}>
          <ResponsiveImage
            src={imageSrc ? sizes.toSrc(imageSrc, 'S') : defaultDesktop}
            srcSet={imageSrc && sizes.toSrcSet(imageSrc)}
            sizes={imageSrc && imageSizes}
            alt={imageAlt || headline}
            {...widthHeight}
          />
        </div>
        <Typography variant="paragraph" component="h3" classes={s.headline}>
          {headline}
        </Typography>
      </div>
      <div className={s.mobile}>
        <Typography classes={s.mobileTypo} component="h3">
          {headline}
        </Typography>
        <div className={s.imageWrapper}>
          <img
            src={imageSrc ? sizesMobile.toSrc(imageSrc, 'XS') : defaultMobile}
            srcSet={imageSrc && sizesMobile.toSrcSet(imageSrc)}
            sizes={imageSrc && imageSizesMobile}
            alt={imageAlt || headline}
          />
        </div>
      </div>
    </Link>
  );
};

export default withStyles(s)(AlsoReadItem);
