import { connect } from 'react-redux';
import PageContent from 'components/templates/PageContent';
import ErrorPageContainer from 'containers/pages/ErrorPageContainer';
import getMainPageType from 'selectors/getMainPageType';
import getDarkMode from 'selectors/getDarkMode';
import getMainPageWhiteBg from '../../selectors/getMainPageWhiteBg';

const mapStateToProps = state => ({
  PageType: getMainPageType(state) || ErrorPageContainer,
  darkMode: getDarkMode(state) && true,
  darkModeType: getDarkMode(state),
  isWhiteBg: getMainPageWhiteBg(state),
});

const mapDispatchToProps = undefined;

const MainContentContainer = connect(mapStateToProps, mapDispatchToProps)(PageContent);

export default MainContentContainer;
