import gb from 'assets/svg/gb.svg';
import sv from 'assets/svg/sv.svg';

const icons = {
  'flag-sv': {
    src: sv,
    alt: 'Svenska flaggan',
  },
  'flag-gb': {
    src: gb,
    alt: 'Engelska flaggan',
  },
};

export default icons;
