// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".List-list-3N4ow{list-style:none;margin-bottom:16px;margin-top:0;padding-left:0}", ""]);
// Exports
exports.locals = {
	"list": "List-list-3N4ow"
};
module.exports = exports;
