/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import renderFactsStorylineElement from 'utils/renderFactsStorylineElement';
import s from './FAQAnswer.css';

const FAQAnswer = ({ classes, imageSrc, storyline = [] }) => {
  const classNames = classnames(classes, s.factsBox, !imageSrc && s.factsBoxNoImage);

  return (
    <div className={classNames}>
      <div className={s.factsBoxContent}>
        <div className={s.factsBoxFlexContent}>
          <div className={s.factsBoxText}>
            <div className={classnames(s.factsBoxTextContainer)}>
              {storyline &&
                storyline.map((el, idx) =>
                  renderFactsStorylineElement(el, idx !== 0, idx !== storyline.length - 1)
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(FAQAnswer);
