import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';
import extractImages from 'utils/extractImages';
import clientifyUrl from '../utils/clientifyUrl';

const normalizePuffzData = puffz => {
  return puffz.map(puff => ({
    id: puff.id,
    href: puff.href,
    imageSrc: puff.images.images.BASE.LANDSCAPE.href_full,
    imageAlt: puff.images.altText,
    title: puff.title,
    caption: puff.caption,
  }));
};

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      const puffzArr = pickFrom(data, 'context.deskedContent.pageTeasers');

      const puffz = puffzArr.map(obj => {
        const puffzTeaserArr = pickFrom(obj, 'teaserOptions');

        const teaserOptions = puffzTeaserArr.reduce((acc, val) => {
          acc[val.key] = val.key === 'useSummaryFields' ? val.value === 'true' : val.value;
          return acc;
        }, {});

        const fields = pickFrom(obj, 'content.fields');
        const teaserImage = pickFrom(obj, 'content.teaserImage');
        const href = pickFrom(obj, 'content.href');

        return {
          href: clientifyUrl(href),
          title: teaserOptions.useSummaryFields ? teaserOptions.title : pickFrom(obj, 'content.title'),
          id: pickFrom(obj, 'content.id'),
          teaser: pickFrom(fields, 'teaser'),
          images: teaserImage && extractImages(teaserImage),
          linkText: teaserOptions.linkText,
        };
      });

      return normalizePuffzData(puffz);
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
