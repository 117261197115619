// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ArrowNav-arrowNav-1fvDj{--offset:20%;-ms-flex-pack:center;-ms-flex-align:center;align-items:center;background-color:#dee4ea;border:none;border-radius:50%;color:#3568a7;cursor:pointer;display:none;height:50px;justify-content:center;padding:15px;position:absolute;top:50%;width:50px;z-index:2}@media (min-width:1169px){.ArrowNav-arrowNav-1fvDj{display:-ms-flexbox;display:flex}}.ArrowNav-arrowNav-1fvDj:hover{background-color:#adb8c3}.ArrowNav-arrowNav-1fvDj svg{height:100%!important;width:100%!important}.ArrowNav-arrowNav-1fvDj.ArrowNav-left-1-07f{-ms-flex-pack:end;justify-content:flex-end;left:0;-webkit-transform:translate(calc(-100% - var(--offset)),calc(-50% - 20px));transform:translate(calc(-100% - var(--offset)),calc(-50% - 20px))}.ArrowNav-arrowNav-1fvDj.ArrowNav-left-1-07f svg{margin-left:-5px}.ArrowNav-arrowNav-1fvDj.ArrowNav-right-2BDrI{-ms-flex-pack:start;justify-content:flex-start;right:0;-webkit-transform:translate(calc(100% + var(--offset)),calc(-50% - 20px));transform:translate(calc(100% + var(--offset)),calc(-50% - 20px))}.ArrowNav-arrowNav-1fvDj.ArrowNav-right-2BDrI svg{margin-right:-5px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"arrowNav": "ArrowNav-arrowNav-1fvDj",
	"left": "ArrowNav-left-1-07f",
	"right": "ArrowNav-right-2BDrI"
};
module.exports = exports;
