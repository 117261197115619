import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import reducer from 'utils/reduceTeaserOptions';
import extractImages from 'utils/extractImages';
import pickFrom from 'utils/pickFrom';
import clientifyUrl from '../utils/clientifyUrl';

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      const pressImageProfilesRaw = pickFrom(data, 'context.deskedContent.pressImageProfiles');
      const pressImageProfiles = pressImageProfilesRaw?.map(profile => {
        const profileImage = pickFrom(profile, 'content.profileImage');
        const teaserOptionsRaw = pickFrom(profile, 'teaserOptions');
        const href = pickFrom(profile, 'content.href');
        const highResolutionImagesRaw = pickFrom(profile, 'content.highResolutionImages');
        const highResolutionImages = highResolutionImagesRaw?.map(image => {
          const content = image?.content;
          const fields = content?.fields;
          const metadata = fields?.COM_ESCENIC_DEFAULTMETADATA?.reduce((acc, val) => {
            acc[val.KEY] = val.VALUE;
            return acc;
          }, {});
          return {
            ...content,
            fields: {
              ...fields,
              metadata,
            },
          };
        });
        let teaserOptions;

        try {
          teaserOptions = reducer(teaserOptionsRaw);
        } catch (err) {
          console.error(err);
          return undefined;
        }
        return {
          id: pickFrom(profile, 'content.id'),
          name: teaserOptions?.useSummaryFields ? teaserOptions.title : pickFrom(profile, 'content.title'),
          assignment: teaserOptions?.useSummaryFields
            ? teaserOptions.assignment
            : pickFrom(profile, 'content.fields.assignment'),
          images: profileImage && extractImages(profileImage),
          href: clientifyUrl(href),
          resolutionImages: highResolutionImages,
        };
      });

      return [...pressImageProfiles];
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
