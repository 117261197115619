import React, { useState } from 'react';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import Typography from 'components/atoms/Typography';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faEnvelope, faMobile } from '@fortawesome/pro-light-svg-icons';
import CoworkerFiles from '../CoworkerFiles';
import Modal from '../Modal';
import generateSizes from '../../../utils/generateSizes';
import ResponsiveImage from '../../atoms/ResponsiveImage';
import getImages from '../../../utils/getImages';
import s from './CoworkerProfile.css';
import Translate from '../../../containers/atoms/TranslateContainer';
import useTranslate from '../../../utils/hooks/useTranslate';
import useTranslation from '../../../utils/hooks/useTranslation';

const notEmpty = str => str !== undefined && str !== '';

const CoworkerProfile = ({
  darkMode,
  name,
  fields,
  image,
  profileFields,
  documents = [],
  highResolutionImages = [],
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const translate = useTranslate();
  const translation = useTranslation();
  const { phoneMobile, phoneWorkDirect, emailAddress, showMobile, hideEmail, hidePhone } = profileFields;
  const { assignment, assignmentEng, description, descriptionEng } = fields;
  const assignmentValue = translation.IS_ENGLISH ? assignmentEng?.rawText : assignment?.rawText;
  const descriptionValue = translation.IS_ENGLISH ? descriptionEng?.rawText : description?.rawText;
  const imageSizes = generateSizes({ default: '158px' });

  const tempDocuments = documents.map(document => document.content);

  return (
    <div className={s.container}>
      <Modal
        modalOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        label={translate('COWORKER_PROFILE_PAGE.IMAGES_AND_DOCUMENTS')}
      >
        <CoworkerFiles highResolutionImages={highResolutionImages} documents={tempDocuments} mobileMode />
      </Modal>
      <div className={s.profileWrapper}>
        <div className={s.profilePictureWrapper}>
          {image != null && (
            <ResponsiveImage
              src={getImages('SQUARE').toSrc(image?.href, 'S')}
              srcSet={getImages('SQUARE').toSrcSet(image?.href)}
              sizes={imageSizes}
              width={156}
              height={156}
              lazyLoadWrapperHeight={156}
            />
          )}
        </div>
        <div>
          <Typography variant="h2" darkMode={darkMode}>
            {name}
          </Typography>
          <div className={s.titleText}>{assignmentValue}</div>
          <div className={s.contactContainers}>
            {notEmpty(phoneMobile) && showMobile && (
              <a href={`tel:${phoneMobile}`} className={s.contactLink}>
                <FontAwesomeIcon icon={faMobile} className={s.icon} style={{ width: 13, height: 21 }} />
                {phoneMobile}
              </a>
            )}
            {notEmpty(phoneWorkDirect) && !hidePhone && (
              <a href={`tel:${phoneWorkDirect}`} className={s.contactLink}>
                <FontAwesomeIcon icon={faBriefcase} className={s.icon} style={{ width: 21, height: 20 }} />
                {phoneWorkDirect}
              </a>
            )}
            {notEmpty(emailAddress) && !hideEmail && (
              <a href={`mailto:${emailAddress}`} className={s.contactLink}>
                <FontAwesomeIcon icon={faEnvelope} className={s.icon} style={{ width: 21, height: 20 }} />
                <Translate>COWORKER_PROFILE_PAGE.SEND_EMAIL</Translate>
              </a>
            )}
          </div>
        </div>
      </div>
      {notEmpty(descriptionValue) && (
        <Typography gutterBottom classes={s.profileDescription} darkMode={darkMode}>
          {descriptionValue}
        </Typography>
      )}
      {(highResolutionImages?.length > 0 || documents?.length > 0) && (
        <div className={s.seeMoreButtonWrapper}>
          <ButtonSecondaryGhost classes={s.seeMoreButton} onClick={() => setModalOpen(true)}>
            <Translate>COWORKER_PROFILE_PAGE.SEE_IMAGES_AND_FILES</Translate>(
            {(highResolutionImages?.length || 0) + (documents?.length || 0)})
          </ButtonSecondaryGhost>
        </div>
      )}
    </div>
  );
};

export default withStyles(s)(CoworkerProfile);
