/* eslint-disable no-console */
import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './TnlvFeedItem.css';

const isSameDay = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const TnlvFeedItem = ({ classes, text, date, href, large, first, last, ...other }) => {
  const classNames = classnames(s.TnlvFeedItem, first && s.first, last && s.last, classes);
  const today = new Date();
  const yesterday = new Date(today.getTime());
  yesterday.setDate(today.getDate() - 1);
  const rssDate = new Date(date);
  const hours = rssDate.getHours();
  let minutes = rssDate.getMinutes();
  minutes = minutes > 9 ? minutes : `0${minutes}`;

  const format = { day: 'numeric', month: 'short' };
  const dateConvert = rssDate.toLocaleDateString('sv-SE', format);

  const time = `${hours}:${minutes}`;

  let dateText = '';

  if (isSameDay(rssDate, yesterday)) {
    dateText = 'Igår';
  } else if (!isSameDay(rssDate, today)) {
    dateText = `${dateConvert}`;
  }

  return (
    <a className={classNames} {...other} href={href} target="_blank" rel="noopener noreferrer">
      <div className={s.contentWrapper}>
        <div className={s.dateItem}>
          <span className={s.passedDateText}>{dateText}</span>
          {time}
        </div>
        <div className={s.text}>{text}</div>
      </div>
    </a>
  );
};

export default withStyles(s)(TnlvFeedItem);
