// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WritersList-writersList-CFeo7{background-color:#eff2f5;padding-bottom:3rem}.WritersList-writersList-CFeo7.WritersList-kompetensforsorjning-2v_0h{margin-top:-35px;padding-top:6rem;position:relative}.WritersList-writersList-CFeo7 .WritersList-heading-1LsOG{border-bottom:1px solid #dee4ea;margin-bottom:2rem}.WritersList-writersList-CFeo7 .WritersList-writers-3s8RV{grid-gap:16px;display:grid;gap:16px;grid-template-columns:1fr}@media (min-width:820px){.WritersList-writersList-CFeo7 .WritersList-writers-3s8RV{grid-template-columns:1fr 1fr}}.WritersList-writersList-CFeo7 .WritersList-writers-3s8RV .WritersList-writer-2qx1U{-ms-flex-align:start;align-items:flex-start}.WritersList-writersList-CFeo7 .WritersList-writers-3s8RV .WritersList-writer-2qx1U .WritersList-nameWrapper-3r3GE a{color:#33414e}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"writersList": "WritersList-writersList-CFeo7",
	"kompetensforsorjning": "WritersList-kompetensforsorjning-2v_0h",
	"heading": "WritersList-heading-1LsOG",
	"writers": "WritersList-writers-3s8RV",
	"writer": "WritersList-writer-2qx1U",
	"nameWrapper": "WritersList-nameWrapper-3r3GE"
};
module.exports = exports;
