// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EventPage-topWrapper-1uMT8{position:relative}.EventPage-faq-Q5yXt,.EventPage-hideSmallMobile-1D0d5{display:none}.EventPage-faq-Q5yXt{padding-bottom:64px;padding-top:34px}.EventPage-fadeOut-2h7EQ{opacity:0;-webkit-transition:visibility 0s .5s,opacity .5s linear;transition:visibility 0s .5s,opacity .5s linear;visibility:hidden}.EventPage-fadeIn-33wz8{opacity:1;-webkit-transition:opacity .5s ease-in-out linear;transition:opacity .5s ease-in-out linear;visibility:visible}.EventPage-scrollWrapper-dxh3s{position:relative}@media (min-width:820px){.EventPage-topRowWrapper-36Z3W{padding-left:44px;padding-right:44px}.EventPage-hideTablet-3sFnv{display:none}.EventPage-hideSmallMobile-1D0d5{display:block}.EventPage-leftContentWrapper-3PP-E{margin-right:32px}}@media (min-width:1169px){.EventPage-leftContentWrapper-3PP-E{margin-right:76px}}.EventPage-eventCardLiveHeadImage-2JPHt{color:#62717f;font-size:8rem;position:absolute;right:8%;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}@media (min-width:541px){.EventPage-eventCardLiveHeadImage-2JPHt{right:15%}}.EventPage-eventCardInfoButtomText-33UcZ{text-align:center}svg.EventPage-fileIcon-1KTAD{color:#ea8f12}.EventPage-card-1H5R4,.EventPage-infoVcard--0LAS{margin-bottom:32px}.EventPage-tagsContainer-3xtFw{margin-bottom:80px;margin-top:32px}.EventPage-labelItem-1cOQc{margin:3px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"topWrapper": "EventPage-topWrapper-1uMT8",
	"faq": "EventPage-faq-Q5yXt",
	"hideSmallMobile": "EventPage-hideSmallMobile-1D0d5",
	"fadeOut": "EventPage-fadeOut-2h7EQ",
	"fadeIn": "EventPage-fadeIn-33wz8",
	"scrollWrapper": "EventPage-scrollWrapper-dxh3s",
	"topRowWrapper": "EventPage-topRowWrapper-36Z3W",
	"hideTablet": "EventPage-hideTablet-3sFnv",
	"leftContentWrapper": "EventPage-leftContentWrapper-3PP-E",
	"eventCardLiveHeadImage": "EventPage-eventCardLiveHeadImage-2JPHt",
	"eventCardInfoButtomText": "EventPage-eventCardInfoButtomText-33UcZ",
	"fileIcon": "EventPage-fileIcon-1KTAD",
	"card": "EventPage-card-1H5R4",
	"infoVcard": "EventPage-infoVcard--0LAS",
	"tagsContainer": "EventPage-tagsContainer-3xtFw",
	"labelItem": "EventPage-labelItem-1cOQc"
};
module.exports = exports;
