// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PressReleasePage-article_container-uXllZ .PressReleasePage-stickyContainer-1qIY8{position:relative}@media screen and (min-width:541px){.PressReleasePage-article_container-uXllZ .PressReleasePage-stickyContainer-1qIY8{min-height:21rem}}.PressReleasePage-alsoReadWrapper-3IPCW{background:#eff2f5;padding-bottom:24px;padding-top:40px}.PressReleasePage-secondaryWrapper-1BPmd{padding-left:0;padding-right:0}.PressReleasePage-fadeOut-5BfTi{opacity:0;-webkit-transition:visibility 0s .5s,opacity .5s linear;transition:visibility 0s .5s,opacity .5s linear;visibility:hidden}.PressReleasePage-fadeIn-35iKh{opacity:1;-webkit-transition:opacity .5s linear;transition:opacity .5s linear;visibility:visible}@media (min-width:541px){.PressReleasePage-secondaryWrapper-1BPmd{padding-left:56px;padding-right:56px}}@media (min-width:820px){.PressReleasePage-secondaryWrapper-1BPmd{padding-left:0;padding-right:0}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"article_container": "PressReleasePage-article_container-uXllZ",
	"stickyContainer": "PressReleasePage-stickyContainer-1qIY8",
	"alsoReadWrapper": "PressReleasePage-alsoReadWrapper-3IPCW",
	"secondaryWrapper": "PressReleasePage-secondaryWrapper-1BPmd",
	"fadeOut": "PressReleasePage-fadeOut-5BfTi",
	"fadeIn": "PressReleasePage-fadeIn-35iKh"
};
module.exports = exports;
