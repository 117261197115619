/* eslint-disable css-modules/no-undef-class */
import React, { useState } from 'react';
import classnames from 'classnames';
import ScrollTrigger from 'react-scroll-trigger';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DarkMode from 'components/templates/DarkMode';
import ShareBarContainer from 'containers/molecules/ShareBarContainer';
import renderStorylineElement from 'utils/renderReportStorylineElement';
import CenteredRow from 'components/templates/Grid/CenteredRow';
import ContentTypeDate from 'components/atoms/ContentTypeDate';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import Typography from 'components/atoms/Typography';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import Author from 'components/molecules/Author';
import Modal from 'components/organisms/Modal';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import CollapsibleGroup from 'components/organisms/CollapsibleGroup';
import Tag from 'components/atoms/Tag';
import OrderReportContainer from 'containers/organisms/OrderReportContainer';
import pickFrom from 'utils/pickFrom';
import getImages from 'utils/getImages';
import getTypeIcon from 'utils/getTypeIcon';
import generateSizes from 'utils/generateSizes';
import pushToDataLayer from 'utils/pushToDataLayer';
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './ReportPage.css';
import Translate from '../../../containers/atoms/TranslateContainer';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';

const REPORT_IMAGE_MAX_HEIGHT = 375;

const ReportImage = ({ src, alt, width, height }) => {
  const sizes = getImages('FREEFORM');
  const imageSizes = generateSizes({ lg: '265px', md: '210px', sm: '144px', default: '140px' });
  return (
    <div className={s.reportImage}>
      <ResponsiveImage
        src={sizes.toSrc(src, 'M')}
        srcSet={sizes.toSrcSet(src)}
        sizes={imageSizes}
        alt={alt}
        width={width}
        height={height}
        lazyLoadWrapperMaxHeight={`${REPORT_IMAGE_MAX_HEIGHT}px`}
      />
    </div>
  );
};

const OtherFiles = ({ label, files }) => {
  const onClick = (title, id) => {
    pushToDataLayer('fileDownload', {
      downloadType: label,
      downloadLabel: `${title} (${id})`,
    });
  };
  return (
    <div className={s.otherFiles}>
      {files.map(file => {
        const binary = pickFrom(file, 'content.binary');
        const titel = pickFrom(file, 'title');
        const primaryLink = pickFrom(file, 'primaryLink');
        return (
          <div className={s.otherFilesItem} key={primaryLink + titel}>
            <a
              href={primaryLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => onClick(file?.content?.title, file?.content?.id)}
              className={s.otherFileName}
            >
              {titel}
            </a>
            <div className={s.otherFilesType}>
              <FontAwesomeIcon icon={getTypeIcon(binary?.extension)} className={s.fileIconDownloadSmall} />
              <Typography variant="contentTypeDate" darkMode component="span">
                {binary?.extension?.toUpperCase()}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ReportPage = ({
  classes,
  label,
  titel,
  reportImage,
  tags,
  publishedDate,
  relatedItems,
  authors,
  groupedStorylineTree,
  orderDetails,
  darkModeType,
  metadata,
  contextId,
}) => {
  const [orderOpen, setOrderOpen] = useState(false);
  const classNames = classnames(s.reportPage, classes, s.darkMode);
  const { headGroup, contentGroup } = groupedStorylineTree;
  const file = pickFrom(relatedItems, '[0]');
  const binary = pickFrom(file, 'content.binary');
  const [visible, setVisible] = useState(true);
  const openLink = (event, url) => {
    pushToDataLayer('fileDownload', {
      downloadType: label,
      downloadLabel: `${file?.content?.title} (${file?.content?.id})`,
    });
    if (window) {
      window.open(url, '_blank');
      event.preventDefault();
    }
  };

  return (
    <div className={classNames}>
      <OGShareTags metadata={metadata} />
      <ScrollTrigger onEnter={() => setVisible(true)} onExit={() => setVisible(false)}>
        <Modal modalOpen={orderOpen} closeModal={() => setOrderOpen(false)} label="Beställ rapport">
          <OrderReportContainer
            reportId={file?.content?.id}
            reportTitle={titel}
            webshopArticleNumber={orderDetails?.webshopArticleNumber}
            imageSrc={reportImage?.href}
            imageAlt={reportImage?.altText}
            closeModal={() => setOrderOpen(false)}
          />
        </Modal>
        <DarkMode darkMode darkModeType={darkModeType} classes={classnames(s.topWrapper)}>
          <div className={s.reportHeaderGridWrapper}>
            <div className={s.reportHeaderGrid}>
              <div className={s.reportHeaderText}>
                <ContentTypeDate darkMode contentType="Rapport" date={publishedDate} />
                <div>{headGroup && headGroup.map(el => renderStorylineElement(el, true))}</div>
              </div>
              <div className={s.reportHeaderImage}>
                {reportImage && (
                  <ReportImage
                    src={reportImage.href}
                    alt={reportImage.altText}
                    width={reportImage.width}
                    height={reportImage.height}
                  />
                )}
              </div>
              <div className={s.reportHeaderButtons}>
                <div className={s.buttonsWrapper}>
                  {binary && (
                    <ButtonPrimaryAlt classes={s.downloadButton} onClick={e => openLink(e, file.primaryLink)}>
                      <FontAwesomeIcon
                        icon={getTypeIcon(binary.extension, true)}
                        className={s.fileIconDownload}
                      />
                      <Translate>REPORT_PAGE.DOWNLOAD</Translate>
                    </ButtonPrimaryAlt>
                  )}
                  {orderDetails?.orderable && orderDetails?.webshopArticleNumber && (
                    <ButtonSecondaryGhost
                      inverted
                      classes={s.orderButton}
                      onClick={() => {
                        setOrderOpen(true);
                        pushToDataLayer('webshopAdd', {
                          reportTitle: `${titel} (${contextId})`,
                        });
                        pushToDataLayer(null, {
                          ecommerce: null,
                        });
                        pushToDataLayer('add_to_cart', {
                          ecommerce: {
                            items: [
                              {
                                item_id: contextId,
                                item_name: `${titel} (${contextId})`,
                                quantity: 1,
                              },
                            ],
                          },
                        });
                      }}
                    >
                      Beställ trycksak
                    </ButtonSecondaryGhost>
                  )}
                </div>
                {relatedItems?.length > 1 && (
                  <CollapsibleGroup
                    isInitiallyExpanded={false}
                    readMoreText={
                      <Typography variant="caption" darkMode classes={s.writtenBy}>
                        <Translate>REPORT_PAGE.MORE_FILES</Translate>
                      </Typography>
                    }
                    readLessText={
                      <Typography variant="caption" darkMode classes={s.writtenBy}>
                        <Translate>REPORT_PAGE.MORE_FILES</Translate>
                      </Typography>
                    }
                    darkMode
                  >
                    <OtherFiles label={label} files={relatedItems?.slice(1)} />
                  </CollapsibleGroup>
                )}
              </div>
            </div>
          </div>
        </DarkMode>
        <div className={classnames(boostrapGrid.container)}>
          <div className={s.reportContent}>
            <CenteredRow>
              <div className={classnames(visible === true ? s.fadeIn : s.fadeOut)}>
                <ShareBarContainer />
              </div>
              <div className={s.stickyContainer}>
                <ShareBarContainer hideMobile />
                {contentGroup && contentGroup.map(renderStorylineElement)}
                <div className={s.tagWrapper}>
                  {tags?.map(tag => {
                    const href = `/${tag?.tagUri}`;
                    return (
                      <Tag key={href} href={href}>
                        {tag?.name}
                      </Tag>
                    );
                  })}
                </div>
                {authors && (
                  <div className={s.authorsWrapper}>
                    <Author authors={authors} />
                  </div>
                )}
              </div>
            </CenteredRow>
          </div>
        </div>
      </ScrollTrigger>
    </div>
  );
};

export default withStyles(boostrapGrid, s)(ReportPage);
