const data = [
  {
    id: 1,
    name: 'Afghanistan',
  },
  {
    id: 2,
    name: 'Albanien',
  },
  {
    id: 3,
    name: 'Algeriet',
  },
  {
    id: 4,
    name: 'Andorra',
  },
  {
    id: 5,
    name: 'Angola',
  },
  {
    id: 6,
    name: 'Antigua och Barbuda',
  },
  {
    id: 7,
    name: 'Argentina',
  },
  {
    id: 8,
    name: 'Armenien',
  },
  {
    id: 9,
    name: 'Australien',
  },
  {
    id: 10,
    name: 'Azerbajdzjan',
  },
  {
    id: 11,
    name: 'Bahamas',
  },
  {
    id: 12,
    name: 'Bahrain',
  },
  {
    id: 13,
    name: 'Bangladesh',
  },
  {
    id: 14,
    name: 'Barbados',
  },
  {
    id: 15,
    name: 'Belgien',
  },
  {
    id: 16,
    name: 'Belize',
  },
  {
    id: 17,
    name: 'Benin',
  },
  {
    id: 18,
    name: 'Bhutan',
  },
  {
    id: 19,
    name: 'Bolivia',
  },
  {
    id: 20,
    name: 'Bosnien och Hercegovina',
  },
  {
    id: 21,
    name: 'Botswana',
  },
  {
    id: 22,
    name: 'Brasilien',
  },
  {
    id: 23,
    name: 'Brunei',
  },
  {
    id: 24,
    name: 'Bulgarien',
  },
  {
    id: 25,
    name: 'Burkina Faso',
  },
  {
    id: 26,
    name: 'Burundi',
  },
  {
    id: 27,
    name: 'Centralafrikanska republiken',
  },
  {
    id: 28,
    name: 'Chile',
  },
  {
    id: 29,
    name: 'Colombia',
  },
  {
    id: 30,
    name: 'Costa Rica',
  },
  {
    id: 31,
    name: 'Cypern',
  },
  {
    id: 32,
    name: 'Danmark',
  },
  {
    id: 33,
    name: 'Djibouti',
  },
  {
    id: 34,
    name: 'Dominica',
  },
  {
    id: 35,
    name: 'Dominikanska republiken',
  },
  {
    id: 36,
    name: 'Ecuador',
  },
  {
    id: 37,
    name: 'Egypten',
  },
  {
    id: 38,
    name: 'Ekvatorialguinea',
  },
  {
    id: 39,
    name: 'Elfenbenskusten',
  },
  {
    id: 40,
    name: 'El Salvador',
  },
  {
    id: 41,
    name: 'Eritrea',
  },
  {
    id: 42,
    name: 'Estland',
  },
  {
    id: 43,
    name: 'Etiopien',
  },
  {
    id: 44,
    name: 'Fiji',
  },
  {
    id: 45,
    name: 'Filippinerna',
  },
  {
    id: 46,
    name: 'Finland',
  },
  {
    id: 47,
    name: 'Frankrike',
  },
  {
    id: 48,
    name: 'Förenade arabemiraten',
  },
  {
    id: 49,
    name: 'Gabon',
  },
  {
    id: 50,
    name: 'Gambia',
  },
  {
    id: 51,
    name: 'Georgien',
  },
  {
    id: 52,
    name: 'Ghana',
  },
  {
    id: 53,
    name: 'Grekland',
  },
  {
    id: 54,
    name: 'Grenada',
  },
  {
    id: 55,
    name: 'Guatemala',
  },
  {
    id: 56,
    name: 'Guinea',
  },
  {
    id: 57,
    name: 'Guinea-Bissau',
  },
  {
    id: 58,
    name: 'Guyana',
  },
  {
    id: 59,
    name: 'Haiti',
  },
  {
    id: 60,
    name: 'Honduras',
  },
  {
    id: 61,
    name: 'Indien',
  },
  {
    id: 62,
    name: 'Indonesien',
  },
  {
    id: 63,
    name: 'Irak',
  },
  {
    id: 64,
    name: 'Iran',
  },
  {
    id: 65,
    name: 'Irland',
  },
  {
    id: 66,
    name: 'Island',
  },
  {
    id: 67,
    name: 'Israel',
  },
  {
    id: 68,
    name: 'Italien',
  },
  {
    id: 69,
    name: 'Jamaica',
  },
  {
    id: 70,
    name: 'Japan',
  },
  {
    id: 71,
    name: 'Jordanien',
  },
  {
    id: 72,
    name: 'Kambodja',
  },
  {
    id: 73,
    name: 'Kamerun',
  },
  {
    id: 74,
    name: 'Kanada',
  },
  {
    id: 75,
    name: 'Kap Verde',
  },
  {
    id: 76,
    name: 'Kazakstan',
  },
  {
    id: 77,
    name: 'Kenya',
  },
  {
    id: 78,
    name: 'Kina',
  },
  {
    id: 79,
    name: 'Kirgizistan',
  },
  {
    id: 80,
    name: 'Kiribati',
  },
  {
    id: 81,
    name: 'Komorerna',
  },
  {
    id: 82,
    name: 'Republiken Kongo',
  },
  {
    id: 83,
    name: 'Demokratiska republiken Kongo',
  },
  {
    id: 84,
    name: 'Kosovo',
  },
  {
    id: 85,
    name: 'Kroatien',
  },
  {
    id: 86,
    name: 'Kuba',
  },
  {
    id: 87,
    name: 'Kuwait',
  },
  {
    id: 88,
    name: 'Laos',
  },
  {
    id: 89,
    name: 'Lesotho',
  },
  {
    id: 90,
    name: 'Lettland',
  },
  {
    id: 91,
    name: 'Libanon',
  },
  {
    id: 92,
    name: 'Liberia',
  },
  {
    id: 93,
    name: 'Libyen',
  },
  {
    id: 94,
    name: 'Liechtenstein',
  },
  {
    id: 95,
    name: 'Litauen',
  },
  {
    id: 96,
    name: 'Luxemburg',
  },
  {
    id: 97,
    name: 'Madagaskar',
  },
  {
    id: 98,
    name: 'Makedonien',
  },
  {
    id: 99,
    name: 'Malawi',
  },
  {
    id: 100,
    name: 'Malaysia',
  },
  {
    id: 101,
    name: 'Maldiverna',
  },
  {
    id: 102,
    name: 'Mali',
  },
  {
    id: 103,
    name: 'Malta',
  },
  {
    id: 104,
    name: 'Marocko',
  },
  {
    id: 105,
    name: 'Marshallöarna',
  },
  {
    id: 106,
    name: 'Mauretanien',
  },
  {
    id: 107,
    name: 'Mauritius',
  },
  {
    id: 108,
    name: 'Mexiko',
  },
  {
    id: 109,
    name: 'Mikronesiens federerade stater',
  },
  {
    id: 110,
    name: 'Moçambique',
  },
  {
    id: 111,
    name: 'Moldavien',
  },
  {
    id: 112,
    name: 'Monaco',
  },
  {
    id: 113,
    name: 'Mongoliet',
  },
  {
    id: 114,
    name: 'Montenegro',
  },
  {
    id: 115,
    name: 'Myanmar',
  },
  {
    id: 116,
    name: 'Namibia',
  },
  {
    id: 117,
    name: 'Nauru',
  },
  {
    id: 118,
    name: 'Nederländerna',
  },
  {
    id: 119,
    name: 'Nepal',
  },
  {
    id: 120,
    name: 'Nicaragua',
  },
  {
    id: 121,
    name: 'Niger',
  },
  {
    id: 122,
    name: 'Nigeria',
  },
  {
    id: 123,
    name: 'Nordkorea',
  },
  {
    id: 124,
    name: 'Norge',
  },
  {
    id: 125,
    name: 'Nya Zeeland',
  },
  {
    id: 126,
    name: 'Oman',
  },
  {
    id: 127,
    name: 'Pakistan',
  },
  {
    id: 128,
    name: 'Palau',
  },
  {
    id: 129,
    name: 'Panama',
  },
  {
    id: 130,
    name: 'Papua Nya Guinea',
  },
  {
    id: 131,
    name: 'Paraguay',
  },
  {
    id: 132,
    name: 'Peru',
  },
  {
    id: 133,
    name: 'Polen',
  },
  {
    id: 134,
    name: 'Portugal',
  },
  {
    id: 135,
    name: 'Puerto Rico',
  },
  {
    id: 136,
    name: 'Qatar',
  },
  {
    id: 137,
    name: 'Rumänien',
  },
  {
    id: 138,
    name: 'Rwanda',
  },
  {
    id: 139,
    name: 'Ryssland',
  },
  {
    id: 140,
    name: 'Saint Christopher och Nevis',
  },
  {
    id: 141,
    name: 'Saint Lucia',
  },
  {
    id: 142,
    name: 'Saint Vincent och Grenadinerna',
  },
  {
    id: 143,
    name: 'Salomonöarna',
  },
  {
    id: 144,
    name: 'Samoa',
  },
  {
    id: 145,
    name: 'San Marino',
  },
  {
    id: 146,
    name: 'São Tomé och Príncipe',
  },
  {
    id: 147,
    name: 'Saudiarabien',
  },
  {
    id: 148,
    name: 'Schweiz',
  },
  {
    id: 149,
    name: 'Senegal',
  },
  {
    id: 150,
    name: 'Serbien',
  },
  {
    id: 151,
    name: 'Seychellerna',
  },
  {
    id: 152,
    name: 'Sierra Leone',
  },
  {
    id: 153,
    name: 'Singapore',
  },
  {
    id: 154,
    name: 'Slovakien',
  },
  {
    id: 155,
    name: 'Slovenien',
  },
  {
    id: 156,
    name: 'Somalia',
  },
  {
    id: 157,
    name: 'Spanien',
  },
  {
    id: 158,
    name: 'Sri Lanka',
  },
  {
    id: 159,
    name: 'Storbritannien',
  },
  {
    id: 160,
    name: 'Sudan',
  },
  {
    id: 161,
    name: 'Surinam',
  },
  {
    id: 162,
    name: 'Sverige',
  },
  {
    id: 163,
    name: 'Swaziland',
  },
  {
    id: 164,
    name: 'Sydafrika',
  },
  {
    id: 165,
    name: 'Sydkorea',
  },
  {
    id: 166,
    name: 'Syrien',
  },
  {
    id: 167,
    name: 'Tadzjikistan',
  },
  {
    id: 168,
    name: 'Taiwan',
  },
  {
    id: 169,
    name: 'Tanzania',
  },
  {
    id: 170,
    name: 'Tchad',
  },
  {
    id: 171,
    name: 'Thailand',
  },
  {
    id: 172,
    name: 'Tjeckien',
  },
  {
    id: 173,
    name: 'Togo',
  },
  {
    id: 174,
    name: 'Tonga',
  },
  {
    id: 175,
    name: 'Trinidad och Tobago',
  },
  {
    id: 176,
    name: 'Tunisien',
  },
  {
    id: 177,
    name: 'Turkiet',
  },
  {
    id: 178,
    name: 'Turkmenistan',
  },
  {
    id: 179,
    name: 'Tuvalu',
  },
  {
    id: 180,
    name: 'Tyskland',
  },
  {
    id: 181,
    name: 'Uganda',
  },
  {
    id: 182,
    name: 'Ukraina',
  },
  {
    id: 183,
    name: 'Ungern',
  },
  {
    id: 184,
    name: 'Uruguay',
  },
  {
    id: 185,
    name: 'USA',
  },
  {
    id: 186,
    name: 'Uzbekistan',
  },
  {
    id: 187,
    name: 'Vanuatu',
  },
  {
    id: 188,
    name: 'Vatikanstaten',
  },
  {
    id: 189,
    name: 'Venezuela',
  },
  {
    id: 190,
    name: 'Vietnam',
  },
  {
    id: 191,
    name: 'Vitryssland',
  },
  {
    id: 192,
    name: 'Yemen',
  },
  {
    id: 193,
    name: 'Zambia',
  },
  {
    id: 194,
    name: 'Zimbabwe',
  },
  {
    id: 195,
    name: 'Åland',
  },
  {
    id: 196,
    name: 'Österrike',
  },
  {
    id: 197,
    name: 'Östtimor',
  },
];

export default data;
