import menuItemsSv from './menuItems_sv.json';
import menuItemsEn from './menuItems_en.json';
import translationsSv from '../../translations/sv.json';
import translationsEn from '../../translations/en.json';

const menuItems = { sv: menuItemsSv, en: menuItemsEn };
const translations = { sv: translationsSv, en: translationsEn };

const getRoot = store => store.app;
const getTranslation = store => getRoot(store).translation;
const getMenuItems = store => menuItems[getTranslation(store)] || { links: [], menu: [] };
const getTranslations = store => translations[getTranslation(store)];

export { getMenuItems, getTranslation, getTranslations };
