import extSlugify from 'slugify';

export default function slugify(str) {
  if (/^\d/.test(str)) {
    // eslint-disable-next-line no-param-reassign
    str = `no${str}`;
  }
  return extSlugify(str, {
    lower: true,
    remove: /[*+~.()'"!:@&?]/g,
  });
}
