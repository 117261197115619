// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FocusAreaListSection-focusAreaSection-1dSw0{padding-bottom:2.5rem}.FocusAreaListSection-focusAreaSection-1dSw0 .FocusAreaListSection-sectionTitle-1ZtLo{text-transform:uppercase}.FocusAreaListSection-focusAreaSection-1dSw0.FocusAreaListSection-big-YIoM_ .FocusAreaListSection-sectionTitle-1ZtLo{text-transform:none}@media (min-width:820px){.FocusAreaListSection-focusAreaSection-1dSw0.FocusAreaListSection-big-YIoM_ .FocusAreaListSection-sectionTitle-1ZtLo{margin-bottom:1rem}}.FocusAreaListSection-focusAreaSection-1dSw0.FocusAreaListSection-big-YIoM_ .FocusAreaListSection-focusAreas-FzsD0 .FocusAreaListSection-focusArea-1JuaT{border-bottom:2px solid #cfd4d8;min-height:13.125rem;padding:2.5rem 0 1.5rem}@media (min-width:820px){.FocusAreaListSection-focusAreaSection-1dSw0.FocusAreaListSection-big-YIoM_ .FocusAreaListSection-focusAreas-FzsD0 .FocusAreaListSection-focusArea-1JuaT .FocusAreaListSection-title-FxYZC{margin:0}}.FocusAreaListSection-focusAreaSection-1dSw0.FocusAreaListSection-big-YIoM_ .FocusAreaListSection-focusAreas-FzsD0 .FocusAreaListSection-focusArea-1JuaT .FocusAreaListSection-buttons-1R8u3{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-top:1.5rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"focusAreaSection": "FocusAreaListSection-focusAreaSection-1dSw0",
	"sectionTitle": "FocusAreaListSection-sectionTitle-1ZtLo",
	"big": "FocusAreaListSection-big-YIoM_",
	"focusAreas": "FocusAreaListSection-focusAreas-FzsD0",
	"focusArea": "FocusAreaListSection-focusArea-1JuaT",
	"title": "FocusAreaListSection-title-FxYZC",
	"buttons": "FocusAreaListSection-buttons-1R8u3"
};
module.exports = exports;
