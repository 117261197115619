import { connect } from 'react-redux';
import ListPage from 'components/pages/ListPage';
import { actions as locationActions } from 'ducks/location';
import getSearchQuery from 'selectors/getSearchQuery';
import getQueryString from 'selectors/getQueryString';
import getSearchMetadata from 'selectors/getSearchMetadata';
import getSearchResults from 'selectors/getSearchResults';
import getSearchFacets from 'selectors/getSearchFacets';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getDataPathWithoutParams from 'selectors/getDataPathWithoutParams';

const mapStateToProps = state => {
  const searchFacets = getSearchFacets(state);
  const hideFilters = searchFacets === undefined;
  return {
    searchQuery: getSearchQuery(state),
    queryString: getQueryString(state),
    searchMetadata: getSearchMetadata(state),
    searchResults: getSearchResults(state),
    searchFacets,
    groupedStorylineTree: getGroupedStorylineTree(state),
    pathWithoutParams: getDataPathWithoutParams(state),
    hideFilters,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    navigate: (url, scrollPosition) => dispatch(locationActions.set(url, scrollPosition)),
  };
};

const ListPageContainer = connect(mapStateToProps, mapDispatchToProps)(ListPage);

export default ListPageContainer;
