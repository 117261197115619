/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import renderStorylineElement from 'utils/renderStorylineElement';
import boostrapGrid from 'style/bootstrap-grid.css';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import FocusAreaList from 'components/organisms/FocusAreaList';
import { useSelector } from 'react-redux';
import s from './FocusAreasPage.css';
import { getMetadata } from '../FlexibleSection/duck/selectors';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';

const FocusAreasPage = ({ classes, focusAreasListItems, groupedStorylineTree }) => {
  const classNames = classnames(s.focusAreasPage, classes);
  const metadata = useSelector(getMetadata);

  return (
    <>
      <OGShareTags metadata={metadata} />
      <div className={classNames}>
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(boostrapGrid.row, s.imageRelative)}>
            <div className={getBootstrapColumns('16,4', '22,1', '20,2')}>
              {groupedStorylineTree.headGroup &&
                groupedStorylineTree.headGroup.map(el => renderStorylineElement(el, false))}
            </div>
          </div>
          {focusAreasListItems && (
            <FocusAreaList
              sm="16,4"
              md="22,1"
              lg="20,2"
              listItems={focusAreasListItems}
              classes={s.focusAreasListSmall}
            />
          )}
        </div>
        {focusAreasListItems && (
          <div className={s.focusAreasListBigContainer}>
            <div className={classnames(boostrapGrid.container)}>
              <FocusAreaList sm="16,4" md="22,1" lg="20,2" listItems={focusAreasListItems} big />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withStyles(boostrapGrid, s)(FocusAreasPage);
