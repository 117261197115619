import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import pushToDataLayer from '../../../../utils/pushToDataLayer';
import useCueMenu from '../../../../utils/hooks/queries/useCueMenu';
import Link from '../../../../containers/atoms/LinkContainer';
import useGlobalState from '../../../../zustand/zustand';
import s from './MobileMainMenu.css';

const MenuItem = ({ item }) => {
  const hasChildren = item.children?.length > 0;
  const setMobileMenuOpen = useGlobalState(state => state.pageHeader.mobileMenu.setIsOpen);
  const [isExpanded, setIsExpanded] = useState(item.hasActiveChildren);

  const handleClick = () => {
    setMobileMenuOpen(false);
    pushToDataLayer('topMenuClick', {
      topMenuElementPath: item.path.join(' > '),
    });
  };

  return (
    <li
      className={classnames({
        [s.active]: item.isActive || item.hasActiveChildren,
      })}
      id={item.isActive ? 'active-menu-item' : undefined} // The id is used for smooth scrolling, see zustand.js
    >
      <div>
        <Link onClick={handleClick} to={item.href}>
          {item.text}
        </Link>
        {hasChildren && (
          <button type="button" onClick={() => setIsExpanded(p => !p)}>
            <motion.span
              initial={false}
              animate={{ rotate: isExpanded ? '180deg' : '0deg' }}
              transition={{ duration: 0.3 }}
            >
              <FontAwesomeIcon icon={faChevronDown} className={s.expandIcon} />
            </motion.span>
          </button>
        )}
      </div>
      {hasChildren && (
        <AnimatePresence initial={false}>
          {isExpanded && (
            <motion.ul
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              transition={{ duration: 0.3 }}
            >
              {item.children.map(child => {
                return <MenuItem key={child.id} item={child} />;
              })}
            </motion.ul>
          )}
        </AnimatePresence>
      )}
    </li>
  );
};

const MobileMainMenu = () => {
  const { mainMenu } = useCueMenu({ useDivider: false });

  if (!mainMenu) {
    return null;
  }

  return (
    <div className={s.mobileMainMenu} id="mobile-main-menu">
      <nav>
        <ul>
          {mainMenu.map(menuItem => {
            return <MenuItem key={menuItem.id} item={menuItem} />;
          })}
        </ul>
      </nav>
    </div>
  );
};

export default withStyles(s)(MobileMainMenu);
