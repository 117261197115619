// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FooterPublisher-footerPublisher-1FTke{color:#cfd4d8;font-family:LabGrotesque-Regular,sans-serif;font-size:14px;line-height:23px;margin-top:6px}@media (min-width:820px){.FooterPublisher-footerPublisher-1FTke{text-align:right}}.FooterPublisher-footerPublisher-1FTke a.FooterPublisher-footerPublisherLink-3h9nQ{color:#cfd4d8;display:inline-block;text-decoration:underline;-webkit-transition:color .2s ease-in-out;transition:color .2s ease-in-out}.FooterPublisher-footerPublisher-1FTke a.FooterPublisher-footerPublisherLink-3h9nQ:hover{color:#e4e4e4;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"footerPublisher": "FooterPublisher-footerPublisher-1FTke",
	"footerPublisherLink": "FooterPublisher-footerPublisherLink-3h9nQ"
};
module.exports = exports;
