import { connect } from 'react-redux';
import OrderReport from 'components/organisms/OrderReport';
import { selectors as locationSelectors } from 'ducks/location';

const mapStateToProps = state => {
  return {
    hostname: locationSelectors.getHostname(state),
  };
};

const mapDispatchToProps = undefined;

const OrderReportContainer = connect(mapStateToProps, mapDispatchToProps)(OrderReport);

export default OrderReportContainer;
