// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoggedInHeader-container-3U-7X{background-color:#ea8f12}.LoggedInHeader-container-3U-7X .LoggedInHeader-userLoggedInMenuItem-3_dzh{-ms-flex-align:start;align-items:flex-start;color:#fff;display:-ms-flexbox;display:flex;-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;gap:6px;padding:12px 16px}.LoggedInHeader-container-3U-7X .LoggedInHeader-userLoggedInMenuItem-3_dzh .LoggedInHeader-company-3XhBQ,.LoggedInHeader-container-3U-7X .LoggedInHeader-userLoggedInMenuItem-3_dzh .LoggedInHeader-name-2-99G{font-size:.875rem}.LoggedInHeader-container-3U-7X .LoggedInHeader-userLoggedInMenuItem-3_dzh .LoggedInHeader-company-3XhBQ svg,.LoggedInHeader-container-3U-7X .LoggedInHeader-userLoggedInMenuItem-3_dzh .LoggedInHeader-name-2-99G svg{margin-right:4px;width:12px}.LoggedInHeader-container-3U-7X .LoggedInHeader-userLoggedInMenuItem-3_dzh .LoggedInHeader-name-2-99G{font-family:LabGrotesque-Regular,sans-serif}.LoggedInHeader-container-3U-7X .LoggedInHeader-userLoggedInMenuItem-3_dzh .LoggedInHeader-company-3XhBQ{font-family:LabGrotesque-Medium,sans-serif}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"container": "LoggedInHeader-container-3U-7X",
	"userLoggedInMenuItem": "LoggedInHeader-userLoggedInMenuItem-3_dzh",
	"company": "LoggedInHeader-company-3XhBQ",
	"name": "LoggedInHeader-name-2-99G"
};
module.exports = exports;
