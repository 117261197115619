/* eslint-disable prettier/prettier */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import Typography from '../../Typography';
import s from './QuoteArticleSidebar.css';

const QuoteArticleSidebar = ({ quote, name, darkMode, workTitle, classes, ...other }) => {
  const classNames = classnames(classes, s.quoteArticleSidebar);

  return (
    <div className={classNames} {...other}>
      <Typography variant="quoteArticleSidebar">{quote}</Typography>
      <div className={s.quoteArticleSidebarBottomContainer}>
        <FontAwesomeIcon className={s.quoteArticleSidebarIcon} icon={faQuoteRight} />
        <div>
          <Typography variant="contentTypeDate" component="div" classes={s.quoteArticleSidebarSubtext} darkMode={darkMode}>
            {name}
          </Typography>
          {workTitle && (
            <Typography variant="contentTypeDate" component="div" classes={s.quoteArticleSidebarSubtext} darkMode={darkMode}>
              {workTitle}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(QuoteArticleSidebar);
