import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import blogPostNormalizer from 'utils/blogPostNormalizer';
import getTranslation from './getTranslation';

export default createSelector(cookSelectors.getData, getTranslation, (data, { LANG }) => {
  if (!data) {
    return undefined;
  }

  const posts = data.latestBlogPosts?.map(post => blogPostNormalizer(post, { lang: LANG }));
  const contentType = data?.context?.contentType;
  const currentBlogPostId = data?.context?.id;

  // Filter out the one you are currently on if you're on the blogPost page, otherwise take them all
  return contentType === 'blogPost' ? posts.filter(post => post.id !== currentBlogPostId) : posts;
});
