// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VimeoTestBalk-vimeoTestBalk-3G9f8{background:#b12f26;color:#fff;font-size:1.125rem;line-height:1.5;margin:0 0 16px;padding:16px}.VimeoTestBalk-vimeoTestBalk-3G9f8 strong{font-family:LabGrotesque-Bold,sans-serif}.VimeoTestBalk-vimeoTestBalk-3G9f8 span{display:block;font-size:.875rem;font-style:italic;margin-top:8px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"vimeoTestBalk": "VimeoTestBalk-vimeoTestBalk-3G9f8"
};
module.exports = exports;
