import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import _replace from 'lodash/fp/replace';
import getImages from 'utils/getImages';
import getLatestBlogPosts from 'selectors/getLatestBlogPosts';
import getBlogUniqueName from 'selectors/getBlogUniqueName';
import getBlogName from 'selectors/getBlogName';

const getHeroImage = createSelector(cookSelectors.getData, data => {
  const imgUrl =
    data?.context?.deskedContent?.backgroundImage?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full;

  if (!imgUrl) {
    return undefined;
  }

  return getImages('FREEFORM')?.toSrc(imgUrl, 'XL');
});

const getBlogDescription = createSelector(cookSelectors.getData, data => {
  return data?.context?.deskedContent?.newsletter?.[0]?.content?.fields?.description;
});

const getBlogApsisMailingListId = createSelector(cookSelectors.getData, data => {
  return data?.context?.deskedContent?.newsletter?.[0]?.content?.fields?.apsisMailingListId;
});

const getBlogMailjetMailingListId = createSelector(cookSelectors.getData, data => {
  return data?.context?.deskedContent?.newsletter?.[0]?.content?.fields?.mailjetMailingListId;
});

const getBlogMailjetAccount = createSelector(cookSelectors.getData, data => {
  return data?.context?.deskedContent?.newsletter?.[0]?.content?.fields?.mailjetAccount;
});

const getWriters = createSelector(cookSelectors.getData, locationSelectors.getHostname, (data, hostname) => {
  const replaceHostname = _replace(hostname, '');

  const writers = data?.context?.deskedContent?.writers?.map(writer => ({
    id: writer?.content?.id,
    profileLink: replaceHostname(writer?.content?.href),
    firstName: writer?.content?.fields?.firstName,
    surName: writer?.content?.fields?.surName,
    description: writer?.content?.fields?.description,
    image: {
      id: writer?.content?.profileImage?.[0]?.content?.id,
      href: writer?.content?.profileImage?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full,
      altText: writer?.content?.profileImage?.[0]?.content?.fields?.alttext,
    },
  }));

  return writers;
});

const getBlogPageHeader = createSelector(cookSelectors.getData, data => {
  return {
    heading: data?.context?.deskedContent?.sectionInfo?.[0]?.content?.elements?.[0]?.fields?.[0]?.value,
    description: data?.context?.deskedContent?.sectionInfo?.[0]?.content?.elements?.[1]?.fields?.[0]?.value,
  };
});

export default createSelector(
  getLatestBlogPosts,
  getHeroImage,
  getBlogName,
  getBlogUniqueName,
  getBlogPageHeader,
  getBlogDescription,
  getBlogApsisMailingListId,
  getBlogMailjetMailingListId,
  getBlogMailjetAccount,
  getWriters,
  (
    latestBlogPosts,
    heroImage,
    blogName,
    blogUniqueName,
    blogPageHeader,
    blogDescription,
    blogApsisMailingListId,
    blogMailjetMailingListId,
    blogMailjetAccount,
    writers
  ) => {
    return {
      latestBlogPosts,
      heroImage,
      blogName,
      blogUniqueName,
      blogPageHeader,
      blogDescription,
      blogApsisMailingListId,
      blogMailjetMailingListId,
      blogMailjetAccount,
      writers,
    };
  }
);
