// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PageLoader-pageLoader-1MKYr{height:5px;overflow-x:hidden;position:fixed;width:100vw;z-index:999999}.PageLoader-line-2Rm_g{opacity:.4;width:150%}.PageLoader-line-2Rm_g,.PageLoader-subline-3EsVI{background:#ea8f12;height:5px;position:absolute}.PageLoader-inc-2Yf4N{-webkit-animation:PageLoader-increase-2UOpk 2s infinite;animation:PageLoader-increase-2UOpk 2s infinite}.PageLoader-dec-3elTD{-webkit-animation:PageLoader-decrease-1iXPf 2s .5s infinite;animation:PageLoader-decrease-1iXPf 2s .5s infinite}@-webkit-keyframes PageLoader-increase-2UOpk{0%{left:-5%;width:5%}to{left:130%;width:100%}}@keyframes PageLoader-increase-2UOpk{0%{left:-5%;width:5%}to{left:130%;width:100%}}@-webkit-keyframes PageLoader-decrease-1iXPf{0%{left:-80%;width:80%}to{left:110%;width:10%}}@keyframes PageLoader-decrease-1iXPf{0%{left:-80%;width:80%}to{left:110%;width:10%}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"pageLoader": "PageLoader-pageLoader-1MKYr",
	"line": "PageLoader-line-2Rm_g",
	"subline": "PageLoader-subline-3EsVI",
	"inc": "PageLoader-inc-2Yf4N",
	"increase": "PageLoader-increase-2UOpk",
	"dec": "PageLoader-dec-3elTD",
	"decrease": "PageLoader-decrease-1iXPf"
};
module.exports = exports;
