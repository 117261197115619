import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import getPageTitle from 'selectors/getPageTitle';

export default createSelector(
  cookSelectors.getData,
  locationSelectors.getPathname,
  locationSelectors.getFullPath,
  getPageTitle,
  (data, path, fullPath, pageTitle) => {
    try {
      const { resolution, context } = data;
      const { label, id, authors, published, contentType } = context;
      const { context: contextType, section } = resolution;
      let sectionOrArticleId = id;
      if (contextType === 'sec') {
        sectionOrArticleId = section?.id;
      }
      let labelDisplay = '';
      if (contentType === 'text') {
        labelDisplay = 'Textartikel';
      } else {
        labelDisplay = label;
      }

      const gtmData = {
        event: 'customPageview',
        pagePath: path,
        fullPath,
        title: `${pageTitle} [${contextType === 'sec' ? 'Sektion' : section?.name}] (${sectionOrArticleId})`,
        pageId: sectionOrArticleId,
        contentType: contextType === 'sec' ? 'Sektion' : labelDisplay,
        author: authors?.[0]?.name,
        publishDate: published,
        homeSection: section ? `${section.name} (${section.id})` : 'Fråga vi arbetar med',
      };

      return gtmData;
    } catch (e) {
      return {
        event: 'customPageview',
        pagePath: path,
        title: pageTitle,
      };
    }
  }
);
