// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FacebookNumbers-facebookNumbers-2IMWI{background-color:#4267b2;padding-bottom:40px;padding-top:40px}.FacebookNumbers-facebookNumbers-2IMWI .FacebookNumbers-areYouOneDesktop-22heJ{display:none}.FacebookNumbers-facebookNumbers-2IMWI .FacebookNumbers-areYouOneMobile-1izu2{display:block}.FacebookNumbers-facebookNumbers-2IMWI .FacebookNumbers-count-3lLW9{color:#fff;font-family:LabGrotesque-Bold,sans-serif;font-size:3.25rem;line-height:3.75rem;margin-bottom:3px}.FacebookNumbers-facebookNumbers-2IMWI .FacebookNumbers-followButton-2RjLB{margin-bottom:40px;margin-top:16px}.FacebookNumbers-facebookNumbers-2IMWI .FacebookNumbers-facebook-31Dzg{margin-right:10px}.FacebookNumbers-facebookNumbers-2IMWI .FacebookNumbers-instagram-1pBHr,.FacebookNumbers-facebookNumbers-2IMWI .FacebookNumbers-linkedin-2hTXj,.FacebookNumbers-facebookNumbers-2IMWI .FacebookNumbers-twitter-G0tps{color:#fff;margin-right:6px}.FacebookNumbers-facebookNumbers-2IMWI .FacebookNumbers-socialItem-2HSjl{margin-right:12px;white-space:nowrap}@media (min-width:541px){.FacebookNumbers-facebookNumbers-2IMWI{padding-top:80px;text-align:center}.FacebookNumbers-facebookNumbers-2IMWI .FacebookNumbers-followButton-2RjLB{margin-bottom:56px;margin-top:16px}.FacebookNumbers-facebookNumbers-2IMWI .FacebookNumbers-areYouOneDesktop-22heJ{display:block}.FacebookNumbers-facebookNumbers-2IMWI .FacebookNumbers-areYouOneMobile-1izu2{display:none}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"facebookNumbers": "FacebookNumbers-facebookNumbers-2IMWI",
	"areYouOneDesktop": "FacebookNumbers-areYouOneDesktop-22heJ",
	"areYouOneMobile": "FacebookNumbers-areYouOneMobile-1izu2",
	"count": "FacebookNumbers-count-3lLW9",
	"followButton": "FacebookNumbers-followButton-2RjLB",
	"facebook": "FacebookNumbers-facebook-31Dzg",
	"instagram": "FacebookNumbers-instagram-1pBHr",
	"linkedin": "FacebookNumbers-linkedin-2hTXj",
	"twitter": "FacebookNumbers-twitter-G0tps",
	"socialItem": "FacebookNumbers-socialItem-2HSjl"
};
module.exports = exports;
