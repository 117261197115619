import { connect } from 'react-redux';
import PageTitle from 'components/atoms/PageTitle';
import getPageTitle from 'selectors/getPageTitle';

const mapStateToProps = state => ({
  title: getPageTitle(state),
});

const mapDispatchToProps = undefined;

const PageTitleContainer = connect(mapStateToProps, mapDispatchToProps)(PageTitle);

export default PageTitleContainer;
