import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { selectors as locationSelectors } from 'ducks/location';
import getMenuParameters from 'selectors/getMenuParameters';
import { fetchMenu } from '../../apiQueries';
import clientifyUrl from '../../clientifyUrl';

const SPACER_MENU_ITEM_TYPE = 'SpacerMenuItem';

const divideArray = arr => {
  let indexKey = 0;
  const obj = arr.reduce((acc, current) => {
    if (current.type === SPACER_MENU_ITEM_TYPE) {
      indexKey += 1;
      return acc;
    }

    return {
      ...acc,
      [indexKey]: [...(acc[indexKey] || []), current],
    };
  }, {});

  return Object.values(obj).map(menuGroup => ({
    id: uuidv4(),
    elements: menuGroup,
  }));
};

const getIsActive = (pathname, item) => {
  const href = clientifyUrl(item.content?.href ?? item?.href);
  return pathname === href;
};

const getHasActiveChildren = (pathname, item) => {
  if (getIsActive(pathname, item)) {
    return true;
  }
  return !!item.children?.find(child => getHasActiveChildren(pathname, child));
};

const normalizeMenu = (menu, pathname, path, options = {}) => {
  const normalizedMenu =
    Array.isArray(menu) && menu.length > 0
      ? menu.map(item => {
          const isActive = getIsActive(pathname, item);
          const hasActiveChildren = !isActive && getHasActiveChildren(pathname, item);
          const myPath = [...path, item?.text];

          return {
            id: uuidv4(),
            href: clientifyUrl(item.content?.href ?? item.href),
            isActive,
            hasActiveChildren,
            text: item?.text,
            type: item?.type,
            icon: item?.icon,
            path: myPath,
            children: options.useDivider
              ? divideArray(normalizeMenu(item?.children, pathname, myPath, options))
              : normalizeMenu(item?.children, pathname, myPath, options),
          };
        })
      : [];

  return options.useDivider
    ? normalizedMenu
    : normalizedMenu.filter(item => item.type !== SPACER_MENU_ITEM_TYPE);
};

const defaultOptions = {
  useDivider: true,
};

const useCueMenu = (options = defaultOptions) => {
  const pathname = useSelector(locationSelectors.getPathname);
  const menuParameters = useSelector(getMenuParameters);
  const dataPath = useSelector(locationSelectors.getDataPath);

  const query = useQuery(['cueMenu', { menuParameters }], ({ queryKey }) => fetchMenu(queryKey, dataPath), {
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    select: data => ({
      mainMenu: normalizeMenu(data?.mainMenu, pathname, [], options),
      extraMenu: normalizeMenu(data?.extraMenu, pathname, [], options),
    }),
  });

  return {
    query,
    mainMenu: query.data?.mainMenu,
    extraMenu: query.data?.extraMenu,
  };
};

export default useCueMenu;
