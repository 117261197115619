// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MobileFooter-mobileFooter-3zhaw{display:block;padding:40px 0 80px}.MobileFooter-mobileFooter-3zhaw .MobileFooter-block-1g02y{margin-top:40px}@media (min-width:541px){.MobileFooter-mobileFooter-3zhaw{display:none}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"mobileFooter": "MobileFooter-mobileFooter-3zhaw",
	"block": "MobileFooter-block-1g02y"
};
module.exports = exports;
