// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FacebookButton-facebookButton-3m2Et{background-color:#4267b2;border:1px solid #cfd4d8;border-radius:2px;color:#fff;cursor:pointer;display:inline-block;font-family:LabGrotesque-Bold,sans-serif;padding:16px 32px;text-align:center;text-decoration:none;-webkit-transition:color .2s ease-in-out,background-color .2s ease-in-out,border .2s ease-in-out;transition:color .2s ease-in-out,background-color .2s ease-in-out,border .2s ease-in-out}.FacebookButton-facebookButton-3m2Et:hover{background:#355aa5;color:#fff}.FacebookButton-facebookButton-3m2Et:active{background:#4f74bf;color:#fff}.FacebookButton-facebookButton-3m2Et.FacebookButton-block-V8-Nz{width:100%}.FacebookButton-facebookButton-3m2Et.FacebookButton-gutterBottom-1U_uF{margin-bottom:1rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"facebookButton": "FacebookButton-facebookButton-3m2Et",
	"block": "FacebookButton-block-V8-Nz",
	"gutterBottom": "FacebookButton-gutterBottom-1U_uF"
};
module.exports = exports;
