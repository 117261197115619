const sizes = {
  default: '',
  xs: '(min-width: 320px) ',
  sm: '(min-width: 541px) ',
  md: '(min-width: 820px) ',
  lg: '(min-width: 1169px) ',
  xl: '(min-width: 1600px) ',
};

const generateSizes = sizeObj => {
  const sizesKeys = Object.keys(sizes).reverse();
  return sizesKeys
    .reduce((acc, key) => {
      if (sizeObj[key]) acc.push(`${sizes[key]}${sizeObj[key]}`);
      return acc;
    }, [])
    .join(', ');
};

export default generateSizes;
