import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import getCssSizesVars from '../../pages/FlexibleSection/utils/getCssSizesVars';
import s from './Spacer.css';

const Spacer = ({ options }) => {
  const spacerCssVars = getCssSizesVars({
    object: options,
    cssProperty: 'spacing',
    cssVarPrefix: '--spacing-',
    options: { useGenericValueAtBreakpoint: 'xxs' },
  });

  return (
    <div
      aria-hidden="true"
      style={{
        ...spacerCssVars,
      }}
      className={s.spacer}
    />
  );
};

export default withStyles(s)(Spacer);
