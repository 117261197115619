import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import getHeroImage from 'selectors/getHeroImage';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, getHeroImage, (data, heroImage) => {
  try {
    const style = pickFrom(data, 'context.fields.style', 'tagInfo[0].fields.style');
    if (style === 'white') {
      return false;
    }
    return (
      style ||
      (heroImage && 'blueWithBackgroundImage') ||
      (pickFrom(data, 'context.rootGroup.type') === 'regionOffice' && 'blue') ||
      pickFrom(data, 'context.rootGroup.type') === 'microsite' ||
      (pickFrom(data, 'context.rootGroup.type') === 'focusArea' && 'blue') ||
      (pickFrom(data, 'resolution.context') === 'sec' &&
        pickFrom(data, 'resolution.section.uniqueName') === 'kalendarium_start' &&
        'blue') ||
      (pickFrom(data, 'resolution.context') === 'sec' &&
        pickFrom(data, 'resolution.section.uniqueName') === 'inspelade_webbinarier' &&
        'blue') ||
      (pickFrom(data, 'context.contentType') === 'report' && 'grayWithOutlineLogo') ||
      (pickFrom(data, 'context.contentType') === 'remiss' && 'grayWithOutlineLogo') ||
      (pickFrom(data, 'context.contentType') === 'skrivelse' && 'grayWithOutlineLogo') ||
      (pickFrom(data, 'context.contentType') === 'profile' && 'grayWithOutlineLogo') ||
      (pickFrom(data, 'context.contentType') === 'blogPost' && 'grayWithOutlineLogo') ||
      (pickFrom(data, 'context.rootGroup.type') === 'blog' && 'grayWithOutlineLogo') ||
      undefined
    );
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
