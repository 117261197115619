import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faSignalStream } from '@fortawesome/pro-regular-svg-icons';
import Label from 'components/atoms/Label';
import Typography from 'components/atoms/Typography';
import DateCalendar from 'components/atoms/DateCalendar';
import Link from 'containers/atoms/LinkContainer';
import { generateFromToToString, formatDate } from 'utils/dateUtil';
// eslint-disable-next-line css-modules/no-unused-class
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './EventBox.css';
import Translate from '../../../containers/atoms/TranslateContainer';
import useTranslate from '../../../utils/hooks/useTranslate';

const validateDate = date => date !== '' && date !== undefined;

const IconNode = ({ icon, text }) => (
  <div className={s.iconNode}>
    <div className={s.iconNodeImageWrapper}>
      <FontAwesomeIcon icon={icon} className={s.iconNodeImage} />
    </div>
    <span className={s.iconNodeText}>{text}</span>
  </div>
);

const EventBox = ({
  classes,
  href,
  contentType,
  hideTimeInformation,
  headline,
  from,
  to,
  location,
  deadlineDate,
  labelVariant,
  cold,
  implementationType,
  ...other
}) => {
  const translate = useTranslate();
  const classNames = classnames(s.eventBox, cold && s.cold, classes);

  const deadlineString = `Anmäl dig senast ${formatDate(deadlineDate)}`;

  return (
    <div className={classNames} {...other}>
      <div className={s.eventBoxFlexWrapper}>
        <div className={s.eventBoxTextblock}>
          <Typography variant="contentTypeDate" classes={s.eventOverline}>
            <Translate>{`CONTENT_TYPE.${contentType}`}</Translate>
          </Typography>
          <Typography
            variant="h3"
            component={Link}
            to={href}
            classes={s.eventHeadline}
            gutterBottom={false}
            gutterTop={false}
          >
            {headline}
          </Typography>
          <div className={s.eventBoxIcons}>
            {validateDate(from) && validateDate(to) && (
              <IconNode icon={faCalendarAlt} text={generateFromToToString(hideTimeInformation, from, to)} />
            )}
            {implementationType !== 'live' && <IconNode icon={faMapMarkerAlt} text={location} />}
            {implementationType === 'live' && (
              <IconNode icon={faSignalStream} text={translate('EVENT_PAGE.EVENT_LIVE')} />
            )}
            {implementationType === 'liveAndPhysical' && (
              <IconNode icon={faSignalStream} text={translate('EVENT_PAGE.EVENT_LIVE_AND_PHYSICAL')} />
            )}
            {validateDate(deadlineDate) && <IconNode icon={faInfoCircle} text={deadlineString} />}
          </div>
          {labelVariant && <Label variant={labelVariant} classes={s.label} />}
        </div>
        {validateDate(from) && <DateCalendar date={from} />}
      </div>
    </div>
  );
};

export default withStyles(s, boostrapGrid)(EventBox);
