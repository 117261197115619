// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DownloadButton-downloadButton-1n6NC{background-color:#ea8f12;border:none;border-radius:2px;color:#fff;cursor:pointer;font-family:LabGrotesque-Bold,sans-serif;font-size:.875rem;padding:14px 16px;-webkit-transition:background-color .2s ease-in-out;transition:background-color .2s ease-in-out;white-space:nowrap}.DownloadButton-downloadButton-1n6NC:hover{background-color:#d88514}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"downloadButton": "DownloadButton-downloadButton-1n6NC"
};
module.exports = exports;
