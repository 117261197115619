// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CollapsibleGroup-collapsibleGroup-3cdEt{margin-bottom:1rem}.CollapsibleGroup-collapsibleGroup-3cdEt .CollapsibleGroup-inner-3c9g0{overflow:hidden}.CollapsibleGroup-collapsibleGroup-3cdEt .CollapsibleGroup-button-2lCdu{color:#33414e;cursor:pointer;padding:0;text-decoration:none}.CollapsibleGroup-collapsibleGroup-3cdEt .CollapsibleGroup-button-2lCdu.CollapsibleGroup-dark-3FQfU{color:#fff}.CollapsibleGroup-collapsibleGroup-3cdEt .CollapsibleGroup-button-2lCdu.CollapsibleGroup-bigColorBar-2zjjo{background-color:#ea8f12;color:#fff;padding:8px 12px;text-align:left;width:100%}", ""]);
// Exports
exports.locals = {
	"collapsibleGroup": "CollapsibleGroup-collapsibleGroup-3cdEt",
	"inner": "CollapsibleGroup-inner-3c9g0",
	"button": "CollapsibleGroup-button-2lCdu",
	"dark": "CollapsibleGroup-dark-3FQfU",
	"bigColorBar": "CollapsibleGroup-bigColorBar-2zjjo"
};
module.exports = exports;
