import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import Link from '../../../../containers/atoms/LinkContainer';
import ButtonSecondaryGhost from '../../../atoms/buttons/ButtonSecondaryGhost/ButtonSecondaryGhost';
import s from './PaginationButtons.css';

const PaginationButtons = ({ prevUrl, nextUrl, prevLabel, nextLabel }) => {
  return (
    <div
      className={classnames(s.paginationButtons, {
        [s.noPrev]: !prevUrl,
        [s.noNext]: !nextUrl,
      })}
    >
      {prevUrl && (
        <ButtonSecondaryGhost className={s.prev} component={Link} to={prevUrl}>
          <FontAwesomeIcon icon={faChevronLeft} />
          {prevLabel}
        </ButtonSecondaryGhost>
      )}
      {nextUrl && (
        <ButtonSecondaryGhost className={s.next} component={Link} to={nextUrl}>
          {nextLabel}
          <FontAwesomeIcon icon={faChevronRight} />
        </ButtonSecondaryGhost>
      )}
    </div>
  );
};

export default withStyles(s)(PaginationButtons);
