import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';

export default createSelector(cookSelectors.getData, data => {
  try {
    return data?.context?.rootGroup?.pageOptions?.find(el => el.key === 'headerTextColor')?.value;
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
