import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import noScroll from 'no-scroll';
import ReactModal from 'react-modal';
import s from './Modal.css';

const Modal = ({
  classes,
  closeClasses,
  dark,
  modalOpen,
  closeModal,
  label,
  alignTop,
  children,
  ...other
}) => {
  const classNames = classnames(s.modal, classes);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (modalOpen) noScroll.on();
    else noScroll.off();
  }, [modalOpen]);

  return isMounted ? (
    <ReactModal
      appElement={typeof window !== 'undefined' && document.getElementById('app')}
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={{}}
      className={classNames}
      overlayClassName={classnames(s.overlay, dark && s.dark, alignTop && s.alignTop)}
      contentLabel={label}
      {...other}
    >
      <button type="button" className={classnames(s.closeButton, closeClasses)} onClick={closeModal}>
        <FontAwesomeIcon icon={faTimes} className={s.closeIcon} />
      </button>
      {children}
    </ReactModal>
  ) : null;
};

export default withStyles(s)(Modal);
