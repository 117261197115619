import getImages from 'utils/getImages';
import { COLOR_MAP } from './constants';
import getAvsnittOptionKeyValue from './getAvsnittOptionKeyValue';

const getAvsnittOptions = avsnitt => {
  const newAvsnittOptions = avsnitt.groupOptions?.reduce((acc, current) => {
    const [key, value] = getAvsnittOptionKeyValue(current.key, current.value);

    return {
      ...acc,
      [key]: value,
    };
  }, {});

  // If the segments contain a picture or a video, find the first one and use it as the background image/background video.
  const backgroundMedia = avsnitt.segmentContent?.find(
    segment => segment.content.contentType === 'picture' || segment.content.contentType === 'video'
  );

  // Add the backgroundMedia to the avsnittOptions object
  if (backgroundMedia) {
    newAvsnittOptions.backgroundMedia = {
      type: backgroundMedia.content.contentType,
      alt:
        backgroundMedia.content.contentType === 'picture'
          ? backgroundMedia.content.fields.alttext
          : undefined,
      href:
        backgroundMedia.content.contentType === 'picture'
          ? getImages('FREEFORM').toSrc(backgroundMedia.content.fields.BASE_LANDSCAPE.href_full, 'XL')
          : backgroundMedia.content.binary.href,
    };
  }

  // If there is backgroundColor and backgroundColorOpacity, remove them and add them as a CSS backgroundColor
  // (Keep as transparent if that's the value)
  if (newAvsnittOptions?.backgroundColor && newAvsnittOptions?.backgroundColorOpacity) {
    const { backgroundColor, backgroundColorOpacity } = newAvsnittOptions;
    delete newAvsnittOptions.backgroundColor;
    delete newAvsnittOptions.backgroundColorOpacity;

    newAvsnittOptions.backgroundColor =
      backgroundColor === COLOR_MAP.DEFAULT
        ? COLOR_MAP.DEFAULT
        : `rgba(${backgroundColor}, ${parseFloat(backgroundColorOpacity, 10) / 100})`;
  }

  return newAvsnittOptions;
};

export default getAvsnittOptions;
