// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DesktopHeader-desktopHeader-3M9Nr{display:none;gap:32px;height:100%}@media (min-width:1169px){.DesktopHeader-desktopHeader-3M9Nr{display:-ms-flexbox;display:flex}.DesktopHeader-desktopHeader-3M9Nr .DesktopHeader-headerLogoWrapper-3b5jp{width:130px}.DesktopHeader-desktopHeader-3M9Nr .DesktopHeader-menusWrapper-vI-KR{-ms-flex-pack:justify;display:-ms-flexbox;display:flex;-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;justify-content:space-between}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"desktopHeader": "DesktopHeader-desktopHeader-3M9Nr",
	"headerLogoWrapper": "DesktopHeader-headerLogoWrapper-3b5jp",
	"menusWrapper": "DesktopHeader-menusWrapper-vI-KR"
};
module.exports = exports;
