// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InfoPage-article_container-1F0Hz{margin-bottom:3rem}.InfoPage-article_container-1F0Hz .InfoPage-stickyContainer-2ufL2{position:relative}@media screen and (min-width:541px){.InfoPage-article_container-1F0Hz .InfoPage-stickyContainer-2ufL2{min-height:21rem}}.InfoPage-faq-1a_xG{display:none;padding-bottom:64px;padding-top:34px}.InfoPage-alsoReadWrapper-3Cpg0{background:#eff2f5;padding-bottom:24px;padding-top:40px}.InfoPage-secondaryWrapper-3ZDXb{padding-left:0;padding-right:0}.InfoPage-fadeOut-3goOr{opacity:0;-webkit-transition:visibility 0s .5s,opacity .5s linear;transition:visibility 0s .5s,opacity .5s linear;visibility:hidden}.InfoPage-fadeIn-38YFi{opacity:1;-webkit-transition:opacity .5s linear;transition:opacity .5s linear;visibility:visible}@media (min-width:541px){.InfoPage-secondaryWrapper-3ZDXb{padding-left:56px;padding-right:56px}}@media (min-width:820px){.InfoPage-secondaryWrapper-3ZDXb{padding-left:0;padding-right:0}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"article_container": "InfoPage-article_container-1F0Hz",
	"stickyContainer": "InfoPage-stickyContainer-2ufL2",
	"faq": "InfoPage-faq-1a_xG",
	"alsoReadWrapper": "InfoPage-alsoReadWrapper-3Cpg0",
	"secondaryWrapper": "InfoPage-secondaryWrapper-3ZDXb",
	"fadeOut": "InfoPage-fadeOut-3goOr",
	"fadeIn": "InfoPage-fadeIn-38YFi"
};
module.exports = exports;
