/* eslint-disable dot-notation */
import { createSelector } from 'reselect';
import getStructuredStorylineTree from './getStructuredStorylineTree';

const propsToAttributes = obj => {
  const keys = Object.keys(obj);
  return keys.reduce((acc, key) => (obj[key] ? `${acc} ${key}="${obj[key]}"` : acc), '').trim();
};

const generateHtmlElement = (text, annotation) => {
  switch (annotation.name) {
    case 'bold':
      return `<strong>${text}</strong>`;
    case 'internal_link':
    case 'inline_link': {
      const { fields, relation } = annotation.value;
      const linkProps = {
        target:
          (fields?.newWindow && '_blank') ||
          (annotation.name === 'inline_link' && fields?.newWindow === undefined && '_blank'),
      };
      const href =
        fields?.uri?.rawText ||
        (relation?.contentType === 'document' && relation?.binary?.href) ||
        relation?.href;
      if (href) {
        return `<a href="${href}" ${propsToAttributes(linkProps)}>${text}</a>`;
      }
      return text;
    }

    case 'strike':
      return `<del>${text}</del>`;
    case 'subscript':
      return `<sub>${text}</sub>`;
    case 'superscript':
      return `<sup>${text}</sup>`;
    default:
      return text;
  }
};

const generateHtml = (rawText, annotations) => {
  let html = rawText;
  try {
    // split string into chars and a data structure to work with
    const representation = rawText.split('').map(char => ({ char, annotation: undefined }));
    // add annotations to the elements in the data structure
    annotations.forEach(annotation => {
      for (let i = annotation.index; i < annotation.index + annotation.length; i += 1) {
        const repAnnotaton = representation[i].annotation;
        // only accept one per char
        if (!repAnnotaton) {
          representation[i].annotation = annotation;
        }
      }
    });

    const nodes = [];

    // group chars by annotations
    const lastAcc = representation.reduce((acc, rep) => {
      const lastItem = acc[acc.length - 1];
      // first item, start a new "annotation group"
      if (!lastItem) {
        acc.push(rep);
        return acc;
      }
      // if other annotation compared to last, end the annotation group and start a new
      if (lastItem.annotation !== rep.annotation) {
        nodes.push(acc);
        return [rep];
      }
      // else just keep pushing to annotation group
      acc.push(rep);
      return acc;
    }, []);

    // if last annotation group ain't added to the nodes array, also add it.
    if (lastAcc && lastAcc.length > 0) {
      nodes.push(lastAcc);
    }

    // generate html elements from nodes array
    html = nodes
      .map(node => {
        const { annotation } = node[0];
        let text = node.map(element => element.char).join('');
        text = text?.replace('\n', '<br />') || text; // insert soft line break
        // do this node have annotations? If it does generate html tag for it.
        if (annotation) {
          return generateHtmlElement(text, annotation);
        }
        return text;
      })
      .join('');
  } catch (error) {
    console.error(error);
  }

  return html;
};

const addHtml = elements => {
  try {
    return elements.map(element => {
      const { fields } = element;
      const { headline, 'lead-text': leadText, paragraph } = fields;
      // might be able to clean up this code, but pick text data to generate html for depending on field type.
      if (headline) {
        return {
          ...element,
          fields: {
            ...fields,
            headline: {
              ...headline,
              html: generateHtml(headline.rawText, headline.annotations),
            },
          },
        };
      }
      if (leadText) {
        return {
          ...element,
          fields: {
            ...fields,
            'lead-text': {
              ...leadText,
              html: generateHtml(leadText.rawText, leadText.annotations),
            },
          },
        };
      }
      if (paragraph) {
        return {
          ...element,
          fields: {
            ...fields,
            paragraph: {
              ...paragraph,
              html: generateHtml(paragraph.rawText, paragraph.annotations),
            },
          },
        };
      }
      if (element.children) {
        return {
          ...element,
          children: addHtml(element.children),
        };
      }
      return element;
    });
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export default createSelector(getStructuredStorylineTree, addHtml);
