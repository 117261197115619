// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StorylineBoxBackground-storylineBoxBackground-3jSkF{height:100%;position:relative;width:100%}.StorylineBoxBackground-storylineBox-17Fc_{height:100%;width:100%}.StorylineBoxBackground-boxBackground-c3q30{height:100%;margin-left:-12px;margin-right:-12px;position:relative}.StorylineBoxBackground-videoColorOverlay-10_MW{z-index:1}.StorylineBoxBackground-videoColorOverlay-10_MW,video{height:100%;left:0;position:absolute;top:0;width:100%}video{-o-object-fit:cover;object-fit:cover}@media screen and (max-width:541px){.StorylineBoxBackground-boxBackground-c3q30{margin-left:-24px;margin-right:-24px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"storylineBoxBackground": "StorylineBoxBackground-storylineBoxBackground-3jSkF",
	"storylineBox": "StorylineBoxBackground-storylineBox-17Fc_",
	"boxBackground": "StorylineBoxBackground-boxBackground-c3q30",
	"videoColorOverlay": "StorylineBoxBackground-videoColorOverlay-10_MW"
};
module.exports = exports;
