import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const ENABLE_ON_LOCALHOST = false;

const getSentryEnv = () => {
  const isLocal = window.location.href.includes('localhost');
  const isStage = window.location.href.includes('stage-www');
  return isLocal ? 'temp' : isStage ? 'stage' : 'production';
};

const getIsEnabled = () => {
  return ENABLE_ON_LOCALHOST || getSentryEnv() !== 'temp';
};

export const sentryInitClient = () => {
  Sentry.init({
    dsn: 'https://03fb786f573f4985951b3253a96b2455@o1118559.ingest.sentry.io/6152561',
    integrations: [new BrowserTracing()],
    enabled: getIsEnabled(),
    environment: getSentryEnv(),
    attachStacktrace: true,
    // Disable auto reporting
    // https://forum.sentry.io/t/browser-disable-auto-reporting-or-just-manually-catch-errors/6614
    defaultIntegrations: false,
  });
};

const defaultLoggerOptions = {
  throw: false,
  level: 'warning',
};

const sentryLogger = (errorMessage, inputOptions = {}) => {
  const options = {
    ...defaultLoggerOptions,
    ...inputOptions,
  };

  const error = new Error(errorMessage);

  if (getIsEnabled()) {
    logger.log('sentryLogger', '[Sentry enabled] This is the error', error);
    Sentry.captureException(error, {
      level: options.level,
      extra: {
        path: window.location.href,
      },
    });
  } else {
    logger.log('sentryLogger', '[Sentry disabled] This is the error', error);
    /* eslint-disable no-console */
    console.log('🔴 Sentry is disabled but here was the error:');
    console.error(error);
    /* eslint-enable no-console */
  }

  if (options.throw) {
    throw error;
  }
};

export default sentryLogger;
