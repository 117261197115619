import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { faSignIn } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withFeatureFlagGuard from '../../../../../utils/hocs/withFeatureFlagGuard';
import useAuth from '../../../../../utils/hooks/useAuth';
import useClientSide from '../../../../../utils/hooks/useClientSide';
import ButtonPrimaryAlt from '../../../../atoms/buttons/ButtonPrimaryAlt/ButtonPrimaryAlt';
import DesktopLoggedInMenu from '../DesktopLoggedInMenu/DesktopLoggedInMenu';
import s from './DesktopUserListItem.css';
import useDarkHeader from '../../../../../utils/hooks/useDarkHeader';
import useUser from '../../../../../utils/hooks/useUser';
import Spinner from '../../../../atoms/Spinner/Spinner';

const DesktopUserListItem = () => {
  const isClient = useClientSide();
  const { isAuthenticated, login, acquireTokenSilentQuery, authQuery } = useAuth();
  const { userQuery } = useUser();
  const isDarkHeader = useDarkHeader();

  if (!isClient) {
    return null;
  }

  const showSpinner = userQuery.isLoading || acquireTokenSilentQuery.isLoading || authQuery.isLoading;
  if (showSpinner) {
    return <Spinner color={isDarkHeader ? 'white' : 'black'} />;
  }

  return (
    <li className={s.desktopUserListItem}>
      {isAuthenticated && userQuery.data ? (
        <DesktopLoggedInMenu />
      ) : (
        <ButtonPrimaryAlt size="sm" onClick={login}>
          <FontAwesomeIcon icon={faSignIn} /> Logga in
        </ButtonPrimaryAlt>
      )}
    </li>
  );
};

export default withFeatureFlagGuard(withStyles(s)(DesktopUserListItem), 'disablePremiumContent', true);
