import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import boostrapGrid from 'style/bootstrap-grid.css';
import FooterContact from 'components/molecules/FooterContact';
import FooterMoreNews from 'components/molecules/FooterMoreNews';
import FooterSubscribe from 'components/molecules/FooterSubscribe';
import FooterLinks from 'components/molecules/FooterLinks';
import FooterSocial from 'components/molecules/FooterSocial';
import FooterPublisher from 'components/molecules/FooterPublisher';
import useTranslation from '../../../utils/hooks/useTranslation';
import s from './DesktopFooter.css';

const DesktopFooter = () => {
  const { IS_SWEDISH } = useTranslation();

  return (
    <div className={s.desktopFooter}>
      <div className={boostrapGrid.row}>
        <div className={boostrapGrid['col-8']}>
          <FooterContact />
        </div>
        <div className={boostrapGrid['col-8']}>{IS_SWEDISH && <FooterMoreNews />}</div>
        <div className={boostrapGrid['col-8']}>
          <FooterSubscribe id="desktop-footer-subscribe" />
        </div>
      </div>
      <div className={classnames(boostrapGrid.row, s.lowerContentSocial)}>
        <div className={boostrapGrid['col-16']} />
        <div className={boostrapGrid['col-8']}>
          <FooterSocial />
        </div>
      </div>
      <div className={classnames(boostrapGrid.row, s.lowerContentLinks)}>
        <div className={boostrapGrid['col-16']}>
          <FooterLinks />
        </div>
        <div className={boostrapGrid['col-8']}>
          <FooterPublisher />
        </div>
      </div>
    </div>
  );
};

export default withStyles(s, boostrapGrid)(DesktopFooter);
