import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useForm } from 'react-hook-form';
import Typography from 'components/atoms/Typography';
import TextField from 'components/molecules/TextField';
import TextAreaField from 'components/molecules/TextAreaField';
import SelectField from 'components/molecules/SelectField';
import FormSuccess from 'components/molecules/FormSuccess';
import FormError from 'components/molecules/FormError';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import Checkbox from 'components/atoms/Checkbox';
import countries from 'utils/countries';
import postForm from 'utils/postForm';
import pushToDataLayer from 'utils/pushToDataLayer';
import validateOrganizationNumber from 'utils/validateOrganizationNumber';
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './BecomeMember.css';

const renameMap = {
  Organization: 'companyName',
  fname: 'firstName',
  lname: 'lastName',
  tel: 'phone',
  'postal-code': 'postalCode',
  'street-address': 'address',
  'address-level1': 'city',
  'country-name': 'countryName',
};

// eslint-disable-next-line no-unused-vars
const mapFormNamesToKeys = data =>
  Object.keys(data).reduce((acc, val) => {
    acc[renameMap[val] || val] = data[val];
    return acc;
  }, {});

const BecomeMember = ({ classes, memberOrganisations = [] }) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isRegionStateProvinceVisible, setRegionStateProvinceVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [postingOrder, setPostingOrder] = useState({
    order: undefined,
    posting: false,
    status: undefined,
  });

  useEffect(() => {
    if (postingOrder.order) {
      const sendToGTM = status => {
        pushToDataLayer('memberExpressionOfInterestForm', {
          zipCode: `${postingOrder.order.address.zipCode}`,
          memberExpressionOfInterestStatus: status,
        });
      };

      postForm('/becomemember', postingOrder.order)
        .then(data => {
          if (data.status === 'ok') {
            setPostingOrder({
              ...postingOrder,
              posting: false,
              status: 'complete',
            });
            sendToGTM('Postat intresseanmälan - lyckad');
          } else {
            console.error(data);
            setPostingOrder({
              ...postingOrder,
              posting: false,
              status: 'error',
            });

            sendToGTM('Postat intresseanmälan - misslyckad');
          }
        })
        .catch(err => {
          console.error(err);
          setPostingOrder({
            ...postingOrder,
            posting: false,
            status: 'error',
          });

          sendToGTM('Postat intresseanmälan - misslyckad');
        });
    }
  }, [postingOrder.order]);

  const { register, watch, getValues, handleSubmit, errors } = useForm();
  const classNames = classnames(s.becomeMember, classes);
  const watchMemberOrganization = watch('memberOrganization', '0ccd80f7-ff07-de11-b4e1-02bf9e69ca65');
  const postOrder = data => {
    // eslint-disable-next-line no-console
    const prePostData = mapFormNamesToKeys(data);

    const postData = {
      organizationName: prePostData.organization,
      organizationNumber: prePostData.organizationNumber,
      preferedMemberOrganization: prePostData.memberOrganization,
      companyBranch: prePostData.companyBranch,
      message: prePostData.message,
      address: {
        address1: prePostData.companyAddress,
        address2: '',
        regionStateProvince: prePostData.regionStateProvince,
        zipCode: prePostData.companyPostalCode,
        city: prePostData.companyCity,
        countryId: +prePostData.organizationCountry,
      },
      contact: {
        firstName: prePostData.firstName,
        lastName: prePostData.lastName,
        email: prePostData.email,
        phone: prePostData.phone,
      },
    };

    setPostingOrder({
      ...postOrder,
      posting: true,
      order: postData,
    });
  };

  function validateRegionStateProvince(value) {
    if (!value && getValues('organizationCountry') !== '162') {
      // regionStateProvince field is required if some country other than Sweden (id 162).
      return 'Region saknas';
    }
    return true;
  }
  return (
    <div className={classNames}>
      {postingOrder.status === 'complete' ? (
        <div>
          <FormSuccess
            headline="Tack för din intresseanmälan!"
            text="Vi återkommer med mer information så fort din intresseanmälan är behandlad!"
          />
        </div>
      ) : (
        <form autoComplete="on" onSubmit={handleSubmit(postOrder)}>
          <SelectField
            id="organizationCountry"
            name="organizationCountry"
            label="Fyll i det land ditt företag är registrerat i"
            defaultValue="162"
            register={register({ required: 'Land saknas' })}
            onInput={event => {
              setRegionStateProvinceVisible(event.target.value !== '162');
            }}
          >
            {countries.map(country => (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            ))}
          </SelectField>
          <SelectField
            id="memberOrganization"
            name="memberOrganization"
            label="Välj vilken medlemsorganisation du vill bli medlem i"
            defaultValue="0ccd80f7-ff07-de11-b4e1-02bf9e69ca65"
            errorMessage={errors?.memberOrganization?.message}
            register={register({ required: 'Medlemsorganisation saknas' })}
          >
            <option value="0ccd80f7-ff07-de11-b4e1-02bf9e69ca65">Vet ej</option>
            {memberOrganisations?.map(org => (
              <option key={org.idInForm} value={org.idInForm}>
                {org.title}
              </option>
            ))}
          </SelectField>
          {watchMemberOrganization === '0ccd80f7-ff07-de11-b4e1-02bf9e69ca65' ? (
            <TextAreaField
              id="companyBranch"
              label="Bransch/verksamhet"
              errorMessage={errors?.companyBranch?.message}
              register={register({ required: 'Bransch/verksamhet saknas' })}
            />
          ) : (
            <TextAreaField id="companyBranch" label="Bransch/verksamhet" register={register()} />
          )}

          <Typography variant="caption" component="div" gutterBottom classes={s.captionText}>
            Fyll i företagets uppgifter
          </Typography>
          <div className={s.equalFieldWrapper}>
            <TextField
              id="organization"
              label="Företagets namn"
              classes={s.leftItem}
              errorMessage={errors?.organization?.message}
              register={register({ required: 'Företagsnamn saknas' })}
            />
            <TextField
              id="organizationNumber"
              label="Organisationsnummer"
              classes={s.rightItem}
              errorMessage={
                errors?.organizationNumber?.type === 'validate'
                  ? 'Felaktigt organisationsnummer'
                  : errors?.organizationNumber?.message
              }
              register={register({
                required: 'Organisationsnummer saknas',
                validate: validateOrganizationNumber,
              })}
            />
          </div>
          <TextField
            id="companyAddress"
            label="Adress"
            errorMessage={errors?.companyAddress?.message}
            register={register({ required: 'Adress saknas' })}
          />
          <div className={s.cityWrapper}>
            <TextField
              id="companyPostalCode"
              label="Postnummer"
              classes={s.postalNr}
              errorMessage={errors?.companyPostalCode?.message}
              register={register({
                required: 'Postnummer saknas',
                pattern: {
                  value: /\s*(?:[0-9.]\s*){5,}$/,
                  message: 'Felaktigt postnummer',
                },
              })}
            />
            <TextField
              id="companyCity"
              label="Postort"
              classes={s.city}
              errorMessage={errors?.companyCity?.message}
              register={register({ required: 'Postort saknas' })}
            />
          </div>
          <div hidden={!isRegionStateProvinceVisible}>
            <TextField
              id="regionStateProvince"
              label="Region"
              errorMessage={errors?.regionStateProvince?.message}
              register={register({ validate: validateRegionStateProvince })}
            />
          </div>
          <Typography variant="caption" component="div" gutterBottom classes={s.captionTextYou}>
            Fyll i dina kontaktuppgifter
          </Typography>
          <div className={s.equalFieldWrapper}>
            <TextField
              id="fname"
              label="Förnamn"
              classes={s.leftItem}
              errorMessage={errors?.fname?.message}
              register={register({ required: 'Förnamn saknas' })}
            />
            <TextField
              id="lname"
              label="Efternamn"
              classes={s.rightItem}
              errorMessage={errors?.lname?.message}
              register={register({ required: 'Efternamn saknas' })}
            />
          </div>
          <div className={s.equalFieldWrapper}>
            <TextField
              id="tel"
              label="Telefonnummer"
              errorMessage={errors?.tel?.message}
              classes={s.leftItem}
              register={register({
                pattern: {
                  value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
                  message: 'Ogiltigt telefonnummer',
                },
              })}
            />
            <TextField
              id="email"
              label="E-post"
              type="email"
              errorMessage={errors?.email?.message}
              classes={s.rightItem}
              register={register({
                required: 'E-post saknas',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Ogiltig e-postadress',
                },
              })}
            />
          </div>
          <TextAreaField id="message" label="Meddelande eller frågor till oss" register={register()} />
          <Checkbox
            id="terms"
            label={
              <>
                Jag godkänner Svenskt Näringslivs hantering av{' '}
                <Typography
                  variant="link"
                  component="a"
                  href="https://www.svensktnaringsliv.se/dataskydd/integritet-och-behandling-av-personuppgifter-i-digitala-kanaler_1010031.html"
                  target="_blank"
                >
                  personuppgifter
                </Typography>
                .
              </>
            }
            checked={termsAccepted}
            onChange={e => setTermsAccepted(e.target.checked)}
            gutterBottom
          />
          {postingOrder.status === 'error' && (
            <FormError
              classes={s.errorBox}
              headline="Kunde inte skicka intresseanmalan"
              text="Något gick fel när intresseanmalan skickades, kontrollera så alla fält är korrekt ifyllda eller försök igen."
            />
          )}
          <ButtonPrimaryAlt
            type="submit"
            classes={s.submitButton}
            disabled={!termsAccepted || postingOrder.posting}
          >
            {postingOrder.posting && <div className={s.ldsDualRing} />}
            {postingOrder.posting ? 'Skickar intresseanmälan...' : 'Skicka intresseanmälan'}
          </ButtonPrimaryAlt>
        </form>
      )}
    </div>
  );
};

export default withStyles(s, boostrapGrid)(BecomeMember);
