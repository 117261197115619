/* eslint-disable camelcase */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import { formatDateWithYear } from 'utils/dateUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import DownloadButton from 'components/atoms/DownloadButton';
import ButtonPrimaryAlt from '../../atoms/buttons/ButtonPrimaryAlt';
import Link from '../../../containers/atoms/LinkContainer';
import Carousel from '../Carousel/Carousel';
import chunkArray from '../../../utils/chunkArray';
import useMediaQueryWidth from '../../../utils/hooks/useMediaQueryWidth';

import s from './LatestReports.css';

const openLink = (event, url) => {
  if (window) {
    window.open(url, '_blank');
    event.preventDefault();
  }
};

const LatestAndSelectedReports = ({ latestAndSelectedReports = [] }) => {
  const mq1025 = useMediaQueryWidth(800);

  const nrOfSelectedEventsPerSlide = latestAndSelectedReports?.length === 1 ? 1 : mq1025 ? 2 : 1;
  return (
    <div className={s.latestReports}>
      <div className={s.latestReportsContainer}>
        {latestAndSelectedReports?.length > 0 && (
          <>
            <div className={s.selectedEventsHeader}>
              <Typography variant="h3">Våra senaste rapporter</Typography>
            </div>
            <Carousel
              className={s.carousel}
              items={chunkArray(latestAndSelectedReports, nrOfSelectedEventsPerSlide).map(chunk => (
                <div
                  key={Math.floor(Math.random() * 1000)}
                  className={s.carouselItemChunk}
                  style={{ gridTemplateColumns: `repeat(${nrOfSelectedEventsPerSlide}, 1fr)` }}
                >
                  {chunk.map(report => (
                    <div className={s.reportBox}>
                      <div className={s.reportText}>
                        <span className={s.reportBoxLabelDate}>{formatDateWithYear(report.published)}</span>
                        <h4>
                          <a href={report.href} className={s.reportTitle}>
                            {report.title}
                          </a>
                        </h4>
                      </div>
                      <div className={s.downloadButtonWrapper}>
                        <DownloadButton onClick={e => openLink(e, report.secondaryLink)}>
                          <FontAwesomeIcon icon={faFilePdf} className={s.fileIconDownload} />
                          Ladda ner
                        </DownloadButton>
                      </div>
                      <a href={report.href} className={s.coverImage}>
                        <img
                          src={report.coverImage || '/defaultReport.png'}
                          width="100%"
                          height="auto"
                          alt={report.title}
                        />
                      </a>
                    </div>
                  ))}
                </div>
              ))}
            />
            <div className={s.buttonWrapper}>
              <ButtonPrimaryAlt component={Link} to="/material/?q=*&contenttype=report">
                Alla rapporter
              </ButtonPrimaryAlt>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withStyles(s)(LatestAndSelectedReports);
