import { useState, useEffect, useCallback } from 'react';
import useClientSide from './useClientSide';

const useBreakpoint = (transformDensity = false) => {
  const isClientSide = useClientSide();

  const getSize = useCallback(() => {
    if (isClientSide) {
      const width = window.innerWidth * (transformDensity ? window.devicePixelRatio : 1);
      if (width < 541) return 'xs';
      if (width < 820) return 'sm';
      if (width < 1169) return 'md';
      if (width < 1600) return 'lg';
      return 'xl';
    }

    // Use xl if we're on the server
    return 'xl';
  }, [isClientSide]);

  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    setWindowSize(getSize());

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClientSide]);

  return windowSize;
};

export default useBreakpoint;
