/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import s from './SpecialRelatedArticles.css';

const SpecialRelatedArticles = ({ articles, fields = {} }) => {
  const filteredArticles = articles.filter(art => art !== undefined);
  if (filteredArticles.length === 0) return null;

  return (
    <div className={s.container}>
      <div className={s.relatedArticles}>
        <Typography className={s.text} variant="readMoreItem" darkMode>
          {fields?.title?.rawText}
        </Typography>
        {articles
          .filter(art => art !== undefined)
          .map(({ id, headline, href }) => (
            <div key={id} className={s.text}>
              <Typography variant="readMoreItem" darkMode>
                <Link target="_blank" to={href} className={s.link}>
                  <FontAwesomeIcon icon={faArrowRight} className={s.arrowRight} /> {headline}
                </Link>
              </Typography>
            </div>
          ))}
      </div>
    </div>
  );
};

export default withStyles(s)(SpecialRelatedArticles);
