import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ArrowNav.css';

const ArrowNav = ({ navigate }) => {
  return (
    <>
      <button
        aria-label="Föregående"
        type="button"
        onClick={() => navigate(-1)}
        className={classnames(s.arrowNav, s.left)}
      >
        <FontAwesomeIcon icon={faChevronLeft} width="100%" height="100%" />
      </button>
      <button
        aria-label="Nästa"
        type="button"
        onClick={() => navigate(1)}
        className={classnames(s.arrowNav, s.right)}
      >
        <FontAwesomeIcon icon={faChevronRight} width="100%" height="100%" />
      </button>
    </>
  );
};

export default withStyles(s)(ArrowNav);
