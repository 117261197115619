/* eslint-disable dot-notation */
import { createSelector } from 'reselect';
import getStructuredStorylineTreeWithHTML from './getStructuredStorylineTreeWithHTML';

export default createSelector(getStructuredStorylineTreeWithHTML, storylineTree => {
  try {
    const headGroup = [];
    const contentGroup = [];
    // basic grouping for presentation
    storylineTree.forEach(element => {
      switch (element.type) {
        case 'headline':
        case 'lead_text':
          headGroup.push(element);
          break;
        default:
          contentGroup.push(element);
      }
    });

    if (contentGroup?.[0]?.type === 'image') {
      const imageElement = contentGroup[0];
      const shouldBeHeadImage =
        imageElement?.fields?.alignment === undefined ||
        imageElement?.fields?.alignment?.rawText === 'center';
      if (shouldBeHeadImage) {
        const headImageElement = contentGroup.shift();
        headGroup.push({
          ...headImageElement,
          type: 'headImage',
        });
      }
    } else if (contentGroup?.[0]?.type === 'embed') {
      const headEmbedElement = contentGroup.shift();
      headGroup.push({
        ...headEmbedElement,
        type: 'headEmbed',
      });
    } else if (contentGroup?.[0]?.type === 'relation') {
      const relationElement = contentGroup[0];
      const shouldBeHeadRelationVideo = relationElement?.relation?.contentType === 'tv';
      if (shouldBeHeadRelationVideo) {
        const headRelationElement = contentGroup.shift();
        headGroup.push({
          ...headRelationElement,
          type: 'headRelation',
        });
      }
    } else if (contentGroup[0]?.type === 'gallery') {
      const headGallery = contentGroup.shift();
      headGroup.push({
        ...headGallery,
        type: 'headGallery',
      });
    }

    return {
      headGroup,
      contentGroup,
    };
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
