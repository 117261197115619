// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VFSForm-vfsForm-2n0Xx .VFSForm-inputs-1-WyO{grid-gap:1rem;display:grid;gap:1rem;grid-template-columns:1fr;margin:1rem 0}@media (min-width:820px){.VFSForm-vfsForm-2n0Xx .VFSForm-inputs-1-WyO{grid-template-columns:1fr 1fr 1fr}}.VFSForm-vfsForm-2n0Xx .VFSForm-inputs-1-WyO .VFSForm-textInputLabel-RxLgq{display:block;font-family:LabGrotesque-Bold,sans-serif;grid-column:1/-1}.VFSForm-vfsForm-2n0Xx .VFSForm-inputs-1-WyO .VFSForm-textInputLabel-RxLgq input{background-color:#fff;border:1px solid #cfd4d8;color:#62717f;display:block;font-family:LabGrotesque-Regular,sans-serif;font-size:1rem;line-height:1.5rem;margin:.5rem 0;padding:13px 16px;width:100%}.VFSForm-vfsForm-2n0Xx .VFSForm-results-3poH7{overflow:hidden}.VFSForm-vfsForm-2n0Xx .VFSForm-results-3poH7 .VFSForm-resultsInner-18-sq{padding-bottom:1rem;padding-top:2rem}.VFSForm-vfsForm-2n0Xx .VFSForm-results-3poH7 .VFSForm-resultsInner-18-sq .VFSForm-result-22RLT{-ms-flex-align:center;align-items:center;background:url(/vfs/vfs-arrow.png) 0 0 no-repeat;background-position:0;background-size:clamp(32px,4vw,48px);display:-ms-flexbox;display:flex;font-family:LabGrotesque-Regular,sans-serif;font-size:clamp(1.5rem,5vw,2.125rem);line-height:1.2;min-height:120px;padding-left:clamp(40px,7vw,72px)}.VFSForm-vfsForm-2n0Xx .VFSForm-results-3poH7 .VFSForm-resultsInner-18-sq .VFSForm-result-22RLT strong{font-family:LabGrotesque-Bold,sans-serif}.VFSForm-vfsForm-2n0Xx .VFSForm-results-3poH7 .VFSForm-resultsInner-18-sq .VFSForm-result-22RLT+.VFSForm-result-22RLT{margin-top:2rem}.VFSForm-vfsForm-2n0Xx .VFSForm-cta-32dC8{display:inline-block;margin:1rem 0}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"vfsForm": "VFSForm-vfsForm-2n0Xx",
	"inputs": "VFSForm-inputs-1-WyO",
	"textInputLabel": "VFSForm-textInputLabel-RxLgq",
	"results": "VFSForm-results-3poH7",
	"resultsInner": "VFSForm-resultsInner-18-sq",
	"result": "VFSForm-result-22RLT",
	"cta": "VFSForm-cta-32dC8"
};
module.exports = exports;
