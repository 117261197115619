// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HighlightedArticle-highlightedArticle-XHI7f{display:block;margin-bottom:20px;text-decoration:none}.HighlightedArticle-headline-2M3Nm{display:none}.HighlightedArticle-contentType-gyEyQ{text-transform:uppercase}.HighlightedArticle-externalIcon-1Cyya{color:inherit;height:12px;margin:0 0 0 8px;width:12px}.HighlightedArticle-highlightedArticle-XHI7f:hover .HighlightedArticle-headline-2M3Nm,.HighlightedArticle-highlightedArticle-XHI7f:hover .HighlightedArticle-headlineMobile-2wFdX{text-decoration:underline}.HighlightedArticle-highlightedArticle-XHI7f .HighlightedArticle-highlightedArticleImageWrapper-3E43m{margin-left:-24px;margin-right:-24px}.HighlightedArticle-paragraphLargeFix-3KsBV{margin-top:-10px!important}@media (min-width:541px){.HighlightedArticle-highlightedArticle-XHI7f{margin-bottom:32px}.HighlightedArticle-headline-2M3Nm{display:block}.HighlightedArticle-headlineMobile-2wFdX{display:none}.HighlightedArticle-highlightedArticle-XHI7f .HighlightedArticle-highlightedArticleImageWrapper-3E43m{margin:0}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"highlightedArticle": "HighlightedArticle-highlightedArticle-XHI7f",
	"headline": "HighlightedArticle-headline-2M3Nm",
	"contentType": "HighlightedArticle-contentType-gyEyQ",
	"externalIcon": "HighlightedArticle-externalIcon-1Cyya",
	"headlineMobile": "HighlightedArticle-headlineMobile-2wFdX",
	"highlightedArticleImageWrapper": "HighlightedArticle-highlightedArticleImageWrapper-3E43m",
	"paragraphLargeFix": "HighlightedArticle-paragraphLargeFix-3KsBV"
};
module.exports = exports;
