/* eslint-disable no-plusplus */
import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      const tags = [];
      const tagsLists = [];

      const issueTags = pickFrom(data, 'context.issueTags');
      const subjectTags = pickFrom(data, 'context.subjectTags');
      const organizationTags = pickFrom(data, 'context.organizationTags');
      const personTags = pickFrom(data, 'context.personTags');
      const locationTags = pickFrom(data, 'context.locationTags');

      tagsLists.push(issueTags, subjectTags, organizationTags, personTags, locationTags);

      for (let i = 0; i < tagsLists?.length; i++) {
        const list = tagsLists[i];

        if (list?.length > 0) {
          list.map(tag => {
            return tags.push(tag);
          });
        }
      }

      return [...(tags || [])];
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
