import React from 'react';
import Typography from 'components/atoms/Typography';
import pickFrom from 'utils/pickFrom';
import renderStorylineElement from 'utils/renderStorylineElement';

import htmlParser from 'utils/htmlParser';

/*
  Special case for h2 rendering, "inherit" rest from renderStorulineElement
*/

const renderElement = (element, attrDarkMode = false) => {
  const darkMode = typeof attrDarkMode === 'number' ? false : attrDarkMode;
  try {
    switch (element.type) {
      case 'headline':
        return (
          <Typography key={element.id} variant="h1Big" gutterBottom darkMode={darkMode}>
            {htmlParser(pickFrom(element, 'fields.headline.html'))}
          </Typography>
        );
      default:
        return renderStorylineElement(element, attrDarkMode);
    }
  } catch (error) {
    return undefined;
  }
};

export default renderElement;
