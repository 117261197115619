import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import Link from '../../../../containers/atoms/LinkContainer';
import sentencifyArray from '../../../../utils/sentencifyArray';
import s from './BlogPostInfo.css';

const BlogPostInfo = ({ blogName, blogUniqueName, authors }) => {
  return (
    <div
      className={classnames(s.blogPostInfo, {
        [s.fokusPaSkatterna]: blogUniqueName === 'fokus-pa-skatterna',
        [s.kompetensforsorjning]: blogUniqueName === 'kompetensforsorjning',
      })}
    >
      <span className={s.blogTitle}>BLOGG</span>
      <Link to={`/blogg/${blogUniqueName}/`} className={s.blogName}>
        {blogName}
      </Link>
      {authors?.length > 0 && (
        <div>
          {sentencifyArray(authors, {
            mapper: author =>
              author.href ? (
                <Link className={s.author} to={author.href} key={author.name}>
                  {author.name}
                </Link>
              ) : (
                <span key={author.name}>{author.name}</span>
              ),
          })}
        </div>
      )}
    </div>
  );
};

export default withStyles(s)(BlogPostInfo);
