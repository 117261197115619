/* eslint-disable react/jsx-curly-brace-presence */

/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Link from 'containers/atoms/LinkContainer';
import s from './FooterPublisher.css';
import Translate from '../../../containers/atoms/TranslateContainer';

const FooterPublisher = () => (
  <div className={s.footerPublisher}>
    <Translate>FOOTER.PUBLISHER</Translate>&nbsp;
    <Link to="/medarbetare/anna-dalqvist_1006333.html" className={s.footerPublisherLink}>
      Anna Dalqvist
    </Link>
  </div>
);

export default withStyles(s)(FooterPublisher);
