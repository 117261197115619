/* eslint-disable camelcase */
import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import { stringToVariant as stringToLabelVariant } from 'components/atoms/Label';
import boostrapGrid from 'style/bootstrap-grid.css';
import Translate from '../../../containers/atoms/TranslateContainer';
import BackgroundPolygon from '../../atoms/BackgroundPolygon/BackgroundPolygon';
import getBootstrapColumns from '../../../selectors/getBootstrapColumns';
import EventBox from '../../molecules/EventBox/EventBox';
import ButtonPrimaryAlt from '../../atoms/buttons/ButtonPrimaryAlt';
import Link from '../../../containers/atoms/LinkContainer';
import WebinarBox from '../../molecules/WebinarBox/WebinarBox';
import useTranslation from '../../../utils/hooks/useTranslation';
import Carousel from '../Carousel/Carousel';
import chunkArray from '../../../utils/chunkArray';
import useMediaQueryWidth from '../../../utils/hooks/useMediaQueryWidth';
import s from './EventsWebinars.css';

const EventsWebinars = ({
  selectedEvents = [],
  latestWebinars = [],
  darkBackground,
  upcomingEventsTitle = '',
  latestWebinarsTitle = '',
  forFlexibleSection = false,
}) => {
  const mq542 = useMediaQueryWidth(542);
  const mq821 = useMediaQueryWidth(821);
  const mq1025 = useMediaQueryWidth(1025);

  const translation = useTranslation();

  if (selectedEvents?.length < 1 && latestWebinars?.length < 1) return null;

  const nrOfLatestWebinarsPerSlide = mq1025 ? 3 : mq821 ? 2 : mq542 ? 2 : 1;
  const nrOfSelectedEventsPerSlide = selectedEvents?.length === 1 ? 1 : mq1025 ? 2 : 1;

  return (
    <>
      {!forFlexibleSection && <BackgroundPolygon dark />}
      <div className={s.selectedEvents}>
        <div className={classnames(boostrapGrid.container)}>
          {selectedEvents?.length > 0 && (
            <div className={classnames(boostrapGrid.row)}>
              <div className={getBootstrapColumns('18,3', '16,4')}>
                <div className={s.selectedEventsHeader}>
                  <Typography variant="h3" darkMode>
                    {upcomingEventsTitle ? (
                      <span>{upcomingEventsTitle}</span>
                    ) : (
                      <Translate>EVENTS.WEBINARS_AND_EVENTS</Translate>
                    )}
                  </Typography>
                </div>
                <Carousel
                  className={s.carousel}
                  items={chunkArray(selectedEvents, nrOfSelectedEventsPerSlide).map(chunk => (
                    <div
                      key={Math.floor(Math.random() * 1000)}
                      className={s.carouselItemChunk}
                      style={{ gridTemplateColumns: `repeat(${nrOfSelectedEventsPerSlide}, 1fr)` }}
                    >
                      {chunk.map(event => (
                        <EventBox
                          key={event.id + Math.floor(Math.random() * 1000)}
                          href={event.href}
                          contentType={event?.fields?.eventType}
                          hideTimeInformation={event?.fields?.hideTimeInformation === 'true'}
                          headline={event.title}
                          from={event?.fields?.startDate}
                          to={event?.fields?.endDate}
                          location={event?.fields?.eventTown}
                          implementationType={event?.fields?.implementationType}
                          deadlineDate={event?.fields?.deadlineDate}
                          labelVariant={stringToLabelVariant(event?.fields?.eventStatus)}
                        />
                      ))}
                    </div>
                  ))}
                />
                {translation.IS_SWEDISH && (
                  <div className={classnames(boostrapGrid.row)}>
                    <div className={classnames(getBootstrapColumns('24'))}>
                      <div className={s.buttonWrapper}>
                        <ButtonPrimaryAlt component={Link} to="/kalendarium/">
                          Alla kommande evenemang
                        </ButtonPrimaryAlt>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {latestWebinars?.length > 0 && (
            <div className={classnames(boostrapGrid.row, s.gutterTop)}>
              <div className={getBootstrapColumns('18,3', '16,4')}>
                <Typography variant="h5" darkMode gutterTop gutterBottom>
                  {latestWebinarsTitle ? (
                    <span>{latestWebinarsTitle}</span>
                  ) : (
                    <Translate>EVENTS.OUR_LATEST_WEBINARS</Translate>
                  )}
                </Typography>
                <Carousel
                  className={s.carousel}
                  items={chunkArray(latestWebinars, nrOfLatestWebinarsPerSlide).map(chunk => (
                    <div
                      className={s.carouselItemChunk}
                      style={{ gridTemplateColumns: `repeat(${nrOfLatestWebinarsPerSlide}, 1fr)` }}
                    >
                      {chunk.map(({ title, image, id, href, airedDate }) => (
                        <WebinarBox key={id} href={href} date={airedDate} image={image} headline={title} />
                      ))}
                    </div>
                  ))}
                />
                {translation.IS_SWEDISH && (
                  <div className={classnames(boostrapGrid.row)}>
                    <div className={classnames(getBootstrapColumns('24'))}>
                      <div className={s.buttonWrapper}>
                        <ButtonPrimaryAlt component={Link} to="/kalendarium/inspelade-webbinarier/">
                          Alla inspelade evenemang
                        </ButtonPrimaryAlt>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {!forFlexibleSection && (
        <BackgroundPolygon inverted dark classes={classnames(darkBackground && s.polygon)} />
      )}
    </>
  );
};

export default withStyles(s, boostrapGrid)(EventsWebinars);
