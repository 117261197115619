/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SpecialSocialBall.css';

const SpecialSocialBall = ({ image, brand, width, height, color = '', darkMode }) => (
  <div className={classnames(s.socialBall, s[brand], darkMode && s.darkMode)}>
    <FontAwesomeIcon icon={image} className={s.image} style={{ width, height }} color={color} />
  </div>
);

export default withStyles(s)(SpecialSocialBall);
