import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { SPACES, COLOR_MAP } from '../../../utils/constants';
import s from './GridChild.css';
import BackgroundMediaLayer from '../../BackgroundMediaLayer/BackgroundMediaLayer';
import getCssSizesVars from '../../../utils/getCssSizesVars';

const GridChild = ({ children, as: As = 'div', className, options }) => {
  const gridColumnStartCssVars = getCssSizesVars({
    object: options,
    cssProperty: 'gridColumnStart',
    cssVarPrefix: '--grid-column-start-',
    options: { useGenericValueAtBreakpoint: 'md' },
  });
  const gridColumnEndCssVars = getCssSizesVars({
    object: options,
    cssProperty: 'gridColumnEnd',
    cssVarPrefix: '--grid-column-end-',
    options: { useGenericValueAtBreakpoint: 'md' },
  });
  const gridRowStartCssVars = getCssSizesVars({
    object: options,
    cssProperty: 'gridRowStart',
    cssVarPrefix: '--grid-row-start-',
    options: { useGenericValueAtBreakpoint: 'md' },
  });
  const gridRowEndCssVars = getCssSizesVars({
    object: options,
    cssProperty: 'gridRowEnd',
    cssVarPrefix: '--grid-row-end-',
    options: { useGenericValueAtBreakpoint: 'md' },
  });

  return (
    <As
      className={classnames(s.gridChild, {
        [className]: !!className,
      })}
      style={{
        backgroundColor: COLOR_MAP[options.backgroundColor]
          ? `rgba(${COLOR_MAP[options.backgroundColor]}, ${options.backgroundColorOpacity / 100 ?? 1})`
          : COLOR_MAP.DEFAULT,
        padding: SPACES[options.padding] ?? SPACES.DEFAULT,
        ...gridColumnStartCssVars,
        ...gridColumnEndCssVars,
        ...gridRowStartCssVars,
        ...gridRowEndCssVars,
        ...(options.justifySelf ? { justifySelf: options.justifySelf } : {}),
        ...(options.alignSelf ? { alignSelf: options.alignSelf } : {}),
        ...(options.justifyContent ? { justifyContent: options.justifyContent } : {}),
        ...(options.alignContent ? { alignContent: options.alignContent } : {}),
      }}
    >
      {options.backgroundMedia && <BackgroundMediaLayer media={options.backgroundMedia} />}
      <div className={s.child}>{children}</div>
    </As>
  );
};

export default withStyles(s)(GridChild);
