import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-solid-svg-icons';
import s from './ButtonPrimaryAlt.css';

const ButtonPrimaryAlt = ({
  classes,
  component: Component = 'button',
  gutterBottom,
  block,
  children,
  type = 'button',
  className = '',
  loading,
  size = 'md',
  ...other
}) => {
  const classNames = classnames(
    s.buttonPrimaryAlt,
    block && s.block,
    gutterBottom && s.gutterBottom,
    classes,
    {
      [className]: !!className,
      [s.sm]: size === 'sm',
      [s.md]: size === 'md',
      [s.lg]: size === 'lg',
    }
  );

  return (
    <Component className={classNames} {...(Component === 'button' ? { type } : {})} {...other}>
      {loading && (
        <FontAwesomeIcon
          style={{ width: 20, height: 20, marginRight: 10 }}
          icon={faLoader}
          color="white"
          spin
        />
      )}
      {children}
    </Component>
  );
};

export default withStyles(s)(ButtonPrimaryAlt);
