// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SelfSustainingCounty-selfSustainingCounty-D_a7v{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;margin-bottom:1.9rem;margin-top:1.9rem}.SelfSustainingCounty-selfSustainingCounty-D_a7v .SelfSustainingCounty-desktopMap-1NEuF{display:none;height:100%;width:30%}@media (min-width:820px){.SelfSustainingCounty-selfSustainingCounty-D_a7v .SelfSustainingCounty-desktopMap-1NEuF{display:block}}.SelfSustainingCounty-selfSustainingCounty-D_a7v .SelfSustainingCounty-body-1X78C{display:-ms-flexbox;display:flex}.SelfSustainingCounty-selfSustainingCounty-D_a7v .SelfSustainingCounty-body-1X78C .SelfSustainingCounty-mobileMap-3sySS{max-width:30%}@media (min-width:820px){.SelfSustainingCounty-selfSustainingCounty-D_a7v .SelfSustainingCounty-body-1X78C .SelfSustainingCounty-mobileMap-3sySS{display:none}}.SelfSustainingCounty-selfSustainingCounty-D_a7v .SelfSustainingCounty-selects-14Cch{grid-gap:16px;display:grid;gap:16px;grid-template-columns:1fr;margin-top:16px}@media (min-width:541px){.SelfSustainingCounty-selfSustainingCounty-D_a7v .SelfSustainingCounty-selects-14Cch{grid-template-columns:1fr 1fr}}.SelfSustainingCounty-selfSustainingCounty-D_a7v .SelfSustainingCounty-keyValues-CwKx9{-ms-flex-align:end;grid-gap:16px;align-items:flex-end;display:grid;gap:16px;grid-template-areas:\"b1 b1\" \"b2 b2\" \"b3 b3\" \"b4 b4\";grid-template-columns:1fr 1fr;margin-top:24px;padding:0 32px}@media (min-width:541px){.SelfSustainingCounty-selfSustainingCounty-D_a7v .SelfSustainingCounty-keyValues-CwKx9{grid-template-areas:\"b1 b3\" \"b2 b4\";padding:0}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"selfSustainingCounty": "SelfSustainingCounty-selfSustainingCounty-D_a7v",
	"desktopMap": "SelfSustainingCounty-desktopMap-1NEuF",
	"body": "SelfSustainingCounty-body-1X78C",
	"mobileMap": "SelfSustainingCounty-mobileMap-3sySS",
	"selects": "SelfSustainingCounty-selects-14Cch",
	"keyValues": "SelfSustainingCounty-keyValues-CwKx9"
};
module.exports = exports;
