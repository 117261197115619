import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Link from 'containers/atoms/LinkContainer';
import s from './FooterLinks.css';
import Translate from '../../../containers/atoms/TranslateContainer';
import useTranslation from '../../../utils/hooks/useTranslation';

const FooterLinks = () => {
  const { IS_SWEDISH } = useTranslation();

  return (
    <div className={s.footerLinks}>
      <Link to={IS_SWEDISH ? '/dataskydd/' : '/english/data-protection/'} className={s.footerLink}>
        <Translate>FOOTER.LINKS.PRIVACY_POLICY</Translate>
      </Link>
      <Link to="/cookie-settings/" className={s.footerLink}>
        <Translate>FOOTER.LINKS.COOKIE_SETTINGS</Translate>
      </Link>
      {IS_SWEDISH && (
        <Link to="/medlem/intresseanmalan/" className={s.footerLink}>
          Bli medlemsföretag
        </Link>
      )}
    </div>
  );
};

export default withStyles(s)(FooterLinks);
