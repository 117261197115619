// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ScrollToShowMore-container-11igm{-ms-flex-align:end;align-items:flex-end;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;white-space:nowrap}.ScrollToShowMore-container-11igm .ScrollToShowMore-text-1SfYY{font-size:.75rem;font-style:italic}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"container": "ScrollToShowMore-container-11igm",
	"text": "ScrollToShowMore-text-1SfYY"
};
module.exports = exports;
