/* eslint-disable prettier/prettier */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import Typography from 'components/atoms/Typography';
import s from './ContentTypeDate.css';

const ContentTypeDate = ({ contentType, darkMode, date, classes, ...other }) => {
  const classNames = classnames(classes, s.contentTypeDate);
  return (
    <div className={classNames} {...other}>
      <Typography variant="contentTypeDate" darkMode={darkMode} classes={classnames(s.contentType, date && s.contentTypeMargin)}>
        {contentType}
      </Typography>
      <Typography variant="contentTypeDate" darkMode={darkMode}>
        {date}
      </Typography>
    </div>
  );
};

export default withStyles(s)(ContentTypeDate);
