import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';
import _sortBy from 'lodash/sortBy';
import clientifyUrl from '../utils/clientifyUrl';

const validateDate = date => date !== '' && date !== undefined;

export const processUpcomingEvents = events => {
  const sortedEvents = _sortBy(events, event => event?.fields?.startDate);
  const validEvents = sortedEvents?.filter(
    event => validateDate(event.fields?.startDate) && validateDate(event.fields?.endDate)
  );
  const validUpcomingEvents = validEvents?.filter(event => new Date(event.fields?.endDate) > new Date());
  return validUpcomingEvents.map(event => ({
    ...event,
    href: clientifyUrl(event?.href),
  }));
};

export default createSelector(cookSelectors.getData, data => {
  try {
    const upcomingEvents = pickFrom(data, 'upcomingEvents', 'latestEvents');
    return processUpcomingEvents(upcomingEvents);
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
