import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Select from 'react-select';
import useClientSide from '../../../utils/hooks/useClientSide';
import Spinner from '../../atoms/Spinner/Spinner';
import s from './ComboBox.css';

const ComboBox = props => {
  const isClientSide = useClientSide();

  if (!isClientSide) {
    return null;
  }

  return (
    <Select
      {...props}
      components={{ LoadingIndicator: () => <Spinner className={s.spinner} /> }}
      menuPortalTarget={document.body}
      styles={{
        container: provided => ({
          ...provided,
          marginTop: '0.5rem',
          ...props.customStyles?.container,
        }),
        control: provided => ({
          ...provided,
          borderRadius: 0,
          ...props.customStyles?.control,
        }),
        dropdownIndicator: provided => ({
          ...provided,
          color: '#ea8f12',
          cursor: 'pointer',
          ':hover': {
            color: '#d88514',
          },
          ...props.customStyles?.dropdownIndicator,
        }),
        valueContainer: provided => ({
          ...provided,
          fontFamily: 'LabGrotesque-Regular, sans-serif',
          padding: '9px 16px',
          fontSize: '1rem',
          ...props.customStyles?.valueContainer,
        }),
        placeholder: provided => ({
          ...provided,
          color: '#62717f',
          ...props.customStyles?.placeholder,
        }),
        input: provided => ({
          ...provided,
          color: '#33414e',
          ...props.customStyles?.input,
        }),
        singleValue: provided => ({
          ...provided,
          color: '#33414e',
          ...props.customStyles?.singleValue,
        }),
        menu: provided => ({
          ...provided,
          zIndex: 9999,
          borderRadius: 0,
          ...props.customStyles?.menu,
        }),
        option: (provided, state) => ({
          ...provided,
          color: '#33414e',
          background: state.isFocused ? 'rgba(0, 0, 0, .1)' : 'white',
          ...props.customStyles?.option,
        }),
        menuPortal: provided => ({
          ...provided,
          zIndex: 999999999,
          ...props.customStyles?.menuPortal,
        }),
      }}
    />
  );
};

export default withStyles(s)(ComboBox);
