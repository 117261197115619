// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AuthorBlogPostsSlider-authorBlogPostsSlider-oZyJS{padding:1rem 0}.AuthorBlogPostsSlider-authorBlogPostsSlider-oZyJS .AuthorBlogPostsSlider-postLink-S5DYI{text-decoration:none}.AuthorBlogPostsSlider-authorBlogPostsSlider-oZyJS .AuthorBlogPostsSlider-postLink-S5DYI:hover h3{text-decoration:underline}", ""]);
// Exports
exports.locals = {
	"authorBlogPostsSlider": "AuthorBlogPostsSlider-authorBlogPostsSlider-oZyJS",
	"postLink": "AuthorBlogPostsSlider-postLink-S5DYI"
};
module.exports = exports;
