const parseBreakpoints = input => {
  const regEx = /\[(?<size>xxs|xs|sm|md|lg|xl):(?<value>[\w(),\s]+)\]/g;
  const matches = [...input.matchAll(regEx)];

  const parsed =
    matches.length === 0
      ? input
      : matches.reduce(
          (acc, current) => ({
            ...acc,
            [current.groups.size.trim()]: current.groups.value.trim(),
          }),
          {}
        );

  return parsed;
};

export default parseBreakpoints;
