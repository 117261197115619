import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { faBuilding, faSignOut, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import Link from 'containers/atoms/LinkContainer';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import useAuth from '../../../../../utils/hooks/useAuth';
import useDarkHeader from '../../../../../utils/hooks/useDarkHeader';
import UserCompaniesList from '../UserCompaniesList/UserCompaniesList';
import useUser from '../../../../../utils/hooks/useUser';
import userMenu from '../userMenu';
import s from './DesktopLoggedInMenu.css';

const DesktopLoggedInMenu = () => {
  const { logout } = useAuth();
  const { userQuery } = useUser();
  const isDarkHeader = useDarkHeader();

  // TODO: Add correct URLs to list items
  return (
    <div tabIndex={0} role="button" className={s.desktopLoggedInMenu}>
      <div className={s.status}>
        <span className={s.nameInitials}>{userQuery.data?.userData?.nameInitials}</span>
        {userQuery.data?.userData?.activeCompany && (
          <span
            className={classnames(s.company, {
              [s.darkMode]: isDarkHeader,
            })}
          >
            {userQuery.data.userData.activeCompany.name}
          </span>
        )}
      </div>

      <div className={s.popoutWrapper}>
        <div className={s.popout}>
          <div className={s.header}>
            <ul>
              <li className={s.name}>
                <FontAwesomeIcon icon={faUser} />
                {userQuery.data?.userData?.name}
              </li>
              {userQuery.data?.userData?.activeCompany?.name && (
                <li className={s.company}>
                  <FontAwesomeIcon icon={faBuilding} />
                  {userQuery.data.userData.activeCompany.name}
                </li>
              )}
            </ul>
          </div>
          <ul>
            {userQuery.data.userData?.companies?.length > 0 && <UserCompaniesList />}
            {userMenu.map(menuItem => (
              <li key={menuItem.id}>
                <Link to={menuItem.href} target={menuItem?.icon === 'building' ? '_blank' : '_self'}>
                  <span style={{ marginRight: 5 }}>{menuItem.linkText}</span>
                  {menuItem?.icon === 'building' && <FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                </Link>
              </li>
            ))}
            <li>
              <button type="button" onClick={logout}>
                <FontAwesomeIcon icon={faSignOut} /> Logga ut
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(DesktopLoggedInMenu);
