import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './List.css';

const List = ({ classes, ordered, orange, children, ...other }) => {
  const classNames = classnames(s.list, classes);
  const Component = ordered ? 'ol' : 'ul';
  return ordered ? (
    <Component className={classNames} {...other}>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, { ...child.props, number: index + 1, orange });
      })}
    </Component>
  ) : (
    <Component className={classNames} {...other}>
      {React.Children.map(children, child => {
        return React.cloneElement(child, { ...child.props, orange });
      })}
    </Component>
  );
};

export default withStyles(s)(List);
