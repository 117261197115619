import React, { useState } from 'react';
import { animate, motion, useMotionValue, useDragControls } from 'framer-motion';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import useDimensions from '../../../utils/hooks/useDimensions';
import ArrowNav from './components/ArrowNav/ArrowNav';
import DotNav from './components/DotNav/DotNav';
import s from './Carousel.css';
import ImageNumber from './components/ImageNumber/ImageNumber';

const Carousel = ({
  items,
  className = '',
  style = {},
  innerClassName = '',
  styleInner = {},
  darkMode,
  rotatePagination = true,
  showItemNumber = false,
}) => {
  const dragControls = useDragControls();
  const x = useMotionValue(0);
  const [ref, { width }] = useDimensions();

  const [currentIndex, setCurrentIndex] = useState(0);

  if (!items) {
    return null;
  }

  function startDrag(event) {
    dragControls.start(event, { snapToCursor: false });
  }

  // Add a key to each item to avoid React warnings
  const carouselItems = items.map((item, i) => ({ id: i, item }));

  const navigateTo = index => {
    setCurrentIndex(index);
    animate(x, width * index * -1, { duration: 0.5, ease: 'easeInOut' });
  };

  const navigate = dir => {
    const nextIndex =
      currentIndex + dir < 0
        ? rotatePagination
          ? carouselItems.length - 1
          : 0
        : currentIndex + dir > carouselItems.length - 1
        ? rotatePagination
          ? 0
          : carouselItems.length - 1
        : currentIndex + dir;

    navigateTo(nextIndex);
  };

  const swipeConfidenceThreshold = 100;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  return (
    <div
      className={classnames(s.carousel, {
        [className]: !!className,
      })}
      style={style}
    >
      {items.length > 1 && <ArrowNav navigate={navigate} />}
      <motion.div
        onPointerDown={startDrag}
        className={classnames(s.inner, {
          [innerClassName]: !!innerClassName,
        })}
        style={styleInner}
      >
        {items.length > 1 ? (
          <motion.div
            ref={ref}
            drag="x"
            // dragPropagation
            dragConstraints={{ left: 0, right: 0 }}
            onDrag={(e, info) => {
              x.set(width * currentIndex * -1 + info.offset.x);
            }}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                navigate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                navigate(-1);
              } else {
                navigate(0);
              }
            }}
            className={s.innerInner}
            style={{ x }}
          >
            {carouselItems.map(({ id, item }) => (
              <div key={id} className={s.item}>
                {item}
              </div>
            ))}
          </motion.div>
        ) : (
          <div className={s.innerInner}>
            <div className={s.item}>{items[0]}</div>
          </div>
        )}
      </motion.div>
      {items.length > 1 && (
        <DotNav
          length={items.length}
          currentIndex={currentIndex}
          navigateTo={navigateTo}
          darkMode={darkMode}
        />
      )}
      {showItemNumber && <ImageNumber currentImageNumber={currentIndex + 1} numberOfImages={items.length} />}
    </div>
  );
};

export default withStyles(s)(Carousel);
