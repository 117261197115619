// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MailUsPage-mailUsPage-1Z9RY{margin-bottom:80px}.MailUsPage-mailUsPage-1Z9RY .MailUsPage-leftMenu-3rd5S{display:none}@media screen and (min-width:1169px){.MailUsPage-mailUsPage-1Z9RY .MailUsPage-leftMenu-3rd5S{display:none;display:block;margin-left:-.75rem;margin-right:-.75rem;padding-bottom:5.25rem;padding-top:6px;position:sticky;top:12rem}.MailUsPage-mailUsPage-1Z9RY .MailUsPage-leftMenu-3rd5S .MailUsPage-text-2VbWD{text-transform:uppercase;word-break:break-word}.MailUsPage-mailUsPage-1Z9RY .MailUsPage-leftMenu-3rd5S .MailUsPage-leftMenuIndepthItem-3TNm9{margin-bottom:0;margin-top:.25rem}.MailUsPage-mailUsPage-1Z9RY .MailUsPage-leftMenu-3rd5S .MailUsPage-leftMenuIndepthItem-3TNm9:first-child{margin-top:.5rem}.MailUsPage-mailUsPage-1Z9RY .MailUsPage-leftMenu-3rd5S .MailUsPage-leftMenuItem-2B3JL{font-size:.875rem;font-weight:700}.MailUsPage-mailUsPage-1Z9RY .MailUsPage-leftMenu-3rd5S .MailUsPage-leftMenuItem-2B3JL.MailUsPage-selected-zown_{color:#ea8f12}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"mailUsPage": "MailUsPage-mailUsPage-1Z9RY",
	"leftMenu": "MailUsPage-leftMenu-3rd5S",
	"text": "MailUsPage-text-2VbWD",
	"leftMenuIndepthItem": "MailUsPage-leftMenuIndepthItem-3TNm9",
	"leftMenuItem": "MailUsPage-leftMenuItem-2B3JL",
	"selected": "MailUsPage-selected-zown_"
};
module.exports = exports;
