import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import TextArea from 'components/atoms/TextArea';
import s from './TextAreaField.css';

const TextAreaField = ({ classes, id, label, register, ...other }) => {
  const classNames = classnames(s.textAreaField, classes);
  return (
    <div className={classNames}>
      <Typography id={`${id}-label`} htmlFor={id} variant="inputLabel" gutterBottom>
        {label}
      </Typography>
      <TextArea id={id} name={id} register={register} {...other} />
    </div>
  );
};

export default withStyles(s)(TextAreaField);
