import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { useForm } from 'react-hook-form';
import Modal from '../Modal';
import ButtonPrimaryAlt from '../../atoms/buttons/ButtonPrimaryAlt';
import Link from '../../atoms/Link';
import Typography from '../../atoms/Typography';
import TextField from '../../molecules/TextField';
import ButtonSecondaryGhost from '../../atoms/buttons/ButtonSecondaryGhost';
import s from './NewsletterModal.css';
import useTranslate from '../../../utils/hooks/useTranslate';
import useTranslation from '../../../utils/hooks/useTranslation';
import useAuth from '../../../utils/hooks/useAuth';
import useUser from '../../../utils/hooks/useUser';
import useFeatureFlags from '../../../utils/hooks/useFeatureFlags';

const NewsletterModal = ({
  shouldShowModal,
  closeModal,
  onSubmit,
  success,
  error,
  name,
  posting,
  subscriptionStatus,
  selectedEmail,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const t = useTranslate();
  const { IS_SWEDISH } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { userQuery } = useUser();
  const featureFlags = useFeatureFlags();

  const shouldUseUserEmail =
    !featureFlags.disablePremiumContent && isAuthenticated && userQuery.data?.userData?.email;

  return (
    <Modal modalOpen={shouldShowModal} closeModal={closeModal} label="Prenumerera">
      <div className={s.subscribeBox}>
        {success ? (
          <div>
            <div className={s.successIconWrapper}>
              {(subscriptionStatus === 'ok' || subscriptionStatus === 'unsubscribed') && (
                <FontAwesomeIcon icon={faCheckCircle} className={s.successIcon} />
              )}
              {subscriptionStatus === 'pending' && (
                <FontAwesomeIcon icon={faInfoCircle} className={s.pendingIcon} />
              )}
              {subscriptionStatus === 'tryAgain' && (
                <FontAwesomeIcon icon={faExclamationCircle} className={s.warningIcon} />
              )}
              {subscriptionStatus === 'optOutAll' && (
                <FontAwesomeIcon icon={faInfoCircle} className={s.pendingIcon} />
              )}
              <Typography variant="h4" component="div">
                {subscriptionStatus === 'ok' && t('SUBSCRIBE.MODAL_OK_TITLE')}
                {subscriptionStatus === 'unsubscribed' && t('SUBSCRIBE.MODAL_OPT_OUT_TITLE')}
                {subscriptionStatus === 'pending' && t('SUBSCRIBE.MODAL_PENDING_TITLE')}
                {subscriptionStatus === 'tryAgain' && t('SUBSCRIBE.MODAL_TRY_AGAIN_TITLE')}
                {subscriptionStatus === 'optOutAll' && t('SUBSCRIBE.MODAL_OPT_OUT_TITLE')}
              </Typography>
            </div>
            <Typography>
              {subscriptionStatus === 'ok' && (
                <>
                  {t('SUBSCRIBE.MODAL_OK')} <strong>{name}</strong> med e-postadressen {selectedEmail}!
                </>
              )}
              {subscriptionStatus === 'unsubscribed' && (
                <>
                  {t('SUBSCRIBE.MODAL_UNSUBSCRIBE_OK')} <strong>{name}</strong> för e-postadressen{' '}
                  {selectedEmail}!
                </>
              )}
              {subscriptionStatus === 'pending' && t('SUBSCRIBE.MODAL_PENDING')}
              {subscriptionStatus === 'tryAgain' && t('SUBSCRIBE.MODAL_TRY_AGAIN')}
              {subscriptionStatus === 'optOutAll' && (
                <>
                  {t('SUBSCRIBE.MODAL_OPT_OUT')}{' '}
                  <Typography href="mailto:redaktion@svensktnaringsliv.se" component="a" variant="link">
                    redaktion@svensktnaringsliv.se
                  </Typography>{' '}
                  {t('SUBSCRIBE.MODAL_OPT_OUT_AFTER')}
                </>
              )}
            </Typography>
            <ButtonSecondaryGhost classes={s.closeButton} onClick={closeModal}>
              {t('SUBSCRIBE.MODAL_CLOSE')}
            </ButtonSecondaryGhost>
          </div>
        ) : (
          <>
            <Typography variant="contentTypeDate" component="div">
              {t('SUBSCRIBE.MODAL_TITLE')}
            </Typography>
            <Typography variant="h3" component="div" gutterBottom>
              {name}
            </Typography>
            <form autoComplete="on" onSubmit={handleSubmit(({ email }) => onSubmit(email))}>
              <div className={s.formsWrapper}>
                <TextField
                  classes={s.emailField}
                  id="email"
                  placeholder={t('SUBSCRIBE.ENTER_EMAIL')}
                  label={t('SUBSCRIBE.EMAIL_LABEL')}
                  gutterBottom={false}
                  labelHidden
                  type="email"
                  errorMessage={errors?.email?.message}
                  defaultValue={shouldUseUserEmail ? userQuery.data?.userData?.email ?? '' : ''}
                  register={register({
                    required: t('SUBSCRIBE.EMAIL_MISSING'),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t('SUBSCRIBE.EMAIL_INVALID'),
                    },
                  })}
                />
                <ButtonPrimaryAlt classes={s.subscribeButton} type="submit" disabled={posting}>
                  {posting && <div className={s.ldsDualRing} />}
                  {t('SUBSCRIBE.MODAL_COMPLETE')}
                </ButtonPrimaryAlt>
              </div>
            </form>
            {error && (
              <Typography component="div" classes={s.error} gutterBottom>
                {t('SUBSCRIBE.MODAL_ERROR')}
              </Typography>
            )}
            {isAuthenticated && shouldUseUserEmail && (
              <Typography variant="contentTypeDate" component="p" className={s.prefilledEmailInfo}>
                <FontAwesomeIcon icon={faCircleInfo} />
                <span>
                  {t('SUBSCRIBE.MODAL_PREFILLED_EMAIL', {
                    link: linkText => (
                      <Link
                        // TODO: Add correct link url
                        to={IS_SWEDISH ? '/dataskydd/' : '/english/data-protection/'}
                        target="_blank"
                        className={s.termsLink}
                      >
                        {linkText}
                      </Link>
                    ),
                  })}
                </span>
              </Typography>
            )}
            <Typography variant="contentTypeDate" component="div">
              {t('SUBSCRIBE.MODAL_FOOTER_COPY', {
                link: linkText => (
                  <Link
                    to={IS_SWEDISH ? '/dataskydd/' : '/english/data-protection/'}
                    target="_blank"
                    className={s.termsLink}
                  >
                    {linkText}
                  </Link>
                ),
              })}
            </Typography>
          </>
        )}
      </div>
    </Modal>
  );
};

export default withStyles(s)(NewsletterModal);
