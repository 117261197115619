const getIsOngoing = (startDateISO, endDateISO) => {
  const now = new Date();
  return now >= new Date(startDateISO) && now <= new Date(endDateISO);
};

const getIsPast = endDateISO => {
  const now = new Date();
  return now >= new Date(endDateISO);
};

const getIsFuture = startDateISO => {
  const now = new Date();
  return now <= new Date(startDateISO);
};

const getLiveEventBadgeType = ({ vimeoLiveEventUrl, vimeoVideoUrl, startDateISO, endDateISO }) => {
  const isLiveType = !!vimeoLiveEventUrl || !!vimeoVideoUrl;

  const isLive = isLiveType && getIsOngoing(startDateISO, endDateISO);
  const wasLive = isLiveType && getIsPast(endDateISO);
  const willBeLive = isLiveType && getIsFuture(startDateISO);

  const liveBadgeType = isLive ? 'live' : wasLive ? 'past' : willBeLive ? 'future' : null;

  return liveBadgeType;
};

export default getLiveEventBadgeType;
