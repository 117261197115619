// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PuffDoubleColumn-puffDoubleColumn-3F_bk{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;height:100%;width:100%}@media screen and (min-width:541px){.PuffDoubleColumn-puffDoubleColumn-3F_bk{padding:0 3rem}}@media screen and (min-width:700px){.PuffDoubleColumn-puffDoubleColumn-3F_bk{-ms-flex-wrap:nowrap;flex-wrap:nowrap;min-height:20.25rem;padding:0}}.PuffDoubleColumn-puffDoubleColumn-3F_bk .PuffDoubleColumn-imageWrapper-ylYic{width:100%}@media screen and (min-width:700px){.PuffDoubleColumn-puffDoubleColumn-3F_bk .PuffDoubleColumn-imageWrapper-ylYic{-ms-flex:0 0 50%;flex:0 0 50%}}.PuffDoubleColumn-puffDoubleColumn-3F_bk .PuffDoubleColumn-imageWrapper-ylYic .PuffDoubleColumn-image-2hRhy{background-position:50%;background-repeat:no-repeat;background-size:contain;height:11.25rem;width:100%}@media screen and (min-width:541px){.PuffDoubleColumn-puffDoubleColumn-3F_bk .PuffDoubleColumn-imageWrapper-ylYic .PuffDoubleColumn-image-2hRhy{height:14.25rem}}@media screen and (min-width:700px){.PuffDoubleColumn-puffDoubleColumn-3F_bk .PuffDoubleColumn-imageWrapper-ylYic .PuffDoubleColumn-image-2hRhy{height:100%}}.PuffDoubleColumn-puffDoubleColumn-3F_bk .PuffDoubleColumn-textWrapper-2N4r3{background-color:#62717f;padding:2.5rem 0}@media screen and (min-width:700px){.PuffDoubleColumn-puffDoubleColumn-3F_bk .PuffDoubleColumn-textWrapper-2N4r3{-ms-flex:0 0 50%;flex:0 0 50%;padding:2.5rem}}.PuffDoubleColumn-puffDoubleColumn-3F_bk .PuffDoubleColumn-textWrapper-2N4r3 .PuffDoubleColumn-button-MagPL{display:inline-block}.PuffDoubleColumn-puffDoubleColumn-3F_bk .PuffDoubleColumn-textWrapper-2N4r3 .PuffDoubleColumn-externalLinkIcon-38Kmd{font-size:14px;height:14px;margin-right:10px;margin-top:2px;width:14px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"puffDoubleColumn": "PuffDoubleColumn-puffDoubleColumn-3F_bk",
	"imageWrapper": "PuffDoubleColumn-imageWrapper-ylYic",
	"image": "PuffDoubleColumn-image-2hRhy",
	"textWrapper": "PuffDoubleColumn-textWrapper-2N4r3",
	"button": "PuffDoubleColumn-button-MagPL",
	"externalLinkIcon": "PuffDoubleColumn-externalLinkIcon-38Kmd"
};
module.exports = exports;
