export const COLOR_MAP = {
  none: 'transparent',
  orange: '234, 143, 18',
  red: '177, 47, 38',
  darkBlue: '51, 65, 78',
  steel: '98, 113, 127',
  creamyWhite: '250, 249, 247',
  lightBlue: '239, 242, 245',
  white: '255, 255, 255',
  DEFAULT: 'transparent',
};

export const SPACES = {
  none: 0,
  standard: '1.5rem',
  big: '3rem',
  DEFAULT: '1.5rem',
};

export const WIDTHS = {
  extraNarrow: '698px',
  default: '1023px',
  extraWide: '1396px',
  DEFAULT: '1023ox',
};

export const SIZES = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'];
