/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import getTranslation from '../../selectors/getTranslation';
import translations from '../../translations';
import pickFrom from '../pickFrom';

const useTranslate = () => {
  const { LANG } = useSelector(getTranslation);
  const translateLang = translations?.[LANG];

  const translate = (key, tags) => {
    const translatedString = pickFrom(translateLang, key);

    if (!translatedString) {
      console.warn(`Could not translate: ${key}`);
      return `;${key};`;
    }

    if (tags) {
      const regex = /<(?<tag>\w+)>(?<str>[^<]*)<\/\1>/g;
      const matches = [...translatedString.matchAll(regex)];

      const { result } = matches.reduce(
        (acc, current, i) => {
          const matchStart = current.index;
          const prev = current.input.slice(acc.currentSliceIndex, matchStart);

          const el = (
            <React.Fragment key={`${current.groups.tag}-${i}`}>
              {tags[current.groups.tag](current.groups.str)}
            </React.Fragment>
          );
          const last =
            i === matches.length - 1 ? (
              <React.Fragment key={`last-${i}`}>
                {current.input.slice(matchStart + current[0].length)}
              </React.Fragment>
            ) : undefined;

          return {
            result: [...acc.result, prev, el, ...(last ? [last] : [])],
            currentSliceIndex: matchStart + current[0].length,
          };
        },
        { result: [], currentSliceIndex: 0 }
      );

      return result;
    }

    return translatedString;
  };

  return translate;
};

export default useTranslate;
