import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import s from './Container.css';

const Container = ({ children, as: As = 'div', width, className }) => {
  return (
    <As
      style={{ maxWidth: width }}
      className={classnames(s.container, {
        [className]: !!className,
      })}
    >
      {children}
    </As>
  );
};

export default withStyles(s)(Container);
