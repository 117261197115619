// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Balk-balk--2srA{height:46px;position:relative;width:100%}.Balk-balkContent-1SZmv{height:46px;margin:auto;padding:.3px;text-align:center;width:91%}@media screen and (max-width:820px){.Balk-balkContent-1SZmv{width:95%}}@media screen and (max-width:541px){.Balk-balk--2srA,.Balk-balkContent-1SZmv{max-height:56px;min-height:46px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"balk": "Balk-balk--2srA",
	"balkContent": "Balk-balkContent-1SZmv"
};
module.exports = exports;
