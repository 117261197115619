import jwt from 'jsonwebtoken';

const getExpireDateFromToken = async idToken => {
  let snmoData;
  try {
    snmoData = jwt.decode(idToken);
  } catch (error) {
    console.error('Problem with idToken:', idToken, error);
    if (error.expiredAt) {
      console.error('expiredAt:', error.expiredAt);
    }
    throw error;
  }
  if (!snmoData.exp || !Number.isInteger(snmoData.exp) || snmoData.exp <= 0) {
    throw new Error('Provided idToken had no valid exp property');
  }
  return snmoData.exp;
};

export default getExpireDateFromToken;
