import React from 'react';
import BackgroundColorLayer from '../BackgroundColorLayer/BackgroundColorLayer';
import BackgroundMediaLayer from '../BackgroundMediaLayer/BackgroundMediaLayer';
import Container from '../Layout/Container/Container';
import GridParent from '../Layout/GridParent/GridParent';
import renderStorylineElement from '../../../../../utils/renderStorylineElement';
import CountDownBeam from '../CountDownBeam/CountDownBeam';
import Section from '../Layout/Section/Section';

const FlexibleContent = ({ avsnitt, zIndex }) => {
  return (
    <Section
      id={avsnitt?.avsnittOptions?.anchorTarget || undefined}
      isHero={avsnitt?.contentKey === 'hero'}
      key={avsnitt?.id}
      options={avsnitt?.avsnittOptions}
      zIndex={zIndex}
    >
      <BackgroundColorLayer color={avsnitt?.avsnittOptions?.backgroundColor} />
      {avsnitt?.avsnittOptions?.backgroundMedia && (
        <BackgroundMediaLayer media={avsnitt?.avsnittOptions?.backgroundMedia} />
      )}

      <Container width={avsnitt?.avsnittOptions?.width}>
        {avsnitt?.gridParents.map(gridParent => {
          return (
            <GridParent key={gridParent?.id} options={gridParent?.fields}>
              {gridParent?.elements.map(gridChild => renderStorylineElement(gridChild, false))}
            </GridParent>
          );
        })}
      </Container>
      {avsnitt?.avsnittOptions?.showHeroBeam && (
        <CountDownBeam
          backgroundColor={avsnitt?.avsnittOptions?.heroBeamBackgroundColor}
          countDownDate={avsnitt?.avsnittOptions?.heroBeamCountDownDate}
          showCountDown={avsnitt?.avsnittOptions?.showHeroBeamCountDown}
          text={avsnitt?.avsnittOptions?.heroBeamText}
          textAppendix={avsnitt?.avsnittOptions?.heroBeamTextAfterCountDown}
        />
      )}
    </Section>
  );
};

export default FlexibleContent;
