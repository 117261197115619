// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VariantMatrix-variantMatrix-1cx7S{margin-bottom:2rem;margin-top:2rem}.VariantMatrix-variantMatrix-1cx7S .VariantMatrix-articlesWrapper-3iq6V{grid-gap:24px;display:grid;gap:24px;grid-template-columns:repeat(auto-fit,minmax(200px,1fr))}.VariantMatrix-article-nCu17{color:#33414e;text-decoration:none}.VariantMatrix-article-nCu17:hover{text-decoration:underline}.VariantMatrix-article-nCu17 img{aspect-ratio:16/9;border:1px solid #eee;width:100%}.VariantMatrix-article-nCu17 .VariantMatrix-headline-WEl2Y{word-break:break-word}.VariantMatrix-article-nCu17 .VariantMatrix-externalIcon-3VnEM{color:inherit;height:12px;margin:0 0 2px 8px;width:12px}.VariantMatrix-article-nCu17.VariantMatrix-darkMode-13qnI img{border-color:transparent}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"variantMatrix": "VariantMatrix-variantMatrix-1cx7S",
	"articlesWrapper": "VariantMatrix-articlesWrapper-3iq6V",
	"article": "VariantMatrix-article-nCu17",
	"headline": "VariantMatrix-headline-WEl2Y",
	"externalIcon": "VariantMatrix-externalIcon-3VnEM",
	"darkMode": "VariantMatrix-darkMode-13qnI"
};
module.exports = exports;
