// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CountDownBeam-countDownBeam-316RQ{bottom:0;position:absolute;width:100%}.CountDownBeam-countDownBeam-316RQ .CountDownBeam-text-jzZrr{padding:.5rem;text-align:center}", ""]);
// Exports
exports.locals = {
	"countDownBeam": "CountDownBeam-countDownBeam-316RQ",
	"text": "CountDownBeam-text-jzZrr"
};
module.exports = exports;
