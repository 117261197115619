import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './PageLoader.css';

const PageLoader = ({ classes, ...other }) => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, 150);
    return () => clearTimeout(timer);
  }, []);
  const classNames = classnames(s.pageLoader, classes);
  return (
    showLoader && (
      <div className={classNames} {...other}>
        <div className={s.line} />
        <div className={classnames(s.subline, s.inc)} />
        <div className={classnames(s.subline, s.dec)} />
      </div>
    )
  );
};

export default withStyles(s)(PageLoader);
