import React from 'react';
import { useSelector } from 'react-redux';
import getBlogUniqueName from 'selectors/getBlogUniqueName';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './HeroHeader.css';

const HeroHeader = ({ children, bgImage, fullWidthBg, dimmed }) => {
  const blogUniqueName = useSelector(getBlogUniqueName);

  // If fullWidthBg set the styles to the outer div (and therefore the image will stretch full width)
  const outerStyles = {
    ...(fullWidthBg && bgImage ? { backgroundImage: `url(${bgImage})` } : {}),
  };

  // ...otherwise put it on the inner div to center the bg
  const innerStyles = {
    ...(!fullWidthBg && bgImage ? { backgroundImage: `url(${bgImage})` } : {}),
  };

  return (
    <div
      className={classnames(s.heroHeader, {
        [s.dimmedFokusPaSkatterna]: dimmed && blogUniqueName === 'fokus-pa-skatterna',
        [s.dimmedKompetensforsorjning]: dimmed && blogUniqueName === 'kompetensforsorjning',
        [s.profileBlogImageblendBg]: !fullWidthBg && !!bgImage,
      })}
      style={outerStyles}
    >
      <div className={classnames(boostrapGrid.container, s.inner)} style={innerStyles}>
        {children}
      </div>
    </div>
  );
};

export default withStyles(s)(HeroHeader);
