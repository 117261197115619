import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import ms from '../../GOAContainer.css';
import s from './ColumnArkivering.css';
import ButtonPrimaryAlt from '../../../../atoms/buttons/ButtonPrimaryAlt/ButtonPrimaryAlt';

const ColumnArkivering = ({ reviewData, reviewedState, archiving, handleArchiveClicked }) => {
  const msgDefault =
    'Om sidan inte längre ska finnas kvar kan du arkivera den. Den försvinner då från sajten, men går fortfarande att komma åt genom att kontakta Redaktionen.';
  const msgNotArchivable =
    'Sidor av typen Tagginformation, Sakområde, Sektionsinformation och Kontaktinformation Regionkontor kan bara arkiveras av Redaktionen.';

  return (
    <>
      <Typography variant="h4" className={ms.headline}>
        Arkivering
      </Typography>
      <Typography variant="paragraphSmall">
        {!reviewData?.reviewData?.article?.isArchivable ? msgNotArchivable : msgDefault}
      </Typography>
      <ButtonPrimaryAlt
        disabled={!reviewData?.reviewData?.article?.isArchivable || reviewedState === 'archived'}
        loading={archiving}
        onClick={() => handleArchiveClicked()}
      >
        Arkivera nu
      </ButtonPrimaryAlt>
      {reviewedState === 'archived' && (
        <Typography variant="paragraphInfo">Artikeln är arkiverad.</Typography>
      )}
    </>
  );
};

export default withStyles(ms, s)(ColumnArkivering);
