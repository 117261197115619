import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import SelectNative from 'components/atoms/SelectNative';
import s from './SelectField.css';

const SelectField = ({ classes, id, label, register, hideLabel, ...other }) => {
  const classNames = classnames(s.selectField, classes);
  return (
    <div className={classNames}>
      <Typography
        id={`${id}-label`}
        htmlFor={id}
        variant="inputLabel"
        gutterBottom
        classes={classnames(hideLabel && s.hideLabel)}
      >
        {label}
      </Typography>
      <SelectNative id={id} name={id} register={register} {...other} />
    </div>
  );
};

export default withStyles(s)(SelectField);
