// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SpecialRelatedArticles-container-2emlI{-ms-flex-pack:end;display:-ms-flexbox;display:flex;justify-content:flex-end}.SpecialRelatedArticles-relatedArticles-3UC52{background:#62717f;padding:1.3rem;width:100%}.SpecialRelatedArticles-text-2ltpw:not(:last-of-type){margin-bottom:.9rem}.SpecialRelatedArticles-arrowRight-1Jag0{color:#ea8f12;height:1.125rem;margin-right:.5rem}.SpecialRelatedArticles-link-20CMq{color:#fff;font-size:1.125rem;margin-bottom:.5rem;text-decoration:none}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"container": "SpecialRelatedArticles-container-2emlI",
	"relatedArticles": "SpecialRelatedArticles-relatedArticles-3UC52",
	"text": "SpecialRelatedArticles-text-2ltpw",
	"arrowRight": "SpecialRelatedArticles-arrowRight-1Jag0",
	"link": "SpecialRelatedArticles-link-20CMq"
};
module.exports = exports;
