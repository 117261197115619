import { connect } from 'react-redux';
import TvPage from 'components/pages/TvPage';
import getPublishedDate from 'selectors/getPublishedDate';
import getRelatedItems from 'selectors/getRelatedItems';
import getArticleAuthors from 'selectors/getArticleAuthors';
import getMetdata from 'selectors/metadata/getMetdata';
import getTvPageVideo from 'selectors/getTvPageVideo';
import getPageLabel from 'selectors/getPageLabel';
import getPageTitle from 'selectors/getPageTitle';
import getArticleTags from 'selectors/getArticleTags';

const mapStateToProps = state => {
  return {
    publishedDate: getPublishedDate(state),
    relatedItems: getRelatedItems(state),
    authors: getArticleAuthors(state),
    metadata: getMetdata(state),
    pageVideo: getTvPageVideo(state),
    pageLabel: getPageLabel(state),
    pageTitle: getPageTitle(state),
    tags: getArticleTags(state),
  };
};

const mapDispatchToProps = undefined;

const TvPageContainer = connect(mapStateToProps, mapDispatchToProps)(TvPage);

export default TvPageContainer;
