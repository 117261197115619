import axios from 'axios';

const defaultMenuParameters = {
  main: 'main',
  extra: 'extra',
};

const fetchMenu = async (queryKey, dataPath) => {
  const { menuParameters = defaultMenuParameters } = queryKey[1];
  const { origin, pathname } = new URL(dataPath);
  const pathPrefix = pathname.includes('plate') ? 'plate' : 'cook';

  const endpointUrl = `${origin}/${pathPrefix}/.menu/?mainMenu=${menuParameters.main}&extraMenu=${menuParameters.extra}`;

  const {
    data: { data },
  } = await axios.get(endpointUrl, { withCredentials: true });

  const mainMenuWithoutFirstItemKey = data.mainMenu?.map(({ item }) => ({ ...item }));
  const extraMenuWithoutFirstItemKey = data.extraMenu?.map(({ item }) => ({ ...item }));

  return {
    mainMenu: mainMenuWithoutFirstItemKey,
    extraMenu: extraMenuWithoutFirstItemKey,
  };
};

export default fetchMenu;
