/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './FooterContact.css';
import useTranslation from '../../../utils/hooks/useTranslation';

const FooterContact = () => {
  const { IS_SWEDISH, IS_ENGLISH } = useTranslation();

  if (IS_SWEDISH)
    return (
      <div className={s.footerContact}>
        <div className={s.headline}>Kontakta Svenskt Näringsliv</div>
        <div>
          <span className={s.boldText}>Postadress:</span> 114 82 Stockholm
        </div>
        <div>
          <span className={s.boldText}>Besöksadress:</span> Storgatan 19
        </div>
        <div>
          <span className={s.boldText}>Telefon:</span> 08-553 430 00
        </div>
        <p>
          <a href="/om_oss/kontakt/" className={s.footerContact}>
            Kontakta oss
          </a>
        </p>
      </div>
    );

  if (IS_ENGLISH)
    return (
      <div className={s.footerContact}>
        <div className={s.headline}>Contact our EU Office</div>
        <div>
          <h4 className={s.boldText}>Address</h4>
          Rue du Luxembourg 3
          <br />
          BE-1000 Bruxelles
        </div>

        <div>
          <h4 className={s.boldText}>Email</h4>
          <a href="mailto:eu-office@swedishenterprise.se" className={s.footerContact}>
            eu-office@swedishenterprise.se
          </a>
        </div>
      </div>
    );

  return undefined;
};

export default withStyles(s)(FooterContact);
