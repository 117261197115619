import React from 'react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'containers/atoms/LinkContainer';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import getDormainFromUrl from 'utils/getDomainFromUrl';
import getImages from 'utils/getImages';
import pickFrom from 'utils/pickFrom';
import useTranslate from '../../../utils/hooks/useTranslate';
import ResponsiveImage from '../../atoms/ResponsiveImage';
import Typography from '../../atoms/Typography';
import s from './SelectedContent.css';

const VARIANTS = {
  threeInARow: 'threeInARow',
  topArticleAndTwoSmall: 'topArticleAndTwoSmall',
  DEFAULT: 'threeInARow',
};

const SelectedContent = ({ selectedContent, variant }) => {
  const t = useTranslate();
  const displayVariant = VARIANTS[variant] || VARIANTS.DEFAULT;

  return (
    <div
      className={classnames(s.selectedContent, {
        [s.threeInARow]: displayVariant === VARIANTS.threeInARow,
        [s.topArticleAndTwoSmall]: displayVariant === VARIANTS.topArticleAndTwoSmall,
      })}
    >
      <div className={s.inner}>
        {selectedContent.map((story, i) => {
          const imageHref = pickFrom(story, 'teaserImage[0].content.fields.BASE_LANDSCAPE.href_full');
          const imageWidth = pickFrom(story, 'teaserImage[0].content.fields.BASE_LANDSCAPE.sourceWidth');
          const imageHeight = pickFrom(story, 'teaserImage[0].content.fields.BASE_LANDSCAPE.sourceHeight');
          const sizes = getImages('LANDSCAPE');
          const eventType = story?.fields?.eventType ?? '';
          const event = story.contentType === 'event' ? eventType : story.contentType;
          const contentType = event;
          const imageSrc = imageHref && sizes.toSrc(imageHref, 'M');
          const imageSrcSet = imageHref && sizes.toSrcSet(imageHref);
          return (
            <Link
              key={story.id}
              to={story.href}
              className={s.item}
              target={contentType === 'link' ? '_blank' : undefined}
              style={{ gridArea: `g${i + 1}` }}
            >
              {imageSrc && (
                <ResponsiveImage
                  src={imageSrc}
                  srcSet={imageSrcSet}
                  alt="article image"
                  width={imageWidth}
                  height={imageHeight}
                />
              )}
              <Typography variant="contentTypeDate" className={s.label}>
                {contentType === 'link'
                  ? getDormainFromUrl(story.href)
                  : t(`CONTENT_TYPE.${contentType}`) || contentType}
                {contentType === 'link' && (
                  <FontAwesomeIcon aria-hidden="true" icon={faExternalLinkAlt} className={s.externalIcon} />
                )}
              </Typography>
              <Typography variant="h2" component="h2" classes={s.headline} gutterBottom>
                {story?.fields?.teaserTitle || story.title}
              </Typography>
              <Typography variant="paragraphLarge" classes={s.teaser}>
                {story?.fields?.teaser}
              </Typography>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default withStyles(s)(SelectedContent);
