// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CoworkerFiles-coworkerFiles-1vxcn{background-color:#fff;margin:auto;max-width:617px;min-height:100%;padding:45px 24px 24px;width:100vw}.CoworkerFiles-coworkerFiles-1vxcn div.CoworkerFiles-caption-2StZS:last-of-type:not(:first-child){border-top:1px solid #cfd4d8;margin-top:24px;padding-top:16px}@media (min-width:541px){.CoworkerFiles-coworkerFiles-1vxcn{min-height:auto;min-width:540px}}@media (min-width:820px){.CoworkerFiles-coworkerFiles-1vxcn{padding:40px}}.CoworkerFiles-headline-RrGAF{color:#1f272f;font-family:LabGrotesque-Bold,sans-serif;font-size:1.5rem;line-height:2rem;margin-bottom:32px}@media (min-width:820px){.CoworkerFiles-headline-RrGAF{margin-bottom:40px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"coworkerFiles": "CoworkerFiles-coworkerFiles-1vxcn",
	"caption": "CoworkerFiles-caption-2StZS",
	"headline": "CoworkerFiles-headline-RrGAF"
};
module.exports = exports;
