// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TnlvFeedItem-TnlvFeedItem-2UMJg{color:#1f272f;display:block;padding-bottom:24px;padding-left:60px;text-decoration:none}.TnlvFeedItem-first-rBOaU .TnlvFeedItem-title-2T4Ci{font-size:.75rem}.TnlvFeedItem-TnlvFeedItem-2UMJg:not(.TnlvFeedItem-first-rBOaU) .TnlvFeedItem-title-2T4Ci:before{border-left:2px solid #d2ecee;content:\"\";display:block;height:calc(100% + 18px);left:-19px;position:absolute;top:0;width:0}.TnlvFeedItem-contentWrapper-2w1rt{position:relative}.TnlvFeedItem-passedDateText-3URqw{color:#666;display:block;font-family:LabGrotesque-Regular,sans-serif;font-size:.675rem;line-height:0;text-align:right}.TnlvFeedItem-dateItem-1TcjB{color:#1f272f;font-family:Roboto Condensed;font-size:.875rem;left:-60px;line-height:1.75rem;position:absolute;top:-1px}.TnlvFeedItem-first-rBOaU .TnlvFeedItem-dateItem-1TcjB{top:3px}.TnlvFeedItem-dateItem-1TcjB:before{background-color:#fa255e;border:2px solid #fff;border-radius:50%;content:\" \";display:block;height:12px;left:36px;position:absolute;top:8px;width:12px;z-index:1}.TnlvFeedItem-text-30FK6{color:#1f1f1f;font-family:Roboto Condensed;font-size:1rem;line-height:1.5rem}.TnlvFeedItem-first-rBOaU .TnlvFeedItem-text-30FK6{font-size:1.25rem;line-height:1.75rem}@media (min-width:541px){.TnlvFeedItem-text-30FK6{font-size:1.25rem;line-height:1.75rem}.TnlvFeedItem-first-rBOaU .TnlvFeedItem-text-30FK6{font-size:1.75rem;line-height:2.25rem}}.TnlvFeedItem-TnlvFeedItem-2UMJg:not(.TnlvFeedItem-last-IHGS0) .TnlvFeedItem-text-30FK6:before{border-left:2px solid #fbc1d0;content:\"\";display:block;height:calc(100% + 18px);left:-19px;position:absolute;top:13px;width:0}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"TnlvFeedItem": "TnlvFeedItem-TnlvFeedItem-2UMJg",
	"first": "TnlvFeedItem-first-rBOaU",
	"title": "TnlvFeedItem-title-2T4Ci",
	"contentWrapper": "TnlvFeedItem-contentWrapper-2w1rt",
	"passedDateText": "TnlvFeedItem-passedDateText-3URqw",
	"dateItem": "TnlvFeedItem-dateItem-1TcjB",
	"text": "TnlvFeedItem-text-30FK6",
	"last": "TnlvFeedItem-last-IHGS0"
};
module.exports = exports;
