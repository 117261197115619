import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import extractImages from 'utils/extractImages';
import _replace from 'lodash/fp/replace';
import pickFrom from 'utils/pickFrom';
import reducer from 'utils/reduceTeaserOptions';

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, (data, hostname) => {
  const replaceHostname = _replace(hostname, '');

  if (data) {
    try {
      const expertOpinions = pickFrom(data, 'context.deskedContent.expertOpinions');

      return expertOpinions.map(obj => {
        const teaserOptionsRaw = pickFrom(obj, 'teaserOptions');
        const profileTeaserOptionsRaw = pickFrom(obj, 'content.profile[0].teaserOptions');
        const profileImage = pickFrom(obj, 'content.profile[0].content.profileImage');
        const teaserOptions = reducer(teaserOptionsRaw);
        const profileTeaserOptions = reducer(profileTeaserOptionsRaw);

        return {
          id: pickFrom(obj, 'content.id'),
          title: teaserOptions.useSummaryFields ? teaserOptions.title : pickFrom(obj, 'content.title'),
          href: replaceHostname(pickFrom(obj, 'content.href')),
          teaser: pickFrom(obj, 'content.fields.teaser'),
          images: profileImage && extractImages(profileImage),
          name: profileTeaserOptions.useSummaryFields
            ? profileTeaserOptions.title
            : pickFrom(obj, 'content.profile[0].content.title'),
          assignment: profileTeaserOptions.useSummaryFields
            ? profileTeaserOptions.assignment
            : pickFrom(obj, 'content.profile[0].content.fields.assignment'),
        };
      });
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }
  return undefined;
});
