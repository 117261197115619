import { useState } from 'react';
import pushToDataLayer from '../pushToDataLayer';

const initialState = {
  posting: false,
  subscriptionStatus: undefined,
  success: false,
  error: false,
};

function useSubscribeNewsletter({
  apsisMailingListId,
  mailchimpMailingListId,
  mailjetMailingListId,
  mailjetAccount,
}) {
  const [state, setState] = useState(initialState);
  const postUnSubscription = async (listRecipientId, newsletterName) => {
    if (listRecipientId == null || listRecipientId === '') return false;

    setState({ ...state, posting: true });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        apsisMailingListId,
        mailchimpMailingListId,
        mailjetMailingListId,
        mailjetAccount,
        listRecipientId,
      }),
    };
    pushToDataLayer('newsletterUnsubscribe', {
      newsletterTitle: newsletterName,
    });
    try {
      const response = await fetch('/unsubscribe', requestOptions);
      if (response.ok) {
        const data = await response.json();
        setState({
          ...state,
          posting: false,
          success: true,
          subscriptionStatus: data.status,
        });
        return true;
      }
      setState({
        ...state,
        posting: false,
        success: false,
        error: new Error('Unsubscription response not ok'),
      });
      return false;
    } catch (error) {
      setState({
        ...state,
        posting: false,
        success: false,
        error,
      });
      return false;
    }
  };

  const postSubscription = async (email, newsletterName) => {
    if (email == null || email === '') return false;

    setState({ ...state, posting: true });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        apsisMailingListId,
        mailchimpMailingListId,
        mailjetMailingListId,
        mailjetAccount,
        email,
      }),
    };
    pushToDataLayer('newsletterSignup', {
      newsletterTitle: newsletterName,
    });
    try {
      const response = await fetch('/subscribe', requestOptions);
      if (response.ok) {
        const data = await response.json();
        setState({
          ...state,
          posting: false,
          success: true,
          subscriptionStatus: data.status,
        });
        return true;
      }
      setState({
        ...state,
        posting: false,
        success: false,
        error: new Error('Subscription response not ok'),
      });
      return false;
    } catch (error) {
      setState({
        ...state,
        posting: false,
        success: false,
        error,
      });
      return false;
    }
  };

  return {
    onSubscribe: postSubscription,
    onUnSubscribe: postUnSubscription,
    resetSubscribeNewsletter: () => {
      setState(initialState);
    },
    ...state,
  };
}

export default useSubscribeNewsletter;
