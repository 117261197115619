import { connect } from 'react-redux';
import BusinessPanelFormPage from 'components/pages/BusinessPanelFormPage';
// import getBusinessPanelFormPageData from 'selectors/getBusinessPanelFormPageData';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';

const mapStateToProps = state => {
  return {
    //   pageData: getBusinessPanelFormPageData(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
  };
};

const mapDispatchToProps = undefined;

const BusinessPanelFormContainer = connect(mapStateToProps, mapDispatchToProps)(BusinessPanelFormPage);

export default BusinessPanelFormContainer;
