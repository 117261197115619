// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Tag-tag-31xNr{background:#eff2f5;border:1px solid #cfd4d8;border-radius:22px;color:#3568a7;cursor:pointer;display:inline-block;font-family:LabGrotesque-Regular,sans-serif;font-size:1rem;margin-bottom:16px;margin-right:8px;padding:12px 24px;text-decoration:none;-webkit-transition:background-color .2s ease-in-out;transition:background-color .2s ease-in-out}.Tag-tag-31xNr:hover{background:#faf9f7}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"tag": "Tag-tag-31xNr"
};
module.exports = exports;
