import React, { useState } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import history from 'utils/history';
import Typography from 'components/atoms/Typography';
import Checkbox from 'components/atoms/Checkbox';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import SearchBar from 'components/molecules/SearchBar';
import SearchResultItem from 'components/molecules/SearchResultItem';
import Modal from 'components/organisms/Modal';
import pickFrom from 'utils/pickFrom';
import firstValueInQueryObject from 'utils/firstValueInQueryObject';
import renderStorylineElement from 'utils/renderStorylineElement';
import exists from 'utils/exists';
import Link from 'containers/atoms/LinkContainer';
import boostrapGrid from 'style/bootstrap-grid.css';
import { useSelector } from 'react-redux';
import FilterBlock from './components/FilterBlock';
import s from './ListPage.css';
import useTranslate from '../../../utils/hooks/useTranslate';
import Translate from '../../../containers/atoms/TranslateContainer';
import FilterSelectedBlock from './components/FilterSelectedBlock';
import { getMetadata } from '../FlexibleSection/duck/selectors';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';

const buildSearchString = (basePath, searchTerm, filters) => {
  let query = `${basePath}?q=`;
  if (searchTerm) {
    query += encodeURIComponent(searchTerm);
  }
  Object.keys(filters).forEach(key => {
    if (filters[key] && key !== 'q') {
      filters[key].forEach(val => {
        query += `&${key}=${encodeURIComponent(val)}`;
      });
    }
  });
  return query;
};

const ListPage = ({
  groupedStorylineTree,
  pathWithoutParams,
  searchQuery,
  queryString,
  searchMetadata,
  searchResults,
  searchFacets,
  navigate,
  hideFilters,
}) => {
  const [state, setState] = useState({
    modalOpen: false,
    searchPhrase: '',
  });
  const [queryStrings, setQueryStrings] = useState({
    ...queryString,
    start: undefined,
  });
  const translate = useTranslate();

  const triggerFilter = filters => {
    const searchPath = buildSearchString(pathWithoutParams, searchQuery.q, filters);
    history.push(searchPath, { scrollPosition: window.scrollY }); // do SPA navigation
    navigate(searchPath, window.scrollY);
  };

  const unselectFilter = (filterId, filterValue) => {
    const newQueryString = {
      ...queryString,
      start: undefined,
    };
    newQueryString[filterId] = newQueryString[filterId].filter(val => val !== filterValue);
    setQueryStrings(newQueryString);
    triggerFilter(newQueryString);
  };

  const selectFilter = (filterId, value) => {
    const newQueryString = {
      ...queryString,
      start: undefined,
    };
    if (!newQueryString[filterId]) newQueryString[filterId] = [];
    newQueryString[filterId].push(value);
    setQueryStrings(newQueryString);
    triggerFilter(newQueryString);
  };

  /* const replaceFilter = (filterId, value) => {
    const newQueryString = {
      ...queryString,
      start: undefined,
    };
    newQueryString[filterId] = [value];
    triggerFilter(newQueryString);
  }; */

  const setSearchPhrase = searchPhrase => {
    setState({
      ...state,
      searchPhrase,
    });
  };

  const clearFilters = () => {
    setQueryStrings({});
    triggerFilter({});
  };

  const handleSubmit = e => {
    e.preventDefault();
    // const scrollPosition = typeof window !== 'undefined' ? window.scrollY : undefined;
    let searchPath = pathWithoutParams;
    if (exists(state.searchPhrase)) {
      searchPath += `?q=${encodeURIComponent(state.searchPhrase)}`;
    }
    history.push(searchPath, { scrollPosition: 0 }); // do SPA navigation
    navigate(searchPath);
  };

  const getItem = (itemsString, items) => {
    let text = '';

    for (let i = 0; i < items.length; i++) {
      text += `&${itemsString}=${items[i]}`;
    }
    return text;
  };

  const handleSubmitWithFilter = e => {
    e.preventDefault();
    let searchPath = `/sokresultat/?q=*`;
    if (exists(state.searchPhrase)) {
      // searchPath += `?q=${encodeURIComponent(state.searchPhrase)}`;
      searchPath = searchPath.replace('?q=*', `?q=${encodeURIComponent(state.searchPhrase)}`);
    }

    if (queryStrings.contenttype !== undefined) {
      searchPath += getItem('contenttype', queryStrings.contenttype);
    }
    if (queryStrings.focusarea !== undefined) {
      searchPath += getItem('focusarea', queryStrings.focusarea);
    }
    if (queryStrings.year !== undefined) {
      searchPath += getItem('year', queryStrings.year);
    }
    if (queryStrings.issue !== undefined) {
      searchPath += getItem('issue', queryStrings.issue);
    }

    history.push(searchPath, { scrollPosition: 0 }); // do SPA navigation
    navigate(searchPath);
  };

  const resetSearch = () => {
    setSearchPhrase('');
    history.push(pathWithoutParams, { scrollPosition: 0 }); // do SPA navigation
    navigate(pathWithoutParams);
  };

  // const sortItem = firstValueInQueryObject(queryString, 'sort') || firstValueInQueryObject(searchQuery, 'sort');
  const { headGroup, contentGroup } = groupedStorylineTree;
  const [checked, setChecked] = useState(false);
  const metadata = useSelector(getMetadata);

  return (
    <>
      <OGShareTags metadata={metadata} />
      <div className={s.listPage}>
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(boostrapGrid.row)}>
            <div
              className={
                hideFilters
                  ? getBootstrapColumns('16,4', '14,5', '12,6')
                  : getBootstrapColumns('20,2', '16,4', '13,1')
              }
            >
              {!hideFilters && (
                <Modal
                  modalOpen={state.modalOpen}
                  closeModal={() => setState({ ...state, modalOpen: false })}
                  label={translate('LIST_PAGE.FILTER_SEARCH')}
                >
                  <FilterBlock
                    searchQuery={searchQuery.q?.[0]}
                    facets={searchFacets}
                    filters={queryString || searchQuery}
                    selectFilter={selectFilter}
                    unselectFilter={unselectFilter}
                    clearFilters={clearFilters}
                    mobileMode
                    closeModal={() => setState({ ...state, modalOpen: false })}
                  />
                </Modal>
              )}
              <div className={s.mainContent}>
                {headGroup && headGroup.map(renderStorylineElement)}
                {contentGroup && contentGroup.map(renderStorylineElement)}
                {(() => {
                  const q = firstValueInQueryObject(searchQuery, 'q');
                  return (
                    q &&
                    q !== 'undefined' &&
                    q !== '*' && (
                      <Typography variant="h5" component="h2" gutterBottom classes={s.pageHeadline}>
                        <Translate>LIST_PAGE.SEARCHED_ON</Translate> “{q}”
                        <button type="button" className={s.removeButton} onClick={resetSearch}>
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            className={s.removeIcon}
                            style={{ width: 20, height: 20 }}
                          />
                        </button>
                      </Typography>
                    )
                  );
                })()}
                <div className={s.searchWrapper}>
                  <SearchBar
                    autoComplete="off"
                    classes={s.searchBar}
                    onSubmit={!checked ? handleSubmit : handleSubmitWithFilter}
                    placeholder={translate('LIST_PAGE.SEARCH')}
                    searchButton
                    searchTerm={state.searchPhrase}
                    handleChange={e => setSearchPhrase(e.target.value)}
                  />
                  <ButtonPrimaryAlt
                    classes={s.filterButton}
                    onClick={() => setState({ ...state, modalOpen: true })}
                  >
                    <Translate>LIST_PAGE.FILTER</Translate>
                  </ButtonPrimaryAlt>
                </div>
                <div className={s.sortItemsContainer}>
                  <div>
                    <Typography variant="contentTypeDate" classes={s.resultHeadlines}>
                      <Translate>LIST_PAGE.HITS</Translate>
                    </Typography>
                    {searchResults.length === 0 ? (
                      <span className={s.mediumFont}>{searchMetadata.resultCount}</span>
                    ) : (
                      <span className={s.mediumFont}>{`${
                        +firstValueInQueryObject(searchQuery, 'start') + 1
                      } - ${
                        +firstValueInQueryObject(searchQuery, 'start') + searchResults.length
                      } ${translate('LIST_PAGE.OF')} ${searchMetadata.resultCount}`}</span>
                    )}
                  </div>
                </div>
                {queryString && (queryString.contenttype || queryString.issue || queryString.year) && (
                  <div className={s.checkboxMobile}>
                    <p>
                      <Checkbox
                        id="activeFilterCheckbox2"
                        label={translate('LIST_PAGE.KEEP_ACTIVE_FILTERS_ON_SEARCH')}
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                      />
                    </p>
                  </div>
                )}
                <FilterSelectedBlock
                  searchQuery={searchQuery.q?.[0]}
                  facets={searchFacets}
                  filters={queryString || searchQuery}
                  selectFilter={selectFilter}
                  unselectFilter={unselectFilter}
                  clearFilters={clearFilters}
                  mobileMode
                />
                {searchResults?.map(item => {
                  const imageHref = pickFrom(item.images, 'images.BASE.LANDSCAPE.href_full');

                  const imageData = {
                    originalSize: pickFrom(item, 'images.originalSize'),
                    freeFormSourceWidth: pickFrom(item.images, 'images.BASE.FREEFORM.sourceWidth'),
                    freeFormSourceHeight: pickFrom(item.images, 'images.BASE.FREEFORM.sourceHeight'),
                  };

                  return (
                    <SearchResultItem
                      key={item.id}
                      href={item.href}
                      contentType={item.contentType}
                      title={item.title}
                      assignment={item.fields.assignment}
                      description={item.fields.description}
                      teaser={item.fields.teaser}
                      from={item.fields.startDate}
                      to={item.fields.endDate}
                      hideTimeInformation={item.fields.hideTimeInformation}
                      deadlineDate={item.fields.deadlineDate}
                      location={item.fields.location}
                      published={item.published}
                      imageHref={imageHref}
                      imageAlt="Kompetens"
                      binaryExtension={item?.binary?.extension}
                      binaryHref={item?.binary?.href}
                      imageData={imageData}
                    />
                  );
                })}
                <div />
              </div>
              <div className={s.showMoreButtons}>
                {searchMetadata.previousPage && searchMetadata.previousPage !== 'undefined' && (
                  <ButtonSecondaryGhost
                    key={`${searchMetadata.previousPage}-prevbutton`}
                    component={Link}
                    disabled={searchMetadata.previousPage === 'undefined'}
                    to={
                      searchMetadata.previousPage === 'undefined'
                        ? undefined
                        : `${pathWithoutParams}${searchMetadata.previousPage}`
                    }
                    classes={s.showMoreButton}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} className={s.cheveronLeft} />
                    <Translate>LIST_PAGE.PREVIOUS_PAGE</Translate>
                  </ButtonSecondaryGhost>
                )}
                {searchMetadata.nextPage && searchMetadata.nextPage !== 'undefined' && (
                  <ButtonSecondaryGhost
                    key={`${searchMetadata.nextPage}-nextbutton`}
                    component={Link}
                    disabled={searchMetadata.nextPage === 'undefined'}
                    to={
                      searchMetadata.nextPage === 'undefined'
                        ? undefined
                        : `${pathWithoutParams}${searchMetadata.nextPage}`
                    }
                    classes={s.showMoreButton}
                  >
                    <Translate>LIST_PAGE.NEXT_PAGE</Translate>
                    <FontAwesomeIcon icon={faChevronRight} className={s.cheveronRight} />
                  </ButtonSecondaryGhost>
                )}
              </div>
            </div>
            {!hideFilters && (
              <div className={classnames(getBootstrapColumns('22,1', '22,1', '8,2'))}>
                <div>
                  <FilterSelectedBlock
                    searchQuery={searchQuery.q?.[0]}
                    facets={searchFacets}
                    filters={queryString || searchQuery}
                    selectFilter={selectFilter}
                    unselectFilter={unselectFilter}
                    clearFilters={clearFilters}
                    mobileMode={false}
                  />
                </div>
                {queryString &&
                  (queryString.subject ||
                    queryString.contenttype ||
                    queryString.issue ||
                    queryString.year) && (
                    <div className={s.checkboxDesktop}>
                      <p className={s.checkboxDesktopStyle}>
                        <Checkbox
                          id="activeFilterCheckbox22"
                          label={translate('LIST_PAGE.KEEP_ACTIVE_FILTERS_ON_SEARCH')}
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                        />
                      </p>
                    </div>
                  )}
                {queryString &&
                (queryString.subject || queryString.contenttype || queryString.issue || queryString.year) ? (
                  <div className={s.sideContent}>
                    <FilterBlock
                      searchQuery={searchQuery.q?.[0]}
                      facets={searchFacets}
                      filters={queryString || searchQuery}
                      selectFilter={selectFilter}
                      unselectFilter={unselectFilter}
                      clearFilters={clearFilters}
                    />
                  </div>
                ) : (
                  <div className={s.adaptSideContent}>
                    <FilterBlock
                      searchQuery={searchQuery.q?.[0]}
                      facets={searchFacets}
                      filters={queryString || searchQuery}
                      selectFilter={selectFilter}
                      unselectFilter={unselectFilter}
                      clearFilters={clearFilters}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(s)(ListPage);
