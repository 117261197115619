import clientifyUrl from '../../../../utils/clientifyUrl';

const userMenu = [
  {
    id: 1,
    href: clientifyUrl('/min-sida/'),
    linkText: 'Min sida',
    icon: 'user',
  },
  {
    id: 2,
    href: 'https://medlemsportalen.svensktnaringsliv.se/',
    linkText: 'Medlemsportalen',
    icon: 'building',
  },
];

export default userMenu;
