// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ContactItem-contactItem-2h-tq{background-color:#fff;border-top:1px solid #dee4ea;color:#33414e;display:grid;min-height:6.5rem;padding:24px 0;text-decoration:none;-webkit-transition:background-color .2s ease-in-out;transition:background-color .2s ease-in-out}@media (min-width:541px){.ContactItem-contactItem-2h-tq{background-color:#faf9f7;padding:24px}}.ContactItem-desktop-3Bogz{display:none}@media (min-width:541px){.ContactItem-desktop-3Bogz{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}}.ContactItem-mobile-2d1VX{display:block}@media (min-width:541px){.ContactItem-mobile-2d1VX{display:none}}.ContactItem-mobile-2d1VX .ContactItem-upperWrapper-eWdIE{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.ContactItem-contactItem-2h-tq:first-child{border-top:none}.ContactItem-contactItem-2h-tq:last-child{border-bottom:1px solid #dee4ea}.ContactItem-title-11b5Q{color:#33414e;font-family:LabGrotesque-Medium,sans-serif;line-height:26px;text-decoration:none}.ContactItem-textWrapper-2EvLS{-ms-flex-positive:1;flex-grow:1;margin-right:16px}.ContactItem-icon-2S2fD{color:#ea8f12;height:32px;margin-right:10px}@media (min-width:541px){.ContactItem-icon-2S2fD{height:26px;margin-right:0}}.ContactItem-preImageWrapper-3NVIC{-ms-flex:0 0 38px;flex:0 0 38px;font-size:32px;margin-right:8px;text-align:right}@media (min-width:541px){.ContactItem-preImageWrapper-3NVIC{font-size:26px;margin-right:16px;text-align:center}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"contactItem": "ContactItem-contactItem-2h-tq",
	"desktop": "ContactItem-desktop-3Bogz",
	"mobile": "ContactItem-mobile-2d1VX",
	"upperWrapper": "ContactItem-upperWrapper-eWdIE",
	"title": "ContactItem-title-11b5Q",
	"textWrapper": "ContactItem-textWrapper-2EvLS",
	"icon": "ContactItem-icon-2S2fD",
	"preImageWrapper": "ContactItem-preImageWrapper-3NVIC"
};
module.exports = exports;
