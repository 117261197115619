/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
// eslint-disable-next-line css-modules/no-unused-class
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './FooterContent.css';

const FooterContent = ({ children }) => (
  <div className={s.footerContent}>
    <div className={boostrapGrid.container}>{children}</div>
  </div>
);

export default withStyles(boostrapGrid, s)(FooterContent);
