const SORT_ORDER = {
  italic: 0,
  underline: 0,
  strike: 1,
  subscript: 2,
  superscript: 3,
  bold: 4,
  internal_link: 9,
  inline_link: 10,
};

const sortAnnotations = (a, b) => SORT_ORDER[b.name] - SORT_ORDER[a.name];

export default sortAnnotations;
