import React from 'react';
import classnames from 'classnames';
import Typography from 'components/atoms/Typography';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import s from './FormSuccess.css';

const FormSuccess = ({ classes, headline, text, className, ...other }) => {
  const classNames = classnames(s.formSuccess, classes, {
    [className]: !!className,
  });
  return (
    <div className={classNames} {...other}>
      <div className={s.headline}>
        <FontAwesomeIcon icon={faCheckCircle} className={s.infoCircle} />
        {headline}
      </div>
      <Typography variant="paragraphSmall" classes={s.text}>
        {text}
      </Typography>
    </div>
  );
};

export default withStyles(s)(FormSuccess);
