import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import pickFrom from 'utils/pickFrom';
import reducer from 'utils/reduceTeaserOptions';
import extractImages from 'utils/extractImages';
import _replace from 'lodash/fp/replace';

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, (data, hostname) => {
  if (data) {
    try {
      const replaceHostname = _replace(hostname, '');
      const profileObj = pickFrom(data, 'context.deskedContent.topImageQuote[0]');
      const profileImage = pickFrom(profileObj, 'content.image');
      const profileLink = pickFrom(profileObj, 'content.link[0].content.href');
      const profileTeaserOptionsRaw = pickFrom(profileObj, 'teaserOptions');
      const profileTeaserOptions = reducer(profileTeaserOptionsRaw);
      return {
        quote: pickFrom(profileObj, 'content.fields.quote'),
        images: profileImage && extractImages(profileImage),
        profileLink: replaceHostname(profileLink),
        linkText: pickFrom(profileObj, 'content.fields.linkText'),
        name: profileTeaserOptions?.useSummaryFields
          ? profileTeaserOptions?.title
          : pickFrom(profileObj, 'content.title'),
        assignment: profileTeaserOptions?.useSummaryFields
          ? profileTeaserOptions?.assignment
          : pickFrom(profileObj, 'content.fields.assignment'),
      };
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
