/* eslint-disable camelcase */

import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import Link from '../../../../../containers/atoms/LinkContainer';

import s from './Tabs.css';

const Tabs = ({ currentPath }) => {
  const tabs = [
    {
      to: '/kalendarium/inspelade-webbinarier/',
      selected: currentPath === '/kalendarium/inspelade-webbinarier/',
      label: 'Inspelade webbinarier',
    },
    { to: '/kalendarium/', selected: currentPath === '/kalendarium/', label: 'Kommande evenemang' },
  ];

  return (
    <div className={s.container}>
      {tabs.map(tab => (
        <Link key={tab.to} className={classnames(s.tab, tab.selected && s.selected)} to={tab.to}>
          {tab.label}
        </Link>
      ))}
    </div>
  );
};

export default withStyles(s)(Tabs);
