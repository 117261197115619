// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Timelines-container-3B47m{-ms-flex-pack:center;-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;justify-content:center}.Timelines-wrapper-10EYN{padding:2rem}.Timelines-sessions-2tb-i{border-radius:12px;margin-top:2rem;padding:1.5rem;position:relative}.Timelines-linesToLeft-3F80R,ul .Timelines-sessions-2tb-i{list-style:none;padding:0}.Timelines-lines-3bOem{border-left:3px solid #ea8f12;list-style-type:none;margin-left:10px;padding-left:20px;position:relative}.Timelines-lines-3bOem:last-child{border:0;left:3px;padding-bottom:0}.Timelines-lines-3bOem:before{content:attr(righttext);font-family:LabGrotesque-Bold,sans-serif;font-size:20px;margin-right:.5rem;padding-right:16px;position:absolute;right:100%;top:-6px}.Timelines-lines-3bOem:after{background:#ea8f12;border:1px solid #ea8f12;border-radius:50%;content:\"\";height:13px;left:-8px;position:absolute;top:0;width:13px}.Timelines-darkMode-373g3{color:#fff}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"container": "Timelines-container-3B47m",
	"wrapper": "Timelines-wrapper-10EYN",
	"sessions": "Timelines-sessions-2tb-i",
	"linesToLeft": "Timelines-linesToLeft-3F80R",
	"lines": "Timelines-lines-3bOem",
	"darkMode": "Timelines-darkMode-373g3"
};
module.exports = exports;
