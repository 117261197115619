const getRoot = store => store.location;
const getPathname = store => getRoot(store).pathname;
const getHostname = store => getRoot(store).hostname;
const getScrollPosition = store => getRoot(store).scrollPosition;
const getDataPathPrefix = store => getRoot(store).dataPathPrefix;

const getDataPath = store => `${getHostname(store)}${getDataPathPrefix(store)}${getPathname(store)}`;
const getFullPath = store => `${getHostname(store)}${getPathname(store)}`;

export { getPathname, getHostname, getScrollPosition, getDataPath, getFullPath };
