import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import getImages from '../../../../utils/getImages';
import defaultArticleImage from './defaultArticleImage.jpg';
import s from './VariantMatrix.css';
import clientifyUrl from '../../../../utils/clientifyUrl';

const Item = ({ article, darkMode }) => {
  const contentType = article?.relation?.contentType;
  const imageSrc = article?.relation?.teaserImage?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full;
  const imageAlt = article?.relation?.image?.alttext;
  const sizes = getImages('LANDSCAPE');

  const isLink = contentType === 'link';

  return (
    <Link
      className={classnames(s.article, {
        [s.darkMode]: darkMode,
      })}
      to={clientifyUrl(article.href)}
      target={isLink ? '_blank' : undefined}
    >
      <img src={sizes.toSrc(imageSrc, 'M') || defaultArticleImage} alt={imageAlt} />
      <Typography darkMode={darkMode} forceVariant variant="paragraph" component="h3" className={s.headline}>
        {article.headline}
        {isLink && <FontAwesomeIcon aria-hidden="true" icon={faExternalLinkAlt} className={s.externalIcon} />}
      </Typography>
    </Link>
  );
};

const VariantMatrix = ({ classes, articles, title, darkMode }) => {
  const filteredArticles = articles.filter(art => art !== undefined);

  if (filteredArticles.length === 0) {
    return null;
  }

  return (
    <div className={classnames(classes, s.variantMatrix)}>
      <Typography darkMode={darkMode} forceVariant variant="caption" component="h2">
        {title.toUpperCase()}
      </Typography>
      <div className={s.articlesWrapper}>
        {filteredArticles.map(article => (
          <Item key={article.id} article={article} darkMode={darkMode} />
        ))}
      </div>
    </div>
  );
};

export default withStyles(s)(VariantMatrix);
