import React from 'react';
import classnames from 'classnames';
import Typography from 'components/atoms/Typography';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SoundBlock.css';

const SoundBlock = ({ classes, uri, caption, ...other }) => {
  const classNames = classnames(s.soundBlock, classes);

  /* const settings = {
    width: '560',
    height: '349',
    frameBorder: '0',
    webkitallowfullscreen: 'true',
    mozallowfullscreen: 'true',
  }; */

  return (
    <div className={classNames} {...other}>
      <iframe
        title={caption}
        width="100%"
        height="166"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src={`https://w.soundcloud.com/player/?url=${uri}&color=%23e6583f&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=true`}
      />
      {caption && (
        <div className={s.textWrapper}>
          <Typography variant="contentTypeDate">{caption}</Typography>
        </div>
      )}
    </div>
  );
};

export default withStyles(s)(SoundBlock);
