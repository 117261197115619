/* eslint-disable no-underscore-dangle */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import s from './GridParent.css';
import getCssSizesVars from '../../../utils/getCssSizesVars';

const GridParent = ({ children, as: As = 'div', className, options }) => {
  const gridTemplateColumnsCssVars = getCssSizesVars({
    object: options,
    cssProperty: 'gridTemplateColumns',
    cssVarPrefix: '--grid-template-columns-',
    options: { useGenericValueAtBreakpoint: 'md' },
  });

  const gridTemplateRowsCssVars = getCssSizesVars({
    object: options,
    cssProperty: 'gridTemplateRows',
    cssVarPrefix: '--grid-template-rows-',
    options: { useGenericValueAtBreakpoint: 'md' },
  });

  const gridRowGapCssVars = getCssSizesVars({
    object: options,
    cssProperty: 'gridRowGap',
    cssVarPrefix: '--grid-row-gap-',
    options: { useGenericValueAtBreakpoint: 'xs' },
  });

  const gridColumnGapCssVars = getCssSizesVars({
    object: options,
    cssProperty: 'gridColumnGap',
    cssVarPrefix: '--grid-column-gap-',
    options: { useGenericValueAtBreakpoint: 'xs' },
  });

  return (
    <As
      style={{
        ...gridTemplateColumnsCssVars,
        ...gridTemplateRowsCssVars,
        ...gridRowGapCssVars,
        ...gridColumnGapCssVars,
        ...(options?.justifyItems ? { justifyItems: options.justifyItems } : {}),
        ...(options?.alignItems ? { alignItems: options.alignItems } : {}),
        ...(options?.justifyContent ? { justifyContent: options.justifyContent } : {}),
        ...(options?.alignContent ? { alignContent: options.alignContent } : {}),
      }}
      className={classnames(s.gridParent, {
        [className]: !!className,
      })}
    >
      {children}
    </As>
  );
};

export default withStyles(s)(GridParent);
