// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeaderLogo-headerLogo-214nt{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;height:100%}.HeaderLogo-headerLogo-214nt .HeaderLogo-logoMobile-3sdvi{width:220px}@media (min-width:1169px){.HeaderLogo-headerLogo-214nt .HeaderLogo-logoMobile-3sdvi{display:none}}.HeaderLogo-headerLogo-214nt .HeaderLogo-logoDesktop-2Y1r9{display:none;height:calc(var(--header-height) - var(--header-padding-y)*2 - 20px)}@media (min-width:1169px){.HeaderLogo-headerLogo-214nt .HeaderLogo-logoDesktop-2Y1r9{display:block}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"headerLogo": "HeaderLogo-headerLogo-214nt",
	"logoMobile": "HeaderLogo-logoMobile-3sdvi",
	"logoDesktop": "HeaderLogo-logoDesktop-2Y1r9"
};
module.exports = exports;
