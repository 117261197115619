// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header-header-1VL0s{-ms-flex-pack:justify;background:#b12f26;color:#fff;justify-content:space-between;padding:10px 20px}.Header-header-1VL0s,.Header-header-1VL0s .Header-headerRight--jrSp,.Header-header-1VL0s .Header-headerRight--jrSp .Header-nameContainer-3HsGD{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.Header-header-1VL0s .Header-headerRight--jrSp .Header-nameContainer-3HsGD{margin-left:20px}.Header-header-1VL0s .Header-headerRight--jrSp .Header-nameContainer-3HsGD .Header-name-3yuj1{margin-left:10px}.Header-header-1VL0s .Header-headerRight--jrSp .Header-iconWrapper-fr7pI{-ms-flex-pack:center;-ms-flex-align:center;align-items:center;background-color:#dee4ea;border-radius:50%;display:-ms-flexbox;display:flex;height:40px;justify-content:center;width:40px}.Header-header-1VL0s .Header-headerRight--jrSp .Header-closeIcon-1P4BZ{margin-left:20px}.Header-header-1VL0s .Header-headerRight--jrSp .Header-closeIcon-1P4BZ:hover{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"header": "Header-header-1VL0s",
	"headerRight": "Header-headerRight--jrSp",
	"nameContainer": "Header-nameContainer-3HsGD",
	"name": "Header-name-3yuj1",
	"iconWrapper": "Header-iconWrapper-fr7pI",
	"closeIcon": "Header-closeIcon-1P4BZ"
};
module.exports = exports;
