import { connect } from 'react-redux';
import WorkPage from 'components/pages/WorkPage';
import getWorkPageData from 'selectors/getWorkPageData';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getQuoteSlideshowData from 'selectors/getQuoteSlideshowData';
import getKeyFiguresData from 'selectors/getKeyFiguresData';

const mapStateToProps = state => {
  return {
    pageData: getWorkPageData(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
    quoteSlideshowData: getQuoteSlideshowData(state),
    keyFiguresData: getKeyFiguresData(state),
  };
};

const mapDispatchToProps = undefined;

const WorkPageContainer = connect(mapStateToProps, mapDispatchToProps)(WorkPage);

export default WorkPageContainer;
