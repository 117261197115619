import { connect } from 'react-redux';
import PressRoomPage from 'components/pages/PressRoomPage';
import getUpcomingEvents from 'selectors/getUpcomingEvents';
import getPressImageProfiles from 'selectors/getPressImageProfiles';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getLatestArticles from 'selectors/getLatestArticles';
import getLatestReports from 'selectors/getLatestReports';
import getLatestRemisses from 'selectors/getLatestRemisses';

const mapStateToProps = state => {
  return {
    upcomingEvents: getUpcomingEvents(state),
    pressImageProfiles: getPressImageProfiles(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
    pressReleases: getLatestArticles(state),
    latestReports: getLatestReports(state),
    latestRemisses: getLatestRemisses(state),
  };
};

const mapDispatchToProps = undefined;

const PressRoomPageContainer = connect(mapStateToProps, mapDispatchToProps)(PressRoomPage);

export default PressRoomPageContainer;
