import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import reducer from 'utils/reduceTeaserOptions';
import extractImages from 'utils/extractImages';
import pickFrom from 'utils/pickFrom';
import clientifyUrl from '../utils/clientifyUrl';

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      const contactPersonsRaw =
        data?.context?.contactPersons ||
        data?.context?.deskedContent?.contactPersons ||
        data?.tagInfo?.[0].contactPersons;
      return contactPersonsRaw.map(obj => {
        const href = pickFrom(obj, 'content.href');
        const title = pickFrom(obj, 'content.title');
        const profileImage = pickFrom(obj, 'content.profileImage');
        const teaserOptionsRaw = pickFrom(obj, 'teaserOptions');
        let teaserOptions;

        try {
          teaserOptions = reducer(teaserOptionsRaw);
        } catch (err) {
          console.error(err);
          return undefined;
        }

        return {
          id: pickFrom(obj, 'content.id'),
          name: title,
          href: clientifyUrl(href),
          images: profileImage && extractImages(profileImage),
          assignment: teaserOptions?.useSummaryFields
            ? teaserOptions?.assignment
            : pickFrom(obj, 'content.fields.assignment'),
        };
      });
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
