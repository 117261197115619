import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import InputField from 'components/atoms/InputField';
import ModifyButton from 'components/atoms/ModifyButton';
import s from './OrderCount.css';

const OrderCount = ({
  classes,
  value,
  onChange,
  onIncrement,
  onDecrement,
  id,
  label,
  register,
  ...other
}) => {
  const classNames = classnames(s.textField, classes);
  return (
    <div className={classNames} {...other}>
      <Typography
        id={`${id}-label`}
        htmlFor={id}
        variant="caption"
        component="label"
        classes={s.label}
        style={{ marginBottom: 6 }}
      >
        {label}
      </Typography>
      <div className={s.inputWrapper}>
        <ModifyButton borderLeft classes={s.modifyButton} onClick={onDecrement}>
          -
        </ModifyButton>
        <InputField
          id={id}
          type="number"
          name={id}
          round={false}
          style={{ width: 50, textAlign: 'center', paddingLeft: 0, paddingRight: 0 }}
          value={Number(value).toString()}
          register={register}
          onChange={onChange}
        />
        <ModifyButton borderRight classes={s.modifyButton} onClick={onIncrement}>
          +
        </ModifyButton>
      </div>
    </div>
  );
};

export default withStyles(s)(OrderCount);
