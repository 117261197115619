import { connect } from 'react-redux';
import Application from 'components/Application';
import { selectors as cookSelectors } from 'ducks/cook';
import getDarkMode from 'selectors/getDarkMode';
import getHeroMode from 'selectors/getHeroMode';
import getMenuTextColor from 'selectors/getMenuTextColor';
import { setTranslation } from '../ducks/app/actions';
import { getPathname } from '../ducks/location/selectors';
import getTranslation from '../selectors/getTranslation';

const mapStateToProps = state => ({
  isFetching: cookSelectors.isFetching(state),
  darkMode: getDarkMode(state) && true,
  heroMode: getHeroMode(state) && true,
  translation: getTranslation(state),
  headTextColor: getMenuTextColor(state),
  pathname: getPathname(state),
});

const mapDispatchToProps = dispatch => {
  return {
    setTranslation: setTo => dispatch(setTranslation(setTo)),
  };
};

const ApplicationContainer = connect(mapStateToProps, mapDispatchToProps)(Application);

export default ApplicationContainer;
