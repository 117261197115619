import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import getImages from 'utils/getImages';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import AlignmentDirection from 'components/atoms/AlignmentDirection';
import s from './StorylineBoxBackground.css';

const COLOR_MAP = {
  orange: '234, 143, 18',
  red: '177, 47, 38',
  darkBlue: '51, 65, 78',
  steel: '98, 113, 127',
  creamyWhite: '250, 249, 247',
  lightBlue: '239, 242, 245',
  white: '255, 255, 255',
  DEFAULT: 'transparent',
};

const IMAGE_SIZE_MAP = {
  xs: 'M',
  sm: 'M',
  md: 'L',
  lg: 'XL',
  xl: 'XL',
};

const getBgStyle = ({ src, srcTyp, backgroundTyp, bgOpacity, imageSize }) => {
  const opacity = COLOR_MAP[backgroundTyp] ? bgOpacity : 1;
  const bgColor = COLOR_MAP[backgroundTyp]
    ? `rgba(${COLOR_MAP[backgroundTyp]}, ${opacity})`
    : COLOR_MAP.DEFAULT;

  const style = {};

  if (srcTyp === 'picture') {
    if (COLOR_MAP[backgroundTyp] && bgOpacity < 1) {
      style.boxShadow = `inset 0 0 0 99999px ${bgColor}`;
    }
    style.backgroundImage = `url(${getImages('FREEFORM').toSrc(src, imageSize)})`;
    style.backgroundSize = 'cover';
    style.backgroundRepeat = 'no-repeat';
  } else if (srcTyp === 'video') {
    style.backgroundColor = bgColor;
    delete style.boxShadow;
  } else {
    style.backgroundColor = bgColor;
  }

  return style;
};

const StorylineBoxBackground = ({
  children,
  backgroundTyp = '',
  src,
  srcTyp,
  horizontalAlignment = 'center',
  verticalAlignment = 'center',
  bgOpacity,
}) => {
  const breakpoint = useBreakpoint();
  const imageSize = IMAGE_SIZE_MAP[breakpoint];

  const style = getBgStyle({ src, srcTyp, backgroundTyp, bgOpacity, imageSize });

  return (
    <div className={s.storylineBoxBackground}>
      <div className={s.storylineBox}>
        <div className={s.boxBackground} style={style}>
          {srcTyp === 'video' && (
            <>
              {bgOpacity < 1 && COLOR_MAP[backgroundTyp] && (
                <div
                  className={s.videoColorOverlay}
                  style={{
                    backgroundColor: `rgb(${COLOR_MAP[backgroundTyp]})`,
                    opacity: bgOpacity,
                  }}
                />
              )}
              <video autoPlay muted loop>
                <source src={src} type="video/mp4" className={s.video} />
              </video>
            </>
          )}
          <AlignmentDirection
            style={{ position: 'relative', zIndex: 2 }}
            horizontalAlignment={horizontalAlignment}
            verticalAlignment={verticalAlignment}
          >
            {children}
          </AlignmentDirection>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(StorylineBoxBackground);
