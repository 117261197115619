import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import getImages from 'utils/getImages';
import getArticleAuthors from './getArticleAuthors';

export default createSelector(cookSelectors.getData, getArticleAuthors, (data, articleAuthors) => {
  if (!data) {
    return undefined;
  }

  // First use "Anpassad toppbild" if available, otherwise use the authors "Bild i blogg"
  const prioImage1 = data.context?.heroImage?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full;
  const prioImage2 =
    data.context?.internalAuthors?.[0]?.content?.blogImage?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full;
  const prioImage3 = '/default-blog-post-bg.jpg';

  // "Anpassad toppbild"
  if (prioImage1) {
    return {
      url: getImages('FREEFORM')?.toSrc(prioImage1, 'XL'),
      fullWidthBg: true,
    };
  }

  // Profile image "Bild i blogg"
  if (prioImage2 && articleAuthors?.length <= 1) {
    return {
      url: getImages('FREEFORM')?.toSrc(prioImage2, 'XL'),
      fullWidthBg: false,
    };
  }

  // General image without author in it (only the background)
  return {
    url: getImages('FREEFORM')?.toSrc(prioImage3, 'XL'),
    fullWidthBg: false,
  };
});
