/* eslint-disable css-modules/no-unused-class */
/* eslint-disable css-modules/no-undef-class */

import React, { useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentTypeDate from 'components/atoms/ContentTypeDate';
import ShareBarContainer from 'containers/molecules/ShareBarContainer';
import Typography from 'components/atoms/Typography';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import DarkMode from 'components/templates/DarkMode';
import CenteredRow from 'components/templates/Grid/CenteredRow';
import pickFrom from 'utils/pickFrom';
import renderStorylineElement from 'utils/renderStorylineElement';
import Tag from 'components/atoms/Tag';
import Author from 'components/molecules/Author';
import getTypeIcon from 'utils/getTypeIcon';
// eslint-disable-next-line css-modules/no-unused-class
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './PetitionPage.css';
import Translate from '../../../containers/atoms/TranslateContainer';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';

const OtherFiles = ({ files }) => (
  <div>
    <Typography variant="caption" darkMode classes={s.writtenBy}>
      <Translate>PETITION_PAGE.MORE_FILES</Translate>
    </Typography>
    {files.map(file => {
      const binary = pickFrom(file, 'content.binary');
      const titel = pickFrom(file, 'title');
      const primaryLink = pickFrom(file, 'primaryLink');
      return (
        <div className={s.otherFilesItem} key={primaryLink + titel}>
          <a href={primaryLink} target="_blank" rel="noopener noreferrer" className={s.otherFileName}>
            {titel}
          </a>
          <div className={s.otherFilesType}>
            <FontAwesomeIcon icon={getTypeIcon(binary?.extension)} className={s.fileIconDownloadSmall} />
            <Typography variant="contentTypeDate" darkMode component="span">
              {binary?.extension?.toUpperCase()}
            </Typography>
          </div>
        </div>
      );
    })}
  </div>
);

const InfoItem = ({ headline, value }) => (
  <div className={s.infoItem}>
    <div className={s.infoTextHeadline}>{headline}</div>
    <div className={s.infoTextValue}>{value}</div>
  </div>
);

const PetitionPage = ({
  classes,
  groupedStorylineTree,
  darkMode = true,
  darkModeType,
  tags,
  publishedDate,
  authors,
  pageTitle,
  pageLabel,
  relatedItems,
  metadata,
  fields,
}) => {
  const classNames = classnames(s.petitionPage, darkMode && s.darkMode, classes);
  const file = pickFrom(relatedItems, '[0]');
  const binary = pickFrom(file, 'content.binary');
  const [visible, setVisible] = useState(true);
  const newMetadata = {
    ...metadata,
    headline: pageTitle,
    description: fields?.body,
  };
  const openLink = (event, url) => {
    if (window) {
      window.open(url, '_blank');
      event.preventDefault();
    }
  };

  const { headGroup, contentGroup } = groupedStorylineTree;

  return (
    <div className={classNames}>
      <OGShareTags metadata={newMetadata} />
      <ScrollTrigger onEnter={() => setVisible(true)} onExit={() => setVisible(false)}>
        <DarkMode darkMode={darkMode} darkModeType={darkModeType}>
          <div className={classnames(boostrapGrid.container)}>
            <div className={classnames(s.topRowWrapper, boostrapGrid.row)}>
              <div
                className={classnames(
                  boostrapGrid['col-lg-13'],
                  boostrapGrid['offset-lg-2'],
                  boostrapGrid['col-md-18'],
                  boostrapGrid['offset-md-3'],
                  boostrapGrid['col-sm-20'],
                  boostrapGrid['offset-sm-2']
                )}
              >
                <div className={s.leadWrapper}>
                  <ContentTypeDate contentType={pageLabel} date={publishedDate} darkMode={darkMode} />
                  {headGroup && headGroup.map(el => renderStorylineElement(el, darkMode))}
                  {binary && (
                    <ButtonPrimaryAlt classes={s.downloadButton} onClick={e => openLink(e, file.primaryLink)}>
                      <FontAwesomeIcon
                        icon={getTypeIcon(binary.extension, true)}
                        className={s.fileIconDownload}
                      />
                      <Translate>PETITION_PAGE.DOWNLOAD</Translate>
                    </ButtonPrimaryAlt>
                  )}
                </div>
                {relatedItems?.length > 1 && <OtherFiles files={relatedItems?.slice(1)} />}
              </div>
              <div
                className={classnames(
                  boostrapGrid['col-lg-6'],
                  boostrapGrid['offset-lg-0'],
                  boostrapGrid['col-md-18'],
                  boostrapGrid['offset-md-3'],
                  boostrapGrid['col-sm-20'],
                  boostrapGrid['offset-sm-2']
                )}
              >
                <div className={s.infoWrapper}>
                  {fields?.recipient && (
                    <InfoItem
                      headline={<Translate>PETITION_PAGE.RECIEVER</Translate>}
                      value={fields.recipient}
                    />
                  )}
                  {fields?.commonArticle && (
                    <InfoItem
                      headline={<Translate>PETITION_PAGE.JOINT_LETTER</Translate>}
                      value={fields.commonArticle}
                    />
                  )}
                  {fields?.externalRegistrationNumber && (
                    <InfoItem
                      headline={<Translate>PETITION_PAGE.EXTERNAL_DIARY_NUMBER</Translate>}
                      value={fields.externalRegistrationNumber}
                    />
                  )}
                  {fields?.snRegistrationNumber && (
                    <InfoItem
                      headline={<Translate>PETITION_PAGE.SN_DIARY_NUMBER</Translate>}
                      value={fields.snRegistrationNumber}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </DarkMode>
        <div className={classnames(boostrapGrid.container)}>
          <div className={s.article_container}>
            <CenteredRow>
              <div className={classnames(visible === true ? s.fadeIn : s.fadeOut)}>
                <ShareBarContainer />
              </div>
              <div className={s.stickyContainer}>
                <ShareBarContainer hideMobile />
                {contentGroup && contentGroup.map(el => renderStorylineElement(el))}
                <div className={s.tagWrapper}>
                  {tags?.map(tag => {
                    const href = `/${tag?.tagUri}`;
                    return <Tag href={href}>{tag?.name}</Tag>;
                  })}
                </div>
                {authors && (
                  <div className={s.authorsWrapper}>
                    <Author authors={authors} title={<Translate>PETITION_PAGE.CONTACT_PERSONS</Translate>} />
                  </div>
                )}
              </div>
            </CenteredRow>
          </div>
        </div>
      </ScrollTrigger>
    </div>
  );
};

export default withStyles(boostrapGrid, s)(PetitionPage);
