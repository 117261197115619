import { connect } from 'react-redux';
import RegionOfficePage from 'components/pages/RegionOfficePage';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getContactPersons from 'selectors/getContactPersons';
import getSelectedContent from 'selectors/getSelectedContent';
import getQuoteBoxData from 'selectors/getQuoteBoxData';
import getFocusAreaListItems from 'selectors/getFocusAreaListItems';
import getRegionOfficeFields from 'selectors/getRegionOfficeFields';
import getQuoteSlideshowData from 'selectors/getQuoteSlideshowData';
import getExpertAreaData from 'selectors/getExpertAreaData';
import getLatestArticles from 'selectors/getLatestArticles';
import getPageTitle from 'selectors/getPageTitle';
import getUpcomingEvents from 'selectors/getUpcomingEvents';
import getExpertOpinionHeadline from 'selectors/getExpertOpinionHeadline';
import getLatestWebinars from '../../selectors/getLatestWebinars';

const mapStateToProps = state => {
  const selectedContent = getSelectedContent(state);
  return {
    pageTitle: getPageTitle(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
    contactPersons: getContactPersons(state),
    firstSelectedContent: selectedContent && selectedContent.length > 0 && selectedContent[0],
    restSelectedContent: selectedContent && selectedContent.length > 0 && selectedContent.slice(1),
    quoteBoxData: getQuoteBoxData(state),
    expertAreaData: getExpertAreaData(state),
    regionOfficeFields: getRegionOfficeFields(state),
    focusAreaListItems: getFocusAreaListItems(state),
    latestArticles: getLatestArticles(state),
    upcomingEvents: getUpcomingEvents(state),
    latestWebinars: getLatestWebinars(state),
    quoteSlideshowData: getQuoteSlideshowData(state),
    expertOpinionHeadline: getExpertOpinionHeadline(state),
  };
};

const mapDispatchToProps = undefined;

const RegionOfficePageContainer = connect(mapStateToProps, mapDispatchToProps)(RegionOfficePage);

export default RegionOfficePageContainer;
