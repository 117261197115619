/* eslint-disable dot-notation */
import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';

export default createSelector(cookSelectors.getData, data => {
  try {
    const { context } = data;
    const { fields } = context;
    const { ogDescription } = fields;
    return ogDescription;
  } catch (error) {
    return undefined;
  }
});
