import React, { useEffect, useState } from 'react';
/* eslint-disable css-modules/no-undef-class */
import withStyles from 'isomorphic-style-loader/withStyles';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import ButtonPrimary from 'components/atoms/buttons/ButtonPrimary';
import Typography from 'components/atoms/Typography';
import getImages from 'utils/getImages';
import s from './NewsletterItem.css';
import useTranslate from '../../../../../utils/hooks/useTranslate';
import { isValidEmail } from '../../../../../utils/emailUtils';

const NewsletterItem = ({
  imageSrc,
  imageAlt,
  headline,
  subHeadline,
  onSubscribeOrUnsubscribe,
  children,
  posting,
  selectedEmail,
  isSubscribed,
  listRecipientId,
  isAuthenticated,
}) => {
  const t = useTranslate();
  const sizes = getImages('SQUARE');
  const [isValidEmailFormat, setIsValidEmailFormat] = useState(false);

  useEffect(() => {
    setIsValidEmailFormat(isValidEmail(selectedEmail));
  }, [selectedEmail]);

  return (
    <div className={s.newsletterItem}>
      <div className={s.headerWrapper}>
        {imageSrc && (
          <img src={sizes.toSrc(imageSrc, 'XS')} width="52" height="52" alt={imageAlt} className={s.logo} />
        )}
        <div>
          <Typography forceVariant variant="h3" classes={s.headline}>
            {headline}
          </Typography>
          <Typography variant="paragraphSmall" classes={s.subHeadline}>
            {subHeadline}
          </Typography>
        </div>
        {isSubscribed ? (
          <ButtonPrimary
            disabled={posting || !isValidEmailFormat}
            loading={posting}
            classes={s.subscribeButton}
            onClick={() => onSubscribeOrUnsubscribe(isSubscribed, listRecipientId)}
          >
            {t('SUBSCRIBE.BUTTON_LABEL_UNSUBSCRIBE')}
          </ButtonPrimary>
        ) : (
          <ButtonPrimaryAlt
            title={
              !isAuthenticated && (posting || !isValidEmailFormat)
                ? 'Skriv in en e-postadress i början av sidan för att kunna prenumerera'
                : null
            }
            disabled={posting || !isValidEmailFormat}
            loading={posting}
            classes={s.subscribeButton}
            onClick={() => onSubscribeOrUnsubscribe(isSubscribed)}
          >
            {t('SUBSCRIBE.BUTTON_LABEL_SUBSCRIBE')}
          </ButtonPrimaryAlt>
        )}
      </div>
      <div>{children}</div>
      {isSubscribed ? (
        <ButtonPrimary
          disabled={posting || !isValidEmailFormat}
          loading={posting}
          classes={s.subscribeButtonMobile}
          onClick={() => onSubscribeOrUnsubscribe(isSubscribed, listRecipientId)}
        >
          {t('SUBSCRIBE.BUTTON_LABEL_UNSUBSCRIBE')}
        </ButtonPrimary>
      ) : (
        <ButtonPrimaryAlt
          disabled={posting || !isValidEmailFormat}
          loading={posting}
          classes={s.subscribeButtonMobile}
          onClick={() => onSubscribeOrUnsubscribe(isSubscribed)}
        >
          {t('SUBSCRIBE.BUTTON_LABEL_SUBSCRIBE')}
        </ButtonPrimaryAlt>
      )}
    </div>
  );
};

export default withStyles(s)(NewsletterItem);
