/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import Typography from 'components/atoms/Typography';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import renderFactsStorylineElement from 'utils/renderFactsStorylineElement';
import getImages from 'utils/getImages';
import generateSizes from 'utils/generateSizes';
import { useSelector } from 'react-redux';
import getRootGroupType from '../../../selectors/getRootGroupType';
import s from './FactsBox.css';

const FactsBox = ({ classes, headline, imageSrc, imageAlt, storyline }) => {
  const { type: rootGroupType } = useSelector(getRootGroupType);

  const sizes = getImages('SQUARE');
  const imageSizes = generateSizes({ default: '123px' });
  const sizesMobile = getImages('LANDSCAPE');
  const imageSizesMobile = generateSizes({ default: 'calc(100vw - 48' });
  return (
    <div
      className={classnames(classes, s.factsBox, {
        [s.factsBoxNoImage]: !imageSrc,
        [s.noExtendedMargins]: rootGroupType === 'flexibleSection',
      })}
    >
      <div className={s.factsBoxContent}>
        {imageSrc && (
          <ResponsiveImage
            src={sizesMobile.toSrc(imageSrc, 'S')}
            srcSet={sizesMobile.toSrcSet(imageSrc)}
            sizes={imageSizesMobile}
            classes={s.factsBoxImageMobile}
            alt={imageAlt}
          />
        )}

        <div className={s.factsBoxFlexContent}>
          <div className={s.factsBoxText}>
            {imageSrc ? (
              <Typography variant="factsHeadline" component="h2" classes={s.name}>
                {headline}
              </Typography>
            ) : (
              <div className={s.factsHeadlineNoImageContainer}>
                <Typography variant="factsHeadline" component="h2" classes={s.factsHeadlineNoImage}>
                  {headline}
                </Typography>
              </div>
            )}
            <div className={classnames(s.factsBoxTextContainer)}>
              {storyline &&
                storyline.map((el, idx) =>
                  renderFactsStorylineElement(el, idx !== 0, idx !== storyline.length - 1)
                )}
            </div>
          </div>
          {imageSrc && (
            <div className={s.factsBoxImageWrapper}>
              <ResponsiveImage
                src={sizes.toSrc(imageSrc, 'XS')}
                srcSet={sizes.toSrcSet(imageSrc)}
                sizes={imageSizes}
                classes={s.factsBoxImage}
                alt={imageAlt}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(FactsBox);
