import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import extractImages from 'utils/extractImages';
import clientifyUrl from '../utils/clientifyUrl';

const parseStories = stories => {
  return stories?.map(story => {
    const teaserImage = extractImages(story?.teaserImage);
    const teaserImage2 = extractImages(story?.teaserImage2);
    return {
      title: story.title,
      id: story.id,
      image: teaserImage || teaserImage2,
      href: clientifyUrl(story?.href),
    };
  });
};

export default createSelector(cookSelectors.getData, data => {
  try {
    const storiesWithSharedTags =
      data?.storiesWithSharedTags && parseStories(data.storiesWithSharedTags)?.slice(0, 6);
    let latestStories = data?.latestStories && parseStories(data?.latestStories);
    latestStories = latestStories.filter(story => !storiesWithSharedTags.find(tag => tag.id === story.id));
    const alsoReadItems = [...storiesWithSharedTags, ...latestStories];
    return alsoReadItems.slice(0, 6);
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
