// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DarkMode-topWrapper-2AVgV{position:relative}.DarkMode-topWrapper-2AVgV.DarkMode-noOffset-18iGe{margin-bottom:32px}.DarkMode-darkModeBackground-1Jc3D{background-color:#33414e;height:calc(100% + 186px);position:absolute;top:-186px;width:100%}.DarkMode-darkModeBackground-1Jc3D.DarkMode-darkModeBackgroundGray-3yN5B{background-color:#62717f}@media (min-width:541px){.DarkMode-darkModeBackground-1Jc3D{height:calc(100% + 225px);top:-225px}}.DarkMode-darkModeBackground-1Jc3D.DarkMode-largeOffset-20doG{height:calc(100% + 242px)}.DarkMode-darkModeBackground-1Jc3D.DarkMode-xlargeOffset-W9IGt{height:calc(100% + 317px)}@media (min-width:541px){.DarkMode-darkModeBackground-1Jc3D.DarkMode-largeOffset-20doG{height:calc(100% + 266px)}.DarkMode-darkModeBackground-1Jc3D.DarkMode-xlargeOffset-W9IGt{height:calc(100% + 341px)}}.DarkMode-darkModeImage-3MnLC{background-position:50%;background-repeat:no-repeat;background-size:cover;opacity:.1}.DarkMode-darkModeImage-3MnLC,.DarkMode-darkModeLogo-1y8TT{height:100%;position:absolute;width:100%}.DarkMode-darkModeLogo-1y8TT{background:url(/snOutlineWings.svg);background-repeat:no-repeat;opacity:0}@media (min-width:541px){.DarkMode-darkModeLogo-1y8TT{background-position:bottom -97px right -80px;background-size:120%;opacity:.25}}@media (min-width:820px){.DarkMode-darkModeLogo-1y8TT{background-size:100%}}@media (min-width:1169px){.DarkMode-darkModeLogo-1y8TT{background-position:bottom -140px right -88px;background-size:90%}}@media (min-width:1600px){.DarkMode-darkModeLogo-1y8TT{background-position:bottom -80px right -88px;background-position-x:80%;background-size:auto}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"topWrapper": "DarkMode-topWrapper-2AVgV",
	"noOffset": "DarkMode-noOffset-18iGe",
	"darkModeBackground": "DarkMode-darkModeBackground-1Jc3D",
	"darkModeBackgroundGray": "DarkMode-darkModeBackgroundGray-3yN5B",
	"largeOffset": "DarkMode-largeOffset-20doG",
	"xlargeOffset": "DarkMode-xlargeOffset-W9IGt",
	"darkModeImage": "DarkMode-darkModeImage-3MnLC",
	"darkModeLogo": "DarkMode-darkModeLogo-1y8TT"
};
module.exports = exports;
