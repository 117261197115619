const mapStrUndefinedValuesToUndefined = obj => {
  if (!obj) {
    return undefined;
  }

  return Object.entries(obj)?.reduce(
    (acc, [currentKey, currentValue]) => ({
      ...acc,
      [currentKey]: currentValue === 'undefined' ? undefined : currentValue,
    }),
    {}
  );
};

export default mapStrUndefinedValuesToUndefined;
