// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BlogPostPage-blogPostPage-1c5hO{background:#fff}.BlogPostPage-blogPostPage-1c5hO .BlogPostPage-container-3Dafj{margin-top:440px}@media (min-width:820px){.BlogPostPage-blogPostPage-1c5hO .BlogPostPage-container-3Dafj{margin-top:380px}}.BlogPostPage-blogPostPage-1c5hO .BlogPostPage-container-3Dafj time{color:#62717f;display:block;margin-bottom:1rem}.BlogPostPage-blogPostPage-1c5hO .BlogPostPage-container-3Dafj .BlogPostPage-innerContainer-e4wKQ{min-height:21rem;position:relative}.BlogPostPage-blogPostPage-1c5hO .BlogPostPage-latestBlogPostsContainer-1SF5-{background:#eff2f5;padding:32px}.BlogPostPage-blogPostPage-1c5hO .BlogPostPage-latestBlogPostsContainer-1SF5- .BlogPostPage-viewAllPosts-3bIzt{margin:32px auto 0;width:-webkit-max-content;width:-moz-max-content;width:max-content}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"blogPostPage": "BlogPostPage-blogPostPage-1c5hO",
	"container": "BlogPostPage-container-3Dafj",
	"innerContainer": "BlogPostPage-innerContainer-e4wKQ",
	"latestBlogPostsContainer": "BlogPostPage-latestBlogPostsContainer-1SF5-",
	"viewAllPosts": "BlogPostPage-viewAllPosts-3bIzt"
};
module.exports = exports;
