import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import getImages from 'utils/getImages';
import pickFrom from 'utils/pickFrom';
import VCard from 'components/molecules/VCard';
import s from './ContactPersons.css';

const ContactPersons = ({ classes, persons = [], ...other }) => {
  const classNames = classnames(s.contactPersons, classes);
  return (
    <div className={classNames} {...other}>
      {persons?.length > 1 && <Typography variant="caption">KONTAKTPERSONER</Typography>}
      {persons?.length === 1 && <Typography variant="caption">KONTAKTPERSON</Typography>}
      <div className={s.personsWrapper}>
        {persons?.map(person => {
          const sizes = getImages('SQUARE');
          const imageSrc = pickFrom(person, 'images.images.BASE.LANDSCAPE.href_full');
          const imageAlt = pickFrom(person, 'images.altText');

          return (
            <VCard
              key={person?.id}
              imageSrc={imageSrc && sizes.toSrc(imageSrc, 'S')}
              imageAlt={imageAlt && imageAlt}
              name={person?.name}
              title={person?.assignment}
              href={person?.href}
              linkText={person?.name}
              classes={s.infoVcard}
            />
          );
        })}
      </div>
    </div>
  );
};

export default withStyles(s)(ContactPersons);
