import { connect } from 'react-redux';
import EventPage from 'components/pages/EventPage';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getPageTitle from 'selectors/getPageTitle';
import getTags from 'selectors/getTags';
import getEventPageData from 'selectors/getEventPageData';
import getResetFields from 'selectors/getResetFields';
import getMetdata from '../../selectors/metadata/getMetdata';

const mapStateToProps = state => {
  return {
    groupedStorylineTree: getGroupedStorylineTree(state),
    pageTitle: getPageTitle(state),
    tags: getTags(state),
    pageData: getEventPageData(state),
    metadata: getMetdata(state),
    actionMetadata: state?.cook?.actionMetadata,
    resetFields: getResetFields(state),
  };
};

const mapDispatchToProps = undefined;

const EventPageContainer = connect(mapStateToProps, mapDispatchToProps)(EventPage);

export default EventPageContainer;
