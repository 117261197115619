import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import clientifyUrl from '../utils/clientifyUrl';

export default createSelector(cookSelectors.getData, data => {
  if (!data) {
    return undefined;
  }

  return {
    prev: clientifyUrl(data.context?.blogPostBefore?.href),
    next: clientifyUrl(data.context?.blogPostAfter?.href),
  };
});
