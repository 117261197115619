import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import s from './ScrollToShowMore.css';

const ScrollToShowMore = ({ style }) => {
  return (
    <div className={s.container} style={style}>
      <FontAwesomeIcon icon={faArrowRight} style={{ height: 12, color: '#444' }} />
      <span className={s.text}>Scrolla för att visa mer</span>
    </div>
  );
};

export default withStyles(s)(ScrollToShowMore);
