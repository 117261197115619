import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faCalendarPlus,
  faCalendarCheck,
  faCalendarTimes,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import Translator from 'utils/translator';
import variants from './variants';
import translations from './translations.json';
import s from './Label.css';

const t = Translator(translations);

const getIcon = variant => {
  switch (variant) {
    case variants.WAS_LIVE:
      return faVideo;
    case variants.FULLY_BOOKED:
      return faCalendarCheck;
    case variants.NEW_DATE:
      return faCalendarPlus;
    case variants.CANCELED:
      return faCalendarTimes;
    default:
      return faInfoCircle;
  }
};

const Label = ({ classes, variant, ...other }) => {
  let red = false;
  let live = false;
  let dot = false;
  switch (variant) {
    case variants.LIVE:
      live = true;
      dot = true;
      break;
    case variants.ON_AIR:
      dot = true;
      red = true;
      break;
    case variants.FULLY_BOOKED:
    case variants.CANCELED:
      red = true;
      break;
    default:
      break;
  }
  const classNames = classnames(s.label, dot && s.dot, red && s.red, live && s.live, classes);
  return (
    <div className={classNames} {...other}>
      {dot ? (
        <div className={s.dot} />
      ) : (
        <FontAwesomeIcon icon={getIcon(variant)} style={{ width: 14, height: 14 }} className={s.icon} />
      )}
      <span>{t(variant)}</span>
    </div>
  );
};

export default withStyles(s)(Label);
