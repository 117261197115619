/* eslint-disable css-modules/no-unused-class */
/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import boostrapGrid from 'style/bootstrap-grid.css';

const CenteredRow = ({ children, gutterBottom, className }) => (
  <div className={boostrapGrid.row}>
    <div
      className={classnames(
        boostrapGrid['col-lg-12'],
        boostrapGrid['offset-lg-6'],
        boostrapGrid['col-md-14'],
        boostrapGrid['offset-md-5'],
        boostrapGrid['col-sm-16'],
        boostrapGrid['offset-sm-4'],
        {
          [className]: !!className,
        }
      )}
      style={{ marginBottom: gutterBottom ? '2.5rem' : '0' }}
    >
      {children}
    </div>
  </div>
);

export default withStyles(boostrapGrid)(CenteredRow);
