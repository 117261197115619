// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ListItem-listItem-2gKq-{padding-left:27px;position:relative}.ListItem-listItem-2gKq-:not(.ListItem-ordered-2Sla3).ListItem-orange-2SQDU:before{background:#ea8f12}.ListItem-number-2P35j{left:0;position:absolute}.ListItem-listItem-2gKq-:not(.ListItem-ordered-2Sla3):before{background:#33414e;border-radius:50%;content:\" \";height:6px;left:3px;position:absolute;top:10px;width:6px}.ListItem-listItem-2gKq-.ListItem-darkMode-1WAfX:not(.ListItem-ordered-2Sla3):before{background:#fff}", ""]);
// Exports
exports.locals = {
	"orange": "ListItem-orange-2SQDU",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"listItem": "ListItem-listItem-2gKq-",
	"ordered": "ListItem-ordered-2Sla3",
	"number": "ListItem-number-2P35j",
	"darkMode": "ListItem-darkMode-1WAfX"
};
module.exports = exports;
