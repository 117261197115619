import { connect } from 'react-redux';
import StandardSectionPage from 'components/pages/StandardSectionPage';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getUniqueName from 'selectors/getUniqueName';

const mapStateToProps = state => {
  return {
    groupedStorylineTree: getGroupedStorylineTree(state),
    uniqueName: getUniqueName(state),
  };
};

const mapDispatchToProps = undefined;

const StandardSectionPageContainer = connect(mapStateToProps, mapDispatchToProps)(StandardSectionPage);

export default StandardSectionPageContainer;
