// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TOC-toc-3XJJg{background:#faf9f7;border-radius:2px;margin-bottom:2rem;margin-left:-1.5rem;margin-right:-1.5rem;overflow:hidden}.TOC-toc-3XJJg>button{font-size:1.5rem}.TOC-header-2gaBv{-ms-flex-align:center;align-items:center;background:#dee4ea;cursor:pointer;display:-ms-flexbox;display:flex;padding:1rem 1.5rem}.TOC-header-2gaBv .TOC-title-8phVU{font-family:LabGrotesque-Bold,sans-serif;font-size:1.25rem}.TOC-title-8phVU{-ms-flex-positive:1;flex-grow:1}.TOC-icon-2Zm_Y{-webkit-box-sizing:content-box;box-sizing:content-box;height:1.25rem;margin-left:.5rem;width:1.25rem}.TOC-list-3hldL{font-size:1.125rem;list-style:none;margin:0 0 1rem;padding:1rem 1.5rem}.TOC-list-3hldL .TOC-listItem-2EOVj{font-size:1.125rem;line-height:1.5}@media (min-width:541px){.TOC-toc-3XJJg{margin-left:-2rem;margin-right:-2rem}.TOC-header-2gaBv,.TOC-list-3hldL{padding-inline:2rem}}.TOC-listItem-2EOVj .TOC-link-Ga2sr{color:inherit;font-size:inherit;line-height:inherit;text-decoration:none}.TOC-listItem-2EOVj .TOC-link-Ga2sr:hover{text-decoration:underline}.TOC-listItem-2EOVj.TOC-item-3-2RAwU,.TOC-listItem-2EOVj.TOC-item-4-2NJWo{font-size:1rem}.TOC-prefix-2UiFu{margin-right:.5rem}.TOC-item-3-2RAwU{font-size:1rem;margin-left:1rem}.TOC-item-4-2NJWo{font-size:1rem;margin-left:2rem}.TOC-item-3-2RAwU+.TOC-item-2-1scOE,.TOC-item-4-2NJWo+.TOC-item-2-1scOE{margin-top:1rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"toc": "TOC-toc-3XJJg",
	"header": "TOC-header-2gaBv",
	"title": "TOC-title-8phVU",
	"icon": "TOC-icon-2Zm_Y",
	"list": "TOC-list-3hldL",
	"listItem": "TOC-listItem-2EOVj",
	"link": "TOC-link-Ga2sr",
	"item-3": "TOC-item-3-2RAwU",
	"item-4": "TOC-item-4-2NJWo",
	"prefix": "TOC-prefix-2UiFu",
	"item-2": "TOC-item-2-1scOE"
};
module.exports = exports;
