import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import extractImages from 'utils/extractImages';
import reduceTeaserOptions from 'utils/reduceTeaserOptions';
import clientifyUrl from '../utils/clientifyUrl';

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      const selectedContentRaw =
        data?.context?.selectedContent ||
        data?.context?.deskedContent?.sectionInfo?.[0]?.content?.automaticSelectedContent ||
        data?.context?.deskedContent?.selectedContent ||
        data?.tagInfo?.[0]?.automaticSelectedContent ||
        data?.tagInfo?.[0]?.selectedContent ||
        [];
      return selectedContentRaw.map(obj => {
        // eslint-disable-next-line prefer-const
        let { content, teaserOptions: teaserOptionsRaw } = obj;
        if (!content) {
          content = obj;
        }
        const { teaserImage, href } = content;

        const teaserOptions = teaserOptionsRaw && reduceTeaserOptions(teaserOptionsRaw);

        const teaserValues = {
          title: teaserOptions?.useSummaryFields ? teaserOptions?.title : content.title,
          fields: teaserOptions?.useSummaryFields
            ? {
                ...content?.fields,
                teaser: teaserOptions?.teaser,
              }
            : content?.fields,
        };
        return {
          ...content,
          ...teaserValues,
          href: clientifyUrl(href),
          images: extractImages(teaserImage),
        };
      });
    } catch (err) {
      console.error(err);
      return [];
    }
  }
  return [];
});
