/* eslint-disable dot-notation */
import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import getStructuredStorylineTree from 'selectors/getStructuredStorylineTree';
import reduceTeaserOptions from 'utils/reduceTeaserOptions';

const getHeadlineFromElements = elements => {
  const headlineElement = elements.find(el => el.type === 'headline');
  return headlineElement?.fields?.headline?.rawText || 'Svenskt Näringsliv';
};

export default createSelector(
  getStructuredStorylineTree,
  cookSelectors.getData,
  cookSelectors.getError,
  (elements, data, error) => {
    if (error?.status === 'error') {
      return `Sidan kan inte visas (${error?.code})`;
    }
    if (!data) {
      return 'Svenskt Näringsliv';
    }

    let pageTitle;

    const context = data?.context;
    const contentType = data?.context?.contentType;
    const contextType = data?.resolution?.context;

    if (context && contextType === 'sec') {
      const { section } = context;
      const { uniqueName } = section;
      switch (uniqueName) {
        case 'ece_frontpage':
          pageTitle = 'Svenskt Näringsliv';
          break;
        default: {
          const sectionInfo = context?.deskedContent?.sectionInfo?.[0];
          const teaserOptions = sectionInfo && reduceTeaserOptions(sectionInfo?.teaserOptions);
          pageTitle = teaserOptions?.useSummaryFields ? teaserOptions.title : sectionInfo?.content.title;
        }
      }
    } else if (contextType === 'tag') {
      const headlineFromElements = getHeadlineFromElements(elements);
      return (elements?.length > 0 && headlineFromElements) || data?.context?.title;
    } else if (contextType === 'art') {
      switch (contentType) {
        case 'remiss':
        case 'tv':
        case 'event':
        case 'skrivelse':
          pageTitle = context?.title;
          break;
        default:
          pageTitle = getHeadlineFromElements(elements);
      }
    }

    return (pageTitle || 'Svenskt Näringsliv')?.replace('&shy;', '');
  }
);
