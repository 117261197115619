import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import CoworkerProfilePage from 'components/pages/CoworkerProfilePage';
import getCoworkerProfileData from 'selectors/getCoworkerProfileData';
import getLatestArticles from 'selectors/getLatestArticles';
import getHighResolutionImages from 'selectors/getHighResolutionImages';
import getDocuments from 'selectors/getDocuments';
import getAuthorsLatestBlogPosts from 'selectors/getAuthorsLatestBlogPosts';

const getIsBlogWriter = createSelector(cookSelectors.getData, data => {
  return {
    fokusPaSkatterna: data?.context?.isBlogWriterOnFokusPaSkatterna,
    kompetensforsorjning: data?.context?.isBlogWriterOnKompetensforsorjning,
  };
});

const mapStateToProps = state => {
  return {
    coworkerProfileData: getCoworkerProfileData(state),
    latestArticles: getLatestArticles(state),
    highResolutionImages: getHighResolutionImages(state),
    documents: getDocuments(state),
    latestBlogPosts: getAuthorsLatestBlogPosts(state),
    isBlogWriter: getIsBlogWriter(state),
  };
};

const mapDispatchToProps = undefined;

const CoworkerProfilePageContainer = connect(mapStateToProps, mapDispatchToProps)(CoworkerProfilePage);

export default CoworkerProfilePageContainer;
