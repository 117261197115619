import React from 'react';
/* eslint-disable css-modules/no-undef-class */
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import Link from 'containers/atoms/LinkContainer';
import RegionOfficesWithNames from 'assets/svgComponents/Maps/RegionOfficesWithNames';
// eslint-disable-next-line css-modules/no-unused-class
import boostrapGrid from 'style/bootstrap-grid.css';

import s from './RegionOfficesPage.css';
import Typography from '../../atoms/Typography/Typography';

const RegionLink = ({ children, to }) => (
  <li className={s.regionLinkItem}>
    <Typography variant="h3" component={Link} to={to} classes={s.regionLink}>
      <FontAwesomeIcon
        icon={faArrowRight}
        className={s.arrowRight}
        style={{ width: 18, height: 16 }}
        aria-hidden="true"
      />
      {children}
    </Typography>
  </li>
);

const RegionOfficesPage = () => {
  return (
    <>
      <div className={s.backgroundPlate} />
      <div className={s.contactWrapper}>
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
            <div
              className={classnames(
                boostrapGrid['col-lg-16'],
                boostrapGrid['offset-lg-4'],
                boostrapGrid['col-md-24']
              )}
            >
              <div className={classnames(s.contactRow)}>
                <Link to="/om_oss/kontakt/" className={s.contactBox}>
                  <Typography variant="h3" classes={s.contactTypeHeadline}>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className={s.arrowRight}
                      style={{ width: 18, height: 16 }}
                      aria-hidden="true"
                    />
                    Kontakta huvudkontoret
                  </Typography>
                  <Typography variant="paragraphSmall" classes={s.contactAddress}>
                    Storgatan 19, 114 82 Stockholm
                  </Typography>
                </Link>
                <Link to="/eu-kontoret/" className={s.contactBox}>
                  <Typography variant="h3" classes={s.contactTypeHeadline}>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className={s.arrowRight}
                      style={{ width: 18, height: 16 }}
                      aria-hidden="true"
                    />
                    EU-kontoret
                  </Typography>
                  <Typography variant="paragraphSmall" classes={s.contactAddress}>
                    Rue du Luxembourg 3, Bruxelles, Belgium
                  </Typography>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classnames(boostrapGrid.container, s.regionContainer)}>
        <div className={s.region_offices_container}>
          <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
            <div
              className={classnames(
                boostrapGrid['col-lg-18'],
                boostrapGrid['offset-lg-3'],
                boostrapGrid['col-md-24']
              )}
            >
              <div className={s.desktopMapWrapper}>
                <RegionOfficesWithNames classes={s.desktopMap} />
              </div>
              <ul className={s.regionLinks}>
                <RegionLink to="/regioner/norrbotten/">Norrbottens län</RegionLink>
                <RegionLink to="/regioner/vasterbotten/">Västerbottens län</RegionLink>
                <RegionLink to="/regioner/vasternorrland/">Västernorrlands län</RegionLink>
                <RegionLink to="/regioner/jamtland/">Jämtlands län</RegionLink>
                <RegionLink to="/regioner/gavleborg/">Gävleborgs län</RegionLink>
                <RegionLink to="/regioner/dalarna/">Dalarnas län</RegionLink>
                <RegionLink to="/regioner/uppsala/">Uppsala län</RegionLink>
                <RegionLink to="/regioner/varmland/">Värmlands län</RegionLink>
                <RegionLink to="/regioner/vastmanland/">Västmanlands län</RegionLink>
                <RegionLink to="/regioner/stockholm/">Stockholms län</RegionLink>
                <RegionLink to="/regioner/sodermanland/">Södermanlands län</RegionLink>
                <RegionLink to="/regioner/orebro/">Örebro län</RegionLink>
                <RegionLink to="/regioner/ostergotland/">Östergötlands län</RegionLink>
                <RegionLink to="/regioner/vastra-gotaland/">Västra Götalands län</RegionLink>
                <RegionLink to="/regioner/kalmar/">Kalmar län</RegionLink>
                <RegionLink to="/regioner/gotland/">Gotlands län</RegionLink>
                <RegionLink to="/regioner/jonkoping/">Jönköpings län</RegionLink>
                <RegionLink to="/regioner/halland/">Hallands län</RegionLink>
                <RegionLink to="/regioner/kronoberg/">Kronobergs län</RegionLink>
                <RegionLink to="/regioner/blekinge/">Blekinge län</RegionLink>
                <RegionLink to="/regioner/skane/">Skåne län</RegionLink>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(boostrapGrid, s)(RegionOfficesPage);
