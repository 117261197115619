import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import bootstrapGrid from 'style/bootstrap-grid.css';
import BootstrapWrapper from '../BootstrapWrapper/BootstrapWrapper';
import FooterSubscribe from '../../../molecules/FooterSubscribe';
import Typography from '../../../atoms/Typography/Typography';
import s from './SubscribeToBlog.css';

const SubscribeToBlog = ({
  blogUniqueName,
  name,
  description,
  apsisMailingListId,
  mailjetMailingListId,
  mailjetAccount,
}) => {
  return (
    <div
      className={classnames(s.subscribeToBlog, {
        [s.fokusPaSkatterna]: blogUniqueName === 'fokus-pa-skatterna',
        [s.kompetensforsorjning]: blogUniqueName === 'kompetensforsorjning',
      })}
    >
      <div className={bootstrapGrid.container}>
        <div className={bootstrapGrid.row}>
          <BootstrapWrapper className={s.inner}>
            <div>
              <Typography darkMode variant="h2">
                Prenumerera på bloggen
              </Typography>
              <Typography className={s.description} darkMode>
                {description}
              </Typography>
            </div>
            <div>
              <FooterSubscribe
                hideHeadline
                id="blogg-prenumeration"
                apsisMailingListId={apsisMailingListId}
                mailjetMailingListId={mailjetMailingListId}
                mailjetAccount={mailjetAccount}
                newsletterName={name}
              />
            </div>
          </BootstrapWrapper>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(SubscribeToBlog);
