import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from '../utils/pickFrom';
import { selectors as locationSelectors } from '../ducks/location';

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, data => {
  const micrositeContentData = pickFrom(data, 'context.deskedContent.content');

  const arrayOfGroupOptions = [];
  const arrayOfGroups = [];
  const items = [];

  micrositeContentData.forEach(content => {
    const groupOptions = pickFrom(content, 'groupOptions');
    arrayOfGroupOptions.push(groupOptions);
  });

  micrositeContentData.forEach(content => {
    const groups = pickFrom(content, 'groups');
    arrayOfGroups.push(groups);
  });
  let y = -1;
  const arrayOfContent = [];
  arrayOfGroups.forEach(puff => {
    y++;

    puff.forEach(el => {
      let contentType = '';
      let contentSrc = '';
      if (el?.content !== undefined) {
        contentType = el?.content.contentType;
        if (contentType === 'picture') {
          contentSrc = el?.content?.fields?.BASE_LANDSCAPE?.href_full;
        } else if (contentType === 'video') {
          contentSrc = el?.content?.binary?.href;
        }
        arrayOfContent.push({ y, contentType, contentSrc });
      }
    });
  });

  const getSrcType = els => {
    let contentSrcType = 'defalut';
    els.forEach(el => {
      const content = pickFrom(el, 'content');
      if (content !== undefined) {
        contentSrcType = content?.contentType;
      }
    });
    return contentSrcType;
  };

  const getSrc = els => {
    let scType = 'defalut';
    let contentSrcType = '';
    els.forEach(el => {
      const content = pickFrom(el, 'content');
      if (content !== undefined) {
        contentSrcType = content?.contentType;

        if (contentSrcType === 'picture') {
          scType = content?.fields?.BASE_LANDSCAPE?.href_full;
        }
        if (contentSrcType === 'video') {
          scType = content?.binary?.href;
        }
      }
    });
    return scType;
  };

  let i = 0;
  arrayOfGroupOptions.forEach(puff => {
    const contentTypeValue = getSrcType(arrayOfGroups[i]);
    const contentSrcValue = getSrc(arrayOfGroups[i]);
    const tiltedBottomValue = puff.find(e => e.key === 'tiltedBottom')?.value;
    const tiltedTopValue = puff.find(e => e.key === 'tiltedTop')?.value;
    const showOutlineLogoValue = puff.find(e => e.key === 'showOutlineLogo')?.value;
    const backgroundValue = puff.find(e => e.key === 'backgroundColor')?.value;
    const widthValue = puff.find(e => e.key === 'width')?.value;
    const bottomMarginValue = puff.find(e => e.key === 'bottomMargin')?.value;
    const topMarginValue = puff.find(e => e.key === 'topMargin')?.value;
    const bgOpacity = (parseInt(puff.find(e => e.key === 'backgroundColorOpacity')?.value, 10) || 100) / 100;
    const anchorTarget = puff.find(e => e.key === 'anchorTarget')?.value || undefined;

    const srcType = contentTypeValue === undefined || contentTypeValue === null ? '' : contentTypeValue;
    const src = contentSrcValue === undefined || contentSrcValue === null ? '' : contentSrcValue;
    const tiltedBottom =
      tiltedBottomValue === undefined || tiltedBottomValue === null ? '' : tiltedBottomValue;
    const tiltedTop = tiltedTopValue === undefined || tiltedTopValue === null ? '' : tiltedTopValue;
    const showOutlineLogo =
      showOutlineLogoValue === undefined || showOutlineLogoValue === null ? '' : showOutlineLogoValue;
    const background = backgroundValue === undefined || backgroundValue === null ? '' : backgroundValue;
    const width = widthValue === undefined || widthValue === null ? 'default' : widthValue;
    const bottomMargin =
      bottomMarginValue === undefined || bottomMarginValue === null ? 'default' : bottomMarginValue;
    const topMargin = topMarginValue === undefined || topMarginValue === null ? 'default' : topMarginValue;

    items.push({
      tiltedBottom,
      tiltedTop,
      showOutlineLogo,
      background,
      width,
      srcType,
      src,
      topMargin,
      bottomMargin,
      bgOpacity,
      anchorTarget,
    });
    i++;
  });

  return {
    items,
  };
});
