export const addExternalScript = (url, htmlElement, scriptElementId) => {
  if (!url) {
    return;
  }
  let script = document.querySelector(`script[src='${url}']`);
  if (!script) {
    script = document.createElement('script');
    script.type = 'application/javascript';
    if (scriptElementId) {
      script.id = scriptElementId;
    }
    script.src = url;
    script.defer = true;
    document.querySelector(`${htmlElement}`).appendChild(script);
  }
};
