import React from 'react';
/* eslint-disable css-modules/no-undef-class */
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
// eslint-disable-next-line css-modules/no-unused-class
import renderStorylineElement from 'utils/renderStorylineElement';
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './SubscriptionPage.css';
import NewsletterSubscriptionsContainer from '../../organisms/NewsletterSubscriptionsContainer/NewsletterSubscriptionsContainer';

const SubscriptionPage = ({ groupedStorylineTree, newsletters }) => {
  const { headGroup, contentGroup } = groupedStorylineTree;
  return (
    <div className={s.subscriptionWrapper}>
      <div className={classnames(boostrapGrid.container)}>
        <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
          <div
            className={classnames(
              boostrapGrid['col-lg-12'],
              boostrapGrid['offset-lg-6'],
              boostrapGrid['col-md-16'],
              boostrapGrid['offset-md-4']
            )}
          >
            <div className={s.headContentWrapper}>
              {headGroup && headGroup.map(renderStorylineElement)}
              {contentGroup && contentGroup.map(renderStorylineElement)}
            </div>
            <NewsletterSubscriptionsContainer newsletters={newsletters} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(boostrapGrid, s)(SubscriptionPage);
