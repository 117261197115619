import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Link from 'containers/atoms/LinkContainer';
import Typography from 'components/atoms/Typography';
import ms from '../../GOAContainer.css';
import s from './ColumnStatistik.css';

const ColumnStatistik = ({ reviewData, isMediumOrLG }) => {
  const [showRelatedArticles, setShowRelatedArticles] = useState(false);
  const statistics = reviewData?.reviewData?.article?.googleAnalyticsPageViews;
  return (
    <>
      <Typography variant="h4" className={ms.headline}>
        Statistik
      </Typography>
      <Typography variant="paragraphSmall">Sidvisningar</Typography>
      <table>
        <tbody>
          <tr>
            <th>idag</th>
            <th>7{isMediumOrLG ? 'd' : ' dagar'}</th>
            <th>30{isMediumOrLG ? 'd' : ' dagar'}</th>
            <th>365{isMediumOrLG ? 'd' : ' dagar'}</th>
          </tr>
          <tr>
            <td>{statistics?.TODAY ?? 0}</td>
            <td>{statistics?.ONE_WEEK_BACK ?? 0}</td>
            <td>{statistics?.ONE_MONTH_BACK ?? 0}</td>
            <td>{statistics?.ONE_YEAR_BACK ?? 0}</td>
          </tr>
        </tbody>
      </table>
      <Typography variant="paragraphSmall" gutterBottom>
        Länkar till sidan: {reviewData?.reviewData?.article?.reverseRelatedArticles?.length ?? 0} (
        <span onClick={() => setShowRelatedArticles(!showRelatedArticles)} className={s.link}>
          {showRelatedArticles ? 'dölj' : 'visa'}
        </span>
        )
      </Typography>
      {showRelatedArticles &&
        reviewData?.reviewData?.article?.reverseRelatedArticles.map(article => (
          <div key={article.id} className={s.linkItem}>
            <Typography variant="paragraphSmall">
              {article?.lastModified}{' '}
              <span style={{ textTransform: 'uppercase' }}>({article?.contentTypeLabel})</span>
            </Typography>
            <Typography variant="paragraphSmall">
              <Link target="_blank" to={article.url}>
                {article.title}
              </Link>
            </Typography>
          </div>
        ))}
    </>
  );
};

export default withStyles(ms, s)(ColumnStatistik);
