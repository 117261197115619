import React from 'react';
import classnames from 'classnames';
import Typography from 'components/atoms/Typography';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import s from './FormError.css';

const FormError = ({ classes, headline, text, ...other }) => {
  const classNames = classnames(s.formError, classes);
  return (
    <div className={classNames} {...other}>
      <div className={s.headline}>
        <FontAwesomeIcon icon={faInfoCircle} className={s.infoCircle} />
        {headline}
      </div>
      <Typography variant="paragraphSmall" classes={s.text}>
        {text}
      </Typography>
    </div>
  );
};

export default withStyles(s)(FormError);
