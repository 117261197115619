// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Spacer-spacer-Ux7TX{--spacing-default:0}@media (min-width:0){.Spacer-spacer-Ux7TX{height:var(--spacing-default);height:var(--spacing-xxs,var(--spacing-default))}}@media (min-width:320px){.Spacer-spacer-Ux7TX{height:var(--spacing-default);height:var(--spacing-xs,var(--spacing-xxs,var(--spacing-default)))}}@media (min-width:541px){.Spacer-spacer-Ux7TX{height:var(--spacing-default);height:var(--spacing-sm,var(--spacing-xs,var(--spacing-xxs,var(--spacing-default))))}}@media (min-width:820px){.Spacer-spacer-Ux7TX{height:var(--spacing-default);height:var(--spacing-md,var(--spacing-sm,var(--spacing-xs,var(--spacing-xxs,var(--spacing-default)))))}}@media (min-width:1169px){.Spacer-spacer-Ux7TX{height:var(--spacing-default);height:var(--spacing-lg,var(--spacing-md,var(--spacing-sm,var(--spacing-xs,var(--spacing-xxs,var(--spacing-default))))))}}@media (min-width:1600px){.Spacer-spacer-Ux7TX{height:var(--spacing-default);height:var(--spacing-xl,var(--spacing-lg,var(--spacing-md,var(--spacing-sm,var(--spacing-xs,var(--spacing-xxs,var(--spacing-default)))))))}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"spacer": "Spacer-spacer-Ux7TX"
};
module.exports = exports;
