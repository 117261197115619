// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DocumentItem-documentItem-2WvD5{margin-bottom:16px;text-decoration:none}.DocumentItem-headline-2hhCP{background:inherit;border:0;color:#33414e;font-family:LabGrotesque-Bold,sans-serif;font-size:1rem;line-height:1.85rem;margin:0;text-decoration:none}@media (min-width:541px){.DocumentItem-headline-2hhCP{font-size:1.125rem;line-height:1.625rem}}.DocumentItem-fileIcon-3JZbI{color:#62717f;height:14px;margin-right:8px;margin-top:1px;width:auto}.DocumentItem-fileType-2sIhV{background:inherit;color:#62717f;font-family:LabGrotesque-Regular,sans-serif;font-size:.875rem;line-height:1.5rem;text-decoration:none;text-transform:uppercase}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"documentItem": "DocumentItem-documentItem-2WvD5",
	"headline": "DocumentItem-headline-2hhCP",
	"fileIcon": "DocumentItem-fileIcon-3JZbI",
	"fileType": "DocumentItem-fileType-2sIhV"
};
module.exports = exports;
