/* eslint-disable css-modules/no-undef-class */

/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import ContactItem from 'components/molecules/ContactItem';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import renderStorylineElement from 'utils/renderStorylineElement';
import boostrapGrid from 'style/bootstrap-grid.css';
import classnames from 'classnames';
import s from './ContactUsPage.css';

const ContactUsPage = ({ contactItems, groupedStorylineTree }) => (
  <div className={s.contactUsPage}>
    <div className={classnames(boostrapGrid.container)}>
      <div className={classnames(boostrapGrid.row)}>
        <div className={getBootstrapColumns('20,2', '18,3', '12,6')}>
          <div className={s.contactList}>
            {groupedStorylineTree.headGroup &&
              groupedStorylineTree.headGroup.map(el => renderStorylineElement(el, false))}
            <div className={s.contatcItemsWrapper}>
              {contactItems && contactItems.map(item => <ContactItem key={item.id} data={item} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={s.faqListBg}>
      <div className={classnames(boostrapGrid.container)}>
        <div className={classnames(boostrapGrid.row)}>
          <div className={getBootstrapColumns('20,2', '18,3', '12,6')}>
            {groupedStorylineTree.contentGroup &&
              groupedStorylineTree.contentGroup.map(el => renderStorylineElement(el, false))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default withStyles(s, boostrapGrid)(ContactUsPage);
