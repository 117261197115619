// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".YouHaveSearchedFor-youHaveSearchedFor-Fb72C .YouHaveSearchedFor-removeButton-wni_F{background-color:inherit;border:none;cursor:pointer;line-height:20px;margin-left:4px;padding:12px}.YouHaveSearchedFor-youHaveSearchedFor-Fb72C .YouHaveSearchedFor-removeButton-wni_F .YouHaveSearchedFor-removeIcon-2KFTM{color:#ea8f12;height:20px;width:20px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"youHaveSearchedFor": "YouHaveSearchedFor-youHaveSearchedFor-Fb72C",
	"removeButton": "YouHaveSearchedFor-removeButton-wni_F",
	"removeIcon": "YouHaveSearchedFor-removeIcon-2KFTM"
};
module.exports = exports;
