import React, { useState, useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {
  faTimes,
  faSearch,
  faLock,
  faLockOpen,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-light-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import FocusTrap from 'focus-trap-react';
import useDarkHeader from '../../../../utils/hooks/useDarkHeader';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import s from './MobileHeader.css';
import MobileMenu from '../MobileMenu/MobileMenu';
import useGlobalState from '../../../../zustand/zustand';
import useAuth from '../../../../utils/hooks/useAuth';
import useUser from '../../../../utils/hooks/useUser';
import Spinner from '../../../atoms/Spinner/Spinner';
import useFeatureFlags from '../../../../utils/hooks/useFeatureFlags';

const openCloseButtonAnimationProps = {
  initial: { y: -10, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 10, opacity: 0 },
  transition: { duration: 0.15 },
};

const MobileHeader = () => {
  const { isAuthenticated, login, authQuery } = useAuth();
  const { userQuery } = useUser();
  const showSpinner = userQuery.isLoading || authQuery.isLoading;
  const featureFlags = useFeatureFlags();

  const isDarkHeader = useDarkHeader();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useGlobalState(state => [
    state.pageHeader.mobileMenu.isOpen,
    state.pageHeader.mobileMenu.setIsOpen,
  ]);
  const [isMobileLoggedInMenuOpen, setIsMobileLoggedInMenuOpen] = useGlobalState(state => [
    state.pageHeader.mobileLoggedInMenu.isOpen,
    state.pageHeader.mobileLoggedInMenu.setIsOpen,
  ]);
  const shouldRenderSearchButton = useGlobalState(state => state.pageHeader.searchButton.isVisible);
  const setShowSearch = useGlobalState(state => state.search.setShowSearch);
  const [scrollbarOffset, setScrollbarOffset] = useState(0);
  const [isTrapped, setIsTrapped] = useState(false);

  useEffect(() => {
    if (isMobileMenuOpen || isMobileLoggedInMenuOpen) {
      setTimeout(() => {
        setIsTrapped(true);
      }, 0);
    } else {
      setIsTrapped(false);
    }
  }, [isMobileMenuOpen, isMobileLoggedInMenuOpen]);

  const handleLoginOrProfileClicked = () => {
    if (isAuthenticated) {
      setIsMobileMenuOpen(false);
      if (isMobileLoggedInMenuOpen) {
        setIsMobileLoggedInMenuOpen(false);
      } else {
        setIsMobileLoggedInMenuOpen(true);
      }
    } else {
      login();
    }
  };

  const handleShowOrCloseMenu = action => {
    setIsMobileLoggedInMenuOpen(false);
    if (action === 'open') {
      setIsMobileMenuOpen(true);
    } else {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    const offset = window.innerWidth - document.body.clientWidth;
    setScrollbarOffset(offset);
  }, []);

  return (
    <>
      {/* MobileMenu renders in a portal */}
      <MobileMenu />
      {isTrapped &&
        !!document.querySelector('#mobile-menu') &&
        !!document.querySelector('#mobile-header') && (
          <FocusTrap
            containerElements={[
              document.querySelector('#mobile-menu'),
              document.querySelector('#mobile-header'),
            ]}
          />
        )}
      <div
        id="mobile-header"
        className={classnames(s.mobileHeader, {
          [s.darkMode]: isDarkHeader,
        })}
      >
        <div className={classnames(s.logoRow, isDarkHeader ? s.logoRowDark : s.logoRowLight)}>
          <HeaderLogo />
        </div>
        <div className={`${s.buttonsRow} ${isDarkHeader ? s.buttonsRowDark : ''}`}>
          <div
            className={s.buttonsWrapper}
            style={isMobileMenuOpen ? { marginRight: `${scrollbarOffset}px` } : undefined}
          >
            <div className={s.leftButtons}>
              {showSpinner && <Spinner color={isDarkHeader ? 'white' : 'black'} />}
              {!showSpinner && !featureFlags.disablePremiumContent && (
                <button type="button" onClick={() => handleLoginOrProfileClicked()}>
                  {isAuthenticated && userQuery.data ? (
                    <FontAwesomeIcon icon={faLockOpen} />
                  ) : (
                    <FontAwesomeIcon icon={faLock} />
                  )}
                  <span className={s.menuText}>
                    {isAuthenticated && userQuery.data ? (
                      <>
                        <span>{userQuery.data?.userData?.name?.split(' ')[0]}</span>
                        <FontAwesomeIcon icon={isMobileLoggedInMenuOpen ? faChevronUp : faChevronDown} />
                      </>
                    ) : (
                      <span>Logga in</span>
                    )}
                  </span>
                </button>
              )}
            </div>
            <div className={s.rightButtons}>
              {shouldRenderSearchButton && (
                <AnimatePresence initial={false}>
                  {!isMobileMenuOpen && (
                    <motion.button
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      type="button"
                      onClick={() => setShowSearch(true)}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                      <span className={s.menuText}>Sök</span>
                    </motion.button>
                  )}
                </AnimatePresence>
              )}
              <AnimatePresence initial={false} exitBeforeEnter>
                {isMobileMenuOpen ? (
                  <motion.button
                    key="1"
                    type="button"
                    onClick={() => handleShowOrCloseMenu('close')}
                    {...openCloseButtonAnimationProps}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </motion.button>
                ) : (
                  <motion.button
                    key="2"
                    type="button"
                    onClick={() => handleShowOrCloseMenu('open')}
                    {...openCloseButtonAnimationProps}
                  >
                    <FontAwesomeIcon icon={faBars} />
                    <span className={s.menuText}>Meny</span>
                  </motion.button>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(s)(MobileHeader);
