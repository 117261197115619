// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FooterSocial-footerSocial-3zNGx{color:#fff;display:block;font-family:LabGrotesque-Regular,sans-serif;line-height:0;margin-left:-12px}.FooterSocial-footerSocial-3zNGx .FooterSocial-link-1ZBvi{margin-bottom:12px;margin-left:12px}@media (min-width:541px){.FooterSocial-footerSocial-3zNGx{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin-left:0;position:absolute;right:56px;text-align:right}}@media (min-width:820px){.FooterSocial-footerSocial-3zNGx{display:block;position:relative;right:0}}.FooterSocial-footerSocial-3zNGx a{display:inline-block}.FooterSocial-footerSocial-3zNGx a.FooterSocial-footerSocial-3zNGx:hover{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"footerSocial": "FooterSocial-footerSocial-3zNGx",
	"link": "FooterSocial-link-1ZBvi"
};
module.exports = exports;
