import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from '../utils/pickFrom';
import clientifyUrl from '../utils/clientifyUrl';

export const processLatestWebinars = webinars => {
  return webinars?.map(({ title, teaserImage, videoBackgroundImage, id, href, fields }) => {
    const imageFields = teaserImage[0]?.content?.fields || videoBackgroundImage?.[0]?.content?.fields;
    const imageKey = 'BASE_LANDSCAPE';

    return {
      title,
      image: imageFields && {
        href: imageFields?.[imageKey]?.href_full.replace(imageKey, 'LANDSCAPE_S'),
        alt: imageFields?.alttext,
        width: imageFields?.[imageKey]?.sourceWidth,
        height: imageFields?.[imageKey]?.sourceHeight,
      },
      id,
      href: clientifyUrl(href),
      airedDate: fields?.startDate,
    };
  });
};

export default createSelector(cookSelectors.getData, data => {
  try {
    const latestWebinars = pickFrom(
      data,
      'context.deskedContent.latestWebinars',
      'tagInfo[0].latestWebinars'
    );
    return processLatestWebinars(latestWebinars);
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
