import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './PodBanner.css';
import ButtonSecondaryGhost from '../../../atoms/buttons/ButtonSecondaryGhost';
import Link from '../../../../containers/atoms/LinkContainer';
import Typography from '../../../atoms/Typography/Typography';

const PodBanner = () => {
  return (
    <div className={s.podBannerSection}>
      <div className={s.PodBannerBackground}>
        <div className={s.PodBanner}>
          <Typography className={s.skattefragor} darkMode component="span">
            Skattefrågan
          </Typography>
          <Typography className={s.body} darkMode>
            Lyssna på Svenskt Näringslivs
            <br />
            podd om aktuella skattefrågor
          </Typography>
          <ButtonSecondaryGhost
            darkMode
            className={s.viewAllPosts}
            component={Link}
            to="https://nsd100.libsyn.com/"
            target="_blank"
          >
            Till podden
          </ButtonSecondaryGhost>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(PodBanner);
