import { isValid, format, isFuture } from 'date-fns';
import { sv } from 'date-fns/locale';

const DEFAULT_OPTIONS = { locale: sv };

const formatDate = (date, options = DEFAULT_OPTIONS) =>
  isValid(new Date(date)) ? format(new Date(date), 'd MMMM', options) : undefined;

const formatDateWithYear = (date, options = DEFAULT_OPTIONS) =>
  isValid(new Date(date)) ? format(new Date(date), 'd MMMM yyyy', options) : undefined;

const formatTime = (date, options = DEFAULT_OPTIONS) =>
  isValid(new Date(date)) ? format(new Date(date), 'H:mm', options) : undefined;

const formatTime24 = (date, options = DEFAULT_OPTIONS) =>
  isValid(new Date(date)) ? format(new Date(date), 'HH:mm', options) : undefined;

const formatDateWithYearAndTime = date => `${formatDate(date)} kl. ${formatTime24(date)}`;

const toIsoDate = (date, options = DEFAULT_OPTIONS) =>
  isValid(new Date(date)) ? format(new Date(date), 'yyyy-MM-dd HH:mm', options) : undefined;

const isInFuture = date => (isValid(new Date(date)) ? isFuture(new Date(date)) : false);

const isLessThan24HoursApart = (date1, date2) => {
  const diffInMs = Math.abs(new Date(date1) - new Date(date2));
  const diffInHours = diffInMs / (1000 * 60 * 60);
  return diffInHours < 24;
};

const generateFromToToString = (hideTimeInformation, from, to, options = {}) => {
  const fromDay = options.showYear ? formatDateWithYear(from) : formatDate(from);
  const toDay = options.showYear ? formatDateWithYear(to) : formatDate(to);

  let fromToString = hideTimeInformation ? fromDay : `${fromDay}, ${formatTime(from)}-${formatTime(to)}`;

  if (new Date(from).getMonth() !== new Date(to).getMonth()) {
    fromToString = `${fromDay} - ${toDay}`;
  } else if (fromDay !== toDay) {
    fromToString = `${fromDay.split(' ')?.[0]}-${toDay}`;
  }
  return fromToString;
};

const getMonthName = date => {
  return date.toLocaleString('default', { month: 'long' });
};

export {
  generateFromToToString,
  formatDate,
  formatDateWithYear,
  formatTime,
  toIsoDate,
  formatTime24,
  formatDateWithYearAndTime,
  isInFuture,
  getMonthName,
  isLessThan24HoursApart,
};
