import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import CountDown from './CountDown/CountDown';
import Chat from './Chat/Chat';
import useLiveEvent from '../../../../utils/hooks/queries/useLiveEvent';
import VimeoTestBalk from './VimeoTestBalk/VimeoTestBalk';
import TranscodingVideo from './TranscodingVideo/TranscodingVideo';
import s from './LiveEvent.css';
import BlurryPlayer from './BlurryPlayer/BlurrPlayer';

const LiveEvent = ({ isStorylineElement, element, viewingMode, vimeoHasBeenReset }) => {
  const {
    isLoading,
    isVimeoTest,
    eventStartDate,
    vimeoUrl,
    showVideo,
    chatUrl,
    showChat,
    showCountdown,
    showTranscodingVideo,
    videoNotFound,
    backgroundImage,
  } = useLiveEvent({
    isStorylineElement,
    element,
  });

  if (videoNotFound) {
    console.error('Vimeo video not found', vimeoUrl);
    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className={s.liveEvent}>
      {vimeoHasBeenReset && <BlurryPlayer viewingMode={viewingMode} />}
      {!vimeoHasBeenReset && (
        <>
          {isVimeoTest && <VimeoTestBalk />}
          {showTranscodingVideo && <TranscodingVideo backgroundImage={backgroundImage} />}
          {showCountdown && <CountDown date={eventStartDate} backgroundImage={backgroundImage} />}
          {showVideo && (
            <div className={s.videoWrapper}>
              <iframe
                src={vimeoUrl}
                title="title"
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; fullscreen; picture-in-picture"
              />
            </div>
          )}
          <Chat show={showChat} chatUrl={chatUrl} />
        </>
      )}
    </div>
  );
};

export default withStyles(s)(LiveEvent);
