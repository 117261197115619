// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Author-author-3sqCu{border-top:1px solid #dee4ea;padding-bottom:16px;padding-top:16px}.Author-author-3sqCu .Author-writtenBy-1yqY0{margin-right:1rem;text-transform:uppercase}.Author-author-3sqCu .Author-writtenBy-1yqY0,.Author-authorName-2qFVY{display:inline-block;line-height:30px;margin-bottom:0}.Author-authorName-2qFVY{color:#33414e;font-family:LabGrotesque-Regular,sans-serif;font-size:1rem;margin-right:16px;white-space:nowrap}a.Author-authorName-2qFVY{color:#3568a7;text-decoration:underline}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"author": "Author-author-3sqCu",
	"writtenBy": "Author-writtenBy-1yqY0",
	"authorName": "Author-authorName-2qFVY"
};
module.exports = exports;
