import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import boostrapGrid from 'style/bootstrap-grid.css';
import Typography from '../../atoms/Typography';
import s from './MembershipOrganizations.css';
import MembershipOrganization from '../../pages/MembershipOrganizationsPage/components/MembershipOrganization';
import useTranslation from '../../../utils/hooks/useTranslation';

const MembershipOrganizations = ({ memberOrganizationGroups }) => {
  const translation = useTranslation();

  if (!memberOrganizationGroups) return null;

  return memberOrganizationGroups?.map(memberOrganizationGroup => (
    <div key={memberOrganizationGroup.name}>
      <Typography variant="h2" gutterTop gutterBottom>
        {translation.IS_ENGLISH ? memberOrganizationGroup.englishName : memberOrganizationGroup.name}
      </Typography>
      {memberOrganizationGroup?.memberOrganizations?.length > 0 && (
        <div className={s.memberOrganizationGroupWrapper}>
          {memberOrganizationGroup?.memberOrganizations.map((membershipOrganization, idx) => (
            <MembershipOrganization
              key={membershipOrganization.id}
              id={membershipOrganization.id}
              name={membershipOrganization.name}
              href={membershipOrganization.website}
              teaser={membershipOrganization.teaser}
              // eslint-disable-next-line camelcase
              imageSrc={membershipOrganization.image?.images?.BASE?.LANDSCAPE?.href_full}
              imageAlt={membershipOrganization.image?.altText}
              hasBorder={idx !== memberOrganizationGroup.memberOrganizations.length - 1}
            />
          ))}
        </div>
      )}
    </div>
  ));
};

export default withStyles(s, boostrapGrid)(MembershipOrganizations);
