import React, { useState } from 'react';
/* eslint-disable css-modules/no-undef-class */
import ScrollTrigger from 'react-scroll-trigger';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import ContentTypeDate from 'components/atoms/ContentTypeDate';
import Author from 'components/molecules/Author';
import ShareBarContainer from 'containers/molecules/ShareBarContainer';
import AlsoRead from 'components/organisms/AlsoRead';
import RelatedContent from 'components/organisms/RelatedContent';
import VideoBlock from 'components/atoms/VideoBlock/VideoBlock';
import Tag from 'components/atoms/Tag';
import pickFrom from 'utils/pickFrom';
import CenteredRow from 'components/templates/Grid/CenteredRow';
import CenteredSecondaryRow from 'components/templates/Grid/CenteredSecondaryRow';
import renderArticleStorylineElement from 'utils/renderArticleStorylineElement';
// eslint-disable-next-line css-modules/no-unused-class
import boostrapGrid from 'style/bootstrap-grid.css';

import s from './ArticlePage.css';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';

const ArticlePage = ({
  publishedDate,
  relatedItems,
  groupedStorylineTree,
  authors,
  metadata,
  pageLabel,
  alsoRead,
  tags,
  translation,
}) => {
  if (!groupedStorylineTree) return undefined;
  const { headGroup, contentGroup } = groupedStorylineTree;
  const relatedVideo = relatedItems?.find(item => item.type === 'tv');
  const relItems = relatedItems?.filter(item => item.type !== 'tv');
  const [visible, setVisible] = useState(true);

  return (
    <>
      <OGShareTags metadata={metadata} />
      <ScrollTrigger onEnter={() => setVisible(true)} onExit={() => setVisible(false)}>
        <div className={classnames(boostrapGrid.container)}>
          <div className={s.article_container}>
            <CenteredRow>
              <ContentTypeDate contentType={pageLabel} date={publishedDate} />
              {headGroup && headGroup.map(renderArticleStorylineElement)}
              {relatedVideo && (
                <VideoBlock
                  key={pickFrom(relatedVideo, 'content.fields.assetId')}
                  relatedVideo={{
                    streamingProvider: pickFrom(relatedVideo, 'content.fields.streamingProvider'),
                    caption: pickFrom(relatedVideo, 'content.fields.body'),
                    id: pickFrom(relatedVideo, 'content.fields.assetId'),
                  }}
                  stretched
                  inHeadGroup
                />
              )}
              <div className={classnames(visible === true ? s.fadeIn : s.fadeOut)}>
                <ShareBarContainer />
              </div>
              <div className={s.stickyContainer}>
                <ShareBarContainer visible={visible} hideMobile />
                {contentGroup && contentGroup.map(renderArticleStorylineElement)}
                {tags?.map(tag => {
                  const href = `/${tag?.tagUri}`;
                  return (
                    <Tag key={href} href={href}>
                      {tag?.name}
                    </Tag>
                  );
                })}
              </div>
            </CenteredRow>
          </div>
        </div>
      </ScrollTrigger>
      <div className={s.secondaryWrapper}>
        <div className={classnames(boostrapGrid.container)}>
          <CenteredSecondaryRow>
            <Author authors={authors} />
            {relItems?.length > 0 && <RelatedContent items={relItems} />}
          </CenteredSecondaryRow>
        </div>
      </div>

      {translation.IS_SWEDISH && alsoRead?.length > 0 && (
        <div className={classnames(s.alsoReadWrapper, s.secondaryWrapper)}>
          <div className={classnames(boostrapGrid.container)}>
            <CenteredSecondaryRow>
              <AlsoRead items={alsoRead} />
            </CenteredSecondaryRow>
          </div>
        </div>
      )}
    </>
  );
};

export default withStyles(boostrapGrid, s)(ArticlePage);
