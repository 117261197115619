import { format, isToday } from 'date-fns';
import { enGB, sv } from 'date-fns/locale';
import getImages from 'utils/getImages';
import clientifyUrl from './clientifyUrl';

const MAX_LENGTH = 300;

const blogPostNormalizer = (post, { lang } = {}) => {
  const teaser = post?.fields?.teaser || post?.firstParagraph?.[0]?.fields?.[0]?.value;

  const coWorkers = post.profile || [];
  const formerCoWorkersAuthors =
    post.fields?.formerCoworkerAuthors?.map(formerCoworker => ({
      content: {
        title: formerCoworker?.name,
      },
    })) || [];

  const publishedDate = new Date(post.published);

  return {
    id: post.id,
    title: post.title,
    date: post.published,
    dateString: isToday(publishedDate)
      ? lang === 'sv'
        ? 'Idag'
        : 'Today'
      : format(publishedDate, 'd MMMM yyyy', { locale: lang === 'sv' ? sv : enGB }),
    url: clientifyUrl(post.href),
    authors: [...formerCoWorkersAuthors, ...coWorkers]?.map(profile => ({
      name: profile.content?.title,
      image: {
        src: getImages('SQUARE')?.toSrc(
          profile.content?.profileImage?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full,
          'M'
        ),
        alt: profile.content?.profileImage?.[0]?.content?.fields?.alttext,
      },
    })),
    teaser: teaser?.length > MAX_LENGTH ? `${teaser.split('')?.splice(0, MAX_LENGTH).join('')}…` : teaser,
  };
};

export default blogPostNormalizer;
