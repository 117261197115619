import { connect } from 'react-redux';
import BoardMembersPage from 'components/pages/BoardMembersPage';
import getCoworkerPageData from 'selectors/getCoworkerPageData';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';

const mapStateToProps = state => {
  return {
    pageData: getCoworkerPageData(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
  };
};

const mapDispatchToProps = undefined;

const BoardMembersPageContainer = connect(mapStateToProps, mapDispatchToProps)(BoardMembersPage);

export default BoardMembersPageContainer;
