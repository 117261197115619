import React from 'react';
import Link from 'containers/atoms/LinkContainer';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faBuildings,
  faEnvelope,
  faLock,
  faMedal,
  faPeopleGroup,
} from '@fortawesome/pro-light-svg-icons';
import s from './MinSidaMenu.css';
import Typography from '../../../../atoms/Typography/Typography';
import LoggedInHeader from '../../../../molecules/LoggedInHeader/LoggedInHeader';
import useUser from '../../../../../utils/hooks/useUser';
import UserCompaniesList from '../../../PageHeader/UserSpecifics/UserCompaniesList/UserCompaniesList';
import useAuth from '../../../../../utils/hooks/useAuth';

const MinSidaMenu = ({ userData }) => {
  const { logout } = useAuth();
  const { userQuery } = useUser();

  const menu = [
    {
      title: 'Hantera nyhetsbrev',
      icon: faEnvelope,
      link: '/min-sida/hantera-nyhetsbrev/',
      hideIfNoActiveCompany: false,
      target: '_self',
    },
    {
      title: 'Gå med i företagarpanelen',
      icon: faPeopleGroup,
      link: '/foretagarpanelen/anmalan/',
      hideIfNoActiveCompany: true,
      target: '_self',
    },
    {
      title: 'Medlemsbevis',
      icon: faMedal,
      link: '/min-sida/medlemsbevis/',
      hideIfNoActiveCompany: true,
      target: '_self',
    },
    {
      title: 'Medlemsportalen',
      icon: faBuildings,
      link: 'https://medlemsportalen.svensktnaringsliv.se',
      hideIfNoActiveCompany: false,
      target: 'blank',
    },
  ];

  const handleLogout = () => {
    logout();
  };

  return (
    <div className={s.menu}>
      <div className={s.topMenu}>
        <LoggedInHeader userData={userQuery?.data?.userData} />
        {userData?.companies?.length > 0 && (
          <div className={s.companiesList}>
            <UserCompaniesList />
          </div>
        )}
      </div>
      {menu
        .filter(
          item => !item.hideIfNoActiveCompany || (item.hideIfNoActiveCompany && userData?.activeCompany)
        )
        .map(item => (
          <Link className={s.linkItem} to={item.link} key={item.title} target={item.target}>
            <div className={s.menuItem} key={item.link}>
              <FontAwesomeIcon icon={item.icon} color="#ea8f12" />
              <Typography bold>{item.title}</Typography>
              {item?.title === 'Medlemsportalen' && <FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
            </div>
          </Link>
        ))}
      <div className={s.menuItem} onClick={handleLogout}>
        <FontAwesomeIcon icon={faLock} color="#ea8f12" />
        <div className={s.linkItem}>
          <Typography bold>Logga ut</Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(MinSidaMenu);
