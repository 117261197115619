import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import produce from 'immer';
import { useCallback, useRef } from 'react';
import useCookData from './useCookData';
import useFeatureFlags from './useFeatureFlags';
import useAuth from './useAuth';
import useGlobalState from '../../zustand/zustand';

const normalizeUserData = data => {
  if (!data) {
    return undefined;
  }

  const activeCompanyId = data?.preferencesPayload?.preferences?.activeCompanyId;
  const companies = data?.userData?.companies
    ?.map(company => ({
      id: company.id,
      name: company.name,
      legalName: company.legalName,
      displayName: company.displayName,
      memberships: company.memberships,
      hasReportedMuThisYear: company.hasReportedMuThisYear,
      overdueInvoices: company.overdueInvoices,
      roles: company?.roles,
    }))
    ?.sort((a, b) => a.name.localeCompare(b.name));

  const activeCompany = companies?.find(company => company.id === activeCompanyId);

  return {
    ...data,
    userData: {
      ...data.userData,
      activeCompany,
      companies,
    },
  };
};

const getUser = async idToken => {
  const { data } = await axios.post(
    '/user/me',
    { idToken, forceExpired: window.forceExpiredUser },
    { withCredentials: true }
  );

  // return normalizeUserData(data);
  return {
    preferencesPayload: data.preferencesPayload,
    userData: data.userData,
  };
};

const getExtraCompanyData = async activeCompanyId => {
  const { data } = await axios.post(
    '/user/extra-company-data',
    { activeCompanyId },
    { withCredentials: true }
  );

  return data;
};

const getCompanyInvoiceData = async activeCompanyId => {
  const { data } = await axios.post(
    '/user/company-invoice-data',
    { activeCompanyId },
    { withCredentials: true }
  );

  return data;
};

const updateUserPreferences = async newPreferences => {
  const { data } = await axios.put('/user/preferences', newPreferences, { withCredentials: true });
  return data;
};

const useUser = () => {
  const cookData = useCookData();
  const prevUserDataRef = useRef(null);
  const queryClient = useQueryClient();
  const featureFlags = useFeatureFlags();
  const { logout } = useAuth();
  const shouldLogout = useRef(false);
  const setUserPreferencesIsLocked = useCallback(
    useGlobalState(state => state.userPreferencesLock.setIsLocked),
    []
  );
  const setCompanyDataIsLoading = useGlobalState(state => state.companyData.setIsLoading);
  const setCompanyExtraDataIsLoading = useGlobalState(state => state.companyExtraData.setIsLoading);
  const setCompanyInvoiceDataIsLoading = useGlobalState(state => state.companyInvoiceData.setIsLoading);
  const setUserPreferencesIsLoading = useGlobalState(state => state.userPreferences.setIsLoading);

  if (featureFlags.disablePremiumContent) {
    return {
      userQuery: { data: {} },
      updateUserActiveCompanyMutation: {},
    };
  }

  const acquireTokenSilentQueryData = queryClient.getQueryData('acquireTokenSilent');
  const authQueryData = queryClient.getQueryData('auth');

  const userQueryForExtraCompanyDetails = useQuery(
    'extraCompanyDetails',
    () => {
      setCompanyExtraDataIsLoading(true);
      const activeCompanyId = prevUserDataRef.current?.userData?.activeCompany?.id;
      if (activeCompanyId) {
        return getExtraCompanyData(activeCompanyId).catch(error => {
          throw error;
        });
      }
      return null;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: !!acquireTokenSilentQueryData && !!authQueryData && !!prevUserDataRef.current,
      onSettled: () => {
        setCompanyExtraDataIsLoading(false);
      },
    }
  );

  const userQueryForCompanyInvoices = useQuery(
    'companyInvoiceData',
    () => {
      setCompanyInvoiceDataIsLoading(true);
      const activeCompanyId = prevUserDataRef.current?.userData?.activeCompany?.id;
      if (activeCompanyId) {
        return getCompanyInvoiceData(activeCompanyId).catch(error => {
          throw error;
        });
      }
      return null;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: !!acquireTokenSilentQueryData && !!authQueryData && !!prevUserDataRef.current,
      onSettled: () => {
        setCompanyInvoiceDataIsLoading(false);
      },
    }
  );

  const userQuery = useQuery(
    'user',
    () => {
      setUserPreferencesIsLocked(true);
      setCompanyDataIsLoading(true);
      return getUser(acquireTokenSilentQueryData).catch(error => {
        shouldLogout.current = true;
        throw error;
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: !!acquireTokenSilentQueryData && !!authQueryData,
      staleTime: 1000 * 30, // 30 seconds
      select: data => normalizeUserData(data),
      onSuccess: data => {
        if (!prevUserDataRef.current?.userData?.role !== data?.userData?.role) {
          cookData.refetchDataIfConditionsMet();
        }
        prevUserDataRef.current = data;
      },
      onError: () => {
        if (shouldLogout.current) {
          logout();
          shouldLogout.current = false;
        }
      },
      onSettled: () => {
        setUserPreferencesIsLocked(false);
        setCompanyDataIsLoading(false);
      },
    }
  );

  const updateUserActiveCompanyMutation = useMutation(
    newCompanyId => {
      setUserPreferencesIsLocked(true);
      setUserPreferencesIsLoading(true);
      const userData = queryClient.getQueryData('user');
      const newUserData = produce(userData, draft => {
        // eslint-disable-next-line no-param-reassign
        draft.preferencesPayload.preferences.activeCompanyId = newCompanyId;
      });
      return updateUserPreferences(newUserData.preferencesPayload);
    },
    {
      onMutate: async newCompanyId => {
        await queryClient.cancelQueries('user');
        const prevUserData = queryClient.getQueryData('user');

        queryClient.setQueryData('user', oldUserData =>
          produce(oldUserData, draft => {
            // eslint-disable-next-line no-param-reassign
            draft.preferencesPayload.preferences.activeCompanyId = newCompanyId;
          })
        );

        return { prevUserData };
      },
      retry: (failureCount, error) => {
        if (error?.response?.status === 500) {
          queryClient.invalidateQueries('auth');
        }

        return failureCount <= 3;
      },
      onError: (err, newCompanyId, context) => {
        queryClient.setQueryData('user', context.prevUserData);
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('extraCompanyDetails');
        queryClient.invalidateQueries('companyInvoiceData');
        setUserPreferencesIsLocked(false);
        setUserPreferencesIsLoading(false);
      },
    }
  );

  const updateUserHiddenNotificationsMutation = useMutation(
    notifications => {
      setUserPreferencesIsLocked(true);
      const userData = queryClient.getQueryData('user');
      const newUserData = produce(userData, draft => {
        // eslint-disable-next-line no-param-reassign
        draft.preferencesPayload.preferences.hiddenNotifications = notifications;
      });
      return updateUserPreferences(newUserData.preferencesPayload);
    },
    {
      onMutate: async notifications => {
        await queryClient.cancelQueries('user');
        const prevUserData = queryClient.getQueryData('user');

        queryClient.setQueryData('user', oldUserData =>
          produce(oldUserData, draft => {
            // eslint-disable-next-line no-param-reassign
            draft.preferencesPayload.preferences.hiddenNotifications = notifications;
          })
        );

        return { prevUserData };
      },
      retry: (failureCount, error) => {
        if (error?.response?.status === 500) {
          queryClient.invalidateQueries('auth');
        }

        return failureCount <= 3;
      },
      onError: (err, newCompanyId, context) => {
        queryClient.setQueryData('user', context.prevUserData);
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        setUserPreferencesIsLocked(false);
      },
    }
  );

  return {
    userQuery,
    userQueryForExtraCompanyDetails,
    userQueryForCompanyInvoices,
    updateUserActiveCompanyMutation,
    updateUserHiddenNotificationsMutation,
  };
};

export default useUser;
