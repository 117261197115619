/* eslint-disable css-modules/no-undef-class */

/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import BecomeMember from 'components/organisms/BecomeMember';
import InDepthList from 'components/molecules/InDepthList';
import InDepthItem from 'components/molecules/InDepthItem';
import renderStorylineElement from 'utils/renderStorylineElement';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import boostrapGrid from 'style/bootstrap-grid.css';
import classnames from 'classnames';
import s from './BecomeMemberPage.css';

// eslint-disable-next-line no-unused-vars
const BecomeMemberPage = ({ pageData, groupedStorylineTree, memberOrganisations }) => (
  <div className={s.becomeMemberPage}>
    <div className={classnames(boostrapGrid.container)}>
      <div className={classnames(boostrapGrid.row)}>
        {pageData?.leftMenu?.enabled && (
          <div className={getBootstrapColumns('0,0', '0,0', '4,1')}>
            <div className={s.leftMenu}>
              <Typography variant="caption" classes={s.text}>
                {pageData.leftMenu.title}
              </Typography>
              <InDepthList>
                {pageData.leftMenu.items?.map(item => {
                  return (
                    <InDepthItem key={item.id} classes={s.leftMenuIndepthItem} href={item.href}>
                      <Typography
                        varaint="caption"
                        component="span"
                        classes={classnames(s.leftMenuItem, item.id === 3 && s.selected)}
                      >
                        {item.linkText}
                      </Typography>
                    </InDepthItem>
                  );
                })}
              </InDepthList>
            </div>
          </div>
        )}
        <div className={getBootstrapColumns('22,1', '22,1', pageData?.leftMenu?.enabled ? '12,2' : '12,6')}>
          {groupedStorylineTree?.headGroup.map(el => renderStorylineElement(el, false))}
          {groupedStorylineTree?.contentGroup.map(el => renderStorylineElement(el, false))}
          <BecomeMember memberOrganisations={memberOrganisations} />
        </div>
      </div>
    </div>
  </div>
);

export default withStyles(s, boostrapGrid)(BecomeMemberPage);
