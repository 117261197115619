import React, { useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { motion, AnimatePresence } from 'framer-motion';
import useGlobalState from '../../../../zustand/zustand';
import Portal from '../../../atoms/Portal/Portal';
import MobileMainMenu from '../MobileMainMenu/MobileMainMenu';
import MobileExtraMenu from '../MobileExtraMenu/MobileExtraMenu';
import s from './MobileMenu.css';
import MobileUserListItem from '../UserSpecifics/MobileUserListItem/MobileUserListItem';

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useGlobalState(state => [
    state.pageHeader.mobileMenu.isOpen,
    state.pageHeader.mobileMenu.setIsOpen,
  ]);

  const [isLoggedInMenuOpen] = useGlobalState(state => [
    state.pageHeader.mobileLoggedInMenu.isOpen,
    state.pageHeader.mobileLoggedInMenu.setIsOpen,
  ]);

  const setHeight = () => {
    setTimeout(() => {
      const notificationsBar = document.getElementById('notification-bar');
      const menu = document.getElementById('mobile-menu');
      if (notificationsBar && menu) {
        const notificationsBarHeight = notificationsBar.offsetHeight;
        menu.style.top = `${notificationsBarHeight + 100}px`;
      }
      if (!notificationsBar && menu) {
        menu.style.top = `${100}px`;
      }
    }, 100);
  };

  useEffect(() => {
    if (isLoggedInMenuOpen || isOpen) {
      setHeight();
    }
  }, [isLoggedInMenuOpen, isOpen]);

  useEffect(() => {
    if (isOpen) {
      const handleKeyDown = e => {
        if (e.key === 'Escape') {
          setIsOpen(false);
        }
      };

      document.addEventListener('keydown', handleKeyDown, { once: true });
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {(isOpen || isLoggedInMenuOpen) && (
        <Portal>
          <motion.div
            id="mobile-menu"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`${s.mobileMenu} ${isLoggedInMenuOpen ? s.mobileMenuLoggedInMenu : ''}`}
          >
            {isLoggedInMenuOpen && <MobileUserListItem />}
            {isOpen && (
              <>
                <MobileMainMenu />
                <MobileExtraMenu />
              </>
            )}
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  );
};

export default withStyles(s)(MobileMenu);
