import { connect } from 'react-redux';
import ContactUsPage from 'components/pages/ContactUsPage';
import getContactItems from 'selectors/getContactItems';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getFaqItems from 'selectors/getFaqItems';

const mapStateToProps = state => {
  return {
    contactItems: getContactItems(state),
    faqItems: getFaqItems(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
  };
};

const mapDispatchToProps = undefined;

const ContactUsPageContainer = connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);

export default ContactUsPageContainer;
