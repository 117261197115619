import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SearchField.css';

const SearchField = ({
  wrapperClasses,
  classes,
  gutterBottom,
  register,
  inputRef,
  spotlightMode,
  ...other
}) => {
  const wrapperClassNames = classnames(s.input, wrapperClasses);
  const classNames = classnames(
    s.searchField,
    spotlightMode && s.spotlightMode,
    gutterBottom && s.gutterBottom,
    classes
  );
  return (
    <div className={wrapperClassNames}>
      <input className={classNames} type="text" ref={register || inputRef} {...other} />
    </div>
  );
};

export default withStyles(s)(SearchField);
