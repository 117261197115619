// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ContactUsPage-contactUsPage-jV-Gh .ContactUsPage-contactList-1PQaK{padding:0 .75rem 2.5rem 2.5rem}.ContactUsPage-faqListBg-3CjZY{background-color:#eff2f5;padding-bottom:36px;padding-top:8px}@media (min-width:541px){.ContactUsPage-contactUsPage-jV-Gh .ContactUsPage-contactList-1PQaK{padding:0 0 3rem}.ContactUsPage-faqListBg-3CjZY{padding-bottom:54px;padding-top:16px}}@media (min-width:820px){.ContactUsPage-contactUsPage-jV-Gh .ContactUsPage-contactList-1PQaK{padding:0 0 5rem}.ContactUsPage-faqListBg-3CjZY{padding-bottom:80px;padding-top:36px}}.ContactUsPage-contactUsPage-jV-Gh .ContactUsPage-contactList-1PQaK .ContactUsPage-contatcItemsWrapper-2lUyQ{border:none;border-radius:2px;margin-top:2.5rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"contactUsPage": "ContactUsPage-contactUsPage-jV-Gh",
	"contactList": "ContactUsPage-contactList-1PQaK",
	"faqListBg": "ContactUsPage-faqListBg-3CjZY",
	"contatcItemsWrapper": "ContactUsPage-contatcItemsWrapper-2lUyQ"
};
module.exports = exports;
