import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useForm } from 'react-hook-form';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import Typography from 'components/atoms/Typography';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import TextField from 'components/molecules/TextField';
import SelectField from 'components/molecules/SelectField';
import OrderCount from 'components/molecules/OrderCount';
import FormSuccess from 'components/molecules/FormSuccess';
import FormError from 'components/molecules/FormError';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import Checkbox from 'components/atoms/Checkbox';
import pushToDataLayer from 'utils/pushToDataLayer';
import getImages from 'utils/getImages';
import postForm from 'utils/postForm';
import generateSizes from 'utils/generateSizes';
import countries from 'utils/countries';
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './OrderReport.css';

const renameMap = {
  organization: 'companyName',
  fname: 'firstName',
  lname: 'lastName',
  tel: 'phone',
  'postal-code': 'postalCode',
  'street-address': 'address',
  'address-level1': 'city',
  'country-name': 'countryName',
};

const mapFormNamesToKeys = data =>
  Object.keys(data).reduce((acc, val) => {
    acc[renameMap[val] || val] = data[val];
    return acc;
  }, {});

const OrderReport = ({
  classes,
  reportId,
  reportTitle,
  webshopArticleNumber,
  imageSrc,
  imageAlt,
  closeModal,
  hostname,
}) => {
  const [reportCount, setReportCount] = useState(1);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [postingOrder, setPostingOrder] = useState({
    order: undefined,
    posting: false,
    status: undefined,
  });
  useEffect(() => {
    if (postingOrder.order) {
      const sendToGTM = status => {
        const orderDate = new Date().toISOString();
        pushToDataLayer('webshopSubmit', {
          reportTitle: `${reportTitle} (${postingOrder.order.reportId})`,
          completeOrderStatus: status,
        });
        pushToDataLayer(null, {
          ecommerce: null,
        });
        pushToDataLayer('purchase', {
          ecommerce: {
            transaction_id: `T_${postingOrder.order.reportId}_${orderDate}`,
            orderStatus: status,
            items: [
              {
                item_id: postingOrder.order.reportId,
                item_name: `${reportTitle} (${postingOrder.order.reportId})`,
                quantity: reportCount,
              },
            ],
          },
        });
      };

      postForm(`${hostname}/backoffice/public/webshop/order/`, mapFormNamesToKeys(postingOrder.order))
        .then(data => {
          if (data.status === 'ok') {
            setPostingOrder({
              ...postingOrder,
              posting: false,
              status: 'complete',
            });

            sendToGTM('completeOrder - lyckad');
          } else {
            console.error(data);
            setPostingOrder({
              ...postingOrder,
              posting: false,
              status: 'error',
            });

            sendToGTM('completeOrder - misslyckad');
          }
        })
        .catch(err => {
          console.error(err);
          setPostingOrder({
            ...postingOrder,
            posting: false,
            status: 'error',
          });

          sendToGTM('completeOrder - misslyckad');
        });
    }
  }, [postingOrder.order]);
  const { register, handleSubmit, errors } = useForm();
  const classNames = classnames(s.orderReport, classes);
  const sizes = getImages('PORTRAIT');
  const imageSizes = generateSizes({ default: '83px' });

  const increment = () => setReportCount(reportCount + 1);
  const decrement = () => setReportCount(reportCount > 1 ? reportCount - 1 : reportCount);
  const onChange = e => {
    const val = parseInt(e.target.value, 10);
    setReportCount(Number.isNaN(val) ? 0 : val);
  };

  const postOrder = data => {
    setPostingOrder({
      order: data,
      posting: true,
      status: undefined,
    });
  };

  return (
    <div className={classNames}>
      <div className={s.headline}>Beställ trycksak</div>
      {postingOrder.status === 'complete' ? (
        <div>
          <FormSuccess headline="Tack för beställning!" text="Ordern skickas till dig när den är klar." />
          <ButtonSecondaryGhost classes={s.orderButton} onClick={closeModal}>
            Stäng
          </ButtonSecondaryGhost>
        </div>
      ) : (
        <form autoComplete="on" onSubmit={handleSubmit(postOrder)}>
          <div className={classnames(boostrapGrid.row, s.imageRelative)}>
            <div className={classnames(getBootstrapColumns('24', '12', '12'), s.leftColumn)}>
              <div className={s.subHeadline}>Din beställning</div>
              <div className={s.yourOrderContainer}>
                <Typography variant="caption" component="div" gutterBottom>
                  RAPPORT
                </Typography>
                <input
                  type="text"
                  name="reportId"
                  ref={register({ required: true })}
                  className={s.hidden}
                  defaultValue={reportId}
                />
                <input
                  type="text"
                  name="reportTitle"
                  ref={register({ required: true })}
                  className={s.hidden}
                  defaultValue={reportTitle}
                />
                <input
                  type="text"
                  name="webshopArticleNumber"
                  ref={register({ required: true })}
                  className={s.hidden}
                  defaultValue={webshopArticleNumber}
                />
                <div className={s.reportDescriptionWrapper}>
                  <Typography component="div">{reportTitle}</Typography>
                  <div className={s.reportImageWrapper}>
                    <ResponsiveImage
                      src={sizes.toSrc(imageSrc, 'S')}
                      srcSet={sizes.toSrcSet(imageSrc)}
                      sizes={imageSizes}
                      alt={imageAlt}
                    />
                  </div>
                </div>
                <hr className={s.lineBreak} />
                <div className={s.orders}>
                  <OrderCount
                    id="quantity"
                    label="ANTAL"
                    value={reportCount}
                    onIncrement={increment}
                    onDecrement={decrement}
                    onChange={onChange}
                    register={register()}
                  />
                </div>
              </div>
            </div>
            <div className={classnames(getBootstrapColumns('24', '12', '12'), s.rightColumn)}>
              <div className={s.subHeadline}>Fyll i dina uppgifter</div>
              <TextField id="organization" label="Företag" register={register()} />
              <div className={s.namesWrapper}>
                <TextField
                  id="fname"
                  label="Namn"
                  classes={s.name}
                  errorMessage={errors?.fname?.message}
                  register={register({ required: 'Namn saknas' })}
                />
                <TextField
                  id="lname"
                  label="Efternamn"
                  classes={s.surname}
                  errorMessage={errors?.lname?.message}
                  register={register({ required: 'Efternamn saknas' })}
                />
              </div>
              <TextField
                id="email"
                label="E-post"
                type="email"
                errorMessage={errors?.email?.message}
                register={register({
                  required: 'E-post saknas',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Ogiltig e-postadress',
                  },
                })}
              />
              <TextField
                id="tel"
                label="Telefonnummer"
                errorMessage={errors?.tel?.message}
                register={register({
                  pattern: {
                    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
                    message: 'Ogiltigt telefonnummer',
                  },
                })}
              />
              <TextField
                id="street-address"
                label="Adress"
                errorMessage={errors?.['street-address']?.message}
                register={register({ required: 'Adress saknas' })}
              />
              <div className={s.cityWrapper}>
                <TextField
                  id="postal-code"
                  label="Postnummer"
                  classes={s.postalNr}
                  errorMessage={errors?.['postal-code']?.message}
                  register={register({
                    required: 'Postnummer saknas',
                    pattern: {
                      value: /\s*(?:[0-9.]\s*){5,}$/,
                      message: 'Felaktigt postnummer',
                    },
                  })}
                />
                <TextField
                  id="address-level1"
                  label="Postort"
                  classes={s.city}
                  errorMessage={errors?.['address-level1']?.message}
                  register={register({ required: 'Postort saknas' })}
                />
              </div>
              <SelectField
                id="country"
                name="country-name"
                label="Land"
                defaultValue="Sverige"
                register={register()}
              >
                {countries.map(country => (
                  <option key={country.id} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </SelectField>
              <Checkbox
                id="terms"
                label={
                  <>
                    Jag godkänner Svenskt Näringslivs hantering av{' '}
                    <Typography
                      variant="link"
                      component="a"
                      href="https://www.svensktnaringsliv.se/dataskydd/integritet-och-behandling-av-personuppgifter-i-digitala-kanaler_1010031.html"
                      target="_blank"
                    >
                      personuppgifter
                    </Typography>
                    .
                  </>
                }
                checked={termsAccepted}
                onChange={e => setTermsAccepted(e.target.checked)}
                gutterBottom
              />
              {postingOrder.status === 'error' && (
                <FormError
                  classes={s.errorBox}
                  headline="Kunde inte skicka order"
                  text="Något gick fel i beställningen av rapporten, kontrollera så alla fält är korrekt ifyllda eller försök igen."
                />
              )}
              <ButtonPrimaryAlt
                type="submit"
                classes={s.submitButton}
                disabled={
                  !termsAccepted || Number.isNaN(reportCount) || reportCount < 1 || postingOrder.posting
                }
              >
                {postingOrder.posting && <div className={s.ldsDualRing} />}
                {postingOrder.posting ? 'Skickar beställning...' : 'Skicka beställning'}
              </ButtonPrimaryAlt>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default withStyles(s, boostrapGrid)(OrderReport);
