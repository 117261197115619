import { Component } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import normalizeCss from 'normalize.css';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Layout.css';

class Layout extends Component {
  componentDidMount() {
    const { darkMode } = this.props;
    if (darkMode) {
      document.body.classList.add('dark-mode');
    }
  }

  componentDidUpdate(prevProps) {
    const { darkMode } = this.props;
    if (prevProps.darkMode !== darkMode) {
      if (darkMode) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withStyles(normalizeCss, s)(Layout);
