// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ColumnStatistik-link-75vqh{text-decoration:underline}.ColumnStatistik-link-75vqh:hover{cursor:pointer}.ColumnStatistik-linkItem-6qSeU{margin-bottom:10px}", ""]);
// Exports
exports.locals = {
	"link": "ColumnStatistik-link-75vqh",
	"linkItem": "ColumnStatistik-linkItem-6qSeU"
};
module.exports = exports;
