import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import SearchField from 'components/atoms/SearchField';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SearchBar.css';
import useTranslate from '../../../utils/hooks/useTranslate';

const SearchBar = ({
  classes,
  dark,
  placeholder,
  searchTerm,
  handleChange,
  searchButton,
  focusOnMounted,
  spotlightMode,
  noBorder,
  ...other
}) => {
  const translate = useTranslate();
  const inputRef = useRef(null);
  useEffect(() => {
    if (focusOnMounted) {
      inputRef.current.focus();
    }
  }, []);
  const classNames = classnames(
    s.searchBar,
    dark && s.dark,
    spotlightMode && s.spotlightMode,
    searchButton && s.hasSearchButton,
    noBorder && s.noBorder,
    classes
  );
  return (
    <form className={classNames} {...other}>
      <div className={s.iconWrapper}>
        <FontAwesomeIcon icon={faSearch} className={s.icon} />
      </div>
      <SearchField
        wrapperClasses={s.searchFieldWrapper}
        classes={s.searchField}
        id="searchField"
        name="searchField"
        placeholder={placeholder}
        onChange={handleChange}
        value={searchTerm}
        inputRef={focusOnMounted ? inputRef : undefined}
        spotlightMode={spotlightMode}
      />
      {searchButton && <input type="submit" className={s.searchButton} value={translate('SEARCH_BAR')} />}
    </form>
  );
};

export default withStyles(s)(SearchBar);
