import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import getElementsInArticle from '../utils/getElementsInArticle';
import getGridParentFields from '../utils/getGridParentFields';
import getAvsnittOptions from '../utils/getAvsnittOptions';

export const getMetadata = createSelector(
  cookSelectors.getData,
  locationSelectors.getHostname,
  locationSelectors.getFullPath,
  (data, hostname, fullPath) => {
    // Bilder för facebook
    const imageSrc1 =
      data?.context?.deskedContent?.sectionInfo?.[0]?.content?.ogImage?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full?.replace(
        'BASE_LANDSCAPE',
        'BASE_FACEBOOK'
      );

    // Bild i puff
    const imageSrc2 =
      data?.context?.deskedContent?.sectionInfo?.[0]?.content?.ogImage2?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full?.replace(
        'BASE_LANDSCAPE',
        'BASE_FACEBOOK'
      );

    const defaultImageSrc = `${hostname}/fb-share-svensktnaringsliv.png`;

    const ogImage = {
      src: imageSrc1 || imageSrc2 || defaultImageSrc,
      width: imageSrc1 || imageSrc2 ? 1200 : 200,
      height: imageSrc1 || imageSrc2 ? 628 : 200,
    };

    return {
      headline:
        data?.context?.deskedContent?.sectionInfo?.[0]?.content?.fields?.openGraphTitle ||
        data?.context?.deskedContent?.sectionInfo?.[0]?.content?.title ||
        data?.context?.title,
      href: fullPath,
      description: data?.context?.deskedContent?.sectionInfo?.[0]?.content?.fields?.openGraphDescription,
      metaDescription: data?.context?.deskedContent?.sectionInfo?.[0]?.content?.fields?.seoMetaDescription,
      image: ogImage.src,
      imageWidth: ogImage.width,
      imageHeight: ogImage.height,
    };
  }
);

const getDeskedContent = createSelector(cookSelectors.getData, data => {
  return data?.context?.deskedContent?.content;
});

const getDeskedHero = createSelector(cookSelectors.getData, data => {
  return data?.context?.deskedContent?.hero;
});

const DESKED_CONTENT_MAP = {
  hero: getDeskedHero,
  content: getDeskedContent,
  DEFAULT: () => [],
};

export const getFlexibleSection = deskedContentKey => {
  return createSelector(
    DESKED_CONTENT_MAP[deskedContentKey] ?? DESKED_CONTENT_MAP.DEFAULT,
    locationSelectors.getHostname,
    (deskedContentArray, hostname) => {
      const allAvsnitt = (deskedContentArray ?? [])
        .filter(avsnitt => avsnitt.type === 'segment')
        .map(avsnitt => {
          const avsnittOptions = getAvsnittOptions(avsnitt);
          const avsnittTextColor = avsnittOptions?.textColor ?? 'dark';

          const allSegmentElements = (avsnitt?.segmentContent ?? [])
            .flatMap(x => x.content?.elements)
            .filter(Boolean);

          const gridParents = (avsnitt.segmentContent ?? [])
            .filter(article => article.content.contentType === 'grid')
            .filter(article => article?.content?.elements?.length > 0)
            .map(article => {
              const elementsInArticle = getElementsInArticle(
                article.content.storyline,
                article.content.elements,
                avsnittTextColor,
                allSegmentElements,
                hostname
              );

              return {
                ...article.content,
                fields: getGridParentFields(article.content.fields),
                elements: elementsInArticle,
              };
            });

          return {
            avsnittOptions,
            gridParents,
          };
        })
        // Add id and contentKey
        .map((x, i) => ({ ...x, id: `${deskedContentKey}-${i + 1}`, contentKey: deskedContentKey }));

      return allAvsnitt;
    }
  );
};
