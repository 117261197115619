// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FooterMoreNews-footerMoreNews-2vKV5{color:#fff;font-family:LabGrotesque-Regular,sans-serif;font-size:14px;line-height:20px;max-width:373px}.FooterMoreNews-footerMoreNews-2vKV5 .FooterMoreNews-headline-2U-0O{color:#fff;font-size:16px;line-height:24px;margin-bottom:16px}.FooterMoreNews-footerMoreNews-2vKV5 .FooterMoreNews-bold-2vUOH,.FooterMoreNews-footerMoreNews-2vKV5 .FooterMoreNews-headline-2U-0O{font-family:LabGrotesque-Bold,sans-serif}.FooterMoreNews-footerMoreNews-2vKV5 .FooterMoreNews-rightWrapper-36z3P{display:-ms-flexbox;display:flex}.FooterMoreNews-footerMoreNews-2vKV5 .FooterMoreNews-rightWrapper-36z3P .FooterMoreNews-imageWrapper-31Lkc{margin-right:16px}.FooterMoreNews-footerMoreNews-2vKV5 a.FooterMoreNews-footerLink-vuHKf{color:#fff;display:block;line-height:23px;margin-top:10px;text-decoration:underline;-webkit-transition:color .2s ease-in-out;transition:color .2s ease-in-out}.FooterMoreNews-footerMoreNews-2vKV5 a.FooterMoreNews-footerLink-vuHKf:hover{color:#cfd4d8;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"footerMoreNews": "FooterMoreNews-footerMoreNews-2vKV5",
	"headline": "FooterMoreNews-headline-2U-0O",
	"bold": "FooterMoreNews-bold-2vUOH",
	"rightWrapper": "FooterMoreNews-rightWrapper-36z3P",
	"imageWrapper": "FooterMoreNews-imageWrapper-31Lkc",
	"footerLink": "FooterMoreNews-footerLink-vuHKf"
};
module.exports = exports;
