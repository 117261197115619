// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HighlightedArticleLarge-highlightedArticleLarge-Dqbgv{display:block;margin-bottom:20px;text-decoration:none}.HighlightedArticleLarge-headline-2Gf2k{display:none}.HighlightedArticleLarge-contentType-1XsX0{text-transform:uppercase}.HighlightedArticleLarge-externalIcon-306xf{color:inherit;height:12px;margin:0 0 2px 8px;width:12px}.HighlightedArticleLarge-highlightedArticle-1W4dg:hover .HighlightedArticleLarge-headlineMobile-24SI5,.HighlightedArticleLarge-highlightedArticleLarge-Dqbgv:hover .HighlightedArticleLarge-headline-2Gf2k{text-decoration:underline}.HighlightedArticleLarge-highlightedArticleLarge-Dqbgv .HighlightedArticleLarge-highlightedArticleLargeImageWrapper-1u9aB{margin-left:-24px;margin-right:-24px}.HighlightedArticleLarge-paragraphLargeFix-mjU8d{margin-top:-10px!important}@media (min-width:541px){.HighlightedArticleLarge-highlightedArticleLarge-Dqbgv{margin-bottom:32px}.HighlightedArticleLarge-headline-2Gf2k{display:block}.HighlightedArticleLarge-headlineMobile-24SI5{display:none}.HighlightedArticleLarge-highlightedArticleLarge-Dqbgv .HighlightedArticleLarge-highlightedArticleLargeImageWrapper-1u9aB{margin:0}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"highlightedArticleLarge": "HighlightedArticleLarge-highlightedArticleLarge-Dqbgv",
	"headline": "HighlightedArticleLarge-headline-2Gf2k",
	"contentType": "HighlightedArticleLarge-contentType-1XsX0",
	"externalIcon": "HighlightedArticleLarge-externalIcon-306xf",
	"highlightedArticle": "HighlightedArticleLarge-highlightedArticle-1W4dg",
	"headlineMobile": "HighlightedArticleLarge-headlineMobile-24SI5",
	"highlightedArticleLargeImageWrapper": "HighlightedArticleLarge-highlightedArticleLargeImageWrapper-1u9aB",
	"paragraphLargeFix": "HighlightedArticleLarge-paragraphLargeFix-mjU8d"
};
module.exports = exports;
