// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonPrimaryAlt-buttonPrimaryAlt-20wrR{background-color:#ea8f12;border:none;border-radius:2px;color:#fff;cursor:pointer;display:block;font-family:LabGrotesque-Bold,sans-serif;text-align:center;text-decoration:none;-webkit-transition:background-color .2s ease-in-out;transition:background-color .2s ease-in-out}.ButtonPrimaryAlt-buttonPrimaryAlt-20wrR.ButtonPrimaryAlt-sm-3sOkp{padding:8px 16px}.ButtonPrimaryAlt-buttonPrimaryAlt-20wrR.ButtonPrimaryAlt-md-28qmB{padding:16px 32px}.ButtonPrimaryAlt-buttonPrimaryAlt-20wrR:hover{background:#efa033}.ButtonPrimaryAlt-buttonPrimaryAlt-20wrR:active{background:#c6790f}.ButtonPrimaryAlt-buttonPrimaryAlt-20wrR:disabled,.ButtonPrimaryAlt-buttonPrimaryAlt-20wrR:disabled:active,.ButtonPrimaryAlt-buttonPrimaryAlt-20wrR:disabled:hover{background-color:#a0a0a0;color:#f1f1f1;cursor:default}.ButtonPrimaryAlt-buttonPrimaryAlt-20wrR.ButtonPrimaryAlt-block-RJLO9{width:100%}.ButtonPrimaryAlt-buttonPrimaryAlt-20wrR.ButtonPrimaryAlt-gutterBottom-t7T7X{margin-bottom:1rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"buttonPrimaryAlt": "ButtonPrimaryAlt-buttonPrimaryAlt-20wrR",
	"sm": "ButtonPrimaryAlt-sm-3sOkp",
	"md": "ButtonPrimaryAlt-md-28qmB",
	"block": "ButtonPrimaryAlt-block-RJLO9",
	"gutterBottom": "ButtonPrimaryAlt-gutterBottom-t7T7X"
};
module.exports = exports;
