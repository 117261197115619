/* eslint-disable dot-notation */
import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import extractImages from 'utils/extractImages';

const arrayToObject = arr =>
  arr.reduce((acc, val) => {
    acc[val.key] = val.value;
    return acc;
  }, {});

const extractMemberOrganization = rawOrganization => {
  const teaserImage = rawOrganization?.content?.teaserImage;
  return {
    id: rawOrganization?.content?.id,
    name: rawOrganization.content?.title,
    teaser: rawOrganization?.content?.fields?.teaser,
    website: rawOrganization?.content?.fields?.website,
    image: teaserImage && extractImages(teaserImage),
  };
};

export default createSelector(cookSelectors.getData, data => {
  try {
    const memberOrganizations =
      data?.context?.deskedContent?.memberOrganisations ||
      data?.memberOrganizationsSection?.page?.deskedContent?.memberOrganisations;
    return memberOrganizations?.map(memberOrganization => {
      const group = {
        ...arrayToObject(memberOrganization.groupOptions),
        memberOrganizations: memberOrganization?.memberOrganisations?.map(extractMemberOrganization),
      };
      return group;
    });
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
