import { useEffect, useCallback, useRef } from 'react';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { getPathname } from '../../ducks/location/selectors';
import { loginRequest, msalConfig } from '../authConfig';
import getExpireDateFromToken from '../getExpireDateFromToken';
import useFeatureFlags from './useFeatureFlags';
import useGlobalState from '../../zustand/zustand';

const acquireTokenSilent = async msalInstance => {
  const account = msalInstance.getActiveAccount();
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account,
  });
  return response.idToken;
};

const getSnAuth = async idToken => {
  const { data } = await axios.post('/user/auth', { idToken, forceExpired: window.forceExpiredAuth });
  return {
    snToken: data.snToken,
    exp: data.exp,
    idToken: data.idToken,
  };
};

const useAuth = () => {
  const pathname = useSelector(getPathname);
  const [, setCookie] = useCookies();
  const queryClient = useQueryClient();
  const { instance } = useMsal();
  const activeAccountRef = useRef(useAccount());
  const isAuthenticated = useIsAuthenticated();
  const featureFlags = useFeatureFlags();
  const setAuthHasLoaded = useCallback(
    useGlobalState(state => state.auth.setIsLoaded),
    []
  );

  if (!instance.snContext) {
    instance.snContext = {
      ssoSilentRunning: null,
      activeAccount: null,
      shouldLogout: null,
    };
  }

  useEffect(() => {
    if (!instance.snContext.ssoSilentRunning && !instance.snContext.activeAccount) {
      instance.snContext.ssoSilentRunning = true;
      instance
        .ssoSilent({ authority: msalConfig.auth.authority })
        .then(ssoSilentResponse => {
          instance.snContext.activeAccount = ssoSilentResponse.account;
          activeAccountRef.current = ssoSilentResponse.account;
          if (ssoSilentResponse?.account?.idTokenClaims?.sub) {
            window.dataLayer.push({
              userId: ssoSilentResponse?.account?.idTokenClaims?.sub,
            });
          }
        })
        .catch(error => {
          if (!(error instanceof InteractionRequiredAuthError)) {
            console.error('sso silent - error:', error);
          }
        })
        .finally(() => {
          setAuthHasLoaded(true);
          instance.snContext.ssoSilentRunning = false;
        });
    }
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries('acquireTokenSilent');
  }, [pathname]);

  if (featureFlags.disablePremiumContent) {
    return {
      acquireTokenSilentQuery: { data: {} },
      authQuery: { data: {} },
      login: () => {},
      logout: () => {},
      isAuthenticated: false,
    };
  }

  const login = useCallback(async () => {
    try {
      await instance.loginRedirect({
        ...loginRequest,
        state: window.location.pathname, // include the current page's URL as the state parameter
      });
    } catch (e) {
      console.error('loginRedirect. error:', e);
    }
  }, []);

  const logout = useCallback(async () => {
    setCookie('sn-auth', '', { path: '/', expires: new Date(0) });
    try {
      await instance.logoutRedirect();
    } catch (e) {
      console.error('loginRedirect. error:', e);
    } finally {
      queryClient.setQueryData('auth', undefined);
      queryClient.setQueryData('acquireTokenSilent', undefined);
    }
  }, []);

  const acquireTokenSilentQuery = useQuery(
    'acquireTokenSilent',
    () =>
      acquireTokenSilent(instance).catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.snContext.shouldLogout = true;
        }
        console.error(error);
        throw error;
      }),
    {
      retry: 3,
      enabled: !!instance.snContext.activeAccount,
      refetchInterval: 5 * 60 * 1000,
      refetchIntervalInBackground: true,
      onSuccess: async idToken => {
        const expireDate = idToken ? await getExpireDateFromToken(idToken) : null;
        if (queryClient.getQueryData('auth')?.exp !== expireDate) {
          queryClient.invalidateQueries('auth');
        }
        setAuthHasLoaded(true);
      },
      onError: () => {
        if (instance.snContext.shouldLogout) {
          logout();
          instance.snContext.shouldLogout = false;
        }
      },
    }
  );

  const authQuery = useQuery(
    'auth',
    () =>
      getSnAuth(queryClient.getQueryData('acquireTokenSilent')).catch(error => {
        console.error(error);
        throw error;
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!acquireTokenSilentQuery.data,
      staleTime: 1000 * 30, // 30 seconds
      onError: () => {
        if (instance.snContext.shouldLogout) {
          logout();
          instance.snContext.shouldLogout = false;
        }
      },
    }
  );

  return {
    acquireTokenSilentQuery,
    authQuery,
    login,
    logout,
    isAuthenticated: isAuthenticated && !!authQuery?.data,
  };
};

export default useAuth;
