import { connect } from 'react-redux';
import FocusAreaPage from 'components/pages/FocusAreaPage';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getContactPersons from 'selectors/getContactPersons';
import getSelectedContent from 'selectors/getSelectedContent';
import getQuoteBoxData from 'selectors/getQuoteBoxData';
import getFocusAreaListItems from 'selectors/getFocusAreaListItems';
import getUpcomingEvents from 'selectors/getUpcomingEvents';
import getResolutionSection from 'selectors/getResolutionSection';
import getLatestWebinars from '../../selectors/getLatestWebinars';
import getLatestArticles from '../../selectors/getLatestArticles';
import getIssues from '../../selectors/getIssues';
import getPageId from '../../selectors/getPageId';

const mapStateToProps = state => {
  const selectedContent = getSelectedContent(state);

  return {
    groupedStorylineTree: getGroupedStorylineTree(state),
    contactPersons: getContactPersons(state),
    context: getIssues(state),
    firstSelectedContent: selectedContent && selectedContent.length > 0 && selectedContent[0],
    restSelectedContent: selectedContent && selectedContent.length > 0 && selectedContent.slice(1),
    quoteBoxData: getQuoteBoxData(state),
    focusAreaListItems: getFocusAreaListItems(state),
    upcomingEvents: getUpcomingEvents(state),
    latestWebinars: getLatestWebinars(state),
    latestArticles: getLatestArticles(state),
    pageid: getPageId(state),
    resolutionSection: getResolutionSection(state),
  };
};

const mapDispatchToProps = undefined;

const FocusAreaPageContainer = connect(mapStateToProps, mapDispatchToProps)(FocusAreaPage);

export default FocusAreaPageContainer;
