import { createSelector } from 'reselect';
import { selectors as locationSelectors } from 'ducks/location';

export default createSelector(locationSelectors.getPathname, pathname => {
  try {
    const queryString = pathname.split('?')[1];
    if (queryString) {
      const queries = queryString.split('&');
      const queryObj = queries.reduce((acc, val) => {
        const split = val.split('=');
        if (!acc[split[0]]) {
          acc[split[0]] = [];
        }

        acc[split[0]].push(split?.[1]);
        return acc;
      }, {});
      return queryObj;
    }
    return undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
});
