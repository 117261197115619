// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TvPage-videoBlock-27m4c{margin-top:1.75rem}.TvPage-videoCaption-2hrDl{margin-top:3rem}.TvPage-article_container-ZDMUl .TvPage-stickyContainer-3KCXy{position:relative}@media screen and (min-width:541px){.TvPage-article_container-ZDMUl .TvPage-stickyContainer-3KCXy{min-height:21rem}}.TvPage-alsoReadWrapper-1fcTv{background:#eff2f5;padding-bottom:24px;padding-top:40px}.TvPage-secondaryWrapper-gBMns{padding-left:0;padding-right:0}.TvPage-fadeOut-1ybGJ{opacity:0;-webkit-transition:visibility 0s .5s,opacity .5s linear;transition:visibility 0s .5s,opacity .5s linear;visibility:hidden}.TvPage-fadeIn-2a7CE{opacity:1;-webkit-transition:opacity .5s linear;transition:opacity .5s linear;visibility:visible}@media (min-width:541px){.TvPage-secondaryWrapper-gBMns{padding-left:56px;padding-right:56px}}@media (min-width:820px){.TvPage-secondaryWrapper-gBMns{padding-left:0;padding-right:0}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"videoBlock": "TvPage-videoBlock-27m4c",
	"videoCaption": "TvPage-videoCaption-2hrDl",
	"article_container": "TvPage-article_container-ZDMUl",
	"stickyContainer": "TvPage-stickyContainer-3KCXy",
	"alsoReadWrapper": "TvPage-alsoReadWrapper-1fcTv",
	"secondaryWrapper": "TvPage-secondaryWrapper-gBMns",
	"fadeOut": "TvPage-fadeOut-1ybGJ",
	"fadeIn": "TvPage-fadeIn-2a7CE"
};
module.exports = exports;
