import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import boostrapGrid from 'style/bootstrap-grid.css';
import HeroSectionBackground from './components/HeroSectionBackground';
import SectionBackground from './components/SectionBackground';
import Section from './components/Section/Section';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';
import s from './MicrositePage.css';

const MicrositePage = ({
  metadata,
  micrositeHeroBackground,
  micrositeHeroStoryline,
  micrositeSection,
  micrositeSectionStoryline,
}) => {
  let i = -1;

  return (
    <>
      <OGShareTags metadata={metadata} />
      <div className={s.micrositePage}>
        <div>
          <HeroSectionBackground
            beambackgroundColor={micrositeHeroBackground?.beambackgroundColor}
            src={micrositeHeroBackground?.src}
            srcTyp={micrositeHeroBackground?.srcTyp}
            isShowHeroBeam={micrositeHeroBackground?.isBeam}
            backgroundColor={micrositeHeroBackground?.backgroundColor}
            isShowHeroBeamCountDown={micrositeHeroBackground?.isCountDown}
            heroBeamCountDownDate={micrositeHeroBackground?.countDownDate}
            heroBeamText={micrositeHeroBackground?.text}
            heroBeamTextAfterCountDown={micrositeHeroBackground?.textAfterCountDown}
            height={micrositeHeroBackground?.height}
          >
            <Section
              SectionTyp="heroSection"
              box1={micrositeHeroStoryline?.box1}
              showOutlineLogo1={!!micrositeHeroStoryline?.showOutlineLogo1}
              textColorOne={micrositeHeroStoryline?.textColorOne}
              marginsOne={micrositeHeroStoryline?.marginsOne}
              verticalAlignmentOne={micrositeHeroStoryline?.verticalAlignmentOne}
              horizontalAlignmentOne={micrositeHeroStoryline?.horizontalAlignmentOne}
              backgroundColorOne={micrositeHeroStoryline?.backgroundColorOne}
              box2={micrositeHeroStoryline?.box2}
              showOutlineLogo2={!!micrositeHeroStoryline?.showOutlineLogo2}
              textColorTwo={micrositeHeroStoryline?.textColorTwo}
              backgroundColorTwo={micrositeHeroStoryline?.backgroundColorTwo}
              marginsTwo={micrositeHeroStoryline?.marginsTwo}
              verticalAlignmentTwo={micrositeHeroStoryline?.verticalAlignmentTwo}
              horizontalAlignmentTwo={micrositeHeroStoryline?.horizontalAlignmentTwo}
              width="default"
              tiltedTop={false}
              tiltedBottom={false}
              topMargin={micrositeHeroBackground?.topMargin}
              bottomMargin={micrositeHeroBackground?.bottomMargin}
              srcOne={micrositeHeroStoryline?.srcOne}
              srcTypOne={micrositeHeroStoryline?.srcTypeOne}
              srcTwo={micrositeHeroStoryline?.srcTwo}
              srcTypTwo={micrositeHeroStoryline?.srcTypeTwo}
              bgOpacityOne={micrositeHeroStoryline?.bgOpacityOne}
              bgOpacityTwo={micrositeHeroStoryline?.bgOpacityTwo}
            />
          </HeroSectionBackground>
        </div>
        <div>
          {micrositeSection?.items?.length > 0 && (
            <div>
              {micrositeSection?.items.map(item => {
                const tiltedTop = item.tiltedTop === 'true';
                const tiltedBottom = item.tiltedBottom === 'true';
                i++;
                const showOutlineLogo1 =
                  micrositeSectionStoryline.micrositeBoxs[i].showOutlineLogo1 === 'true';
                const showOutlineLogo2 =
                  micrositeSectionStoryline.micrositeBoxs[i].showOutlineLogo2 === 'true';

                return (
                  <SectionBackground
                    anchorTarget={item.anchorTarget}
                    key={i.toString()}
                    tiltedTop={tiltedTop}
                    tiltedBottom={tiltedBottom}
                    bgOpacity={item.bgOpacity}
                    backgroundTyp={item.background}
                    src={item.src}
                    srcTyp={item.srcType}
                    isShowOutlineLogo={item.showOutlineLogo === 'true'}
                  >
                    <Section
                      SectionTyp="section"
                      box1={micrositeSectionStoryline.micrositeBoxs[i].box1}
                      showOutlineLogo1={showOutlineLogo1}
                      textColorOne={micrositeSectionStoryline.micrositeBoxs[i].textColorOne}
                      marginsOne={micrositeSectionStoryline.micrositeBoxs[i].marginsOne}
                      verticalAlignmentOne={micrositeSectionStoryline.micrositeBoxs[i].verticalAlignmentOne}
                      horizontalAlignmentOne={
                        micrositeSectionStoryline.micrositeBoxs[i].horizontalAlignmentOne
                      }
                      backgroundColorOne={micrositeSectionStoryline.micrositeBoxs[i].backgroundColorOne}
                      box2={micrositeSectionStoryline.micrositeBoxs[i].box2}
                      showOutlineLogo2={showOutlineLogo2}
                      textColorTwo={micrositeSectionStoryline.micrositeBoxs[i].textColorTwo}
                      backgroundColorTwo={micrositeSectionStoryline.micrositeBoxs[i].backgroundColorTwo}
                      marginsTwo={micrositeSectionStoryline.micrositeBoxs[i].marginsTwo}
                      verticalAlignmentTwo={micrositeSectionStoryline.micrositeBoxs[i].verticalAlignmentTwo}
                      horizontalAlignmentTwo={
                        micrositeSectionStoryline.micrositeBoxs[i].horizontalAlignmentTwo
                      }
                      width={item.width}
                      tiltedTop={tiltedTop}
                      tiltedBottom={tiltedBottom}
                      topMargin={item.topMargin}
                      bottomMargin={item.bottomMargin}
                      srcOne={micrositeSectionStoryline.micrositeBoxs[i].srcOne}
                      srcTypOne={micrositeSectionStoryline.micrositeBoxs[i].srcTypeOne}
                      srcTwo={micrositeSectionStoryline.micrositeBoxs[i].srcTwo}
                      srcTypTwo={micrositeSectionStoryline.micrositeBoxs[i].srcTypeTwo}
                      bgOpacityOne={micrositeSectionStoryline.micrositeBoxs[i].bgOpacityOne}
                      bgOpacityTwo={micrositeSectionStoryline.micrositeBoxs[i].bgOpacityTwo}
                    />
                  </SectionBackground>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withStyles(s, boostrapGrid)(MicrositePage);
