import toPairs from 'lodash/toPairs';
import groupBy from 'lodash/groupBy';
import pickFrom from 'utils/pickFrom';

const fixUrls = pair => {
  return [
    pair[0],
    {
      ...pair[1],
      href_full: pair[1].href_full,
    },
  ];
};

const extractImages = teaserImageRel => {
  try {
    const fields = pickFrom(teaserImageRel, '[0].content.fields');
    if (fields) {
      const { alttext = '', ...otherFields } = fields;

      const images = toPairs(otherFields)
        .filter(pair => pair[1])
        .filter(pair => pair[1].href_full)
        .map(fixUrls);

      const groupsRaw = groupBy(images, pair => pair[0].split('_')[0]);

      const groups = Object.keys(groupsRaw).reduce((acc, key) => {
        acc[key] = groupsRaw[key].reduce((groupAcc, imgObj) => {
          // eslint-disable-next-line no-param-reassign
          groupAcc[imgObj[0].split('_')[1]] = imgObj[1];
          return groupAcc;
        }, {});
        return acc;
      }, {});

      return {
        altText: alttext,
        images: groups,
        originalSize: pickFrom(teaserImageRel, '[0].content.originalPictureSize'),
      };
    }

    return undefined;
  } catch (err) {
    console.warn(err);
    return undefined;
  }
};

export default extractImages;
