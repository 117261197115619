/* eslint-disable react/no-array-index-key */
/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import boostrapGrid from 'style/bootstrap-grid.css';
import classnames from 'classnames';
import renderStorylineElement from 'utils/renderStorylineElement';
import StorylineBoxBackground from './StorylineBoxBackground/StorylineBoxBackground';
import s from './StorylineBox.css';

const StorylineBox = ({
  box = [],
  src,
  srcTyp,
  contentGroup = [],
  showOutlineLogo,
  margin = '',
  textColor = '',
  backgroundTyp = '',
  horizontalAlignment = 'center',
  verticalAlignment = 'center',
  bgOpacity,
}) => {
  const emtySectionsContent = classnames(s.emtyBoxContent);
  const spaceDefault = classnames(s.spaceDefault);
  const spaceLarge = classnames(s.spaceLarge);
  const spaceExtraLarge = classnames(s.spaceExtraLarge);

  const setBoxStyle = () => {
    if (box !== false) {
      if (margin === 'default') {
        return spaceDefault;
      }
      if (margin === 'large') {
        return spaceLarge;
      }
      if (margin === 'extraLarge') {
        return spaceExtraLarge;
      }
      return spaceDefault;
    }
    return emtySectionsContent;
  };

  const getTextColor = () => {
    if (textColor === 'light') {
      return true;
    }
    if (textColor === 'dark') {
      return false;
    }
    return false;
  };

  return (
    <StorylineBoxBackground
      backgroundTyp={backgroundTyp}
      src={src}
      srcTyp={srcTyp}
      horizontalAlignment={horizontalAlignment}
      verticalAlignment={verticalAlignment}
      bgOpacity={bgOpacity}
    >
      <div className={setBoxStyle()}>
        <div>
          {contentGroup && contentGroup.map(el => renderStorylineElement(el, getTextColor()))}
          {showOutlineLogo && (
            <img alt="Outline Logo" className={s.showOutlineLogo} src="/snOutlineWings.svg" />
          )}
        </div>
      </div>
    </StorylineBoxBackground>
  );
};

export default withStyles(boostrapGrid, s)(StorylineBox);
