import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import s from './VariantSimple.css';

const VariantSimple = ({ classes, articles, variant, title, darkMode }) => {
  return (
    <div
      className={classnames(classes, s.variantSimple, {
        [s.alignRightOnDesktop]: variant === 'simpleLinkList',
        [s.darkMode]: darkMode,
      })}
    >
      <div className={s.textContainer}>
        <Typography darkMode={darkMode} variant="readMoreHeader" className={s.title}>
          {title}
        </Typography>
        {articles
          .filter(art => art !== undefined)
          .map(({ id, headline, href, relation }) => (
            <Typography
              key={id}
              darkMode={darkMode}
              variant="readMoreItem"
              component={Link}
              to={href}
              target={relation?.contentType === 'link' ? '_blank' : undefined}
            >
              {darkMode && <FontAwesomeIcon icon={faArrowRight} className={s.arrowRight} />} {headline}{' '}
              {relation?.contentType === 'link' && (
                <FontAwesomeIcon aria-hidden="true" icon={faExternalLinkAlt} className={s.externalIcon} />
              )}
            </Typography>
          ))}
      </div>
    </div>
  );
};

export default withStyles(s)(VariantSimple);
