// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EventBox-eventBox-bQVvg{background-color:#fff;display:block;padding:24px 16px 20px;text-decoration:none}.EventBox-eventBox-bQVvg.EventBox-cold-2QNYA{background-color:#eff2f5}@media (min-width:541px){.EventBox-eventBox-bQVvg{padding:24px}}.EventBox-eventBoxFlexWrapper-3GBP-{-ms-flex-pack:justify;display:-ms-flexbox;display:flex;justify-content:space-between}.EventBox-eventBox-bQVvg .EventBox-eventBoxTextblock-3l0j4{margin-right:16px;max-width:559px}.EventBox-eventBox-bQVvg .EventBox-eventHeadline-3XufU{margin-bottom:16px}.EventBox-eventBox-bQVvg:hover .EventBox-eventHeadline-3XufU{text-decoration:underline}.EventBox-eventBox-bQVvg .EventBox-eventOverline-2pLUg{display:block;margin-bottom:4px;text-transform:uppercase}.EventBox-eventBoxIcons-3PH_a{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-wrap:wrap;flex-wrap:wrap}@media (min-width:541px){.EventBox-eventBoxIcons-3PH_a{-ms-flex-direction:row;flex-direction:row}}.EventBox-iconNode-I9RdI{-ms-flex-negative:0;color:#62717f;flex-shrink:0;font-family:LabGrotesque-Regular,sans-serif;font-size:1rem;line-height:30px;margin-right:16px}.EventBox-iconNode-I9RdI,.EventBox-iconNodeImageWrapper-2l8nD{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.EventBox-iconNodeImageWrapper-2l8nD{-ms-flex-pack:center;height:16px;justify-content:center;margin-bottom:3px;margin-right:8px;width:16px}.EventBox-iconNode-I9RdI .EventBox-iconNodeImage-quiR6{color:#ea8f12;height:16px;width:auto}.EventBox-label-3aaFV{margin-top:5px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"eventBox": "EventBox-eventBox-bQVvg",
	"cold": "EventBox-cold-2QNYA",
	"eventBoxFlexWrapper": "EventBox-eventBoxFlexWrapper-3GBP-",
	"eventBoxTextblock": "EventBox-eventBoxTextblock-3l0j4",
	"eventHeadline": "EventBox-eventHeadline-3XufU",
	"eventOverline": "EventBox-eventOverline-2pLUg",
	"eventBoxIcons": "EventBox-eventBoxIcons-3PH_a",
	"iconNode": "EventBox-iconNode-I9RdI",
	"iconNodeImageWrapper": "EventBox-iconNodeImageWrapper-2l8nD",
	"iconNodeImage": "EventBox-iconNodeImage-quiR6",
	"label": "EventBox-label-3aaFV"
};
module.exports = exports;
