import { fork } from 'redux-saga/effects';
import { saga as cookSaga } from '../ducks/cook';
import { saga as locationSaga } from '../ducks/location';
import mainSaga from './main';

function* main() {
  yield fork(cookSaga);
  yield fork(locationSaga);
  yield fork(mainSaga);
}

export default main;
