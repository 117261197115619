// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CoworkerProfile-container-GJfXt{border-top:1px solid #dee4ea;margin-bottom:3rem;padding-top:3rem}.CoworkerProfile-profileWrapper-absgA{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin-bottom:16px;text-align:center}@media (min-width:820px){.CoworkerProfile-profileWrapper-absgA{-ms-flex-direction:row;flex-direction:row;margin-bottom:24px;text-align:left}}.CoworkerProfile-profilePictureWrapper-38IpP{-ms-flex-align:center;-ms-flex-pack:center;align-items:center;border:1px solid #62717f;border-radius:50%;display:-ms-flexbox;display:flex;justify-content:center;margin-bottom:24px;-webkit-mask-image:radial-gradient(#fff,#000);mask-image:radial-gradient(#fff,#000);max-height:158px;max-width:158px;overflow:hidden}@media (min-width:820px){.CoworkerProfile-profilePictureWrapper-38IpP{margin-bottom:0;margin-right:32px}}.CoworkerProfile-titleText-1hYc4{background:inherit;border:0;font-family:LabGrotesque-Regular,sans-serif;font-size:1.25rem;line-height:1.875rem;margin:0 0 16px;text-decoration:none}@media (min-width:1169px){.CoworkerProfile-titleText-1hYc4{font-size:1.5rem;line-height:2.25rem;margin-bottom:12px}}.CoworkerProfile-contactContainers-1-FKF{-ms-flex-pack:center;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;justify-content:center}@media (min-width:820px){.CoworkerProfile-contactContainers-1-FKF{-ms-flex-pack:left;display:-ms-flexbox;display:flex;justify-content:left}}.CoworkerProfile-contactLink-2DlDz{-ms-flex-align:center;align-items:center;background:inherit;background-color:inherit;border:0;color:#3568a7;display:-ms-flexbox;display:flex;font-family:LabGrotesque-Regular,sans-serif;font-size:1rem;line-height:1.625rem;margin:4px 16px 4px 0;text-decoration:underline}@media (min-width:1169px){.CoworkerProfile-contactLink-2DlDz{font-size:1.125rem}}.CoworkerProfile-icon-nM6zx{color:#3568a7;margin-bottom:3px;margin-right:7px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"container": "CoworkerProfile-container-GJfXt",
	"profileWrapper": "CoworkerProfile-profileWrapper-absgA",
	"profilePictureWrapper": "CoworkerProfile-profilePictureWrapper-38IpP",
	"titleText": "CoworkerProfile-titleText-1hYc4",
	"contactContainers": "CoworkerProfile-contactContainers-1-FKF",
	"contactLink": "CoworkerProfile-contactLink-2DlDz",
	"icon": "CoworkerProfile-icon-nM6zx"
};
module.exports = exports;
