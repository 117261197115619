import { connect } from 'react-redux';
import MicrositePage from 'components/pages/MicrositePage';
import getMetdata from 'selectors/metadata/getMetdata';
import getMicrositeHeroBackgroundData from '../../selectors/getMicrositeHeroBackgroundData';
import getMicrositeHeroStorylineData from '../../selectors/getMicrositeHeroStorylineData';
import getMicrositeSectionData from '../../selectors/getMicrositeSectionData';
import getMicrositeSectionStorylineData from '../../selectors/getMicrositeSectionStorylineData';

const mapStateToProps = state => {
  return {
    micrositeHeroBackground: getMicrositeHeroBackgroundData(state),
    micrositeHeroStoryline: getMicrositeHeroStorylineData(state),
    micrositeSection: getMicrositeSectionData(state),
    micrositeSectionStoryline: getMicrositeSectionStorylineData(state),
    metadata: getMetdata(state),
  };
};

const mapDispatchToProps = undefined;

const MicrositePageContainer = connect(mapStateToProps, mapDispatchToProps)(MicrositePage);

export default MicrositePageContainer;
