import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Headroom from 'react-headroom';
import getBlogUniqueName from '../../../selectors/getBlogUniqueName';
import DesktopHeader from './DesktopHeader/DesktopHeader';
import MobileHeader from './MobileHeader/MobileHeader';
import useDarkHeader from '../../../utils/hooks/useDarkHeader';
import useGlobalState from '../../../zustand/zustand';
import s from './PageHeader.css';

const PageHeader = () => {
  const isDarkHeader = useDarkHeader();
  const [isFloating, setIsFloating] = useGlobalState(state => [
    state.pageHeader.isFloating,
    state.pageHeader.setIsFloating,
  ]);
  const blogUniqueName = useSelector(getBlogUniqueName);

  const isFokusPaSkatternaBlogSearchPage = blogUniqueName === 'fokus-pa-skatterna-sokresultat';
  const isKompetensforsorjningBlogSearchPage = blogUniqueName === 'kompetensforsorjning-sokresultat';

  return (
    <Headroom
      onPin={() => setIsFloating(true)}
      onUnpin={() => setIsFloating(false)}
      onUnfix={() => setIsFloating(false)}
    >
      <header
        id="app-header"
        className={classnames(s.pageHeader, {
          [s.darkMode]: isDarkHeader,
          [s.fokusPaSkatternaBg]: !isFloating && isFokusPaSkatternaBlogSearchPage,
          [s.kompetensforsorjningBg]: !isFloating && isKompetensforsorjningBlogSearchPage,
        })}
      >
        <div className={s.inner}>
          <DesktopHeader />
          <MobileHeader />
        </div>
      </header>
    </Headroom>
  );
};

export default withStyles(s)(PageHeader);
