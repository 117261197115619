import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from '../utils/pickFrom';
import { selectors as locationSelectors } from '../ducks/location';

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, data => {
  const contextRootGroupType = pickFrom(data, 'context.rootGroup.type');
  let mirosite = false;
  if (contextRootGroupType === 'microsite') {
    mirosite = true;
  }

  return {
    type: contextRootGroupType,
    isMirosite: mirosite,
  };
});
