/* eslint-disable react/no-array-index-key */
import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import s from './Table.css';

const Table = ({ classes, rows, rowHeader, darkMode, columnHeader, ...other }) => {
  const classNames = classnames(s.table, classes, darkMode && s.darkMode);
  let firstRow;
  let restRows = rows;
  if (rowHeader) {
    firstRow = restRows?.[0];
    restRows = restRows?.slice(1);
  }
  return (
    <div className={s.tableScrollWrapper}>
      <table className={classNames} {...other}>
        {firstRow && (
          <thead>
            <tr>
              {firstRow?.map((item, idx) => (
                <Typography
                  variant="paragraphSmall"
                  component="th"
                  bold
                  scope="col"
                  darkMode={darkMode}
                  key={idx}
                >
                  {item}
                </Typography>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {restRows?.map((row, idx) => (
            <tr key={idx}>
              {row?.map((columnItem, columnIndex) =>
                columnIndex === 0 && columnHeader ? (
                  <Typography
                    key={columnIndex}
                    variant="paragraphSmall"
                    component="th"
                    bold
                    scope="row"
                    darkMode={darkMode}
                    className={classnames(s.columnHeader, darkMode && s.darkMode)}
                  >
                    {columnItem}
                  </Typography>
                ) : (
                  <Typography key={columnIndex} variant="paragraphSmall" component="td" darkMode={darkMode}>
                    {columnItem}
                  </Typography>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default withStyles(s)(Table);
