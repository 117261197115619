// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Section-micrositeHeroSection-3ZMuF{padding-bottom:50px;padding-top:250px}.Section-micrositeSection-3SLjo{padding-bottom:0;padding-top:0;position:relative;z-index:2}.Section-row-3q6nH{display:-ms-flexbox;display:flex}.Section-col-55RMq{-ms-flex:1 1;flex:1 1}@media screen and (max-width:820px){.Section-micrositeHeroSection-3ZMuF{padding-bottom:100px;padding-top:250px}.Section-row-3q6nH{display:block;margin-left:-12px;margin-right:-12px}}@media screen and (max-width:541px){.Section-micrositeHeroSection-3ZMuF{padding-top:180px}.Section-row-3q6nH{display:block;margin-left:-24px;margin-right:-24px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"micrositeHeroSection": "Section-micrositeHeroSection-3ZMuF",
	"micrositeSection": "Section-micrositeSection-3SLjo",
	"row": "Section-row-3q6nH",
	"col": "Section-col-55RMq"
};
module.exports = exports;
