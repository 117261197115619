import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useSelector } from 'react-redux';
import { selectors as locationSelectors } from 'ducks/location';
import useVFSResult from '../../../utils/hooks/useVFSResult';
import Carousel from '../Carousel/Carousel';
import Typography from '../../atoms/Typography';
import formatNumber from '../../../utils/formatNumber';
import { calcAntalValfardtjanst, getSkatteintakter, VALFARDSTJANSTER } from '../../../utils/vfsUtils';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';
import s from './VFSResult1.css';

const VFSResult1 = ({ darkMode }) => {
  const { isValid, vfsData } = useVFSResult();
  const fullPath = useSelector(locationSelectors.getFullPath);
  const hostname = useSelector(locationSelectors.getHostname);

  if (!isValid) {
    return null;
  }

  const { companyName, antalAnstallda, lonekostnaderPerAr, bolagsskattPerAr, totalSkatt } = vfsData;

  const imagesToRender = [
    VALFARDSTJANSTER.polis,
    VALFARDSTJANSTER.forskoleplats,
    VALFARDSTJANSTER.gymnasielarare,
    VALFARDSTJANSTER.underskoterskaIHemtjanst,
  ]
    .map(valfardstjanst => ({
      valfardstjanst,
      nr: calcAntalValfardtjanst(lonekostnaderPerAr, bolagsskattPerAr, valfardstjanst, totalSkatt),
    }))
    .filter(item => item.nr > 0)
    .map(item => item.valfardstjanst);

  return (
    <>
      <OGShareTags
        metadata={{
          headline: `Välfärdsskapande för ${companyName}`,
          href: fullPath,
          description: `Tillsammans med era ${antalAnstallda} medarbetare skapar ni skatteintäkter på ${formatNumber(
            getSkatteintakter(lonekostnaderPerAr, bolagsskattPerAr, totalSkatt)
          )} kronor. Det blir till offentlig välfärd i form av sjuksköterskor, lärare, förskoleplatser, poliser och läkarbesök.`,
          image: `${hostname}/vfs/vfs-polis.jpg`,
          imageWidth: 1200,
          imageHeight: 588,
        }}
      />
      <div className={s.vfsResult1}>
        <Typography variant="lead" darkMode={darkMode}>
          Tillsammans med era {formatNumber(antalAnstallda)} medarbetare skapar ni på {companyName}{' '}
          skatteintäkter på{' '}
          {formatNumber(getSkatteintakter(lonekostnaderPerAr, bolagsskattPerAr, totalSkatt))} kronor. Det blir
          till offentlig välfärd i form av sjuksköterskor, lärare, förskoleplatser, poliser och läkarbesök.
        </Typography>
        <Carousel
          darkMode={darkMode}
          className={s.imageCarousel}
          items={imagesToRender.map(valfardstjanst => (
            <img
              src={`/valfardsskaparna-image/v1?companyName=${encodeURIComponent(
                companyName
              )}&lonekostnaderPerAr=${lonekostnaderPerAr}&bolagsskattPerAr=${bolagsskattPerAr}&valfardstjanst=${valfardstjanst}&totalSkatt=${totalSkatt}`}
              alt={valfardstjanst}
            />
          ))}
        />
      </div>
    </>
  );
};

export default withStyles(s)(VFSResult1);
