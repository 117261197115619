import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import getImages from 'utils/getImages';
import generateSizes from 'utils/generateSizes';
import Translate from '../../../../../containers/atoms/TranslateContainer';

import s from './ImageItem.css';

const ImageItem = ({
  format,
  width,
  height,
  credit,
  imageSrc,
  imageAlt,
  downloadUrl,
  proportion = 'LANDSCAPE',
}) => {
  const sizes = getImages(proportion);
  const imageSizes = generateSizes({ sm: '174px', default: '90px' });

  return (
    <div className={s.imageItem}>
      <div className={s.imageInfoWrapper}>
        <div>
          <span className={s.headline}>
            <Translate>IMAGE_ITEM.FILE_FORMAT</Translate>:{' '}
          </span>
          <span className={s.value}>{format}</span>
        </div>
        <div>
          <span className={s.headline}>
            <Translate>IMAGE_ITEM.IMAGE_DIMENSIONS</Translate>:{' '}
          </span>
          <span className={s.value}>
            {width} x {height} <Translate>IMAGE_ITEM.PIXELS</Translate>
          </span>
        </div>
        <div>
          <span className={s.headline}>
            <Translate>IMAGE_ITEM.PHOTO</Translate>:{' '}
          </span>
          <span className={s.value}>{credit}</span>
        </div>
        <a className={s.downloadLink} href={downloadUrl}>
          <Translate>IMAGE_ITEM.DOWNLOAD_IMAGE</Translate>
        </a>
        <ButtonSecondaryGhost
          component="a"
          classes={s.downloadButton}
          href={downloadUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Translate>IMAGE_ITEM.DOWNLOAD_IMAGE</Translate>
        </ButtonSecondaryGhost>
      </div>
      <div className={s.imageWrapper}>
        <ResponsiveImage
          src={sizes.toSrc(imageSrc, 'S')}
          srcSet={sizes.toSrcSet(imageSrc)}
          sizes={imageSizes}
          alt={imageAlt}
        />
      </div>
    </div>
  );
};

export default withStyles(s)(ImageItem);
