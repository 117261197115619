// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Tags-tags-3iK8D{border:1px solid #dee4ea;border-width:1px 0}.Tags-tags-3iK8D .Tags-title-g7PvQ{color:#62717f;display:block;font-family:LabGrotesque-Bold,sans-serif;font-size:.875rem;padding:16px 0}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"tags": "Tags-tags-3iK8D",
	"title": "Tags-title-g7PvQ"
};
module.exports = exports;
