import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { v4 as uuidv4 } from 'uuid';
import { motion } from 'framer-motion';
import useAuth from '../../../../../utils/hooks/useAuth';
import useUser from '../../../../../utils/hooks/useUser';
import s from './UserCompaniesList.css';
import Spinner from '../../../../atoms/Spinner/Spinner';
import useGlobalState from '../../../../../zustand/zustand';

const UserCompaniesList = () => {
  const { isAuthenticated } = useAuth();
  const { userQuery, updateUserActiveCompanyMutation } = useUser();
  const userPreferencesIsLocked = useGlobalState(state => state.userPreferencesLock.isLocked);

  return isAuthenticated ? (
    <li className={s.userCompaniesList}>
      <motion.select
        layout
        id="userCompanies"
        name="userCompanies"
        value={userQuery.data?.userData?.activeCompany?.id ?? ''}
        onChange={e => updateUserActiveCompanyMutation.mutate(e.target.value)}
        disabled={userPreferencesIsLocked || updateUserActiveCompanyMutation.isLoading}
      >
        <option value={null}>Välj företag</option>
        {userQuery.data?.userData?.companies
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map(company => (
            <option key={uuidv4()} value={company.id}>
              {company.name}
            </option>
          ))}
      </motion.select>
      {(userPreferencesIsLocked || updateUserActiveCompanyMutation.isLoading) && (
        <Spinner className={s.spinner} />
      )}
    </li>
  ) : null;
};

export default withStyles(s)(UserCompaniesList);
