import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, data => {
  try {
    const selfStr = pickFrom(data, 'searchMetadata.selfStr')?.replace('?', '');
    const queries = selfStr?.split('&');
    const queryObj = queries?.reduce((acc, val) => {
      const split = val.split('=');
      if (!acc[split[0]]) {
        acc[split[0]] = [];
      }

      acc[split[0]].push(split?.[1]);
      return acc;
    }, {});
    return queryObj;
  } catch (e) {
    console.error(e);
    return undefined;
  }
});
