// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeroSectionBackground-mainHeroBackground-25slI{margin-top:-255px;position:relative}.HeroSectionBackground-heroBackground-1k--V{min-height:500px;position:relative}video{height:100%;left:0;-o-object-fit:cover;object-fit:cover;position:absolute;top:0;width:100%}.HeroSectionBackground-showOutlineLogoOne-2ObxO{right:80px}.HeroSectionBackground-showOutlineLogoOne-2ObxO,.HeroSectionBackground-showOutlineLogoTwo-3P-W3{max-height:100%;max-width:100%;opacity:.25;position:absolute;top:-10px}.HeroSectionBackground-showOutlineLogoTwo-3P-W3{left:80px}@media screen and (max-width:820px){.HeroSectionBackground-heroBackground-1k--V{min-height:400px}}@media screen and (max-width:541px){.HeroSectionBackground-mainHeroBackground-25slI{margin-top:-200px}.HeroSectionBackground-heroBackground-1k--V{min-height:300px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"mainHeroBackground": "HeroSectionBackground-mainHeroBackground-25slI",
	"heroBackground": "HeroSectionBackground-heroBackground-1k--V",
	"showOutlineLogoOne": "HeroSectionBackground-showOutlineLogoOne-2ObxO",
	"showOutlineLogoTwo": "HeroSectionBackground-showOutlineLogoTwo-3P-W3"
};
module.exports = exports;
