import React from 'react';
import formatNumber from '../../../utils/formatNumber';
import { tempusify } from '../../../utils/vfsUtils';

const TempusifiedValfardstjanst = ({ number, valfardstjanst }) => {
  return (
    <>
      <strong>{formatNumber(number)}</strong>&nbsp;{tempusify(number, valfardstjanst)}
    </>
  );
};

export default TempusifiedValfardstjanst;
