import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import s from './ListItem.css';

const ListItem = ({ classes, number, orange, darkMode, children, ...other }) => {
  const classNames = classnames(
    s.listItem,
    number !== undefined && s.ordered,
    orange && s.orange,
    darkMode && s.darkMode,
    classes
  );
  return number ? (
    <Typography component="li" darkMode={darkMode} classes={classNames} {...other}>
      <div className={s.number}>{number}.</div>
      {children}
    </Typography>
  ) : (
    <Typography component="li" darkMode={darkMode} classes={classNames} {...other}>
      {children}
    </Typography>
  );
};

export default withStyles(s)(ListItem);
