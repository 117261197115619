import React, { useEffect } from 'react';
/* eslint-disable css-modules/no-undef-class */
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import ShareBarContainer from 'containers/molecules/ShareBarContainer';
import CenteredRow from 'components/templates/Grid/CenteredRow';
import renderStorylineElement from 'utils/renderStorylineElement';
// eslint-disable-next-line css-modules/no-unused-class
import boostrapGrid from 'style/bootstrap-grid.css';

import { useSelector } from 'react-redux';
import s from './StandardSectionPage.css';
import BoardMembersContainer from '../../../containers/organisms/BoardMembersContainer';
import MembershipOrganizationsContainer from '../../../containers/organisms/MembershipOrganizationsContainer';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';
import { getMetadata } from '../FlexibleSection/duck/selectors';
import { addExternalScript } from '../../../utils/hooks/addExternalScript';

const StandardSectionPage = ({ groupedStorylineTree, uniqueName }) => {
  const metadata = useSelector(getMetadata);

  useEffect(() => {
    if (uniqueName === 'cookie-settings') {
      addExternalScript(
        'https://consent.cookiebot.com/f33127f6-97e8-48b8-a6fe-7109ade0798a/cd.js',
        '#cookieBotGoesHere',
        'CookieDeclaration'
      );
    }
  }, [uniqueName]);

  if (!groupedStorylineTree) return undefined;
  const { headGroup, contentGroup } = groupedStorylineTree;
  return (
    <>
      <OGShareTags metadata={metadata} />
      <div className={classnames(boostrapGrid.container)}>
        <div className={s.standardSectionPageContainer}>
          <CenteredRow gutterBottom>
            {headGroup && headGroup.map(renderStorylineElement)}
            <ShareBarContainer />
            <div className={s.stickyContainer}>
              <ShareBarContainer hideMobile />
              {contentGroup && contentGroup.map(renderStorylineElement)}
              {uniqueName === 'cookie-settings' && <div id="cookieBotGoesHere" />}
            </div>
            <BoardMembersContainer />
            <MembershipOrganizationsContainer />
          </CenteredRow>
        </div>
      </div>
    </>
  );
};

export default withStyles(boostrapGrid, s)(StandardSectionPage);
