// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DotNav-dotNav-1yb8p{-ms-flex-pack:center;display:-ms-flexbox;display:flex;justify-content:center;margin-top:1rem}.DotNav-dotNav-1yb8p button{background:none;border:none;cursor:pointer;padding:0}.DotNav-dotNav-1yb8p button .DotNav-dot-2A-QE{border-radius:50%;display:block;height:5px;margin:7px;width:5px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"dotNav": "DotNav-dotNav-1yb8p",
	"dot": "DotNav-dot-2A-QE"
};
module.exports = exports;
