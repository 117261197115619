import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { faUser, faClose } from '@fortawesome/pro-regular-svg-icons';
import Typography from 'components/atoms/Typography';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Header.css';

const Header = ({ isSmallScreen, user, handleCloseClicked }) => {
  return (
    <header className={s.header}>
      <Typography variant={isSmallScreen ? 'paragraphSmall' : 'h4'} darkMode>
        Granskning och arkivering
      </Typography>
      <div className={s.headerRight}>
        {!isSmallScreen && user && (
          <div className={s.nameContainer}>
            <div className={s.iconWrapper}>
              <FontAwesomeIcon icon={faUser} style={{ height: 16, color: '#33414e' }} />
            </div>
            <Typography variant="h4" className={s.name} darkMode>
              {user?.name}
            </Typography>
          </div>
        )}
        <div onClick={handleCloseClicked} className={classnames(s.iconWrapper, s.closeIcon)}>
          <FontAwesomeIcon icon={faClose} style={{ height: 16, color: '#33414e' }} />
        </div>
      </div>
    </header>
  );
};

export default withStyles(s)(Header);
