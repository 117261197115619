// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TranscodingVideo-transcodingVideo-IdjxQ{overflow:hidden;padding-top:56.25%;position:relative}.TranscodingVideo-transcodingVideo-IdjxQ .TranscodingVideo-content-3ZwSj{-ms-flex-pack:center;-ms-flex-align:center;align-items:center;color:#fff;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;justify-content:center;padding:10%}.TranscodingVideo-cover-4nlJD{height:100%;left:0;position:absolute;top:0;width:100%}.TranscodingVideo-backgroundImage-3xTUm{background-repeat:no-repeat;background-size:cover;-webkit-filter:blur(4px);filter:blur(4px)}.TranscodingVideo-gradientWithBgImage-t5yYW{background:-webkit-gradient(linear,left bottom,left top,from(rgba(0,0,0,.9)),color-stop(30%,rgba(0,0,0,.5)),to(rgba(0,0,0,.3)));background:linear-gradient(0deg,rgba(0,0,0,.9),rgba(0,0,0,.5) 30%,rgba(0,0,0,.3))}.TranscodingVideo-gradientWithoutBgImage-3JcrG{background:-webkit-gradient(linear,left bottom,left top,from(#2a3843),to(#62717f));background:linear-gradient(0deg,#2a3843,#62717f)}.TranscodingVideo-backgroundIcon-2Andc{color:#fff;height:100%;left:auto;right:-6%;top:0;width:60%}.TranscodingVideo-backgroundIcon-2Andc.TranscodingVideo-withBgImage-1vUKV{opacity:.2}.TranscodingVideo-backgroundIcon-2Andc.TranscodingVideo-withoutBgImage-3Tu2g{opacity:.05}.TranscodingVideo-backgroundIcon-2Andc svg{height:100%!important;width:100%!important}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"transcodingVideo": "TranscodingVideo-transcodingVideo-IdjxQ",
	"content": "TranscodingVideo-content-3ZwSj",
	"cover": "TranscodingVideo-cover-4nlJD",
	"backgroundImage": "TranscodingVideo-backgroundImage-3xTUm",
	"gradientWithBgImage": "TranscodingVideo-gradientWithBgImage-t5yYW",
	"gradientWithoutBgImage": "TranscodingVideo-gradientWithoutBgImage-3JcrG",
	"backgroundIcon": "TranscodingVideo-backgroundIcon-2Andc",
	"withBgImage": "TranscodingVideo-withBgImage-1vUKV",
	"withoutBgImage": "TranscodingVideo-withoutBgImage-3Tu2g"
};
module.exports = exports;
