/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable css-modules/no-unused-class */
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import SocialBall from 'components/atoms/SocialBall';
import pushToDataLayer from 'utils/pushToDataLayer';
import { faLinkedin, faFacebookSquare, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPrint } from '@fortawesome/free-solid-svg-icons';

import s from './ShareBar.css';
import useGlobalState from '../../../zustand/zustand';

class ShareBar extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.barRef = React.createRef();
    this.state = {
      stickyBottom: false,
      isFloating: false,
    };
  }

  componentDidMount() {
    const { hideMobile } = this.props;
    this.unsub = useGlobalState.subscribe(state => {
      this.setState({
        isFloating: state?.pageHeader.isFloating,
      });
    });
    if (!hideMobile) {
      document.addEventListener('scroll', this.trackScrolling);
      this.trackScrolling();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
    this.unsub();
  }

  trackScrolling = () => {
    const { stickyBottom } = this.state;
    const shoudBeSticky =
      document.documentElement.scrollTop > this.scrollRef.current.offsetTop && !this.state.isFloating;
    if (stickyBottom !== shoudBeSticky) {
      this.setState({
        stickyBottom: shoudBeSticky,
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  printClicked = e => {
    e.preventDefault();
    if (window && window.print) {
      window.print();
    }
    pushToDataLayer('printIntent');
  };

  // eslint-disable-next-line class-methods-use-this
  handleSocialClick = (e, gtmActionValue) => {
    e.preventDefault();
    window.open(
      e.currentTarget.href,
      'sharewindow',
      'left=20,top=20,width=650,height=400,toolbar=1,resizable=0'
    );
    pushToDataLayer('socialLinkClick', {
      socialLinkCategory: gtmActionValue,
      socialLinkSourcePageUrl: window.location.href,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  handleEmailClick = () => {
    pushToDataLayer('socialLinkClick', {
      socialLinkCategory: 'Email-tip',
      socialLinkSourcePageUrl: window.location.href,
    });
  };

  generateSocialElements = ({
    metadata,
    hideMobile,
    classes,
    staticBar,
    other,
    ref,
    coloredBalls = false,
    emailTip,
    event,
  }) => (
    <div
      className={classnames(
        s.shareBar,
        !hideMobile && s.hideDesktop,
        event && s.eventPage,
        staticBar && s.staticBar,
        classes
      )}
      {...other}
      ref={ref}
    >
      <a
        href={encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${metadata.href}`)}
        className={s.link}
        onClick={e => this.handleSocialClick(e, 'Facebook-Share')}
      >
        <SocialBall image={faFacebookSquare} brand="facebook" height={21} width={21} colored={coloredBalls} />
      </a>
      <a
        href={encodeURI(`https://twitter.com/share?url=${metadata.href}&text=${metadata.headline}`)}
        onClick={e => this.handleSocialClick(e, 'Twitter-Tweet')}
        className={s.link}
      >
        <SocialBall image={faXTwitter} brand="twitter" height={21} width={21} colored={coloredBalls} />
      </a>
      <a
        href={encodeURI(
          `https://www.linkedin.com/shareArticle?mini=true&url=${metadata.href}&title=${metadata.headline}&source=LinkedIn`
        )}
        onClick={e => this.handleSocialClick(e, 'LinkedIn-Share')}
        className={s.link}
      >
        <SocialBall image={faLinkedin} brand="linkedin" height={21} width={21} colored={coloredBalls} />
      </a>
      <a onClick={this.handleEmailClick} href={encodeURI(`${emailTip} ${metadata.href}`)} className={s.link}>
        <SocialBall image={faEnvelope} height={21} width={21} colored={coloredBalls} />
      </a>
      <a href="#" className={s.link} onClick={this.printClicked}>
        <SocialBall image={faPrint} height={21} width={21} colored={coloredBalls} />
      </a>
    </div>
  );

  render() {
    const { hideMobile, stickyWrapperClasses, classes, staticBar, event, other, metadata, translations } =
      this.props;
    const { stickyBottom } = this.state;
    const emailTip = `mailto:?subject=${translations?.SHAREBAR?.MAIL_SUBJECT}&body=${translations?.SHAREBAR?.MAIL_BODY}:`;
    return hideMobile ? (
      <div
        className={classnames(
          s.stickyWrapper,
          stickyWrapperClasses,
          hideMobile && s.hideMobile,
          event && s.eventPage
        )}
      >
        {this.generateSocialElements({ metadata, hideMobile, classes, other, emailTip })}
      </div>
    ) : (
      <div>
        <div ref={this.scrollRef} />
        {this.generateSocialElements({
          metadata,
          hideMobile,
          classes,
          staticBar,
          other,
          ref: this.barRef,
          emailTip,
          event,
        })}
        <div className={classnames(stickyBottom === true ? s.fadeIn : s.fadeOut)}>
          <div className={s.stickyBottom}>
            {this.generateSocialElements({
              metadata,
              hideMobile,
              classes,
              other,
              coloredBalls: true,
              emailTip,
              event,
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(ShareBar);
