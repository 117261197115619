import React, { useState } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import history from 'utils/history';
import Typography from 'components/atoms/Typography';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import SearchBar from 'components/molecules/SearchBar';
import SearchResultItem from 'components/molecules/SearchResultItem';
import Modal from 'components/organisms/Modal';
import DarkMode from 'components/templates/DarkMode';
import firstValueInQueryObject from 'utils/firstValueInQueryObject';
import exists from 'utils/exists';
import Link from 'containers/atoms/LinkContainer';
import boostrapGrid from 'style/bootstrap-grid.css';
import FilterBlock from './components/FilterBlock';
import s from './CalendarPage.css';
import Tabs from './components/Tabs/Tabs';
import PlayButtonImage from '../../atoms/PlayButtonImage';
import renderCalendarStorylineElement from '../../../utils/renderCalendarStorylineElement';

const CalendarPage = ({
  searchQuery,
  queryString,
  searchMetadata,
  searchResults,
  searchFacets,
  navigate,
  pathWithoutParams,
  groupedStorylineTree,
  darkMode,
}) => {
  const [state, setState] = useState({
    modalOpen: false,
    searchPhrase: '',
  });

  const isCurrentPath = pathToCheck => pathToCheck === pathWithoutParams;

  const buildSearchString = (searchTerm, filters) => {
    let query = `${pathWithoutParams}?q=`;
    if (searchTerm) {
      query += encodeURIComponent(searchTerm);
    }
    Object.keys(filters).forEach(key => {
      if (filters[key] && key !== 'q') {
        filters[key].forEach(val => {
          query += `&${key}=${encodeURIComponent(val)}`;
        });
      }
    });
    return query;
  };

  const triggerFilter = filters => {
    const searchPath = buildSearchString(searchQuery.q, filters);
    history.push(searchPath, { scrollPosition: window.scrollY }); // do SPA navigation
    navigate(searchPath, window.scrollY);
  };

  const unselectFilter = (filterId, filterValue) => {
    const newQueryString = {
      ...queryString,
    };
    newQueryString[filterId] = newQueryString[filterId].filter(val => val !== filterValue);
    triggerFilter(newQueryString);
  };

  const selectFilter = (filterId, value) => {
    const newQueryString = {
      ...queryString,
    };
    if (!newQueryString[filterId]) newQueryString[filterId] = [];
    newQueryString[filterId].push(value);
    triggerFilter(newQueryString);
  };

  const setSearchPhrase = searchPhrase => {
    setState({
      ...state,
      searchPhrase,
    });
  };

  const clearFilters = () => {
    triggerFilter({});
  };

  const handleSubmit = e => {
    e.preventDefault();
    // const scrollPosition = typeof window !== 'undefined' ? window.scrollY : undefined;
    let searchPath = pathWithoutParams;
    if (exists(state.searchPhrase)) {
      searchPath += `?q=${encodeURIComponent(state.searchPhrase)}`;
    }
    history.push(searchPath, { scrollPosition: 0 }); // do SPA navigation
    navigate(searchPath);
  };

  const resetSearch = () => {
    setSearchPhrase('');
    history.push(pathWithoutParams, { scrollPosition: 0 }); // do SPA navigation
    navigate(pathWithoutParams);
  };
  return (
    <div className={s.calendarPage}>
      <Modal
        modalOpen={state.modalOpen}
        closeModal={() => setState({ ...state, modalOpen: false })}
        label="Filtrera sökresultat"
      >
        <FilterBlock
          facets={searchFacets}
          filters={queryString || searchQuery}
          selectFilter={selectFilter}
          unselectFilter={unselectFilter}
          clearFilters={clearFilters}
          mobileMode
          hiddenFacets={isCurrentPath('/kalendarium/inspelade_webbinarier') ? ['implementationtype'] : []}
          closeModal={() => setState({ ...state, modalOpen: false })}
        />
      </Modal>
      <DarkMode darkMode offset>
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(boostrapGrid.row)}>
            <div className={getBootstrapColumns('20,2', '16,4', '22,1')}>
              <div className={s.header}>
                <div className={s.pageHeadline}>
                  {groupedStorylineTree?.headGroup &&
                    groupedStorylineTree?.headGroup.map(el => renderCalendarStorylineElement(el, darkMode))}
                </div>
                <div className={s.searchWrapper}>
                  <SearchBar
                    noBorder
                    autoComplete="off"
                    classes={s.searchBar}
                    onSubmit={handleSubmit}
                    placeholder="Sök"
                    searchButton
                    searchTerm={state.searchPhrase}
                    handleChange={e => setSearchPhrase(e.target.value)}
                  />
                  <ButtonPrimaryAlt
                    classes={s.filterButton}
                    onClick={() => setState({ ...state, modalOpen: true })}
                  >
                    Filtrera
                  </ButtonPrimaryAlt>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DarkMode>
      <div className={classnames(boostrapGrid.container)}>
        <div className={classnames(boostrapGrid.row)}>
          <div className={classnames(s.listContainer, getBootstrapColumns('20,2', '16,4', '13,1'))}>
            <Tabs currentPath={pathWithoutParams} />
            <div className={s.mainContent}>
              {(() => {
                const q = firstValueInQueryObject(searchQuery, 'q');
                return (
                  q &&
                  q !== 'undefined' &&
                  q !== '*' && (
                    <Typography variant="h3" component="h2" gutterBottom className={s.searchHeadline}>
                      Du har sökt på: “{q}”
                      <button type="button" className={s.removeButton} onClick={resetSearch}>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className={s.removeIcon}
                          style={{ width: 20, height: 20 }}
                        />
                      </button>
                    </Typography>
                  )
                );
              })()}
              {searchResults?.map(item => (
                <div key={item.id}>
                  <SearchResultItem
                    href={item.href}
                    contentType={item?.fields?.eventType || item?.contentType}
                    title={item.title}
                    teaser={item.fields.teaser}
                    from={item.fields.startDate}
                    to={item.fields.endDate}
                    hideTimeInformation={item.fields.hideTimeInformation}
                    deadlineDate={item.fields.deadlineDate}
                    location={item.fields.eventTown}
                    implementationType={item.fields.implementationType}
                    published={item.published}
                    imageAlt={item?.images?.alt}
                    binaryExtension={item?.binary?.extension}
                    binaryHref={item?.binary?.href}
                    customImage={
                      isCurrentPath('/kalendarium/inspelade-webbinarier/')
                        ? () => (
                            <div className={s.customImageWrapper}>
                              <PlayButtonImage noLink image={item?.images} href={item.href} />
                            </div>
                          )
                        : undefined
                    }
                    imageHref={isCurrentPath('/kalendarium/inspelade-webbinarier/') ? item?.images?.href : ''}
                    hideDate={isCurrentPath('/kalendarium/inspelade-webbinarier/')}
                    liveEventBadgeType={item.liveEventBadgeType}
                    stackLayout={isCurrentPath('/kalendarium/inspelade-webbinarier/')}
                    hideAdditionalEventInfo={isCurrentPath('/kalendarium/inspelade-webbinarier/')}
                  />
                </div>
              ))}
            </div>
            <div className={s.showMoreButtons}>
              {searchMetadata.previousPage && searchMetadata.previousPage !== 'undefined' && (
                <ButtonSecondaryGhost
                  key={`${searchMetadata.previousPage}-prevbutton`}
                  component={Link}
                  disabled={searchMetadata.previousPage === 'undefined'}
                  to={
                    searchMetadata.previousPage === 'undefined'
                      ? undefined
                      : `${pathWithoutParams}${searchMetadata.previousPage}`
                  }
                  classes={s.showMoreButton}
                >
                  <FontAwesomeIcon icon={faChevronLeft} className={s.cheveronLeft} />
                  Föregående sida
                </ButtonSecondaryGhost>
              )}
              {searchMetadata.nextPage && searchMetadata.nextPage !== 'undefined' && (
                <ButtonSecondaryGhost
                  key={`${searchMetadata.nextPage}-nextbutton`}
                  component={Link}
                  disabled={searchMetadata.nextPage === 'undefined'}
                  to={
                    searchMetadata.nextPage === 'undefined'
                      ? undefined
                      : `${pathWithoutParams}${searchMetadata.nextPage}`
                  }
                  classes={s.showMoreButton}
                >
                  Nästa sida
                  <FontAwesomeIcon icon={faChevronRight} className={s.cheveronRight} />
                </ButtonSecondaryGhost>
              )}
            </div>
          </div>
          <div className={classnames(getBootstrapColumns('22,1', '22,1', '8,2'), s.sideContent)}>
            <SearchBar
              noBorder
              autoComplete="off"
              classes={s.searchBar}
              onSubmit={handleSubmit}
              placeholder="Sök"
              searchButton
              searchTerm={state.searchPhrase}
              handleChange={e => setSearchPhrase(e.target.value)}
            />
            <div className={s.filterBlockContainer}>
              <FilterBlock
                searchQuery={searchQuery.q?.[0]}
                facets={searchFacets}
                filters={queryString || searchQuery}
                selectFilter={selectFilter}
                unselectFilter={unselectFilter}
                clearFilters={clearFilters}
                hiddenFacets={
                  isCurrentPath('/kalendarium/inspelade-webbinarier/') ? ['implementationtype'] : []
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(CalendarPage);
