import { connect } from 'react-redux';
import ShareBar from 'components/molecules/ShareBar';
import getMetdata from 'selectors/metadata/getMetdata';
import getTranslation from '../../selectors/getTranslation';

const mapStateToProps = state => {
  return {
    metadata: getMetdata(state),
    translations: getTranslation(state)?.translations,
  };
};

const mapDispatchToProps = undefined;

const ShareBarContainer = connect(mapStateToProps, mapDispatchToProps)(ShareBar);

export default ShareBarContainer;
