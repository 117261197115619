import React from 'react';
import OGTags from '../../pages/ArticlePage/components/OGTags';
import TwitterTags from '../../pages/ArticlePage/components/TwitterTags';

const OGShareTags = ({ metadata }) => {
  if (metadata == null) return null;

  return (
    <>
      <OGTags
        title={metadata.headline}
        type="article"
        url={metadata.href}
        siteName="Svenskt Näringsliv"
        description={metadata.description}
        metaDescription={metadata.metaDescription}
        image={metadata.image}
        imageWidth={metadata.imageWidth}
        imageHeight={metadata.imageHeight}
      />
      <TwitterTags
        card="summary_large_image"
        site="svenaringsliv"
        title={metadata.headline}
        description={metadata.description}
        url={metadata.href}
        image={metadata.image}
        imageWidth={metadata.imageWidth}
        imageHeight={metadata.imageHeight}
      />
    </>
  );
};

export default OGShareTags;
