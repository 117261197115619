// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrderCount-orderCount-2CJlI{margin-bottom:20px}.OrderCount-inputWrapper-3hdQ7{display:-ms-flexbox;display:flex}.OrderCount-label-2HFkz{display:block}", ""]);
// Exports
exports.locals = {
	"orderCount": "OrderCount-orderCount-2CJlI",
	"inputWrapper": "OrderCount-inputWrapper-3hdQ7",
	"label": "OrderCount-label-2HFkz"
};
module.exports = exports;
