import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import getImages from 'utils/getImages';
import pickFrom from 'utils/pickFrom';
import extractImages from 'utils/extractImages';
import _replace from 'lodash/fp/replace';
import reducer from 'utils/reduceTeaserOptions';

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, (data, hostname) => {
  const replaceHostname = _replace(hostname, '');

  if (data) {
    try {
      const { context } = data;
      const { fields } = context;
      const startDate = pickFrom(fields, 'startDate');
      const endDate = pickFrom(fields, 'endDate');
      const deadlineDate = pickFrom(fields, 'deadlineDate');
      const contactPersonsObj = pickFrom(context, 'contactPersons');
      const relatedItemsObj = pickFrom(context, 'related');
      const videoBackgroundImage = getImages('LANDSCAPE').toSrc(
        pickFrom(context, 'videoBackgroundImage[0].content.fields.BASE_LANDSCAPE.href_full'),
        'L'
      );

      const contactPersons = contactPersonsObj.map(person => {
        const teaserOptionsRaw = pickFrom(person, 'teaserOptions');
        const teaserOptions = reducer(teaserOptionsRaw);
        const profileImage = pickFrom(person, 'content.profileImage');
        const profileLink = pickFrom(person, 'content.href');

        return {
          id: pickFrom(person, 'content.id'),
          profileLink: replaceHostname(profileLink),
          images: profileImage && extractImages(profileImage),
          name: teaserOptions.useSummaryFields ? teaserOptions.title : pickFrom(person, 'content.title'),
          assignment: teaserOptions.useSummaryFields
            ? teaserOptions.assignment
            : pickFrom(person, 'content.fields.assignment'),
        };
      });

      const filteredRelatedItems = relatedItemsObj.filter(item => item?.content?.contentType === 'document');

      const documents = filteredRelatedItems.map(document => {
        const teaserOptionsRaw = pickFrom(document, 'teaserOptions');
        const teaserOptions = reducer(teaserOptionsRaw);
        return {
          id: pickFrom(document, 'content.id'),
          title: teaserOptions.useSummaryFields ? teaserOptions.title : pickFrom(document, 'content.title'),
          downloadLink: pickFrom(document, 'content.binary.href'),
          fileExtension: pickFrom(document, 'content.binary.extension'),
        };
      });

      return {
        startDate,
        endDate,
        deadlineDate,
        county: pickFrom(fields, 'county'),
        location: pickFrom(fields, 'location'),
        implementationType: pickFrom(fields, 'implementationType'),
        eventTown: pickFrom(fields, 'eventTown'),
        streetAddress: pickFrom(fields, 'streetAddress'),
        eventStatus: pickFrom(fields, 'eventStatus'),
        eventType: pickFrom(fields, 'eventType'),
        hideTimeInformation: pickFrom(fields, 'hideTimeInformation'),
        externalForm: pickFrom(fields, 'externalForm'),
        vimeoLiveEventUrl: pickFrom(fields, 'vimeoLiveEventUrl'),
        vimeoVideoUrl: pickFrom(fields, 'vimeoVideoUrl'),
        contactPersons: [...contactPersons],
        documents: [...documents],
        videoBackgroundImage,
      };
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
