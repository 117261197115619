/* eslint-disable css-modules/no-undef-class */
/* eslint-disable prettier/prettier */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import s from './Typography.css';

const variantMapping = {
  h1Big: 'h1',
  h1: 'h1',
  h2: 'h2',
  h2Small: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  lead: 'p',
  paragraph: 'p',
  paragraphLarge: 'p',
  paragraphSmall: 'p',
  paragraphInfo: 'p',
  list: 'span',
  link: 'span',
  linkSmall: 'span',
  linkNoUnderline: 'span',
  linkFaded: 'span',
  imageCaption: 'span',
  caption: 'span',
  inputLabel: 'label',
  contentTypeDate: 'span',
  quoteArticleSidebar: 'q',
  quoteArticleMain: 'q',
  readMoreHeader: 'div',
  readMoreItem: 'div',
  factsHeadline: 'div',
};

const isMicrositeSelector = state => state?.cook?.data?.context?.rootGroup?.type === 'microsite';
const isFlexibleSectionSelector = state => state?.cook?.data?.context?.rootGroup?.type === 'flexibleSection';

const Typography = ({
  variant = 'paragraph',
  center,
  darkMode,
  light,
  gutterBottom,
  gutterBottomSmall,
  gutterTop,
  bold,
  classes,
  component,
  className,
  forceVariant,
  ...other
}) => {
  let Component = component;
  if (!Component && variantMapping[variant]) {
    Component = variantMapping[variant];
  }

  const isMicrosite = useSelector(isMicrositeSelector);
  const isFlexibleSection = useSelector(isFlexibleSectionSelector);
  if (!forceVariant && (isMicrosite || isFlexibleSection) && Component === 'h2') {
    // eslint-disable-next-line no-param-reassign
    variant = 'h1';
  }
  if (!forceVariant && (isMicrosite || isFlexibleSection) && Component === 'h3') {
    // eslint-disable-next-line no-param-reassign
    variant = 'h2';
  }

  const classNames = classnames(
    s.typographyRoot,
    darkMode && s.darkMode,
    light && s.light,
    variant === 'h1Big' && s.typographyH1Big,
    variant === 'h1' && s.typographyH1,
    variant === 'h2' && s.typographyH2,
    variant === 'h3' && s.typographyH3,
    variant === 'h4' && s.typographyH4,
    variant === 'h5' && s.typographyH5,
    variant === 'lead' && s.typographyLead,
    variant === 'paragraph' && s.typographyParagraph,
    variant === 'paragraphLarge' && s.typographyParagraphLarge,
    variant === 'paragraphSmall' && s.typographyParagraphSmall,
    variant === 'paragraphInfo' && s.typographyParagraphInfo,
    variant === 'list' && s.typographyList,
    variant === 'link' && s.typographyLink,
    variant === 'linkSmall' && s.typographyLinkSmall,
    variant === 'linkNoUnderline' && s.typographyLinkNoUnderline,
    variant === 'linkFaded' && s.typographyLinkFaded,
    variant === 'imageCaption' && s.typographyImageCaption,
    variant === 'caption' && s.typographyCaption,
    variant === 'inputLabel' && s.typographyInputLabel,
    variant === 'contentTypeDate' && s.typographyContentTypeDate,
    variant === 'quoteArticleSidebar' && s.typographyQuoteArticleSidebar,
    variant === 'quoteArticleMain' && s.typographyQuoteArticleMain,
    variant === 'readMoreHeader' && s.typographyReadMoreHeader,
    variant === 'readMoreItem' && s.typographyReadMoreItem,
    variant === 'factsHeadline' && s.typographyFactsHeadline,
    gutterBottom && s.gutterBottom,
    gutterBottomSmall && s.gutterBottomSmall,
    gutterTop && s.gutterTop,
    bold && s.bold,
    classes,
    className
  );
  return <Component className={classNames} {...other} />;
};

export default withStyles(s)(Typography);
