import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import Typography from '../../../atoms/Typography/Typography';
import Link from '../../../../containers/atoms/LinkContainer';
import s from './BlogPostListItem.css';
import sentencifyArray from '../../../../utils/sentencifyArray';

const BlogPostListItem = ({ blogPost, showDividers }) => {
  return (
    <li
      className={classnames(s.blogPostListItem, {
        [s.showDividers]: showDividers,
      })}
    >
      <Link to={blogPost.url}>
        <article>
          <time dateTime={blogPost.date}>{blogPost.dateString}</time>
          <Typography variant="h2">{blogPost.title}</Typography>
          <div className={s.flexContainer}>
            <div className={classnames(s.imageWrapperDesktop, s[`items-${blogPost.authors?.length}`])}>
              {blogPost.authors?.length > 0 ? (
                blogPost.authors.map(a =>
                  a.image?.src ? (
                    <img key={a.name} src={a.image.src} alt={a.image.alt} />
                  ) : (
                    <FontAwesomeIcon key={a.name} icon={faUser} />
                  )
                )
              ) : (
                <FontAwesomeIcon icon={faUser} />
              )}
            </div>
            <div className={s.content}>
              <Typography variant="paragraph">{blogPost.teaser}</Typography>
              <p className={s.byDesktop}>
                {sentencifyArray(blogPost.authors, {
                  mapper: ({ name }) => <span key={name}>{name}</span>,
                })}
              </p>

              <div className={s.authorsMobile}>
                {blogPost.authors?.length > 0 &&
                  blogPost.authors.map(a => (
                    <div key={a.name} className={s.author}>
                      {a.image?.src ? (
                        <img src={a.image.src} alt={a.image.alt} />
                      ) : (
                        <FontAwesomeIcon icon={faUser} />
                      )}
                      <span>{a.name}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </article>
      </Link>
    </li>
  );
};

export default withStyles(s)(BlogPostListItem);
