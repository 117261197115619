/* eslint-disable dot-notation */
import { createSelector } from 'reselect';
import _values from 'lodash/values';
import pickFrom from 'utils/pickFrom';
import getStorylineSorted from './getStorylineSorted';
import extractHighResolutionImage from '../utils/extractHighResolutionImage';
import extractImages from '../utils/extractImages';
import clientifyUrl from '../utils/clientifyUrl';

const sortOrder = {
  italic: 0,
  underline: 0,
  strike: 1,
  subscript: 2,
  superscript: 3,
  bold: 4,
  internal_link: 9,
  inline_link: 10,
};

const sortAnnotations = (a, b) => sortOrder[b.name] - sortOrder[a.name];

const extractPictureRelation = element => {
  try {
    const { alttext, BASE_LANDSCAPE, description } = element.fields;
    // eslint-disable-next-line camelcase
    const href_full = pickFrom(BASE_LANDSCAPE, 'href_full');
    return {
      ...element,
      image: {
        alttext,
        description,
        // eslint-disable-next-line camelcase
        href: href_full,
      },
    };
  } catch (error) {
    console.error(error);
    return element;
  }
};

const extractStoryRelation = element => {
  let imageElement = element.coverImage && element.coverImage[0];
  if (!imageElement) {
    imageElement = element.teaserImage && element.teaserImage[0];
  }
  const href = pickFrom(imageElement, 'content.fields.BASE_LANDSCAPE.href_full');
  const alttext = pickFrom(imageElement, 'content.fields.alttext');
  const description = pickFrom(imageElement, 'content.fields.description');

  if (href) {
    return {
      ...element,
      image: {
        alttext,
        description,
        href,
      },
    };
  }
  return element;
};

const extractProfileRelation = (element = {}) => {
  return {
    image: extractPictureRelation(element.profileImage[0]?.content)?.image,
    name: element.title,
    highResolutionImages: element.highResolutionImages.map(extractHighResolutionImage),
    documents: element.documents,
    fields: element.fields,
  };
};

const extractNewsletterRelation = (element = {}) => {
  return {
    title: element.fields?.newsletterName || element?.title,
    id: element.id,
    fields: element.fields,
    image: extractImages(element.teaserImage),
  };
};

// Make a more consumer friendy data format for relation object
const extractRelation = element => {
  switch (element.contentType) {
    case 'profile':
      return extractProfileRelation(element);
    case 'story':
    case 'storyline':
    case 'report':
    case 'sectionInfo':
    case 'link':
    case 'remiss':
    case 'event':
    case 'blogPost':
    case 'skrivelse':
    case 'pressRelease':
    case 'tagInfo':
      return extractStoryRelation(element);
    case 'picture':
      return extractPictureRelation(element);
    case 'newsletter':
      return extractNewsletterRelation(element);

    default:
      return element;
  }
};

// Make a more consumer friendy data format for relation object
const extractRelationData = elements => {
  return elements.map(el => {
    try {
      if (el.type === 'relation') {
        const href = pickFrom(el, 'relation.href');
        return {
          relationData: {
            headline: pickFrom(el, 'fields.title.rawText', 'relation.title'),
            href: href && clientifyUrl(href),
            coverImage: pickFrom(el, 'relation.coverImage'),
          },
          ...el,
        };
      }
      if (el.type === 'internal_link') {
        const relation = pickFrom(el, 'relation');
        const href = pickFrom(relation, 'href');
        return {
          ...el,
          relation: {
            ...relation,
            href: clientifyUrl(href),
          },
        };
      }
    } catch (error) {
      console.error(error);
    }
    return el;
  });
};

const extractFields = fields =>
  fields.reduce((acc, field) => {
    switch (field['__typename']) {
      case 'StoryElementTextField':
        acc[field.name] = {
          rawText: field.value,
          annotations: field.annotations && field.annotations.slice().sort(sortAnnotations),
        };
        break;
      case 'StoryElementBooleanField':
        acc[field.name] = field.booleanValue;
        break;
      default:
        break;
    }
    return acc;
  }, {});

const filterSucceedingRightImages = elements => {
  return elements.reduce((acc, el) => {
    if (acc.length > 0) {
      const lastElement = acc[acc.length - 1];
      if (el.type === 'image') {
        const lastElementPushToRightColumnOnDesktop =
          pickFrom(lastElement, 'fields.pushToRightColumnOnDesktop.rawText') === 'true' ||
          pickFrom(lastElement, 'fields.pushToRightColumnOnDesktop') === true;
        const currentElementPushToRightColumnOnDesktop =
          pickFrom(el, 'fields.pushToRightColumnOnDesktop.rawText') === 'true' ||
          pickFrom(el, 'fields.pushToRightColumnOnDesktop') === true;

        if (lastElementPushToRightColumnOnDesktop && currentElementPushToRightColumnOnDesktop) {
          return acc;
        }
      }
    }
    acc.push(el);
    return acc;
  }, []);
};

const addAnnotationValueObject = storyline => {
  storyline.forEach(element => {
    _values(element.fields).forEach(field => {
      // eslint-disable-next-line no-unused-expressions
      field?.annotations?.forEach(annotation => {
        const valueItem = storyline.find(el => el.id === annotation.value);
        if (valueItem) {
          // eslint-disable-next-line no-param-reassign
          annotation.value = valueItem;
        }
      });
    });
  });
};

const addPrefix = nodes => {
  let currentLevel = 0;
  const indices = [0];

  return nodes.map(({ level, id, text }) => {
    while (level - 2 > currentLevel) {
      indices[++currentLevel] = 0;
    }
    while (level - 2 < currentLevel) {
      indices[currentLevel--] = 0;
    }
    indices[currentLevel]++;
    return {
      id,
      text,
      level,
      prefix: indices.slice(0, currentLevel + 1).join('.'),
    };
  });
};

/*
  Takes a sorted storyline elements and add subelements to thier parents.
  Also sorts annotations according to predefined priority.
*/
export default createSelector(getStorylineSorted, data => {
  try {
    const { context } = data;
    const { elements, storyline } = context;
    let storylineElements = elements.map(element => ({
      element,
      id: element.id,
      type: element.type,
      fields: extractFields(element.fields),
      relation: element.relation && extractRelation(element.relation),
      children: element.children,
    }));

    storylineElements = extractRelationData(storylineElements);

    const storylineElementsCopy = JSON.parse(JSON.stringify(storylineElements));

    storylineElementsCopy.forEach(element => {
      // eslint-disable-next-line no-param-reassign
      element.children = element?.children?.map(childId => {
        return storylineElementsCopy.find(el => el.id === childId);
      });
      return element;
    });

    addAnnotationValueObject(storylineElementsCopy);
    let firstLevelElements = storylineElementsCopy.filter(element => storyline.indexOf(element.id) >= 0);
    firstLevelElements = filterSucceedingRightImages(firstLevelElements);

    const tocs = firstLevelElements.filter(({ type }) => type === 'table_of_content');

    if (tocs.length > 0) {
      const headers = firstLevelElements
        .filter(({ type }) => type.startsWith('headline'))
        .map(el => ({
          id: el.id,
          text: el.fields.headline.rawText,
          level: el.type === 'headline' ? 1 : parseInt(el.type.slice(8), 10),
        }))
        .filter(({ level }) => level > 1);

      const prefixedHeaders = addPrefix(headers);
      tocs.forEach(toc => {
        // eslint-disable-next-line no-param-reassign
        toc.items = prefixedHeaders;
      });
    }

    return firstLevelElements;
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
