import React, { useState } from 'react';
import NewsletterModal from '../NewsletterModal';
import NewsletterItem from '../../pages/SubscriptionPage/components/NewsletterItem';
import List from '../../molecules/List';
import ListItem from '../../atoms/ListItem';
import useSubscribeNewsletter from '../../../utils/hooks/useSubscribeNewsletter';
import useAuth from '../../../utils/hooks/useAuth';

const NewsletterSubscribe = ({ newsletter, selectedEmail, refresh }) => {
  const [showModal, setShowModal] = useState(false);
  const { isAuthenticated } = useAuth();
  const {
    onSubscribe,
    onUnSubscribe,
    posting,
    subscriptionStatus,
    success,
    error,
    resetSubscribeNewsletter,
  } = useSubscribeNewsletter({
    apsisMailingListId: newsletter?.fields.apsisMailingListId,
    mailchimpMailingListId: newsletter?.fields.mailchimpMailingListId,
    mailjetMailingListId: newsletter?.fields.mailjetMailingListId,
    mailjetAccount: newsletter?.fields.mailjetAccount,
  });

  const newsletterTitle = newsletter?.fields?.newsletterName || newsletter?.title || '';
  const newsletterImageSrc =
    newsletter?.teaserImage?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full ||
    newsletter.image?.images?.BASE?.LANDSCAPE?.href_full;

  const newsletterImageAlt =
    newsletter?.teaserImage?.[0]?.content?.fields?.alttext || newsletter.image?.altText;

  const onSubscribeEmail = email => onSubscribe(email, newsletterTitle);
  const onUnSubscribeEmail = listRecipientId => onUnSubscribe(listRecipientId, newsletterTitle);

  const onCloseModal = () => {
    setShowModal(false);
    resetSubscribeNewsletter();
  };

  const handleSubscribeOrUnSubscribeClicked = (isSubscribed, listRecipientId) => {
    if (!isSubscribed && selectedEmail) {
      onSubscribeEmail(selectedEmail).then(() => {
        setShowModal(true);
        refresh();
      });
    }
    if (isSubscribed && listRecipientId) {
      onUnSubscribeEmail(listRecipientId).then(() => {
        setShowModal(true);
        refresh();
      });
    }
  };

  return (
    <>
      <NewsletterModal
        name={newsletterTitle}
        shouldShowModal={showModal}
        closeModal={() => onCloseModal()}
        onSubmit={onSubscribeEmail}
        success={success}
        error={error}
        posting={posting}
        subscriptionStatus={subscriptionStatus}
        selectedEmail={selectedEmail}
      />
      <NewsletterItem
        key={newsletter.id}
        imageSrc={newsletterImageSrc}
        imageAlt={newsletterImageAlt}
        headline={newsletterTitle}
        subHeadline={newsletter.fields.publicationFrequency}
        onSubscribeOrUnsubscribe={handleSubscribeOrUnSubscribeClicked}
        posting={posting}
        selectedEmail={selectedEmail}
        isSubscribed={newsletter?.isSubscribed}
        listRecipientId={newsletter?.listRecipientId}
        isAuthenticated={isAuthenticated}
      >
        <List>
          {newsletter?.fields?.uniqueSellingPoints?.map(sellingPoint => (
            <ListItem key={sellingPoint.uniqueSellingPoint}>{sellingPoint.uniqueSellingPoint}</ListItem>
          ))}
        </List>
      </NewsletterItem>
    </>
  );
};

export default NewsletterSubscribe;
