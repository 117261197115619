import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useForm } from 'react-hook-form';
import Typography from 'components/atoms/Typography';
import TextField from 'components/molecules/TextField';
import TextAreaField from 'components/molecules/TextAreaField';
import SelectField from 'components/molecules/SelectField';
import FormSuccess from 'components/molecules/FormSuccess';
import FormError from 'components/molecules/FormError';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import postForm from 'utils/postForm';
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './MailUs.css';

const MailUs = ({
  classes,
  hostname,
  toolboxId,
  contactOptions,
  membershipContactOptionKey,
  memberOrganisations,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [postingOrder, setPostingOrder] = useState({
    order: undefined,
    posting: false,
    status: undefined,
  });
  const [showMemberOrganizations, setShowMemberOrganizations] = useState(false);

  useEffect(() => {
    if (postingOrder.order) {
      postForm(`${hostname}/backoffice/public/contactus/email/`, postingOrder.order)
        .then(data => {
          if (data.status === 'ok') {
            setPostingOrder({
              ...postingOrder,
              posting: false,
              status: 'complete',
            });
          } else {
            console.error(data);
            setPostingOrder({
              ...postingOrder,
              posting: false,
              status: 'error',
            });
          }
        })
        .catch(err => {
          console.error(err);
          setPostingOrder({
            ...postingOrder,
            posting: false,
            status: 'error',
          });
        });
    }
  }, [postingOrder.order]);

  const { register, handleSubmit, errors } = useForm();
  const classNames = classnames(s.mailUs, classes);

  const sortAlphabetically = key => (a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  };

  const postOrder = data => {
    const postData = {
      toolboxId: data.toolboxId,
      memberOrganisationId: data.memberOrganization,
      contactOptionKey: data.question,
      senderEmail: data.email,
      senderName: data.name,
      message: data.description,
    };

    setPostingOrder({
      ...postOrder,
      posting: true,
      order: postData,
    });
  };

  return (
    <div className={classNames}>
      {postingOrder.status === 'complete' ? (
        <div>
          <FormSuccess headline="Tack för att du kontaktade oss!" text="Vi återkommer så fort vi kan!" />
        </div>
      ) : (
        <form autoComplete="on" onSubmit={handleSubmit(postOrder)}>
          <input id="toolboxId" name="toolboxId" type="hidden" value={toolboxId} ref={register()} />
          <SelectField
            id="question"
            name="question"
            label="Ärende"
            defaultValue="select"
            errorMessage={errors?.question?.type === 'validate' ? 'Välj ärende' : errors?.question?.message}
            onChange={e =>
              e.currentTarget.value === membershipContactOptionKey
                ? setShowMemberOrganizations(true)
                : setShowMemberOrganizations(false)
            }
            register={register({
              required: 'Välj ärende',
              validate: val => val !== 'select',
            })}
          >
            <option value="">Välj</option>
            {contactOptions?.map(item => (
              <option key={item.key} value={item.key}>
                {item.label}
              </option>
            ))}
          </SelectField>
          {showMemberOrganizations && (
            <SelectField
              id="memberOrganization"
              name="memberOrganization"
              label="Medlemsorganisation"
              defaultValue="select"
              errorMessage={
                errors?.memberOrganization?.type === 'validate'
                  ? 'Välj medlemsorganisation'
                  : errors?.memberOrganization?.message
              }
              register={register({
                required: showMemberOrganizations && 'Välj medlemsorganisation',
                validate: val => !showMemberOrganizations || val !== 'select',
              })}
            >
              <option value="">Välj</option>
              {memberOrganisations?.sort(sortAlphabetically('title')).map(item => (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              ))}
              <option value="-1">Vet ej</option>
            </SelectField>
          )}
          <TextAreaField
            id="description"
            label="Beskriv ditt ärende"
            errorMessage={errors?.description?.message}
            register={register({ required: 'Beskrivning saknas' })}
          />
          <TextField
            id="name"
            label="Namn"
            errorMessage={errors?.name?.message}
            register={register({ required: 'Namn saknas' })}
          />
          <TextField
            id="email"
            label="E-post"
            type="email"
            errorMessage={errors?.email?.message}
            register={register({
              required: 'E-post saknas',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Ogiltig e-postadress',
              },
            })}
          />
          <Typography gutterBottom>
            Genom att skicka in detta formulär godkänner du att Svenskt Näringsliv får spara dina uppgifter i
            elektronisk form.
          </Typography>
          {postingOrder.status === 'error' && (
            <FormError
              classes={s.errorBox}
              headline="Kunde inte skicka mejl"
              text="Något gick fel när mejlet skickades, kontrollera så alla fält är korrekt ifyllda eller försök igen."
            />
          )}
          <ButtonPrimaryAlt type="submit" classes={s.submitButton} disabled={postingOrder.posting}>
            {postingOrder.posting && <div className={s.ldsDualRing} />}
            {postingOrder.posting ? 'Skickar...' : 'Skicka'}
          </ButtonPrimaryAlt>
        </form>
      )}
    </div>
  );
};

export default withStyles(s, boostrapGrid)(MailUs);
