// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SearchButton-searchButton-1rkrA{-ms-flex-align:center;align-items:center;background-color:transparent;border:0;color:#33414e;display:-ms-flexbox;display:flex;font-family:LabGrotesque-Medium,sans-serif;padding:8px 0 8px 16px;text-decoration:none}.SearchButton-searchButton-1rkrA.SearchButton-darkMode-1kZqM{color:#fff}.SearchButton-searchButton-1rkrA:hover{cursor:pointer;text-decoration:underline}.SearchButton-searchButton-1rkrA svg{color:#ea8f12;margin-right:8px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"searchButton": "SearchButton-searchButton-1rkrA",
	"darkMode": "SearchButton-darkMode-1kZqM"
};
module.exports = exports;
