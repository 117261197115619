import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import useCueMenu from '../../../../utils/hooks/queries/useCueMenu';
import pushToDataLayer from '../../../../utils/pushToDataLayer';
import Link from '../../../../containers/atoms/LinkContainer';
import s from './MobileExtraMenu.css';
import useGlobalState from '../../../../zustand/zustand';
import icons from '../icons';

const MenuItem = ({ item }) => {
  const setMobileMenuOpen = useGlobalState(state => state.pageHeader.mobileMenu.setIsOpen);
  const handleClick = () => {
    setMobileMenuOpen(false);
    pushToDataLayer('topMenuClick', {
      topMenuElementPath: item.path.join(' > '),
    });
  };

  return (
    <li
      className={classnames({
        [s.active]: item.isActive,
      })}
    >
      <Link onClick={handleClick} to={item.href}>
        {item.text}
        {item.icon && icons[item.icon] && (
          <img className={s.icon} src={icons[item.icon].src} alt={icons[item.icon].alt} />
        )}
      </Link>
    </li>
  );
};

const MobileExtraMenu = () => {
  const { extraMenu } = useCueMenu({ useDivider: false });

  if (!extraMenu) {
    return null;
  }

  return (
    <div className={s.mobileExtraMenu}>
      <nav>
        <ul>
          {extraMenu.map(menuItem => {
            return <MenuItem key={menuItem.id} item={menuItem} />;
          })}
        </ul>
      </nav>
    </div>
  );
};

export default withStyles(s)(MobileExtraMenu);
