import { getUrlIfSnDomain } from './urlUtils';

const clientifyUrl = url => {
  const snUrl = getUrlIfSnDomain(url);
  if (snUrl) {
    const newUrl = `${snUrl.pathname}${snUrl.search}${snUrl.hash}`;
    return newUrl;
  }
  return url;
};

export default clientifyUrl;
