// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MembershipOrganization-membershipOrganization-1fARt{padding-bottom:24px;padding-top:24px}.MembershipOrganization-membershipOrganization-1fARt.MembershipOrganization-hasBorder-3zyp-{border-bottom:1px solid #dee4ea}.MembershipOrganization-membershipOrganization-1fARt .MembershipOrganization-subHeadline-3gse7{color:#62717f}.MembershipOrganization-logo-3PefI{display:none}.MembershipOrganization-logoMobile-u3tdD{display:block;margin-bottom:24px}.MembershipOrganization-teaserText-bVvga{margin-top:12px}@media (min-width:541px){.MembershipOrganization-logo-3PefI{display:block;margin-left:16px;margin-top:2px}.MembershipOrganization-logoMobile-u3tdD{display:none}.MembershipOrganization-headerWrapper-3j5IY{-ms-flex-pack:justify;-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;justify-content:space-between}.MembershipOrganization-headline-3W7bY{margin-right:16px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"membershipOrganization": "MembershipOrganization-membershipOrganization-1fARt",
	"hasBorder": "MembershipOrganization-hasBorder-3zyp-",
	"subHeadline": "MembershipOrganization-subHeadline-3gse7",
	"logo": "MembershipOrganization-logo-3PefI",
	"logoMobile": "MembershipOrganization-logoMobile-u3tdD",
	"teaserText": "MembershipOrganization-teaserText-bVvga",
	"headerWrapper": "MembershipOrganization-headerWrapper-3j5IY",
	"headline": "MembershipOrganization-headline-3W7bY"
};
module.exports = exports;
