import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AnimatePresence, motion } from 'framer-motion';
import municipalitiesData from './municipalities.json';
import s from './VFSMunicipalityForm.css';
import Typography from '../../atoms/Typography';
import ComboBox from '../../molecules/ComboBox/ComboBox';
import { calcNrOfValfardtjanst, MUNICIPALITY_SCHABLON, VALFARDSTJANSTER } from '../../../utils/vfsUtils';
import TempusifiedValfardstjanst from '../../atoms/TempusifiedValfardtjanst/TempusifiedValfardtjanst';

const formatTax = tax => {
  return new Intl.NumberFormat('sv-SE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    notation: 'compact',
  })
    .format(tax)
    .replace('md', 'miljarder')
    .replace('mn', 'miljoner');
};

const sortAlphabetically = key => (a, b) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};

const municipalities = municipalitiesData.sort(sortAlphabetically('name')).map(municipality => ({
  value: municipality.code,
  label: municipality.name,
  nrOfEmployed: municipality.nrOfEmployed,
}));

const VFSMunicipalityForm = ({ darkMode }) => {
  const [selectedMunicipalicy, setSelectedMunicipalicy] = useState(null);

  const showResults = !!selectedMunicipalicy;

  return (
    <div className={s.vfsMunicipalityForm}>
      <form className={s.form}>
        <Typography darkMode={darkMode} variant="inputLabel" htmlFor="municipality">
          Kommun
          <ComboBox
            inputId="municipality"
            placeholder="Välj en kommun"
            options={municipalities}
            value={selectedMunicipalicy}
            onChange={option => setSelectedMunicipalicy(option)}
          />
        </Typography>
      </form>
      <AnimatePresence>
        {showResults && (
          <motion.div
            className={s.results}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className={s.resultsInner}>
              <Typography className={s.result} component="p" variant="h2" darkMode={darkMode}>
                Företagen i {selectedMunicipalicy.label} med sina anställda betalar årligen{' '}
                <strong>{formatTax(selectedMunicipalicy.nrOfEmployed * MUNICIPALITY_SCHABLON)}</strong> i
                skatt. Det motsvarar kostnaden för{' '}
                <TempusifiedValfardstjanst
                  number={Math.round(
                    calcNrOfValfardtjanst(selectedMunicipalicy.nrOfEmployed, VALFARDSTJANSTER.grundskola) / 5
                  )}
                  valfardstjanst={VALFARDSTJANSTER.grundskola}
                />
                ,{' '}
                <TempusifiedValfardstjanst
                  number={Math.round(
                    calcNrOfValfardtjanst(selectedMunicipalicy.nrOfEmployed, VALFARDSTJANSTER.forskoleplats) /
                      5
                  )}
                  valfardstjanst={VALFARDSTJANSTER.forskoleplats}
                />
                ,{' '}
                <TempusifiedValfardstjanst
                  number={Math.round(
                    calcNrOfValfardtjanst(
                      selectedMunicipalicy.nrOfEmployed,
                      VALFARDSTJANSTER.specialistlakare
                    ) / 5
                  )}
                  valfardstjanst={VALFARDSTJANSTER.specialistlakare}
                />
                ,{' '}
                <TempusifiedValfardstjanst
                  number={Math.round(
                    calcNrOfValfardtjanst(selectedMunicipalicy.nrOfEmployed, VALFARDSTJANSTER.polis) / 5
                  )}
                  valfardstjanst={VALFARDSTJANSTER.polis}
                />{' '}
                och{' '}
                <TempusifiedValfardstjanst
                  number={Math.round(
                    calcNrOfValfardtjanst(
                      selectedMunicipalicy.nrOfEmployed,
                      VALFARDSTJANSTER.underskoterskaIHemtjanst
                    ) / 5
                  )}
                  valfardstjanst={VALFARDSTJANSTER.underskoterskaIHemtjanst}
                />
                .
              </Typography>
              <Typography className={s.result} component="p" variant="h2" darkMode={darkMode}>
                För varje person som företagen kan anställa skapas ytterligare intäkter på 287 tusen kronor.
                <br />
                Pengar som kan gå till viktiga välfärdssatsningar.
              </Typography>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default withStyles(s)(VFSMunicipalityForm);
