import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/pro-light-svg-icons';
import useCountDown from 'utils/hooks/useCountDown';
import useTranslate from 'utils/hooks/useTranslate';
import s from './CountDown.css';

const BackgroundImage = ({ src }) => (
  <div className={classnames(s.cover, s.backgroundImage)} style={{ backgroundImage: `url(${src})` }} />
);
const Gradient = ({ withBgImage }) => (
  <div
    className={classnames(s.cover, {
      [s.gradientWithBgImage]: withBgImage,
      [s.gradientWithoutBgImage]: !withBgImage,
    })}
  />
);
const PlayIcon = ({ withBgImage }) => (
  <div
    className={classnames(s.cover, s.backgroundIcon, {
      [s.withBgImage]: withBgImage,
      [s.withoutBgImage]: !withBgImage,
    })}
  >
    <FontAwesomeIcon icon={faPlayCircle} />
  </div>
);

const TimeSlot = ({ value, suffix }) => (
  <p suppressHydrationWarning>
    {value} <span>{suffix}</span>
  </p>
);

const CountDown = ({ date, backgroundImage }) => {
  const {
    isDone,
    countdown: { days, hours, minutes, seconds },
    showDate,
    humanReadableDate,
  } = useCountDown(date);
  const t = useTranslate();

  const renderHeadingText = () => {
    if (isDone) {
      return t('EVENTS.BROADCAST_STARTS_SOON');
    }

    return showDate ? t('EVENTS.BROADCAST_STARTS') : t('EVENTS.BROADCAST_STARTS_IN');
  };

  return (
    <div className={s.countDown}>
      {backgroundImage && <BackgroundImage src={backgroundImage} />}
      <Gradient withBgImage={!!backgroundImage} />
      <PlayIcon withBgImage={!!backgroundImage} />
      <div className={classnames(s.cover, s.content)}>
        <h4>{renderHeadingText()}</h4>
        <div className={s.count}>
          {showDate ? (
            <p className={s.humanReadableDate}>{humanReadableDate}</p>
          ) : (
            <>
              <TimeSlot value={days.value} suffix={days.suffix} />
              <TimeSlot value={hours.value} suffix={hours.suffix} />
              <TimeSlot value={minutes.value} suffix={minutes.suffix} />
              <TimeSlot value={seconds.value} suffix={seconds.suffix} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(CountDown);
