import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Tag from 'components/atoms/Tag';
import s from './Tags.css';

const Tags = ({ tags }) => {
  return tags?.length > 0 ? (
    <div className={s.tags}>
      <span className={s.title}>TAGGAR</span>
      {tags.map(tag => (
        <Tag key={tag.href} href={`/${tag.tagUri}`}>
          {tag.name}
        </Tag>
      ))}
    </div>
  ) : null;
};

export default withStyles(s)(Tags);
