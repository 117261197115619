import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import pushToDataLayer from '../../../../utils/pushToDataLayer';
import useCueMenu from '../../../../utils/hooks/queries/useCueMenu';
import useDarkHeader from '../../../../utils/hooks/useDarkHeader';
import Link from '../../../../containers/atoms/LinkContainer';
import icons from '../icons';
import DesktopUserListItem from '../UserSpecifics/DesktopUserListItem/DesktopUserListItem';
import s from './DesktopExtraMenu.css';

const MenuList = ({ menuData }) => {
  const isDarkHeader = useDarkHeader();

  return (
    <ul>
      {menuData.map(item => {
        const handleClick = () => {
          pushToDataLayer('topMenuClick', {
            topMenuElementPath: item.path.join(' > '),
          });
        };

        return (
          <li
            key={item.id}
            className={classnames({
              [s.darkMode]: isDarkHeader,
            })}
          >
            <Link onClick={handleClick} to={item.href}>
              {item.icon && icons[item.icon] && (
                <img
                  width={16}
                  height={12}
                  className={s.icon}
                  src={icons[item.icon].src}
                  alt={icons[item.icon].alt}
                />
              )}
              {item.text}
            </Link>
          </li>
        );
      })}
      <DesktopUserListItem />
    </ul>
  );
};

const DesktopExtraMenu = ({ className }) => {
  const { extraMenu } = useCueMenu();

  if (!extraMenu) {
    return null;
  }

  return (
    <nav
      className={classnames(s.desktopExtraMenu, {
        [className]: !!className,
      })}
    >
      <MenuList menuData={extraMenu} />
    </nav>
  );
};

export default withStyles(s)(DesktopExtraMenu);
