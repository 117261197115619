import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import clientifyUrl from '../utils/clientifyUrl';

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      const { context } = data;
      const { internalAuthors: internalAuthorsRaw, fields } = context;
      const formerCoworkerAuthorsRaw = fields?.formerCoworkerAuthors;
      const externalAuthorsRaw = fields?.externalAuthors;

      const internalAuthors = internalAuthorsRaw?.map(obj => {
        const { content } = obj;
        const { href, fields: objFields, title } = content;
        const { title: fieldTitle } = objFields;
        return {
          name: title || fieldTitle,
          href: clientifyUrl(href),
        };
      });
      const formerCoworkerAuthors = formerCoworkerAuthorsRaw?.map(obj => {
        const { name } = obj;
        return {
          name,
        };
      });
      const externalAuthors = externalAuthorsRaw?.map(obj => {
        const { externalAuthor } = obj;
        return {
          name: externalAuthor,
        };
      });

      return [...(internalAuthors || []), ...(formerCoworkerAuthors || []), ...(externalAuthors || [])];
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }
  return undefined;
});
