// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PressContactCard-pressContactCard-2QnRH{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.PressContactCard-pressContactCard-2QnRH .PressContactCard-imageWrapper-2EWfY{-ms-flex-negative:0;border:1px solid #dee4ea;border-radius:50%;flex-shrink:0;height:90px;margin-right:24px;-webkit-mask-image:radial-gradient(#fff,#000);mask-image:radial-gradient(#fff,#000);overflow:hidden;position:relative;width:90px}.PressContactCard-pressContactCard-2QnRH .PressContactCard-imageWrapper-2EWfY.PressContactCard-noUser-36tpt{background-color:#eff2f5;color:#bcc3c9}.PressContactCard-pressContactCard-2QnRH .PressContactCard-imageWrapper-2EWfY>:first-child{left:50%;position:absolute;top:50%;-webkit-transform:translate3d(-50%,-50%,0);transform:translate3d(-50%,-50%,0)}.PressContactCard-pressContactCard-2QnRH .PressContactCard-name-208x-{color:#1f272f;font-family:LabGrotesque-Bold,sans-serif;font-size:20px;line-height:28px;margin-bottom:4px}.PressContactCard-pressContactCard-2QnRH .PressContactCard-subtitle-2Kqhx,.PressContactCard-pressContactCard-2QnRH .PressContactCard-title-3y-Pz{color:#62717f;font-family:LabGrotesque-Regular,sans-serif;font-size:16px;line-height:26px;margin-bottom:4px}.PressContactCard-pressContactCard-2QnRH .PressContactCard-subtitle-2Kqhx{color:#30b079}.PressContactCard-pressContactCard-2QnRH .PressContactCard-viewContacts-3L4sF{background-color:inherit;border:0;color:#3568a7;cursor:pointer;font-family:LabGrotesque-Regular,sans-serif;font-size:16px;line-height:26px;padding:0;text-decoration:underline}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"pressContactCard": "PressContactCard-pressContactCard-2QnRH",
	"imageWrapper": "PressContactCard-imageWrapper-2EWfY",
	"noUser": "PressContactCard-noUser-36tpt",
	"name": "PressContactCard-name-208x-",
	"subtitle": "PressContactCard-subtitle-2Kqhx",
	"title": "PressContactCard-title-3y-Pz",
	"viewContacts": "PressContactCard-viewContacts-3L4sF"
};
module.exports = exports;
