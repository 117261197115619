import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { faVideo } from '@fortawesome/pro-regular-svg-icons';
import s from './LiveEventBadge.css';

const LiveEventBadge = ({ type, className }) => {
  const componentData =
    {
      past: {
        className: s.past,
        label: 'HAR SÄNTS LIVE',
        icon: faVideo,
      },
      live: {
        className: s.live,
        label: 'LIVE',
        icon: faCircle,
      },
      future: {
        className: s.future,
        label: 'LIVESÄNDS',
        icon: faCircle,
      },
    }[type] ?? null;

  return !componentData ? null : (
    <p className={classnames(s.liveEventBadge, componentData.className, { [className]: !!className })}>
      <FontAwesomeIcon icon={componentData.icon} /> <span>{componentData.label}</span>
    </p>
  );
};

export default withStyles(s)(LiveEventBadge);
