// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MinSidaStart-container-2JvXv{margin-top:1rem}@media (min-width:541px){.MinSidaStart-container-2JvXv{margin-top:0}}.MinSidaStart-container-2JvXv .MinSidaStart-link-2oUvm{color:#000}.MinSidaStart-container-2JvXv .MinSidaStart-externalLinkIcon-3eldB{height:14px;width:14px}.MinSidaStart-container-2JvXv .MinSidaStart-resultsInner-1leur{padding-bottom:1rem;padding-top:2rem}.MinSidaStart-container-2JvXv .MinSidaStart-resultsInner-1leur .MinSidaStart-result-Soxey{-ms-flex-align:center;align-items:center;background:url(/vfs/vfs-arrow.png) 0 0 no-repeat;background-position:0;background-size:clamp(32px,4vw,48px);display:-ms-flexbox;display:flex;font-family:LabGrotesque-Regular,sans-serif;font-size:clamp(1.5rem,5vw,2.125rem);line-height:1.2;min-height:120px;padding-left:clamp(40px,7vw,72px)}.MinSidaStart-container-2JvXv .MinSidaStart-resultsInner-1leur .MinSidaStart-result-Soxey strong{font-family:LabGrotesque-Bold,sans-serif}.MinSidaStart-container-2JvXv .MinSidaStart-resultsInner-1leur .MinSidaStart-result-Soxey+.MinSidaStart-result-Soxey{margin-top:2rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"container": "MinSidaStart-container-2JvXv",
	"link": "MinSidaStart-link-2oUvm",
	"externalLinkIcon": "MinSidaStart-externalLinkIcon-3eldB",
	"resultsInner": "MinSidaStart-resultsInner-1leur",
	"result": "MinSidaStart-result-Soxey"
};
module.exports = exports;
