// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AlsoReadItem-alsoReadItem-22c0Q{display:block;font-family:LabGrotesque-Bold,sans-serif;text-decoration:none;text-decoration-color:#33414e}@media (min-width:541px){.AlsoReadItem-alsoReadItem-22c0Q{margin-top:16px}}.AlsoReadItem-alsoReadItem-22c0Q:hover .AlsoReadItem-headline-1YPqk{text-decoration:underline}.AlsoReadItem-alsoReadItem-22c0Q .AlsoReadItem-desktop-B5rK8{display:none}.AlsoReadItem-alsoReadItem-22c0Q .AlsoReadItem-desktop-B5rK8 .AlsoReadItem-imageWrapper-2LXyk{-webkit-transition:all .2s ease-in-out;transition:all .2s ease-in-out;width:100%}.AlsoReadItem-alsoReadItem-22c0Q .AlsoReadItem-mobile-3NgXf .AlsoReadItem-imageWrapper-2LXyk img{height:90px;width:90px}.AlsoReadItem-alsoReadItem-22c0Q:hover .AlsoReadItem-desktop-B5rK8 .AlsoReadItem-imageWrapper-2LXyk{opacity:.8}.AlsoReadItem-mobileTypo-1iIX5{-ms-flex-positive:1;flex-grow:1;margin-right:1rem}@media (min-width:541px){.AlsoReadItem-alsoReadItem-22c0Q .AlsoReadItem-desktop-B5rK8{display:block}}.AlsoReadItem-alsoReadItem-22c0Q .AlsoReadItem-mobile-3NgXf{border-top:1px solid #d6d6d6;display:-ms-flexbox;display:flex;margin-top:1rem;padding-top:1rem}@media (min-width:541px){.AlsoReadItem-alsoReadItem-22c0Q .AlsoReadItem-mobile-3NgXf{display:none}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"alsoReadItem": "AlsoReadItem-alsoReadItem-22c0Q",
	"headline": "AlsoReadItem-headline-1YPqk",
	"desktop": "AlsoReadItem-desktop-B5rK8",
	"imageWrapper": "AlsoReadItem-imageWrapper-2LXyk",
	"mobile": "AlsoReadItem-mobile-3NgXf",
	"mobileTypo": "AlsoReadItem-mobileTypo-1iIX5"
};
module.exports = exports;
