import { connect } from 'react-redux';
import ReportPage from 'components/pages/ReportPage';
import getReportImage from 'selectors/getReportImage';
import getPublishedDate from 'selectors/getPublishedDate';
import getRelatedItems from 'selectors/getRelatedItems';
import getArticleAuthors from 'selectors/getArticleAuthors';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getOrderDetails from 'selectors/getOrderDetails';
import getArticleTags from 'selectors/getArticleTags';
import getLabel from 'selectors/getLabel';
import getMetdata from '../../selectors/metadata/getMetdata';
import getContextId from '../../selectors/getContextId';

const mapStateToProps = state => {
  const groupedStorylineTree = getGroupedStorylineTree(state);
  const titel = groupedStorylineTree?.headGroup?.find(d => d.type === 'headline')?.fields?.headline?.rawText;
  return {
    titel,
    groupedStorylineTree,
    publishedDate: getPublishedDate(state),
    relatedItems: getRelatedItems(state),
    authors: getArticleAuthors(state),
    reportImage: getReportImage(state),
    orderDetails: getOrderDetails(state),
    tags: getArticleTags(state),
    label: getLabel(state),
    metadata: getMetdata(state),
    contextId: getContextId(state),
  };
};

const mapDispatchToProps = undefined;

const ReportPageContainer = connect(mapStateToProps, mapDispatchToProps)(ReportPage);

export default ReportPageContainer;
