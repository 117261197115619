// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ContentTypeDate-contentTypeDate-17n5W{margin-bottom:1.5rem}.ContentTypeDate-contentTypeDate-17n5W>.ContentTypeDate-contentType-ixoGI{text-transform:uppercase}.ContentTypeDate-contentTypeDate-17n5W>.ContentTypeDate-contentTypeMargin-OSqeQ{margin-right:.625rem}", ""]);
// Exports
exports.locals = {
	"contentTypeDate": "ContentTypeDate-contentTypeDate-17n5W",
	"contentType": "ContentTypeDate-contentType-ixoGI",
	"contentTypeMargin": "ContentTypeDate-contentTypeMargin-OSqeQ"
};
module.exports = exports;
