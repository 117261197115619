import React from 'react';
import classnames from 'classnames';
import Link from 'containers/atoms/LinkContainer';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Tag.css';

const Tag = ({ classes, href, children, ...other }) => {
  const classNames = classnames(s.tag, classes);
  return (
    <Link className={classNames} to={href} {...other}>
      {children}
    </Link>
  );
};

export default withStyles(s)(Tag);
