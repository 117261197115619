import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Typography from 'components/atoms/Typography';
import TnlvFeedItem from 'components/molecules/TnlvFeedItem';
import withStyles from 'isomorphic-style-loader/withStyles';
import TnlvLogo from 'assets/svg/tnlv_logo.svg';
import s from './TnlvFeed.css';

const TnlvFeed = ({ classes, ...other }) => {
  const [feed, setFeed] = useState({
    fetching: true,
    data: [],
    error: undefined,
  });
  // get Counties Data -
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('/feed');
        if (response.ok) {
          const data = await response.json();
          setFeed({
            ...feed,
            fetching: false,
            data,
          });
        } else {
          setFeed({
            ...feed,
            fetching: false,
            error: new Error('Subscription response not ok'),
          });
        }
      } catch (error) {
        setFeed({
          ...feed,
          fetching: false,
          error,
        });
      }
    }
    fetchData();
  }, []);
  const classNames = classnames(s.TnlvFeed, classes);
  return !feed.error ? (
    <div className={classNames} {...other}>
      <div className={s.headlineContainer}>
        <img src={TnlvLogo} alt="TN logo" className={s.TnlvLogo} />
        <div>
          <h2 className={s.headline}>Senaste nyheterna från Tidningen&nbsp;Näringslivet</h2>
          <h2 className={s.headlineSmall}>Senaste nyheterna från Tidningen Näringslivet</h2>
          <Typography
            variant="link"
            component="a"
            href="https://www.tn.se"
            target="_blank"
            className={s.TnlvLink}
          >
            www.tn.se
          </Typography>
        </div>
      </div>
      {!feed && !feed.error && (
        <div className={s.preloaderBox}>
          <div className={s.ldsDualRing} />
          <div className={s.preloaderHeadline}>Hämtar innehåll...</div>
        </div>
      )}

      {feed?.data?.elements?.[0]?.elements?.[0].elements
        .filter(({ name }) => name === 'item')
        .slice(0, 5)
        .map((item, index) => {
          let date;
          item.elements.map(ls => {
            if (ls.name === 'pubDate') {
              date = ls.elements[0].text;
            } else return false;
            return date;
          });

          const key = `${item.name}-${index}`;

          return (
            <TnlvFeedItem
              key={key}
              text={item.elements[0].elements[0].text}
              date={date}
              href={item.elements[1].elements[0].text}
              large={index === 0}
              first={index === 0}
              last={
                index ===
                // eslint-disable-next-line no-unsafe-optional-chaining
                feed?.data?.elements?.[0]?.elements?.[0].elements
                  .filter(({ name }) => name === 'item')
                  .slice(0, 5).length -
                  1
              }
            />
          );
        })}
      {feed.error && 'Kunde inte hämta feeeeeden'}
    </div>
  ) : null;
};

export default withStyles(s)(TnlvFeed);
