// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".QuoteArticleSidebar-quoteArticleSidebar-1ebzH{margin:24px 0}@media (min-width:820px){.QuoteArticleSidebar-quoteArticleSidebar-1ebzH{margin:32px auto;max-width:444px}}@media (min-width:1169px){.QuoteArticleSidebar-quoteArticleSidebar-1ebzH{margin:0;max-width:16.66667vw;position:absolute;right:-50px;-webkit-transform:translateX(100%);transform:translateX(100%)}}@media (min-width:1600px){.QuoteArticleSidebar-quoteArticleSidebar-1ebzH{margin:0;max-width:270px}}.QuoteArticleSidebar-quoteArticleSidebar-1ebzH q{margin-bottom:1rem}.QuoteArticleSidebar-quoteArticleSidebarBottomContainer-2BhOt{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;margin-bottom:.75rem}.QuoteArticleSidebar-quoteArticleSidebarBottomContainer-2BhOt .QuoteArticleSidebar-quoteArticleSidebarIcon-3Hhk8{color:#b12f26;font-size:37px;margin-right:7px}.QuoteArticleSidebar-quoteArticleSidebarBottomContainer-2BhOt .QuoteArticleSidebar-quoteArticleSidebarSubtext-adnC9{margin-bottom:0}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"quoteArticleSidebar": "QuoteArticleSidebar-quoteArticleSidebar-1ebzH",
	"quoteArticleSidebarBottomContainer": "QuoteArticleSidebar-quoteArticleSidebarBottomContainer-2BhOt",
	"quoteArticleSidebarIcon": "QuoteArticleSidebar-quoteArticleSidebarIcon-3Hhk8",
	"quoteArticleSidebarSubtext": "QuoteArticleSidebar-quoteArticleSidebarSubtext-adnC9"
};
module.exports = exports;
