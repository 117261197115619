// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EventsWebinars-polygon-3ywYv{background-color:#eff2f5}.EventsWebinars-buttonWrapper-IQWnU{-ms-flex-pack:center;display:-ms-flexbox;display:flex;justify-content:center}.EventsWebinars-selectedEvents-1fAMm{background-color:#33414e;padding:2.5rem 0 1rem;position:relative}@media screen and (min-width:541px){.EventsWebinars-selectedEvents-1fAMm{padding:3.125rem 0 2rem}}.EventsWebinars-selectedEvents-1fAMm .EventsWebinars-selectedEventsHeader-1XCPM{margin-bottom:1.5rem}@media screen and (min-width:541px){.EventsWebinars-selectedEvents-1fAMm .EventsWebinars-selectedEventsHeader-1XCPM{-ms-flex-align:baseline;align-items:baseline;display:-ms-flexbox;display:flex}}.EventsWebinars-selectedEvents-1fAMm .EventsWebinars-selectedEventsLink-3kspa{font-weight:400}@media screen and (min-width:541px){.EventsWebinars-selectedEvents-1fAMm .EventsWebinars-selectedEventsLink-3kspa{margin-left:1rem}}.EventsWebinars-selectedEventsBottomBg-2C5hY{background-color:#eff2f5;padding:7rem 0}@media screen and (min-width:541px){.EventsWebinars-selectedEventsBottomBg-2C5hY{padding:9rem 0}}@media screen and (min-width:820px){.EventsWebinars-selectedEventsBottomBg-2C5hY{padding:6rem 0;padding:9rem 0}}.EventsWebinars-carousel-3TY0i{margin-bottom:2rem}.EventsWebinars-carouselItemChunk-1ZU4m{grid-gap:1.5rem;display:grid;gap:1.5rem;height:100%}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"polygon": "EventsWebinars-polygon-3ywYv",
	"buttonWrapper": "EventsWebinars-buttonWrapper-IQWnU",
	"selectedEvents": "EventsWebinars-selectedEvents-1fAMm",
	"selectedEventsHeader": "EventsWebinars-selectedEventsHeader-1XCPM",
	"selectedEventsLink": "EventsWebinars-selectedEventsLink-3kspa",
	"selectedEventsBottomBg": "EventsWebinars-selectedEventsBottomBg-2C5hY",
	"carousel": "EventsWebinars-carousel-3TY0i",
	"carouselItemChunk": "EventsWebinars-carouselItemChunk-1ZU4m"
};
module.exports = exports;
