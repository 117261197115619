import { createSelector } from 'reselect';

import { selectors as cookSelectors } from '../ducks/cook';
import pickFrom from '../utils/pickFrom';

export default createSelector(cookSelectors.getData, data => {
  try {
    const groupOptions = pickFrom(data, 'context.rootGroup.groupOptions');

    const showSpecial = (groupOptions || []).find(option => option.key === 'showSpecial');

    return showSpecial?.value === 'true';
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
