/* eslint-disable dot-notation */
import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import reduceTeaserOptions from 'utils/reduceTeaserOptions';
import extractImages from 'utils/extractImages';

export default createSelector(cookSelectors.getData, data => {
  try {
    const newsletters = data?.context?.deskedContent?.newsletters;
    return newsletters.map(newsletter => {
      const { useSummaryFields: useTeaser, ...teaserOptions } = reduceTeaserOptions(
        newsletter?.teaserOptions
      );
      const teaserImage = newsletter?.content?.teaserImage;
      return {
        ...newsletter.content,
        title: useTeaser ? teaserOptions?.title : newsletter?.content.title,
        image: teaserImage && extractImages(teaserImage),
        fields: {
          ...newsletter.content.fields,
          apsisMailingListId:
            newsletter.content.fields.apsisMailingListId === ''
              ? undefined
              : newsletter.content.fields.apsisMailingListId,
          mailchimpMailingListId:
            newsletter.content.fields.mailchimpMailingListId === ''
              ? undefined
              : newsletter.content.fields.mailchimpMailingListId,
          mailjetMailingListId:
            newsletter.content.fields.mailjetMailingListId === ''
              ? undefined
              : newsletter.content.fields.mailjetMailingListId,
          mailjetAccount:
            newsletter.content.fields.mailjetAccount === ''
              ? undefined
              : newsletter.content.fields.mailjetAccount,
        },
      };
    });
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
