import React from 'react';
// eslint-disable-next-line import/no-cycle
import renderStorylineElement from 'utils/renderStorylineElement';

const Medlemsportalen = ({ groupedStorylineTree }) => {
  const { headGroup, contentGroup } = groupedStorylineTree;
  return (
    <div>
      {headGroup && headGroup.map(renderStorylineElement)}
      {contentGroup && contentGroup.map(renderStorylineElement)}
    </div>
  );
};

export default Medlemsportalen;
