import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import boostrapGrid from 'style/bootstrap-grid.css';
import useUser from '../../../utils/hooks/useUser';
import useAuth from '../../../utils/hooks/useAuth';
import withFeatureFlagGuard from '../../../utils/hocs/withFeatureFlagGuard';
import OGShareTags from '../../molecules/OGShareTags';
import s from './MyPage.css';
import MinSidaMenu from './components/menu/MinSidaMenu';
import MinSidaStart from './components/start/MinSidaStart';
import ErtValfardsskapande from './components/ert-valfardsskapande/ErtValfardsskapande';
// eslint-disable-next-line import/no-cycle
import HanteraNyhetsbrev from './components/hantera-nyhetsbrev/HanteraNyhetsbrev';
// eslint-disable-next-line import/no-cycle
import Medlemsbevis from './components/medlemsbevis/Medlemsbevis';
// eslint-disable-next-line import/no-cycle
import Medlemsportalen from './components/medlemsportalen/Medlemsportalen';

const MyPage = ({ metadata, uniqueName, groupedStorylineTree }) => {
  const { isAuthenticated } = useAuth();
  const { userQuery, userQueryForExtraCompanyDetails } = useUser();

  return isAuthenticated ? (
    <>
      <OGShareTags metadata={metadata} />
      <div className={classnames(boostrapGrid.container, s.bsHeightFix)}>
        <div className={classnames(s.container, uniqueName === 'min-sida' && s.reverse)}>
          <MinSidaMenu uniqueName={uniqueName} userData={userQuery?.data?.userData} />
          <div className={s.content}>
            {uniqueName === 'min-sida' && <MinSidaStart userData={userQuery.data?.userData} />}
            {uniqueName === 'ert-valfardsskapande' && userQuery.data?.userData?.activeCompany && (
              <ErtValfardsskapande />
            )}
            {uniqueName === 'hantera-nyhetsbrev' && (
              <HanteraNyhetsbrev
                groupedStorylineTree={groupedStorylineTree}
                userData={userQuery.data?.userData}
              />
            )}
            {uniqueName === 'medlemsbevis' && userQuery.data?.userData?.activeCompany && (
              <Medlemsbevis
                userData={userQuery.data?.userData}
                extraCompanyData={userQueryForExtraCompanyDetails?.data}
                groupedStorylineTree={groupedStorylineTree}
              />
            )}
            {uniqueName === 'medlemsportalen' && (
              <Medlemsportalen groupedStorylineTree={groupedStorylineTree} />
            )}
            {uniqueName === 'snmo-konto' && <Medlemsportalen groupedStorylineTree={groupedStorylineTree} />}
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default withFeatureFlagGuard(withStyles(s)(MyPage), 'disablePremiumContent', true);
