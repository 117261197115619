import React from 'react';
import QuoteBox from 'components/molecules/QuoteBox/QuoteBox';
import Carousel from '../Carousel/Carousel';

const ImageQuoteCarousel = ({ items, bg }) => {
  const carouselItems = items.map(el => {
    const externalUrl = el?.relation?.fields?.externalUrl;
    const linkText = el?.relation?.fields?.linkText;
    const quote = el?.relation?.fields?.quote;
    const internalUrl = el?.relation?.link[0]?.content?.href;
    const imageSrc = el?.relation?.image[0]?.content?.fields?.BASE_LANDSCAPE?.href_full;
    const imageAlt = el?.relation?.image[0]?.content?.fields?.alttext;
    const id = el?.relation.id;
    const target = internalUrl ? '_self' : '_blank';

    return (
      <QuoteBox
        key={id}
        imageSrc={imageSrc}
        imageAlt={imageAlt}
        quote={quote}
        linkText={linkText}
        internalUrl={internalUrl}
        externalUrl={externalUrl}
        target={target}
        bgColorScheme={bg}
      />
    );
  });

  return <Carousel items={carouselItems} />;
};

export default ImageQuoteCarousel;
