import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';

export default createSelector(cookSelectors.getData, data => {
  const vimeoVideoUrl = data?.context?.fields?.vimeoVideoUrl; // url for embedded videos
  const vimeoLiveEventUrl = data?.context?.fields?.vimeoLiveEventUrl; // url for embedded live videos

  let vimeoUrl;
  if (vimeoVideoUrl) {
    const vimeoId = vimeoVideoUrl?.split('/').pop();
    vimeoUrl = `https://player.vimeo.com/video/${vimeoId}`;
  } else if (vimeoLiveEventUrl) {
    vimeoUrl = `${vimeoLiveEventUrl}/embed`;
  }

  // Removes the protocol from the url
  vimeoUrl = vimeoUrl?.replace(/https?:/, '');

  const vimeoEventId = vimeoLiveEventUrl?.split('/').pop(); // id for chat url
  return {
    vimeoUrl,
    chatUrl: vimeoEventId ? `//vimeo.com/event/${vimeoEventId}/chat` : undefined,
  };
});
