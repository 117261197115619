import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Link from 'containers/atoms/LinkContainer';
import s from './InDepthItem.css';

const InDepthItem = ({ classes, children, href, darkMode, ...other }) => {
  const classNames = classnames(s.inDepthItem, darkMode && s.darkMode, classes);
  return (
    <li className={classNames}>
      <Link to={href} className={s.inDepthItemLink} {...other}>
        <FontAwesomeIcon icon={faArrowRight} className={s.inDepthItemIcon} />
        {children}
        {other.target === '_blank' && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={faExternalLinkAlt}
            className={s.inDepthItemExternalIcon}
          />
        )}
      </Link>
    </li>
  );
};

export default withStyles(s)(InDepthItem);
