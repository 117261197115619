import { createSelector } from 'reselect';

import getSearchResults from 'selectors/getSearchResults';
import getLiveEventBadgeType from '../utils/getLiveEventBadgeType';

export default createSelector(getSearchResults, results => {
  try {
    const imageKey = 'BASE_LANDSCAPE';

    return results.map(({ images, ...result }) => {
      const liveEventBadgeType = getLiveEventBadgeType({
        vimeoLiveEventUrl: result.fields?.vimeoLiveEventUrl,
        vimeoVideoUrl: result.fields?.vimeoVideoUrl,
        startDateISO: result.fields?.startDate,
        endDateISO: result.fields?.endDate,
      });

      return {
        ...result,
        images: images && {
          href: images?.images?.BASE?.LANDSCAPE?.href_full.replace(imageKey, 'LANDSCAPE_S'),
          alt: images.altText,
        },
        liveEventBadgeType,
      };
    });
  } catch (err) {
    console.error(err);
    return undefined;
  }
});
