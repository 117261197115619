/* eslint-disable css-modules/no-undef-class */

/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import Link from 'containers/atoms/LinkContainer';
import Typography from 'components/atoms/Typography';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import ButtonPrimary from 'components/atoms/buttons/ButtonPrimary';
import QuoteBox from 'components/molecules/QuoteBox/QuoteBox';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import renderStorylineElement from 'utils/renderStorylineElement';
import pickFrom from 'utils/pickFrom';
import boostrapGrid from 'style/bootstrap-grid.css';
import Vacancies from './components/Vacancies';
import studentImage from './studenter.jpg';
import Carousel from '../../organisms/Carousel/Carousel';
import s from './WorkPage.css';

const WorkPage = ({ groupedStorylineTree, quoteSlideshowData, keyFiguresData, pageData }) => {
  return (
    <div className={s.workPage}>
      <div className={classnames(boostrapGrid.container)}>
        <div className={classnames(boostrapGrid.row)}>
          <div className={getBootstrapColumns('20,2', '18,3', '12,6')}>
            <div className={s.contactList}>
              {groupedStorylineTree.headGroup &&
                groupedStorylineTree.headGroup.map(el => renderStorylineElement(el, false))}
              {groupedStorylineTree.contentGroup &&
                groupedStorylineTree.contentGroup.map(el => renderStorylineElement(el, false))}
            </div>
          </div>
        </div>
      </div>
      <div className={s.vacanciesWrapper}>
        <div className={classnames(boostrapGrid.container, s.vacancies)}>
          <div className={classnames(boostrapGrid.row, s.vacanciesRow)}>
            <div className={getBootstrapColumns('20,2', '18,3', '14,5')}>
              <Vacancies />
            </div>
          </div>
          <div className={classnames(boostrapGrid.row)}>
            <div className={getBootstrapColumns('20,2', '9,3', '7,5')}>
              <Typography variant="h3" gutterBottom classes={s.primaryColorHeadline}>
                Generell intresseanmälan
              </Typography>
              <Typography gutterBottom>
                Ovan finns våra lediga tjänster som du kan söka just nu. Finns inget som passar, är du
                välkommen att skicka en generell intresseanmälan.
              </Typography>
              <div className={s.externaLinkWrapper}>
                <Typography
                  href="https://web103.reachmee.com/ext/I006/433/apply?site=9&lang=SE&validator=3f25e0d3cbd97f6f397d43ecb6b8b018&job_id=-1"
                  target="_blank"
                  component="a"
                  variant="linkSmall"
                >
                  Skicka intresseanmälan
                </Typography>
                <div className={s.iconWrapper}>
                  <FontAwesomeIcon icon={faExternalLink} className={s.icon} />
                </div>
              </div>
            </div>
            <div className={getBootstrapColumns('20,2', '9,0', '7,0')}>
              <Typography variant="h3" gutterBottom classes={s.primaryColorHeadline}>
                Logga in för att ändra eller komplettera en ansökan
              </Typography>
              <Typography gutterBottom>
                Har du redan gjort en ansökan till ett jobb som du vill komplettera eller ändra på? Då kan du
                logga in med kontot du registrerade när du skickade in din ansökan.
              </Typography>
              <div className={s.externaLinkWrapper}>
                <Typography
                  href="https://web103.reachmee.com/ext/I006/433/profile?site=9&validator=3f25e0d3cbd97f6f397d43ecb6b8b018&lang=SE"
                  target="_blank"
                  component="a"
                  variant="linkSmall"
                >
                  Logga in
                </Typography>
                <div className={s.iconWrapper}>
                  <FontAwesomeIcon icon={faExternalLink} className={s.icon} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {quoteSlideshowData?.length > 0 && (
        <div className={s.quoteArea}>
          <div className={classnames(boostrapGrid.container)}>
            <div className={classnames(boostrapGrid.row)}>
              <div className={getBootstrapColumns('20,2', '18,3', '12,6')}>
                <div className={s.quoteBoxWrapper}>
                  {quoteSlideshowData && (
                    <Carousel
                      styleInner={{ paddingBottom: '4rem' }}
                      items={quoteSlideshowData.map(quote => {
                        const imageHref = pickFrom(quote, 'images.images.BASE.LANDSCAPE.href_full');
                        const target = quote.internalUrl ? '_self' : '_blank';
                        return (
                          <QuoteBox
                            imageSrc={imageHref && imageHref}
                            imageAlt={pickFrom(quote, 'images.altText', 'name')}
                            quote={quote.quote}
                            linkText={quote.linkText}
                            internalUrl={quote.internalUrl}
                            externalUrl={quote.externalUrl}
                            target={target}
                            inSlideshow
                            offset
                            key={quote.id}
                          />
                        );
                      })}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {pageData?.keyFigures && (
        <div className={classnames(boostrapGrid.container)}>
          <div className={s.keyFigures}>
            <div className={boostrapGrid.row}>
              <div className={getBootstrapColumns('20,2', '18,3', '12,6')}>
                {pageData?.keyFigures?.headline && (
                  <Typography variant="h2" classes={s.keyFiguresTitle}>
                    {pageData.keyFigures.headline}
                  </Typography>
                )}
                <div className={boostrapGrid.row}>
                  <div className={getBootstrapColumns('16')}>
                    <div className={s.leftCol}>
                      {pageData?.keyFigures?.texts?.map(obj => {
                        return (
                          <Typography key={`keyFiguresText${obj.id}`} classes={s.paragraph}>
                            {obj.text}
                          </Typography>
                        );
                      })}
                    </div>
                  </div>
                  <div className={getBootstrapColumns('8')}>
                    <div className={s.rightCol}>
                      {keyFiguresData?.map(figure => (
                        <div key={figure?.id} className={s.keyFigure}>
                          <Typography variant="h1Big" component="div" classes={s.number}>
                            {figure?.number?.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')}
                          </Typography>
                          <Typography variant="caption" component="div" classes={s.text}>
                            {figure?.text}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={classnames(boostrapGrid.container, s.fTalangWrapper)}>
        <div className={classnames(boostrapGrid.row)}>
          <div className={getBootstrapColumns('20,2', '9,3', '9,3')}>
            <ResponsiveImage
              src={studentImage}
              alt="Studenter"
              classes={s.ftalangImage}
              width={469}
              height={262}
            />
          </div>
          <div className={getBootstrapColumns('20,2', '9,0', '9,0')}>
            <div className={s.fTalangText}>
              <Typography variant="h2" gutterBottom classes={s.secondaryColorHeadline}>
                Jobbmöjligheter för studenter
              </Typography>
              <Typography gutterBottom>
                Hos Svenskt Näringsliv finns det flera jobbmöjligheter för studenter. Bland annat tar vi emot
                praktikanter och studentmedarbetare.
              </Typography>
              <ButtonPrimary
                component={Link}
                to="/om_oss/lediga-tjanster/student/"
                classes={s.fTalangGoToButton}
              >
                Läs mer här
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/* TO-DO: Lägg in länk (eller puffar) till denna sida: https://beta.svensktnaringsliv.net/om_oss/lediga-tjanster/student/ */

export default withStyles(s, boostrapGrid)(WorkPage);
