const getCorrespondingSection = (sections, boardMember) => {
  return sections.find(section => section.id === boardMember.role);
};

const getSortedBoardMembers = boardMembers => {
  if (boardMembers) {
    try {
      const sections = [];
      boardMembers.map(boardMember => {
        if (sections.length <= 0) {
          sections.push({
            id: boardMember?.role,
            boardMembers: [boardMember],
          });
        } else {
          const correspondingSection = getCorrespondingSection(sections, boardMember);
          if (correspondingSection) {
            correspondingSection.boardMembers.push(boardMember);
          } else {
            sections.push({
              id: boardMember?.role,
              boardMembers: [boardMember],
            });
          }
        }
        return undefined;
      });

      return sections;
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
};

export default getSortedBoardMembers;
