// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LinkButton-linkButton-Z1h-i{border-radius:2px;display:-ms-flexbox;display:flex;font-weight:700;margin:0 1rem 1rem 0;padding:1rem 1.8rem;text-decoration:none}.LinkButton-linkButton-Z1h-i.LinkButton-orange-38hNN{background:#ea8f12;color:#fff}.LinkButton-linkButton-Z1h-i.LinkButton-orange-38hNN:hover{background:#d88514}.LinkButton-linkButton-Z1h-i.LinkButton-steel-2BJsr{background:#62717f;color:#fff}.LinkButton-linkButton-Z1h-i.LinkButton-steel-2BJsr:hover{background:#798692}.LinkButton-facebookButton-2RgDo{background-color:#4267b2;color:#fff}.LinkButton-facebookButton-2RgDo:hover{background:#355aa5}", ""]);
// Exports
exports.locals = {
	"orange": "LinkButton-orange-38hNN",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "LinkButton-steel-2BJsr",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"linkButton": "LinkButton-linkButton-Z1h-i",
	"facebookButton": "LinkButton-facebookButton-2RgDo"
};
module.exports = exports;
