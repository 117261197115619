import { combineReducers } from 'redux';
import cook from 'ducks/cook';
import location from 'ducks/location';
import app from 'ducks/app';

export default combineReducers({
  cook,
  location,
  app,
});
