/* eslint-disable react/no-array-index-key */
/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import InDepthItem from 'components/molecules/InDepthItem';
import boostrapGrid from 'style/bootstrap-grid.css';
import Typography from 'components/atoms/Typography';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import Tag from 'components/atoms/Tag';
import s from './FocusAreaListSection.css';

const renderSmallList = (section, classNames) => {
  return (
    <div className={classNames}>
      <Typography variant="caption" classes={classnames(s.sectionTitle)}>
        {section.focusAreaClusterTitle}
      </Typography>
      <div className={s.focusAreas}>
        {section.focusAreas.length > 0 &&
          section.focusAreas.map((area, index) => {
            return (
              <div className={s.focusArea} key={index + area.id}>
                <InDepthItem href={area.href} classes={s.title}>
                  {area.title}
                </InDepthItem>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const renderBigList = (section, classNames) => {
  return (
    <div className={classNames}>
      <Typography variant="h2" classes={classnames(s.sectionTitle)}>
        {section.focusAreaClusterTitle}
      </Typography>
      <div className={s.focusAreas}>
        {section.focusAreas.length > 0 &&
          section.focusAreas.map((area, index) => {
            return (
              <div className={s.focusArea} key={index + area.id}>
                <div className={boostrapGrid.row}>
                  <div className={getBootstrapColumns('24', '8')}>
                    <InDepthItem href={area.href} classes={s.title}>
                      {area.title}
                    </InDepthItem>
                  </div>
                  <div className={getBootstrapColumns('24', '16')}>
                    <Typography>{area.description}</Typography>
                    {area.allIssues?.length > 0 && (
                      <div className={s.buttons}>
                        {area.allIssues?.map(issue => (
                          <Tag key={issue.id} href={issue.href}>
                            {issue.title}
                          </Tag>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const FocusAreaListSection = ({ section, big = false }) => {
  const classNames = classnames(s.focusAreaSection, big && s.big);

  return (
    <div className={big ? getBootstrapColumns('24', '24', '24') : getBootstrapColumns('24', '12', '8')}>
      {!big && renderSmallList(section, classNames)}
      {big && renderBigList(section, classNames)}
    </div>
  );
};

export default withStyles(boostrapGrid, s)(FocusAreaListSection);
