/* eslint-disable react/no-array-index-key */
/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import boostrapGrid from 'style/bootstrap-grid.css';
import Typography from 'components/atoms/Typography';
import InDepthItem from 'components/molecules/InDepthItem';
import InDepthList from 'components/molecules/InDepthList';
import s from './OtherSitesList.css';

const OtherSitesList = ({ classes }) => {
  const classNames = classnames(s.focusAreaList, classes);

  return (
    <div className={classNames}>
      <Typography variant="h2" classes={s.headline}>
        Besök våra andra sajter
      </Typography>

      <InDepthList>
        <InDepthItem href="https://www.tn.se/" target="_blank">
          Tidningen Näringslivet
        </InDepthItem>
        <InDepthItem href="https://www.ekonomifakta.se/" target="_blank">
          Ekonomifakta
        </InDepthItem>
        <InDepthItem href="https://www.foretagsklimat.se/" target="_blank">
          Företagsklimat
        </InDepthItem>
        <InDepthItem href="https://jobbamedlonen.se/" target="_blank">
          Jobba med lönen
        </InDepthItem>
        <InDepthItem href="https://www.avtalat.se/" target="_blank">
          Avtalat
        </InDepthItem>
        <InDepthItem href="https://ftalang.se/" target="_blank">
          fTalang
        </InDepthItem>
        <InDepthItem href="https://eduna.se/" target="_blank">
          Eduna
        </InDepthItem>
        <InDepthItem href="https://www.antligen18.se/" target="_blank">
          Äntligen 18!
        </InDepthItem>
        <InDepthItem href="https://www.naringslivetshus.se/" target="_blank">
          Näringslivets Hus
        </InDepthItem>
        <InDepthItem href="https://www.snprocess.se/" target="_blank">
          SN Process
        </InDepthItem>
      </InDepthList>
    </div>
  );
};

export default withStyles(boostrapGrid, s)(OtherSitesList);
