// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Medlemsbevis-container-1FbCS .Medlemsbevis-imageContainer-2YmD3{margin-block:10px}.Medlemsbevis-container-1FbCS .Medlemsbevis-imageContainer-2YmD3 img{outline:thin solid #dee4ea}.Medlemsbevis-container-1FbCS .Medlemsbevis-downloadLink-14w0z{color:#33414e;text-decoration:underline}.Medlemsbevis-container-1FbCS .Medlemsbevis-downloadLink-14w0z:hover{cursor:pointer}.Medlemsbevis-container-1FbCS .Medlemsbevis-downloadLinkPlaque-3JBFp{margin-top:1rem}.Medlemsbevis-container-1FbCS .Medlemsbevis-checkbox-jxIVZ{margin-block:1rem}.Medlemsbevis-container-1FbCS .Medlemsbevis-snPlaque-2PvIw{background-color:#fbfaf8;width:265px}.Medlemsbevis-container-1FbCS .Medlemsbevis-snPlaque-2PvIw .Medlemsbevis-plaqueCompanyName-1Th6K{font-size:22px;text-align:center}.Medlemsbevis-container-1FbCS .Medlemsbevis-snPlaque-2PvIw .Medlemsbevis-plaqueLogo-3nIN6{margin-top:26px}.Medlemsbevis-container-1FbCS .Medlemsbevis-snPlaque-2PvIw .Medlemsbevis-plaqueMemberSince-1KK1h{font-size:14px;margin-top:26px;text-align:center}.Medlemsbevis-container-1FbCS .Medlemsbevis-spinnerContainer-T_g72{margin-bottom:1rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"container": "Medlemsbevis-container-1FbCS",
	"imageContainer": "Medlemsbevis-imageContainer-2YmD3",
	"downloadLink": "Medlemsbevis-downloadLink-14w0z",
	"downloadLinkPlaque": "Medlemsbevis-downloadLinkPlaque-3JBFp",
	"checkbox": "Medlemsbevis-checkbox-jxIVZ",
	"snPlaque": "Medlemsbevis-snPlaque-2PvIw",
	"plaqueCompanyName": "Medlemsbevis-plaqueCompanyName-1Th6K",
	"plaqueLogo": "Medlemsbevis-plaqueLogo-3nIN6",
	"plaqueMemberSince": "Medlemsbevis-plaqueMemberSince-1KK1h",
	"spinnerContainer": "Medlemsbevis-spinnerContainer-T_g72"
};
module.exports = exports;
