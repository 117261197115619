import * as types from './types';

const fetchData = (path, snToken) => ({
  type: types.FETCH,
  payload: {
    path,
    snToken,
  },
});

const fetchSuccess = data => ({
  type: types.FETCH_SUCCESS,
  payload: {
    data: data.data,
    actionMetadata: data.actionMetadata,
  },
});

const fetchFailed = error => ({
  type: types.FETCH_FAILED,
  payload: {
    error,
  },
});

export { fetchData, fetchSuccess, fetchFailed };
