const reducer = teaserOptions => {
  try {
    const options = (teaserOptions ?? []).reduce((acc, val) => {
      acc[val.key] = val.key === 'useSummaryFields' ? val.value === 'true' : val.value;
      return acc;
    }, {});
    return options;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default reducer;
