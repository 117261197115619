import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import firstValueInQueryObject from 'utils/firstValueInQueryObject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import Typography from '../../../atoms/Typography';
import Translate from '../../../../containers/atoms/TranslateContainer';
import s from './YouHaveSearchedFor.css';

const YouHaveSearchedFor = ({ searchQuery, resetSearch }) => {
  const q = firstValueInQueryObject(searchQuery, 'q');

  return q && q !== 'undefined' && q !== '*' ? (
    <Typography variant="h5" component="h2" gutterBottom className={s.youHaveSearchedFor}>
      <Translate>LIST_PAGE.SEARCHED_ON</Translate> ”{q}”
      <button type="button" className={s.removeButton} onClick={resetSearch}>
        <FontAwesomeIcon icon={faTimesCircle} className={s.removeIcon} style={{ width: 20, height: 20 }} />
      </button>
    </Typography>
  ) : null;
};

export default withStyles(s)(YouHaveSearchedFor);
