import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, locationSelectors.getFullPath, (data, url) => {
  if (data) {
    try {
      let canonicalLink;
      if (url.includes('valfardsskaparna/resultat')) {
        canonicalLink = url;
      } else {
        canonicalLink = pickFrom(data, 'context.href', 'context.section.href');
      }
      return canonicalLink;
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
