import { connect } from 'react-redux';
import RegionOfficesPage from 'components/pages/RegionOfficesPage';

const mapStateToProps = undefined;

const mapDispatchToProps = undefined;

const RegionOfficesPageContainer = connect(mapStateToProps, mapDispatchToProps)(RegionOfficesPage);

export default RegionOfficesPageContainer;
