import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import blogPostNormalizer from 'utils/blogPostNormalizer';
import getTranslation from './getTranslation';

export default createSelector(cookSelectors.getData, getTranslation, (data, { LANG }) => {
  if (!data) {
    return undefined;
  }

  const posts = data.searchResults?.map(post => blogPostNormalizer(post, { lang: LANG }));
  return posts;
});
