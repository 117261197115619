// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ForbiddenPage-link-1qJop{color:#000;text-decoration:underline}.ForbiddenPage-link-1qJop:hover{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"link": "ForbiddenPage-link-1qJop"
};
module.exports = exports;
