import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Link from '../../../../containers/atoms/LinkContainer';
import s from './WrittenBy.css';
import sentencifyArray from '../../../../utils/sentencifyArray';

const WrittenBy = ({ authors }) => {
  return authors?.length > 0 ? (
    <p className={s.writtenBy}>
      <span className={s.title}>SKRIVET AV</span>
      {sentencifyArray(authors, {
        mapper: author =>
          author.href ? (
            <Link key={author.name} to={author.href}>
              {author.name}
            </Link>
          ) : (
            <span key={author.name}>{author.name}</span>
          ),
      })}
    </p>
  ) : null;
};

export default withStyles(s)(WrittenBy);
