/* eslint-disable no-unused-vars */
import { call, takeEvery, put } from 'redux-saga/effects';
import history from 'utils/history';
import { eventChannel } from 'redux-saga';
import noScroll from 'no-scroll';
import debounce from 'lodash/debounce';
import * as actions from './actions';

function createEventChannel() {
  return eventChannel(emitter => {
    let unlisten;

    const onHistoryChanged = (location, action) => {
      if (action === 'POP') {
        const { pathname, search } = location;
        const scrollPosition = location?.state?.scrollPosition || 0;
        emitter({
          pathname: pathname + search,
          scrollPosition,
        });
      }
    };

    const onScroll = debounce(() => {
      history.replace(window.location.pathname + window.location.search + window.location.hash, {
        scrollPosition: window.scrollY,
      });
    }, 350);

    if (typeof window !== 'undefined') {
      unlisten = history.listen(onHistoryChanged);
      window.addEventListener('scroll', onScroll);
    }

    return () => {
      if (unlisten) {
        unlisten();
        window.removeEventListener('scroll', onScroll);
      }
    };
  });
}

function* popstate({ pathname, scrollPosition }) {
  noScroll.off();
  yield put(actions.set(pathname, scrollPosition));
}

function* saga() {
  const channel = yield call(createEventChannel);

  yield takeEvery(channel, popstate);
}

export default saga;
