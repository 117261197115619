import * as types from './types';

const initialState = {
  path: undefined,
  snToken: undefined,
  data: undefined,
  actionMetadata: undefined,
  fetching: false,
  error: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH: {
      const { path, snToken } = action.payload;
      return {
        ...state,
        path,
        fetching: true,
        snToken,
      };
    }
    case types.FETCH_SUCCESS: {
      const { data, actionMetadata } = action.payload;
      return {
        ...state,
        data,
        actionMetadata,
        fetching: false,
        error: undefined,
      };
    }
    case types.FETCH_FAILED: {
      const { error } = action.payload;
      return {
        ...initialState,
        error,
      };
    }
    default:
      break;
  }
  return state;
};

export default reducer;
