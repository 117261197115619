import React from 'react';
import getImages from 'utils/getImages';
import classnames from 'classnames';
import Link from 'containers/atoms/LinkContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/pro-solid-svg-icons';
import Typography from 'components/atoms/Typography';
import useElementDims from '../../../utils/hooks/useElementDims';
import QuoteBox2 from './QuoteBox2';
import s from './QuoteBox.css';

// eslint-disable-next-line no-unused-vars
const QuoteBox = ({
  className,
  imageSrc,
  imageAlt,
  quote,
  name,
  profileLink,
  internalUrl,
  externalUrl,
  linkText,
  assignment,
  target,
  isAbsolute = false,
}) => {
  const sizes = getImages('PORTRAIT');
  const [ref, dims] = useElementDims();

  return (
    <div
      ref={ref}
      className={classnames(s.quoteBox, {
        [className]: !!className,
        [s.absolute]: isAbsolute,
        [s.sm]: dims.width >= 459,
        [s.md]: dims.width >= 830,
      })}
    >
      {imageSrc && <img className={s.image} src={sizes.toSrc(imageSrc, 'L')} alt={imageAlt} />}
      <div className={s.wing} />
      <figure className={s.content}>
        <blockquote>
          <FontAwesomeIcon icon={faQuoteRight} />
          <Typography component="p" variant="h3" className={s.quote}>
            {quote}
          </Typography>
          <cite>
            {profileLink && (
              <Typography href={profileLink} variant="link" component="a" target={target} className={s.name}>
                {`${name}${assignment ? `,` : ``}`}
              </Typography>
            )}
            {internalUrl && (
              <Typography to={internalUrl} variant="linkSmall" component={Link} className={s.name}>
                {linkText}
              </Typography>
            )}
            {externalUrl && (
              <Typography href={externalUrl} variant="link" component="a" target={target} className={s.name}>
                {linkText}
              </Typography>
            )}
            {!internalUrl && !externalUrl && !profileLink && (linkText || name) && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>{`${linkText || name}${assignment ? ` - ${assignment}` : ``}`}</>
            )}
          </cite>
        </blockquote>
      </figure>
    </div>
  );
};

// export default withStyles(s)(QuoteBox);
export default QuoteBox2;
