import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import Typography from '../../atoms/Typography';
import pushToDataLayer from '../../../utils/pushToDataLayer';
import s from './CollapsibleGroup.css';

const CollapsibleGroup = ({
  children,
  variant,
  isInitiallyExpanded,
  readMoreText,
  readLessText,
  darkMode,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyExpanded);

  return (
    <div
      className={classnames(s.collapsibleGroup, {
        [s.closed]: !isOpen,
        [className]: !!className,
      })}
    >
      <Typography
        className={classnames(s.button, {
          [s.dark]: darkMode,
          [s.bigColorBar]: variant === 'bigColorBar',
        })}
        component="button"
        variant="link"
        type="button"
        onClick={() => {
          setIsOpen(prev => !prev);
          if (!isOpen) {
            pushToDataLayer('collapsible_group_expand', {
              link_text: readMoreText,
              link_variant: variant,
            });
          } else {
            pushToDataLayer('collapsible_group_collapse', {
              link_text: readLessText,
              link_variant: variant,
            });
          }
        }}
      >
        {isOpen ? (
          <>
            {' '}
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{ width: 14, height: 14, marginRight: '10px' }}
            />{' '}
            {readLessText}
          </>
        ) : (
          <>
            {' '}
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ width: 14, height: 14, marginRight: '10px' }}
            />{' '}
            {readMoreText}
          </>
        )}
      </Typography>
      <motion.div
        className={s.inner}
        initial={false}
        animate={{ height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default withStyles(s)(CollapsibleGroup);

<i className="fa-regular " />;
