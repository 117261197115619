import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUser } from '@fortawesome/pro-light-svg-icons';
import s from './LoggedInHeader.css';

const LoggedInHeader = ({ userData }) => {
  return (
    <div className={s.container}>
      <div className={s.userLoggedInMenuItem}>
        <span className={s.name}>
          <FontAwesomeIcon icon={faUser} /> {userData?.name}
        </span>
        {userData?.activeCompany && (
          <span className={s.company}>
            <FontAwesomeIcon icon={faBuilding} />
            <span>{userData?.activeCompany?.name}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default withStyles(s)(LoggedInHeader);
