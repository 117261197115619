import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import reducer from 'utils/reduceTeaserOptions';
import pickFrom from 'utils/pickFrom';
import extractHighResolutionImage from 'utils/extractHighResolutionImage';

const extractBoardMember = (member, role) => {
  const highResolutionImages = pickFrom(member, 'content.highResolutionImages');
  const profileImageObj = pickFrom(member, 'content.highResolutionImages[0]');
  const teaserOptionsRaw = pickFrom(member, 'teaserOptions');
  let teaserOptions;

  try {
    teaserOptions = reducer(teaserOptionsRaw);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return undefined;
  }

  return {
    id: pickFrom(member, 'content.id'),
    name: pickFrom(member, 'content.title'),
    role,
    teaserOptions,
    highResolutionImages: highResolutionImages?.map(extractHighResolutionImage),
    fields: pickFrom(member, 'content.fields'),
    image: {
      id: pickFrom(profileImageObj, 'content.id'),
      href: pickFrom(profileImageObj, 'content.fields.BASE_LANDSCAPE.href_full'),
      altText: pickFrom(profileImageObj, 'content.fields.alttext'),
    },
  };
};

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, data => {
  if (data) {
    try {
      const boardMembersObj =
        pickFrom(data, 'context.deskedContent.boardMembers') ||
        pickFrom(data, 'boardMembersSection.page.deskedContent.boardMembers');
      const boardAlternatesObj =
        pickFrom(data, 'context.deskedContent.boardAlternates') ||
        pickFrom(data, 'boardMembersSection.page.deskedContent.boardAlternates');
      const boardMembers = boardMembersObj?.map(member => extractBoardMember(member, 'member')) || [];
      const boardAlternates =
        boardAlternatesObj?.map(member => extractBoardMember(member, 'alternate')) || [];

      return [...boardMembers, ...boardAlternates];
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return undefined;
    }
  }
  return undefined;
});
