import React from 'react';

export const LoginLoader = () => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem('isLoggingInTimeStamp', new Date().getTime().toString());
  }

  const containerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  };
  const wrapperStyles = {
    width: '520px',
  };
  const imgStyles = {
    width: '80px',
  };
  const headerStyles = {
    display: 'flex',
    alignItems: 'center',
    height: '75px',
    width: '480px',
    paddingInline: '20px',
    backgroundColor: '#33414e',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  };
  const contentStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '480px',
    padding: '20px',
    height: '165px',
    backgroundColor: '#DEE4EAFF',
    fontFamily: 'Arial, serif',
    fontSize: '1.25rem',
  };
  const footerStyles = {
    width: '480px',
    paddingInline: '20px',
    height: '32.25px',
    backgroundColor: '#C6CDD3FF',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  };

  return (
    <div style={containerStyles}>
      <div style={wrapperStyles}>
        <div style={headerStyles}>
          <img style={imgStyles} src="/nlLogoHeaderWhite.png" alt="logo" />
        </div>
        <div style={contentStyles}>Loggar in...</div>
        <div style={footerStyles}> </div>
      </div>
    </div>
  );
};
