import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import LatestArticle from 'components/molecules/LatestArticle';
import getImages from 'utils/getImages';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';

// eslint-disable-next-line css-modules/no-unused-class

import s from './ListArticles.css';

const getImage = item => ({
  image: item?.teaserImage?.[0] ?? item?.coverImage?.[0],
  format: item?.teaserImage[0] ? 'BASE_LANDSCAPE' : 'BASE_FREEFORM',
  mobileFormat: item?.teaserImage[0] ? 'SQUARE' : 'FREEFORM',
});

const ListArticles = ({ articleList = [], darkMode }) => {
  const [latestToShow, setLatestToShow] = useState(10);
  return articleList && Array.isArray(articleList) ? (
    <div>
      {articleList &&
        articleList.slice(0, latestToShow).map(item => {
          const href = item?.href;
          const title = item?.title;
          const contentType = item?.contentType;
          const summery = item?.fields?.teaser;
          const date = item?.published;

          const { image, format, mobileFormat } = getImage(item);
          const imageHref = image?.content?.fields?.[format]?.href_full;
          const imageAlt = image?.content?.fields?.alttext;
          const imageWidth =
            format === 'BASE_LANDSCAPE'
              ? image?.content?.fields?.[format]?.sourceWidth || image?.content?.fields?.[format]?.width
              : image?.content?.originalPictureSize?.width;
          const imageHeight =
            format === 'BASE_LANDSCAPE'
              ? image?.content?.fields?.[format]?.sourceHeight || image?.content?.fields?.[format]?.height
              : image?.content?.originalPictureSize?.height;
          const sizes = getImages(format.slice('BASE_'.length));
          const sizesMobile = getImages(mobileFormat);

          return (
            <LatestArticle
              darkMode={darkMode}
              key={item?.id}
              href={href}
              articleType={contentType}
              headline={title}
              day={date}
              text={summery}
              imageSrc={imageHref && sizes.toSrc(imageHref, 'M')}
              imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
              imageSizes="190px"
              imageMobileSrc={imageHref && sizes.toSrc(imageHref, 'XS')}
              imageMobileSrcSet={imageHref && sizesMobile.toSrcSet(imageHref)}
              imageMobileSizes="90px"
              imageAlt={imageAlt}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
            />
          );
        })}
      {articleList.length > latestToShow && (
        <ButtonSecondaryGhost
          darkMode={darkMode}
          key={`show-more-${latestToShow}`}
          classes={s.showMoreEvents}
          onClick={() => setLatestToShow(latestToShow + 10)}
        >
          Visa fler
        </ButtonSecondaryGhost>
      )}
    </div>
  ) : null;
};

export default withStyles(s)(ListArticles);
