import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import getImages from 'utils/getImages';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import s from './SectionBackground.css';

const COLOR_MAP = {
  orange: '234, 143, 18',
  red: '177, 47, 38',
  darkBlue: '51, 65, 78',
  steel: '98, 113, 127',
  creamyWhite: '250, 249, 247',
  lightBlue: '239, 242, 245',
  white: '255, 255, 255',
  DEFAULT: '255, 255, 255',
};

const IMAGE_SIZE_MAP = {
  xs: 'M',
  sm: 'M',
  md: 'L',
  lg: 'XL',
  xl: 'XL',
};

const getBgStyle = ({ src, srcTyp, backgroundTyp, bgOpacity, imageSize }) => {
  const opacity = !COLOR_MAP[backgroundTyp] ? 1 : bgOpacity;
  const bgColor = `rgba(${COLOR_MAP[backgroundTyp] || COLOR_MAP.DEFAULT}, ${opacity})`;

  const style = {
    boxShadow: `inset 0 0 0 99999px ${bgColor}`,
  };

  if (srcTyp === 'picture') {
    style.backgroundImage = `url(${getImages('FREEFORM').toSrc(src, imageSize)})`;
    style.backgroundSize = 'cover';
    style.backgroundRepeat = 'no-repeat';
  }

  if (srcTyp === 'video') {
    style.backgroundColor = bgColor;
    delete style.boxShadow;
  }

  if (bgOpacity === 1) {
    delete style.backgroundImage;
    delete style.backgroundSize;
    delete style.backgroundRepeat;
  }

  return style;
};

const SectionBackground = ({
  children,
  tiltedTop = '',
  tiltedBottom = '',
  backgroundTyp = '',
  src = '',
  srcTyp = '',
  isShowOutlineLogo = '',
  bgOpacity,
  anchorTarget,
}) => {
  const breakpoint = useBreakpoint();
  const imageSize = IMAGE_SIZE_MAP[breakpoint];

  const bgStyle = getBgStyle({ src, srcTyp, backgroundTyp, bgOpacity, imageSize });

  const getOutlineLogoStyleValue = () => {
    if (!tiltedTop && tiltedBottom) {
      return s.ShowOutlineLogoTiltedBottom;
    }
    if (!tiltedTop && tiltedBottom) {
      return s.tiltedBottom;
    }
    if (tiltedTop && tiltedBottom) {
      return s.ShowOutlineLogoTiltedBottom;
    }
    return s.ShowOutlineLogoBottom;
  };

  return (
    <div
      id={anchorTarget}
      className={classnames(s.sectionBackground, {
        [s.tiltedBottom]: !!tiltedBottom,
        [s.tiltedTop]: !!tiltedTop,
      })}
      style={bgStyle}
    >
      {srcTyp === 'video' && bgOpacity < 1 && (
        <>
          {COLOR_MAP[backgroundTyp] && (
            <div
              className={s.videoColorOverlay}
              style={{
                backgroundColor: `rgb(${COLOR_MAP[backgroundTyp]})`,
                opacity: bgOpacity,
              }}
            />
          )}
          <video playsInline autoPlay muted loop>
            <source src={src} type="video/mp4" className={s.video} />
          </video>
        </>
      )}
      {children}
      {isShowOutlineLogo === true && (
        <img alt="Outline Logo" className={getOutlineLogoStyleValue()} src="/snOutlineWings.svg" />
      )}
    </div>
  );
};

export default withStyles(s)(SectionBackground);
