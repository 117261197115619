import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import DownloadButton from 'components/atoms/DownloadButton';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import getTypeIcon from 'utils/getTypeIcon';
import getImages from 'utils/getImages';
import generateSizes from 'utils/generateSizes';
import Link from 'containers/atoms/LinkContainer';
import s from './RelatedItem.css';

const RelatedItem = ({ classes, data, noBorder, ...other }) => {
  const classNames = classnames(s.relatedItem, noBorder && s.noBorder, classes);
  const imageSizes = generateSizes({ default: '38px' });
  // eslint-disable-next-line camelcase
  const imageSrc = data?.images?.images?.BASE.LANDSCAPE?.href_full;
  const openLink = (event, url) => {
    if (window) {
      window.open(url, '_blank');
      event.preventDefault();
    }
  };
  const renderPre = () => (
    <div className={s.preImageWrapper}>
      {data.images ? (
        <ResponsiveImage
          src={getImages('PORTRAIT').toSrc(imageSrc, 'XS')}
          srcSet={getImages('PORTRAIT').toSrcSet(imageSrc)}
          sizes={imageSizes}
          alt={data.images.altText}
        />
      ) : (
        <FontAwesomeIcon icon={getTypeIcon(data.type)} className={s.fileIcon} />
      )}
    </div>
  );

  const renderText = () => (
    <div className={s.textWrapper}>
      <Typography variant="contentTypeDate" component="div">
        {data.overline}
      </Typography>
      <div className={s.title}>{data.title}</div>
    </div>
  );

  const renderButton = () => (
    <DownloadButton onClick={e => openLink(e, data.secondaryLink)}>
      <FontAwesomeIcon icon={getTypeIcon(data.secondaryExtension, true)} className={s.fileIconDownload} />
      Ladda ner
    </DownloadButton>
  );
  return (
    <Link
      to={data.primaryLink}
      target={
        data.content.contentType === 'link' || data.content.contentType === 'document' ? '_blank' : undefined
      }
      className={classNames}
      {...other}
    >
      <div className={s.desktop}>
        {renderPre()}
        {renderText()}
        {data.type === 'report' && data.secondaryLink && renderButton()}
      </div>
      <div className={s.mobile}>
        <div className={s.upperWrapper}>
          {renderText()}
          {renderPre()}
        </div>
        {data.type === 'report' && data.secondaryLink && (
          <div className={s.downloadButton}>{renderButton()}</div>
        )}
      </div>
    </Link>
  );
};

export default withStyles(s)(RelatedItem);
