import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
  faLock,
  faUser,
  faBuilding,
  faInfoCircle,
  faArrowUpRightFromSquare,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'containers/atoms/LinkContainer';
import useAuth from '../../../../../utils/hooks/useAuth';
import useClientSide from '../../../../../utils/hooks/useClientSide';
import withFeatureFlagGuard from '../../../../../utils/hocs/withFeatureFlagGuard';
import useUser from '../../../../../utils/hooks/useUser';
import UserCompaniesList from '../UserCompaniesList/UserCompaniesList';
import userMenu from '../userMenu';
import useGlobalState from '../../../../../zustand/zustand';
import s from './MobileUserListItem.css';
import LoggedInHeader from '../../../../molecules/LoggedInHeader/LoggedInHeader';

const MobileUserListItem = () => {
  const setMobileMenuOpen = useGlobalState(state => state.pageHeader.mobileMenu.setIsOpen);
  const setMobileLoggedInMenuOpen = useGlobalState(state => state.pageHeader.mobileLoggedInMenu.setIsOpen);
  const [isExpanded] = useState(true);
  const isClient = useClientSide();
  const { isAuthenticated, logout } = useAuth();
  const { userQuery } = useUser();

  if (!isClient) {
    return null;
  }

  const handleClick = () => {
    setMobileMenuOpen(false);
    setMobileLoggedInMenuOpen(false);
  };

  const handleLogout = () => {
    logout();
    setMobileLoggedInMenuOpen(false);
  };

  // TODO: Add correct URLs to list items
  return (
    <div
      className={classNames(s.mobileUserListItem, {
        [s.authenticated]: isAuthenticated,
      })}
    >
      {isAuthenticated && <LoggedInHeader userData={userQuery?.data?.userData} />}
      {isAuthenticated && (
        <AnimatePresence initial={false}>
          {isExpanded && (
            <motion.ul
              className={s.expandedList}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              transition={{ duration: 0.3 }}
            >
              {userQuery?.data?.userData?.companies?.length > 0 && <UserCompaniesList />}
              {userMenu.map(menuItem => (
                <li key={menuItem.id}>
                  <div>
                    {menuItem?.icon === 'user' && <FontAwesomeIcon icon={faUser} />}
                    {menuItem?.icon === 'building' && <FontAwesomeIcon icon={faBuilding} />}
                    {menuItem?.icon === 'info' && <FontAwesomeIcon icon={faInfoCircle} />}
                    <Link
                      onClick={handleClick}
                      to={menuItem.href}
                      target={menuItem?.icon === 'building' ? '_blank' : '_self'}
                    >
                      <span style={{ marginRight: 5 }}>{menuItem.linkText}</span>
                      {menuItem?.icon === 'building' && <FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                    </Link>
                  </div>
                </li>
              ))}
              <li>
                <div>
                  <FontAwesomeIcon icon={faLock} />
                  <button type="button" onClick={handleLogout}>
                    Logga ut
                  </button>
                </div>
              </li>
            </motion.ul>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

export default withFeatureFlagGuard(withStyles(s)(MobileUserListItem), 'disablePremiumContent', true);
