/* eslint-disable no-underscore-dangle */
import parseBreakpoints from './parseBreakpoints';
import sortAnnotations from './sortAnnotations';

const extractFields = (fields, avsnittTextColor) => {
  const newFields = fields.reduce((acc, current) => {
    const key = current.name;

    if (key === 'textColor') {
      return {
        ...acc,
        [key]: current.value === 'inherit' ? avsnittTextColor : current.value ?? avsnittTextColor,
      };
    }

    if (
      key === 'gridColumnStart' ||
      key === 'gridColumnEnd' ||
      key === 'gridRowStart' ||
      key === 'gridRowEnd' ||
      key === 'spacing'
    ) {
      return {
        ...acc,
        [key]: parseBreakpoints(current.value),
      };
    }

    if (current.__typename === 'StoryElementTextField') {
      return {
        ...acc,
        [key]: {
          rawText: current.value,
          annotations: current.annotations?.slice().sort(sortAnnotations),
        },
      };
    }

    if (current.__typename === 'StoryElementBooleanField') {
      return {
        ...acc,
        [key]: current.booleanValue,
      };
    }

    if (current.__typename === 'StoryElementNumberField') {
      return {
        ...acc,
        [key]: current.numberValue,
      };
    }

    return {
      ...acc,
      [key]: current.value,
    };
  }, {});

  // Inherit textColor from avsnitt if not set
  if (!('textColor' in newFields)) {
    newFields.textColor = avsnittTextColor;
  }

  return newFields;
};

export default extractFields;
