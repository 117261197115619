import React, { useEffect, useState } from 'react';
import axios from 'axios';
import withStyles from 'isomorphic-style-loader/withStyles';
// eslint-disable-next-line import/no-cycle
import renderStorylineElement from 'utils/renderStorylineElement';
import s from './HanteraNyhetsbrev.css';
import { getFrontEndOrigin } from '../../../../../utils/apiUtil';
import NewsletterSubscriptionsContainer from '../../../NewsletterSubscriptionsContainer/NewsletterSubscriptionsContainer';

const HanteraNyhetsbrev = ({ groupedStorylineTree }) => {
  const { headGroup, contentGroup } = groupedStorylineTree;
  const [newsletters, setNewsletters] = useState([]);
  const [loadingNewsletters, setLoadingNewsletters] = useState(false);

  const loadNewsletters = async () => {
    setLoadingNewsletters(true);
    const endpointUrl = `${getFrontEndOrigin()}/plate/nyhetsbrev/`;
    const results = await axios.get(endpointUrl);
    const nl = results?.data?.data?.context?.deskedContent?.newsletters;

    if (nl?.length > 0) {
      const processedData = nl.map(item => item.content);
      setNewsletters(processedData);
    }
    setLoadingNewsletters(false);
  };

  useEffect(() => {
    loadNewsletters();
  }, []);

  return (
    <div className={s.container}>
      {headGroup && headGroup.map(renderStorylineElement)}
      {contentGroup && contentGroup.map(renderStorylineElement)}
      {!loadingNewsletters && newsletters?.length > 0 && (
        <NewsletterSubscriptionsContainer newsletters={newsletters} />
      )}
    </div>
  );
};

export default withStyles(s)(HanteraNyhetsbrev);
