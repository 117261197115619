import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, data => {
  try {
    const searchResults = pickFrom(data, 'searchFacets');
    return searchResults?.reduce((acc, val) => {
      acc[val.field] = val.facets;
      if (val.field === 'year') {
        const sortedFacets = val.facets?.sort((a, b) => +b.value - +a.value);
        acc[val.field] = sortedFacets;
      }
      return acc;
    }, {});
  } catch (e) {
    console.error(e);
    return undefined;
  }
});
