import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      return pickFrom(data, 'resolution.section.uniqueName');
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
