const getRoot = store => store.cook;
const getPath = store => getRoot(store).path;
const getSnToken = store => getRoot(store).snToken;
const getData = store => getRoot(store).data;
const getActionMetadata = store => getRoot(store).actionMetadata;
const isFetching = store => getRoot(store).fetching;
const getError = store => getRoot(store).error;
const getEdited = store => getData(store).context?.edited;

export { getPath, getSnToken, getData, getActionMetadata, isFetching, getError, getEdited };
