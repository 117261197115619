import { connect } from 'react-redux';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import getBreadcrumbs from 'selectors/getBreadcrumbs';
import getShowBreadcrumbs from 'selectors/getShowBreadcrumbs';
import getRootGroupType from 'selectors/getRootGroupType';

const mapStateToProps = state => {
  const { isMirosite } = getRootGroupType(state);
  return {
    breadcrumbs: getBreadcrumbs(state),
    showBreadcrumbs: getShowBreadcrumbs(state),
    rootGroupType: getRootGroupType(state),
    isMirosite,
  };
};

const mapDispatchToProps = undefined;

const BreadcrumbsContainer = connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);

export default BreadcrumbsContainer;
