import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import clientifyUrl from '../utils/clientifyUrl';

export default createSelector(cookSelectors.getData, data => {
  try {
    const latestAndSelectedReports = data?.context?.deskedContent?.latestReports;
    return latestAndSelectedReports.map(report => {
      return {
        title: report?.title,
        href: clientifyUrl(report?.href),
        coverImage: report?.coverImage?.[0]?.content.fields.BASE_FREEFORM.href_full,
        published: report?.published,
        secondaryLink: report?.document?.[0]?.content?.binary?.href,
      };
    });
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
