import { connect } from 'react-redux';
import PageScroll from 'components/atoms/PageScroll';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';

const mapStateToProps = state => ({
  data: cookSelectors.getData(state),
  scrollPosition: locationSelectors.getScrollPosition(state),
});

const mapDispatchToProps = undefined;

const PageScrollContainer = connect(mapStateToProps, mapDispatchToProps)(PageScroll);

export default PageScrollContainer;
