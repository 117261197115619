import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faCheckCircle,
  faInfoCircle,
  faWarning,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
import s from './Toast.css';
import Typography from '../../atoms/Typography/Typography';

const Toast = ({ dismiss, type, message }) => {
  return (
    <div className={s.container}>
      <div className={s.close} onClick={dismiss}>
        <FontAwesomeIcon icon={faClose} size="1x" />
      </div>
      <div
        className={classnames(s.side, {
          [s.success]: type === 'success',
          [s.info]: type === 'info',
          [s.warning]: type === 'warning',
          [s.error]: type === 'error',
        })}
      />
      <div
        className={classnames(s.content, {
          [s.success]: type === 'success',
          [s.info]: type === 'info',
          [s.warning]: type === 'warning',
          [s.error]: type === 'error',
        })}
      >
        <Typography variant="paragraph">{message}</Typography>
        {type === 'success' && (
          <FontAwesomeIcon icon={faCheckCircle} className={classnames(s.colorSuccess, s.spinner)} size="2x" />
        )}
        {type === 'info' && (
          <FontAwesomeIcon icon={faInfoCircle} className={classnames(s.colorInfo, s.spinner)} size="2x" />
        )}
        {type === 'warning' && (
          <FontAwesomeIcon icon={faWarning} className={classnames(s.colorWarning, s.spinner)} size="2x" />
        )}
        {type === 'error' && (
          <FontAwesomeIcon icon={faTimesCircle} className={classnames(s.colorError, s.spinner)} size="2x" />
        )}
      </div>
    </div>
  );
};

export default withStyles(s)(Toast);
