import React, { useEffect, useState } from 'react';
import LazyLoad, { forceCheck } from 'react-lazyload';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import s from './ResponsiveImage.css';

const ResponsiveImage = ({
  classes,
  className,
  profileCard,
  alt = '',
  lazyload = {},
  width,
  height,
  lazyLoadWrapperHeight,
  lazyLoadWrapperMaxHeight,
  src,
  srcSet,
  sizes,
  ...other
}) => {
  useEffect(() => {
    if (window.location.href.includes('/medarbetare/')) {
      const interval = setInterval(forceCheck, 1000);
      return () => clearInterval(interval);
    }
    return () => {};
  }, []);

  const [hasError, setHasError] = useState(false);
  const classNames = classnames(classes, s.responsiveImage, hasError && s.hasError, {
    [className]: !!className,
  });
  const classNamesProfileCard = classnames(classes, s.responsiveProfileCardImage, hasError && s.hasError);
  const useAspectRatio = !!width && !!height;
  const isSvg = src && src.endsWith('.svg');
  const adjustedSrc = isSvg ? src.replace(/\/alternates\/[^/]+\//, '/') : src;

  return (
    <LazyLoad
      offset={400}
      {...lazyload}
      style={{
        ...(lazyLoadWrapperMaxHeight ? { maxHeight: lazyLoadWrapperMaxHeight } : {}),
        ...(useAspectRatio
          ? {
              aspectRatio: `${width} / ${height}`,
              position: 'relative',
              ...(lazyLoadWrapperHeight ? { height: lazyLoadWrapperHeight } : {}),
            }
          : {}),
      }}
    >
      <div className={isSvg ? s.svgContainer : ''} style={isSvg ? { paddingTop: '56.25%' } : {}}>
        <img
          {...(useAspectRatio ? { width, height } : {})}
          className={profileCard ? classNamesProfileCard : classNames}
          alt={alt}
          src={adjustedSrc}
          {...(isSvg ? {} : { srcSet, sizes })}
          {...other}
          onError={() => setHasError(true)}
          onLoad={() => setHasError(false)}
        />
      </div>
    </LazyLoad>
  );
};

export default withStyles(s)(ResponsiveImage);
