// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextAreaField-textAreaField-2r2Ty{margin-bottom:20px}", ""]);
// Exports
exports.locals = {
	"textAreaField": "TextAreaField-textAreaField-2r2Ty"
};
module.exports = exports;
