export const MUNICIPALITY_SCHABLON = 286976;

export const VALFARDSTJANSTER = {
  forskoleplats: 'forskoleplats',
  grundskola: 'grundskola',
  gymnasielarare: 'gymnasielarare',
  polis: 'polis',
  sjukskoterska: 'sjukskoterska',
  specialistlakare: 'specialistlakare',
  underskoterskaIHemtjanst: 'underskoterskaIHemtjanst',
};

export const AVTRYCK_PER_VALFARDSTJANSTER = {
  [VALFARDSTJANSTER.forskoleplats]: 169500,
  [VALFARDSTJANSTER.grundskola]: 30362601,
  [VALFARDSTJANSTER.gymnasielarare]: 632393,
  [VALFARDSTJANSTER.polis]: 611892,
  [VALFARDSTJANSTER.sjukskoterska]: 648163,
  [VALFARDSTJANSTER.specialistlakare]: 1362563,
  [VALFARDSTJANSTER.underskoterskaIHemtjanst]: 484151,
};

export const VALFARDSTJANSTER_NAMES = {
  [VALFARDSTJANSTER.forskoleplats]: {
    singular: 'förskoleplats',
    plural: 'förskoleplatser',
  },
  [VALFARDSTJANSTER.grundskola]: {
    singular: 'grundskola',
    plural: 'grundskolor',
  },
  [VALFARDSTJANSTER.gymnasielarare]: {
    singular: 'gymnasielärare',
    plural: 'gymnasielärare',
  },
  [VALFARDSTJANSTER.polis]: {
    singular: 'polis',
    plural: 'poliser',
  },
  [VALFARDSTJANSTER.sjukskoterska]: {
    singular: 'sjuksköterska',
    plural: 'sjuksköterskor',
  },
  [VALFARDSTJANSTER.specialistlakare]: {
    singular: 'specialistläkare',
    plural: 'specialistläkare',
  },
  [VALFARDSTJANSTER.underskoterskaIHemtjanst]: {
    singular: 'undersköterska i hemtjänsten',
    plural: 'undersköterskor i hemtjänsten',
  },
};

export const getSkatteintakter = (lonekostnaderPerAr, bolagsskattPerAr, totalSkatt) => {
  return totalSkatt
    ? parseInt(totalSkatt, 10)
    : parseInt(lonekostnaderPerAr, 10) * 0.42398 + parseInt(bolagsskattPerAr, 10);
};

export const calcAntalValfardtjanst = (lonekostnaderPerAr, bolagsskattPerAr, valfardstjanst, totalSkatt) => {
  let totalCost;
  if (totalSkatt && totalSkatt !== 'undefined') {
    totalCost = parseInt(totalSkatt, 10);
  } else {
    totalCost = parseInt(lonekostnaderPerAr, 10) * 0.42398 + parseInt(bolagsskattPerAr, 10);
  }
  return Math.round(totalCost / AVTRYCK_PER_VALFARDSTJANSTER[valfardstjanst]);
};

export const calcNrOfValfardtjanst = (nrOfEmployed, valfardstjanst) => {
  return Math.round((nrOfEmployed * MUNICIPALITY_SCHABLON) / AVTRYCK_PER_VALFARDSTJANSTER[valfardstjanst]);
};

export const calcKey = ({ companyName, antalAnstallda, salt }) => {
  return (companyName.length + parseInt(antalAnstallda, 10) + salt).toString();
};

export const tempusify = (nr, valfardstjanst) => {
  return nr === 1
    ? VALFARDSTJANSTER_NAMES[valfardstjanst].singular
    : VALFARDSTJANSTER_NAMES[valfardstjanst].plural;
};
