import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faSignalStream } from '@fortawesome/pro-regular-svg-icons';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import DateCalendar from 'components/atoms/DateCalendar';
import getImages from 'utils/getImages';
import exists from 'utils/exists';
import Translator, { getLang } from 'utils/translator';
import { generateFromToToString, formatDate } from 'utils/dateUtil';
import contentTypeTranslations from 'translations/contentType.json';
import documentTypeTranslations from 'translations/documentType.json';
import { format } from 'date-fns';
import { sv, enGB } from 'date-fns/locale';
import s from './SearchResultItem.css';
import useTranslate from '../../../utils/hooks/useTranslate';
import LiveEventBadge from '../LiveEventBadge/LiveEventBadge';

const t = Translator({
  sv: {
    ...contentTypeTranslations.sv,
    ...documentTypeTranslations.sv,
  },
  en: {
    ...contentTypeTranslations.en,
    ...documentTypeTranslations.en,
  },
});

const validateDate = date => date !== '' && date !== undefined;

const generateImageObject = (contentType, imageHref, imageWidth, imageHeight, imageData) => {
  let sizes;
  let sizesMobile;
  let imageSrc;
  let imageSrcSet;
  let imageSizes;
  let imageMobileSrc;
  let imageMobileSrcSet;
  let imageMobileSizes;
  let width;
  let height;
  let widthMobile;
  let heightMobile;
  let lazyLoadWrapperHeight;
  let lazyLoadWrapperHeightMobile;

  switch (contentType) {
    case 'story':
    case 'tv':
    case 'event':
    case 'memberOrganisation':
      sizes = getImages('LANDSCAPE');
      width = imageWidth;
      height = imageHeight;
      widthMobile = 200;
      heightMobile = 200;
      lazyLoadWrapperHeight = 80;
      lazyLoadWrapperHeightMobile = 70;

      sizesMobile = getImages('SQUARE');

      imageSrc = imageHref && sizes.toSrc(imageHref, 'M');
      imageSrcSet = imageHref && sizes.toSrcSet(imageHref);
      imageSizes = '190px';
      imageMobileSrc = imageHref && sizes.toSrc(imageHref, 'XS');
      imageMobileSrcSet = imageHref && sizesMobile.toSrcSet(imageHref);
      imageMobileSizes = '90px';
      break;
    case 'profile':
      sizes = getImages('PORTRAIT');
      sizesMobile = getImages('PORTRAIT');

      lazyLoadWrapperHeight = 125;
      lazyLoadWrapperHeightMobile = 95;

      width = 147;
      height = 190;
      widthMobile = 147;
      heightMobile = 190;

      imageSrc = imageHref && sizes.toSrc(imageHref, 'M');
      imageSrcSet = imageHref && sizes.toSrcSet(imageHref);
      imageSizes = '120px';
      imageMobileSrc = imageHref && sizes.toSrc(imageHref, 'XS');
      imageMobileSrcSet = imageHref && sizesMobile.toSrcSet(imageHref);
      imageMobileSizes = '90px';
      break;
    case 'report':
      sizes = getImages('FREEFORM');
      sizesMobile = getImages('FREEFORM');

      width = 101;
      height = 140;
      widthMobile = 101;
      heightMobile = 140;

      if (imageData?.freeFormSourceWidth && imageData?.freeFormSourceHeight) {
        height = width / (imageData.freeFormSourceWidth / imageData.freeFormSourceHeight);
      } else if (imageData?.originalSize?.width && imageData?.originalSize?.width) {
        const originalWidth = imageData.originalSize.width;
        const originalHeight = imageData.originalSize.height;

        if (originalWidth && originalHeight) {
          const derivedHeight = Math.round(
            width / (parseInt(originalWidth, 10) / parseInt(originalHeight, 10))
          );
          height = derivedHeight || height;
        }
      }

      // lazyLoadWrapperHeight = 140;
      lazyLoadWrapperHeight = height;
      lazyLoadWrapperHeightMobile = 101;

      imageSrc = imageHref && sizes.toSrc(imageHref, 'M');
      imageSrcSet = imageHref && sizes.toSrcSet(imageHref);
      imageSizes = '120px';
      imageMobileSrc = imageHref && sizes.toSrc(imageHref, 'XS');
      imageMobileSrcSet = imageHref && sizesMobile.toSrcSet(imageHref);
      imageMobileSizes = '90px';
      break;
    default:
      break;
  }

  return {
    imageSrc,
    imageSrcSet,
    imageSizes,
    imageMobileSrc,
    imageMobileSrcSet,
    imageMobileSizes,
    width,
    height,
    widthMobile,
    heightMobile,
    lazyLoadWrapperHeight,
    lazyLoadWrapperHeightMobile,
  };
};

const IconNode = ({ icon, text }) => (
  <div className={s.iconNode}>
    <div className={s.iconNodeImageWrapper}>
      <FontAwesomeIcon icon={icon} className={s.iconNodeImage} />
    </div>
    <span className={s.iconNodeText}>{text}</span>
  </div>
);

const SearchResultItem = ({
  classes,
  href,
  contentType,
  title,
  assignment,
  description,
  published,
  teaser,
  imageHref,
  imageAlt,
  imageWidth,
  imageHeight,
  from,
  to,
  hideTimeInformation,
  customImage,
  location,
  implementationType,
  deadlineDate,
  binaryExtension,
  binaryHref,
  noBorder,
  hideDate,
  liveEventBadgeType,
  stackLayout,
  hideAdditionalEventInfo,
  imageData,
  ...other
}) => {
  const translate = useTranslate();
  const classNames = classnames(
    s.searchResultItem,
    classes,
    noBorder && s.noBorder,
    stackLayout && s.stackLayout
  );

  const dateToString = dateString => {
    const date = new Date(dateString);
    return format(date, 'd MMMM yyyy', { locale: getLang() === 'sv' ? sv : enGB });
  };

  const imageObject = generateImageObject(contentType, imageHref, imageWidth, imageHeight, imageData);

  const showDateFor = [
    'document',
    'pressRelease',
    'publication',
    'remiss',
    'skrivelse',
    'story',
    'tv',
    'report',
  ];

  const deadlineString = `Anmäl dig senast ${formatDate(deadlineDate)}`;

  const showLiveEventBadge = ['live', 'future'].includes(liveEventBadgeType);

  return (
    <Link
      to={binaryHref || href}
      target={binaryHref ? '_blank' : undefined}
      className={classNames}
      {...other}
    >
      <div className={s.textBlock}>
        <Typography variant="contentTypeDate" classes={s.latestOverline}>
          {t(binaryExtension) || translate(`CONTENT_TYPE.${contentType}`) || contentType}
          {showDateFor.indexOf(contentType) >= 0 && published && (
            <span className={s.day}>{` — ${dateToString(published)}`}</span>
          )}
        </Typography>

        <Typography
          variant="h3"
          classes={classnames(s.searchResultItemHeadline, teaser && s.marginBottom)}
          gutterBottom={false}
        >
          {title}
        </Typography>
        {assignment && (
          <Typography
            variant="paragraphLarge"
            component="div"
            classes={classnames(s.searchResultItemAssignment, s.marginBottom)}
            gutterBottom={false}
          >
            {assignment}
          </Typography>
        )}
        <div>
          <Typography gutterBottom={false} component="span">
            {teaser || description}
          </Typography>
        </div>
        <div className={s.eventBoxIcons}>
          {validateDate(from) && validateDate(to) && (
            <IconNode
              icon={faCalendarAlt}
              text={generateFromToToString(hideTimeInformation, from, to, { showYear: true })}
            />
          )}
          {implementationType !== 'live' && exists(location) && (
            <IconNode icon={faMapMarkerAlt} text={location} />
          )}
          {!hideAdditionalEventInfo && implementationType === 'live' && (
            <IconNode icon={faSignalStream} text={translate('EVENT_PAGE.EVENT_LIVE')} />
          )}
          {!hideAdditionalEventInfo && implementationType === 'liveAndPhysical' && (
            <IconNode icon={faSignalStream} text={translate('EVENT_PAGE.EVENT_LIVE_AND_PHYSICAL')} />
          )}
          {!hideAdditionalEventInfo && validateDate(deadlineDate) && (
            <IconNode icon={faInfoCircle} text={deadlineString} />
          )}
        </div>

        {showLiveEventBadge && <LiveEventBadge type={liveEventBadgeType} className={s.liveEventBadge} />}
      </div>

      <div className={s.videoThumbnailWrapper} style={{ textAlign: 'right' }}>
        {customImage && customImage()}
        {!customImage && imageObject.imageSrc && (
          <div
            className={classnames(
              s.imageBlock,
              (contentType === 'report' || contentType === 'portrait') && s.portrait
            )}
          >
            <ResponsiveImage
              src={imageObject.imageSrc}
              srcSet={imageObject.imageSrcSet}
              sizes={imageObject.imageSizes}
              alt={imageAlt}
              width={imageObject.width}
              height={imageObject.height}
              lazyLoadWrapperHeight={imageObject.lazyLoadWrapperHeight}
            />
          </div>
        )}
        {!customImage && imageObject.imageMobileSrc && (
          <div className={s.imageMobileBlock}>
            <ResponsiveImage
              src={imageObject.imageMobileSrc}
              srcSet={imageObject.imageMobileSrcSet}
              sizes={imageObject.imageMobileSizes}
              alt={imageAlt}
              width={imageObject.widthMobile}
              height={imageObject.heightMobile}
              lazyLoadWrapperHeight={imageObject.lazyLoadWrapperHeightMobile}
            />
          </div>
        )}
        {!hideDate && validateDate(from) && <DateCalendar date={from} />}
      </div>
    </Link>
  );
};

export default withStyles(s)(SearchResultItem);
