import { connect } from 'react-redux';
import GTMDataLayer from 'components/atoms/GTMDataLayer';
import getGTMDataLayerData from 'selectors/getGTMDataLayerData';

const mapStateToProps = state => ({
  data: getGTMDataLayerData(state),
});

const mapDispatchToProps = undefined;

const GTMDataLayerContainer = connect(mapStateToProps, mapDispatchToProps)(GTMDataLayer);

export default GTMDataLayerContainer;
