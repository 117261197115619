/* eslint-disable camelcase */

import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import Typography from 'components/atoms/Typography';
import Translator from 'utils/translator';
import inQueryObject from 'utils/inQueryObject';
import contentTypeTranslations from 'translations/contentTypeLowerCase.json';
import FilterBall from 'components/atoms/FilterBall';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import s from './FilterBlock.css';

const t = Translator(contentTypeTranslations);

const defaultCountToShow = 6;
const increaseCountWith = 30;

const FilterBlock = ({
  searchQuery,
  filters,
  facets,
  selectFilter,
  unselectFilter,
  clearFilters,
  mobileMode,
  closeModal,
}) => {
  const [showMoreState, setShowMoreState] = useState({
    contenttype: 0,
    issue: 0,
    focusarea: 0,
    year: 0,
  });

  useEffect(() => {
    setShowMoreState({
      contenttype: 0,
      issue: 0,
      focusarea: 0,
      year: 0,
    });
  }, [searchQuery]);

  const setShowMore = (key, val) => {
    const newShowMoreState = {
      ...showMoreState,
    };
    newShowMoreState[key] += val;
    setShowMoreState(newShowMoreState);
  };

  const renderFacet = (facet, headline, key, valueTransformer = val => val) => (
    <div className={s.facet}>
      <div className={s.headline}>{headline}</div>

      {facet?.slice(0, defaultCountToShow + increaseCountWith * showMoreState[key]).map(item => {
        const firstInValue = item.value?.split('_')?.[0] || item.value;
        return (
          <FilterBall
            key={item.value}
            count={item.count}
            selected={inQueryObject(filters, key, firstInValue)}
            onClick={() =>
              inQueryObject(filters, key, firstInValue)
                ? unselectFilter(key, firstInValue)
                : selectFilter(key, firstInValue)
            }
          >
            {valueTransformer(item.value)}
          </FilterBall>
        );
      })}
      <div className={s.moreLessBox}>
        {facet?.length > defaultCountToShow &&
          defaultCountToShow + increaseCountWith * showMoreState[key] > defaultCountToShow && (
            <div
              className={s.showMoreFilters}
              key={`decrease-${defaultCountToShow + increaseCountWith * showMoreState[key]}`}
            >
              <Typography
                variant="link"
                component="button"
                style={{ textDecoration: 'none' }}
                onClick={() => setShowMore(key, -1)}
              >
                - Visa färre
              </Typography>
            </div>
          )}
        {facet?.length > defaultCountToShow &&
          defaultCountToShow + increaseCountWith * showMoreState[key] < facet.length && (
            <div
              className={s.showMoreFilters}
              key={`increase-${defaultCountToShow + increaseCountWith * showMoreState[key]}`}
            >
              <Typography
                variant="link"
                component="button"
                style={{ textDecoration: 'none' }}
                onClick={() => setShowMore(key, 1)}
              >
                + Visa fler
              </Typography>
            </div>
          )}
      </div>
    </div>
  );

  return (
    <div className={classnames(s.filterBlock, mobileMode && s.mobileMode)}>
      <div className={s.filterBallContainer}>
        {facets?.contenttype?.length > 0 &&
          renderFacet(facets.contenttype, 'Typ av innehåll', 'contenttype', val => t(val))}
        {facets?.focusarea_facet?.length > 0 &&
          renderFacet(facets.focusarea_facet, 'Sakomraden', 'focusarea', val => val?.split('_')?.[1])}
        {facets?.issue_facet?.length > 0 &&
          renderFacet(facets.issue_facet, 'Frågor', 'issue', val => val?.split('_')?.[1])}
        {facets?.year?.length > 0 && renderFacet(facets?.year, 'Årtal', 'year')}
      </div>
      <div className={s.buttonContainer}>
        <ButtonSecondaryGhost onClick={clearFilters}>Rensa</ButtonSecondaryGhost>
        {mobileMode && (
          <ButtonSecondaryGhost classes={s.showResultButton} onClick={closeModal}>
            Visa resultat
          </ButtonSecondaryGhost>
        )}
      </div>
    </div>
  );
};

export default withStyles(s)(FilterBlock);
