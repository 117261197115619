// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Vacancies-vacancyLink-2SJBI:hover{text-decoration:underline}.Vacancies-vacancyItemMobile-3aCtX{margin-bottom:1.5rem}.Vacancies-vacancyItemMobile-3aCtX .Vacancies-vacancyLink-2SJBI{text-decoration:underline}.Vacancies-arrowRight-ooln8{color:#ea8f12;font-size:14px;height:14px;margin-left:1rem;margin-right:1rem;width:auto}.Vacancies-titleContainer-2YsAA{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.Vacancies-table-8GMEj table,.Vacancies-table-8GMEj tbody,.Vacancies-table-8GMEj td,.Vacancies-table-8GMEj tfoot,.Vacancies-table-8GMEj th,.Vacancies-table-8GMEj thead,.Vacancies-table-8GMEj tr{-webkit-border-horizontal-spacing:0;-webkit-border-vertical-spacing:0;border:none;border-collapse:inherit;border-color:inherit;border-spacing:0;font-weight:inherit;height:auto;margin:0;padding:0;text-align:left;width:auto}.Vacancies-table-8GMEj tbody tr{background-color:#fff;border:1px solid #dee4ea}.Vacancies-mobileList-18QAl{display:block}@media (min-width:820px){.Vacancies-mobileList-18QAl{display:none}}.Vacancies-table-8GMEj{border-collapse:collapse;display:none}.Vacancies-table-8GMEj thead{white-space:nowrap}.Vacancies-table-8GMEj td,.Vacancies-table-8GMEj th{padding-bottom:8px;padding-right:30px;padding-top:8px}.Vacancies-table-8GMEj td:last-child,.Vacancies-table-8GMEj th:last-child{padding-right:0}@media (min-width:820px){.Vacancies-table-8GMEj{display:block}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"vacancyLink": "Vacancies-vacancyLink-2SJBI",
	"vacancyItemMobile": "Vacancies-vacancyItemMobile-3aCtX",
	"arrowRight": "Vacancies-arrowRight-ooln8",
	"titleContainer": "Vacancies-titleContainer-2YsAA",
	"table": "Vacancies-table-8GMEj",
	"mobileList": "Vacancies-mobileList-18QAl"
};
module.exports = exports;
