import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import SearchBar from 'components/molecules/SearchBar';
import getImages from 'utils/getImages';
import VCard from 'components/molecules/VCard';
import renderStorylineElement from 'utils/renderStorylineElement';
import InDepthList from 'components/molecules/InDepthList';
import InDepthItem from 'components/molecules/InDepthItem';
import boostrapGrid from 'style/bootstrap-grid.css';
import pickFrom from 'utils/pickFrom';
import useTranslate from '../../../utils/hooks/useTranslate';

import s from './CoworkerPage.css';

const CoworkerPage = ({ pageData, groupedStorylineTree, coworkers, sortedCoworkers }) => {
  const translate = useTranslate();
  const [searchTerm, setSearchTerm] = useState('');
  const [sectionSearchResults, setSectionSearchResults] = useState(sortedCoworkers);
  const handleChange = event => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    const sectionResults = sortedCoworkers?.map(section => {
      const coworkerResults = coworkers?.filter(
        coworker =>
          `${coworker?.firstName} ${coworker?.surName}`.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
          coworker?.role?.toLowerCase()?.includes(searchTerm.toLocaleLowerCase())
      );
      return {
        id: section.id,
        coworkers: section.coworkers?.filter(coworker => coworkerResults?.includes(coworker)),
      };
    });
    setSectionSearchResults(sectionResults);
  }, [searchTerm]);

  const handleSubmit = e => {
    e?.preventDefault();
  };

  return (
    <div className={s.coworkerPage}>
      <div className={classnames(boostrapGrid.container)}>
        <div className={classnames(boostrapGrid.row)}>
          {pageData?.leftMenu?.enabled && (
            <div className={getBootstrapColumns('0,0', '0,0', '4,1')}>
              <div className={s.leftMenu}>
                <Typography variant="caption" classes={s.text}>
                  {pageData.leftMenu.title}
                </Typography>
                <InDepthList>
                  {pageData.leftMenu.items?.map(item => {
                    return (
                      <InDepthItem key={item.id} classes={s.leftMenuIndepthItem} href={item.href}>
                        <Typography
                          variant="caption"
                          classes={classnames(s.leftMenuItem, item.id === 3 && s.selected)}
                        >
                          {item.linkText}
                        </Typography>
                      </InDepthItem>
                    );
                  })}
                </InDepthList>
              </div>
            </div>
          )}
          <div className={getBootstrapColumns('22,1', '18,3', pageData?.leftMenu?.enabled ? '12,2' : '12,6')}>
            <div className={s.mainContent}>
              {groupedStorylineTree.headGroup &&
                groupedStorylineTree.headGroup.map(el => renderStorylineElement(el, false))}
              {groupedStorylineTree.contentGroup &&
                groupedStorylineTree.contentGroup.map(el => renderStorylineElement(el, false))}
              <SearchBar
                classes={s.searchBar}
                placeholder={translate('COWORKER_PAGE.SEARCH_LIST')}
                focusOnMounted
                searchTerm={searchTerm}
                handleChange={handleChange}
                onSubmit={handleSubmit}
              />
              <div className={s.coworkersSections}>
                {sectionSearchResults?.map(section => {
                  return (
                    <div
                      className={classnames(s.section, !section.coworkers.length && s.empty)}
                      key={section.id}
                    >
                      <Typography variant="h5">{section.id}</Typography>
                      <div className={s.coworkers}>
                        {section?.coworkers.map(coworker => {
                          const sizes = getImages('SQUARE');
                          const imageSrc = pickFrom(coworker, 'image.href');
                          const imageAlt = pickFrom(coworker, 'image.altText');
                          return (
                            <div className={s.coworker} key={coworker.id}>
                              <VCard
                                imageSrc={imageSrc && sizes.toSrc(imageSrc, 'S')}
                                imageSrcSet={imageSrc && sizes.toSrcSet(imageSrc)}
                                imageAlt={imageAlt && imageAlt}
                                name={`${coworker.surName}, ${coworker.firstName}`}
                                title={coworker.role}
                                classes={s.coworkerCard}
                                nameLink={coworker.profileLink}
                                imageLink={coworker.profileLink}
                                imageWrapperClasses={classnames(
                                  s.coworkerCardImageWrapper,
                                  !imageSrc && s.noImage
                                )}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(CoworkerPage);
