/* eslint-disable no-console */

import getCurrentEnv from './getCurrentEnv';

const LOGGER_PREFIX = '🔸';

const defaultOptions = {
  availableFeatures: [],
  environments: {
    localhost: false,
    stage: false,
  },
};

export class ClientDebugLogger {
  #globalDisable;

  #enabledFeatures;

  #availableFeatures;

  constructor(options = defaultOptions) {
    const isLocal = window?.location?.href?.includes('localhost');
    const isStage = window?.location?.href?.includes('stage-www');

    this.#globalDisable = true;
    this.#enabledFeatures = new Set();
    this.#loadFeatures(options.availableFeatures);
    this.#availableFeatures = new Set(options.availableFeatures);

    if ((options.environments?.localhost && isLocal) || (options.environments?.stage && isStage)) {
      this.#globalDisable = false;
      this.status();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  status = () => {
    const currentEnv = getCurrentEnv();

    console.log('---------------------------------------------------------');
    console.log(`${LOGGER_PREFIX} Logging %cENABLED%c for ${currentEnv}`, 'color: green;', '');
    console.log('---------------------------------------------------------');
  };

  #loadFeatures(initialFeatures) {
    const storedFeatures = window.localStorage.getItem('logger');
    if (storedFeatures) {
      this.#enabledFeatures = new Set(JSON.parse(storedFeatures));
    } else {
      window.localStorage.setItem('logger', JSON.stringify(initialFeatures));
    }
  }

  getFeatures() {
    return [...this.#availableFeatures].map(feature => ({
      name: feature,
      isEnabled: this.#isEnabled(feature),
    }));
  }

  // eslint-disable-next-line no-dupe-class-members
  #isEnabled(feature) {
    return this.#enabledFeatures.has(feature);
  }

  enable(feature, announce = true) {
    this.#enabledFeatures.add(feature);
    window.localStorage.setItem('logger', JSON.stringify([...this.#enabledFeatures]));
    if (announce) {
      console.log(`${LOGGER_PREFIX} Logger %cENABLED%c for: ${feature}`, 'color: green;', '');
    }
  }

  disable(feature, announce = true) {
    this.#enabledFeatures.delete(feature);
    window.localStorage.setItem('logger', JSON.stringify([...this.#enabledFeatures]));
    if (announce) {
      console.log(`${LOGGER_PREFIX} Logger %cDISABLED%c for: ${feature}`, 'color: red;', '');
    }
  }

  toggle(feature) {
    if (this.#isEnabled(feature)) {
      this.disable(feature);
    } else {
      this.enable(feature);
    }
  }

  enableAll() {
    this.#enabledFeatures.clear();
    this.#availableFeatures.forEach(feature => this.enable(feature, false));
    console.log(`${LOGGER_PREFIX} All logger features %cENABLED%c`, 'color: green;', '');
  }

  disableAll() {
    this.#enabledFeatures.clear();
    console.log(`${LOGGER_PREFIX} All logger features %cDISABLED%c`, 'color: red;', '');
  }

  log(feature, ...messages) {
    if (this.#globalDisable) {
      return;
    }

    this.#availableFeatures.add(feature);

    if (this.#isEnabled(feature)) {
      const now = new Date();
      const timestamp = `${now.toLocaleTimeString()}:${now.getMilliseconds()}`;
      const feat = `[${feature}]`;

      const composed = [LOGGER_PREFIX, `[${timestamp}]`, feat, ...messages];

      console.log(...composed);
    }
  }
}

// Construct an object of all methods (except `constructor`) of the ClientDebugLogger
// class and assign all methods to a "noop" function. This is to avoid errors on the
// server when using `logger.methodName` in the code.
export const noopDebugLogger = Object.getOwnPropertyNames(ClientDebugLogger.prototype)
  .filter(method => method !== 'constructor')
  .reduce((acc, current) => {
    return {
      ...acc,
      [current]: () => {},
    };
  }, {});
