import React from 'react';
import QuoteBox from 'components/molecules/QuoteBox/QuoteBox';
import Carousel from '../Carousel/Carousel';

const ArticleCarousel = ({ articles, bg }) => {
  const carouselItems = articles.map(article => {
    const title = article?.headline;
    const subtitle = article?.summary;
    const internalUrl = article?.href;
    const imageSrc = article?.relation?.teaserImage?.[0]?.content?.fields?.BASE_LANDSCAPE?.href_full;
    const imageAlt = article?.relation?.teaserImage?.[0]?.content?.fields?.alttext;
    const id = article?.id;
    const target = '_self';
    let quoteBoxBackground = 'red';
    if (bg === 'blueGrayGradientSlider') {
      quoteBoxBackground = 'blueGrayGradient';
    }

    return (
      <QuoteBox
        key={id}
        imageSrc={imageSrc}
        imageAlt={imageAlt}
        title={title}
        subtitle={subtitle}
        linkText="Läs mer"
        internalUrl={internalUrl}
        target={target}
        bgColorScheme={quoteBoxBackground}
        showQuote={false}
      />
    );
  });

  return <Carousel items={carouselItems} />;
};

export default ArticleCarousel;
