import React from 'react';
import Typography from 'components/atoms/Typography';
import pickFrom from 'utils/pickFrom';
import renderStorylineElement from 'utils/renderStorylineElement';

import htmlParser from 'utils/htmlParser';
import slugify from 'utils/slugify';

/*
  Special case for h2 rendering, "inherit" rest from renderStorulineElement
*/

const renderElement = element => {
  try {
    switch (element.type) {
      case 'headline2':
        return (
          <Typography
            key={element.id}
            variant="h3"
            component="h2"
            gutterBottomSmall
            gutterTop
            id={slugify([element.fields.headline.rawText, element.id.slice(0, 6)].join('_'))}
          >
            {htmlParser(pickFrom(element, 'fields.headline.html'))}
          </Typography>
        );
      default:
        return renderStorylineElement(element);
    }
  } catch (error) {
    return undefined;
  }
};

export default renderElement;
