import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Link from 'containers/atoms/LinkContainer';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import s from './MinSidaStart.css';
import Typography from '../../../../atoms/Typography/Typography';
// import sentencifyArray from '../../../../../utils/sentencifyArray';
// import { calcAntalValfardtjanst, VALFARDSTJANSTER } from '../../../../../utils/vfsUtils';
// import TempusifiedValfardstjanst from '../../../../atoms/TempusifiedValfardtjanst/TempusifiedValfardtjanst';

const MinSidaStart = ({ userData }) => {
  // const [lonekostnaderPerAr] = useState(1900000);
  // const [bolagsskattPerAr] = useState(190000);
  //
  // const resultsToRender = [
  //   VALFARDSTJANSTER.polis,
  //   VALFARDSTJANSTER.forskoleplats,
  //   VALFARDSTJANSTER.gymnasielarare,
  //   VALFARDSTJANSTER.underskoterskaIHemtjanst,
  // ]
  //   .map(valfardstjanst => ({
  //     valfardstjanst,
  //     nr: calcAntalValfardtjanst(lonekostnaderPerAr, bolagsskattPerAr, valfardstjanst),
  //   }))
  //   .filter(item => item.nr > 0)
  //   .map(item => (
  //     <TempusifiedValfardstjanst
  //       number={calcAntalValfardtjanst(lonekostnaderPerAr, bolagsskattPerAr, item.valfardstjanst)}
  //       valfardstjanst={item.valfardstjanst}
  //     />
  //   ));

  return (
    <div className={s.container}>
      <Typography variant="h1Big" gutterBottom>
        {userData?.name}
      </Typography>
      <Typography variant="lead" gutterBottom>
        Välkommen till din sida på svensktnaringsliv.se. Du är inloggad med{' '}
        <Link to="/min-sida/snmo-konto/" className={s.link}>
          ditt personliga konto
        </Link>{' '}
        och har därmed tillgång till inloggnings&shy;skyddat material och unika tjänster.
      </Typography>
      {userData?.companies?.length === 0 && (
        <>
          <Typography variant="paragraphLarge" gutterBottom>
            Ditt konto saknar koppling till ett medlemsföretag.
          </Typography>
          <Typography variant="paragraphLarge" gutterBottom>
            Om du arbetar på ett av våra medlemsföretag, det vill säga ett företag som är medlem i en eller
            flera av Svenskt Näringslivs{' '}
            <Link to="/medlemsorganisationer/" className={s.link}>
              medlemsorganisationer
            </Link>
            , behöver du koppla ditt konto till företaget du arbetar på. Detta gör du i{' '}
            <Link to="https://medlemsportalen.svensktnaringsliv.se" target="_blank" className={s.link}>
              Medlemsportalen{' '}
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={s.externalLinkIcon} />
            </Link>
            .
          </Typography>
        </>
      )}

      {userData?.companies?.length === 1 && userData?.activeCompany && (
        <>
          <Typography variant="paragraphLarge">Du representerar</Typography>
          <Typography variant="h3" gutterBottom>
            {userData?.activeCompany?.name}
          </Typography>
        </>
      )}
      {userData?.companies?.length > 1 && userData?.activeCompany && (
        <>
          <Typography variant="paragraphLarge">Du representerar för närvarande:</Typography>
          <Typography variant="h3" gutterBottom>
            {userData?.activeCompany?.name}
          </Typography>
          <Typography variant="paragraphLarge" gutterBottom>
            Ditt konto är kopplat till ytterligare {userData.companies.length - 1} företag. Välj vilket av
            dessa du vill representera i menyn.
          </Typography>
        </>
      )}
      {userData?.companies?.length > 0 && !userData?.activeCompany && (
        <Typography variant="paragraphLarge">Välj företag i menyn.</Typography>
      )}

      {/* {userData?.companies?.length > 0 && ( */}
      {/*  <> */}
      {/*    <br /> */}
      {/*    <div className={s.resultsInner}> */}
      {/*      <Typography className={s.result} component="p" variant="h2"> */}
      {/*        <span> */}
      {/*          <strong>{userData?.activeCompany?.name}</strong> bidrar med{' '} */}
      {/*          {sentencifyArray(resultsToRender, { */}
      {/*            lastSeparator: 'eller', */}
      {/*          })} */}
      {/*          . */}
      {/*        </span> */}
      {/*      </Typography> */}
      {/*    </div> */}
      {/*  </> */}
      {/* )} */}
    </div>
  );
};

export default withStyles(s)(MinSidaStart);
