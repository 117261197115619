// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonSecondary-buttonSecondary-1lbck{background-color:#62717f;border:none;border-radius:2px;color:#fff;cursor:pointer;display:block;font-family:LabGrotesque-Bold,sans-serif;padding:16px 32px;text-align:center;text-decoration:none;-webkit-transition:color .2s ease-in-out,background-color .2s ease-in-out,border .2s ease-in-out;transition:color .2s ease-in-out,background-color .2s ease-in-out,border .2s ease-in-out}.ButtonSecondary-buttonSecondary-1lbck:hover{background:#798692}.ButtonSecondary-buttonSecondary-1lbck:active{background:#53606c}.ButtonSecondary-buttonSecondary-1lbck.ButtonSecondary-block-3ye0N{width:100%}.ButtonSecondary-buttonSecondary-1lbck.ButtonSecondary-gutterBottom-sRSQH{margin-bottom:1rem}.ButtonSecondary-buttonSecondary-1lbck.ButtonSecondary-gutterTop-WCo3z{margin-top:1rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"buttonSecondary": "ButtonSecondary-buttonSecondary-1lbck",
	"block": "ButtonSecondary-block-3ye0N",
	"gutterBottom": "ButtonSecondary-gutterBottom-sRSQH",
	"gutterTop": "ButtonSecondary-gutterTop-WCo3z"
};
module.exports = exports;
