import React, { useState } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import slugify from 'utils/slugify';
import useTranslate from 'utils/hooks/useTranslate';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import s from './TOC.css';

const TOC = ({ items, fields }) => {
  const [isOpen, setIsOpen] = useState(true);
  const t = useTranslate();

  return (
    <div className={s.toc}>
      <div className={s.header} onClick={() => setIsOpen(o => !o)}>
        <Typography component="p" className={s.title}>
          {fields?.title.rawText ?? 'Innehållsförteckning'}
        </Typography>
        <span>{t(`TABLE_OF_CONTENTS.${isOpen ? 'HIDE' : 'SHOW'}`)}</span>
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className={s.icon} />
      </div>
      <motion.div
        initial={false}
        animate={{ height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
      >
        <ul className={s.list}>
          {items
            .filter(({ level }) => level <= (fields.level.rawText ?? 3))
            .filter(({ level }) => level <= 4)
            .map(item => (
              <Typography
                component="li"
                key={item.id}
                className={classNames(s.listItem, s[`item-${item.level}`])}
              >
                <span className={s.prefix}>{item.prefix.length === 1 ? `${item.prefix}.` : item.prefix}</span>
                <Link className={s.link} to={`#${slugify([item.text, item.id.slice(0, 6)].join('_'))}`}>
                  {item.text}
                </Link>
              </Typography>
            ))}
        </ul>
      </motion.div>
    </div>
  );
};

export default withStyles(s)(TOC);
