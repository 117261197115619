/* eslint-disable no-param-reassign */
import { create } from 'zustand';
import { subscribeWithSelector /* , devtools */ } from 'zustand/middleware';
import scrollIntoView from 'scroll-into-view-if-needed';
import produce from 'immer';

// Turn the set method into an immer proxy
const immer = config => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState = typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

// Global state hook
const useGlobalState = create(
  // devtools(
  subscribeWithSelector(
    immer(set => ({
      auth: {
        isLoaded: false,
        setIsLoaded: value => {
          set(state => {
            state.auth.isLoaded = value;
          });
        },
      },
      userPreferences: {
        isLoading: false,
        setIsLoading: value => {
          set(state => {
            state.userPreferences.isLoading = value;
          });
        },
      },
      companyData: {
        isLoading: false,
        setIsLoading: value => {
          set(state => {
            state.companyData.isLoading = value;
          });
        },
      },
      companyExtraData: {
        isLoading: false,
        setIsLoading: value => {
          set(state => {
            state.companyExtraData.isLoading = value;
          });
        },
      },
      companyInvoiceData: {
        isLoading: false,
        setIsLoading: value => {
          set(state => {
            state.companyInvoiceData.isLoading = value;
          });
        },
      },
      userPreferencesLock: {
        isLocked: false,
        setIsLocked: value => {
          set(state => {
            state.userPreferencesLock.isLocked = value;
          });
        },
      },
      search: {
        showSearch: false,
        setShowSearch: value => {
          set(state => {
            state.search.showSearch = value;
          });
        },
      },
      pageHeader: {
        mobileMenu: {
          isOpen: false,
          setIsOpen: value => {
            set(state => {
              state.pageHeader.mobileMenu.isOpen = value;
            });
          },
        },
        mobileLoggedInMenu: {
          isOpen: false,
          setIsOpen: value => {
            set(state => {
              state.pageHeader.mobileLoggedInMenu.isOpen = value;
            });
          },
        },
        isFloating: false,
        setIsFloating: value => {
          set(state => {
            state.pageHeader.isFloating = value;
          });
        },
        searchButton: {
          isVisible: false,
          setIsVisible: value => {
            set(state => {
              state.pageHeader.searchButton.isVisible = value;
            });
          },
        },
      },
      generalSettings: {
        shouldPollCook: false,
        setShouldPollCook: value => {
          set(state => {
            state.generalSettings.shouldPollCook = value;
          });
        },
      },
    })),
    {
      pageHeader: true,
    }
  )
  // ),
  // 'globalState'
);

const handleNoScrollInBackground = () => {
  document.documentElement.style.overflow = 'hidden';

  setTimeout(() => {
    const activeLinkElem = document.querySelector('#active-menu-item');
    const boundaryElem = document.querySelector('#mobile-menu');
    if (activeLinkElem && boundaryElem) {
      scrollIntoView(activeLinkElem, {
        block: 'center',
        behavior: 'smooth',
        boundary: boundaryElem,
      });
    }
  }, 300);
};

// Subscribe to the isOpen-state
useGlobalState.subscribe(
  state => [state.pageHeader.mobileMenu.isOpen, state.pageHeader.mobileLoggedInMenu.isOpen],
  isOpen => {
    if (!isOpen[0] && !isOpen[1]) {
      document.documentElement.style.overflow = '';
    } else {
      handleNoScrollInBackground();
    }
  }
);

export default useGlobalState;
