import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ButtonSecondaryGhost.css';

const ButtonSecondaryGhost = ({
  classes,
  darkMode,
  component: Component = 'button',
  inverted,
  gutterBottom,
  block,
  children,
  className,
  ...other
}) => {
  const classNames = classnames(
    !darkMode && s.buttonSecondaryGhost,
    darkMode && s.buttonSecondaryGhostDarkMode,
    block && s.block,
    inverted && s.inverted,
    gutterBottom && s.gutterBottom,
    classes,
    {
      [className]: !!className,
    }
  );
  const type = Component === 'button' ? 'button' : undefined;
  return (
    <Component className={classNames} type={type} {...other}>
      {children}
    </Component>
  );
};

export default withStyles(s)(ButtonSecondaryGhost);
