// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SoundBlock-soundBlock-390XY{margin-bottom:1rem}.SoundBlock-soundBlock-390XY.SoundBlock-stretched-fNMmv{margin-left:-1.5rem;margin-right:-1.5rem}@media screen and (min-width:541px){.SoundBlock-soundBlock-390XY.SoundBlock-stretched-fNMmv{margin-left:-2rem;margin-right:-2rem}}@media screen and (min-width:820px){.SoundBlock-soundBlock-390XY.SoundBlock-stretched-fNMmv{margin-left:-5rem;margin-right:-5rem}}.SoundBlock-soundBlock-390XY.SoundBlock-inHeadGroup-1DU0u .SoundBlock-textWrapper-2wTSJ{padding:0 1.5rem}@media screen and (min-width:541px){.SoundBlock-soundBlock-390XY.SoundBlock-inHeadGroup-1DU0u .SoundBlock-textWrapper-2wTSJ{padding:0}}.SoundBlock-soundBlock-390XY .SoundBlock-soundWrapper-2FcOX{height:0;margin-bottom:.75rem;padding-bottom:56.25%;position:relative}.SoundBlock-soundBlock-390XY .SoundBlock-soundWrapper-2FcOX iframe{height:100%;left:0;position:absolute;top:0;width:100%}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"soundBlock": "SoundBlock-soundBlock-390XY",
	"stretched": "SoundBlock-stretched-fNMmv",
	"inHeadGroup": "SoundBlock-inHeadGroup-1DU0u",
	"textWrapper": "SoundBlock-textWrapper-2wTSJ",
	"soundWrapper": "SoundBlock-soundWrapper-2FcOX"
};
module.exports = exports;
