// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotificationBar-container-D6zZd{-ms-flex-pack:justify;background-color:#f7eae9;border-bottom:1px solid #b12f26;color:#b12f26;height:auto;justify-content:space-between;padding:20px;width:100%;word-break:normal;z-index:100001}.NotificationBar-container-D6zZd,.NotificationBar-container-D6zZd .NotificationBar-titleContainer-2u9-h{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.NotificationBar-container-D6zZd .NotificationBar-titleContainer-2u9-h{gap:10px}.NotificationBar-container-D6zZd .NotificationBar-titleContainer-2u9-h .NotificationBar-title-QgFEA{font-size:1.5rem}.NotificationBar-container-D6zZd .NotificationBar-titleContainer-2u9-h .NotificationBar-infoCircle-1C5Rp{height:26px;width:26px}.NotificationBar-container-D6zZd .NotificationBar-link-1FHe2{color:#444;text-decoration:underline}.NotificationBar-container-D6zZd .NotificationBar-notificationText-vWLZc{color:#444;margin-top:1rem}.NotificationBar-container-D6zZd .NotificationBar-externalLink-2BDyt{height:12px;width:12px}.NotificationBar-container-D6zZd .NotificationBar-close-2aIyB{-ms-flex-pack:center;-ms-flex-align:center;align-items:center;background-color:#fff7ed;border-radius:30px;cursor:pointer;display:-ms-flexbox;display:flex;height:30px;justify-content:center;width:30px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"container": "NotificationBar-container-D6zZd",
	"titleContainer": "NotificationBar-titleContainer-2u9-h",
	"title": "NotificationBar-title-QgFEA",
	"infoCircle": "NotificationBar-infoCircle-1C5Rp",
	"link": "NotificationBar-link-1FHe2",
	"notificationText": "NotificationBar-notificationText-vWLZc",
	"externalLink": "NotificationBar-externalLink-2BDyt",
	"close": "NotificationBar-close-2aIyB"
};
module.exports = exports;
