// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LatestReports-buttonWrapper-3zddv{-ms-flex-pack:center;display:-ms-flexbox;display:flex;justify-content:center}.LatestReports-latestReports-1CEaJ{background-color:#eff2f5;margin-bottom:2rem;margin-top:4rem}.LatestReports-latestReports-1CEaJ .LatestReports-latestReportsContainer-19jnc{margin:0 auto;padding:2rem 1.5rem}@media screen and (min-width:1169px){.LatestReports-latestReports-1CEaJ .LatestReports-latestReportsContainer-19jnc{max-width:1400px;padding:3.125rem 100px}}.LatestReports-latestReports-1CEaJ .LatestReports-selectedEventsHeader-V8QHF{margin-bottom:1.5rem}.LatestReports-carousel-2-0n6{margin-bottom:2rem}.LatestReports-carouselItemChunk-3ILkl{grid-gap:1.5rem;display:grid;gap:1.5rem;height:100%}.LatestReports-reportBox-2SQX4{grid-gap:0 .5rem;background-color:#fff;display:grid;gap:0 .5rem;grid-template-columns:6fr 4fr;max-height:300px;padding:1rem}@media screen and (min-width:541px){.LatestReports-reportBox-2SQX4{grid-template-columns:1fr 1fr}}.LatestReports-reportBox-2SQX4 .LatestReports-reportText-8J--A{grid-row:1/2}.LatestReports-reportBox-2SQX4 .LatestReports-reportText-8J--A h4{margin-top:.5rem}.LatestReports-reportBox-2SQX4 .LatestReports-reportText-8J--A a.LatestReports-reportTitle-3jaTo{color:#1f272f;font-size:1rem;line-height:1.2rem;text-decoration:none}.LatestReports-reportBox-2SQX4 .LatestReports-reportText-8J--A .LatestReports-reportBoxLabelDate-1zycq{color:#62717f;font-size:.875rem;line-height:1.375rem}.LatestReports-reportBox-2SQX4 .LatestReports-coverImage-y7JLm{grid-column:2/3;grid-row:1/3;justify-self:end}.LatestReports-reportBox-2SQX4 .LatestReports-coverImage-y7JLm img{border:1px solid #e6e9ed;max-height:250px}.LatestReports-reportBox-2SQX4 .LatestReports-downloadButtonWrapper-2uia-{-ms-flex-item-align:end;align-self:end}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"buttonWrapper": "LatestReports-buttonWrapper-3zddv",
	"latestReports": "LatestReports-latestReports-1CEaJ",
	"latestReportsContainer": "LatestReports-latestReportsContainer-19jnc",
	"selectedEventsHeader": "LatestReports-selectedEventsHeader-V8QHF",
	"carousel": "LatestReports-carousel-2-0n6",
	"carouselItemChunk": "LatestReports-carouselItemChunk-3ILkl",
	"reportBox": "LatestReports-reportBox-2SQX4",
	"reportText": "LatestReports-reportText-8J--A",
	"reportTitle": "LatestReports-reportTitle-3jaTo",
	"reportBoxLabelDate": "LatestReports-reportBoxLabelDate-1zycq",
	"coverImage": "LatestReports-coverImage-y7JLm",
	"downloadButtonWrapper": "LatestReports-downloadButtonWrapper-2uia-"
};
module.exports = exports;
