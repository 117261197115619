/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './BackgroundPolygon.css';

const BackgroundPolygon = ({ classes, inverted = false, dark = false, cold = false, mediumDark = false }) => {
  const classNames = classnames(
    s.wrapper,
    inverted && s.inverted,
    dark && s.dark,
    cold && s.cold,
    mediumDark && s.mediumDark,
    classes
  );
  const color = dark ? '#33414e' : mediumDark ? '#62717f' : '#eff2f5';

  return (
    <div className={classNames}>
      {inverted && (
        <svg
          height="64"
          width="1300"
          viewBox="0 0 1300 64"
          preserveAspectRatio="none"
          className={s.backgroundPolygon}
        >
          <polygon points="0,0 0,64 1300,0" style={{ fill: color }} />
        </svg>
      )}
      {!inverted && (
        <svg
          height="64"
          width="1300"
          viewBox="0 0 1300 64"
          preserveAspectRatio="none"
          className={s.backgroundPolygon}
        >
          <polygon points="0,64 1300,0 1300,64" style={{ fill: color }} />
        </svg>
      )}
    </div>
  );
};

export default withStyles(s)(BackgroundPolygon);
