// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Modal-modal-1Bg78{height:100%;max-height:100%;overflow-y:auto;position:relative}@media (min-width:541px){.Modal-modal-1Bg78{height:inherit}}.Modal-closeButton-35gyM{background-color:#dee4ea;border:none;color:#62717f;cursor:pointer;height:50px;position:absolute;right:0;top:0;width:50px}.Modal-dark-f3FrZ .Modal-closeButton-35gyM{background-color:inherit;color:#cfd4d8}.Modal-closeIcon-Dq5fh{font-size:24px}.Modal-overlay-xMVTY{-ms-flex-align:center;-ms-flex-pack:center;align-items:center;background-color:rgba(0,0,0,.65);bottom:0;display:-ms-flexbox;display:flex;justify-content:center;left:0;overflow-y:auto;position:fixed;right:0;top:0;z-index:99999}.Modal-dark-f3FrZ.Modal-overlay-xMVTY{background-color:rgba(51,65,78,.97)}.Modal-overlay-xMVTY.Modal-alignTop-QmHkS{-ms-flex-align:start;align-items:flex-start;height:100%;width:100%}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"modal": "Modal-modal-1Bg78",
	"closeButton": "Modal-closeButton-35gyM",
	"dark": "Modal-dark-f3FrZ",
	"closeIcon": "Modal-closeIcon-Dq5fh",
	"overlay": "Modal-overlay-xMVTY",
	"alignTop": "Modal-alignTop-QmHkS"
};
module.exports = exports;
