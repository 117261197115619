import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './DownloadButton.css';

const DownloadButton = ({ classes, children, ...other }) => {
  const classNames = classnames(s.downloadButton, classes);
  return (
    <button type="button" className={classNames} {...other}>
      {children}
    </button>
  );
};

export default withStyles(s)(DownloadButton);
