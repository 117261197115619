import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import AlsoReadItem from 'components/molecules/AlsoReadItem';
// eslint-disable-next-line css-modules/no-unused-class
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './AlsoRead.css';

const AlsoRead = ({ classes, items = [], ...other }) => {
  const classNames = classnames(s.alsoRead, classes);
  return (
    <div className={classNames} {...other}>
      <div>
        <Typography variant="caption" component="h2" classes={s.alsoReadHeadline}>
          DU KANSKE OCKSÅ VILL LÄSA
        </Typography>
        <div className={boostrapGrid.row}>
          {items?.map(({ id, title, href, image }) => (
            // eslint-disable-next-line camelcase
            <div key={id} className={classnames(boostrapGrid['col-sm-12'], boostrapGrid['col-lg-8'])}>
              <AlsoReadItem
                imageSrc={image?.images?.BASE.LANDSCAPE?.href_full}
                imageWidth={
                  image?.images?.BASE.LANDSCAPE?.width ||
                  image?.images?.BASE.LANDSCAPE?.sourceWidth ||
                  image?.originalSize?.width
                }
                imageHeight={
                  image?.images?.BASE.LANDSCAPE?.height ||
                  image?.images?.BASE.LANDSCAPE?.sourceHeight ||
                  image?.originalSize?.height
                }
                href={href}
                headline={title}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// {/* <Item key={id} headline={title} href={href} imageSrc={image?.images?.BASE.LANDSCAPE?.href_full} /> */}

export default withStyles(boostrapGrid, s)(AlsoRead);
