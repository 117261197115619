/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import SpecialSocialBall from 'components/atoms/SpecialSocialBall';
import { faLinkedin, faFacebookSquare, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useSelector } from 'react-redux';
import { selectors as locationSelectors } from 'ducks/location';
import pushToDataLayer from '../../../utils/pushToDataLayer';
import s from './SpecialShareBar.css';

const SpecialShareBar = ({
  isShowShareOnFacebook,
  isShowShareOnLinkedIn,
  isShowShareOnTwitter,
  isShowShareViaEmail,
  darkMode,
  color = '',
  title = '',
  href = '',
}) => {
  const fullPath = useSelector(locationSelectors.getFullPath);

  const mailSubject = 'Tips från Svenskt Näringsliv';
  const mailBody = 'Jag vill tipsa om en intressant artikel hos Svenskt Näringsliv';
  const emailTip = `mailto:?subject=${mailSubject}&body=${mailBody}:`;

  const shareUrl = href || fullPath || '';

  const getColorValue = () => {
    if (color === 'orange') {
      return '#ea8f12';
    }
    if (color === 'red') {
      return '#b12f26';
    }
    if (color === 'darkBlue') {
      return '#33414e';
    }
    if (color === 'steel') {
      return '#62717f';
    }
    if (color === 'creamyWhite') {
      return '#faf9f7';
    }
    if (color === 'lightBlue') {
      return '#eff2f5';
    }
    return '';
  };

  const handleSocialClick = (event, gtmActionValue) => {
    event.preventDefault();
    window.open(
      event.currentTarget.href,
      'sharewindow',
      'left=20,top=20,width=650,height=400,toolbar=1,resizable=0'
    );

    pushToDataLayer('socialLinkClick', {
      socialLinkCategory: gtmActionValue,
      socialLinkSourcePageUrl: window.location.href,
    });
  };

  const handleEmailClick = () => {
    pushToDataLayer('socialLinkClick', {
      socialLinkCategory: 'Email-tip',
      socialLinkSourcePageUrl: window.location.href,
    });
  };

  return (
    <div>
      {isShowShareOnFacebook && (
        <span className={s.icon}>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
            className={s.link}
            onClick={e => handleSocialClick(e, 'Facebook-Share')}
          >
            <SpecialSocialBall
              style={s.socialBall}
              image={faFacebookSquare}
              brand="facebook"
              height={21}
              width={21}
              darkMode={darkMode}
              color={getColorValue()}
            />
          </a>
        </span>
      )}
      {isShowShareOnTwitter && (
        <span className={s.icon}>
          <a
            href={`https://twitter.com/share?url=${encodeURIComponent(shareUrl)}&text=${title}`}
            onClick={e => handleSocialClick(e, 'Twitter-Tweet')}
          >
            <SpecialSocialBall
              image={faXTwitter}
              brand="twitter"
              height={21}
              width={21}
              darkMode={darkMode}
              color={getColorValue()}
            />
          </a>
        </span>
      )}
      {isShowShareOnLinkedIn && (
        <span className={s.icon}>
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
              shareUrl
            )}&title=${title}&source=LinkedIn`}
            onClick={e => handleSocialClick(e, 'LinkedIn-Share')}
          >
            <SpecialSocialBall
              image={faLinkedin}
              brand="linkedin"
              height={21}
              width={21}
              darkMode={darkMode}
              color={getColorValue()}
            />
          </a>
        </span>
      )}
      {isShowShareViaEmail && (
        <span className={s.icon}>
          <a href={encodeURI(`${emailTip} ${shareUrl}`)} onClick={handleEmailClick}>
            <SpecialSocialBall
              image={faEnvelope}
              brand="email"
              height={21}
              width={21}
              darkMode={darkMode}
              color={getColorValue()}
            />
          </a>
        </span>
      )}
    </div>
  );
};

export default withStyles(s)(SpecialShareBar);
