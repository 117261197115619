// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SectionBackground-sectionBackground-3JVUA{--tilt-offset:30px;--tilt-offset-negative:-31px;position:relative}@media (min-width:820px){.SectionBackground-sectionBackground-3JVUA{--tilt-offset:80px;--tilt-offset-negative:-81px}}.SectionBackground-section-N_BRs{height:100%}.SectionBackground-tiltedBottom-3Pg1A{-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - var(--tilt-offset)),0 100%);clip-path:polygon(0 0,100% 0,100% calc(100% - var(--tilt-offset)),0 100%)}.SectionBackground-tiltedTop-2uqP8{-webkit-clip-path:polygon(0 var(--tilt-offset),100% 0,100% 100%,0 100%);clip-path:polygon(0 var(--tilt-offset),100% 0,100% 100%,0 100%);margin-top:var(--tilt-offset-negative)}.SectionBackground-tiltedBottom-3Pg1A.SectionBackground-tiltedTop-2uqP8{-webkit-clip-path:polygon(0 var(--tilt-offset),100% 0,100% calc(100% - var(--tilt-offset)),0 100%);clip-path:polygon(0 var(--tilt-offset),100% 0,100% calc(100% - var(--tilt-offset)),0 100%)}.SectionBackground-videoColorOverlay-10K2C{z-index:1}.SectionBackground-videoColorOverlay-10K2C,video{height:100%;left:0;position:absolute;top:0;width:100%}video{-o-object-fit:cover;object-fit:cover}.SectionBackground-ShowOutlineLogoBottom-1Nckf{bottom:-97px;max-height:120%;max-width:100%;opacity:.25;position:absolute;right:-80px}.SectionBackground-ShowOutlineLogoTiltedBottom-1Ksiu{bottom:10px;height:100%;opacity:.25;position:absolute;right:-80px;width:100%}@media screen and (max-width:820px){.SectionBackground-ShowOutlineLogoTiltedBottom-1Ksiu{bottom:8.12rem}}@media screen and (max-width:541px){.SectionBackground-ShowOutlineLogoBottom-1Nckf,.SectionBackground-ShowOutlineLogoTiltedBottom-1Ksiu{opacity:0}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"sectionBackground": "SectionBackground-sectionBackground-3JVUA",
	"section": "SectionBackground-section-N_BRs",
	"tiltedBottom": "SectionBackground-tiltedBottom-3Pg1A",
	"tiltedTop": "SectionBackground-tiltedTop-2uqP8",
	"videoColorOverlay": "SectionBackground-videoColorOverlay-10K2C",
	"ShowOutlineLogoBottom": "SectionBackground-ShowOutlineLogoBottom-1Nckf",
	"ShowOutlineLogoTiltedBottom": "SectionBackground-ShowOutlineLogoTiltedBottom-1Ksiu"
};
module.exports = exports;
