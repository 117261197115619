// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Chat-chat-3cnsK{overflow:hidden}.Chat-chat-3cnsK .Chat-chatWrapper-2kq5F{min-height:27rem;overflow:hidden;padding-top:1rem;position:relative}.Chat-chat-3cnsK .Chat-chatWrapper-2kq5F iframe{height:100%;left:0;position:absolute;top:0;width:100%}", ""]);
// Exports
exports.locals = {
	"chat": "Chat-chat-3cnsK",
	"chatWrapper": "Chat-chatWrapper-2kq5F"
};
module.exports = exports;
