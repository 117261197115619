// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BoardMembersPage-boardMembersPage-2-DqU{background:#fff;min-height:100%;padding-bottom:3.5rem;position:relative}.BoardMembersPage-boardMembersPage-2-DqU .BoardMembersPage-leftMenu-lkzUR{display:none}@media screen and (min-width:1169px){.BoardMembersPage-boardMembersPage-2-DqU .BoardMembersPage-leftMenu-lkzUR{display:none;display:block;margin-left:-.75rem;margin-right:-.75rem;padding:0 0 5.25rem;position:sticky;top:8rem}.BoardMembersPage-boardMembersPage-2-DqU .BoardMembersPage-leftMenu-lkzUR .BoardMembersPage-text-3R8Us{text-transform:uppercase}.BoardMembersPage-boardMembersPage-2-DqU .BoardMembersPage-leftMenu-lkzUR .BoardMembersPage-leftMenuIndepthItem-1Xn4d{margin-bottom:0;margin-top:.25rem}.BoardMembersPage-boardMembersPage-2-DqU .BoardMembersPage-leftMenu-lkzUR .BoardMembersPage-leftMenuIndepthItem-1Xn4d:first-child{margin-top:.5rem}.BoardMembersPage-boardMembersPage-2-DqU .BoardMembersPage-leftMenu-lkzUR .BoardMembersPage-leftMenuItem-1lWKo{font-size:.875rem;font-weight:700}.BoardMembersPage-boardMembersPage-2-DqU .BoardMembersPage-leftMenu-lkzUR .BoardMembersPage-leftMenuItem-1lWKo.BoardMembersPage-selected-2eB-A{color:#ea8f12}}.BoardMembersPage-boardMembersPage-2-DqU .BoardMembersPage-iconWrapper-2DDTp{-ms-flex-align:start;align-items:flex-start;display:-ms-flexbox;display:flex;-ms-flex:0 0 32px;flex:0 0 32px;font-size:32px;margin-right:12px;text-align:right}@media (min-width:541px){.BoardMembersPage-boardMembersPage-2-DqU .BoardMembersPage-iconWrapper-2DDTp{-ms-flex:0 0 26px;flex:0 0 26px;font-size:26px;margin-right:16px;margin-top:4px;text-align:center}}.BoardMembersPage-boardMembersPage-2-DqU .BoardMembersPage-iconWrapper-2DDTp .BoardMembersPage-icon-3hKWK{color:#ea8f12;height:32px}@media (min-width:541px){.BoardMembersPage-boardMembersPage-2-DqU .BoardMembersPage-iconWrapper-2DDTp .BoardMembersPage-icon-3hKWK{height:26px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"boardMembersPage": "BoardMembersPage-boardMembersPage-2-DqU",
	"leftMenu": "BoardMembersPage-leftMenu-lkzUR",
	"text": "BoardMembersPage-text-3R8Us",
	"leftMenuIndepthItem": "BoardMembersPage-leftMenuIndepthItem-1Xn4d",
	"leftMenuItem": "BoardMembersPage-leftMenuItem-1lWKo",
	"selected": "BoardMembersPage-selected-2eB-A",
	"iconWrapper": "BoardMembersPage-iconWrapper-2DDTp",
	"icon": "BoardMembersPage-icon-3hKWK"
};
module.exports = exports;
