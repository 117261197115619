import pickFrom from '../../../../utils/pickFrom';

// eslint-disable-next-line consistent-return
const extractPictureRelation = element => {
  try {
    const { alttext, BASE_LANDSCAPE, description } = element.fields;
    // eslint-disable-next-line camelcase
    const href_full = pickFrom(BASE_LANDSCAPE, 'href_full');
    return {
      ...element,
      image: {
        alttext,
        description,
        // eslint-disable-next-line camelcase
        href: href_full,
      },
    };
  } catch (error) {
    console.error(error);
  }
};

const extractStoryRelation = element => {
  let imageElement = element.coverImage && element.coverImage[0];
  if (!imageElement) {
    imageElement = element.teaserImage && element.teaserImage[0];
  }
  const href = pickFrom(imageElement, 'content.fields.BASE_LANDSCAPE.href_full');
  const alttext = pickFrom(imageElement, 'content.fields.alttext');
  const description = pickFrom(imageElement, 'content.fields.description');

  return {
    ...element,
    image: {
      alttext,
      description,
      href: href ?? '',
    },
  };
};

const extractRelation = element => {
  switch (element.contentType) {
    case 'picture':
      return extractPictureRelation(element);
    case 'story':
    case 'storyline':
    case 'report':
    case 'sectionInfo':
    case 'link':
    case 'remiss':
    case 'event':
    case 'blogPost':
    case 'skrivelse':
    case 'pressRelease':
    case 'tagInfo':
      return extractStoryRelation(element);
    default:
      return element;
  }
};

export default extractRelation;
