// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StorylineBox-showOutlineLogo-2GwJL{max-height:120%;max-width:100%;opacity:1;position:absolute;right:80px;top:10px}.StorylineBox-emtyBoxContent-36T0I{padding-bottom:0;width:0}.StorylineBox-spaceDefault-1rRvi{padding:1.5rem}.StorylineBox-spaceDefault-1rRvi,.StorylineBox-spaceLarge-3iRt9{position:relative;width:100%}.StorylineBox-spaceLarge-3iRt9{padding:2rem}.StorylineBox-spaceExtraLarge-2BiMx{padding:2.5rem;position:relative;width:100%}@media screen and (min-width:541px){.StorylineBox-spaceDefault-1rRvi{padding:1.5rem}.StorylineBox-spaceLarge-3iRt9{padding:2.5rem}.StorylineBox-spaceExtraLarge-2BiMx{padding:3rem}}@media screen and (min-width:820px){.StorylineBox-spaceDefault-1rRvi{padding:1.5rem}.StorylineBox-spaceLarge-3iRt9{padding:3rem}.StorylineBox-spaceExtraLarge-2BiMx{padding:4rem}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"showOutlineLogo": "StorylineBox-showOutlineLogo-2GwJL",
	"emtyBoxContent": "StorylineBox-emtyBoxContent-36T0I",
	"spaceDefault": "StorylineBox-spaceDefault-1rRvi",
	"spaceLarge": "StorylineBox-spaceLarge-3iRt9",
	"spaceExtraLarge": "StorylineBox-spaceExtraLarge-2BiMx"
};
module.exports = exports;
