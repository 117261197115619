import React from 'react';
import classnames from 'classnames';
/* eslint-disable css-modules/no-undef-class */
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import getImages from 'utils/getImages';
import s from './MembershipOrganization.css';

// eslint-disable-next-line prefer-regex-literals
const domainRegex = new RegExp('(?:https?:)?(?://)?(?:www\\.)?([^/]*)');

const MembershipOrganization = ({ id, imageSrc, imageAlt, name, href, teaser, hasBorder = true }) => {
  const sizes = getImages('LANDSCAPE');
  let domain;
  if (href) {
    const domainRegexMatches = href.match(domainRegex);
    if (domainRegexMatches && domainRegexMatches.length > 1) {
      domain = domainRegexMatches[1];
    } else {
      domain = href;
    }
  }
  return (
    <div className={classnames(s.membershipOrganization, hasBorder && s.hasBorder)}>
      <div className={s.headerWrapper}>
        {imageSrc && (
          <img src={sizes.toSrc(imageSrc, 'XS')} height="70" alt={imageAlt} className={s.logoMobile} />
        )}
        <div>
          <Typography variant="h4" component="h3" classes={s.headline} id={`article-${id}`}>
            {name}
          </Typography>
          {href && (
            <Typography variant="linkSmall" component="a" href={href} target="_blank">
              {domain}
            </Typography>
          )}
        </div>
        {imageSrc && <img src={sizes.toSrc(imageSrc, 'XS')} height="80" alt={imageAlt} className={s.logo} />}
      </div>
      {teaser && (
        <Typography variant="paragraphSmall" classes={s.teaserText}>
          {teaser}
        </Typography>
      )}
    </div>
  );
};

export default withStyles(s)(MembershipOrganization);
