import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import boostrapGrid from 'style/bootstrap-grid.css';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import pushToDataLayer from 'utils/pushToDataLayer';
import s from './Breadcrumbs.css';

const REMOVED_BREADCRUMBS = ['Mikrosajter'];

const Breadcrumbs = ({ classes, isMirosite, breadcrumbs, showBreadcrumbs = true, darkMode, textColor }) => {
  let shouldBeDarkMode = darkMode;
  if (textColor) {
    shouldBeDarkMode = textColor !== 'dark'; // if dark not dark mode, text should be white
  }

  const classNames = classnames(
    classes,
    s.breadcrumbs,
    shouldBeDarkMode && s.darkMode,
    !shouldBeDarkMode && s.darkText,
    textColor === 'light' && s.lightText
  );
  const activeBreadcrumbs = breadcrumbs.filter(breadcrumb => !REMOVED_BREADCRUMBS.includes(breadcrumb.name));

  const handleLinkClick = e => {
    const breadcrumbsString = activeBreadcrumbs.map(bc => bc.name).join(' > ');
    const { pathname: targetPathname } = new URL(e.target.href);
    const clickedBreadcrumb = activeBreadcrumbs.find(bc => bc.href === targetPathname).name;

    pushToDataLayer('breadcrumbClick', {
      breadcrumbElementPath: `${breadcrumbsString} [${clickedBreadcrumb}]`,
    });
  };

  return (
    breadcrumbs?.length > 1 &&
    showBreadcrumbs &&
    !isMirosite && (
      <div className={classNames}>
        <div className={classnames(boostrapGrid.container)}>
          <div className={s.breadcrumbItems}>
            {activeBreadcrumbs?.map((breadcrumb, idx) => {
              return (
                <React.Fragment key={breadcrumb.href}>
                  {idx === activeBreadcrumbs.length - 2 && (
                    <Link to={breadcrumb.href} className={s.breadcrumbsCheveronIconBackLink}>
                      <FontAwesomeIcon icon={faChevronLeft} className={s.breadcrumbsCheveronIconBack} />
                    </Link>
                  )}
                  {breadcrumb.linked ? (
                    <Typography
                      onClick={handleLinkClick}
                      variant="contentTypeDate"
                      darkMode={shouldBeDarkMode}
                      component={Link}
                      to={breadcrumb.href}
                      classes={s.breadcrumb}
                    >
                      {breadcrumb.name}
                    </Typography>
                  ) : (
                    <Typography
                      variant="contentTypeDate"
                      darkMode={shouldBeDarkMode}
                      component="span"
                      classes={s.breadcrumb}
                    >
                      {breadcrumb.name}
                    </Typography>
                  )}
                  {idx !== activeBreadcrumbs.length - 1 && (
                    <FontAwesomeIcon icon={faChevronRight} className={s.breadcrumbsCheveronIcon} />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    )
  );
};

export default withStyles(s)(Breadcrumbs);
