import React, { useEffect, useState, useRef } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import axios from 'axios';
import s from './NewsletterSubscriptionsContainer.css';
import NewsletterSubscribe from '../NewsletterSubscribe';
import Typography from '../../atoms/Typography/Typography';
import Radio from '../../atoms/Radio/Radio';
import InputField from '../../atoms/InputField/InputField';
import ButtonSuccess from '../../atoms/buttons/ButtonSuccess/ButtonSuccess';
import useUser from '../../../utils/hooks/useUser';
import useAuth from '../../../utils/hooks/useAuth';
import useTranslate from '../../../utils/hooks/useTranslate';
import Link from '../../atoms/Link';
import useTranslation from '../../../utils/hooks/useTranslation';
import Spinner from '../../atoms/Spinner/Spinner';
import { getFrontEndOrigin } from '../../../utils/apiUtil';
import { renderToast } from '../Toast/toastUtils';
import { isValidEmail } from '../../../utils/emailUtils';

const NewsletterSubscriptionsContainer = ({ newsletters }) => {
  const { isAuthenticated, authQuery } = useAuth();
  const { userQuery } = useUser();
  const t = useTranslate();
  const { IS_SWEDISH } = useTranslation();
  const [selectedOption, setSelectedOption] = useState('loggedInAdress');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedValidatedEmail, setSelectedValidatedEmail] = useState('');
  const [sendingValidationCode, setSendingValidationCode] = useState(false);
  const [validating, setValidating] = useState(false);
  const [sentValidationCode, setSentValidationCode] = useState(false);
  const [validationCode, setValidationCode] = useState('');
  const [validatedEmails, setValidatedEmails] = useState([]);
  const userLoading = userQuery.isLoading || authQuery.isLoading;
  const [recipientsData, setRecipientsData] = useState([]);
  const [updatedNewsletters, setUpdatedNewsletters] = useState([]);
  const inputRef = useRef();

  useEffect(() => {
    if (userQuery?.data?.userData?.email) {
      setSelectedEmail(userQuery?.data?.userData?.email);
      setSelectedValidatedEmail(userQuery?.data?.userData?.email);
    }
  }, [userQuery?.data?.userData?.email]);

  useEffect(() => {
    if (newsletters?.length > 0 && recipientsData?.length > 0) {
      const processedNewsletters = newsletters.map(newsletter => {
        const existingSubscription = recipientsData.find(rd => {
          return rd?.ListID?.toString() === newsletter?.fields?.mailjetMailingListId?.toString();
        });
        return {
          ...newsletter,
          isSubscribed: !!existingSubscription,
          listRecipientId: existingSubscription?.ID ?? null,
        };
      });
      setUpdatedNewsletters(processedNewsletters);
    } else {
      setUpdatedNewsletters(newsletters);
    }
  }, [recipientsData]);

  const loadRecipientsNewsletters = async () => {
    if (isAuthenticated && selectedEmail) {
      const endpointUrl = `${getFrontEndOrigin(true)}/newsletter-status`;
      try {
        const results = await axios.get(`${endpointUrl}?email=${encodeURIComponent(selectedEmail)}`);
        setRecipientsData(results.data?.results);
      } catch (e) {
        renderToast('Kunde inte ladda nyhetsbrevsdata', 'error');
      }
    }
  };

  useEffect(() => {
    if (newsletters?.length > 0 && isValidEmail(selectedEmail)) {
      loadRecipientsNewsletters();
    }
  }, [newsletters, selectedEmail]);

  const reset = () => {
    setSentValidationCode(false);
    setValidationCode('');
  };

  const handleOptionChange = event => {
    reset();
    setSelectedOption(event.target.value);
    if (event.target.value === 'loggedInAdress') {
      setSelectedEmail(userQuery?.data?.userData?.email);
      setSelectedValidatedEmail(userQuery?.data?.userData?.email);
    } else if (event.target.value === 'otherAddress') {
      setSelectedEmail('');
      setSelectedValidatedEmail('');
    } else {
      setSelectedEmail(event.target.value);
      setSelectedValidatedEmail(event.target.value);
    }
  };

  const handleSendValidationCode = async () => {
    if (selectedEmail) {
      setSendingValidationCode(true);
      try {
        await axios.post('/send-email-validation-code', {
          email: selectedEmail,
        });
        renderToast(
          'Vi har skickat en valideringskod till din e-postadress. Skriv in koden i rutan och tryck "Validera"',
          'success',
          7000
        );
        setSentValidationCode(true);
      } catch (e) {
        renderToast(
          'Något gick fel. Kontrollera din epost-adress eller kontakta redaktionen@svensktnaringsliv.se',
          'error',
          7000
        );
      } finally {
        setSendingValidationCode(false);
      }
    }
  };

  const handleValidateCode = async () => {
    if (selectedEmail && validationCode) {
      setValidating(true);
      try {
        await axios.post('/validate-email-validation-code', {
          email: selectedEmail,
          code: validationCode,
        });
        const currentArray = [...validatedEmails];
        currentArray.push(selectedEmail);
        setValidatedEmails(currentArray);
        setSelectedOption(selectedEmail);
        setSelectedValidatedEmail(selectedEmail);
        setSelectedEmail(selectedEmail);
        reset();
      } catch (e) {
        renderToast(
          'Felaktig kod. Antingen är din kod för gammal eller felaktig. Prova igen eller kontakta redaktionen@svensktnaringsliv.se om problemet kvarstår',
          'error',
          7000
        );
      } finally {
        setValidating(false);
      }
    }
  };

  const renderIntegrityInfo = () => {
    return t('SUBSCRIBE.MODAL_FOOTER_COPY', {
      link: linkText => (
        <Link
          to={IS_SWEDISH ? '/dataskydd/' : '/english/data-protection/'}
          target="_blank"
          className={s.termsLink}
        >
          {linkText}
        </Link>
      ),
    });
  };

  const setBothEmails = email => {
    setSelectedEmail(email);
    setSelectedValidatedEmail(email);
  };

  useEffect(() => {
    if (sentValidationCode) {
      inputRef?.current?.focus();
    }
  }, [sentValidationCode]);

  return (
    <div className={s.container}>
      <div className={s.formContainer}>
        {userLoading && (
          <div className={s.spinnerContainer}>
            <Spinner size="2x" />
          </div>
        )}
        {!userLoading && !isAuthenticated && (
          <>
            <Typography variant="paragraph" bold>
              Din e-postadress
            </Typography>
            <InputField
              fullWidth
              type="text"
              round={false}
              placeholder="E-postadress"
              onChange={e => setBothEmails(e.target.value)}
            />
            <br />
            {renderIntegrityInfo()}
          </>
        )}
        {!userLoading && isAuthenticated && (
          <>
            <Typography variant="paragraph" bold>
              För vilken epost-adress vill du hantera dina prenumerationer?
            </Typography>
            <Radio
              id="loggedInAdress"
              value="loggedInAdress"
              checked={selectedOption === 'loggedInAdress'}
              onChange={handleOptionChange}
              label={`Adressen jag är inloggad med (${userQuery?.data?.userData?.email})`}
            />
            {validatedEmails?.length > 0 &&
              validatedEmails.map(item => (
                <Radio
                  id={item}
                  key={item}
                  value={item}
                  checked={selectedOption === item}
                  onChange={handleOptionChange}
                  label={item}
                />
              ))}
            <Radio
              id="otherAddress"
              value="otherAddress"
              checked={selectedOption === 'otherAddress'}
              onChange={handleOptionChange}
              label="Annan e-postadress"
            />
            {selectedOption === 'otherAddress' && (
              <>
                <div className={s.emailContainer}>
                  {sentValidationCode && (
                    <InputField
                      register={inputRef}
                      fullWidth
                      type="text"
                      round={false}
                      placeholder="Valideringskod"
                      onChange={e => setValidationCode(e.target.value)}
                    />
                  )}
                  {!sentValidationCode && (
                    <InputField
                      fullWidth
                      type="text"
                      round={false}
                      placeholder="E-postadress"
                      onChange={e => setSelectedEmail(e.target.value)}
                    />
                  )}
                  {sentValidationCode && (
                    <ButtonSuccess
                      loading={validating}
                      disabled={validating || validationCode?.length === 0}
                      onClick={handleValidateCode}
                    >
                      Validera
                    </ButtonSuccess>
                  )}
                  {!sentValidationCode && (
                    <ButtonSuccess
                      loading={sendingValidationCode}
                      disabled={sendingValidationCode || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(selectedEmail)}
                      onClick={handleSendValidationCode}
                    >
                      Skicka valideringskod
                    </ButtonSuccess>
                  )}
                </div>
                {renderIntegrityInfo()}
              </>
            )}
          </>
        )}
      </div>
      {updatedNewsletters.map(newsletter => (
        <NewsletterSubscribe
          key={newsletter.id}
          newsletter={newsletter}
          selectedEmail={selectedValidatedEmail}
          refresh={() => loadRecipientsNewsletters()}
        />
      ))}
    </div>
  );
};

export default withStyles(s)(NewsletterSubscriptionsContainer);
