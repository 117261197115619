// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ContactPersonBlock-contactWrapper-2zXjT{grid-gap:36px;border:1px sol;display:grid;grid-template-columns:repeat(auto-fill,minmax(230px,1fr));margin-bottom:36px}@media (--sm-viewport ){.ContactPersonBlock-contactWrapper-2zXjT{grid-template-columns:repeat(auto-fill,minmax(274px,1fr))}}", ""]);
// Exports
exports.locals = {
	"contactWrapper": "ContactPersonBlock-contactWrapper-2zXjT"
};
module.exports = exports;
