import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import boostrapGrid from 'style/bootstrap-grid.css';
import pickFrom from 'utils/pickFrom';
import getImages from 'utils/getImages';
import Modal from 'components/organisms/Modal';
import CoworkerFiles from 'components/organisms/CoworkerFiles';
import SearchBar from '../../molecules/SearchBar';
import useTranslate from '../../../utils/hooks/useTranslate';
import Typography from '../../atoms/Typography';
import VCard from '../../molecules/VCard';
import Translate from '../../../containers/atoms/TranslateContainer';
import s from './BoardMembers.css';
import useTranslation from '../../../utils/hooks/useTranslation';

const BoardMembers = ({ boardMembers, sortedBoardMembers }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    highResolutionImages: [],
    documents: [],
  });
  const translate = useTranslate();
  const translation = useTranslation();

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchTerm !== '') {
      setSearchTerm('');
    }
  }, [boardMembers]);

  const sectionResults = sortedBoardMembers?.map(section => {
    const boardMemberResults = boardMembers?.filter(boardMember =>
      boardMember?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
    return {
      id: section.id,
      boardMembers: section.boardMembers?.filter(boardMember => boardMemberResults?.includes(boardMember)),
    };
  });

  if (!sectionResults.length) return null;

  return (
    <>
      <Modal
        modalOpen={modalState.open}
        closeModal={() => setModalState({ ...modalState, open: false })}
        label={translate('BOARD_MEMBERS_PAGE.IMAGES_AND_DOCUMENTS')}
      >
        <CoworkerFiles
          highResolutionImages={modalState.highResolutionImages}
          documents={modalState.documents}
          mobileMode
          imageProportion="FREEFORM"
        />
      </Modal>
      <SearchBar
        classes={s.searchBar}
        placeholder={translate('BOARD_MEMBERS_PAGE.SEARCH_LIST')}
        searchTerm={searchTerm}
        handleChange={handleChange}
      />
      <div className={s.coworkersSections}>
        {sectionResults?.map(section => {
          return (
            <div className={classnames(s.section, !section.boardMembers.length && s.empty)} key={section.id}>
              <Typography variant="h5">
                <Translate>{`BOARD_MEMBERS_PAGE.${section.id}`}</Translate>
              </Typography>
              <div className={s.coworkers}>
                {section?.boardMembers?.map(boardMember => {
                  const sizes = getImages('SQUARE');
                  const imageSrc = pickFrom(boardMember, 'image.href');
                  const imageAlt = pickFrom(boardMember, 'image.altText');
                  return (
                    <div className={s.coworker} key={boardMember.id}>
                      <VCard
                        imageSrc={imageSrc && sizes.toSrc(imageSrc, 'S')}
                        imageSrcSet={imageSrc && sizes.toSrcSet(imageSrc)}
                        imageAlt={imageAlt && imageAlt}
                        name={boardMember.name}
                        title={
                          <div>
                            {boardMember?.teaserOptions.function && (
                              <div className={s.function}>
                                <Translate>{`BOARD_MEMBERS_PAGE.${boardMember?.teaserOptions.function}`}</Translate>
                              </div>
                            )}
                            {boardMember?.fields?.company && (
                              <div className={s.company}>{boardMember?.fields?.company}</div>
                            )}
                            {boardMember?.fields?.city && <div>{boardMember?.fields?.city}</div>}
                            {translation.IS_SWEDISH && boardMember?.teaserOptions?.electedBy && (
                              <div>
                                <Translate>BOARD_MEMBERS_PAGE.ELECTED_BY</Translate>{' '}
                                {boardMember?.teaserOptions?.electedBy}
                              </div>
                            )}
                          </div>
                        }
                        classes={s.coworkerCard}
                        nameLink={boardMember.profileLink}
                        imageLink={boardMember.profileLink}
                        imageWrapperClasses={classnames(s.coworkerCardImageWrapper, !imageSrc && s.noImage)}
                        linkButtonOnClick={
                          boardMember.highResolutionImages &&
                          (() =>
                            setModalState({
                              open: true,
                              highResolutionImages: boardMember.highResolutionImages,
                            }))
                        }
                        linkText={translate('BOARD_MEMBERS_PAGE.HIGH_RESOLUTION_IMAGES')}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default withStyles(s, boostrapGrid)(BoardMembers);
