/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import renderFactsDarkModeStorylineElement from 'utils/renderFactsDarkModeStorylineElement';
import s from './TimelinesParagraph.css';

const TimelinesParagraph = ({ storyline = [], darkMode }) => {
  return (
    <div className={s.timelinesParagraph}>
      {storyline &&
        storyline.map((el, idx) =>
          renderFactsDarkModeStorylineElement(el, darkMode, idx !== 0, idx !== storyline.length - 1)
        )}
    </div>
  );
};

export default withStyles(s)(TimelinesParagraph);
