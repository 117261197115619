// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Table-tableScrollWrapper-AWrc_{margin-bottom:1rem;overflow-x:auto}@media (min-width:820px){.Table-tableScrollWrapper-AWrc_{overflow-x:visible;overflow-x:initial}}.Table-table-6tv23 table,.Table-table-6tv23 tbody,.Table-table-6tv23 td,.Table-table-6tv23 tfoot,.Table-table-6tv23 th,.Table-table-6tv23 thead,.Table-table-6tv23 tr{-webkit-border-horizontal-spacing:0;-webkit-border-vertical-spacing:0;border:none;border-collapse:inherit;border-color:inherit;border-spacing:0;font-weight:inherit;height:auto;margin:0;padding:0;text-align:left;vertical-align:top;width:auto}.Table-table-6tv23 thead tr{background-color:rgba(51,65,78,.05);border:1px solid #dee4ea}.Table-table-6tv23 tbody tr{background-color:rgba(51,65,78,0);border:1px solid #dee4ea}.Table-columnHeader-ZEryp{background-color:rgba(51,65,78,.05);border-right:1px solid #dee4ea}.Table-darkMode-uKZsK thead tr{background-color:hsla(0,0%,100%,.2);border:1px solid #dee4ea}.Table-darkMode-uKZsK tbody tr{background-color:hsla(0,0%,100%,.1);border:1px solid #dee4ea}.Table-darkMode-uKZsK .Table-columnHeader-ZEryp{background-color:hsla(0,0%,100%,.2);border-right:1px solid #dee4ea}.Table-table-6tv23{border-collapse:collapse;min-width:calc(100% - 2px)}@media (min-width:820px){.Table-table-6tv23{min-width:0;min-width:auto}}.Table-table-6tv23 thead{white-space:nowrap}.Table-table-6tv23 td,.Table-table-6tv23 th{padding:8px 12px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"tableScrollWrapper": "Table-tableScrollWrapper-AWrc_",
	"table": "Table-table-6tv23",
	"columnHeader": "Table-columnHeader-ZEryp",
	"darkMode": "Table-darkMode-uKZsK"
};
module.exports = exports;
