import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';
import _sortBy from 'lodash/sortBy';

export default createSelector(cookSelectors.getData, data => {
  try {
    const members = pickFrom(data, 'memberOrganisationsForExpressionOfInterestForm');
    return _sortBy(members, member => member.title);
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
