import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './VimeoTestBalk.css';

const VimeoTestBalk = () => {
  return (
    <p className={s.vimeoTestBalk}>
      <strong>OBS TESTSÄNDNING.</strong>
      <br />
      Dela inte denna sida.
    </p>
  );
};

export default withStyles(s)(VimeoTestBalk);
