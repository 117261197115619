import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import HomePageContainer from 'containers/pages/HomePageContainer';
import BoardMembersPageContainer from 'containers/pages/BoardMembersPageContainer';
import ArticlePageContainer from 'containers/pages/ArticlePageContainer';
import TvPageContainer from 'containers/pages/TvPageContainer';
import ContactUsPageContainer from 'containers/pages/ContactUsPageContainer';
import TagPageContainer from 'containers/pages/TagPageContainer';
import EventPageContainer from 'containers/pages/EventPageContainer';
import InfoPageContainer from 'containers/pages/InfoPageContainer';
import ReportPageContainer from 'containers/pages/ReportPageContainer';
import CoworkerPageContainer from 'containers/pages/CoworkerPageContainer';
import FocusAreaPageContainer from 'containers/pages/FocusAreaPageContainer';
import FocusAreasPageContainer from 'containers/pages/FocusAreasPageContainer';
import BecomeMemberPageContainer from 'containers/pages/BecomeMemberPageContainer';
import RegionOfficePageContainer from 'containers/pages/RegionOfficePageContainer';
import CoworkerProfilePageContainer from 'containers/pages/CoworkerProfilePageContainer';
import PressReleasePageContainer from 'containers/pages/PressReleasePageContainer';
import ReferralResponsePageContainer from 'containers/pages/ReferralResponsePageContainer';
import PressRoomPageContainer from 'containers/pages/PressRoomPageContainer';
import PetitionPageContainer from 'containers/pages/PetitionPageContainer';
import SubscriptionPageContainer from 'containers/pages/SubscriptionPageContainer';
import RegionOfficesPageContainer from 'containers/pages/RegionOfficesPageContainer';
import StandardSectionPageContainer from 'containers/pages/StandardSectionPageContainer';
import MembershipOrganizationsPageContainer from 'containers/pages/MembershipOrganizationsPageContainer';
import MailUsPageContainer from 'containers/pages/MailUsPageContainer';
import WorkPageContainer from 'containers/pages/WorkPageContainer';
import SearchResultPageContainer from 'containers/pages/SearchResultPageContainer';
import MaterialArchivePageContainer from 'containers/pages/MaterialArchivePageContainer';
import CalendarPageContainer from 'containers/pages/CalendarPageContainer';
import ListPageContainer from 'containers/pages/ListPageContainer';
import BusinessPanelFormPageContainer from 'containers/pages/BusinessPanelFormPageContainer';
import MicrositePageContainer from 'containers/pages/MicrositePageContainer';
import FlexibleSection from '../components/pages/FlexibleSection/FlexibleSection';
import BlogPostPage from '../components/pages/blog/BlogPostPage/BlogPostPage';
import BlogPage from '../components/pages/blog/BlogPage/BlogPage';
import BlogSearchPage from '../components/pages/blog/BlogSearchPage/BlogSearchPage';
import MyPageContainer from '../containers/pages/MyPageContainer';

export default createSelector(cookSelectors.getData, cookSelectors.getError, (data, error) => {
  try {
    if (error) {
      return undefined;
    }
    if (data) {
      const contentType = data?.context?.contentType;
      const contextType = data?.resolution?.context;

      if (contextType === 'sec') {
        const uniqueName = data?.resolution?.section?.uniqueName;
        const parentUniqueName = data?.resolution?.section?.parent?.uniqueName;
        const rootGroupType = data?.context?.rootGroup?.type;

        switch (uniqueName) {
          case 'main_contact_SN':
            return ContactUsPageContainer;
          case 'BusinessPanelForm':
            return BusinessPanelFormPageContainer;
          case 'bli_medlem_intresseanmalan':
            return BecomeMemberPageContainer;
          case 'staff_english':
          case 'medarbetare':
            return CoworkerPageContainer;
          case 'pressrum':
            return PressRoomPageContainer;
          case 'regioner':
            return RegionOfficesPageContainer;
          case 'nyhetsbrev':
            return SubscriptionPageContainer;
          case 'medlemsorganisationer':
            return MembershipOrganizationsPageContainer;
          case 'contactEmailSN':
            return MailUsPageContainer;
          case 'ledigatjanster':
            return WorkPageContainer;
          case 'search':
            return SearchResultPageContainer;
          case 'material':
            return MaterialArchivePageContainer;
          case 'kalendarium_start':
          case 'inspelade_webbinarier':
            return CalendarPageContainer;
          case 'fokus-pa-skatterna-sokresultat':
          case 'kompetensforsorjning-sokresultat':
            return BlogSearchPage;
          default:
            break;
        }

        if (uniqueName === 'min-sida' || parentUniqueName === 'min-sida') {
          return MyPageContainer;
        }

        switch (rootGroupType) {
          case 'snStartPage':
            return HomePageContainer;
          case 'focusAreas':
            return FocusAreasPageContainer;
          case 'focusArea':
            return FocusAreaPageContainer;
          case 'regionOffice':
            return RegionOfficePageContainer;
          case 'listSection':
            return ListPageContainer;
          case 'boardMembers':
            return BoardMembersPageContainer;
          case 'microsite':
            return MicrositePageContainer;
          case 'blog':
            return BlogPage;
          case 'flexibleSection':
            return FlexibleSection;
          default:
            break;
        }
        if (rootGroupType === 'snStandardSection') return StandardSectionPageContainer;
      } else if (contextType === 'art') {
        switch (contentType) {
          case 'report':
            return ReportPageContainer;
          case 'focusArea':
            return FocusAreaPageContainer;
          case 'story':
          case 'storyline':
            return ArticlePageContainer;
          case 'remiss':
            return ReferralResponsePageContainer;
          case 'tv':
            return TvPageContainer;
          case 'event':
            return EventPageContainer;
          case 'text':
            return InfoPageContainer;
          case 'pressRelease':
            return PressReleasePageContainer;
          case 'profile':
            return CoworkerProfilePageContainer;
          case 'skrivelse':
            return PetitionPageContainer;
          case 'blogPost':
            return BlogPostPage;
          default:
        }
      } else if (contextType === 'tag') {
        return TagPageContainer;
      }
    }
  } catch (err) {
    console.error(err);
  }
  return undefined;
});
