import React from 'react';
import Embed, { defaultProviders } from 'react-tiny-oembed';

const extraProviders = [
  {
    provider_name: 'Infogram',
    provider_url: 'https://infogram.com/',
    endpoints: [
      {
        schemes: ['https://infogram.com/*'],
        url: 'https://infogram.com/oembed',
      },
    ],
  },
];

function InfogramBlock({ uri }) {
  return (
    <Embed
      style={{ maxWidth: '100%' }}
      url={uri}
      providers={[...defaultProviders, ...extraProviders]}
      // eslint-disable-next-line react/jsx-no-useless-fragment
      LoadingFallbackElement={<></>}
    />
  );
}

export default InfogramBlock;
