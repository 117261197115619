// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FilterSelectedBlock-filterBlock-2FjhH .FilterSelectedBlock-headline-g4c_j{color:#62717f;font-family:LabGrotesque-Bold,sans-serif;font-size:.875rem;line-height:1.375rem;margin-bottom:16px;text-transform:uppercase}.FilterSelectedBlock-filterBlock-2FjhH .FilterSelectedBlock-facet-1oDNX{-ms-flex-direction:row;flex-direction:row}.FilterSelectedBlock-filterBlock-2FjhH .FilterSelectedBlock-buttonContainer-35m0W{display:-ms-flexbox;display:flex;padding-top:22px}.FilterSelectedBlock-filterBlock-2FjhH .FilterSelectedBlock-showResultButton-T6eqC{-ms-flex-positive:1;flex-grow:1;margin-left:6px}.FilterSelectedBlock-mobileMode-31Qxr{display:none}@media screen and (max-width:1168px){.FilterSelectedBlock-filterBlock-2FjhH{display:none}.FilterSelectedBlock-mobileMode-31Qxr{background-color:#fff;display:block;padding:.5rem .5rem 1rem}.FilterSelectedBlock-headlineMobileMode-3aIF4{display:none}.FilterSelectedBlock-buttonContainer-35m0W{border-radius:22px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"filterBlock": "FilterSelectedBlock-filterBlock-2FjhH",
	"headline": "FilterSelectedBlock-headline-g4c_j",
	"facet": "FilterSelectedBlock-facet-1oDNX",
	"buttonContainer": "FilterSelectedBlock-buttonContainer-35m0W",
	"showResultButton": "FilterSelectedBlock-showResultButton-T6eqC",
	"mobileMode": "FilterSelectedBlock-mobileMode-31Qxr",
	"headlineMobileMode": "FilterSelectedBlock-headlineMobileMode-3aIF4"
};
module.exports = exports;
