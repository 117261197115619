import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import getImages from 'utils/getImages';
import classnames from 'classnames';
import Link from 'containers/atoms/LinkContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/pro-solid-svg-icons';
import Typography from 'components/atoms/Typography';
import useMediaQueryWidth from '../../../utils/hooks/useMediaQueryWidth';
import s from './QuoteBox2.css';
import clientifyUrl from '../../../utils/clientifyUrl';

const QuoteBox = ({
  imageSrc,
  imageAlt,
  quote,
  title,
  subtitle,
  name,
  profileLink,
  internalUrl,
  externalUrl,
  linkText,
  assignment,
  target,
  isAbsolute = false,
  bgColorScheme = 'red',
  showQuote = true,
}) => {
  const is820 = useMediaQueryWidth(820);

  let sizes = getImages('PORTRAIT');

  if (is820 && !isAbsolute) {
    sizes = getImages('LANDSCAPE');
  }

  const contentClasses = [s.content];
  const quoteBoxClasses = [s.quoteBox];
  if (isAbsolute) {
    contentClasses.push(s.absolute);
    quoteBoxClasses.push(s.absolute);
  }
  if (bgColorScheme === 'red') {
    contentClasses.push(s.bgRedGradient);
    quoteBoxClasses.push(s.bgRedGradient);
  } else if (bgColorScheme === 'blueGrayGradient') {
    contentClasses.push(s.bgGrayBlueGradient);
    quoteBoxClasses.push(s.bgGrayBlueGradient);
  } else {
    contentClasses.push(s.bgRedGradient);
    quoteBoxClasses.push(s.bgRedGradient);
  }

  return (
    <div className={classnames(...quoteBoxClasses)}>
      {imageSrc && (
        <img
          className={classnames(s.image, {
            [s.absolute]: isAbsolute,
          })}
          src={sizes.toSrc(imageSrc, 'L')}
          alt={imageAlt}
        />
      )}
      <figure className={classnames(...contentClasses)}>
        {!showQuote && (
          <div className={classnames(s.articleContainer)}>
            {title && (
              <Typography
                to={clientifyUrl(internalUrl)}
                component={Link}
                variant="h2"
                className={classnames(s.text, s.title)}
                gutterBottom
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography component="p" variant="p" className={classnames(s.text, s.subtitle)}>
                {subtitle}
              </Typography>
            )}
            {internalUrl && (
              <Typography
                to={clientifyUrl(internalUrl)}
                variant="linkSmall"
                component={Link}
                className={classnames(s.text, s.internalUrl)}
              >
                {linkText}
              </Typography>
            )}
          </div>
        )}
        {showQuote && (
          <blockquote
            className={classnames(s.blockquote, {
              [s.absolute]: isAbsolute,
            })}
          >
            <FontAwesomeIcon icon={faQuoteRight} />
            <Typography component="p" variant="h3" className={s.quote}>
              {quote}
            </Typography>
            <cite>
              {profileLink && (
                <Typography
                  href={clientifyUrl(profileLink)}
                  variant="link"
                  component="a"
                  target={target}
                  className={s.name}
                >
                  {`${name}${assignment ? `,` : ``}`}
                </Typography>
              )}
              {internalUrl && (
                <Typography
                  to={clientifyUrl(internalUrl)}
                  variant="linkSmall"
                  component={Link}
                  className={s.name}
                >
                  {linkText}
                </Typography>
              )}
              {externalUrl && (
                <Typography
                  href={clientifyUrl(externalUrl)}
                  variant="link"
                  component="a"
                  target={target}
                  className={s.name}
                >
                  {linkText}
                </Typography>
              )}
              {!internalUrl && !externalUrl && !profileLink && (linkText || name) && (
                <span className={s.name}>{`${linkText || name}${assignment ? ` - ${assignment}` : ``}`}</span>
              )}
            </cite>
          </blockquote>
        )}
      </figure>
    </div>
  );
};

export default withStyles(s)(QuoteBox);
