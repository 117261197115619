// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".KeyValue-keyValue-1QSLV{-ms-flex-align:center;align-items:center;display:grid;grid-template-columns:1fr}@media (min-width:1169px){.KeyValue-keyValue-1QSLV{grid-template-columns:1fr 1fr}}.KeyValue-keyValue-1QSLV .KeyValue-label-15YVQ{margin-bottom:8px;text-transform:uppercase}@media (min-width:1169px){.KeyValue-keyValue-1QSLV .KeyValue-label-15YVQ{margin-bottom:0}}.KeyValue-keyValue-1QSLV .KeyValue-value-3PV0s{background-color:#faf8f6;color:#b12f26;font-family:LabGrotesque-Bold,sans-serif;font-size:2.75rem;padding:4px}.KeyValue-keyValue-1QSLV .KeyValue-value-3PV0s.KeyValue-hidden-21P2u{color:transparent}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"keyValue": "KeyValue-keyValue-1QSLV",
	"label": "KeyValue-label-15YVQ",
	"value": "KeyValue-value-3PV0s",
	"hidden": "KeyValue-hidden-21P2u"
};
module.exports = exports;
