/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import tnlvLogo from 'assets/svg/tnlv_logo.svg';
import s from './FooterMoreNews.css';
import Translate from '../../../containers/atoms/TranslateContainer';

const FooterContact = () => (
  <div className={s.footerMoreNews}>
    <div className={s.headline}>
      <Translate>FOOTER.MORE_NEWS.TITLE</Translate>
    </div>
    <div className={s.rightWrapper}>
      <div className={s.imageWrapper}>
        <img src={tnlvLogo} width="68" height="68" alt="Tidningen Näringslivet" />
      </div>
      <div>
        <div>
          <Translate>FOOTER.MORE_NEWS.SUMMARY</Translate>
        </div>
        <div>
          <a className={s.footerLink} href="https://www.tn.se/">
            <Translate>FOOTER.MORE_NEWS.LINK</Translate>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default withStyles(s)(FooterContact);
