import { connect } from 'react-redux';
import getBoardMembers from '../../selectors/getBoardMembers';
import getSortedBoardMembers from '../../utils/getSortedBoardMembers';
import BoardMembers from '../../components/organisms/BoardMembers';

const mapStateToProps = state => {
  const unsortedCoworkers = getBoardMembers(state);
  return {
    sortedBoardMembers: getSortedBoardMembers(unsortedCoworkers),
    boardMembers: unsortedCoworkers,
  };
};

const mapDispatchToProps = undefined;

const BoardMembersContainer = connect(mapStateToProps, mapDispatchToProps)(BoardMembers);

export default BoardMembersContainer;
