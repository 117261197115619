import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './FacebookButton.css';

const FacebookButton = ({
  classes,
  component: Component = 'button',
  gutterBottom,
  block,
  children,
  ...other
}) => {
  const classNames = classnames(s.facebookButton, block && s.block, gutterBottom && s.gutterBottom, classes);
  const type = Component === 'button' ? 'button' : undefined;
  return (
    <Component className={classNames} type={type} {...other}>
      {children}
    </Component>
  );
};

export default withStyles(s)(FacebookButton);
