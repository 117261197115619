import getMetdata from 'selectors/metadata/getMetdata';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import { connect } from 'react-redux';
import getUniqueName from '../../selectors/getUniqueName';
import MyPage from '../../components/organisms/MyPage/MyPage';

const mapStateToProps = state => {
  return {
    metadata: getMetdata(state),
    uniqueName: getUniqueName(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
  };
};

const mapDispatchToProps = undefined;

const MyPageContainer = connect(mapStateToProps, mapDispatchToProps)(MyPage);

export default MyPageContainer;
