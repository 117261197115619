// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BlogPostInfo-blogPostInfo-2k5qm{background:#ea8f12 url(/snOutlineWingsWhiteBg.png) 100% 100% no-repeat;background-size:cover;color:#fff;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;left:0;line-height:2em;padding:16px 16px 16px 32px;position:absolute;top:310px}@media (min-width:820px){.BlogPostInfo-blogPostInfo-2k5qm{padding:16px 16px 16px 160px;top:120px}}@media (min-width:1169px){.BlogPostInfo-blogPostInfo-2k5qm{top:90px}}.BlogPostInfo-blogPostInfo-2k5qm.BlogPostInfo-fokusPaSkatterna-1mLV8{background-color:#b12f26}.BlogPostInfo-blogPostInfo-2k5qm.BlogPostInfo-kompetensforsorjning-2XzZ3{background-color:#ea8f12}.BlogPostInfo-blogPostInfo-2k5qm a{color:#fff}.BlogPostInfo-blogPostInfo-2k5qm .BlogPostInfo-blogName-kjSOU{font-size:2rem;font-weight:500;text-decoration:none}.BlogPostInfo-blogPostInfo-2k5qm .BlogPostInfo-author-1u2Nm,.BlogPostInfo-blogPostInfo-2k5qm .BlogPostInfo-blogName-kjSOU:hover{text-decoration:underline}.BlogPostInfo-blogPostInfo-2k5qm .BlogPostInfo-author-1u2Nm{width:-webkit-max-content;width:-moz-max-content;width:max-content}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"blogPostInfo": "BlogPostInfo-blogPostInfo-2k5qm",
	"fokusPaSkatterna": "BlogPostInfo-fokusPaSkatterna-1mLV8",
	"kompetensforsorjning": "BlogPostInfo-kompetensforsorjning-2XzZ3",
	"blogName": "BlogPostInfo-blogName-kjSOU",
	"author": "BlogPostInfo-author-1u2Nm"
};
module.exports = exports;
