// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Card-card-2dHjo{border:1px solid #cfd4d8;border-radius:2px;overflow:hidden}.Card-card-2dHjo .Card-padding-1_fB0{padding:24px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"card": "Card-card-2dHjo",
	"padding": "Card-padding-1_fB0"
};
module.exports = exports;
