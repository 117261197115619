import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';
import extractImages from 'utils/extractImages';

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      const name = pickFrom(data, 'context.title');
      const fields = pickFrom(data, 'context.fields');
      const profileImage = pickFrom(data, 'context.profileImage');
      const images = profileImage?.length > 0 && extractImages(profileImage);
      const href = pickFrom(images, 'images.BASE.LANDSCAPE.href_full');

      return {
        ...fields,
        name,
        image: {
          ...images,
          href,
        },
      };
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
