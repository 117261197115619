/* eslint-disable css-modules/no-unused-class */
/* eslint-disable css-modules/no-undef-class */

import React, { useState } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { faMobile, faPhone, faEnvelope, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'components/organisms/Modal';
import Typography from 'components/atoms/Typography';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import Link from 'containers/atoms/LinkContainer';
// eslint-disable-next-line spaced-comment
//import WarningBanner from 'components/molecules/WarningBanner';
import CoworkerFiles from 'components/organisms/CoworkerFiles';
import AuthorBlogPostsSlider from 'components/organisms/AuthorBlogPostsSlider';
import LatestArticle from 'components/molecules/LatestArticle';
import DarkMode from 'components/templates/DarkMode';
import getImages from 'utils/getImages';
import generateSizes from 'utils/generateSizes';
// eslint-disable-next-line css-modules/no-unused-class
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './CoworkerProfilePage.css';
import Translate from '../../../containers/atoms/TranslateContainer';
import useTranslate from '../../../utils/hooks/useTranslate';

const notEmpty = str => str !== undefined && str !== '';

const getImage = item => ({
  image: item?.teaserImage?.[0] ?? item?.coverImage?.[0],
  format: item?.teaserImage?.[0] ? 'BASE_LANDSCAPE' : 'BASE_FREEFORM',
  mobileFormat: item?.teaserImage[0] ? 'SQUARE' : 'FREEFORM',
});

const CoworkerProfilePage = ({
  classes,
  coworkerProfileData,
  latestArticles,
  highResolutionImages,
  documents,
  darkMode = true,
  darkModeType,
  latestBlogPosts,
  isBlogWriter,
}) => {
  const translate = useTranslate();
  const [modalOpen, setModalOpen] = useState(false);
  const [latestToShow, setLatestToShow] = useState(10);
  const classNames = classnames(s.coworkerProfilePage, darkMode && s.darkMode, classes);
  const imageSizes = generateSizes({ default: '158px' });

  const renderBlogSentence = () => {
    if (isBlogWriter.fokusPaSkatterna && isBlogWriter.kompetensforsorjning) {
      return (
        <Typography className={s.blogSentence} darkMode gutterTop gutterBottom>
          {coworkerProfileData.name} skriver även i bloggarna{' '}
          <Link to="/blogg/fokus-pa-skatterna/">Fokus på skatterna</Link> och{' '}
          <Link to="/blogg/kompetensforsorjning">Kompetensförsörjning</Link>.
        </Typography>
      );
    }

    if (isBlogWriter.fokusPaSkatterna && !isBlogWriter.kompetensforsorjning) {
      return (
        <Typography className={s.blogSentence} darkMode gutterTop gutterBottom>
          {coworkerProfileData.name} skriver även i bloggen{' '}
          <Link to="/blogg/fokus-pa-skatterna/">Fokus på skatterna</Link>.
        </Typography>
      );
    }

    if (!isBlogWriter.fokusPaSkatterna && isBlogWriter.kompetensforsorjning) {
      return (
        <Typography className={s.blogSentence} darkMode gutterTop gutterBottom>
          {coworkerProfileData.name} skriver även i bloggen{' '}
          <Link to="/blogg/kompetensforsorjning/">Kompetensförsörjning</Link>.
        </Typography>
      );
    }

    return null;
  };

  return (
    <div className={classNames}>
      <Modal
        modalOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        label={translate('COWORKER_PROFILE_PAGE.IMAGES_AND_DOCUMENTS')}
      >
        <CoworkerFiles highResolutionImages={highResolutionImages} documents={documents} mobileMode />
      </Modal>
      <DarkMode darkMode={darkMode} darkModeType={darkModeType}>
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(s.topRowWrapper, boostrapGrid.row)}>
            <div
              className={classnames(
                boostrapGrid['col-lg-14'],
                boostrapGrid['offset-lg-5'],
                boostrapGrid['col-md-18'],
                boostrapGrid['offset-md-3'],
                boostrapGrid['col-sm-16'],
                boostrapGrid['offset-sm-4']
              )}
            >
              <div className={s.leadWrapper}>
                <div className={s.profileWrapper}>
                  <div className={s.profilePictureWrapper}>
                    {coworkerProfileData?.image?.href ? (
                      <ResponsiveImage
                        src={getImages('SQUARE').toSrc(coworkerProfileData?.image?.href, 'S')}
                        srcSet={getImages('SQUARE').toSrcSet(coworkerProfileData?.image?.href)}
                        sizes={imageSizes}
                        width={158}
                        height={158}
                        lazyLoadWrapperHeight={158}
                      />
                    ) : (
                      <div className={s.avatarWrapper}>
                        <FontAwesomeIcon icon={faUser} style={{ height: 48, color: '#bcc3c9' }} />
                      </div>
                    )}
                  </div>
                  <div>
                    <Typography variant="h1" darkMode>
                      {coworkerProfileData.name}
                    </Typography>
                    <div className={s.titleText}>{coworkerProfileData.assignment}</div>
                    <div className={s.contactContainers}>
                      {notEmpty(coworkerProfileData.phoneMobile) && coworkerProfileData.showMobile && (
                        <a href={`tel:${coworkerProfileData.phoneMobile}`} className={s.contactLink}>
                          <FontAwesomeIcon
                            icon={faMobile}
                            className={s.icon}
                            style={{ width: 13, height: 21 }}
                          />
                          {coworkerProfileData.phoneMobile}
                        </a>
                      )}
                      {notEmpty(coworkerProfileData.phoneWorkDirect) && !coworkerProfileData.hidePhone && (
                        <a href={`tel:${coworkerProfileData.phoneWorkDirect}`} className={s.contactLink}>
                          <FontAwesomeIcon
                            icon={faPhone}
                            className={s.icon}
                            style={{ width: 21, height: 20 }}
                          />
                          {coworkerProfileData.phoneWorkDirect}
                        </a>
                      )}
                      {notEmpty(coworkerProfileData.emailAddress) && !coworkerProfileData.hideEmail && (
                        <a href={`mailto:${coworkerProfileData.emailAddress}`} className={s.contactLink}>
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className={s.icon}
                            style={{ width: 21, height: 20 }}
                          />
                          <Translate>COWORKER_PROFILE_PAGE.SEND_EMAIL</Translate>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                {notEmpty(coworkerProfileData.description) && (
                  <Typography gutterBottom darkMode classes={s.profileDescription}>
                    {coworkerProfileData.description}
                  </Typography>
                )}

                {(isBlogWriter.fokusPaSkatterna || isBlogWriter.kompetensforsorjning) && renderBlogSentence()}

                {(highResolutionImages?.length > 0 || documents?.length > 0) && (
                  <div className={s.seeMoreButtonWrapper}>
                    <ButtonSecondaryGhost
                      inverted
                      classes={s.seeMoreButton}
                      onClick={() => setModalOpen(true)}
                    >
                      <Translate>COWORKER_PROFILE_PAGE.SEE_IMAGES_AND_FILES</Translate> (
                      {(highResolutionImages?.length || 0) + (documents?.length || 0)})
                    </ButtonSecondaryGhost>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DarkMode>
      {latestArticles?.length > 0 && (
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
            <div
              className={classnames(
                boostrapGrid['col-lg-14'],
                boostrapGrid['offset-lg-5'],
                boostrapGrid['col-md-18'],
                boostrapGrid['offset-md-3'],
                boostrapGrid['col-sm-22'],
                boostrapGrid['offset-sm-1'],
                s.latestArticleWrapper
              )}
            >
              {(isBlogWriter.fokusPaSkatterna || isBlogWriter.kompetensforsorjning) &&
                latestBlogPosts.length > 0 && (
                  <>
                    <h2 className={s.articleSmallHeadline}>
                      <Translate>COWORKER_PROFILE_PAGE.LATEST_BLOG_POSTS_BY</Translate>&nbsp;
                      {coworkerProfileData.name}
                    </h2>
                    <AuthorBlogPostsSlider blogPosts={latestBlogPosts} />
                  </>
                )}
              <h2 className={s.articleSmallHeadline}>
                <Translate>COWORKER_PROFILE_PAGE.LATEST_ARTICLES_BY</Translate>&nbsp;
                {coworkerProfileData.name}
              </h2>
              {latestArticles
                .slice(0, latestToShow)
                .map(({ id, contentType, title, summery, date, href, images, ...rest }) => {
                  const { image, format, mobileFormat } = getImage(rest);

                  const imageHref = image?.content?.fields?.[format]?.href_full;
                  const imageAlt = image?.content?.fields?.alttext;
                  const imageWidth =
                    format === 'BASE_LANDSCAPE'
                      ? image?.content?.fields?.[format]?.sourceWidth ||
                        image?.content?.fields?.[format]?.width
                      : image?.content?.originalPictureSize?.width;
                  const imageHeight =
                    format === 'BASE_LANDSCAPE'
                      ? image?.content?.fields?.[format]?.sourceHeight ||
                        image?.content?.fields?.[format]?.height
                      : image?.content?.originalPictureSize?.height;
                  const sizes = getImages(format.slice('BASE_'.length));
                  const sizesMobile = getImages(mobileFormat);

                  return (
                    <LatestArticle
                      key={id}
                      href={href}
                      articleType={contentType}
                      headline={title}
                      day={date}
                      text={summery}
                      imageSrc={imageHref && sizes.toSrc(imageHref, 'M')}
                      imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
                      imageSizes="190px"
                      imageMobileSrc={imageHref && sizes.toSrc(imageHref, 'XS')}
                      imageMobileSrcSet={imageHref && sizesMobile.toSrcSet(imageHref)}
                      imageMobileSizes="90px"
                      imageAlt={imageAlt}
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                    />
                  );
                })}
              {latestArticles.length > latestToShow && (
                <ButtonSecondaryGhost
                  key={`show-more-${latestToShow}`}
                  classes={s.showMoreEvents}
                  onClick={() => setLatestToShow(latestToShow + 10)}
                >
                  <Translate>COWORKER_PROFILE_PAGE.SHOW_MORE</Translate>
                </ButtonSecondaryGhost>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withStyles(boostrapGrid, s)(CoworkerProfilePage);
