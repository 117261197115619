// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AgendaBox-agendaBox-1UNoX{margin-bottom:1.5rem}.AgendaBox-agendaBox-1UNoX .AgendaBox-eventCard-18_FZ{padding:24px}.AgendaBox-agendaBox-1UNoX .AgendaBox-eventItem-2-5r0 .AgendaBox-eventItemSubItem-Vaz98{margin-top:6px}@media (min-width:820px){.AgendaBox-agendaBox-1UNoX .AgendaBox-eventItem-2-5r0 .AgendaBox-eventItemSubItem-Vaz98{margin-left:114px}}.AgendaBox-agendaBox-1UNoX .AgendaBox-eventItem-2-5r0:not(:first-child){padding-top:24px}.AgendaBox-agendaBox-1UNoX .AgendaBox-eventItem-2-5r0:not(:last-child){border-bottom:1px solid #dee4ea;padding-bottom:24px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"agendaBox": "AgendaBox-agendaBox-1UNoX",
	"eventCard": "AgendaBox-eventCard-18_FZ",
	"eventItem": "AgendaBox-eventItem-2-5r0",
	"eventItemSubItem": "AgendaBox-eventItemSubItem-Vaz98"
};
module.exports = exports;
