// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media (min-width:541px){.MobileExtraMenu-mobileExtraMenu-2XxcS{margin-top:16px}}.MobileExtraMenu-mobileExtraMenu-2XxcS nav{margin:0 auto;max-width:550px;width:100%}.MobileExtraMenu-mobileExtraMenu-2XxcS nav ul{list-style:none;margin:0;padding:0}.MobileExtraMenu-mobileExtraMenu-2XxcS nav ul li{border-top:1px solid #dee4ea;display:-ms-flexbox;display:flex}.MobileExtraMenu-mobileExtraMenu-2XxcS nav ul li:first-of-type{border-top:none}.MobileExtraMenu-mobileExtraMenu-2XxcS nav ul li a{color:#62717f;-ms-flex:1 1;flex:1 1;font-size:1rem;padding:12px 16px;text-decoration:none}.MobileExtraMenu-mobileExtraMenu-2XxcS nav ul li a .MobileExtraMenu-icon-GZ2pM{margin-left:8px;width:16px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"mobileExtraMenu": "MobileExtraMenu-mobileExtraMenu-2XxcS",
	"icon": "MobileExtraMenu-icon-GZ2pM"
};
module.exports = exports;
