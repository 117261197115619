import { connect } from 'react-redux';
import { selectors as locationSelectors } from 'ducks/location';
import MailUsPage from 'components/pages/MailUsPage';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getMailUsData from 'selectors/getMailUsData';

const mapStateToProps = state => {
  return {
    groupedStorylineTree: getGroupedStorylineTree(state),
    mailUsData: getMailUsData(state),
    hostname: locationSelectors.getHostname(state),
  };
};

const mapDispatchToProps = undefined;

const MailUsPageContainer = connect(mapStateToProps, mapDispatchToProps)(MailUsPage);

export default MailUsPageContainer;
