import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import s from './VCard.css';

const renderImage = (classes, component, src, srcSet, alt) => {
  return (
    <div className={classes}>
      {component ||
        (src && (
          <ResponsiveImage width={500} height={500} src={src} srcSet={srcSet} alt={alt} sizes="90px" />
        ))}
      {!component && !src && <FontAwesomeIcon icon={faUser} style={{ width: 27, height: 38 }} />}
    </div>
  );
};

const VCard = ({
  classes,
  className,
  darkMode,
  imageWrapperClasses,
  imageSrc,
  imageAlt,
  imageSrcSet,
  imageComponent,
  name,
  nameLink,
  title,
  titleLink,
  imageLink,
  href,
  downloadLink,
  linkButtonOnClick,
  linkText,
  nameWrapperClasses,
  titleWrapperClasses,
  ...other
}) => {
  const classNames = classnames(s.vCard, classes, { [className]: !!className });
  const imageWrapperClassNames = classnames(
    s.imageWrapper,
    imageWrapperClasses,
    !imageComponent && !imageSrc && s.noUser
  );
  return (
    <div className={classNames} {...other}>
      {imageSrc !== '' && (
        <div>
          {!imageLink && renderImage(imageWrapperClassNames, imageComponent, imageSrc, imageSrcSet, imageAlt)}
          {imageLink && (
            <Link to={imageLink} className={s.imageLink}>
              {renderImage(imageWrapperClassNames, imageComponent, imageSrc, imageSrcSet, imageAlt)}
            </Link>
          )}
        </div>
      )}

      <div>
        {name && !nameLink && (
          <div className={classnames(s.name, darkMode && s.darkMode, nameWrapperClasses)}>{name}</div>
        )}
        {name && nameLink && (
          <div className={classnames(s.name, s.nameLink, darkMode && s.darkMode, nameWrapperClasses)}>
            <Link to={nameLink}>{name}</Link>
          </div>
        )}
        {title && !titleLink && <div className={classnames(s.title, titleWrapperClasses)}>{title}</div>}
        {title && titleLink && (
          <div className={classnames(s.title, s.titleLink, titleWrapperClasses)}>
            <Link to={titleLink} className={classnames(s.viewContacts, darkMode && s.darkMode)}>
              {title}
            </Link>
          </div>
        )}
        {href && (
          <div>
            <Link to={href} className={classnames(s.viewContacts, darkMode && s.darkMode)}>
              {linkText}
            </Link>
          </div>
        )}
        {linkButtonOnClick && (
          <div>
            <button type="button" onClick={linkButtonOnClick} className={s.linkButton}>
              {linkText}
            </button>
          </div>
        )}
        {downloadLink && (
          <div>
            <Typography variant="linkSmall" component="a" target="_blank" href={downloadLink} download>
              {linkText}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(s)(VCard);
