/* eslint-disable camelcase */
import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import boostrapGrid from 'style/bootstrap-grid.css';
import ImageItem from './components/ImageItem';
import DocumentItem from './components/DocumentItem';
import Translate from '../../../containers/atoms/TranslateContainer';

import s from './CoworkerFiles.css';

const CoworkerFiles = ({ classes, highResolutionImages, documents, imageProportion }) => {
  const classNames = classnames(s.coworkerFiles, classes);
  return (
    <div className={classNames}>
      <div className={s.headline}>
        <Translate>COWORKER_FILES.IMAGES_AND_DOCUMENTS</Translate>
      </div>
      <Typography variant="caption" component="div" classes={s.caption}>
        <Translate>COWORKER_FILES.IMAGES</Translate>
      </Typography>
      {highResolutionImages?.map(image => (
        <ImageItem
          proportion={imageProportion}
          format={image?.fields?.metadata?.format}
          width={image?.fields?.metadata?.width}
          height={image?.fields?.metadata?.height}
          credit={image?.fields?.photographer}
          imageSrc={image?.fields?.BASE_LANDSCAPE?.href_full}
          imageAlt={image?.fields?.alttext}
          downloadUrl={image?.binary?.href}
        />
      )) || '-'}

      <Typography variant="caption" component="div" classes={s.caption}>
        <Translate>COWORKER_FILES.DOCUMENTS</Translate>
      </Typography>

      {documents?.map(document => (
        <DocumentItem
          headline={document?.title}
          fileType={document?.binary?.extension}
          downloadUrl={document?.binary?.href}
        />
      )) || '-'}
    </div>
  );
};

export default withStyles(s, boostrapGrid)(CoworkerFiles);
