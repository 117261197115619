import { connect } from 'react-redux';
import FocusAreasPage from 'components/pages/FocusAreasPage';
import getFocusAreaListItems from 'selectors/getFocusAreaListItems';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';

const mapStateToProps = state => {
  return {
    focusAreasListItems: getFocusAreaListItems(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
  };
};

const mapDispatchToProps = undefined;

const FocusAreasPageContainer = connect(mapStateToProps, mapDispatchToProps)(FocusAreasPage);

export default FocusAreasPageContainer;
