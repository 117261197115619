/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import inQueryObject from 'utils/inQueryObject';
import useTranslate from 'utils/hooks/useTranslate';
import FilterBall from 'components/atoms/FilterBall';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';

import s from './FilterSelectedBlock.css';

const FilterSelectedBlock = ({ filters, facets, selectFilter, unselectFilter, clearFilters, mobileMode }) => {
  const translate = useTranslate();

  const renderFacet = (facet, key, valueTransformer = val => val) => (
    <div className={s.facet}>
      {facet.map(item => {
        const firstInValue = item.value?.split('_')?.[0] || item.value;
        return (
          <FilterBall
            selectedBlock
            key={item.value}
            count={item.count}
            selected={inQueryObject(filters, key, firstInValue)}
            onClick={() =>
              inQueryObject(filters, key, firstInValue)
                ? unselectFilter(key, firstInValue)
                : selectFilter(key, firstInValue)
            }
          >
            {valueTransformer(item.value)}
          </FilterBall>
        );
      })}
    </div>
  );

  return filters.issue || filters.profile || filters.subject || filters.year ? (
    <div className={classnames(s.block, mobileMode && s.mobileMode)}>
      <div className={classnames(s.filterBlock, mobileMode && s.mobileMode)}>
        <div className={s.filterBallContainer}>
          <div className={classnames(s.headline, mobileMode && s.headlineMobileMode)}>
            {translate('LIST_PAGE.ACTIVE_FILTERS')}
          </div>
          {facets?.year?.length > 0 && renderFacet(facets?.year, 'year')}
          {facets?.profile_facet?.length > 0 &&
            renderFacet(facets.profile_facet, 'profile', val => val?.split('_')?.[1])}
          {facets?.issue_facet?.length > 0 &&
            renderFacet(facets.issue_facet, 'issue', val => val?.split('_')?.[1])}
          {facets?.subject_facet?.length > 0 &&
            renderFacet(facets.subject_facet, 'subject', val => val?.split('_')?.[1])}
        </div>
        <div className={s.buttonContainer}>
          <ButtonSecondaryGhost onClick={clearFilters}>{translate('LIST_PAGE.CLEAR')}</ButtonSecondaryGhost>
        </div>
      </div>
    </div>
  ) : null;
};

export default withStyles(s)(FilterSelectedBlock);
