import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getSearchQuery from 'selectors/getSearchQuery';
import getQueryString from 'selectors/getQueryString';
import getSearchFacets from 'selectors/getSearchFacets';
import getDataPathWithoutParams from 'selectors/getDataPathWithoutParams';
import getBlogSearchResults from 'selectors/getBlogSearchResults';
import getSearchMetadata from 'selectors/getSearchMetadata';
import mapStrUndefinedValuesToUndefined from '../utils/mapStrUndefinedToUndefined';

const getBlogName = createSelector(cookSelectors.getData, data => {
  return data?.resolution?.section?.parent?.name;
});

export default createSelector(
  getGroupedStorylineTree,
  getBlogName,
  getSearchQuery,
  getQueryString,
  getSearchFacets,
  getDataPathWithoutParams,
  getBlogSearchResults,
  getSearchMetadata,
  (
    groupedStorylineTree,
    blogName,
    searchQuery,
    queryString,
    searchFacets,
    pathWithoutParams,
    searchResults,
    searchMetadata
  ) => {
    const newSearchMetaData = mapStrUndefinedValuesToUndefined(searchMetadata);

    return {
      groupedStorylineTree,
      blogName,
      searchQuery,
      queryString,
      searchFacets,
      pathWithoutParams,
      searchResults,
      searchMetadata: newSearchMetaData,
      pagination: {
        prev: newSearchMetaData?.previousPage && `${pathWithoutParams}${newSearchMetaData.previousPage}`,
        next: newSearchMetaData?.nextPage && `${pathWithoutParams}${newSearchMetaData.nextPage}`,
      },
    };
  }
);
