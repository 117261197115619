// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DesktopFooter-desktopFooter-353b7{display:none;padding:56px}.DesktopFooter-desktopFooter-353b7 .DesktopFooter-lowerContentLinks-fnJ-L,.DesktopFooter-desktopFooter-353b7 .DesktopFooter-lowerContentSocial-2Ox7c{margin-top:25px}@media (min-width:1169px){.DesktopFooter-desktopFooter-353b7{display:block}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"desktopFooter": "DesktopFooter-desktopFooter-353b7",
	"lowerContentLinks": "DesktopFooter-lowerContentLinks-fnJ-L",
	"lowerContentSocial": "DesktopFooter-lowerContentSocial-2Ox7c"
};
module.exports = exports;
