// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TimelinesParagraph-timelinesParagraph-10nGQ{color:#33414e;display:inline-block;font:normal normal normal 18px/28px Lab Grotesque;letter-spacing:0;margin-left:1.5rem;margin-top:-6px;opacity:1;padding-bottom:1.5rem;text-align:left}", ""]);
// Exports
exports.locals = {
	"timelinesParagraph": "TimelinesParagraph-timelinesParagraph-10nGQ"
};
module.exports = exports;
