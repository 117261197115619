// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FilterBall-filterBall-1zitY{background:#fff;border:1px solid #cfd4d8;border-radius:22px;color:#62717f;cursor:pointer;display:inline-block;font-family:LabGrotesque-Regular,sans-serif;font-size:1rem;margin-bottom:12px;margin-right:8px;padding:12px 24px;text-decoration:none;-webkit-transition:all .2s ease-in-out;transition:all .2s ease-in-out}.FilterBall-filterBall-1zitY .FilterBall-count-1IN1M{font-size:.875rem;font-weight:700}.FilterBall-filterBall-1zitY:hover{background:#798692;border:1px solid #798692;color:#fff}.FilterBall-filterBall-1zitY.FilterBall-selected-1_JYB{background-color:#33414e;border:1px solid #33414e;color:#fff}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"filterBall": "FilterBall-filterBall-1zitY",
	"count": "FilterBall-count-1IN1M",
	"selected": "FilterBall-selected-1_JYB"
};
module.exports = exports;
