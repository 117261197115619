import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      const keyFigures = pickFrom(data, 'context.deskedContent.keyFigures');

      return keyFigures.map(obj => {
        return {
          id: pickFrom(obj, 'content.id'),
          title: pickFrom(obj, 'content.title'),
          number: pickFrom(obj, 'content.fields.number'),
          text: pickFrom(obj, 'content.fields.text'),
        };
      });
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
