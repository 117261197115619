// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VFSMunicipalityForm-vfsMunicipalityForm-1spcl{margin-bottom:1rem}.VFSMunicipalityForm-vfsMunicipalityForm-1spcl .VFSMunicipalityForm-form-1mCcK input[type=number],.VFSMunicipalityForm-vfsMunicipalityForm-1spcl .VFSMunicipalityForm-form-1mCcK input[type=text]{background-color:#fff;border:1px solid #cfd4d8;color:#33414e;font-family:LabGrotesque-Regular,sans-serif;font-size:1rem;line-height:1.5rem;margin-top:.5rem;padding:13px 16px;width:100%}.VFSMunicipalityForm-vfsMunicipalityForm-1spcl .VFSMunicipalityForm-results-7QSYT{overflow:hidden}.VFSMunicipalityForm-vfsMunicipalityForm-1spcl .VFSMunicipalityForm-results-7QSYT .VFSMunicipalityForm-resultsInner-2xMbD{padding-top:4rem}.VFSMunicipalityForm-vfsMunicipalityForm-1spcl .VFSMunicipalityForm-results-7QSYT .VFSMunicipalityForm-resultsInner-2xMbD .VFSMunicipalityForm-result-3jjyL{font-family:LabGrotesque-Regular,sans-serif;font-size:clamp(1.25rem,3vw,2.125rem);line-height:1.5}@media (min-width:620px){.VFSMunicipalityForm-vfsMunicipalityForm-1spcl .VFSMunicipalityForm-results-7QSYT .VFSMunicipalityForm-resultsInner-2xMbD .VFSMunicipalityForm-result-3jjyL{background:url(/vfs/vfs-arrow.png) 0 0 no-repeat;background-position:0;background-size:clamp(32px,4vw,48px);line-height:1.25;min-height:clamp(0,5vw,141px);padding-left:clamp(32px,7vw,72px)}}.VFSMunicipalityForm-vfsMunicipalityForm-1spcl .VFSMunicipalityForm-results-7QSYT .VFSMunicipalityForm-resultsInner-2xMbD .VFSMunicipalityForm-result-3jjyL strong{font-family:LabGrotesque-Bold,sans-serif}.VFSMunicipalityForm-vfsMunicipalityForm-1spcl .VFSMunicipalityForm-results-7QSYT .VFSMunicipalityForm-resultsInner-2xMbD .VFSMunicipalityForm-result-3jjyL+.VFSMunicipalityForm-result-3jjyL{margin-top:2rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"vfsMunicipalityForm": "VFSMunicipalityForm-vfsMunicipalityForm-1spcl",
	"form": "VFSMunicipalityForm-form-1mCcK",
	"results": "VFSMunicipalityForm-results-7QSYT",
	"resultsInner": "VFSMunicipalityForm-resultsInner-2xMbD",
	"result": "VFSMunicipalityForm-result-3jjyL"
};
module.exports = exports;
