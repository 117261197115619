import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import pushToDataLayer from '../../../../utils/pushToDataLayer';
import useCueMenu from '../../../../utils/hooks/queries/useCueMenu';
import Link from '../../../../containers/atoms/LinkContainer';
import SearchButton from '../SearchButton/SearchButton';
import useDarkHeader from '../../../../utils/hooks/useDarkHeader';
import useGlobalState from '../../../../zustand/zustand';
import s from './DesktopMainMenu.css';

const MenuList = ({ menuData, pushedItem }) => {
  const isDarkHeader = useDarkHeader();

  return (
    <ul>
      {menuData?.map(item => {
        const hasChildren = item.children?.length > 0;

        const handleClick = () => {
          pushToDataLayer('topMenuClick', {
            topMenuElementPath: item.path.join(' > '),
          });
        };

        return (
          <li
            key={item.id}
            className={classnames({
              [s.hasChildren]: hasChildren,
              [s.darkMode]: isDarkHeader,
              [s.active]: item.isActive || item.hasActiveChildren,
            })}
          >
            <Link onClick={handleClick} to={item.href}>
              {item.text}
            </Link>
            {hasChildren && (
              <div className={s.subMenuWrapper}>
                <div className={s.subMenuInner}>
                  {item.children.map(child => {
                    return Array.isArray(child.elements) && child.elements.length > 0 ? (
                      <MenuList key={child.id} menuData={child.elements} />
                    ) : null;
                  })}
                </div>
              </div>
            )}
          </li>
        );
      })}
      {pushedItem && <li className={s.pushRight}>{pushedItem}</li>}
    </ul>
  );
};

const DesktopMainMenu = ({ className }) => {
  const { mainMenu } = useCueMenu();
  const isDarkHeader = useDarkHeader();
  const shouldRenderSearchButton = useGlobalState(state => state.pageHeader.searchButton.isVisible);

  if (!mainMenu) {
    return null;
  }

  return (
    <nav
      className={classnames(s.desktopMainMenu, {
        [className]: !!className,
        [s.darkMode]: isDarkHeader,
      })}
    >
      <MenuList menuData={mainMenu} pushedItem={shouldRenderSearchButton ? <SearchButton /> : null} />
    </nav>
  );
};

export default withStyles(s)(DesktopMainMenu);
