import React from 'react';
import parse, { domToReact } from 'html-react-parser';
import Typography from 'components/atoms/Typography';
import LinkContainer from 'containers/atoms/LinkContainer';

const generateOptions = darkMode => {
  return {
    replace: node => {
      if (node.type === 'tag') {
        switch (node.name) {
          case 'h1':
          case 'h2':
          case 'h3':
          case 'h4':
            return (
              <Typography variant={node.name} darkMode={darkMode}>
                {domToReact(node.children, generateOptions(darkMode))}
              </Typography>
            );
          case 'p':
            return (
              <Typography darkMode={darkMode}>
                {domToReact(node.children, generateOptions(darkMode))}
              </Typography>
            );
          case 'a':
            if (node.attribs.href.startsWith('#')) {
              // eslint-disable-next-line no-param-reassign
              delete node.attribs.target;
            }

            return (
              <Typography
                variant="link"
                component={LinkContainer}
                darkMode={darkMode}
                {...node.attribs}
                to={node.attribs.href}
              >
                {domToReact(node.children, generateOptions(darkMode))}
              </Typography>
            );
          default:
            break;
        }
      }
      return null;
    },
  };
};

const htmlParser = (html, darkMode) => {
  return html ? parse(html, generateOptions(darkMode)) : html;
};

export default htmlParser;
