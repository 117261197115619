import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import s from './KeyFigures.css';

const KeyFigures = ({ keyFigures }) => {
  return (
    <div className={s.keyFigures}>
      {keyFigures.map(figure => (
        <div key={figure.id} className={s.keyFigure}>
          <Typography component="span" classes={s.number}>
            {figure.number}
          </Typography>
          <Typography variant="caption" classes={s.text}>
            {figure.text}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default withStyles(s)(KeyFigures);
