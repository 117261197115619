// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeroHeader-heroHeader-11L4I{background-position:top;background-repeat:no-repeat;background-size:cover;-webkit-clip-path:polygon(0 0,100% 0,100% 77%,0 100%);clip-path:polygon(0 0,100% 0,100% 77%,0 100%);height:495px;position:absolute;top:-100px;width:100%}@media (min-width:541px){.HeroHeader-heroHeader-11L4I{top:-100px}}@media (min-width:1169px){.HeroHeader-heroHeader-11L4I{top:-123px}}.HeroHeader-heroHeader-11L4I.HeroHeader-profileBlogImageblendBg-1tMUP{background-color:#637080}.HeroHeader-heroHeader-11L4I.HeroHeader-dimmedFokusPaSkatterna-3UN-_{-webkit-box-shadow:inset 0 0 0 99999px rgba(177,47,38,.9);box-shadow:inset 0 0 0 99999px rgba(177,47,38,.9)}.HeroHeader-heroHeader-11L4I.HeroHeader-dimmedKompetensforsorjning-1jHlu{-webkit-box-shadow:inset 0 0 0 99999px rgba(234,143,18,.9);box-shadow:inset 0 0 0 99999px rgba(234,143,18,.9)}.HeroHeader-heroHeader-11L4I .HeroHeader-inner-2s1PA{-ms-flex-pack:center;background-color:transparent;background-position:90% 0;background-repeat:no-repeat;background-size:cover;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;height:100%;justify-content:center}@media (min-width:541px){.HeroHeader-heroHeader-11L4I .HeroHeader-inner-2s1PA{background-position:100% 0}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"heroHeader": "HeroHeader-heroHeader-11L4I",
	"profileBlogImageblendBg": "HeroHeader-profileBlogImageblendBg-1tMUP",
	"dimmedFokusPaSkatterna": "HeroHeader-dimmedFokusPaSkatterna-3UN-_",
	"dimmedKompetensforsorjning": "HeroHeader-dimmedKompetensforsorjning-1jHlu",
	"inner": "HeroHeader-inner-2s1PA"
};
module.exports = exports;
