// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VideoBlock-videoBlock-3o0EU{margin-bottom:1rem;margin-left:-1.5rem;margin-right:-1.5rem;overflow:hidden;padding-bottom:1rem;position:relative}@media screen and (min-width:541px){.VideoBlock-videoBlock-3o0EU{margin-left:-2rem;margin-right:-2rem}}@media screen and (min-width:820px){.VideoBlock-videoBlock-3o0EU{margin-left:0;margin-right:0}}@media (min-width:820px){.VideoBlock-videoBlock-3o0EU.VideoBlock-twoColumnLayout-nZNDY{margin-left:0;margin-right:0}}@media (min-width:1169px){.VideoBlock-videoBlock-3o0EU.VideoBlock-twoColumnLayout-nZNDY{margin-left:0;margin-right:0}}@media screen and (min-width:541px){.VideoBlock-videoBlock-3o0EU.VideoBlock-inHeadGroup-36OD7{margin-left:-2rem;margin-right:-2rem}}.VideoBlock-videoBlock-3o0EU .VideoBlock-videoWrapper-YDsCb{margin-bottom:.75rem;padding:56.25% 0 0;position:relative}.VideoBlock-videoBlock-3o0EU .VideoBlock-videoWrapper-YDsCb iframe{height:100%;left:0;position:absolute;top:0;width:100%}.VideoBlock-videoBlock-3o0EU .VideoBlock-textWrapper-3YxI3{margin-left:1.5em;margin-right:1.5em}@media screen and (min-width:541px){.VideoBlock-videoBlock-3o0EU .VideoBlock-textWrapper-3YxI3{margin-left:0;margin-right:0}}.VideoBlock-videoBlock-3o0EU .VideoBlock-chatWrapper-A1MPM{margin-bottom:.75rem;margin-top:1rem;min-height:27rem;overflow:hidden;position:relative}.VideoBlock-videoBlock-3o0EU .VideoBlock-chatWrapper-A1MPM iframe{height:100%;left:0;position:absolute;top:0;width:100%}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"videoBlock": "VideoBlock-videoBlock-3o0EU",
	"twoColumnLayout": "VideoBlock-twoColumnLayout-nZNDY",
	"inHeadGroup": "VideoBlock-inHeadGroup-36OD7",
	"videoWrapper": "VideoBlock-videoWrapper-YDsCb",
	"textWrapper": "VideoBlock-textWrapper-3YxI3",
	"chatWrapper": "VideoBlock-chatWrapper-A1MPM"
};
module.exports = exports;
