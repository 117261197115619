// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WebinarBox-wrapper-3fIAi{-ms-flex-direction:column;flex-direction:column;max-width:300px}@media (min-width:541px){.WebinarBox-wrapper-3fIAi{display:-ms-flexbox;display:flex}}.WebinarBox-link-3UW6p{display:block}.WebinarBox-link-3UW6p:hover{text-decoration:underline}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"wrapper": "WebinarBox-wrapper-3fIAi",
	"link": "WebinarBox-link-3UW6p"
};
module.exports = exports;
