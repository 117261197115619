import React from 'react';
import classnames from 'classnames';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import withStyles from 'isomorphic-style-loader/withStyles';
import { format } from 'date-fns';
import { sv, enGB } from 'date-fns/locale';
import Translate from '../../../containers/atoms/TranslateContainer';
import s from './LatestArticle.css';
import useTranslation from '../../../utils/hooks/useTranslation';

const LatestArticle = ({
  classes,
  href,
  darkMode,
  articleType,
  headline,
  day,
  text,
  imageSrc,
  imageSrcSet,
  imageSizes,
  imageMobileSrc,
  imageMobileSrcSet,
  imageMobileSizes,
  imageAlt,
  imageWidth,
  imageHeight,
  ...other
}) => {
  const { IS_SWEDISH } = useTranslation();

  const classNames = classnames(s.latestArticle, classes);
  const aspectRatio = imageWidth / imageHeight;
  const height = Math.min(190 / aspectRatio, 190);
  const mobileHeight = !imageMobileSrc?.includes('SQUARE') ? 90 / aspectRatio : 90;

  return (
    <Link to={href} className={classNames} {...other}>
      <div className={s.textBlock}>
        <Typography variant="contentTypeDate" classes={s.latestOverline} darkMode={darkMode}>
          <Translate>{`CONTENT_TYPE.${articleType}`}</Translate>
          {day && (
            <span className={s.day}>{` — ${format(new Date(day), 'd MMMM yyyy', {
              locale: IS_SWEDISH ? sv : enGB,
            })}`}</span>
          )}
        </Typography>
        <Typography variant="h3" classes={s.latestArticleHeadline} gutterBottom={false} darkMode={darkMode}>
          {headline}
        </Typography>
        <div>
          <Typography gutterBottom={false} component="span" darkMode={darkMode}>
            {text.replace(/\u00A0/g, ' ')}
          </Typography>
        </div>
      </div>
      {imageSrc && (
        <div className={s.imageBlock}>
          <ResponsiveImage
            src={imageSrc}
            srcSet={imageSrcSet}
            sizes={imageSizes}
            alt={imageAlt}
            width={imageWidth}
            height={imageHeight}
            lazyLoadWrapperHeight={height}
          />
        </div>
      )}
      {imageMobileSrc && (
        <div className={s.imageMobileBlock}>
          <ResponsiveImage
            src={imageMobileSrc}
            srcSet={imageMobileSrcSet}
            sizes={imageMobileSizes}
            alt={imageAlt}
            width={90}
            height={mobileHeight}
            lazyLoadWrapperHeight={mobileHeight}
          />
        </div>
      )}
    </Link>
  );
};

export default withStyles(s)(LatestArticle);
