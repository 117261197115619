import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/pro-light-svg-icons';
import useTranslate from 'utils/hooks/useTranslate';
import Typography from 'components/atoms/Typography';
import s from './TranscodingVideo.css';

const BackgroundImage = ({ src }) => (
  <div className={classnames(s.cover, s.backgroundImage)} style={{ backgroundImage: `url(${src})` }} />
);
const Gradient = ({ withBgImage }) => (
  <div
    className={classnames(s.cover, {
      [s.gradientWithBgImage]: withBgImage,
      [s.gradientWithoutBgImage]: !withBgImage,
    })}
  />
);
const PlayIcon = ({ withBgImage }) => (
  <div
    className={classnames(s.cover, s.backgroundIcon, {
      [s.withBgImage]: withBgImage,
      [s.withoutBgImage]: !withBgImage,
    })}
  >
    <FontAwesomeIcon icon={faPlayCircle} />
  </div>
);

const TranscodingVideo = ({ backgroundImage }) => {
  const t = useTranslate();

  return (
    <div className={s.transcodingVideo}>
      {backgroundImage && <BackgroundImage src={backgroundImage} />}
      <Gradient withBgImage={!!backgroundImage} />
      <PlayIcon withBgImage={!!backgroundImage} />
      <div className={classnames(s.cover, s.content)}>
        <Typography darkMode variant="paragraphLarge">
          {t('EVENTS.TRANSCODING_VIDEO')}
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(s)(TranscodingVideo);
