import variants from './variants';

const mapping = {
  fullyBooked: variants.FULLY_BOOKED,
  canceled: variants.CANCELED,
  newDate: variants.NEW_DATE,
};

const stringToVariant = string => mapping[string];

export default stringToVariant;
