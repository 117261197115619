import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import axios from 'axios';
import Typography from 'components/atoms/Typography';
import toast from 'react-hot-toast';
import ButtonSuccess from '../../../../atoms/buttons/ButtonSuccess';
import ButtonPrimary from '../../../../atoms/buttons/ButtonPrimary/ButtonPrimary';
import ButtonPrimaryAlt from '../../../../atoms/buttons/ButtonPrimaryAlt/ButtonPrimaryAlt';
import ComboBox from '../../../../molecules/ComboBox/ComboBox';
import { getFrontEndOrigin } from '../../../../../utils/apiUtil';
import Spinner from '../../../../atoms/Spinner/Spinner';
import s from './ChangeReviewer.css';

const ChangeReviewer = ({ reviewData, handleCancelClicked, refreshReviewData, amITheCurrentReviewer }) => {
  const [reviewers, setReviewers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [storing, setStoring] = useState(false);
  const [storeSuccessful, setStoreSuccessful] = useState(false);
  const [selectedReviewer, setSelectedReviewer] = useState(null);

  const showErrorMessage = method => {
    const msg =
      method === 'GET' ? 'Listan med faktagranskare kunde inte laddas.' : 'Faktagranskare kunde inte sättas.';
    toast.error(msg);
  };

  const getReviewers = async () => {
    const articleId = reviewData?.reviewData?.article?.id;

    if (articleId) {
      const endpointUrl = `${getFrontEndOrigin()}/backoffice/api/goa/changeReviewer/${articleId}?failIfNotLoggedIn=true`;
      try {
        const results = await axios.get(endpointUrl, { withCredentials: true });

        if (results?.data?.possibleReviewers) {
          const processedReviewers = results?.data?.possibleReviewers.map(user => ({
            label: user.name,
            value: user.id,
          }));
          setReviewers(processedReviewers);
        } else {
          showErrorMessage('GET');
        }
      } catch (e) {
        showErrorMessage('GET');
      }
    } else {
      showErrorMessage('GET');
    }
  };

  const setReviewer = async reviewerId => {
    const articleId = reviewData?.reviewData?.article?.id;
    setStoring(true);

    const endpointUrl = `${getFrontEndOrigin()}/backoffice/api/goa/changeReviewer/?forceForegroundExecution=true`;
    try {
      const results = await axios.post(
        endpointUrl,
        { reviewerId, articleIds: [articleId] },
        { withCredentials: true }
      );

      if (results) {
        refreshReviewData();
        setSelectedReviewer(null);
        toast.success('Faktagranskare har ändrats.');
        setStoreSuccessful(true);
      } else {
        showErrorMessage('POST');
      }
    } catch (e) {
      showErrorMessage('POST');
    }
    setStoring(false);
  };

  useEffect(() => {
    getReviewers().then(() => setLoading(false));
  }, []);

  return (
    <div className={s.container}>
      {!loading && storeSuccessful && (
        <Typography variant="paragraphInfo">
          Du har angett att{' '}
          <strong>{amITheCurrentReviewer ? 'du' : reviewData?.reviewData?.currentReviewer?.name}</strong> ska
          vara faktagranskare för denna sida.{' '}
          <span className={s.backlink} onClick={() => handleCancelClicked()}>
            Gå tillbaka
          </span>
        </Typography>
      )}

      {!amITheCurrentReviewer ? (
        <>
          <Typography variant="paragraphSmall" gutterBottom>
            Nuvarande faktagranskare är <strong>{reviewData?.reviewData?.currentReviewer?.name}.</strong>
          </Typography>
          <ButtonPrimaryAlt
            type="submit"
            onClick={() => setReviewer(reviewData.apiRequest.authenticatedUser.id)}
            disabled={storing}
            loading={storing}
          >
            Gör mig till faktagranskare
          </ButtonPrimaryAlt>
          <br />
        </>
      ) : (
        <Typography variant="paragraphSmall" gutterBottom>
          Du är nuvarande faktagranskare.
        </Typography>
      )}
      {loading && <Spinner size="2x" />}
      {!loading && (
        <form>
          <Typography variant="inputLabel" htmlFor="reviewer" gutterBottom>
            Välj ny faktagranskare:
            <ComboBox
              className={s.comboBox}
              inputId="reviewer"
              placeholder="Välj ny faktagranskare"
              options={reviewers}
              value={selectedReviewer}
              onChange={option => setSelectedReviewer(option)}
            />
          </Typography>

          <div className={s.btnContainer}>
            <ButtonSuccess
              type="submit"
              onClick={() => setReviewer(selectedReviewer.value)}
              disabled={!selectedReviewer || storing}
              loading={storing}
              style={{ marginRight: 10 }}
            >
              Bekräfta val
            </ButtonSuccess>
            <ButtonPrimary onClick={handleCancelClicked} style={{ marginLeft: 10 }}>
              Avbryt
            </ButtonPrimary>
          </div>
        </form>
      )}
    </div>
  );
};

export default withStyles(s)(ChangeReviewer);
