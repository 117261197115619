import React, { useState, useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import Typography from 'components/atoms/Typography';
import { format } from 'date-fns';
import { enGB, sv } from 'date-fns/locale';
import s from './Vacancies.css';
import useTranslation from '../../../../../utils/hooks/useTranslation';

const Vacancies = () => {
  const { IS_SWEDISH } = useTranslation();
  const [state, setState] = useState({
    fetching: true,
    data: undefined,
    error: undefined,
  });
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('/vacancies');
        if (response.ok) {
          const data = await response.json();
          setState({
            ...state,
            fetching: false,
            data,
          });
        } else {
          setState({
            ...state,
            fetching: false,
            error: new Error('Subscription response not ok'),
          });
        }
      } catch (error) {
        setState({
          ...state,
          fetching: false,
          error,
        });
      }
    }
    fetchData();
  }, []);
  return (
    <div className={s.vacancies}>
      <Typography gutterBottom>
        Här under finns våra lediga tjänster som du kan söka just nu. Finns inget som passar, så är du
        välkommen att skicka en generell intresseanmälan.
      </Typography>
      {state?.data?.data?.length > 0 && (
        <table className={s.table}>
          <thead>
            <tr>
              <th col="cell">
                <Typography variant="contentTypeDate" bold component="span">
                  BEFATTNING
                </Typography>
              </th>
              <th col="cell">
                <Typography variant="contentTypeDate" bold component="span">
                  PLACERING
                </Typography>
              </th>
              <th col="cell">
                <Typography variant="contentTypeDate" bold component="span">
                  ANSÖK SENAST
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {state?.data?.data?.map(vacancy => (
              <tr key={vacancy.projectNr}>
                <th>
                  <div className={s.titleContainer}>
                    <FontAwesomeIcon icon={faArrowRight} className={s.arrowRight} aria-hidden="true" />
                    <Typography
                      variant="paragraphLarge"
                      bold
                      component="a"
                      href={vacancy.link}
                      classes={s.vacancyLink}
                      target="_blank"
                    >
                      {vacancy?.title}
                    </Typography>
                  </div>
                </th>
                <td>
                  <Typography variant="contentTypeDate" component="span">
                    {vacancy?.area2}
                  </Typography>
                </td>
                <td>
                  <Typography variant="contentTypeDate" component="span">
                    {format(new Date(vacancy?.pubDateTo), 'yyyy-MM-dd', { locale: IS_SWEDISH ? sv : enGB })}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {state?.data?.data?.length > 0 && (
        <div className={s.mobileList}>
          {state?.data?.data?.map(vacancy => (
            <div key={vacancy.projectNr} className={s.vacancyItemMobile}>
              <div className={s.titleContainer}>
                <Typography
                  variant="h3"
                  component="a"
                  href={vacancy.link}
                  classes={s.vacancyLink}
                  target="_blank"
                >
                  {vacancy?.title}
                </Typography>
              </div>
              <div>
                <Typography variant="contentTypeDate" component="span">
                  {'Placering: '}
                </Typography>
                <Typography variant="contentTypeDate" bold component="span">
                  {vacancy?.area2}
                </Typography>
              </div>
              <div>
                <Typography variant="contentTypeDate" component="span">
                  Ansök senast: {format(new Date(vacancy?.pubDateTo), 'yyyy-MM-dd')}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      )}
      {state?.data?.data?.length === 0 && (
        <Typography variant="contentTypeDate" bold>
          Just nu finns det inga lediga tjänster.
        </Typography>
      )}
      {state?.fetching && (
        <Typography variant="contentTypeDate" bold>
          Hämtar lediga tjänster...
        </Typography>
      )}
      {state?.error && (
        <Typography variant="contentTypeDate" bold>
          Kunde inte hämta lediga tjänster, försök igen senare.
        </Typography>
      )}
    </div>
  );
};

export default withStyles(s)(Vacancies);
