import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';
import s from './InDepthList.css';

const InDepthList = ({ classes, children, darkMode, layoutType, ...other }) => {
  const gridOrColumnsClassName = layoutType === 'columns' ? s.layoutTypeColumns : s.layoutTypeGrid;

  return (
    <ul className={classNames(s.inDepthList, gridOrColumnsClassName)} {...other}>
      {children}
    </ul>
  );
};

export default withStyles(s)(InDepthList);
