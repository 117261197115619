const chunkArray = (inputArray, perChunk) => {
  return inputArray.reduce((acc, current, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }

    acc[chunkIndex].push(current);

    return acc;
  }, []);
};

export default chunkArray;
