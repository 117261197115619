/* eslint-disable no-param-reassign */
import clientifyUrl from '../../../../utils/clientifyUrl';
import addHtml from './addHtml';
import extractFields from './extractFields';
import extractRelation from './extractRelation';
import extractRelationData from './extractRelationData';

const addAnnotationValueObject = (elementsInArticle, allSegmentElements) => {
  return elementsInArticle.map(elementInArticle => {
    return {
      ...elementInArticle,
      fields: Object.entries(elementInArticle.fields).reduce((acc, [currentKey, currentValue]) => {
        if (currentValue.annotations) {
          currentValue.annotations = currentValue.annotations.map(a => {
            let newValue = allSegmentElements.find(el => el.id === a.value);

            if (newValue?.type === 'internal_link') {
              newValue.relation.href = clientifyUrl(newValue.relation.href);
            }

            if (newValue?.type === 'external_link') {
              const fields = newValue?.fields.reduce((acc2, current2) => {
                return {
                  ...acc2,
                  [current2.name]: current2.value,
                };
              }, {});

              newValue = {
                ...newValue,
                relation: {
                  ...(newValue.relation ?? {}),
                  href: fields?.anchorName ? `#${fields?.anchorName}` : fields?.uri,
                },
              };
            }

            return {
              ...a,
              value: newValue,
            };
          });
        }
        return {
          ...acc,
          [currentKey]: currentValue,
        };
      }, {}),
    };
  });
};

const getElementsInArticle = (ids, elements, avsnittTextColor, allSegmentElements) => {
  const elementsInArticle = addHtml(
    ids.map(id => {
      const elem = elements.find(element => element.id === id);

      return {
        ...elem,
        children: getElementsInArticle(elem.children ?? [], elements, avsnittTextColor, allSegmentElements),
        fields_ORIGINAL: elem.fields,
        fields: extractFields(elem.fields, avsnittTextColor),
        relation_ORIGINAL: elem.relation,
        relation: extractRelation(elem.relation ?? {}),
        get relationData() {
          return extractRelationData(this);
        },
      };
    })
  );

  addAnnotationValueObject(elementsInArticle, allSegmentElements);
  return elementsInArticle;
};

export default getElementsInArticle;
