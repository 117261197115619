// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WrittenBy-writtenBy-3g8uE{border:1px solid #dee4ea;border-width:1px 0;margin:-1px 0 16px;padding:16px 0}.WrittenBy-writtenBy-3g8uE .WrittenBy-title-3pThH{color:#62717f;display:inline;font-family:LabGrotesque-Bold,sans-serif;font-size:.875rem;margin-right:8px;padding:16px 0}.WrittenBy-writtenBy-3g8uE a{color:#3568a7}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"writtenBy": "WrittenBy-writtenBy-3g8uE",
	"title": "WrittenBy-title-3pThH"
};
module.exports = exports;
