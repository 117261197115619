import React from 'react';
import HeadImage from 'components/molecules/HeadImage';
import pickFrom from 'utils/pickFrom';
import renderStorylineElement from 'utils/renderStorylineElement';

const renderElement = element => {
  try {
    switch (element.type) {
      case 'headImage': {
        const imageWidth =
          element?.relation?.fields?.BASE_LANDSCAPE?.sourceWidth ||
          element?.relation?.fields?.BASE_LANDSCAPE?.width;
        const imageHeight =
          element?.relation?.fields?.BASE_LANDSCAPE?.sourceHeight ||
          element?.relation?.fields?.BASE_LANDSCAPE?.height;

        return (
          <HeadImage
            key={pickFrom(element, 'id')}
            imageSrc={pickFrom(
              element,
              'relation.image.href',
              'relation.profileImage[0].content.fields.BASE_LANDSCAPE.href_full'
            )}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            imageAlt={
              pickFrom(element, 'relation.image.alttext', 'relation.image.description') ||
              pickFrom(
                element,
                'relation.profileImage[0].content.fields.alttext',
                'relation.profileImage[0].content.fields.description'
              )
            }
            caption={pickFrom(element, 'fields.caption.rawText', 'relation.image.description')}
            photographer={pickFrom(
              element,
              'relation.fields.photographer',
              'relation.profileImage[0].content.fields.photographer'
            )}
            twoColumnLayout
          />
        );
      }
      default:
        return renderStorylineElement(element);
    }
  } catch (error) {
    return undefined;
  }
};

export default renderElement;
