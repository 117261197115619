import { createSelector } from 'reselect';

export default createSelector(() => {
  return undefined;
  /*
  return {
    keyFigures: {
      headline: 'Jobba på en av Sveriges bästa arbetsplatser',
      texts: [
        {
          id: 0,
          text: 'I en stor medarbetarundersökning som genomfördes 2017 rankades Svensk Näringsliv som landets åttonde bästa arbetsgivare.',
        },
        {
          id: 1,
          text:
            'På svenskt näringsliv finns chansen att jobba med något du verkligen brinner för. Läs mer om hur det är att jobba hos oss. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.',
        },
      ],
    },
  }; */
});
