// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Puff-puff-TnxbW{display:grid;min-height:182px;padding:1.5rem;position:relative;text-decoration:none;width:100%}@media screen and (min-width:541px){.Puff-puff-TnxbW{min-height:347px;padding:2rem}}.Puff-puff-TnxbW:hover .Puff-caption-1jIfn{text-decoration:underline}.Puff-puff-TnxbW:after{background:transparent -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(60%,rgba(0,0,0,.7)),to(#000)) 0 0 no-repeat padding-box;background:transparent linear-gradient(180deg,transparent,rgba(0,0,0,.7) 60%,#000) 0 0 no-repeat padding-box;bottom:0;content:\"\";height:121px;left:0;opacity:.9;position:absolute;width:100%;z-index:1}@media screen and (min-width:541px){.Puff-puff-TnxbW:after{height:166px}}.Puff-puff-TnxbW.Puff-stretchFade-2-b1d:after{background:transparent -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(75%,rgba(0,0,0,.7)),to(#000)) 0 0 no-repeat padding-box;background:transparent linear-gradient(180deg,transparent,rgba(0,0,0,.7) 75%,#000) 0 0 no-repeat padding-box;height:100%}.Puff-puff-TnxbW .Puff-wrapper-bwWW9{-ms-flex-line-pack:end;-ms-flex-align:end;align-content:flex-end;align-items:flex-end;display:grid;height:100%}.Puff-puff-TnxbW .Puff-wrapper-bwWW9 .Puff-puffImage-1kZxE{height:100%;left:0;-o-object-fit:cover;object-fit:cover;position:absolute;top:0;width:100%;z-index:1}.Puff-puff-TnxbW .Puff-wrapper-bwWW9 .Puff-caption-1jIfn,.Puff-puff-TnxbW .Puff-wrapper-bwWW9 .Puff-title-1gPjV{display:block;margin:0;z-index:2}.Puff-puff-TnxbW .Puff-wrapper-bwWW9 .Puff-title-1gPjV{margin-bottom:.5rem}.Puff-puff-TnxbW .Puff-wrapper-bwWW9 .Puff-caption-1jIfn{text-transform:uppercase}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"puff": "Puff-puff-TnxbW",
	"caption": "Puff-caption-1jIfn",
	"stretchFade": "Puff-stretchFade-2-b1d",
	"wrapper": "Puff-wrapper-bwWW9",
	"puffImage": "Puff-puffImage-1kZxE",
	"title": "Puff-title-1gPjV"
};
module.exports = exports;
