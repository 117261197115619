// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--header-height:100px;--header-height-negative:-100px;--header-padding-y:4px;--header-padding-x:12px}@media (min-width:541px){:root{--header-height:100px;--header-height-negative:-100px;--header-padding-x:12px}}@media (min-width:1169px){:root{--header-height:120px;--header-height-negative:-120px;--header-padding-y:20px;--header-padding-x:68px}}.PageHeader-pageHeader-2pkTr{-ms-flex-negative:0;background-color:#fff;background-origin:border-box;border-bottom:none;-webkit-box-shadow:5px 8px 11px -4px rgba(0,0,0,.1);box-shadow:5px 8px 11px -4px rgba(0,0,0,.1);flex-shrink:0;height:100px;height:var(--header-height);padding:initial;position:relative;-webkit-transition:background-color .3s,border-color .3s;transition:background-color .3s,border-color .3s;z-index:1}@media (min-width:1169px){.PageHeader-pageHeader-2pkTr{border-bottom:none;-webkit-box-shadow:none;-webkit-box-shadow:initial;box-shadow:none;padding:4px 12px;padding:var(--header-padding-y) var(--header-padding-x)}}.PageHeader-pageHeader-2pkTr.PageHeader-darkMode-267e9{background-color:transparent;border-color:transparent}.PageHeader-pageHeader-2pkTr.PageHeader-darkMode-267e9,.PageHeader-pageHeader-2pkTr.PageHeader-fokusPaSkatternaBg-36CqW{background-image:-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.4)),color-stop(60%,transparent));background-image:linear-gradient(180deg,rgba(0,0,0,.4),transparent 60%)}.PageHeader-pageHeader-2pkTr.PageHeader-fokusPaSkatternaBg-36CqW{background-color:#b12f26}.PageHeader-pageHeader-2pkTr.PageHeader-kompetensforsorjningBg-1IE63{background-color:#ea8f12;background-image:-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.4)),color-stop(60%,transparent));background-image:linear-gradient(180deg,rgba(0,0,0,.4),transparent 60%)}.PageHeader-pageHeader-2pkTr .PageHeader-inner-3QnaC{height:100%;margin:0 auto;max-width:1256px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"pageHeader": "PageHeader-pageHeader-2pkTr",
	"darkMode": "PageHeader-darkMode-267e9",
	"fokusPaSkatternaBg": "PageHeader-fokusPaSkatternaBg-36CqW",
	"kompetensforsorjningBg": "PageHeader-kompetensforsorjningBg-1IE63",
	"inner": "PageHeader-inner-3QnaC"
};
module.exports = exports;
