import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import reducer from 'utils/reduceTeaserOptions';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, data => {
  try {
    const documents = pickFrom(data, 'context.documents');
    return documents?.map(image => {
      const teaserOptionsRaw = pickFrom(image, 'teaserOptions');
      let teaserOptions;
      try {
        teaserOptions = reducer(teaserOptionsRaw);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return undefined;
      }
      const content = image?.content;
      return {
        ...content,
        title: teaserOptions?.useSummaryFields ? teaserOptions?.title : pickFrom(image, 'content.title'),
      };
    });
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
