const variants = {
  LIVE: 'LIVE',
  ON_AIR: 'ON_AIR',
  WAS_LIVE: 'WAS_LIVE',
  PASSED: 'PASSED',
  FEW_SPOTS_LEFT: 'FEW_SPOTS_LEFT',
  NEW_DATE: 'NEW_DATE',
  FULLY_BOOKED: 'FULLY_BOOKED',
  CANCELED: 'CANCELED',
};

export default variants;
