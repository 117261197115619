import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import s from './InputField.css';

const InputField = ({
  classes,
  type = 'text',
  gutterBottom,
  round,
  errorMessage,
  register,
  fullWidth,
  ...other
}) => {
  const classNames = classnames(s.inputField, gutterBottom && s.gutterBottom, round && s.round, classes);
  return (
    <div
      className={classnames(s.input, errorMessage && s.error)}
      style={{ width: fullWidth ? '100%' : 'unset' }}
    >
      <input className={classNames} type={type} ref={register} {...other} />
      {errorMessage && (
        <div className={s.errorMessage}>
          <FontAwesomeIcon icon={faInfoCircle} className={s.infoCircle} />
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default withStyles(s)(InputField);
