import { connect } from 'react-redux';
import BecomeMemberPage from 'components/pages/BecomeMemberPage';
import getMembershipPageData from 'selectors/getMembershipPageData';
import getMemberOrganisationsForExpressionOfInterestForm from 'selectors/getMemberOrganisationsForExpressionOfInterestForm';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';

const mapStateToProps = state => {
  return {
    pageData: getMembershipPageData(state),
    memberOrganisations: getMemberOrganisationsForExpressionOfInterestForm(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
  };
};

const mapDispatchToProps = undefined;

const BecomeMemberPageContainer = connect(mapStateToProps, mapDispatchToProps)(BecomeMemberPage);

export default BecomeMemberPageContainer;
