import generateHtml from './generateHtml';

const addHtml = elements => {
  try {
    return elements.map(element => {
      const { fields } = element;
      const { headline, 'lead-text': leadText, paragraph } = fields;
      // might be able to clean up this code, but pick text data to generate html for depending on field type.
      if (headline) {
        return {
          ...element,
          fields: {
            ...fields,
            headline: {
              ...headline,
              html: generateHtml(headline.rawText, headline.annotations),
            },
          },
        };
      }
      if (leadText) {
        return {
          ...element,
          fields: {
            ...fields,
            'lead-text': {
              ...leadText,
              html: generateHtml(leadText.rawText, leadText.annotations),
            },
          },
        };
      }
      if (paragraph) {
        return {
          ...element,
          fields: {
            ...fields,
            paragraph: {
              ...paragraph,
              html: generateHtml(paragraph.rawText, paragraph.annotations),
            },
          },
        };
      }
      if (element.children) {
        return {
          ...element,
          children: addHtml(element.children),
        };
      }
      return element;
    });
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export default addHtml;
