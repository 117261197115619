import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import withStyles from 'isomorphic-style-loader/withStyles';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import s from './PressContactCard.css';

const renderImage = (classes, component, src, srcSet, alt) => {
  return (
    <div className={classes}>
      {component || (src && <ResponsiveImage src={src} srcSet={srcSet} alt={alt} sizes="90px" />)}
      {!component && !src && <FontAwesomeIcon icon={faUser} style={{ width: 27, height: 38 }} />}
    </div>
  );
};

const PressContactCard = ({
  classes,
  imageWrapperClasses,
  imageSrc,
  imageAlt,
  imageSrcSet,
  imageComponent,
  name,
  title,
  subtitle,
  ...other
}) => {
  const classNames = classnames(s.pressContactCard, classes);
  const imageWrapperClassNames = classnames(
    s.imageWrapper,
    imageWrapperClasses,
    !imageComponent && !imageSrc && s.noUser
  );
  return (
    <div className={classNames} {...other}>
      {renderImage(imageWrapperClassNames, imageComponent, imageSrc, imageSrcSet, imageAlt)}
      <div>
        {name && <div className={s.name}>{name}</div>}
        {title && <div className={s.title}>{title}</div>}
        {subtitle && <div className={s.subtitle}>{subtitle}</div>}
      </div>
    </div>
  );
};

export default withStyles(s)(PressContactCard);
