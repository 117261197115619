import React from 'react';
import { createSelector } from 'reselect';
import Link from 'containers/atoms/LinkContainer';
import Typography from 'components/atoms/Typography';

export default createSelector(() => {
  return [
    {
      id: 0,
      question:
        'Vi är ett medlemsföretag och ska rapportera in löneuppgifter till lönestatistik. Hur loggar jag in?',
      answer: (
        <Typography>
          Inrapportering av löneuppgifter till årets lönestatistik görs på webbplatsen{' '}
          <Typography
            href="https://lonestatistik.svensktnaringsliv.se"
            target="_blank"
            component="a"
            variant="linkSmall"
          >
            https://lonestatistik.svensktnaringsliv.se
          </Typography>
          . Inloggningsuppgifter har skickats till ert företag i september.
          <br /> <br />
          Frågor kring detta mejlas till{' '}
          <Typography
            href="mailto:support.lonestatistik@svensktnaringsliv.se"
            component="a"
            variant="linkSmall"
          >
            support.lonestatistik@svensktnaringsliv.se
          </Typography>{' '}
          eller tas via telefon till{' '}
          <Typography href="tel:0858002220" component="a" variant="linkSmall">
            08-580 022 20
          </Typography>
          .
        </Typography>
      ),
    },
    {
      id: 1,
      question:
        'Vi är ett medlemsföretag och ska rapportera in löneuppgifter till lönestatistiken. Var hittar jag samtliga yrkeskoder/nyk-koder?',
      answer: (
        <Typography>
          Svenskt Näringsliv har tillsammans med sina medlemsorganisationer tagit fram ett stödsystem för att
          underlätta att hitta rätt yrkeskoder. Du hittar verktyget på{' '}
          <Typography
            href="http://nyk.svensktnaringsliv.se/"
            target="_blank"
            component="a"
            variant="linkSmall"
          >
            http://nyk.svensktnaringsliv.se/
          </Typography>
          .
        </Typography>
      ),
    },
    {
      id: 2,
      question: 'Vi är ett medlemsföretag och ska rapportera in medlemsuppgifter. Hur gör jag?',
      answer: (
        <Typography>
          För att ert medlemsföretag ska betala rätt medlemsuppgift till Svenskt Näringsliv och den eller de
          medlemsorganisationer företaget är medlem i ska företaget varje år rapportera in uppgifter om
          företaget. Detta kallas för medlemsuppgiften.
          <br /> <br />
          Gå till{' '}
          <Typography href="https://www.medlemsuppgift.se" target="_blank" component="a" variant="linkSmall">
            https://www.medlemsuppgift.se
          </Typography>{' '}
          för att raportera in dessa uppgifter.
          <br /> <br />
          Frågor kring medlemsuppgiften kan mejlas till{' '}
          <Typography
            href="mailto:support.medlemsuppgift@svensktnaringsliv.se"
            component="a"
            variant="linkSmall"
          >
            support.medlemsuppgift@svensktnaringsliv.se
          </Typography>{' '}
          eller tas via telefon till{' '}
          <Typography href="tel:0858002220" component="a" variant="linkSmall">
            08-580 022 20
          </Typography>
          .
        </Typography>
      ),
    },
    {
      id: 3,
      question: 'Jag vill beställa skolmaterial, vart hittar jag det?',
      answer: (
        <Typography>
          Allt skolmaterial från Svenskt Näringsliv beställs eller laddas ner helt kostnadsfritt från
          webbplatsen{' '}
          <Typography href="https://eduna.se" target="_blank" component="a" variant="linkSmall">
            https://eduna.se
          </Typography>
          .
        </Typography>
      ),
    },
    {
      id: 4,
      question: 'Hur skickar jag en remiss till Svenskt Näringsliv?',
      answer: (
        <Typography>
          Myndigheter och statliga verk som vill skicka remisser till Svenskt Näringsliv gör det genom att
          skicka materialet till vår registrator för remisser på{' '}
          <Typography href="mailto:remisser@svensktnaringsliv.se" component="a" variant="linkSmall">
            remisser@svensktnaringsliv.se
          </Typography>
          .
        </Typography>
      ),
    },
    {
      id: 5,
      question: 'Vi är ett medlemsföretag och vill säga upp vårt medlemskap. Hur går vi tillväga?',
      answer: (
        <Typography>
          Som medlemsföretag är du alltid medlem i en eller flera medlemsorganisationer. För att avsluta ert
          medlemskap kontaktar du berörd medlemsorganisation.{' '}
          <b>Du hittar kontaktuppgifter på din medlemsorganisations hemsida eller på din senaste faktura.</b>
          <br /> <br />
          Om du inte vet vilken organisation du är medlem i kontaktar du{' '}
          <Typography href="mailto:medlemskapsfraga@svensktnaringsliv.se" component="a" variant="linkSmall">
            medlemskapsfraga@svensktnaringsliv.se
          </Typography>
          .
        </Typography>
      ),
    },
    {
      id: 6,
      question: 'Vi är ett medlemsföretag och har frågor kring fakturan. Vart ska jag vända mig?',
      answer: (
        <Typography>
          Medlemsavgiften för ert företag består av en avgift till den eller de medlemsorganisationer ni är
          medlem i samt en avgift till Svenskt Näringsliv.{' '}
          <b>
            Kontakta i första hand den medlemsorganisation som ni är medlem i. Kontaktuppgifter finns på
            fakturan.
          </b>
          <br /> <br />
          Om frågan rör avgiften till Svenskt Näringsliv kontaktar du{' '}
          <Typography href="mailto:faktura@svensktnaringsliv.se" component="a" variant="linkSmall">
            faktura@svensktnaringsliv.se
          </Typography>
          .
        </Typography>
      ),
    },
    {
      id: 7,
      question:
        'Vi är ej medlemmar idag och är intresserade av att bli medlemsföretag. Hur går vi till väga för att skicka in en intresseanmälan?',
      answer: (
        <Typography>
          Ett medlemskap är alltid i både Svenskt Näringsliv och en eller flera medlemsorganisationer. Om du
          fyller i{' '}
          <Typography to="/medlem/intresseanmalan/" variant="linkSmall" component={Link}>
            formuläret på denna sida
          </Typography>{' '}
          blir du kontaktad av en medarbetare på en medlemsorganisation som kan berätta mer om medlemskapet.
        </Typography>
      ),
    },
  ];
});
