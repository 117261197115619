import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      const contactOptions = data?.context?.deskedContent?.toolbox?.[0]?.content?.fields?.contactOptions;
      const membershipContactOptionKey =
        data?.context?.deskedContent?.toolbox?.[0]?.content?.fields?.membershipContactOptionKey;
      const memberOrganisations = data?.memberOrganisations;
      const toolboxId = data?.context?.deskedContent?.toolbox?.[0]?.content?.id;
      return {
        contactOptions,
        membershipContactOptionKey,
        memberOrganisations,
        toolboxId,
      };
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
