// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BoardMembers-searchBar-16ldQ{margin:1rem 0 2.5rem}.BoardMembers-coworkersSections-1S_OT .BoardMembers-section-SIbnC{display:block}.BoardMembers-coworkersSections-1S_OT .BoardMembers-section-SIbnC.BoardMembers-empty-2SbUm{display:none}.BoardMembers-coworkersSections-1S_OT .BoardMembers-section-SIbnC .BoardMembers-coworkers-cHCUf{border-top:1px solid #dee4ea;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-top:.5rem;padding:1.5rem 0}.BoardMembers-coworkersSections-1S_OT .BoardMembers-section-SIbnC .BoardMembers-coworkers-cHCUf .BoardMembers-coworker-1RClL{margin-bottom:1.5rem;margin-right:1.5rem}@media screen and (min-width:541px){.BoardMembers-coworkersSections-1S_OT .BoardMembers-section-SIbnC .BoardMembers-coworkers-cHCUf .BoardMembers-coworker-1RClL{-ms-flex:1 1 256px;flex:1 1 256px;max-width:50%}}.BoardMembers-coworkersSections-1S_OT .BoardMembers-section-SIbnC .BoardMembers-coworkers-cHCUf .BoardMembers-coworker-1RClL .BoardMembers-coworkerCard-3fSLk .BoardMembers-coworkerCardImageWrapper-1G_Yn{height:4rem;width:4rem}.BoardMembers-function-hj_dQ{font-weight:700}.BoardMembers-company-2lqUp,.BoardMembers-function-hj_dQ{color:#33414e}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"searchBar": "BoardMembers-searchBar-16ldQ",
	"coworkersSections": "BoardMembers-coworkersSections-1S_OT",
	"section": "BoardMembers-section-SIbnC",
	"empty": "BoardMembers-empty-2SbUm",
	"coworkers": "BoardMembers-coworkers-cHCUf",
	"coworker": "BoardMembers-coworker-1RClL",
	"coworkerCard": "BoardMembers-coworkerCard-3fSLk",
	"coworkerCardImageWrapper": "BoardMembers-coworkerCardImageWrapper-1G_Yn",
	"function": "BoardMembers-function-hj_dQ",
	"company": "BoardMembers-company-2lqUp"
};
module.exports = exports;
