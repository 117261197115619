/* eslint-disable css-modules/no-undef-class */
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import { AnimatePresence, motion } from 'framer-motion';
import getImages from 'utils/getImages';
import renderStorylineElement from 'utils/renderStorylineElement';
import Link from 'containers/atoms/LinkContainer';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import LatestArticle from 'components/molecules/LatestArticle';
import DarkMode from 'components/templates/DarkMode';
import VCard from 'components/molecules/VCard';
import InDepthItem from 'components/molecules/InDepthItem';
import HighlightedArticle from 'components/molecules/HighlightedArticle';
import InDepthList from 'components/molecules/InDepthList';
import QuoteBox from 'components/molecules/QuoteBox/QuoteBox';
import FocusAreaList from 'components/organisms/FocusAreaList';
import boostrapGrid from 'style/bootstrap-grid.css';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import getShowLatestArticles from 'selectors/getShowLatestArticles';
import generateSizes from 'utils/generateSizes';
import pickFrom from 'utils/pickFrom';
import { useSelector } from 'react-redux';
import s from './FocusAreaPage.css';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';
import EventsWebinars from '../../organisms/EventsWebinars/EventsWebinars';
import { getMetadata } from '../FlexibleSection/duck/selectors';
import clientifyUrl from '../../../utils/clientifyUrl';

const renderArticle = (article, small = false) => {
  const imageHref = pickFrom(article, 'images.images.BASE.LANDSCAPE.href_full');
  const imageWidth =
    article?.images?.images?.BASE?.LANDSCAPE?.sourceWidth || article?.images?.images?.BASE?.LANDSCAPE?.width;
  const imageHeight =
    article?.images?.images?.BASE?.LANDSCAPE?.sourceHeight ||
    article?.images?.images?.BASE?.LANDSCAPE?.height;

  const sizes = getImages('LANDSCAPE');
  const imageSizes = small
    ? generateSizes({ xl: '412px', md: '31vw', sm: 'calc(50vw - 68px)', default: '100vw' })
    : generateSizes({ xl: '848px', md: '68vw', sm: 'calc(100vw - 112px)', default: '100vw' });
  const eventType = article?.fields?.eventType === undefined ? '' : article?.fields?.eventType;
  const event = article.contentType === 'event' ? eventType : article.contentType;
  return (
    <HighlightedArticle
      key={article.href}
      href={article.href}
      imageSrc={imageHref && (small ? sizes.toSrc(imageHref, 'M') : sizes.toSrc(imageHref, 'L'))}
      small={small}
      imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
      imageSizes={imageSizes}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      contentType={event}
      headline={article?.fields?.teaserTitle || article.title}
      text={pickFrom(article, 'fields.teaser')}
    />
  );
};

const FocusAreaPage = ({
  classes,
  groupedStorylineTree,
  quoteBoxData,
  inDepthItems,
  contactPersons,
  upcomingEvents,
  focusAreaListItems,
  firstSelectedContent,
  context,
  restSelectedContent,
  latestWebinars = [],
  latestArticles = [],
  pageid,
  resolutionSection,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [latestToShow, setLatestToShow] = useState(10);
  const [showAllIssues, setshowAllIssues] = useState(
    context?.issues?.currentIssues?.length === 0 && context?.deskedContent?.allIssues?.length > 0
  );
  const classNames = classnames(s.focusAreaPage, classes, s.darkMode);
  const { headGroup, contentGroup } = groupedStorylineTree;
  const allIssuesSize = context?.deskedContent?.allIssues.length;
  const issuesSize = context?.issues?.currentIssues.length;
  useEffect(() => {
    setshowAllIssues(
      context?.issues?.currentIssues?.length === 0 && context?.deskedContent?.allIssues?.length > 0
    );
  }, [pageid]);

  const metadata = useSelector(getMetadata);
  const showLatestArticles = useSelector(getShowLatestArticles);

  return (
    <div className={classNames}>
      <OGShareTags metadata={metadata} />
      <DarkMode
        darkMode
        classes={classnames(
          s.topWrapper,
          quoteBoxData?.quote && s.gutterBottom,
          quoteBoxData?.quote && s.minHeight
        )}
      >
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(boostrapGrid.row, s.imageRelative)}>
            <div className={getBootstrapColumns('16,4', '22,1', '20,2')}>
              <div>{headGroup && headGroup.map(el => renderStorylineElement(el, true))}</div>
            </div>
            {contentGroup && contentGroup.length > 0 && (
              <div className={getBootstrapColumns('16,4', '14,1', '13,2')}>
                <div>
                  <AnimatePresence>
                    {expanded && contentGroup && (
                      <motion.div
                        style={{ overflow: 'hidden' }}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                      >
                        {contentGroup.map(el => renderStorylineElement(el, true))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <Typography
                    variant="link"
                    component="button"
                    classes={s.expandButton}
                    darkMode
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? '- Fäll ihop' : '+ Läs mer'}
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <div className={classnames(boostrapGrid.row)}>
            <div className={getBootstrapColumns('16,4', '8,1', '6,2')}>
              {(context?.issues?.currentIssues?.length > 0 ||
                context?.deskedContent?.allIssues?.length > 0) && (
                <>
                  <Typography variant="caption" component="h3" gutterBottom classes={s.caption} darkMode>
                    {showAllIssues ? 'Alla frågor' : 'Aktuella frågor'}
                  </Typography>
                  <InDepthList>
                    {showAllIssues
                      ? context?.deskedContent?.allIssues.map(issue => (
                          <InDepthItem
                            key={issue.content.title}
                            href={clientifyUrl(issue.content.href)}
                            darkMode
                          >
                            {issue.content.title}
                          </InDepthItem>
                        ))
                      : context?.issues?.currentIssues.map(issue => (
                          <InDepthItem key={issue.name} href={clientifyUrl(issue.href)} darkMode>
                            {issue.name}
                          </InDepthItem>
                        ))}
                  </InDepthList>
                  {!showAllIssues && issuesSize < allIssuesSize && (
                    <Typography
                      variant="link"
                      component="button"
                      classes={s.expandButton}
                      darkMode
                      onClick={() => setshowAllIssues(true)}
                    >
                      + Visa alla frågor
                    </Typography>
                  )}
                </>
              )}
            </div>
            {quoteBoxData?.quote && (
              <div
                className={classnames(
                  getBootstrapColumns('20,4', '14,1', '14,2'),
                  !(inDepthItems && inDepthItems?.length > 0) && s.quoteBoxColumn
                )}
              >
                <QuoteBox
                  key={pickFrom(quoteBoxData, 'images.images.BASE.LANDSCAPE.href_full')}
                  imageSrc={pickFrom(quoteBoxData, 'images.images.BASE.LANDSCAPE.href_full')}
                  imageAlt={pickFrom(quoteBoxData, 'images.altText', 'name')}
                  quote={quoteBoxData.quote}
                  name={quoteBoxData.linkText}
                  assignment={quoteBoxData.assignment}
                  profileLink={quoteBoxData.profileLink}
                  isAbsolute
                />
              </div>
            )}
          </div>
        </div>
      </DarkMode>
      <div
        className={classnames(boostrapGrid.container, s.selectedContent, {
          [s.gutterTop]: !!quoteBoxData?.quote,
        })}
      >
        <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
          <div className={getBootstrapColumns('20,2', '18,3', '16,4')}>
            <div className={s.articlesWrapper}>
              {firstSelectedContent && renderArticle(firstSelectedContent)}
              <div className={classnames(boostrapGrid.row)}>
                {restSelectedContent &&
                  restSelectedContent.map(article => (
                    <div key={article.id} className={classnames(boostrapGrid['col-sm-12'])}>
                      {renderArticle(article, true)}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classnames(boostrapGrid.container, s.fosusAreaSearchLink)}>
        <div className={classnames(boostrapGrid.row)}>
          <div className={getBootstrapColumns('22,1', '14,5', '12,6')}>
            <ButtonSecondaryGhost
              classes={s.showMoreEvents}
              component={Link}
              to={`/sokresultat/?focusarea=${resolutionSection?.resolutionSectionId}`}
            >
              Sök bland allt material från detta sakområde
            </ButtonSecondaryGhost>
          </div>
        </div>
      </div>
      <EventsWebinars
        selectedEvents={upcomingEvents}
        latestWebinars={latestWebinars}
        darkBackground={contactPersons?.length > 0}
      />

      {contactPersons?.length > 0 && (
        <div className={s.contactWrapper}>
          <div className={s.contact}>
            <div className={classnames(boostrapGrid.container)}>
              <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
                <div
                  className={classnames(
                    boostrapGrid['col-lg-16'],
                    boostrapGrid['offset-lg-4'],
                    boostrapGrid['col-md-18'],
                    boostrapGrid['offset-md-3']
                  )}
                >
                  <h2 className={s.smallHeadline}>Kontaktpersoner</h2>
                  <div>
                    <div className={classnames(boostrapGrid.row)}>
                      {contactPersons &&
                        contactPersons.map(person => {
                          const imageHref = pickFrom(person, 'images.images.BASE.LANDSCAPE.href_full');
                          const sizes = getImages('SQUARE');
                          return (
                            <div key={person.id} className={classnames(boostrapGrid['col-md-12'])}>
                              <VCard
                                classes={s.contactCard}
                                imageSrc={imageHref && sizes.toSrc(imageHref, 'XS')}
                                imageAlt={pickFrom(person, 'images.altText', 'name')}
                                imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
                                name={person.name}
                                title={person.title}
                                href={person.href}
                                linkText="Se kontaktuppgifter"
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showLatestArticles && latestArticles && Array.isArray(latestArticles) && latestArticles.length > 0 && (
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
            <div
              className={classnames(
                boostrapGrid['col-lg-16'],
                boostrapGrid['offset-lg-4'],
                boostrapGrid['col-md-18'],
                boostrapGrid['offset-md-3'],
                s.latestArticleWrapper
              )}
            >
              <h2 className={s.articleSmallHeadline}>Senaste artiklarna</h2>
              {latestArticles
                .slice(0, latestToShow)
                .map(({ id, contentType, title, summery, date, href, images }) => {
                  const imageHref = pickFrom(images, 'images.BASE.LANDSCAPE.href_full');
                  const imageWidth =
                    images?.images?.BASE?.LANDSCAPE?.sourceWidth || images?.images?.BASE?.LANDSCAPE?.width;
                  const imageHeight =
                    images?.images?.BASE?.LANDSCAPE?.sourceHeight || images?.images?.BASE?.LANDSCAPE?.height;

                  const sizes = getImages('LANDSCAPE');
                  const sizesMobile = getImages('SQUARE');
                  return (
                    <LatestArticle
                      key={id}
                      href={href}
                      articleType={contentType}
                      headline={title}
                      day={date}
                      text={summery}
                      imageSrc={imageHref && sizes.toSrc(imageHref, 'M')}
                      imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
                      imageSizes="190px"
                      imageMobileSrc={imageHref && sizes.toSrc(imageHref, 'XS')}
                      imageMobileSrcSet={imageHref && sizesMobile.toSrcSet(imageHref)}
                      imageMobileSizes="90px"
                      imageAlt={images?.altText}
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                    />
                  );
                })}
              {latestArticles.length > latestToShow && (
                <ButtonSecondaryGhost
                  key={`show-more-${latestToShow}`}
                  classes={s.showMoreEvents}
                  onClick={() => setLatestToShow(latestToShow + 10)}
                >
                  Visa fler
                </ButtonSecondaryGhost>
              )}
            </div>
          </div>
        </div>
      )}

      {focusAreaListItems?.length > 0 && (
        <div className={s.focusAreaListContainer}>
          <div className={classnames(boostrapGrid.container)}>
            <FocusAreaList listItems={focusAreaListItems} />
          </div>
        </div>
      )}
    </div>
  );
};

export default withStyles(boostrapGrid, s)(FocusAreaPage);
