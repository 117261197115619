import * as types from './types';

const set = (pathname, scrollPosition = 0) => ({
  type: types.SET,
  payload: {
    pathname,
    scrollPosition,
  },
});

const setHostname = hostname => ({
  type: types.SET_HOSTNAME,
  payload: {
    hostname,
  },
});

const setDataPathPrefix = dataPathPrefix => ({
  type: types.SET_DATA_PATH_PREFIX,
  payload: {
    dataPathPrefix,
  },
});

export { set, setHostname, setDataPathPrefix };
