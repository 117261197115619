import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from '../../atoms/Typography';
import s from './KeyValue.css';

const KeyValue = ({ label, value, ...restProps }) => {
  return (
    <div className={s.keyValue} {...restProps}>
      <Typography className={s.label} variant="caption">
        {label}
      </Typography>
      <span
        suppressHydrationWarning
        className={classnames(s.value, {
          [s.hidden]: !value,
        })}
      >
        {value || '-'}
      </span>
    </div>
  );
};

export default withStyles(s)(KeyValue);
