// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Tabs-container-1USkA{width:100%}.Tabs-container-1USkA,.Tabs-tab-1UNW1{display:-ms-flexbox;display:flex}.Tabs-tab-1UNW1{-ms-flex-align:center;-ms-flex-pack:center;align-items:center;border:1px solid #cfd4d8;color:inherit;justify-content:center;padding:.4rem;text-align:center;text-decoration:none;width:50%}.Tabs-tab-1UNW1.Tabs-selected-SAeR3{background:#33414e;border-color:#33414e;color:#fff}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"container": "Tabs-container-1USkA",
	"tab": "Tabs-tab-1UNW1",
	"selected": "Tabs-selected-SAeR3"
};
module.exports = exports;
