import React from 'react';

const ErtValfardsskapande = () => {
  return (
    <div>
      <h1>I am ErtValfardsskapande</h1>
    </div>
  );
};

export default ErtValfardsskapande;
