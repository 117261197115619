import { createSelector } from 'reselect';
import _isArray from 'lodash/isArray';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';
import extractImages from 'utils/extractImages';
import clientifyUrl from '../utils/clientifyUrl';

export default createSelector(cookSelectors.getData, data => {
  try {
    const latestArticles = pickFrom(data, 'latestArticles', 'latest') || pickFrom(data, 'writtenBy');
    if (!_isArray(latestArticles)) return undefined;

    return latestArticles.map(rel => {
      const { teaserImage, href, published } = rel;
      const teaser = pickFrom(rel, 'fields.teaser');
      const primaryLink = clientifyUrl(href) || undefined;
      return {
        ...rel,
        images: (teaserImage && teaserImage.length > 0 && extractImages(teaserImage)) || undefined,
        date: published,
        href: primaryLink,
        summery: teaser,
      };
    });
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
