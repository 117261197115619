import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import bootstrapGrid from 'style/bootstrap-grid.css';

const BootstrapWrapper = ({ children, className }) => {
  return (
    <div
      className={classnames(
        bootstrapGrid['col-lg-16'],
        bootstrapGrid['offset-lg-4'],
        bootstrapGrid['col-md-18'],
        bootstrapGrid['offset-md-3'],
        {
          [className]: !!className,
        }
      )}
    >
      {children}
    </div>
  );
};

export default withStyles(bootstrapGrid)(BootstrapWrapper);
