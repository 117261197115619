const extractRelationData = element => {
  if (element.type === 'relation') {
    return {
      headline: element.fields?.title?.rawText,
      summary: element.fields?.summary?.rawText,
      href: element.relation?.href,
      coverImage: element.relation.coverImage || element.relation.teaserImage,
    };
  }

  if (element.type === 'internal_link') {
    return {
      ...(element.relation ?? {}),
      href: element.relation?.href,
    };
  }

  return {};
};

export default extractRelationData;
