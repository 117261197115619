// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LiveEventBadge-liveEventBadge-3XzNr{-ms-flex-align:center;align-items:center;display:-ms-inline-flexbox;display:inline-flex;font-family:LabGrotesque-Medium,sans-serif;font-size:.75rem;padding:8px 10px}.LiveEventBadge-liveEventBadge-3XzNr svg{height:6px;margin-right:4px}.LiveEventBadge-liveEventBadge-3XzNr span{margin-bottom:-2px}.LiveEventBadge-liveEventBadge-3XzNr.LiveEventBadge-past-3GLyK{background-color:#f9e2c2;color:#33414e}.LiveEventBadge-liveEventBadge-3XzNr.LiveEventBadge-past-3GLyK svg{color:#ea8f12;height:16px;width:16px}.LiveEventBadge-liveEventBadge-3XzNr.LiveEventBadge-live-3qxlA{background-color:#d05234;color:#fff}.LiveEventBadge-liveEventBadge-3XzNr.LiveEventBadge-live-3qxlA svg{-webkit-animation:LiveEventBadge-live-3qxlA 1s ease-in-out infinite alternate;animation:LiveEventBadge-live-3qxlA 1s ease-in-out infinite alternate}.LiveEventBadge-liveEventBadge-3XzNr.LiveEventBadge-future-2fKtH{background-color:#f9e2c2;color:#b12f26}@-webkit-keyframes LiveEventBadge-live-3qxlA{0%{opacity:.2;-webkit-transform:scale(1);transform:scale(1)}to{opacity:1;-webkit-transform:scale(1.2);transform:scale(1.2)}}@keyframes LiveEventBadge-live-3qxlA{0%{opacity:.2;-webkit-transform:scale(1);transform:scale(1)}to{opacity:1;-webkit-transform:scale(1.2);transform:scale(1.2)}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"liveEventBadge": "LiveEventBadge-liveEventBadge-3XzNr",
	"past": "LiveEventBadge-past-3GLyK",
	"live": "LiveEventBadge-live-3qxlA",
	"future": "LiveEventBadge-future-2fKtH"
};
module.exports = exports;
