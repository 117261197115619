import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, data => {
  try {
    const label = pickFrom(data, 'resolution.parameters.sven_expertOpinions_label');
    return label;
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
