/* eslint-disable no-console */

const base64 = {
  encode: str => {
    try {
      return Buffer.from(str).toString('base64');
    } catch {
      return '';
    }
  },
  decode: b64Encoded => {
    try {
      return Buffer.from(b64Encoded, 'base64').toString();
    } catch {
      return '';
    }
  },
};

export default base64;
