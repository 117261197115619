/* eslint-disable react/no-array-index-key */
/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import boostrapGrid from 'style/bootstrap-grid.css';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import FocusAreaListSection from './FocusAreaListSection/FocusAreaListSection';
import s from './FocusAreaList.css';

const FocusAreaList = ({ classes, listItems, big = false, sm = '22,1', md = '20,2', lg = '16,4' }) => {
  const classNames = classnames(s.focusAreaList, classes);

  return (
    <div className={classNames}>
      <div className={boostrapGrid.row}>
        <div className={getBootstrapColumns(sm, md, lg)}>
          <div className={classnames(boostrapGrid.row)}>
            {listItems &&
              listItems.map((item, index) => {
                return (
                  item.focusAreas.length > 0 && (
                    <FocusAreaListSection key={item.focusAreaClusterTitle + index} section={item} big={big} />
                  )
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(boostrapGrid, s)(FocusAreaList);
