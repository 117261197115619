import React, { useState, useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import getImages from 'utils/getImages';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import CountDownBalk from 'components/molecules/CountDownBalk';
import s from './HeroSectionBackground.css';

const IMAGE_SIZE_MAP = {
  xs: 'M',
  sm: 'M',
  md: 'L',
  lg: 'XL',
  xl: 'XL',
};

const COLOR_MAP = {
  darkBlue: '#33414e',
  steel: '#62717f',
  creamyWhite: '#faf9f7',
  lightBlue: '#eff2f5',
  white: '#fff',
  DEFAULT: '#33414e',
};

const HeroSectionBackground = ({
  children,
  beambackgroundColor = '',
  src = '',
  srcTyp = '',
  backgroundColor = '',
  heroBeamCountDownDate = '',
  textColor = '',
  isShowHeroBeam,
  isShowHeroBeamCountDown,
  heroBeamText = '',
  heroBeamTextAfterCountDown = '',
}) => {
  const breakpoint = useBreakpoint();
  const imageSize = IMAGE_SIZE_MAP[breakpoint];

  // We need to store the bg image in state because SSR. Initially we want to render the same thing on
  // the server and the client and when the useEffect runs we swap out the image.
  const [bgImage, setBgImage] = useState(src);

  useEffect(() => {
    const href = src ? getImages('FREEFORM').toSrc(src, imageSize) : '/testbg.jpg';
    setBgImage(href);
  }, [breakpoint, src, srcTyp]);

  const bgStyle =
    srcTyp === 'picture'
      ? {
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }
      : {
          backgroundColor: COLOR_MAP[backgroundColor] ?? COLOR_MAP.DEFAULT,
        };

  return (
    <div className={s.mainHeroBackground}>
      <div>
        <div className={s.heroBackground} style={bgStyle}>
          {srcTyp === 'video' && (
            <video key={src} playsInline autoPlay muted loop>
              <source src={src} type="video/mp4" className={s.video} />
            </video>
          )}
          {children}
        </div>
        {isShowHeroBeam === true && (
          <CountDownBalk
            backgroundColor={beambackgroundColor}
            beamCountDownDate={heroBeamCountDownDate}
            textColor={textColor}
            isShowBeam={isShowHeroBeam}
            isShowBeamCountDown={isShowHeroBeamCountDown}
            beamText={heroBeamText}
            beamTextAfterCountDown={heroBeamTextAfterCountDown}
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(s)(HeroSectionBackground);
