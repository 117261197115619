import React, { useState } from 'react';
/* eslint-disable css-modules/no-undef-class */
import withStyles from 'isomorphic-style-loader/withStyles';
import ScrollTrigger from 'react-scroll-trigger';
import classnames from 'classnames';
import ContentTypeDate from 'components/atoms/ContentTypeDate';
import Author from 'components/molecules/Author';
import ShareBarContainer from 'containers/molecules/ShareBarContainer';
import AlsoRead from 'components/organisms/AlsoRead';
import RelatedContent from 'components/organisms/RelatedContent';
import VideoBlock from 'components/atoms/VideoBlock/VideoBlock';
import CenteredRow from 'components/templates/Grid/CenteredRow';
import CenteredSecondaryRow from 'components/templates/Grid/CenteredSecondaryRow';
// eslint-disable-next-line css-modules/no-unused-class
import boostrapGrid from 'style/bootstrap-grid.css';
import Tag from 'components/atoms/Tag';

import s from './TvPage.css';
import Typography from '../../atoms/Typography/Typography';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';

const TvPage = ({
  publishedDate,
  relatedItems,
  authors,
  metadata,
  pageVideo,
  pageLabel,
  pageTitle,
  tags,
}) => {
  const relItems = relatedItems?.filter(item => item.type !== 'tv');
  const [visible, setVisible] = useState(true);

  return (
    <>
      <OGShareTags metadata={metadata} type="article" />
      <div className={classnames(boostrapGrid.container)}>
        <div className={s.article_container}>
          <CenteredRow>
            <ScrollTrigger onEnter={() => setVisible(true)} onExit={() => setVisible(false)}>
              {pageLabel && <ContentTypeDate contentType={pageLabel} date={publishedDate} />}
              {pageTitle && (
                <Typography variant="h1Big" gutterTop gutterBottom>
                  {pageTitle}
                </Typography>
              )}
              {pageVideo && (
                <>
                  <VideoBlock
                    key={pageVideo.id}
                    pageVideo={{
                      streamingProvider: pageVideo.streamingProvider,
                      id: pageVideo.id,
                    }}
                    stretched
                    inHeadGroup
                    classes={s.videoBlock}
                  />
                  {pageVideo.caption && (
                    <Typography className={s.videoCaption} gutterBottom>
                      {pageVideo.caption}
                    </Typography>
                  )}
                </>
              )}
              <div className={classnames(visible === true ? s.fadeIn : s.fadeOut)}>
                <ShareBarContainer />
              </div>
              <div className={s.stickyContainer}>
                <ShareBarContainer hideMobile />
                {tags?.map(tag => {
                  const href = `/${tag?.tagUri}`;
                  return <Tag href={href}>{tag?.name}</Tag>;
                })}

                <Author authors={authors} />
                {relItems?.length > 0 && <RelatedContent items={relItems} />}
              </div>
            </ScrollTrigger>
          </CenteredRow>
        </div>
      </div>
      <div className={classnames(s.alsoReadWrapper, s.secondaryWrapper)}>
        <div className={classnames(boostrapGrid.container)}>
          <CenteredSecondaryRow>
            <AlsoRead />
          </CenteredSecondaryRow>
        </div>
      </div>
    </>
  );
};

export default withStyles(boostrapGrid, s)(TvPage);
