// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BusinessPanelFormPage-businessPanelFormPage-1eeAm{margin-bottom:80px}.BusinessPanelFormPage-businessPanelFormPage-1eeAm .BusinessPanelFormPage-leftMenu-1ILcg{display:none}@media screen and (min-width:1169px){.BusinessPanelFormPage-businessPanelFormPage-1eeAm .BusinessPanelFormPage-leftMenu-1ILcg{display:none;display:block;margin-left:-.75rem;margin-right:-.75rem;padding-bottom:5.25rem;position:sticky;top:12rem}.BusinessPanelFormPage-businessPanelFormPage-1eeAm .BusinessPanelFormPage-leftMenu-1ILcg .BusinessPanelFormPage-text-OHzJC{text-transform:uppercase;word-break:break-word}.BusinessPanelFormPage-businessPanelFormPage-1eeAm .BusinessPanelFormPage-leftMenu-1ILcg .BusinessPanelFormPage-leftMenuIndepthItem-2fUIG{margin-bottom:0;margin-top:.25rem}.BusinessPanelFormPage-businessPanelFormPage-1eeAm .BusinessPanelFormPage-leftMenu-1ILcg .BusinessPanelFormPage-leftMenuIndepthItem-2fUIG:first-child{margin-top:.5rem}.BusinessPanelFormPage-businessPanelFormPage-1eeAm .BusinessPanelFormPage-leftMenu-1ILcg .BusinessPanelFormPage-leftMenuItem-2OyHg{font-size:.875rem;font-weight:700}.BusinessPanelFormPage-businessPanelFormPage-1eeAm .BusinessPanelFormPage-leftMenu-1ILcg .BusinessPanelFormPage-leftMenuItem-2OyHg.BusinessPanelFormPage-selected-1R3h0{color:#ea8f12}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"businessPanelFormPage": "BusinessPanelFormPage-businessPanelFormPage-1eeAm",
	"leftMenu": "BusinessPanelFormPage-leftMenu-1ILcg",
	"text": "BusinessPanelFormPage-text-OHzJC",
	"leftMenuIndepthItem": "BusinessPanelFormPage-leftMenuIndepthItem-2fUIG",
	"leftMenuItem": "BusinessPanelFormPage-leftMenuItem-2OyHg",
	"selected": "BusinessPanelFormPage-selected-1R3h0"
};
module.exports = exports;
