// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonSecondaryGhost-buttonSecondaryGhost-DuAoV{background-color:inherit;border:1px solid #cfd4d8;border-radius:2px;color:#62717f;cursor:pointer;display:block;font-family:LabGrotesque-Bold,sans-serif;padding:15px 31px;text-align:center;text-decoration:none;-webkit-transition:color .2s ease-in-out,background-color .2s ease-in-out,border .2s ease-in-out;transition:color .2s ease-in-out,background-color .2s ease-in-out,border .2s ease-in-out}.ButtonSecondaryGhost-buttonSecondaryGhost-DuAoV.ButtonSecondaryGhost-inverted-3ogUg{border-color:#fff;color:#fff}.ButtonSecondaryGhost-buttonSecondaryGhost-DuAoV:active,.ButtonSecondaryGhost-buttonSecondaryGhost-DuAoV:hover{background:#53606c;border:1px solid #53606c;color:#fff}.ButtonSecondaryGhost-buttonSecondaryGhostDarkMode-25vcG{background-color:inherit;border:1px solid #cfd4d8;border-radius:2px;color:#fff;cursor:pointer;display:block;font-family:LabGrotesque-Bold,sans-serif;padding:15px 31px;text-align:center;text-decoration:none;-webkit-transition:color .2s ease-in-out,background-color .2s ease-in-out,border .2s ease-in-out;transition:color .2s ease-in-out,background-color .2s ease-in-out,border .2s ease-in-out}.ButtonSecondaryGhost-buttonSecondaryGhostDarkMode-25vcG:active,.ButtonSecondaryGhost-buttonSecondaryGhostDarkMode-25vcG:hover{background:hsla(0,0%,100%,.1);border:1px solid rgba(0,0,0,.2);color:#fff}.ButtonSecondaryGhost-buttonSecondaryGhost-DuAoV.ButtonSecondaryGhost-block-2qqIA{width:100%}.ButtonSecondaryGhost-buttonSecondaryGhost-DuAoV.ButtonSecondaryGhost-gutterBottom-1fKqv{margin-bottom:1rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"buttonSecondaryGhost": "ButtonSecondaryGhost-buttonSecondaryGhost-DuAoV",
	"inverted": "ButtonSecondaryGhost-inverted-3ogUg",
	"buttonSecondaryGhostDarkMode": "ButtonSecondaryGhost-buttonSecondaryGhostDarkMode-25vcG",
	"block": "ButtonSecondaryGhost-block-2qqIA",
	"gutterBottom": "ButtonSecondaryGhost-gutterBottom-1fKqv"
};
module.exports = exports;
