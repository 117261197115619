import React, { useState } from 'react';
import classnames from 'classnames';
import history from 'utils/history';
import withStyles from 'isomorphic-style-loader/withStyles';
import SearchBar from 'components/molecules/SearchBar';
import Modal from 'components/organisms/Modal';
import s from './SearchModal.css';
import useGlobalState from '../../../zustand/zustand';

const SearchModal = ({ classes, navigate, ...other }) => {
  const [showSearch, setShowSearch] = useGlobalState(state => [
    state.search.showSearch,
    state.search.setShowSearch,
  ]);

  const [state, setState] = useState({
    searchTerm: '',
  });
  const handleChange = e => {
    setState({
      searchTerm: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setShowSearch(false);
    // Timeout to let window close before we pick scroll position
    setTimeout(() => {
      const scrollPosition = typeof window !== 'undefined' ? window.scrollY : undefined;
      const searchPath = `/sokresultat/?q=${encodeURIComponent(state.searchTerm)}`;
      history.push(searchPath, { scrollPosition }); // do SPA navigation
      navigate(searchPath);
      setState({
        searchTerm: '',
      });
    }, 200);
  };

  const closeModal = () => {
    setState({
      searchTerm: '',
    });
    setShowSearch(false);
  };

  const classNames = classnames(s.searchModalContent, classes);
  return (
    <Modal
      dark
      classes={s.searchModal}
      closeClasses={s.closeSearch}
      modalOpen={showSearch}
      closeModal={closeModal}
      label="Sök"
      shouldReturnFocusAfterClose={false}
      shouldFocusAfterRender={false}
      alignTop
    >
      <div className={classNames} {...other}>
        <SearchBar
          classes={s.searchBarComponent}
          onSubmit={handleSubmit}
          searchTerm={state.searchTerm}
          focusOnMounted
          dark
          placeholder="Vad letar du efter?"
          autoComplete="off"
          spotlightMode
          searchButton
          handleChange={handleChange}
        />
      </div>
    </Modal>
  );
};

export default withStyles(s)(SearchModal);
