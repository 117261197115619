/* eslint-disable no-plusplus */
import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      const issueTags = pickFrom(data, 'context.issueTags') || [];
      const subjectTags = pickFrom(data, 'context.subjectTags') || [];
      const personTags = pickFrom(data, 'context.personTags') || [];
      const organizationTags = pickFrom(data, 'context.organizationTags') || [];
      const locationTags = pickFrom(data, 'context.locationTags') || [];
      return [...issueTags, ...subjectTags, ...personTags, ...organizationTags, ...locationTags];
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
