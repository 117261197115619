import React from 'react';
import { Helmet } from 'react-helmet';

const OGTags = ({
  title,
  type,
  url,
  siteName,
  description,
  metaDescription,
  image,
  imageWidth,
  imageHeight,
}) => (
  <Helmet>
    <meta property="og:title" content={title} />
    <meta property="og:type" content={type} />
    <meta property="og:url" content={url} />
    <meta property="og:site_name" content={siteName} />
    {metaDescription && <meta name="description" content={metaDescription} />}
    {description && <meta property="og:description" content={description} />}
    {image && <meta property="og:image" content={image} />}
    {imageWidth && <meta property="og:image:width" content={imageWidth} />}
    {imageHeight && <meta property="og:image:height" content={imageHeight} />}
  </Helmet>
);

export default OGTags;
