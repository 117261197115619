// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SectionWrapper-noSpace-3GMFX{padding-bottom:0;padding-top:0}.SectionWrapper-spaceTiltedTop-2z4f4{padding-top:4.6875rem}.SectionWrapper-spaceTilted-nASC2,.SectionWrapper-spaceTiltedBottom-2e-v8{padding-bottom:4.6875rem}.SectionWrapper-spaceTilted-nASC2{padding-top:4.6875rem}.SectionWrapper-spaceTopDefault-YmIZ7{padding-top:1.5rem}.SectionWrapper-spaceTopLarge-1Qn-s{padding-top:2.5rem}.SectionWrapper-spaceTopExtraLarge-3IA6V{padding-top:3.5rem}.SectionWrapper-spaceBottomDefault-m6vIZ{padding-bottom:1.5rem}.SectionWrapper-spaceBottomLarge-1amGr{padding-bottom:2.5rem}.SectionWrapper-spaceBottomExtraLarge-3_ORo{padding-bottom:5rem}@media screen and (max-width:820px){.SectionWrapper-spaceTiltedTop-2z4f4{padding-top:2.56rem}.SectionWrapper-spaceTilted-nASC2,.SectionWrapper-spaceTiltedBottom-2e-v8{padding-bottom:2.56rem}.SectionWrapper-spaceTilted-nASC2{padding-top:2.56rem}.SectionWrapper-spaceTopDefault-YmIZ7{padding-top:1.56rem}.SectionWrapper-spaceTopLarge-1Qn-s{padding-top:2rem}.SectionWrapper-spaceTopExtraLarge-3IA6V{padding-top:3.5rem}.SectionWrapper-spaceBottomDefault-m6vIZ{padding-bottom:1.56rem}.SectionWrapper-spaceBottomLarge-1amGr{padding-bottom:2rem}.SectionWrapper-spaceBottomExtraLarge-3_ORo{padding-bottom:3.5rem}}@media screen and (max-width:541px){.SectionWrapper-spaceTopLarge-1Qn-s{padding-top:1.5rem}.SectionWrapper-spaceTopExtraLarge-3IA6V{padding-top:2rem}.SectionWrapper-spaceBottomLarge-1amGr{padding-bottom:1.5rem}.SectionWrapper-spaceBottomExtraLarge-3_ORo{padding-bottom:2rem}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"noSpace": "SectionWrapper-noSpace-3GMFX",
	"spaceTiltedTop": "SectionWrapper-spaceTiltedTop-2z4f4",
	"spaceTilted": "SectionWrapper-spaceTilted-nASC2",
	"spaceTiltedBottom": "SectionWrapper-spaceTiltedBottom-2e-v8",
	"spaceTopDefault": "SectionWrapper-spaceTopDefault-YmIZ7",
	"spaceTopLarge": "SectionWrapper-spaceTopLarge-1Qn-s",
	"spaceTopExtraLarge": "SectionWrapper-spaceTopExtraLarge-3IA6V",
	"spaceBottomDefault": "SectionWrapper-spaceBottomDefault-m6vIZ",
	"spaceBottomLarge": "SectionWrapper-spaceBottomLarge-1amGr",
	"spaceBottomExtraLarge": "SectionWrapper-spaceBottomExtraLarge-3_ORo"
};
module.exports = exports;
