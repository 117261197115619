const extractHighResolutionImage = image => {
  const content = image?.content;
  const fields = content?.fields;
  // eslint-disable-next-line camelcase
  const rawMetadata = fields?.COM_ESCENIC_DEFAULTMETADATA || fields?.com_escenic_defaultmetadata;
  const metadata = rawMetadata?.reduce((acc, val) => {
    acc[val.KEY] = val.VALUE;
    return acc;
  }, {});
  return {
    ...content,
    fields: {
      ...fields,
      metadata,
    },
  };
};

export default extractHighResolutionImage;
