const parseVideo = url => {
  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  );
  let currentType;
  let idRecurringOne = '';
  let idRecurringTwo = '';

  if (RegExp.$3.indexOf('youtu') > -1) {
    currentType = 'youtube';
  } else if (RegExp.$3.indexOf('vimeo') > -1) {
    currentType = 'vimeo';
  }
  if (RegExp.$6 === 'event') {
    const parseUrl = url.split('event');
    const parseUrlPartTwo = parseUrl[1];
    if (parseUrlPartTwo.includes('/')) {
      const parseId = parseUrlPartTwo.split('/');
      if (parseId[1] !== undefined) {
        idRecurringOne = parseId[1];
      }
      if (parseId[2] !== undefined) {
        idRecurringTwo = parseId[2];
      }
    }
  }

  return {
    type: currentType,
    id: RegExp.$6,
    idrecurringone: idRecurringOne,
    idrecurringtwo: idRecurringTwo,
  };
};

export default parseVideo;
