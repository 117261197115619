import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import s from './BackgroundMediaLayer.css';

const BackgroundMediaLayer = ({ media }) => {
  if (media.type === 'picture') {
    return (
      <div
        className={classnames(s.backgroundMediaLayer, s.image)}
        style={{
          backgroundImage: `url(${media.href})`,
        }}
      />
    );
  }

  return (
    <video
      className={classnames(s.backgroundMediaLayer, s.video)}
      playsInline
      autoPlay
      muted
      loop
      style={{
        backgroundImage: `url(${media.href})`,
      }}
    >
      <source src={media.href} type="video/mp4" />
    </video>
  );
};

export default withStyles(s)(BackgroundMediaLayer);
