/* eslint-disable no-case-declarations */
import React, { useReducer } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import flattenDeep from 'lodash/flattenDeep';
import SelectField from '../../molecules/SelectField';
import Typography from '../../atoms/Typography';
import KeyValue from './KeyValue';
import map from './map.png';
import COUNTIES from './data.json';
import s from './SelfSustainingCounty.css';

const sortAlphabetically = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const ALL_MUNICIPALITIES = flattenDeep(Object.values(COUNTIES)).sort(sortAlphabetically);

const formatPercent = nr =>
  new Intl.NumberFormat('sv-SE', { style: 'percent', maximumFractionDigits: 1 }).format(nr);
const formatNumber = nr => new Intl.NumberFormat('sv-SE', { style: 'decimal' }).format(nr);

const calc = municipalities => {
  const totalSelfSustained = municipalities.reduce((acc, current) => acc + current.selfSustained, 0);
  const totalNotSelfSustained = municipalities.reduce((acc, current) => acc + current.notSelfSustained, 0);

  const totalPortionSelfSustained = totalSelfSustained / (totalSelfSustained + totalNotSelfSustained);
  return formatPercent(totalPortionSelfSustained);
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_COUNTY':
      const showWholeSweden = action.payload === 'helaSverige';

      return {
        ...state,
        selectedCounty: showWholeSweden ? null : action.payload,
        selectedMunicipality: null,
        showWholeSweden,
        box1Value: showWholeSweden ? calc(ALL_MUNICIPALITIES) : calc(COUNTIES[action.payload]),
        box2Value: showWholeSweden
          ? formatNumber(ALL_MUNICIPALITIES.reduce((acc, current) => acc + current.notSelfSustained, 0))
          : formatNumber(
              COUNTIES[action.payload].reduce((acc, current) => acc + current.notSelfSustained, 0)
            ),
        box3Value: null,
        box4Value: null,
      };
    case 'SET_MUNICIPALITY':
      return {
        ...state,
        selectedMunicipality: action.payload,
        box3Value: formatPercent(
          ALL_MUNICIPALITIES?.find(m => m.name === action.payload)?.portionSelfSustained
        ),
        box4Value: formatNumber(ALL_MUNICIPALITIES?.find(m => m.name === action.payload)?.notSelfSustained),
      };
    default:
      throw new Error();
  }
};

const initialState = {
  selectedCounty: null,
  selectedMunicipality: null,
  showWholeSweden: true,
  box1Value: calc(ALL_MUNICIPALITIES),
  box2Value: formatNumber(ALL_MUNICIPALITIES.reduce((acc, current) => acc + current.notSelfSustained, 0)),
  box3Value: null,
  box4Value: null,
};

const SelfSustainingCounty = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChangeCounty = e => {
    dispatch({ type: 'SET_COUNTY', payload: e.target.value });
  };

  const handleChangeMunicipality = e => {
    dispatch({ type: 'SET_MUNICIPALITY', payload: e.target.value });
  };

  const municipalities = state.showWholeSweden ? ALL_MUNICIPALITIES : COUNTIES[state.selectedCounty];

  return (
    <div className={s.selfSustainingCounty}>
      <img className={s.desktopMap} src={map} alt="Karta över Sverige" />
      <div>
        <Typography variant="h2">Så ser självförsörjningen ut i kommunerna</Typography>
        <div className={s.body}>
          <img className={s.mobileMap} src={map} alt="Karta över Sverige" />
          <Typography variant="lead">
            Här kan du se självförsörjningsgrad och antalet personer som inte är självförsörjande för varje
            län och kommun i Sverige.
          </Typography>
        </div>
        <div className={s.selects}>
          <SelectField
            id="county"
            name="county"
            label="Välj län"
            value={state.selectedCounty || 'helaSverige'}
            onChange={handleChangeCounty}
          >
            <option key="helaSverige" value="helaSverige">
              Hela Sverige
            </option>
            {Object.keys(COUNTIES).map(countyName => (
              <option key={countyName} value={countyName}>
                {countyName}
              </option>
            ))}
          </SelectField>
          <SelectField
            id="municipality"
            name="municipality"
            label="Välj kommun"
            value={state.selectedMunicipality || 'valj'}
            onChange={handleChangeMunicipality}
          >
            <option disabled value="valj">
              Välj kommun
            </option>
            {municipalities.map(municipality => (
              <option key={municipality.code} value={municipality.name} suppressHydrationWarning>
                {municipality.name}
              </option>
            ))}
          </SelectField>
        </div>
        <div className={s.keyValues}>
          <KeyValue
            style={{ gridArea: 'b1' }}
            label={state.showWholeSweden ? 'Självförsörjning i hela Sverige' : 'Självförsörjning i länet'}
            value={state.box1Value}
          />
          <KeyValue
            style={{ gridArea: 'b2' }}
            label={
              state.showWholeSweden
                ? 'Ej självförsörjande personer (hela Sverige)'
                : 'Ej självförsörjande personer (län)'
            }
            value={state.box2Value}
          />
          <KeyValue style={{ gridArea: 'b3' }} label="Självförsörjning i kommunen" value={state.box3Value} />
          <KeyValue
            style={{ gridArea: 'b4' }}
            label="Ej självförsörjande personer (kommun)"
            value={state.box4Value}
          />
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(SelfSustainingCounty);
