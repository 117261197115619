import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, data => {
  if (data) {
    try {
      const fields = pickFrom(data, 'context.fields');

      return {
        streamingProvider: pickFrom(fields, 'streamingProvider'),
        id: pickFrom(fields, 'assetId'),
        caption: pickFrom(fields, 'body'),
      };
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
