import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ButtonSecondary.css';

const ButtonSecondary = ({
  classes,
  component: Component = 'button',
  gutterBottom,
  gutterTop,
  block,
  children,
  className,
  ...other
}) => {
  const classNames = classnames(
    s.buttonSecondary,
    block && s.block,
    gutterBottom && s.gutterBottom,
    gutterTop && s.gutterTop,
    classes,
    {
      [className]: !!className,
    }
  );
  const type = Component === 'button' ? 'button' : undefined;

  return (
    <Component className={classNames} type={type} {...other}>
      {children}
    </Component>
  );
};

export default withStyles(s)(ButtonSecondary);
