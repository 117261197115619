import * as types from './types';

const initialState = {
  translation: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TRANSLATION: {
      return {
        ...state,
        translation: action.payload.translation,
      };
    }

    default:
      break;
  }
  return state;
};

export default reducer;
