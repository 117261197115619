import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-solid-svg-icons';
import s from './ButtonSuccess.css';

const ButtonSuccess = ({
  classes,
  component: Component = 'button',
  gutterBottom,
  block,
  children,
  className,
  loading,
  ...other
}) => {
  const classNames = classnames(s.buttonSuccess, block && s.block, gutterBottom && s.gutterBottom, classes, {
    [className]: !!className,
  });
  const type = Component === 'button' && 'button';
  return (
    <Component className={classNames} type={type || undefined} {...other}>
      {loading && (
        <FontAwesomeIcon
          style={{ width: 20, height: 20, marginRight: 10 }}
          icon={faLoader}
          color="white"
          spin
        />
      )}
      {children}
    </Component>
  );
};

export default withStyles(s)(ButtonSuccess);
