// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FooterContact-footerContact-3fzn-{color:#fff;font-family:LabGrotesque-Regular,sans-serif;font-size:14px;line-height:23px}.FooterContact-footerContact-3fzn- .FooterContact-headline-1lMNY{color:#fff;font-family:LabGrotesque-Bold,sans-serif;font-size:16px;line-height:24px;margin-bottom:16px}.FooterContact-footerContact-3fzn- .FooterContact-boldText-28PGb{font-family:LabGrotesque-Regular,sans-serif;margin-bottom:0}@media (min-width:1169px){.FooterContact-footerContact-3fzn- .FooterContact-boldText-28PGb{font-family:LabGrotesque-Bold,sans-serif}}.FooterContact-footerContact-3fzn- a.FooterContact-footerContact-3fzn-{color:#fff;display:block;text-decoration:underline;-webkit-transition:color .2s ease-in-out;transition:color .2s ease-in-out}.FooterContact-footerContact-3fzn- a.FooterContact-footerContact-3fzn-:hover{color:#dee4ea;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"footerContact": "FooterContact-footerContact-3fzn-",
	"headline": "FooterContact-headline-1lMNY",
	"boldText": "FooterContact-boldText-28PGb"
};
module.exports = exports;
