// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PaginationButtons-paginationButtons-2tgpL{-ms-flex-pack:justify;display:-ms-flexbox;display:flex;justify-content:space-between;padding:16px 0}.PaginationButtons-paginationButtons-2tgpL.PaginationButtons-noPrev-cPmUz{-ms-flex-pack:end;justify-content:flex-end}.PaginationButtons-paginationButtons-2tgpL.PaginationButtons-noNext-RpdTX{-ms-flex-pack:start;justify-content:flex-start}.PaginationButtons-paginationButtons-2tgpL a{display:-ms-flexbox;display:flex}.PaginationButtons-paginationButtons-2tgpL a.PaginationButtons-prev-3OvL- svg{margin:1px 8px 0 0}.PaginationButtons-paginationButtons-2tgpL a.PaginationButtons-next-2-oyJ svg{margin:1px 0 0 8px}", ""]);
// Exports
exports.locals = {
	"paginationButtons": "PaginationButtons-paginationButtons-2tgpL",
	"noPrev": "PaginationButtons-noPrev-cPmUz",
	"noNext": "PaginationButtons-noNext-RpdTX",
	"prev": "PaginationButtons-prev-3OvL-",
	"next": "PaginationButtons-next-2-oyJ"
};
module.exports = exports;
