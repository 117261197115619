// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DateCalendar-dateCalendar-19Vmm{display:grid;font-family:LabGrotesque-Regular,sans-serif;font-size:1.625rem;grid-template-columns:66px;grid-template-rows:30px 57px;text-align:center}.DateCalendar-dateCalendar-19Vmm .DateCalendar-month-1gPo7{background-color:#b12f26;border-radius:4px 4px 0 0;color:#fff;font-size:.875rem;line-height:2rem;text-transform:uppercase}.DateCalendar-dateCalendar-19Vmm .DateCalendar-dayYear-3NBWu{background-color:#fff;border:1px solid #cfd4d8;border-radius:0 0 4px 4px;border-top-color:#fff;color:#33414e;display:grid;grid-template-rows:37px 14px;padding:4px 10px}.DateCalendar-dateCalendar-19Vmm .DateCalendar-dayYear-3NBWu div.DateCalendar-day-1Pkmn{font-size:2.25rem;grid-column-end:2;grid-column-start:1;grid-row-end:3;grid-row-start:1}.DateCalendar-dateCalendar-19Vmm .DateCalendar-dayYear-3NBWu div.DateCalendar-year-JP3Dz{font-size:.7rem;grid-column-end:2;grid-column-start:1;grid-row-end:3;grid-row-start:2}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"dateCalendar": "DateCalendar-dateCalendar-19Vmm",
	"month": "DateCalendar-month-1gPo7",
	"dayYear": "DateCalendar-dayYear-3NBWu",
	"day": "DateCalendar-day-1Pkmn",
	"year": "DateCalendar-year-JP3Dz"
};
module.exports = exports;
