import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors as locationSelectors } from 'ducks/location';
import base64 from '../base64';
import useNavigate from './useNavigate';
import { VFS_SALT } from '../../serverRoutes/valfardsskaparnaPlainParamsHandlerRoute';

const parseParams = resultParams => {
  try {
    // We fallback to the q parameter, because there might still exist bookmarked urls and urls in emails etc with that parameter. This can be removed once the redirect in Varnish is implemented.
    const vfsData = JSON.parse(base64.decode(decodeURIComponent(resultParams.vfsdata ?? resultParams.q)));
    const sumKey = parseInt(base64.decode(decodeURIComponent(resultParams.k)), 10);

    return {
      vfsData,
      sumKey,
    };
  } catch (err) {
    return {
      result: {},
      sumKey: 0,
    };
  }
};

const verifyResult = resultParams => {
  if (!(resultParams.vfsdata ?? resultParams.q) || !resultParams.k) {
    return false;
  }

  const { vfsData, sumKey } = parseParams(resultParams);

  return (
    // eslint-disable-next-line no-unsafe-optional-chaining
    vfsData?.companyName?.length + parseInt(vfsData?.antalAnstallda, 10) + VFS_SALT === sumKey
  );
};

const defaultOptions = {
  redirectToVfsPage: true,
};

const useVFSResult = inputOptions => {
  const options = {
    ...defaultOptions,
    ...inputOptions,
  };
  const fullPath = useSelector(locationSelectors.getFullPath);

  const resultParams = Object.fromEntries(new URL(fullPath).searchParams);

  const navigate = useNavigate();

  const isValid = verifyResult(resultParams);

  useEffect(() => {
    if (!isValid && options.redirectToVfsPage) {
      navigate('/valfardsskaparna/');
    }
  }, [isValid]);

  return {
    isValid,
    vfsData: parseParams(resultParams)?.vfsData,
  };
};

export default useVFSResult;
