import { select, put, takeLatest } from 'redux-saga/effects';
import { types as locationTypes, selectors as locationSelectors } from '../ducks/location';
import { actions as cookActions, selectors as cookSelectors } from '../ducks/cook';

function* locationSet() {
  const path = yield select(locationSelectors.getDataPath);
  const snToken = yield select(cookSelectors.getSnToken);

  yield put(cookActions.fetchData(path, snToken));
}

function* saga() {
  yield takeLatest(locationTypes.SET, locationSet);
}

export default saga;
