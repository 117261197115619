import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AlignmentDirection.css';

const AlignmentDirection = ({ children, horizontalAlignment, verticalAlignment, ...restProps }) => {
  const alignmentTopLeft = classnames(s.alignmentTopLeft);
  const alignmentTopCenter = classnames(s.alignmentTopCenter);
  const alignmentTopRight = classnames(s.alignmentTopRight);

  const alignmentCenterLeft = classnames(s.alignmentCenterLeft);
  const alignmentCenterCenter = classnames(s.alignmentCenterCenter);
  const alignmentCenterRight = classnames(s.alignmentCenterRight);

  const alignmentBottomLeft = classnames(s.alignmentBottomLeft);
  const alignmentBottomCenter = classnames(s.alignmentBottomCenter);
  const alignmentBottomRight = classnames(s.alignmentBottomRight);

  const getAlignmentStyle = () => {
    if (verticalAlignment === 'top' && horizontalAlignment === 'left') {
      return alignmentTopLeft;
    }
    if (verticalAlignment === 'top' && horizontalAlignment === 'center') {
      return alignmentTopCenter;
    }
    if (verticalAlignment === 'top' && horizontalAlignment === 'right') {
      return alignmentTopRight;
    }
    if (verticalAlignment === 'center' && horizontalAlignment === 'left') {
      return alignmentCenterLeft;
    }
    if (verticalAlignment === 'center' && horizontalAlignment === 'center') {
      return alignmentCenterCenter;
    }
    if (verticalAlignment === 'center' && horizontalAlignment === 'right') {
      return alignmentCenterRight;
    }

    if (verticalAlignment === 'bottom' && horizontalAlignment === 'left') {
      return alignmentBottomLeft;
    }
    if (verticalAlignment === 'bottom' && horizontalAlignment === 'center') {
      return alignmentBottomCenter;
    }
    if (verticalAlignment === 'bottom' && horizontalAlignment === 'right') {
      return alignmentBottomRight;
    }

    return alignmentTopLeft;
  };

  return (
    <div className={getAlignmentStyle()} {...restProps}>
      {children}
    </div>
  );
};

export default withStyles(s)(AlignmentDirection);
