// import { useState } from 'react';
import { useSelector } from 'react-redux';
import getBlogUniqueName from '../../selectors/getBlogUniqueName';
import getDarkMode from '../../selectors/getDarkMode';
import useGlobalState from '../../zustand/zustand';
import getRootGroupType from '../../selectors/getRootGroupType';

const useDarkHeader = () => {
  const isDarkModeRedux = useSelector(getDarkMode);
  const blogUniqueName = useSelector(getBlogUniqueName);
  const rootGroupType = useSelector(getRootGroupType);
  const isMobileMenuOpen = useGlobalState(state => state.pageHeader.mobileMenu.isOpen);
  const isPageHeaderFloating = useGlobalState(state => state.pageHeader.isFloating);

  const isFlexibleSection = rootGroupType.type === 'flexibleSection';
  const isBlogSearchPage =
    blogUniqueName === 'fokus-pa-skatterna-sokresultat' ||
    blogUniqueName === 'kompetensforsorjning-sokresultat';

  const isDark =
    !isPageHeaderFloating &&
    !isMobileMenuOpen &&
    (!!isDarkModeRedux || isBlogSearchPage || isFlexibleSection);

  return isDark;
};

export default useDarkHeader;
