import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import extractImages from 'utils/extractImages';
import pickFrom from 'utils/pickFrom';
import clientifyUrl from '../utils/clientifyUrl';

export default createSelector(cookSelectors.getData, data => {
  try {
    const searchResults = pickFrom(data, 'searchResults');
    return searchResults.map(result => {
      let teaserImage;

      if (result?.coverImage?.length > 0) {
        teaserImage = result.coverImage;
      } else if (result?.teaserImage?.length > 0) {
        teaserImage = result.teaserImage;
      } else if (result?.profileImage) {
        teaserImage = result.profileImage;
      } else if (result?.videoBackgroundImage) {
        teaserImage = result.videoBackgroundImage;
      }

      const linkToHomeSection = result?.fields?.linkToHomeSection;
      const homeSectionHref = result?.homeSection?.href;
      const href = linkToHomeSection ? homeSectionHref : result.href;

      return {
        id: result.id,
        title: result.title,
        href: clientifyUrl(href),
        fields: result.fields || {},
        contentType: result.contentType,
        published: result.published,
        binary: result.binary,
        images: (teaserImage && teaserImage?.length > 0 && extractImages(teaserImage)) || undefined,
      };
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
});
