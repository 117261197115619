// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EventItem-title-1PluW{font-size:1.25rem!important}.EventItem-eventItem-VUV2r{margin-right:2.5rem}.EventItem-label-CvzZ2{color:#ea8f12;font-size:.85rem!important;font-weight:700;min-height:33px;text-transform:uppercase}.EventItem-label-CvzZ2,.EventItem-live-3-6PD{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.EventItem-live-3-6PD{-ms-flex-pack:center;background:#d05234;color:#fff;font-size:.75rem;justify-content:center;margin-left:.8rem;padding:.2rem .6rem}.EventItem-live-3-6PD .EventItem-dot-2Zq58{background:#fff;border-radius:50%;height:6px;margin-right:.4rem;width:6px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"title": "EventItem-title-1PluW",
	"eventItem": "EventItem-eventItem-VUV2r",
	"label": "EventItem-label-CvzZ2",
	"live": "EventItem-live-3-6PD",
	"dot": "EventItem-dot-2Zq58"
};
module.exports = exports;
