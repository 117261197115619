import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import s from './Section.css';
import getShowBreadcrumbs from '../../../../../../selectors/getShowBreadcrumbs';

const PADDING_MAP = {
  none: '0rem',
  default: '2rem',
  large: '4rem',
  DEFAULT: '2rem',
};

const Section = ({ children, as: As = 'div', isHero, options, zIndex, id }) => {
  const showBreadcrumbs = useSelector(getShowBreadcrumbs);

  return (
    <As
      id={id}
      style={{
        zIndex,
        '--padding-top': PADDING_MAP[options?.topMargin] ?? PADDING_MAP.DEFAULT,
        '--padding-bottom': PADDING_MAP[options?.bottomMargin] ?? PADDING_MAP.DEFAULT,
      }}
      className={classnames(s.section, {
        [s.tiltedBottom]: options?.tiltedBottom,
        [s.hero]: isHero,
        [s.heroWithBreadcrumbs]: isHero && showBreadcrumbs,
      })}
    >
      {children}
    </As>
  );
};

export default withStyles(s)(Section);
