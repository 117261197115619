/* eslint-disable react/no-array-index-key */
/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './SectionWrapper.css';

const SectionWrapper = ({ children, box1, box2, tiltedTop, tiltedBottom, topMargin, bottomMargin }) => {
  const spaceTiltedTop = classnames(s.spaceTiltedTop);
  const spaceTiltedBottom = classnames(s.spaceTiltedBottom);
  const spaceTilted = classnames(s.spaceTilted);

  const spaceTopDefault = classnames(s.spaceTopDefault);
  const spaceTopLarge = classnames(s.spaceTopLarge);
  const spaceTopExtraLarge = classnames(s.spaceTopExtraLarge);

  const spaceBottomDefault = classnames(s.spaceBottomDefault);
  const spaceBottomLarge = classnames(s.spaceBottomLarge);
  const spaceBottomExtraLarge = classnames(s.spaceBottomExtraLarge);

  const noSpace = classnames(s.noSpace);

  const getTiltedStyle = () => {
    if (box1 !== false || box2 !== false) {
      if (tiltedTop === true && tiltedBottom === true) {
        return spaceTilted;
      }
      if (tiltedTop === false && tiltedBottom === true) {
        return spaceTiltedBottom;
      }
      if (tiltedTop === true && tiltedBottom === false) {
        return spaceTiltedTop;
      }

      return noSpace;
    }
    return noSpace;
  };

  const getSpaceTop = () => {
    if (box1 !== false || box2 !== false) {
      if (topMargin === 'default') {
        return spaceTopDefault;
      }
      if (topMargin === 'large') {
        return spaceTopLarge;
      }
      if (topMargin === 'extraLarge') {
        return spaceTopExtraLarge;
      }
      return spaceTopDefault;
    }
    return noSpace;
  };

  const getSpaceBottom = () => {
    if (box1 !== false || box2 !== false) {
      if (bottomMargin === 'default') {
        return spaceBottomDefault;
      }
      if (bottomMargin === 'large') {
        return spaceBottomLarge;
      }
      if (bottomMargin === 'extraLarge') {
        return spaceBottomExtraLarge;
      }
      return spaceBottomDefault;
    }
    return noSpace;
  };

  return (
    <div className={getSpaceTop()}>
      <div className={getSpaceBottom()}>
        <div className={getTiltedStyle()}> {children}</div>
      </div>
    </div>
  );
};

export default withStyles(boostrapGrid, s)(SectionWrapper);
