import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Link from 'containers/atoms/LinkContainer';
import Typography from 'components/atoms/Typography';
import CenteredRow from 'components/templates/Grid/CenteredRow';
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './ForbiddenPage.css';
import useAuth from '../../../utils/hooks/useAuth';
import AuthIndicator from '../../organisms/AuthIndicator/AuthIndicator';

const ForbiddenPage = ({ actionMetadata }) => {
  const { login } = useAuth();
  const role = actionMetadata?.role || 'unauthenticated';
  const actionForGuest = actionMetadata?.premiumContentPolicy?.roleToAction?.guest;
  const hiddenForGuest = actionForGuest === 'hide';

  const UnauthenticatedContent = () => (
    <>
      <Typography gutterBottom variant="lead">
        Denna sida är bara tillgänglig för inloggade användare.
      </Typography>
      <Typography gutterBottom>
        <span className={s.link} onClick={login}>
          Klicka här
        </span>{' '}
        för att logga in eller skapa konto.
      </Typography>
    </>
  );

  const UnauthenticatedMembersOnlyContent = () => (
    <>
      <Typography gutterBottom variant="lead">
        Denna sida är bara tillgänglig för Svenskt Näringslivs medlemsföretag.
      </Typography>
      <Typography gutterBottom>
        <span className={s.link} onClick={login}>
          Klicka här
        </span>{' '}
        för att logga in eller skapa konto.
      </Typography>
    </>
  );

  const AuthenticatedMembersOnlyContent = () => (
    <>
      <Typography gutterBottom variant="lead">
        Denna sida är bara tillgänglig för Svenskt Näringslivs medlemsföretag.
      </Typography>
      <Typography gutterBottom>
        Om du arbetar på ett av våra medlemsföretag, det vill säga ett företag som är medlem i en eller flera
        av{' '}
        <Link className={s.link} to="/medlemsorganisationer/">
          Svenskt Näringslivs medlemsorganisationer
        </Link>
        , behöver du koppla ditt konto till företaget du arbetar på. Detta gör du i{' '}
        <Link className={s.link} target="_blank" to="https://medlemsportalen.svensktnaringsliv.se/">
          Medlemsportalen.
        </Link>
      </Typography>
    </>
  );

  return (
    <div className={boostrapGrid.container}>
      <CenteredRow>
        <AuthIndicator changeDetector={role}>
          {role === 'unauthenticated' && !hiddenForGuest && <UnauthenticatedContent />}
          {role === 'unauthenticated' && hiddenForGuest && <UnauthenticatedMembersOnlyContent />}
          {role === 'guest' && <AuthenticatedMembersOnlyContent />}
        </AuthIndicator>
      </CenteredRow>
    </div>
  );
};

export default withStyles(boostrapGrid, s)(ForbiddenPage);
