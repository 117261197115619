import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import getBlogPostPage from 'selectors/getBlogPostPage';

import ShareBarContainer from 'containers/molecules/ShareBarContainer';
import CenteredRow from 'components/templates/Grid/CenteredRow';

import renderArticleStorylineElement from 'utils/renderArticleStorylineElement';
import bootstrapGrid from 'style/bootstrap-grid.css';
import ButtonSecondary from '../../../atoms/buttons/ButtonSecondary';
import Link from '../../../../containers/atoms/LinkContainer';

import HeroHeader from '../HeroHeader/HeroHeader';
import BlogPostInfo from '../BlogPostInfo/BlogPostInfo';
import Tags from '../Tags/Tags';
import WrittenBy from '../WrittenBy/WrittenBy';
import PaginationButtons from '../PaginationButtons/PaginationButtons';
import BlogPostList from '../BlogPostList/BlogPostList';

import OGShareTags from '../../../molecules/OGShareTags/OGShareTags';

import s from './BlogPostPage.css';

const BlogPostPage = () => {
  const {
    publishedDate,
    groupedStorylineTree,
    authors,
    metadata,
    tags,
    blogName,
    blogUniqueName,
    heroImage,
    latestBlogPosts,
    blogPostPagination,
  } = useSelector(getBlogPostPage);

  const { headGroup, contentGroup } = groupedStorylineTree;

  return (
    <>
      <OGShareTags metadata={metadata} />
      <div className={s.blogPostPage}>
        <HeroHeader bgImage={heroImage.url} fullWidthBg={heroImage.fullWidthBg} />
        <BlogPostInfo blogName={blogName} blogUniqueName={blogUniqueName} authors={authors} />

        <article className={classnames(bootstrapGrid.container, s.container)}>
          <CenteredRow>
            {!!publishedDate && <time dateTime={publishedDate.iso}>{publishedDate.string}</time>}
            {headGroup && headGroup.map(renderArticleStorylineElement)}
            <ShareBarContainer />

            <div className={s.innerContainer}>
              <ShareBarContainer visible hideMobile />
              {contentGroup && contentGroup.map(renderArticleStorylineElement)}

              <PaginationButtons
                prevUrl={blogPostPagination.prev}
                nextUrl={blogPostPagination.next}
                prevLabel="Föregående inlägg"
                nextLabel="Nästa inlägg"
              />
              <Tags tags={tags} />
              <WrittenBy authors={authors} />
            </div>
          </CenteredRow>
        </article>

        {latestBlogPosts?.length > 0 && (
          <div className={s.latestBlogPostsContainer}>
            <CenteredRow>
              <BlogPostList blogPosts={latestBlogPosts.slice(0, 6)} />
              <ButtonSecondary
                className={s.viewAllPosts}
                component={Link}
                to={`/blogg/${blogUniqueName}/sokresultat/`}
              >
                Visa alla inlägg
              </ButtonSecondary>
            </CenteredRow>
          </div>
        )}
      </div>
    </>
  );
};

export default withStyles(bootstrapGrid, s)(BlogPostPage);
