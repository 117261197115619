import { connect } from 'react-redux';
import Translate from 'components/atoms/Translate';
import getTranslation from 'selectors/getTranslation';

const mapStateToProps = state => ({
  translation: getTranslation(state).LANG,
});

const mapDispatchToProps = undefined;

export default connect(mapStateToProps, mapDispatchToProps)(Translate);
