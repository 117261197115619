import * as types from './types';

const getHostname = () => {
  return process?.env?.NODE_HOSTNAME_URL || 'https://stage-www.svensktnaringsliv.net'; // fallback for local development
};

const initialState = {
  pathname: undefined,
  hostname: getHostname(),
  scrollPosition: 0,
  dataPathPrefix: process?.env?.SKIP_PLATE ? '/cook' : '/plate',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET: {
      const { pathname, scrollPosition } = action.payload;
      return {
        ...state,
        pathname,
        scrollPosition,
      };
    }
    case types.SET_HOSTNAME: {
      return {
        ...state,
        hostname: action.payload.hostname,
      };
    }
    case types.SET_DATA_PATH_PREFIX: {
      return {
        ...state,
        dataPathPrefix: action.payload.dataPathPrefix,
      };
    }
    default:
      break;
  }
  return state;
};

export default reducer;
