import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import TextField from 'components/molecules/TextField';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import FormSuccess from 'components/molecules/FormSuccess';
import { AnimatePresence, motion } from 'framer-motion';
import FormError from 'components/molecules/FormError';
import useVFSResult from '../../../utils/hooks/useVFSResult';
import s from './VFSResult3.css';

const postForm = async (email, vfsData) => {
  const { data } = await axios.post('/valfardsskaparna-email/v1', {
    ...vfsData,
    email,
  });
  return data;
};

const VFSResult3 = ({ darkMode }) => {
  const { isValid, vfsData } = useVFSResult();
  const { register, handleSubmit, errors } = useForm();
  const mutation = useMutation(({ email }) => postForm(email, vfsData));

  if (!isValid) {
    return null;
  }

  const onSubmit = data => mutation.mutate(data);

  return (
    <div className={s.vfsResult3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          id="email"
          label="E-post"
          type="email"
          placeholder="Skriv in din e-postadress"
          errorMessage={errors?.email?.message}
          darkMode={darkMode}
          register={register({
            required: 'E-post saknas',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Ogiltig e-postadress',
            },
          })}
        />

        <AnimatePresence>
          {(mutation.isSuccess || mutation.isError) && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              style={{ overflow: 'hidden' }}
            >
              <div className={s.formMessageWrapper}>
                {mutation.isSuccess ? (
                  <FormSuccess headline="Bild skickad" text="En bild har skickats till din e-post" />
                ) : (
                  <FormError
                    headline="Kunde inte skicka bild"
                    text="Något gick fel när bilden skulle skickas"
                  />
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <ButtonPrimaryAlt type="submit" disabled={mutation.isLoading}>
          Skicka
        </ButtonPrimaryAlt>
      </form>
    </div>
  );
};

export default withStyles(s)(VFSResult3);
