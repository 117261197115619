import React from 'react';
import Typography from 'components/atoms/Typography';
import withStyles from 'isomorphic-style-loader/withStyles';
import ms from '../../GOAContainer.css';
import s from './ColumnGenerellt.css';

const ColumnGenerellt = ({ article }) => {
  return (
    <>
      <Typography variant="h4" className={ms.headline}>
        Generellt
      </Typography>
      <table>
        <tbody>
          <tr>
            <td>Skapad</td>
            <td>{article?.created}</td>
          </tr>
          <tr>
            <td>Ändrad</td>
            <td>{article?.lastModified}</td>
          </tr>
          <tr>
            <td>Ändrad av</td>
            <td>{article?.lastModifiedBy}</td>
          </tr>
          <tr>
            <td>Typ</td>
            <td>{article?.contentTypeLabel}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default withStyles(ms, s)(ColumnGenerellt);
