import { createSelector } from 'reselect';

export default createSelector(() => {
  return [
    {
      id: 0,
      type: 'InternalLink',
      icon: 'Phone',
      optionals: {
        subTitle: 'Mån-fre: 08.30-17.00',
      },
      title: 'Ring oss',
      link: '/om_oss/kontakt/ring-oss/',
      linkText: 'Ring Svenskt Näringsliv',
    },
    {
      id: 1,
      type: 'InternalLink',
      icon: 'Email',
      optionals: {
        subTitle: undefined,
      },
      title: 'Mejla oss',
      link: '/om_oss/kontakt/mejla-oss/',
      linkText: 'Skicka e-post',
    },
    {
      id: 2,
      type: 'ExternalLink',
      icon: 'MainOffice',
      optionals: {
        subTitle: 'Storgatan 19, 114 51 Stockholm',
      },
      title: 'Besök huvudkontoret',
      link: 'https://goo.gl/maps/jd32GxJad9mCMACQ8',
      linkText: 'Visa på karta',
    },
    {
      id: 3,
      type: 'InternalLink',
      icon: 'RegionalOffice',
      optionals: {
        subTitle: undefined,
      },
      title: 'Besök ett regionkontor',
      link: '/regioner/',
      linkText: 'Hitta regionkontor',
    },
    {
      id: 4,
      type: 'InternalLink',
      icon: 'ContactEmployee',
      optionals: {
        subTitle: undefined,
      },
      title: 'Kontakta en medarbetare',
      link: '/medarbetare/',
      linkText: 'Hitta medarbetare',
    },
    {
      id: 5,
      type: 'InternalLink',
      icon: 'Membership',
      optionals: {
        subTitle: undefined,
      },
      title: 'Bli medlemsföretag',
      link: '/medlem/intresseanmalan/',
      linkText: 'Skicka intresseanmälan',
    },
    {
      id: 6,
      type: 'InternalLink',
      icon: 'Press',
      optionals: {
        subTitle: undefined,
      },
      title: 'Ring pressjouren',
      link: '/pressrum/',
      linkText: 'Gå till pressrummet',
    },
  ];
});
