import React, { useEffect } from 'react';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import PageLoader from 'components/atoms/PageLoader';
import Layout from 'components/templates/Layout';
import { Toaster } from 'react-hot-toast';
import DesktopFooter from 'components/organisms/DesktopFooter';
import TabletLandscapeFooter from 'components/organisms/TabletLandscapeFooter';
import TabletPortraitFooter from 'components/organisms/TabletPortraitFooter';
import MobileFooter from 'components/organisms/MobileFooter';
import PageTitleContainer from 'containers/atoms/PageTitleContainer';
import PageCanonicalLinkContainer from 'containers/atoms/PageCanonicalLinkContainer';
import MainContentContainer from 'containers/templates/MainContentContainer';
import PageScrollContainer from 'containers/atoms/PageScrollContainer';
import BreadcrumbsContainer from 'containers/molecules/BreadcrumbsContainer';
import GTMDataLayerContainer from 'containers/atoms/GTMDataLayerContainer';
import FooterContent from 'components/organisms/FooterContent';
import SearchModalContainer from 'containers/organisms/SearchModalContainer';
import PageHeader from '../organisms/PageHeader/PageHeader';
import { SWEDISH, ENGLISH } from '../../ducks/app/translations';
import useGlobalState from '../../zustand/zustand';
import useCookData from '../../utils/hooks/useCookData';
import GOAContainer from '../organisms/GOA/GOAContainer';
import NotificationBar from '../organisms/NotificationBar/NotificationBar';

const Application = ({
  insertCss,
  isFetching,
  darkMode,
  headTextColor,
  pathname,
  translation,
  setTranslation,
}) => {
  const { refetchDataIfConditionsMet: refetchCookDataIfConditionsMet } = useCookData();
  const setShouldRenderSearchButton = useGlobalState(state => state.pageHeader.searchButton.setIsVisible);
  const shouldPollCook = useGlobalState(state => state.generalSettings.shouldPollCook);

  useEffect(() => {
    const isEnglishSite = pathname.includes('english');
    setTranslation(isEnglishSite ? ENGLISH : SWEDISH);
    setShouldRenderSearchButton(!isEnglishSite);
    window.hasExpired = false;
  }, [pathname]);

  useEffect(() => {
    let intervalId;

    if (shouldPollCook) {
      const POLLING_INTERVAL = 1000 * 60;

      intervalId = setInterval(() => {
        refetchCookDataIfConditionsMet();
      }, POLLING_INTERVAL);
    }

    return () => clearInterval(intervalId);
  }, [shouldPollCook]);

  return (
    <StyleContext.Provider value={{ insertCss }}>
      {isFetching && <PageLoader />}
      <PageScrollContainer />
      <GTMDataLayerContainer />
      <PageTitleContainer />
      <PageCanonicalLinkContainer />
      <SearchModalContainer />

      {translation.LANG && (
        <Layout darkMode={darkMode}>
          <Toaster containerStyle={{ zIndex: 9999999 }} />
          <NotificationBar />
          <GOAContainer />
          <PageHeader />
          <BreadcrumbsContainer darkMode={darkMode} textColor={headTextColor} />
          <MainContentContainer />
          <FooterContent>
            <DesktopFooter />
            <TabletLandscapeFooter />
            <TabletPortraitFooter />
            <MobileFooter />
          </FooterContent>
        </Layout>
      )}
    </StyleContext.Provider>
  );
};

export default Application;
