import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';

export default createSelector(
  cookSelectors.getData,
  cookSelectors.getActionMetadata,
  (data, actionMetadata) => {
    const main =
      data?.actualResolution?.parameters?.sven_menu_mainMenu ||
      data?.resolution?.parameters?.sven_menu_mainMenu ||
      'main';
    const extra =
      data?.actualResolution?.parameters?.sven_menu_extraMenu ||
      data?.resolution?.parameters?.sven_menu_extraMenu ||
      'extra';
    const role = actionMetadata?.role || 'unauthenticated';

    return {
      main,
      extra,
      role,
    };
  }
);
