// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ModifyButton-modifyButton-2oSwk{background-color:#dee4ea;border:1px solid #cfd4d8;border-left:none;border-right:none;color:#3568a7;cursor:pointer;display:block;font-family:LabGrotesque-Regular,sans-serif;font-size:1.125rem;line-height:1.6875rem;padding-bottom:10px;padding-top:10px;text-align:center;text-decoration:none;width:40px}.ModifyButton-modifyButton-2oSwk:hover{background-color:#e2e8ed}.ModifyButton-modifyButton-2oSwk:active{background-color:#b4c1cf}.ModifyButton-borderRight-2WsVv{border-radius:0 2px 2px 0;border-right:1px solid #cfd4d8}.ModifyButton-borderLeft-Texye{border-left:1px solid #cfd4d8;border-radius:2px 0 0 2px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"modifyButton": "ModifyButton-modifyButton-2oSwk",
	"borderRight": "ModifyButton-borderRight-2WsVv",
	"borderLeft": "ModifyButton-borderLeft-Texye"
};
module.exports = exports;
