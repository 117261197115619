// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DesktopExtraMenu-desktopExtraMenu-12-Se ul{-ms-flex-align:center;-ms-flex-pack:end;align-items:center;display:-ms-flexbox;display:flex;gap:16px;justify-content:flex-end;list-style:none;margin:0;padding:0}.DesktopExtraMenu-desktopExtraMenu-12-Se ul li{font-family:LabGrotesque-Regular,sans-serif;font-size:.875rem}.DesktopExtraMenu-desktopExtraMenu-12-Se ul li a{-ms-flex-align:center;align-items:center;color:#62717f;display:-ms-flexbox;display:flex;text-decoration:none;-webkit-transition:color .3s;transition:color .3s}.DesktopExtraMenu-desktopExtraMenu-12-Se ul li a:hover{text-decoration:underline}.DesktopExtraMenu-desktopExtraMenu-12-Se ul li a .DesktopExtraMenu-icon-17g9O{margin-right:8px;width:16px}.DesktopExtraMenu-desktopExtraMenu-12-Se ul li.DesktopExtraMenu-darkMode-y6LA_ a{color:#b1b4b9}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"desktopExtraMenu": "DesktopExtraMenu-desktopExtraMenu-12-Se",
	"icon": "DesktopExtraMenu-icon-17g9O",
	"darkMode": "DesktopExtraMenu-darkMode-y6LA_"
};
module.exports = exports;
