/* eslint-disable css-modules/no-undef-class */
import React, { useState } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import FAQAnswer from './FAQAnswer/FAQAnswer';
import s from './FAQ.css';

const FAQ = ({ classes, size = 0, question = '', answers = [], lastItem, ...other }) => {
  const [active, setActive] = useState(false);
  const classNames = classnames(s.faqItem, classes, {
    [s.lastItem]: lastItem,
  });

  const renderIcon = () => (
    <div className={s.iconWrapper}>
      <FontAwesomeIcon icon={faPlus} className={classnames(s.icon, s.plus)} />
      <FontAwesomeIcon icon={faMinus} className={classnames(s.icon, s.minus)} />
    </div>
  );

  const renderHeaderText = () => (
    <div className={s.textWrapper}>
      <Typography variant="h4" classes={classnames(s.question, s.desktop)}>
        {question}
      </Typography>
      <Typography variant="h5" classes={classnames(s.question, s.mobile)}>
        {question}
      </Typography>
    </div>
  );

  const renderExpandableText = () => (
    <div className={s.textWrapper}>
      <FAQAnswer classes={s.answer} key={answers.id} storyline={answers.slice(1, size)} />
    </div>
  );

  return (
    <div className={classNames} {...other}>
      <div className={classnames(s.headerContent, active && s.active)} onClick={() => setActive(!active)}>
        {renderIcon()}
        {renderHeaderText()}
      </div>
      <motion.div
        initial={false}
        animate={{ height: active ? 'auto' : 0 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        className={s.expandableContent}
      >
        {renderExpandableText()}
      </motion.div>
    </div>
  );
};

export default withStyles(s)(FAQ);
