/* eslint-disable css-modules/no-undef-class */
/* eslint-disable prettier/prettier */
import React from 'react';
import translations from '../../../translations';
import pickFrom from '../../../utils/pickFrom';

const translateString = ({ translation, children }) => {
  const translateLang = translations?.[translation];
  const translatedString = pickFrom(translateLang, children);

  if (!translatedString) {
    console.warn(`Could not translate: ${children}`);
    return `;${children};`;
  }

  return translatedString;
};

const Translate = ({ children, translation }) => {
  return <span>{translateString({ children, translation })}</span>;
};

export default Translate;
