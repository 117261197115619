import React from 'react';
import Typography from 'components/atoms/Typography';
import pickFrom from 'utils/pickFrom';
import renderStorylineElement from 'utils/renderStorylineElement';

import htmlParser from 'utils/htmlParser';

const renderElement = (element, gutterTop = true, gutterBottom = true) => {
  try {
    switch (element.type) {
      case 'headline3':
        return (
          <Typography key={element.id} variant="h4" gutterBottom={gutterBottom} gutterTop={gutterTop}>
            {htmlParser(pickFrom(element, 'fields.headline.html'))}
          </Typography>
        );
      default:
        return renderStorylineElement(element);
    }
  } catch (error) {
    return undefined;
  }
};

export default renderElement;
