import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import boostrapGrid from 'style/bootstrap-grid.css';
import FooterContact from 'components/molecules/FooterContact';
import FooterMoreNews from 'components/molecules/FooterMoreNews';
import FooterSubscribe from 'components/molecules/FooterSubscribe';
import FooterLinks from 'components/molecules/FooterLinks';
import FooterSocial from 'components/molecules/FooterSocial';
import FooterPublisher from 'components/molecules/FooterPublisher';
// eslint-disable-next-line css-modules/no-unused-class
import s from './MobileFooter.css';
import useTranslation from '../../../utils/hooks/useTranslation';

const MobileFooter = () => {
  const { IS_SWEDISH } = useTranslation();

  return (
    <div className={s.mobileFooter}>
      <FooterContact />
      <div className={s.block}>{IS_SWEDISH && <FooterMoreNews />}</div>
      <div className={s.block}>
        <FooterSubscribe id="mobile-footer-subscribe" />
      </div>
      <div className={s.block}>
        <FooterLinks />
      </div>
      <FooterPublisher />
      <div className={s.block}>
        <FooterSocial />
      </div>
    </div>
  );
};

export default withStyles(s, boostrapGrid)(MobileFooter);
