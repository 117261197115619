import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getTypeIcon from 'utils/getTypeIcon';
import s from './DocumentItem.css';

const DocumentItem = ({ headline, fileType, downloadUrl }) => {
  return (
    <a className={s.documentItem} href={downloadUrl} target="_blank" rel="noopener noreferrer">
      <span className={s.headline}>{headline}</span>
      <div>
        <FontAwesomeIcon icon={getTypeIcon(fileType, true)} className={s.fileIcon} />
        <span className={s.fileType}>{fileType}</span>
      </div>
    </a>
  );
};

export default withStyles(s)(DocumentItem);
