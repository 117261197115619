import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import extractImages from 'utils/extractImages';
import Translator from 'utils/translator';
import pickFrom from 'utils/pickFrom';
import contentTypeTranslations from 'translations/contentType.json';
import clientifyUrl from '../utils/clientifyUrl';

const t = Translator(contentTypeTranslations);

const contentTypesToRemove = ['Profile'];
// eslint-disable-next-line no-useless-escape
const urlPatt = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/;

const filterByContentType = data =>
  data.filter(item => contentTypesToRemove.indexOf(item.content.contentType) === -1);

export default createSelector(cookSelectors.getData, data => {
  try {
    const related = pickFrom(data, 'context.related', 'context.documents');
    if (!related) return undefined;

    const relatedFiltered = filterByContentType(related);

    const parsedRelated = relatedFiltered.map(rel => {
      const teaserOptionsRaw = pickFrom(rel, 'teaserOptions');
      const fields = pickFrom(rel, 'content.fields');
      const contentType = pickFrom(rel, 'content.contentType');
      const binary = pickFrom(rel, 'content.binary');
      const href = pickFrom(rel, 'content.href');
      const document = pickFrom(rel, 'content.document');
      const bodyImage = pickFrom(rel, 'content.bodyImage');
      const teaserImage = pickFrom(rel, 'content.teaserImage');
      const teaserImageRel = pickFrom(rel, 'content.teaserImageRel');
      const title = pickFrom(rel, 'content.title');

      let primaryLink = clientifyUrl(href);
      let secondaryLink;
      let secondaryExtension;

      if (contentType === 'report') {
        secondaryLink = pickFrom(document, '[0].content.binary.href');
        secondaryExtension = pickFrom(document, '[0].content.binary.extension');
      }

      const teaserOptions = teaserOptionsRaw.reduce((acc, val) => {
        acc[val.key] = val.key === 'useSummaryFields' ? val.value === 'true' : val.value;
        return acc;
      }, {});

      const titleTeaser = pickFrom(teaserOptions, 'title');
      const url = pickFrom(fields, 'url');
      const iconType = contentType === 'document' ? binary.extension : contentType;
      let overline = t(contentType);

      if (contentType === 'document') {
        overline = binary.extension.toUpperCase();
        primaryLink = binary.href;
      }
      if (contentType === 'link') {
        overline = urlPatt.exec(url)[1];
        primaryLink = url;
      }

      return {
        ...rel,
        teaserOptions,
        type: iconType,
        primaryLink,
        secondaryLink,
        secondaryExtension,
        overline,
        images: extractImages(bodyImage || teaserImage || teaserImageRel),
        title: teaserOptions.useSummaryFields ? titleTeaser || title : title,
      };
    });

    return parsedRelated;
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
