// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BecomeMemberPage-becomeMemberPage-2CuCX{margin-bottom:80px}.BecomeMemberPage-becomeMemberPage-2CuCX .BecomeMemberPage-leftMenu-3gr0T{display:none}@media screen and (min-width:1169px){.BecomeMemberPage-becomeMemberPage-2CuCX .BecomeMemberPage-leftMenu-3gr0T{display:none;display:block;margin-left:-.75rem;margin-right:-.75rem;padding-bottom:5.25rem;position:sticky;top:12rem}.BecomeMemberPage-becomeMemberPage-2CuCX .BecomeMemberPage-leftMenu-3gr0T .BecomeMemberPage-text-suMCR{text-transform:uppercase;word-break:break-word}.BecomeMemberPage-becomeMemberPage-2CuCX .BecomeMemberPage-leftMenu-3gr0T .BecomeMemberPage-leftMenuIndepthItem-Iy60s{margin-bottom:0;margin-top:.25rem}.BecomeMemberPage-becomeMemberPage-2CuCX .BecomeMemberPage-leftMenu-3gr0T .BecomeMemberPage-leftMenuIndepthItem-Iy60s:first-child{margin-top:.5rem}.BecomeMemberPage-becomeMemberPage-2CuCX .BecomeMemberPage-leftMenu-3gr0T .BecomeMemberPage-leftMenuItem-yC7ea{font-size:.875rem;font-weight:700}.BecomeMemberPage-becomeMemberPage-2CuCX .BecomeMemberPage-leftMenu-3gr0T .BecomeMemberPage-leftMenuItem-yC7ea.BecomeMemberPage-selected-2l-hZ{color:#ea8f12}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"becomeMemberPage": "BecomeMemberPage-becomeMemberPage-2CuCX",
	"leftMenu": "BecomeMemberPage-leftMenu-3gr0T",
	"text": "BecomeMemberPage-text-suMCR",
	"leftMenuIndepthItem": "BecomeMemberPage-leftMenuIndepthItem-Iy60s",
	"leftMenuItem": "BecomeMemberPage-leftMenuItem-yC7ea",
	"selected": "BecomeMemberPage-selected-2l-hZ"
};
module.exports = exports;
