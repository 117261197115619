import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import InDepthList from 'components/molecules/InDepthList';
import InDepthItem from 'components/molecules/InDepthItem';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import renderStorylineElement from 'utils/renderStorylineElement';
import boostrapGrid from 'style/bootstrap-grid.css';
import { useSelector } from 'react-redux';
import s from './BoardMembersPage.css';
import BoardMembersContainer from '../../../containers/organisms/BoardMembersContainer';
import { getMetadata } from '../FlexibleSection/duck/selectors';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';

const BoardMembersPage = ({ pageData, groupedStorylineTree }) => {
  const metadata = useSelector(getMetadata);

  return (
    <>
      <OGShareTags metadata={metadata} />
      <div className={s.boardMembersPage}>
        <div className={classnames(boostrapGrid.container)}>
          <div className={classnames(boostrapGrid.row)}>
            {pageData?.leftMenu?.enabled && (
              <div className={getBootstrapColumns('0,0', '0,0', '4,1')}>
                <div className={s.leftMenu}>
                  <Typography variant="caption" classes={s.text}>
                    {pageData.leftMenu.title}
                  </Typography>
                  <InDepthList>
                    {pageData.leftMenu.items?.map(item => {
                      return (
                        <InDepthItem key={item.id} classes={s.leftMenuIndepthItem} href={item.href}>
                          <Typography
                            variant="caption"
                            classes={classnames(s.leftMenuItem, item.id === 4 && s.selected)}
                          >
                            {item.linkText}
                          </Typography>
                        </InDepthItem>
                      );
                    })}
                  </InDepthList>
                </div>
              </div>
            )}
            <div
              className={getBootstrapColumns('22,1', '18,3', pageData?.leftMenu?.enabled ? '12,2' : '12,6')}
            >
              <div>
                {groupedStorylineTree.headGroup &&
                  groupedStorylineTree.headGroup.map(el => renderStorylineElement(el, false))}
                {groupedStorylineTree.contentGroup &&
                  groupedStorylineTree.contentGroup.map(el => renderStorylineElement(el, false))}
                <BoardMembersContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(s)(BoardMembersPage);
