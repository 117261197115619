import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import getDormainFromUrl from 'utils/getDomainFromUrl';
import Translate from '../../../containers/atoms/TranslateContainer';
import s from './HighlightedArticleLarge.css';

const HighlightedArticleLarge = ({
  classes,
  href,
  imageSrc,
  imageSrcSet,
  imageSizes,
  imageWidth,
  imageHeight,
  contentType,
  headline,
  text,
  small,
  ...other
}) => {
  const classNames = classnames(s.highlightedArticleLarge, classes);
  return (
    <Link to={href} target={contentType === 'link' ? '_blank' : undefined} className={classNames} {...other}>
      {imageSrc && (
        <div className={s.highlightedArticleLargeImageWrapper}>
          <ResponsiveImage
            src={imageSrc}
            srcSet={imageSrcSet}
            alt="article image"
            sizes={imageSizes}
            width={imageWidth}
            height={imageHeight}
          />
        </div>
      )}
      <div style={{ paddingTop: small ? 16 : 24 }}>
        <Typography
          variant="contentTypeDate"
          style={{ marginBottom: 8, display: 'block' }}
          classes={s.contentType}
        >
          {contentType === 'link'
            ? getDormainFromUrl(href)
            : <Translate>{`CONTENT_TYPE.${contentType}`}</Translate> || contentType}
          {contentType === 'link' && (
            <FontAwesomeIcon aria-hidden="true" icon={faExternalLinkAlt} className={s.externalIcon} />
          )}
        </Typography>
        <Typography variant={small ? 'h2' : 'h1'} component="h2" classes={s.headline} gutterBottom>
          {headline}
        </Typography>
        <Typography variant="h2" classes={s.headlineMobile} gutterBottom>
          {headline}
        </Typography>
        <Typography variant={small ? 'paragraphLarge' : 'lead'} component="p" classes={s.paragraphLargeFix}>
          {text}
        </Typography>
      </div>
    </Link>
  );
};

export default withStyles(s)(HighlightedArticleLarge);
