// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VCard-vCard-1CWPx{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.VCard-vCard-1CWPx .VCard-imageWrapper-1Qn_v{-ms-flex-negative:0;flex-shrink:0;height:90px;margin-right:24px;position:relative;width:90px}.VCard-vCard-1CWPx .VCard-imageWrapper-1Qn_v.VCard-noUser-vnoOz{background-color:#eff2f5;border:1px solid #dee4ea;border-radius:50%;color:#bcc3c9;-webkit-mask-image:radial-gradient(#fff,#000);mask-image:radial-gradient(#fff,#000);overflow:hidden}.VCard-vCard-1CWPx .VCard-imageWrapper-1Qn_v.VCard-noUser-vnoOz>:first-child{left:50%;position:absolute;top:50%;-webkit-transform:translate3d(-50%,-50%,0);transform:translate3d(-50%,-50%,0)}.VCard-vCard-1CWPx .VCard-imageWrapper-1Qn_v img{border:1px solid #dee4ea;border-radius:50%}.VCard-vCard-1CWPx .VCard-name-1bzwB{color:#1f272f;font-family:LabGrotesque-Bold,sans-serif;font-size:20px;line-height:28px;margin-bottom:4px}.VCard-vCard-1CWPx .VCard-title-1r2fq{color:#62717f;font-family:LabGrotesque-Regular,sans-serif;font-size:16px;line-height:24px;margin-bottom:4px}.VCard-vCard-1CWPx .VCard-nameLink-1PE34 a,.VCard-vCard-1CWPx .VCard-titleLink-1IqnE a{color:#1f272f;text-decoration:none}.VCard-vCard-1CWPx .VCard-nameLink-1PE34 a:hover,.VCard-vCard-1CWPx .VCard-titleLink-1IqnE a:hover{text-decoration:underline}.VCard-vCard-1CWPx .VCard-linkButton-1iK-8,.VCard-vCard-1CWPx .VCard-viewContacts-1masW{background-color:inherit;border:0;color:#3568a7;cursor:pointer;font-family:LabGrotesque-Regular,sans-serif;font-size:16px;line-height:26px;padding:0;text-decoration:underline}.VCard-imageLink-3DtcO:hover+div>.VCard-name-1bzwB{text-decoration:underline}.VCard-vCard-1CWPx .VCard-darkMode-10M3_{color:#fff}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"vCard": "VCard-vCard-1CWPx",
	"imageWrapper": "VCard-imageWrapper-1Qn_v",
	"noUser": "VCard-noUser-vnoOz",
	"name": "VCard-name-1bzwB",
	"title": "VCard-title-1r2fq",
	"nameLink": "VCard-nameLink-1PE34",
	"titleLink": "VCard-titleLink-1IqnE",
	"linkButton": "VCard-linkButton-1iK-8",
	"viewContacts": "VCard-viewContacts-1masW",
	"imageLink": "VCard-imageLink-3DtcO",
	"darkMode": "VCard-darkMode-10M3_"
};
module.exports = exports;
