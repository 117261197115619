// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".KeyFigures-keyFigures-3Ingn{-ms-flex-pack:center;-ms-flex-wrap:wrap;flex-wrap:wrap;justify-content:center}.KeyFigures-keyFigure-1d_k_,.KeyFigures-keyFigures-3Ingn{display:-ms-flexbox;display:flex}.KeyFigures-keyFigure-1d_k_{-ms-flex-direction:column;flex-direction:column;margin:1rem}.KeyFigures-keyFigure-1d_k_ .KeyFigures-number-3EjFX,.KeyFigures-keyFigure-1d_k_ .KeyFigures-text-3VWwE{text-align:center;width:100%}.KeyFigures-keyFigure-1d_k_ .KeyFigures-number-3EjFX{color:#b12f26;font-family:LabGrotesque-Bold,sans-serif;font-size:2.75rem;font-weight:400;margin-bottom:1rem;white-space:nowrap}.KeyFigures-keyFigure-1d_k_ .KeyFigures-text-3VWwE{margin:0;text-transform:uppercase}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"keyFigures": "KeyFigures-keyFigures-3Ingn",
	"keyFigure": "KeyFigures-keyFigure-1d_k_",
	"number": "KeyFigures-number-3EjFX",
	"text": "KeyFigures-text-3VWwE"
};
module.exports = exports;
