import {
  faFilePdf as faFilePdfSolid,
  faFileWord as faFileWordSolid,
  faFilePowerpoint as faFilePowerpointSolid,
  faFileExcel as faFileExcelSolid,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faFilePdf,
  faFileAudio,
  faFileWord,
  faFilePowerpoint,
  faFileExcel,
  faCalendar,
  faExternalLinkAlt,
  faFileAlt,
  faPlayCircle,
} from '@fortawesome/pro-light-svg-icons';

const getTypeIcon = (type, solid = false) => {
  switch (type) {
    case 'event':
      return faCalendar;
    case 'tv':
      return faPlayCircle;
    case 'link':
      return faExternalLinkAlt;
    case 'pdf':
      return solid ? faFilePdfSolid : faFilePdf;
    case 'doc':
    case 'docx':
      return solid ? faFileWordSolid : faFileWord;
    case 'ppt':
    case 'pttx':
    case 'pps':
    case 'ppsx':
      return solid ? faFilePowerpointSolid : faFilePowerpoint;
    case 'xls':
    case 'xlsx':
      return solid ? faFileExcelSolid : faFileExcel;
    case 'mp3':
      return faFileAudio;
    default:
      return faFileAlt;
  }
};

export default getTypeIcon;
