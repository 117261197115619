import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';
import clientifyUrl from '../utils/clientifyUrl';

export default createSelector(cookSelectors.getData, data => {
  if (data) {
    try {
      const href = pickFrom(data, 'context.section.href');
      return clientifyUrl(href)?.split('?')?.[0];
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
