// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscribeToBlog-subscribeToBlog-1zngg{-webkit-clip-path:polygon(0 10%,100% 0,100% 90%,0 100%);clip-path:polygon(0 10%,100% 0,100% 90%,0 100%);position:relative;z-index:1}.SubscribeToBlog-subscribeToBlog-1zngg.SubscribeToBlog-kompetensforsorjning-3UYtb{background-color:#ea8f12;padding:80px 0}.SubscribeToBlog-subscribeToBlog-1zngg.SubscribeToBlog-fokusPaSkatterna-3cXz3{background-color:#b12f26;padding:80px 0 150px}.SubscribeToBlog-subscribeToBlog-1zngg .SubscribeToBlog-inner-15Rel{grid-gap:24px;-ms-flex-align:center;align-items:center;display:grid;gap:24px;grid-template-columns:1fr}@media (min-width:820px){.SubscribeToBlog-subscribeToBlog-1zngg .SubscribeToBlog-inner-15Rel{grid-template-columns:1fr 1fr}}.SubscribeToBlog-subscribeToBlog-1zngg .SubscribeToBlog-inner-15Rel h2{margin-bottom:16px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"subscribeToBlog": "SubscribeToBlog-subscribeToBlog-1zngg",
	"kompetensforsorjning": "SubscribeToBlog-kompetensforsorjning-3UYtb",
	"fokusPaSkatterna": "SubscribeToBlog-fokusPaSkatterna-3cXz3",
	"inner": "SubscribeToBlog-inner-15Rel"
};
module.exports = exports;
