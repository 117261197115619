// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BlogSearchPage-blogSearchPage-1o4lr{margin-top:-56px;overflow:hidden}.BlogSearchPage-blogSearchPage-1o4lr h1{margin-top:56px}.BlogSearchPage-blogSearchPage-1o4lr .BlogSearchPage-searchWrapper-2NXmk{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}@media (min-width:541px){.BlogSearchPage-blogSearchPage-1o4lr .BlogSearchPage-searchWrapper-2NXmk{-ms-flex-direction:row;flex-direction:row}}.BlogSearchPage-blogSearchPage-1o4lr .BlogSearchPage-searchWrapper-2NXmk .BlogSearchPage-searchBar-1wIfI{margin:1rem 0}.BlogSearchPage-blogSearchPage-1o4lr .BlogSearchPage-searchWrapper-2NXmk .BlogSearchPage-filterButton-1vOTN{width:100%}@media (min-width:541px){.BlogSearchPage-blogSearchPage-1o4lr .BlogSearchPage-searchWrapper-2NXmk .BlogSearchPage-filterButton-1vOTN{margin-left:16px;width:auto}}@media (min-width:1169px){.BlogSearchPage-blogSearchPage-1o4lr .BlogSearchPage-filterWrapperMobile-221LG .BlogSearchPage-checkboxMobile-3pn5i,.BlogSearchPage-blogSearchPage-1o4lr .BlogSearchPage-searchWrapper-2NXmk .BlogSearchPage-filterButton-1vOTN{display:none}}.BlogSearchPage-blogSearchPage-1o4lr .BlogSearchPage-sideContent-3h2-T{display:none}@media (min-width:1169px){.BlogSearchPage-blogSearchPage-1o4lr .BlogSearchPage-sideContent-3h2-T{display:block}}.BlogSearchPage-blogSearchPage-1o4lr .BlogSearchPage-sideContent-3h2-T .BlogSearchPage-filterSelectedBlockWrapper-3lznB{background:#eff2f5;margin-bottom:2.5rem;padding:1.5rem 2.5rem}.BlogSearchPage-blogSearchPage-1o4lr .BlogSearchPage-sideContent-3h2-T .BlogSearchPage-filterSelectedBlockWrapper-3lznB .BlogSearchPage-checkbox-3AQCs{margin-top:1.5rem}.BlogSearchPage-blogSearchPage-1o4lr .BlogSearchPage-sideContent-3h2-T .BlogSearchPage-filterBlockWrapper-9bFdk{background:#eff2f5;height:100%;padding:3.5rem 2.5rem 2.5rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"blogSearchPage": "BlogSearchPage-blogSearchPage-1o4lr",
	"searchWrapper": "BlogSearchPage-searchWrapper-2NXmk",
	"searchBar": "BlogSearchPage-searchBar-1wIfI",
	"filterButton": "BlogSearchPage-filterButton-1vOTN",
	"filterWrapperMobile": "BlogSearchPage-filterWrapperMobile-221LG",
	"checkboxMobile": "BlogSearchPage-checkboxMobile-3pn5i",
	"sideContent": "BlogSearchPage-sideContent-3h2-T",
	"filterSelectedBlockWrapper": "BlogSearchPage-filterSelectedBlockWrapper-3lznB",
	"checkbox": "BlogSearchPage-checkbox-3AQCs",
	"filterBlockWrapper": "BlogSearchPage-filterBlockWrapper-9bFdk"
};
module.exports = exports;
