import { connect } from 'react-redux';
import CoworkerPage from 'components/pages/CoworkerPage';
import getCoworkerPageData from 'selectors/getCoworkerPageData';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getCoworkers from 'selectors/getCoworkers';
import getSortedCoworkers from 'utils/getSortedCoworkers';

const mapStateToProps = state => {
  const unsortedCoworkers = getCoworkers(state);
  return {
    pageData: getCoworkerPageData(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
    sortedCoworkers: getSortedCoworkers(unsortedCoworkers),
    coworkers: unsortedCoworkers,
  };
};

const mapDispatchToProps = undefined;

const CoworkerPageContainer = connect(mapStateToProps, mapDispatchToProps)(CoworkerPage);

export default CoworkerPageContainer;
