import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import s from './AuthorBlogPostsSlider.css';
import Carousel from '../Carousel/Carousel';

const AuthorBlogPostsSlider = ({ blogPosts }) => {
  return (
    <Carousel
      className={s.authorBlogPostsSlider}
      items={blogPosts.map(blogPost => (
        <Link key={blogPost.id} to={blogPost.url} className={s.postLink}>
          <Typography component="h3" variant="h1">
            {blogPost.title}
          </Typography>
          <Typography>{blogPost.teaser}</Typography>
        </Link>
      ))}
    />
  );
};

export default withStyles(s)(AuthorBlogPostsSlider);
