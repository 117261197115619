// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Label-label-2A0bj{-ms-flex-align:center;align-items:center;background-color:#f9e2c2;color:#33414e;display:-ms-inline-flexbox;display:inline-flex;font-family:LabGrotesque-Bold,sans-serif;font-size:.75rem;line-height:1.375rem;padding:4px 10px}.Label-label-2A0bj.Label-red-EGfRh{color:#b12f26}.Label-label-2A0bj.Label-live-1qGwQ{background-color:#b12f26;color:#fff}.Label-label-2A0bj .Label-icon-2i_WT{color:#ea8f12;margin-bottom:2px;margin-right:6px}.Label-label-2A0bj.Label-red-EGfRh .Label-icon-2i_WT{color:#b12f26}.Label-label-2A0bj .Label-dot-1QJWJ{-webkit-animation:Label-blinker-3kTi0 1.5s ease-in-out infinite;animation:Label-blinker-3kTi0 1.5s ease-in-out infinite;background-color:#fff;border-radius:50%;height:6px;margin-right:6px;width:6px}.Label-label-2A0bj.Label-red-EGfRh .Label-dot-1QJWJ{background-color:#b12f26}@-webkit-keyframes Label-blinker-3kTi0{50%{opacity:.15}}@keyframes Label-blinker-3kTi0{50%{opacity:.15}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "Label-red-EGfRh",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"label": "Label-label-2A0bj",
	"live": "Label-live-1qGwQ",
	"icon": "Label-icon-2i_WT",
	"dot": "Label-dot-1QJWJ",
	"blinker": "Label-blinker-3kTi0"
};
module.exports = exports;
