import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';

const getHeroObjectOld = data => pickFrom(data, 'context.deskedContent.hero[0]', 'tagInfo[0].hero[0]');

const getHeroObjectNew = data => {
  const hero = pickFrom(data, 'context.deskedContent.hero');

  return hero?.find(({ type }) => type === 'backgroundImage')?.backgroundImage[0];
};

export default createSelector(cookSelectors.getData, data => {
  try {
    const heroObject = getHeroObjectNew(data) || getHeroObjectOld(data);
    const href = pickFrom(heroObject, 'content.fields.BASE_LANDSCAPE.href_full');
    const altText = pickFrom(heroObject, 'content.fields.alttext');
    const photographer = pickFrom(heroObject, 'content.fields.photographer');
    if (href) {
      return {
        href,
        altText,
        photographer,
      };
    }
    return undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
