// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AgendaItem-agendaItem-3-Rkd .AgendaItem-agendaItemIcon-3I9Ck{color:#ea8f12;height:14px;margin-bottom:2px;margin-right:6px;width:14px}@media (min-width:820px){.AgendaItem-agendaItem-3-Rkd .AgendaItem-agendaItemIcon-3I9Ck{height:16px;width:16px}}.AgendaItem-agendaTimeWrapper-358VK{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;margin-bottom:2px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"agendaItem": "AgendaItem-agendaItem-3-Rkd",
	"agendaItemIcon": "AgendaItem-agendaItemIcon-3I9Ck",
	"agendaTimeWrapper": "AgendaItem-agendaTimeWrapper-358VK"
};
module.exports = exports;
