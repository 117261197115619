import React from 'react';
import classnames from 'classnames';
import Card from 'components/atoms/Card';
import AgendaItem from 'components/molecules/AgendaItem';
import Typography from 'components/atoms/Typography';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AgendaBox.css';

const AgendaBox = ({ classes, headline, agendas, ...other }) => {
  const classNames = classnames(s.agendaBox, classes);
  return (
    <div className={classNames} {...other}>
      {headline && (
        <Typography variant="h2" gutterBottom gutterTop>
          {headline}
        </Typography>
      )}
      <Card classes={s.eventCard}>
        {agendas?.length <= 0 && <Typography>Ej tillgängligt.</Typography>}
        {agendas?.length > 0 &&
          agendas.map(agenda => {
            return (
              <div key={agenda.id} className={s.eventItem}>
                <AgendaItem time={agenda.time} headline={agenda.headline} description={agenda.description} />
              </div>
            );
          })}
      </Card>
    </div>
  );
};

export default withStyles(s)(AgendaBox);
