// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ImageItem-imageItem-LdIuv{-ms-flex-pack:justify;-ms-flex-align:start;align-items:flex-start;display:-ms-flexbox;display:flex;justify-content:space-between;margin-bottom:16px}.ImageItem-downloadLink-wKw9G,.ImageItem-headline-15YN6,.ImageItem-value-3JpKC{background:inherit;border:0;color:#33414e;font-family:LabGrotesque-Regular,sans-serif;font-size:1rem;line-height:1.85rem;margin:0;text-decoration:none}.ImageItem-headline-15YN6{font-family:LabGrotesque-Bold,sans-serif}.ImageItem-downloadLink-wKw9G{color:#3568a7;display:none;text-decoration:underline}@media (min-width:541px){.ImageItem-downloadLink-wKw9G{display:block}}.ImageItem-imageWrapper-2fJ2y{border:1px solid #cfd4d8;max-width:90px}@media (min-width:541px){.ImageItem-imageWrapper-2fJ2y{max-width:174px}}.ImageItem-downloadButton-1G6bZ{display:inline-block;margin-top:8px}@media (min-width:541px){.ImageItem-downloadButton-1G6bZ{display:none}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"imageItem": "ImageItem-imageItem-LdIuv",
	"downloadLink": "ImageItem-downloadLink-wKw9G",
	"headline": "ImageItem-headline-15YN6",
	"value": "ImageItem-value-3JpKC",
	"imageWrapper": "ImageItem-imageWrapper-2fJ2y",
	"downloadButton": "ImageItem-downloadButton-1G6bZ"
};
module.exports = exports;
