import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import s from './PuffDoubleColumn.css';

const PuffDoubleColumn = ({
  classes,
  imageSrc,
  imageAlt,
  link,
  linkText = 'Besök',
  title,
  description,
  ...other
}) => {
  const classNames = classnames(s.puffDoubleColumn, classes);
  return (
    <div className={classNames} {...other}>
      <div className={s.imageWrapper}>
        <div className={s.image} style={{ backgroundImage: `url('${imageSrc}')` }} />
      </div>
      <div className={s.textWrapper}>
        <Typography variant="h3" darkMode gutterBottom>
          {title}
        </Typography>
        <Typography darkMode gutterBottom component="div">
          {description}
        </Typography>
        {link && (
          <ButtonPrimaryAlt
            component="a"
            href={link}
            target={link.startsWith('http') ? '_blank' : '_self'}
            classes={s.button}
          >
            {link.startsWith('http') && (
              <FontAwesomeIcon icon={faExternalLink} className={s.externalLinkIcon} />
            )}
            {linkText}
          </ButtonPrimaryAlt>
        )}
      </div>
    </div>
  );
};

export default withStyles(s)(PuffDoubleColumn);
