import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from '../utils/pickFrom';
import { selectors as locationSelectors } from '../ducks/location';

const getSrcType = data => {
  let contentSrcType = '';
  data.forEach(el => {
    const content = pickFrom(el, 'content');
    if (content !== undefined) {
      contentSrcType = content?.contentType;
    }
  });
  return contentSrcType;
};

const getSrc = data => {
  let scType = '';
  let contentSrcType = '';
  data.forEach(el => {
    const content = pickFrom(el, 'content');
    if (content !== undefined) {
      contentSrcType = content?.contentType;
      if (contentSrcType === 'picture') {
        scType = content?.fields?.BASE_LANDSCAPE?.href_full;
      }
      if (contentSrcType === 'video') {
        scType = content?.binary?.href;
      }
    }
  });
  return scType;
};
export default createSelector(cookSelectors.getData, locationSelectors.getHostname, data => {
  const hero = pickFrom(data, 'microsite.fields');
  const heroBeamBackgroundColor = pickFrom(hero, 'heroBeamBackgroundColor');
  const heroBeamCountDownDate = pickFrom(hero, 'heroBeamCountDownDate');
  const heroBeamText = pickFrom(hero, 'heroBeamText');
  const heroBeamTextAfterCountDown = pickFrom(hero, 'heroBeamTextAfterCountDown');
  const showHeroBeam = pickFrom(hero, 'showHeroBeam');
  const showHeroBeamCountDown = pickFrom(hero, 'showHeroBeamCountDown');
  const heroWidth = pickFrom(hero, 'width');
  const heroBackgroundColor = pickFrom(hero, 'heroBackgroundColor');
  const heroHeight = pickFrom(hero, 'heroHeight');
  const heroLogo = pickFrom(hero, 'heroLogo');
  const heroSNLogoLinkTarget = pickFrom(hero, 'heroSNLogoLinkTarget');
  const heroCustomLogoLinkTarget = pickFrom(hero, 'heroCustomLogoLinkTarget');
  const heroTopMargin = pickFrom(hero, 'heroTopMargin');
  const heroBottomMargin = pickFrom(hero, 'heroBottomMargin');
  const heroMenu = pickFrom(hero, 'heroMenu');
  const deskedContentHero = pickFrom(data, 'context.deskedContent.hero');
  const srcTyp = getSrcType(deskedContentHero);
  const src = getSrc(deskedContentHero);
  return {
    beambackgroundColor: heroBeamBackgroundColor,
    countDownDate: heroBeamCountDownDate,
    text: heroBeamText,
    textAfterCountDown: heroBeamTextAfterCountDown,
    isCountDown: showHeroBeamCountDown,
    isBeam: showHeroBeam,
    width: heroWidth,
    backgroundColor: heroBackgroundColor,
    height: heroHeight,
    logo: heroLogo,
    snlLogoLinkTarget: heroSNLogoLinkTarget,
    customLogoLinkTarget: heroCustomLogoLinkTarget,
    topMargin: heroTopMargin,
    bottomMargin: heroBottomMargin,
    menu: heroMenu,
    src,
    srcTyp,
  };
});
