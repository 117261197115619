import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';
import contentType from '../translations/contentType.json';
import getTranslation from './getTranslation';

export default createSelector(cookSelectors.getData, getTranslation, (data, { LANG }) => {
  if (data) {
    try {
      const { context } = data;

      const cookType = pickFrom(context, 'contentType');
      return contentType[LANG][cookType];
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
});
