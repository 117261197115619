import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import CenteredRow from 'components/templates/Grid/CenteredRow';
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './ErrorPage.css';
import ForbiddenPage from '../ForbiddenPage/ForbiddenPage';
// eslint-disable-next-line css-modules/no-unused-class

const ErrorPage = ({ error }) => {
  const [loaded, setLoaded] = useState(false);
  const [actionMetadata, setActionMetadata] = useState(null);

  useEffect(() => {
    if (error?.code === 403 && error?.body?.actionMetadata) {
      setActionMetadata(error?.body?.actionMetadata);
    }
    setLoaded(true);
  }, [error]);

  if (!loaded) {
    return null;
  }

  return actionMetadata ? (
    <ForbiddenPage actionMetadata={actionMetadata} />
  ) : (
    <div className={s.errorPage}>
      <div className={boostrapGrid.container}>
        <CenteredRow>
          <Typography variant="h1" gutterBottom>
            Sidan finns inte
          </Typography>
          <Typography gutterBottom variant="lead">
            Sidan du försöker komma åt lyser med sin frånvaro. Antingen har du har fel adress till sidan eller
            så har den blivit avpublicerad.
          </Typography>
          <Typography gutterBottom>
            Prova att söka efter den genom att klicka på förstoringsglaset i menyn eller skicka ett{' '}
            <Typography variant="link" component="a" href="mailto:redaktionen@svensktnaringsliv.se">
              mejl till Redaktionen
            </Typography>
            .
          </Typography>
          <Typography>
            <strong>Felkod:</strong> {error?.code !== undefined ? error.code : '500 - Sidan kan inte visas'}
          </Typography>
        </CenteredRow>
      </div>
    </div>
  );
};

export default withStyles(boostrapGrid, s)(ErrorPage);
