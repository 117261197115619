import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import InputField from 'components/atoms/InputField';
import s from './TextField.css';

const TextField = ({
  classes,
  id,
  label,
  labelHidden,
  gutterBottom = true,
  register,
  darkMode,
  ...other
}) => {
  const classNames = classnames(
    s.textField,
    labelHidden && s.labelHidden,
    gutterBottom && s.gutterBottom,
    classes
  );
  return (
    <div className={classNames}>
      <Typography id={`${id}-label`} htmlFor={id} variant="inputLabel" gutterBottom darkMode={darkMode}>
        {label}
      </Typography>
      <InputField id={id} name={id} register={register} {...other} />
    </div>
  );
};

export default withStyles(s)(TextField);
