// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SearchModal-searchModal-27-i2{height:100%;max-width:1372px;width:100%}.SearchModal-searchModalContent-1eTsu{margin-left:auto;margin-right:auto;margin-top:96px;max-width:832px;padding-left:24px;padding-right:24px}@media (min-width:541px){.SearchModal-searchModalContent-1eTsu{margin-top:150px}.SearchModal-searchModal-27-i2 .SearchModal-closeSearch-2ML3q{right:22px;top:16px}}.SearchModal-resultContainer-3qe4m{margin-top:32px}.SearchModal-seeAllResults-3uxuQ .SearchModal-resultCount-1dR3G{font-weight:700}.SearchModal-seeAllResults-3uxuQ:hover{text-decoration:underline}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"searchModal": "SearchModal-searchModal-27-i2",
	"searchModalContent": "SearchModal-searchModalContent-1eTsu",
	"closeSearch": "SearchModal-closeSearch-2ML3q",
	"resultContainer": "SearchModal-resultContainer-3qe4m",
	"seeAllResults": "SearchModal-seeAllResults-3uxuQ",
	"resultCount": "SearchModal-resultCount-1dR3G"
};
module.exports = exports;
