/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import SocialBall from 'components/atoms/SocialBall';
import Link from 'containers/atoms/LinkContainer';
import {
  faLinkedin,
  faYoutube,
  faFacebookSquare,
  faInstagram,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';

import s from './FooterSocial.css';

const FooterSocial = () => (
  <div className={s.footerSocial}>
    <Link to="https://www.facebook.com/svensktnaringsliv/" className={s.link} target="_blank">
      <SocialBall image={faFacebookSquare} brand="facebook" dark height={21} width={21} />
    </Link>
    <Link to="https://twitter.com/svenaringsliv" className={s.link} target="_blank">
      <SocialBall image={faXTwitter} brand="twitter" dark height={21} width={21} />
    </Link>
    <Link to="https://www.linkedin.com/company/svenskt-n-ringsliv" className={s.link} target="_blank">
      <SocialBall image={faLinkedin} brand="linkedin" dark height={21} width={21} />
    </Link>
    <Link to="https://www.instagram.com/svensktnaringsliv" className={s.link} target="_blank">
      <SocialBall image={faInstagram} brand="instagram" dark height={21} width={21} />
    </Link>
    <Link to="https://www.youtube.com/user/svensktnaringsliv" className={s.link} target="_blank">
      <SocialBall image={faYoutube} brand="youtube" dark height={21} width={21} />
    </Link>
  </div>
);

export default withStyles(s)(FooterSocial);
