import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useForm } from 'react-hook-form';
import Typography from 'components/atoms/Typography';
import TextField from 'components/molecules/TextField';
import SelectField from 'components/molecules/SelectField';
import FormSuccess from 'components/molecules/FormSuccess';
import FormError from 'components/molecules/FormError';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import Checkbox from 'components/atoms/Checkbox';
import postForm from 'utils/postForm';
import validateOrganizationNumber from 'utils/validateOrganizationNumber';
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './BusinessPanelForm.css';

const renameMap = {
  fname: 'firstName',
  lname: 'lastName',
};

// eslint-disable-next-line no-unused-vars
const mapFormNamesToKeys = data =>
  Object.keys(data).reduce((acc, val) => {
    acc[renameMap[val] || val] = data[val];
    return acc;
  }, {});

const BusinessPanelForm = ({ classes }) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [counties, setCounties] = useState({
    fetching: true,
    data: [],
    error: undefined,
  });
  const [positions, setPositions] = useState({
    fetching: true,
    data: [],
    error: undefined,
  });

  const [postingOrder, setPostingOrder] = useState({
    order: undefined,
    posting: false,
    status: undefined,
  });
  // ----------------------------------------------
  // get Counties Data -
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('/counties');
        if (response.ok) {
          const data = await response.json();
          setCounties({
            ...counties,
            fetching: false,
            data,
          });
        } else {
          setCounties({
            ...counties,
            fetching: false,
            error: new Error('Subscription response not ok'),
          });
        }
      } catch (error) {
        setCounties({
          ...counties,
          fetching: false,
          error,
        });
      }
    }
    fetchData();
  }, []);
  // ----------------------------------------------
  // get Positions Data -
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('/positions');
        if (response.ok) {
          const data = await response.json();
          setPositions({
            ...positions,
            fetching: false,
            data,
          });
        } else {
          setPositions({
            ...positions,
            fetching: false,
            error: new Error('Subscription response not ok'),
          });
        }
      } catch (error) {
        setPositions({
          ...positions,
          fetching: false,
          error,
        });
      }
    }
    fetchData();
  }, []);

  // ----------------------------------------------
  // post Businesspanelform Data -
  useEffect(() => {
    if (postingOrder.order) {
      postForm('/businesspanelform', postingOrder.order)
        .then(data => {
          if (data.status === 'ok') {
            setPostingOrder({
              ...postingOrder,
              posting: false,
              status: 'complete',
            });
          } else {
            console.error(data);
            setPostingOrder({
              ...postingOrder,
              posting: false,
              status: 'error',
            });
          }
        })
        .catch(err => {
          console.error(err);
          setPostingOrder({
            ...postingOrder,
            posting: false,
            status: 'error',
          });
        });
    }
  }, [postingOrder.order]);
  const { register, handleSubmit, errors } = useForm();
  const classNames = classnames(s.becomeMember, classes);
  const postOrder = data => {
    // eslint-disable-next-line no-console
    const prePostData = mapFormNamesToKeys(data);

    const postData = {
      organizationNumber: prePostData.organizationNumber,
      firstName: prePostData.firstName,
      lastName: prePostData.lastName,
      email: prePostData.email,
      positionId:
        prePostData.positionId === '3fa85f64-5717-4562-b3fc-2c963f66afa6' ? '' : prePostData.positionId,
      countyName: prePostData.countyName,
    };
    setPostingOrder({
      ...postOrder,
      posting: true,
      order: postData,
    });
  };

  return (
    <div className={classNames}>
      {postingOrder.status === 'complete' ? (
        <div>
          <FormSuccess
            headline="Tack för att du gått med i Svenskt Näringslivs företagarpanel!"
            text="Vi återkommer med mer information så fort din intresseanmälan är behandlad!"
          />
        </div>
      ) : (
        <form autoComplete="on" onSubmit={handleSubmit(postOrder)}>
          <Typography variant="caption" component="div" gutterBottom classes={s.captionText}>
            Fyll i företagets uppgifter
          </Typography>
          <TextField
            id="organizationNumber"
            label="Organisationsnummer"
            errorMessage={
              errors?.organizationNumber?.type === 'validate'
                ? 'Felaktigt organisationsnummer'
                : errors?.organizationNumber?.message
            }
            register={register({
              required: 'Organisationsnummer saknas',
              validate: validateOrganizationNumber,
            })}
          />
          <Typography variant="caption" component="div" gutterBottom classes={s.captionTextYou}>
            Fyll i dina kontaktuppgifter
          </Typography>
          <div className={s.equalFieldWrapper}>
            <TextField
              id="fname"
              label="Förnamn"
              classes={s.leftItem}
              errorMessage={errors?.fname?.message}
              register={register({ required: 'Förnamn saknas' })}
            />
            <TextField
              id="lname"
              label="Efternamn"
              classes={s.rightItem}
              errorMessage={errors?.lname?.message}
              register={register({ required: 'Efternamn saknas' })}
            />
          </div>

          <TextField
            id="email"
            label="E-post"
            type="email"
            errorMessage={errors?.email?.message}
            register={register({
              required: 'E-post saknas',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Ogiltig e-postadress',
              },
            })}
          />
          <SelectField
            id="countyName"
            name="countyName"
            label="Län"
            errorMessage={errors?.countyName?.message}
            register={register({ required: 'Län saknas' })}
          >
            <option value="">Välj Län</option>
            {counties?.data.map(county => (
              <option key={county.id} value={county.name}>
                {county.name}
              </option>
            ))}
          </SelectField>
          <SelectField
            id="positionId"
            name="positionId"
            label="Befattning"
            errorMessage={errors?.positionId?.message}
            register={register({ required: 'Befattning saknas' })}
          >
            <option value="">Välj befattning</option>
            {positions?.data.map(position => (
              <option key={position.id} value={position.id}>
                {position.name}
              </option>
            ))}
            <option value="3fa85f64-5717-4562-b3fc-2c963f66afa6">Annan befattning</option>
          </SelectField>

          <Checkbox
            id="terms"
            label={
              <>
                Jag godkänner Svenskt Näringslivs hantering av{' '}
                <Typography
                  variant="link"
                  component="a"
                  href="https://www.svensktnaringsliv.se/dataskydd/integritet-och-behandling-av-personuppgifter-i-digitala-kanaler_1010031.html"
                  target="_blank"
                >
                  personuppgifter
                </Typography>
                .
              </>
            }
            checked={termsAccepted}
            onChange={e => setTermsAccepted(e.target.checked)}
            gutterBottom
          />
          {postingOrder.status === 'error' && (
            <FormError
              classes={s.errorBox}
              headline="Kunde inte skicka intresseanmalan"
              text="Något gick fel när intresseanmalan skickades, kontrollera så alla fält är korrekt ifyllda eller försök igen."
            />
          )}
          <ButtonPrimaryAlt
            type="submit"
            classes={s.submitButton}
            disabled={!termsAccepted || postingOrder.posting}
          >
            {postingOrder.posting && <div className={s.ldsDualRing} />}
            {postingOrder.posting ? 'Skickar intresseanmälan...' : 'Skicka intresseanmälan'}
          </ButtonPrimaryAlt>
        </form>
      )}
    </div>
  );
};

export default withStyles(s, boostrapGrid)(BusinessPanelForm);
