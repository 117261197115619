// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AlignmentDirection-alignmentTopLeft-2TPyJ{text-align:left}.AlignmentDirection-alignmentTopCenter-2hP_M,.AlignmentDirection-alignmentTopLeft-2TPyJ{-ms-flex-align:start;align-items:flex-start;display:-ms-flexbox;display:flex;height:100%}.AlignmentDirection-alignmentTopCenter-2hP_M{text-align:center}.AlignmentDirection-alignmentTopRight-2L92A{-ms-flex-align:start;align-items:flex-start;display:-ms-flexbox;display:flex;height:100%;text-align:right}.AlignmentDirection-alignmentCenterLeft-3T-PX{text-align:left}.AlignmentDirection-alignmentCenterCenter-2tUhR,.AlignmentDirection-alignmentCenterLeft-3T-PX{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;height:100%}.AlignmentDirection-alignmentCenterCenter-2tUhR{text-align:center}.AlignmentDirection-alignmentCenterRight-2gXvp{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;height:100%;text-align:right}.AlignmentDirection-alignmentBottomLeft-852og{text-align:left}.AlignmentDirection-alignmentBottomCenter-Qeaha,.AlignmentDirection-alignmentBottomLeft-852og{-ms-flex-align:end;align-items:flex-end;display:-ms-flexbox;display:flex;height:100%}.AlignmentDirection-alignmentBottomCenter-Qeaha{text-align:center}.AlignmentDirection-alignmentBottomRight-iXVgA{-ms-flex-align:end;align-items:flex-end;display:-ms-flexbox;display:flex;height:100%;text-align:right}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"alignmentTopLeft": "AlignmentDirection-alignmentTopLeft-2TPyJ",
	"alignmentTopCenter": "AlignmentDirection-alignmentTopCenter-2hP_M",
	"alignmentTopRight": "AlignmentDirection-alignmentTopRight-2L92A",
	"alignmentCenterLeft": "AlignmentDirection-alignmentCenterLeft-3T-PX",
	"alignmentCenterCenter": "AlignmentDirection-alignmentCenterCenter-2tUhR",
	"alignmentCenterRight": "AlignmentDirection-alignmentCenterRight-2gXvp",
	"alignmentBottomLeft": "AlignmentDirection-alignmentBottomLeft-852og",
	"alignmentBottomCenter": "AlignmentDirection-alignmentBottomCenter-Qeaha",
	"alignmentBottomRight": "AlignmentDirection-alignmentBottomRight-iXVgA"
};
module.exports = exports;
