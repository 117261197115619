import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';

export default createSelector(cookSelectors.getPath, path => {
  try {
    return (
      ((path?.endsWith('medlem') || path?.endsWith('medlem/')) && 'blueWithBackgroundImage') || undefined
    );
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
