import * as types from './types';

const setTranslation = translation => ({
  type: types.SET_TRANSLATION,
  payload: {
    translation,
  },
});

export { setTranslation };
