/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import FAQ from 'components/organisms/FAQ';
import s from './AccordionItem.css';

const AccordionItem = ({ item = [] }) => {
  return (
    <div>
      {item &&
        item.map((el, i) => {
          const question = el.children[0].fields.headline.rawText;
          const size = el.children;

          return (
            <div key={el.id}>
              <FAQ
                question={question}
                answers={el.children}
                size={size.length}
                lastItem={i === item.length - 1}
              />
            </div>
          );
        })}
    </div>
  );
};

export default withStyles(s)(AccordionItem);
