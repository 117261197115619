import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, data => {
  try {
    const imageObject = pickFrom(data, 'context.image[0]');
    const href = pickFrom(imageObject, 'content.fields.BASE_FREEFORM.href_full');
    const altText = pickFrom(imageObject, 'content.fields.alttext');
    const imageWidth =
      pickFrom(imageObject, 'content.fields.BASE_FREEFORM.sourceWidth') ??
      pickFrom(imageObject, 'content.originalPictureSize.width');
    const imageHeight =
      pickFrom(imageObject, 'content.fields.BASE_FREEFORM.sourceHeight') ??
      pickFrom(imageObject, 'content.originalPictureSize.height');
    if (href) {
      return {
        href,
        altText,
        width: imageWidth,
        height: imageHeight,
      };
    }
    return undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
