import { createSelector } from 'reselect';

export default createSelector(() => {
  return {
    leftMenu: {
      enabled: false,
      title: 'Om oss',
      items: [
        {
          id: 0,
          linkText: 'Detta är Svenskt Näringsliv',
          href: '/medarbetare',
        },
        {
          id: 1,
          linkText: 'Näringslivets Röst - vårt uppdrag',
          href: '/medarbetare',
        },
        {
          id: 2,
          linkText: 'Kontakta oss',
          href: '/om_oss/kontakt/',
        },
        {
          id: 3,
          linkText: 'Medarbetare',
          href: '/medarbetare/',
        },
        {
          id: 4,
          linkText: 'Styrelse och ledning',
          href: '/om_oss/ledamoter/',
        },
        {
          id: 5,
          linkText: 'Organisation',
          href: '/medarbetare/',
        },
        {
          id: 6,
          linkText: 'Samarbetspartners',
          href: '/medarbetare/',
        },
        {
          id: 7,
          linkText: 'Jobba hos oss',
          href: '/om_oss/lediga-tjanster/',
        },
        {
          id: 8,
          linkText: 'Leverantörs- och partnerinfo',
          href: '/medarbetare/',
        },
      ],
    },
  };
});
