import { connect } from 'react-redux';
import Link from 'components/atoms/Link';
import { actions as locationActions } from 'ducks/location';
import getLabel from 'selectors/getLabel';

const mapStateToProps = state => ({
  label: getLabel(state),
});

const mapDispatchToProps = dispatch => {
  return {
    navigate: url => dispatch(locationActions.set(url)),
  };
};

const LinkContainer = connect(mapStateToProps, mapDispatchToProps)(Link);

export default LinkContainer;
