// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RelatedArticle-relatedArticle-MeLGg{display:-ms-flexbox;display:flex;margin-bottom:24px;margin-top:24px;text-decoration:none}.RelatedArticle-relatedArticle-MeLGg:not(.RelatedArticle-haveImage-2reG5){border-left:3px solid #b12f26}.RelatedArticle-relatedArticle-MeLGg .RelatedArticle-imageWrapper-1LKnq{-ms-flex-negative:0;display:-ms-flexbox;display:flex;flex-shrink:0;height:74px;width:74px}.RelatedArticle-relatedArticle-MeLGg .RelatedArticle-imageWrapper-1LKnq.RelatedArticle-freeForm-3jOg3{-ms-flex-item-align:start;align-self:flex-start;height:auto}.RelatedArticle-relatedArticle-MeLGg .RelatedArticle-fileIconDownload-1i51q{color:#fff;height:14px;margin-right:8px;width:auto}.RelatedArticle-downloadButton-2xYrH{-ms-flex-item-align:center;align-self:center;display:none}.RelatedArticle-downloadButton-2xYrH.RelatedArticle-downloadButtonMobile-36ajm{-ms-flex-item-align:start;align-self:flex-start;display:block;margin-top:6px}@media (min-width:541px){.RelatedArticle-downloadButton-2xYrH{display:block}.RelatedArticle-downloadButton-2xYrH.RelatedArticle-downloadButtonMobile-36ajm{display:none}}.RelatedArticle-relatedArticle-MeLGg.RelatedArticle-haveImage-2reG5 .RelatedArticle-imageWrapper-1LKnq{border-left:3px solid #b12f26}.RelatedArticle-relatedArticle-MeLGg .RelatedArticle-textContainer-1Abdf{-ms-flex-positive:1;-ms-flex-direction:row;flex-direction:row;flex-grow:1;margin-right:1rem;padding-left:13px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"relatedArticle": "RelatedArticle-relatedArticle-MeLGg",
	"haveImage": "RelatedArticle-haveImage-2reG5",
	"imageWrapper": "RelatedArticle-imageWrapper-1LKnq",
	"freeForm": "RelatedArticle-freeForm-3jOg3",
	"fileIconDownload": "RelatedArticle-fileIconDownload-1i51q",
	"downloadButton": "RelatedArticle-downloadButton-2xYrH",
	"downloadButtonMobile": "RelatedArticle-downloadButtonMobile-36ajm",
	"textContainer": "RelatedArticle-textContainer-1Abdf"
};
module.exports = exports;
