// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LatestArticle-latestArticle-Agv-e{-ms-flex-pack:justify;-ms-flex-align:start;align-items:flex-start;border-bottom:1px solid #cfd4d8;display:-ms-flexbox;display:flex;justify-content:space-between;overflow:hidden;padding-bottom:16px;padding-top:16px;text-decoration:none}@media (min-width:541px){.LatestArticle-latestArticle-Agv-e{padding-bottom:24px;padding-top:24px}}.LatestArticle-imageBlock-2faJW{display:none;max-width:190px}.LatestArticle-imageBlock-2faJW,.LatestArticle-imageMobileBlock-2D0SO{-ms-flex-negative:0;flex-shrink:0}.LatestArticle-imageMobileBlock-2D0SO{max-width:90px}@media (min-width:541px){.LatestArticle-imageBlock-2faJW{display:block}.LatestArticle-imageMobileBlock-2D0SO{display:none}}.LatestArticle-textBlock-2YIxY{margin-right:1rem}@media (min-width:541px){.LatestArticle-textBlock-2YIxY{margin-right:25px}}.LatestArticle-latestArticle-Agv-e .LatestArticle-latestOverline-nzkql{display:block;margin-bottom:4px;text-transform:uppercase}.LatestArticle-latestArticle-Agv-e .LatestArticle-latestArticleHeadline-HRW4A{margin-bottom:.5rem}@media (min-width:541px){.LatestArticle-latestArticle-Agv-e .LatestArticle-latestArticleHeadline-HRW4A{margin-bottom:1rem}}.LatestArticle-latestArticle-Agv-e:hover .LatestArticle-latestArticleHeadline-HRW4A{text-decoration:underline}.LatestArticle-latestArticle-Agv-e .LatestArticle-day-1xrF-{text-transform:none}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"latestArticle": "LatestArticle-latestArticle-Agv-e",
	"imageBlock": "LatestArticle-imageBlock-2faJW",
	"imageMobileBlock": "LatestArticle-imageMobileBlock-2D0SO",
	"textBlock": "LatestArticle-textBlock-2YIxY",
	"latestOverline": "LatestArticle-latestOverline-nzkql",
	"latestArticleHeadline": "LatestArticle-latestArticleHeadline-HRW4A",
	"day": "LatestArticle-day-1xrF-"
};
module.exports = exports;
