import React, { useState } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import s from './SelectNative.css';

const SelectNative = ({ classes, gutterBottom, errorMessage, register, ...other }) => {
  const [noneSelected, setNoneSelected] = useState(other.defaultValue === 'none');
  const classNames = classnames(
    s.selectNative,
    gutterBottom && s.gutterBottom,
    noneSelected && s.noneSelected,
    classes
  );
  return (
    <div className={classnames(s.select, errorMessage && s.error)}>
      <select
        className={classNames}
        onChange={e => setNoneSelected(e.target.value === 'none')}
        ref={register}
        {...other}
      />
      {errorMessage && (
        <div className={s.errorMessage}>
          <FontAwesomeIcon icon={faInfoCircle} className={s.infoCircle} />
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default withStyles(s)(SelectNative);
