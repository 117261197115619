import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import TimelinesParagraph from './TimelinesParagraph/TimelinesParagraph';
import s from './Timelines.css';

const Timelines = ({ timelines, darkMode }) => {
  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <ul className={s.sessions}>
          {timelines &&
            timelines.map(timeline => {
              return (
                <li
                  className={classnames(s.lines, darkMode && s.darkMode)}
                  righttext={timeline?.children[0]?.fields?.timelinelabel?.rawText}
                >
                  <TimelinesParagraph
                    storyline={timeline?.children.slice(1, timeline?.children.length)}
                    darkMode={darkMode}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default withStyles(s)(Timelines);
