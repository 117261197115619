let globalLang = 'sv';

const setLang = newLang => {
  globalLang = newLang;
};

const getLang = () => globalLang;

const localizeNumber = (num, lang = globalLang) => {
  try {
    const stringNumber = String(num);
    if (lang === 'en') {
      return stringNumber.replace(',', '.');
    }
    return stringNumber.replace('.', ',');
  } catch (error) {
    return num;
  }
};

const keysToLowerCase = object => {
  return Object.keys(object).reduce((acc, key) => {
    let val = object[key];
    if (typeof val === 'object') {
      val = keysToLowerCase(val);
    }
    acc[key.toLocaleLowerCase()] = val;
    return acc;
  }, {});
};

// Returns a function that picks translations from the translations provided object
const Translator = (_translations, lang = globalLang) => {
  const translations = keysToLowerCase(_translations);
  // key should be in the form of a string, eg: my.key, args should be an array
  return (key, args) => {
    let result = key;
    try {
      result = String(key)
        .toLocaleLowerCase()
        .split('.')
        .reduce((acc, val) => acc[val], translations[lang]);
      if (args && args.length > 0) {
        result = args.reduce((acc, val, index) => acc.replace(`{${index}}`, val), result);
      }
    } catch (error) {
      result = key;
    }
    return result;
  };
};

export { setLang, getLang, localizeNumber };

export default Translator;
