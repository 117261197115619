import { connect } from 'react-redux';
import HomePage from 'components/pages/HomePage';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getHeroImage from 'selectors/getHeroImage';
import getSelectedContent from 'selectors/getSelectedContent';
import getFocusAreaListItems from 'selectors/getFocusAreaListItems';
import getQuoteSlideshowData from 'selectors/getQuoteSlideshowData';
import getExpertAreaData from 'selectors/getExpertAreaData';
import getKeyFiguresData from 'selectors/getKeyFiguresData';
import getSelectedEvents from 'selectors/getSelectedEvents';
import getLatestAndSelectedReports from 'selectors/getLatestAndSelectedReports';
import getPuffzData from 'selectors/getPuffzData';
import getExpertOpinionHeadline from 'selectors/getExpertOpinionHeadline';
import getFacebookCount from 'selectors/getFacebookCount';
import getShowSpecial from '../../selectors/getShowSpecial';
import getLatestWebinars from '../../selectors/getLatestWebinars';

const mapStateToProps = state => {
  return {
    heroImage: getHeroImage(state),
    showSpecial: getShowSpecial(state),
    topStories: getSelectedContent(state),
    selectedEvents: getSelectedEvents(state),
    latestWebinars: getLatestWebinars(state),
    latestAndSelectedReports: getLatestAndSelectedReports(state),
    quoteSlideshowData: getQuoteSlideshowData(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
    focusAreaListItems: getFocusAreaListItems(state),
    expertAreaData: getExpertAreaData(state),
    keyFiguresData: getKeyFiguresData(state),
    puffzData: getPuffzData(state),
    expertOpinionHeadline: getExpertOpinionHeadline(state),
    facebookCount: getFacebookCount(state),
  };
};

const mapDispatchToProps = undefined;

const HomePageContainer = connect(mapStateToProps, mapDispatchToProps)(HomePage);

export default HomePageContainer;
