import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AuthIndicator.css';
import useAuthenticationLoading from '../../../utils/hooks/useAuthenticationLoading';

const AuthIndicator = ({ children, changeDetector }) => {
  const [hasValidSessionVariable, setHasValidSessionVariable] = useState(false);
  const [showBlur, setShowBlur] = useState(false);

  const authIsLoading = useAuthenticationLoading();

  useEffect(() => {
    setTimeout(() => {
      setShowBlur(false);
    }, 1000 * 7);
  }, []);

  useEffect(() => {
    if (authIsLoading || hasValidSessionVariable) {
      setShowBlur(true);
    } else {
      setShowBlur(false);
    }
  }, [authIsLoading, hasValidSessionVariable]);

  useEffect(() => {
    const isLoggingInTimeStamp = sessionStorage.getItem('isLoggingInTimeStamp');
    const currentTime = new Date().getTime();
    if (isLoggingInTimeStamp) {
      const isLoggingInTime = parseInt(isLoggingInTimeStamp, 10);
      if (currentTime - isLoggingInTime < 1000 * 5) {
        setHasValidSessionVariable(true);
        sessionStorage.removeItem('isLoggingInTimeStamp');
      } else {
        setHasValidSessionVariable(false);
      }
    } else {
      setHasValidSessionVariable(false);
    }
    sessionStorage.removeItem('isLoggingInTimeStamp');
  }, [changeDetector]);

  if (showBlur) {
    return <div className={s.container}>{children}</div>;
  }
  return children;
};

export default withStyles(s)(AuthIndicator);
