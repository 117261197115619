// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BackgroundColorLayer-backgroundColorLayer-2kwSi{height:100%;left:0;position:absolute;top:0;width:100%;z-index:-1}", ""]);
// Exports
exports.locals = {
	"backgroundColorLayer": "BackgroundColorLayer-backgroundColorLayer-2kwSi"
};
module.exports = exports;
