// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BackgroundPolygon-wrapper-3lUQS{line-height:0;position:relative}.BackgroundPolygon-wrapper-3lUQS:after{background-color:#fff;bottom:-1px;content:\"\";height:2px;left:0;position:absolute;width:100%;z-index:1}.BackgroundPolygon-wrapper-3lUQS.BackgroundPolygon-dark-3FFVT:after{background-color:#33414e}.BackgroundPolygon-wrapper-3lUQS.BackgroundPolygon-mediumDark-39-pT:after{background-color:#62717f}.BackgroundPolygon-wrapper-3lUQS.BackgroundPolygon-cold-2NlfO:after{background-color:#eff2f5}.BackgroundPolygon-wrapper-3lUQS.BackgroundPolygon-inverted-Rv--M:after{bottom:auto;top:-1px}.BackgroundPolygon-wrapper-3lUQS .BackgroundPolygon-backgroundPolygon-3aesC{height:16px;width:100%}@media (min-width:541px){.BackgroundPolygon-wrapper-3lUQS .BackgroundPolygon-backgroundPolygon-3aesC{height:32px}}@media (min-width:1169px){.BackgroundPolygon-wrapper-3lUQS .BackgroundPolygon-backgroundPolygon-3aesC{height:64px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"wrapper": "BackgroundPolygon-wrapper-3lUQS",
	"dark": "BackgroundPolygon-dark-3FFVT",
	"mediumDark": "BackgroundPolygon-mediumDark-39-pT",
	"cold": "BackgroundPolygon-cold-2NlfO",
	"inverted": "BackgroundPolygon-inverted-Rv--M",
	"backgroundPolygon": "BackgroundPolygon-backgroundPolygon-3aesC"
};
module.exports = exports;
