import { useCallback, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import getLiveEvent from 'selectors/getLiveEvent';
import useGlobalState from '../../../zustand/zustand';
import { fetchVimeoStatus } from '../../apiQueries';
import sentryLogger from '../../sentryLogger';
import useCookData from '../useCookData';

const VIDEO_STATUSES = {
  LIVE_STREAM_NOT_STARTED: 'liveStreamNotStarted',
  AVAILABLE: 'available',
  LIVE_STREAM: 'liveStream',
  TRANSCODING_ARCHIVED_LIVE_STREAM: 'transcodingArchivedLiveStream',
  ARCHIVED_LIVE_STREAM: 'archivedLiveStream',
  NOT_FOUND: 'videoNotFound',
};

const getDistanceToStartDateInMinutes = startDateISO => {
  const now = Date.now();
  const eventStartDate = new Date(startDateISO)?.getTime();
  const diffInMs = eventStartDate - now;
  const diffInMinutes = Math.ceil(diffInMs / 1000 / 60);

  return diffInMinutes;
};

const getDistanceFromEndDateInMinutes = endDateISO => {
  const now = new Date().getTime();
  const eventEndDate = new Date(endDateISO);
  const diffInMinutes = Math.round((now - eventEndDate) / 1000 / 60);

  return diffInMinutes;
};

const getRefetchInterval = distanceToStartDateInMinutes => {
  if (distanceToStartDateInMinutes <= 0) {
    return 30 * 1000;
  }
  if (distanceToStartDateInMinutes <= 10) {
    return 15 * 1000;
  }
  if (distanceToStartDateInMinutes <= 20) {
    return 30 * 1000;
  }
  return 60 * 1000;
};

const videoStatusesFor = {
  countDown: [VIDEO_STATUSES.LIVE_STREAM_NOT_STARTED],
  video: [VIDEO_STATUSES.AVAILABLE, VIDEO_STATUSES.LIVE_STREAM, VIDEO_STATUSES.ARCHIVED_LIVE_STREAM],
  transcodingVideo: [VIDEO_STATUSES.TRANSCODING_ARCHIVED_LIVE_STREAM],
  notFound: [VIDEO_STATUSES.NOT_FOUND],
};

const defaultOptions = {
  isStorylineElement: false,
  element: undefined,
};

const POLLING_FETCH_COUNT_LIMIT_LIMIT = 200;

const useLiveEvent = (inputOptions = {}) => {
  const options = {
    ...defaultOptions,
    ...inputOptions,
  };

  const {
    pollingEndpointUrl,
    pageId: articleId,
    eventStartDateISO,
    eventEndDateISO,
    backgroundImage,
    vimeoUrl,
    chatUrl,
    showChatStoryElementSetting,
    articleLastModifiedCook,
    isVimeoTest,
  } = useSelector(getLiveEvent(options.isStorylineElement, options.element));
  const { refetchDataIfConditionsMet: refetchCookDataIfConditionsMet, isFetching: isFetchingCookData } =
    useCookData();
  const shouldPollCook = useGlobalState(state => state.generalSettings.shouldPollCook);
  const setShouldPollCook = useCallback(
    useGlobalState(state => state.generalSettings.setShouldPollCook),
    []
  );

  const pollingFetchCount = useRef(0);
  const enablePollingQuery = useRef(true);

  if (pollingFetchCount.current >= POLLING_FETCH_COUNT_LIMIT_LIMIT) {
    sentryLogger(
      `Polling endpoint was queried too many times (${pollingFetchCount.current} times, limit: ${POLLING_FETCH_COUNT_LIMIT_LIMIT})`
    );
  }

  const distanceFromEndDateInMinutes = getDistanceFromEndDateInMinutes(eventEndDateISO);
  const distanceToStartDateInMinutes = getDistanceToStartDateInMinutes(eventStartDateISO);
  const refetchInterval = getRefetchInterval(distanceToStartDateInMinutes);

  // If this hook is used from a LiveEvent component that is an embedded storyline component
  // set the cook polling flag to true so that the cook data is fetched when the user is on the page.
  // Only do this this setting if the end date of the event is older than an hour
  // Check Application.js for that fetch effect.
  useEffect(() => {
    if (options.isStorylineElement && distanceFromEndDateInMinutes <= 60) {
      setShouldPollCook(true);
    } else {
      setShouldPollCook(false);
    }
    return () => setShouldPollCook(false);
  }, [options.isStorylineElement, shouldPollCook, distanceFromEndDateInMinutes, setShouldPollCook]);

  const enableQuery =
    enablePollingQuery.current &&
    distanceFromEndDateInMinutes < 60 &&
    !!vimeoUrl &&
    !isFetchingCookData &&
    pollingFetchCount.current <= POLLING_FETCH_COUNT_LIMIT_LIMIT;

  const query = useQuery(['vimeoStatus', articleId], () => fetchVimeoStatus(pollingEndpointUrl), {
    refetchOnWindowFocus: false,
    refetchInterval,
    enabled: enableQuery,
    onSuccess: data => {
      logger.log('fetchers', 'Vimeo data was fetched');

      if (data.videoStatus === VIDEO_STATUSES.ARCHIVED_LIVE_STREAM) {
        enablePollingQuery.current = false;
      }
      const lastModifiedCook = new Date(articleLastModifiedCook);
      const lastModifiedPoll = new Date(data.articleLastModified);

      const isStale = lastModifiedPoll > lastModifiedCook;

      if (isStale) {
        refetchCookDataIfConditionsMet();
      }
    },
    onSettled: () => {
      pollingFetchCount.current += 1;
    },
  });

  let showChat = false;
  if (showChatStoryElementSetting === 'true') {
    showChat = !!query.data?.showChatIgnoringArticleSettings;
  } else if (showChatStoryElementSetting === 'inherit') {
    showChat = !!query.data?.showChat;
  }

  const showCountdown = !!(vimeoUrl && videoStatusesFor.countDown.includes(query.data?.videoStatus));
  const showVideo =
    vimeoUrl &&
    (distanceFromEndDateInMinutes >= 60 ||
      query.isError ||
      videoStatusesFor.video.includes(query.data?.videoStatus));
  const showTranscodingVideo = videoStatusesFor.transcodingVideo.includes(query.data?.videoStatus);
  const videoNotFound = videoStatusesFor.notFound.includes(query.data?.videoStatus);

  return {
    isLoading: query.isLoading,
    isVimeoTest,
    eventStartDate: new Date(eventStartDateISO),
    showCountdown,
    showVideo,
    showChat,
    showTranscodingVideo,
    videoNotFound,
    vimeoUrl,
    chatUrl,
    backgroundImage,
  };
};

export default useLiveEvent;
