// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OtherSitesList-focusAreaList-fWBrm{padding:3rem 0}.OtherSitesList-headline-12qU0{margin-bottom:14px;text-align:center}.OtherSitesList-smallList-1DzUP{display:-ms-flexbox;display:flex}.OtherSitesList-largeList-cp466,.OtherSitesList-mediumList-QPnf6{display:none}@media (min-width:541px){.OtherSitesList-focusAreaList-fWBrm{padding:5rem 0 4.5rem}.OtherSitesList-headline-12qU0{margin-bottom:25px}.OtherSitesList-smallList-1DzUP{display:none}.OtherSitesList-mediumList-QPnf6{display:-ms-flexbox;display:flex}}@media (min-width:820px){.OtherSitesList-mediumList-QPnf6{display:none}.OtherSitesList-largeList-cp466{display:-ms-flexbox;display:flex}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"focusAreaList": "OtherSitesList-focusAreaList-fWBrm",
	"headline": "OtherSitesList-headline-12qU0",
	"smallList": "OtherSitesList-smallList-1DzUP",
	"largeList": "OtherSitesList-largeList-cp466",
	"mediumList": "OtherSitesList-mediumList-QPnf6"
};
module.exports = exports;
