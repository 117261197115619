const pushToDataLayer = (event, args) => {
  if (typeof window !== 'undefined') {
    if (!window.dataLayer) window.dataLayer = [];
    window.dataLayer.push({
      event,
      ...args,
    });
  }
};

export default pushToDataLayer;
