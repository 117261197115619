import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import boostrapGrid from 'style/bootstrap-grid.css';
import FooterContact from 'components/molecules/FooterContact';
import FooterMoreNews from 'components/molecules/FooterMoreNews';
import FooterSubscribe from 'components/molecules/FooterSubscribe';
import FooterLinks from 'components/molecules/FooterLinks';
import FooterSocial from 'components/molecules/FooterSocial';
import FooterPublisher from 'components/molecules/FooterPublisher';
// eslint-disable-next-line css-modules/no-unused-class
import s from './TabletPortraitFooter.css';
import useTranslation from '../../../utils/hooks/useTranslation';

const TabletPortraitFooter = () => {
  const { IS_SWEDISH } = useTranslation();

  return (
    <div className={s.tabletPortraitFooter}>
      <FooterSocial />
      <div className={boostrapGrid.row}>
        <div className={boostrapGrid['col-20']}>
          <FooterContact />
        </div>
      </div>
      <div className={classnames(boostrapGrid.row, s.moreNewsRow)}>
        <div className={boostrapGrid['col-20']}>{IS_SWEDISH && <FooterMoreNews />}</div>
      </div>
      <div className={classnames(boostrapGrid.row, s.lowerContentSocial)}>
        <div className={boostrapGrid['col-20']}>
          <FooterSubscribe id="tablet-portrait-footer-subscribe" />
        </div>
      </div>
      <div className={s.lowerContent}>
        <FooterLinks />
        <div className={s.lowerContentPublisher}>
          <FooterPublisher />
        </div>
      </div>
    </div>
  );
};

export default withStyles(s, boostrapGrid)(TabletPortraitFooter);
