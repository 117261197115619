import React from 'react';
import useFeatureFlags from '../hooks/useFeatureFlags';

const withFeatureFlagGuard = (WrappedComponent, feature, inverted = false) => {
  const WithFeatureFlagGuard = props => {
    const featureFlags = useFeatureFlags();
    const value = featureFlags[feature];

    if ((inverted && value) || (!inverted && !value)) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return WithFeatureFlagGuard;
};

export default withFeatureFlagGuard;
