import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import getImages from 'utils/getImages';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import generateSizes from 'utils/generateSizes';
import s from './Puff.css';

const Puff = ({ classes, href, imageSrc, imageAlt, title, caption, ...other }) => {
  const classNames = classnames(s.puff, !imageSrc && s.stretchFade, classes);
  const sizes = getImages('LANDSCAPE');
  const imageSizes = generateSizes({
    lg: '640px',
    md: 'calc(84vw - 24px)',
    sm: '96vw - 24px',
    default: 'calc(100vw - 48px)',
  });

  return (
    <Link to={href} className={classNames} {...other}>
      <div className={s.wrapper}>
        {imageSrc && (
          <ResponsiveImage
            className={classnames(s.puffImage)}
            src={sizes.toSrc(imageSrc, 'L')}
            srcSet={sizes.toSrcSet(imageSrc)}
            sizes={imageSizes}
            alt={imageAlt && imageAlt}
          />
        )}
        {title && (
          <Typography variant="h3" darkMode classes={s.title}>
            {title}
          </Typography>
        )}
        {caption && (
          <Typography variant="contentTypeDate" darkMode classes={s.caption}>
            {caption}
          </Typography>
        )}
      </div>
    </Link>
  );
};

export default withStyles(s)(Puff);
