import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SearchButton.css';
import useGlobalState from '../../../../zustand/zustand';
import useDarkHeader from '../../../../utils/hooks/useDarkHeader';

const SearchButton = () => {
  const setShowSearch = useGlobalState(state => state.search.setShowSearch);
  const isDarkHeader = useDarkHeader();

  return (
    <button
      className={classnames(s.searchButton, {
        [s.darkMode]: isDarkHeader,
      })}
      type="button"
      onClick={() => setShowSearch(true)}
    >
      <FontAwesomeIcon icon={faSearch} style={{ width: 16, height: 16 }} />
      <span>Sök på webbplatsen</span>
    </button>
  );
};

export default withStyles(s)(SearchButton);
