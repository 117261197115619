import boostrapGrid from 'style/bootstrap-grid.css';
import classnames from 'classnames';

const splitValue = value => {
  return value.split(',');
};

const trimValue = (value, index) => {
  return value.includes(',') ? splitValue(value)[index].trim() : index === 0 ? value.trim() : false;
};

const getBootstrapColumns = (sm, md, lg, xl) => {
  const small = sm && trimValue(sm, 0);
  const smallOffset = sm && trimValue(sm, 1);
  const medium = md && trimValue(md, 0);
  const mediumOffset = md && trimValue(md, 1);
  const large = lg && trimValue(lg, 0);
  const largeOffset = lg && trimValue(lg, 1);
  const extraLarge = xl && trimValue(xl, 0);
  const extraLargeOffset = xl && trimValue(xl, 1);

  return classnames(
    small && boostrapGrid[`col-sm-${small}`],
    smallOffset && boostrapGrid[`offset-sm-${smallOffset}`],

    medium && boostrapGrid[`col-md-${medium}`],
    mediumOffset && boostrapGrid[`offset-md-${mediumOffset}`],

    large && boostrapGrid[`col-lg-${large}`],
    largeOffset && boostrapGrid[`offset-lg-${largeOffset}`],

    extraLarge && boostrapGrid[`col-xl-${extraLarge}`],
    extraLargeOffset && boostrapGrid[`offset-xl-${extraLargeOffset}`]
  );
};

export default getBootstrapColumns;
