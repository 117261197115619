/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import Typography from 'components/atoms/Typography';
import Translator from 'utils/translator';
import inQueryObject from 'utils/inQueryObject';
import locationsTranslations from 'translations/locations.json';
import FilterBall from 'components/atoms/FilterBall';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import s from './FilterBlock.css';
import useTranslate from '../../../../../utils/hooks/useTranslate';

const tLocations = Translator(locationsTranslations);

const defaultCountToShow = 8;
const increaseCountWith = 30;
const initialShowMoreState = {
  county: 0,
  issue: 0,
  implementationtype: 0,
};

const FilterBlock = ({
  searchQuery,
  filters,
  facets,
  selectFilter,
  unselectFilter,
  clearFilters,
  mobileMode,
  closeModal,
  hiddenFacets = [],
}) => {
  const [showMoreState, setShowMoreState] = useState(initialShowMoreState);
  const translate = useTranslate();

  useEffect(() => {
    setShowMoreState(initialShowMoreState);
  }, [searchQuery]);

  const setShowMore = (key, val) => {
    const newShowMoreState = {
      ...showMoreState,
    };
    newShowMoreState[key] += val;
    setShowMoreState(newShowMoreState);
  };

  const inQueryObjectCurry = (itemKey, itemValue) => inQueryObject(filters, itemKey, itemValue);
  const checkHideFacet = facetKey => !hiddenFacets?.includes(facetKey);

  const renderFacet = (
    facet,
    headline,
    key,
    valueTransformer = val => val,
    checkIsSelected = (itemKey, itemValue) => inQueryObjectCurry(itemKey, itemValue),
    filter = {
      selectFilter: (itemKey, val) => selectFilter(itemKey, val),
      unselectFilter: (itemKey, val) => unselectFilter(itemKey, val),
    }
  ) => (
    <div className={s.facet}>
      <div className={s.headline}>{headline}</div>
      {facet?.slice(0, defaultCountToShow + increaseCountWith * showMoreState[key]).map(item => (
        <FilterBall
          key={item.value}
          count={item.count}
          selected={checkIsSelected(key, item.value)}
          onClick={() =>
            checkIsSelected(key, item.value)
              ? filter.unselectFilter(key, item.value)
              : filter.selectFilter(key, item.value)
          }
        >
          {valueTransformer(item.value)}
        </FilterBall>
      ))}
      <div className={s.moreLessBox}>
        {facet?.length > defaultCountToShow &&
          defaultCountToShow + increaseCountWith * showMoreState[key] > defaultCountToShow && (
            <div
              className={s.showMoreFilters}
              key={`decrease-${defaultCountToShow + increaseCountWith * showMoreState[key]}`}
            >
              <Typography
                variant="link"
                component="button"
                style={{ textDecoration: 'none' }}
                onClick={() => setShowMore(key, -1)}
              >
                - Visa färre
              </Typography>
            </div>
          )}
        {facet?.length > defaultCountToShow &&
          defaultCountToShow + increaseCountWith * showMoreState[key] < facet.length && (
            <div
              className={s.showMoreFilters}
              key={`increase-${defaultCountToShow + increaseCountWith * showMoreState[key]}`}
            >
              <Typography
                variant="link"
                component="button"
                style={{ textDecoration: 'none' }}
                onClick={() => setShowMore(key, 1)}
              >
                + Visa fler
              </Typography>
            </div>
          )}
      </div>
    </div>
  );

  return (
    <div className={classnames(s.filterBlock, mobileMode && s.mobileMode)}>
      <div className={s.filterBallContainer}>
        {checkHideFacet('implementationtype') &&
          facets?.implementationtype?.length > 0 &&
          renderFacet(facets.implementationtype, 'Hur kan jag delta?', 'implementationtype', val =>
            translate(`CALENDAR.${val}`)
          )}
        {checkHideFacet('issue_facet') &&
          facets?.issue_facet?.length > 0 &&
          renderFacet(
            facets.issue_facet,
            'Frågor',
            'issue',
            val => val?.split('_')?.[1],
            (key, val) => inQueryObjectCurry(key, val?.split('_')?.[0]),
            {
              selectFilter: (key, val) => selectFilter(key, val?.split('_')?.[0]),
              unselectFilter: (key, val) => unselectFilter(key, val?.split('_')?.[0]),
            }
          )}
        {checkHideFacet('county') &&
          facets?.county?.length > 0 &&
          renderFacet(facets.county, 'Plats', 'county', val => tLocations(val?.replace('_', '-')) || val)}
      </div>
      <div className={s.buttonContainer}>
        <ButtonSecondaryGhost onClick={clearFilters}>Rensa</ButtonSecondaryGhost>
        {mobileMode && (
          <ButtonSecondaryGhost classes={s.showResultButton} onClick={closeModal}>
            Visa resultat
          </ButtonSecondaryGhost>
        )}
      </div>
    </div>
  );
};

export default withStyles(s)(FilterBlock);
