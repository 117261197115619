import React, { useEffect, useState, useRef } from 'react';
import Typography from 'components/atoms/Typography';
// eslint-disable-next-line import/no-cycle
import renderStorylineElement from 'utils/renderStorylineElement';
import { toPng } from 'html-to-image';
import withStyles from 'isomorphic-style-loader/withStyles';
import pushToDataLayer from 'utils/pushToDataLayer';
import s from './Medlemsbevis.css';
import Radio from '../../../../atoms/Radio/Radio';
import Checkbox from '../../../../atoms/Checkbox/Checkbox';
import Spinner from '../../../../atoms/Spinner/Spinner';
import useGlobalState from '../../../../../zustand/zustand';

const Medlemsbevis = ({ userData, extraCompanyData, groupedStorylineTree }) => {
  const ref = useRef();
  const guidSN = '0ccd80f7-ff07-de11-b4e1-02bf9e69ca65';
  const { headGroup, contentGroup } = groupedStorylineTree;
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [legalName, setLegalName] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [includeYear, setIncludeYear] = useState(true);
  const [memberSinceYear, setMemberSinceYear] = useState(null);
  const generalLogoPrint = '/SN-medlemslogotyp-print_5cm.png';
  const generalLogoWeb = '/SN-medlemslogotyp-webb_300px.png';
  const plaqueLogo = '/SN-logo_207.png';
  const companyDataIsLoading = useGlobalState(state => state.companyData.isLoading);
  const companyExtraDataIsLoading = useGlobalState(state => state.companyExtraData.isLoading);
  const companyInvoiceDataisLoading = useGlobalState(state => state.companyInvoiceData.isLoading);
  const userPreferencesIsLoading = useGlobalState(state => state.userPreferences.isLoading);

  const findMemberSinceYear = memberships => {
    if (memberships?.length > 0) {
      const foundDate = memberships.find(
        membership => membership?.membershipOrganisation?.id === guidSN
      )?.entryDate;
      if (foundDate && typeof foundDate === 'string' && parseInt(foundDate?.substring(0, 4), 10) > 1900) {
        const date = new Date(foundDate);
        const options = { timeZone: 'Europe/Stockholm' };
        const swedishDateStr = date.toLocaleString('sv-SE', options);
        const swedishDateYear = swedishDateStr.substring(0, 4);
        if (swedishDateYear?.length === 4) {
          setMemberSinceYear(swedishDateYear);
        } else {
          setMemberSinceYear(null);
        }
      }
    }
  };

  useEffect(() => {
    if (userData && extraCompanyData) {
      setLegalName(userData?.activeCompany.legalName);
      setDisplayName(extraCompanyData?.displayName);
      setSelectedCompany(userData?.activeCompany.legalName);
      findMemberSinceYear(extraCompanyData?.memberships);
    }
  }, [userData, extraCompanyData]);

  const handleDownloadDigitalPlaque = async () => {
    if (ref.current === null) {
      return;
    }

    let logoVariant;
    if (
      selectedCompany === userData?.activeCompany.legalName ||
      userData?.activeCompany.legalName === extraCompanyData?.displayName
    ) {
      logoVariant = 'plakett - juridiskt namn';
    } else if (selectedCompany === extraCompanyData?.displayName) {
      logoVariant = 'plakett - marknadsnamn';
    }

    try {
      await toPng(ref.current, { cacheBust: true });
      await toPng(ref.current, { cacheBust: true });
      await toPng(ref.current, { cacheBust: true });

      const dataUrl = await toPng(ref.current, { cacheBust: true });
      if (dataUrl) {
        pushToDataLayer('membercompanyLogoDownload', {
          logotypVariant: logoVariant,
          companyName: selectedCompany,
        });
        const link = document.createElement('a');
        link.download = `Medlemsplakett.png`;
        link.href = dataUrl;
        link.click();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={s.container}>
      {headGroup && headGroup.map(renderStorylineElement)}
      {contentGroup && contentGroup.map(renderStorylineElement)}
      <Typography variant="h2" gutterTop gutterBottom>
        Digital medlemsplakett med ert företagsnamn
      </Typography>
      <Typography variant="paragraph" gutterBottom>
        Som medlemsföretag har du även möjlighet att ladda ner en digital medlemsplakett. Den består av
        företagets juridiska namn eller det ”marknadsnamn” som finns registrerat hos Svenskt Näringsliv,
        Svenskt Näringslivs logotyp samt året då företaget första gången gick med i någon av Svenskt
        Näringslivs medlemsorganisationer eller SAF-förbund om medlemskapet tecknades innan Svenskt Näringsliv
        bildades.
      </Typography>
      <Typography variant="paragraph" gutterBottom>
        Vill du ändra eller lägga till marknadsnamn kontaktar du den{' '}
        <a className={s.downloadLink} href="/medlemsorganisationer/">
          medlemsorganisation
        </a>{' '}
        som företaget är medlem i.
      </Typography>
      <Typography variant="paragraph" gutterBottom>
        Plaketten får inte modifieras på något sätt efter den laddats ner.
      </Typography>
      <Typography variant="paragraph" gutterBottom>
        Den digitala medlemsplaketten får bara användas så länge företaget är medlem i någon av Svenskt
        Näringslivs medlemsorganisationer.
      </Typography>
      {extraCompanyData &&
      !companyDataIsLoading &&
      !companyExtraDataIsLoading &&
      !companyInvoiceDataisLoading &&
      !userPreferencesIsLoading ? (
        <>
          {legalName && displayName && legalName !== displayName && (
            <>
              <Typography variant="h4" gutterBottom>
                Vilket företagsnamn vill du använda?
              </Typography>
              <Radio
                id={legalName}
                value={legalName}
                checked={selectedCompany === legalName}
                onChange={() => setSelectedCompany(legalName)}
                label={legalName}
              />
              <Radio
                id={displayName}
                value={displayName}
                checked={selectedCompany === displayName}
                onChange={() => setSelectedCompany(displayName)}
                label={displayName}
              />
            </>
          )}
          {memberSinceYear && (
            <Checkbox
              id="includeYear"
              className={s.checkbox}
              label="Inkludera årtal då ni blev medlemmar"
              checked={includeYear}
              onChange={() => setIncludeYear(!includeYear)}
            />
          )}
          <div
            ref={ref}
            id="snPlaque"
            className={s.snPlaque}
            onContextMenu={e => e.preventDefault()}
            style={{
              padding: '26px 29px',
              border: '1px solid #dee4ea',
            }}
          >
            <div className={s.plaqueCompanyName}>{selectedCompany}</div>
            <img className={s.plaqueLogo} src={plaqueLogo} alt="plaque" />
            <div className={s.plaqueMemberSince}>
              Medlemsföretag{includeYear && memberSinceYear && ` sedan ${memberSinceYear}`}
            </div>
          </div>
          <Typography variant="paragraph" gutterTop gutterBottom>
            <span
              className={`${s.downloadLink} ${s.downloadLinkPlaque}`}
              onClick={handleDownloadDigitalPlaque}
            >
              Ladda ner digital medlemsplakett
            </span>
          </Typography>
        </>
      ) : (
        <div className={s.spinnerContainer}>
          <Spinner size="2x" />
        </div>
      )}
      <Typography variant="h2" gutterBottom>
        Generell logotyp
      </Typography>
      <Typography variant="paragraph" gutterBottom>
        Den generella logotypen för medlemsföretag får användas i trycksaker, till exempel årsredovisningar,
        fakturor eller kundbrev samt i digitala sammanhang såsom företagets webbplats.
      </Typography>
      <Typography variant="paragraph" gutterBottom>
        För att skydda logotypens visuella integritet ska den alltid omges av en fri yta där inga andra
        grafiska element får ligga. Frizonen är ett minimum – ju mer fri yta som finns runt logotypen desto
        tydligare träder den fram. Frizonen gäller också som minsta avstånd till ytterkanten på den yta där
        logotypen ska placeras. I de nedladdningsbara logotyperna är frizonen inkluderad i bildfilen.
      </Typography>
      <Typography variant="paragraph" gutterBottom>
        Logotypen får inte modifieras på något sätt.
      </Typography>
      <Typography variant="paragraph" gutterBottom>
        Logotypen får bara användas så länge företaget är medlem i någon av Svenskt Näringslivs
        medlemsorganisationer.
      </Typography>
      <div className={s.imageContainer}>
        <img alt="Generell logotyp" src={generalLogoWeb} width={300} height={79} />
      </div>
      <Typography variant="paragraph" bold>
        Ladda ner
      </Typography>
      <ul>
        <li>
          <Typography variant="paragraph" gutterBottom>
            <a className={s.downloadLink} href={generalLogoPrint} download={generalLogoPrint}>
              Utskriftsversion, 5cm, 300dpi
            </a>
          </Typography>
        </li>
        <li>
          <Typography variant="paragraph" gutterBottom>
            <a className={s.downloadLink} href={generalLogoWeb} download={generalLogoWeb}>
              Webbversion, 300px
            </a>
          </Typography>
        </li>
      </ul>
    </div>
  );
};

export default withStyles(s)(Medlemsbevis);
