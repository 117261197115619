import { connect } from 'react-redux';
import MembershipOrganizationsPage from 'components/pages/MembershipOrganizationsPage';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';

const mapStateToProps = state => {
  return {
    groupedStorylineTree: getGroupedStorylineTree(state),
  };
};

const mapDispatchToProps = undefined;

const MembershipOrganizationsPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipOrganizationsPage);

export default MembershipOrganizationsPageContainer;
