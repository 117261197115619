// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RegionOfficesWithNames-regionOfficesWithNames-3gRyI text{fill:#33414e;font-family:LabGrotesque-Bold,sans-serif;font-size:16px}.RegionOfficesWithNames-strokeGroup-27nMF path{pointer-events:none}.RegionOfficesWithNames-county-_poPj path,.RegionOfficesWithNames-county-_poPj text{-webkit-transition:all .2s ease-in-out;transition:all .2s ease-in-out}.RegionOfficesWithNames-county-_poPj:hover text{fill:#62717f;text-decoration:underline}.RegionOfficesWithNames-county-_poPj:hover path{fill:#ffb538}.RegionOfficesWithNames-county-_poPj:active path{fill:#c46900}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"regionOfficesWithNames": "RegionOfficesWithNames-regionOfficesWithNames-3gRyI",
	"strokeGroup": "RegionOfficesWithNames-strokeGroup-27nMF",
	"county": "RegionOfficesWithNames-county-_poPj"
};
module.exports = exports;
