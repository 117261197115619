// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NewsletterSubscriptionsContainer-container-2e2NN{width:100%}.NewsletterSubscriptionsContainer-container-2e2NN .NewsletterSubscriptionsContainer-formContainer-3d4O1{margin-bottom:20px}.NewsletterSubscriptionsContainer-container-2e2NN .NewsletterSubscriptionsContainer-formContainer-3d4O1 .NewsletterSubscriptionsContainer-spinnerContainer-w_5sW{height:84px;margin:20px 0}.NewsletterSubscriptionsContainer-container-2e2NN .NewsletterSubscriptionsContainer-formContainer-3d4O1 .NewsletterSubscriptionsContainer-termsLink-3u_jQ{color:inherit;text-decoration:underline}.NewsletterSubscriptionsContainer-container-2e2NN .NewsletterSubscriptionsContainer-formContainer-3d4O1 .NewsletterSubscriptionsContainer-emailContainer-1Wsmk{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;gap:20px;margin:20px 0;width:100%}@media (min-width:820px){.NewsletterSubscriptionsContainer-container-2e2NN .NewsletterSubscriptionsContainer-formContainer-3d4O1 .NewsletterSubscriptionsContainer-emailContainer-1Wsmk{-ms-flex-direction:row;flex-direction:row;margin:20px 0}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"container": "NewsletterSubscriptionsContainer-container-2e2NN",
	"formContainer": "NewsletterSubscriptionsContainer-formContainer-3d4O1",
	"spinnerContainer": "NewsletterSubscriptionsContainer-spinnerContainer-w_5sW",
	"termsLink": "NewsletterSubscriptionsContainer-termsLink-3u_jQ",
	"emailContainer": "NewsletterSubscriptionsContainer-emailContainer-1Wsmk"
};
module.exports = exports;
