// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SrOnly-srOnly-2hLTR{clip:rect(0,0,0,0);border-width:0;height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;white-space:nowrap;width:1px}", ""]);
// Exports
exports.locals = {
	"srOnly": "SrOnly-srOnly-2hLTR"
};
module.exports = exports;
