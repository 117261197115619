import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import clientifyUrl from '../utils/clientifyUrl';

export default createSelector(cookSelectors.getData, data => {
  try {
    const section = data?.resolution?.section;
    const contextType = data?.resolution?.context;
    const breadcrumbs = [];
    if (section) {
      let currentItem = section;
      const maxDepth = 99;
      let i = 0;
      while (currentItem && i < maxDepth) {
        breadcrumbs.push({
          name: currentItem.name === 'startsidan' ? 'Start' : currentItem.name,
          href: clientifyUrl(currentItem?.href),
          linked: !(i === 0 && contextType === 'sec'),
        });
        currentItem = currentItem.parent;
        i += 1;
      }
    }
    return breadcrumbs?.reverse();
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
