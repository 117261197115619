/* eslint-disable no-console */

import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import getImages from 'utils/getImages';
// eslint-disable-next-line css-modules/no-unused-class
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './DarkMode.css';
import useBreakpoint from '../../../utils/hooks/useBreakpoint';

const DarkMode = ({ darkMode, darkModeType, offset, imageHref, children, classes }) => {
  const breakpoint = useBreakpoint();
  const imageSize = {
    xs: 'M',
    sm: 'M',
    md: 'L',
    lg: 'L',
    xl: 'L',
  }[breakpoint];

  return (
    <div className={classnames(s.topWrapper, !offset && darkMode && s.noOffset, classes)}>
      {darkMode && darkModeType !== 'white' && (
        <div
          suppressHydrationWarning
          className={classnames(
            s.darkModeBackground,
            darkModeType === 'grayWithOutlineLogo' && s.darkModeBackgroundGray,
            offset === 'large' && s.largeOffset,
            offset === 'xlarge' && s.xlargeOffset
          )}
        >
          {imageSize &&
            (() => {
              switch (darkModeType) {
                case 'grayWithOutlineLogo':
                case 'blueWithOutlineLogo':
                  return <div className={s.darkModeLogo} />;
                case 'blueWithBackgroundImage': {
                  if (imageHref) {
                    const src = imageHref
                      ? getImages('LANDSCAPE').toSrc(imageHref, imageSize)
                      : '/testbg.jpg';
                    return <div className={s.darkModeImage} style={{ backgroundImage: `url('${src}')` }} />;
                  }
                  return <div className={s.darkModeLogo} />;
                }
                default:
                  return null;
              }
            })()}
        </div>
      )}

      {children}
    </div>
  );
};

export default withStyles(boostrapGrid, s)(DarkMode);
