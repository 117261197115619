import { useDispatch } from 'react-redux';
import history from 'utils/history';
import { actions as locationActions } from 'ducks/location';

const useNavigate = () => {
  const dispatch = useDispatch();
  const navigate = (url, scrollPosition) => dispatch(locationActions.set(url, scrollPosition));

  return to => {
    history.push(to, { scrollPosition: 0 });
    navigate(to);
  };
};

export default useNavigate;
