import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import boostrapGrid from 'style/bootstrap-grid.css';
import FooterContact from 'components/molecules/FooterContact';
import FooterMoreNews from 'components/molecules/FooterMoreNews';
import FooterSubscribe from 'components/molecules/FooterSubscribe';
import FooterLinks from 'components/molecules/FooterLinks';
import FooterSocial from 'components/molecules/FooterSocial';
import FooterPublisher from 'components/molecules/FooterPublisher';
// eslint-disable-next-line css-modules/no-unused-class
import s from './TabletLandscapeFooter.css';
import useTranslation from '../../../utils/hooks/useTranslation';

const TabletLandscapeFooter = () => {
  const { IS_SWEDISH } = useTranslation();

  return (
    <div className={s.tabletLandscapeFooter}>
      <div className={boostrapGrid.row}>
        <div className={boostrapGrid['col-13']}>
          <FooterContact />
        </div>
        <div className={boostrapGrid['col-11']}>
          <FooterSubscribe id="tablet-landscape-footer-subscribe" />
        </div>
      </div>
      <div className={classnames(boostrapGrid.row, s.moreNewsRow)}>
        <div className={boostrapGrid['col-12']}>{IS_SWEDISH && <FooterMoreNews />}</div>
        <div className={boostrapGrid['col-12']} />
      </div>
      <div className={classnames(boostrapGrid.row, s.lowerContentSocial)}>
        <div className={boostrapGrid['col-13']} />
        <div className={boostrapGrid['col-11']}>
          <FooterSocial />
        </div>
      </div>
      <div className={classnames(boostrapGrid.row, s.lowerContentLinks)}>
        <div className={boostrapGrid['col-12']}>
          <FooterLinks />
        </div>
        <div className={boostrapGrid['col-12']}>
          <FooterPublisher />
        </div>
      </div>
    </div>
  );
};

export default withStyles(s, boostrapGrid)(TabletLandscapeFooter);
