import React, { useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import axios from 'axios';
import toast from 'react-hot-toast';
import { getFrontEndOrigin } from '../../../../../utils/apiUtil';
import ButtonSuccess from '../../../../atoms/buttons/ButtonSuccess';
import s from './ReviewConfirmation.css';

const ReviewConfirmation = ({ reviewData }) => {
  const [view, setView] = useState('confirm');
  const [storing, setStoring] = useState(false);

  const markArticleAsReviewed = async () => {
    const articleId = reviewData?.reviewData?.article?.id;

    if (articleId) {
      setStoring(true);

      try {
        const endpointUrl = `${getFrontEndOrigin()}/backoffice/api/goa/review/${articleId}`;
        // TODO: Extra parameter needs to be set here. Ask Jimi. ??
        await axios.post(endpointUrl, { reviewed: true, ongoingReviewDone: true }, { withCredentials: true });
        setView('done');
        toast.success('Faktagranskningen lyckades!');
      } catch (e) {
        toast.error('Misslyckades att spara granskningen');
      }
      setStoring(false);
    }
  };

  return (
    <div className={s.container}>
      {reviewData?.reviewData?.article?.ongoingReviewStartDate && view === 'confirm' && (
        <>
          <Typography variant="paragraphSmall" gutterBottom>
            Bekräfta att denna sida är faktagranskad och uppdaterad.
          </Typography>
          <ButtonSuccess onClick={markArticleAsReviewed} disabled={storing} loading={storing}>
            Bekräfta
          </ButtonSuccess>
        </>
      )}
      {view === 'done' && (
        <Typography variant="paragraphInfo">
          Nu är faktagranskningen för denna sida klar! Du kan nu stänga detta fönster.
        </Typography>
      )}
    </div>
  );
};

export default withStyles(s)(ReviewConfirmation);
