// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ContactPersons-contactPersons-NRpp7{border-top:1px solid #dee4ea;margin-bottom:24px;padding-top:24px}.ContactPersons-contactPersons-NRpp7 .ContactPersons-personsWrapper-2eQvd{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap}.ContactPersons-contactPersons-NRpp7 .ContactPersons-personsWrapper-2eQvd .ContactPersons-infoVcard--L1Ri{margin-bottom:32px;margin-top:32px}@media screen and (min-width:541px){.ContactPersons-contactPersons-NRpp7 .ContactPersons-personsWrapper-2eQvd .ContactPersons-infoVcard--L1Ri{-ms-flex:1 1 256px;flex:1 1 256px}}.ContactPersons-contactPersons-NRpp7 .ContactPersons-personsWrapper-2eQvd .ContactPersons-infoVcard--L1Ri:nth-child(odd){margin-right:1.5rem}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"contactPersons": "ContactPersons-contactPersons-NRpp7",
	"personsWrapper": "ContactPersons-personsWrapper-2eQvd",
	"infoVcard": "ContactPersons-infoVcard--L1Ri"
};
module.exports = exports;
