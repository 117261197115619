import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import pickFrom from 'utils/pickFrom';
import extractHighResolutionImage from 'utils/extractHighResolutionImage';

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, data => {
  try {
    const highResolutionImages = pickFrom(data, 'context.highResolutionImages');
    return highResolutionImages?.map(extractHighResolutionImage);
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
