// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TabletLandscapeFooter-tabletLandscapeFooter-3zSTd{display:none;padding:56px}.TabletLandscapeFooter-tabletLandscapeFooter-3zSTd .TabletLandscapeFooter-lowerContentSocial-3JPx_{margin-top:-20px}.TabletLandscapeFooter-tabletLandscapeFooter-3zSTd .TabletLandscapeFooter-lowerContentLinks-ug-oQ{margin-top:25px}.TabletLandscapeFooter-tabletLandscapeFooter-3zSTd .TabletLandscapeFooter-moreNewsRow-2vjM5{margin-top:30px}@media (min-width:820px){.TabletLandscapeFooter-tabletLandscapeFooter-3zSTd{display:block}}@media (min-width:1169px){.TabletLandscapeFooter-tabletLandscapeFooter-3zSTd{display:none}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"tabletLandscapeFooter": "TabletLandscapeFooter-tabletLandscapeFooter-3zSTd",
	"lowerContentSocial": "TabletLandscapeFooter-lowerContentSocial-3JPx_",
	"lowerContentLinks": "TabletLandscapeFooter-lowerContentLinks-ug-oQ",
	"moreNewsRow": "TabletLandscapeFooter-moreNewsRow-2vjM5"
};
module.exports = exports;
