import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';
import { selectors as locationSelectors } from 'ducks/location';
import _values from 'lodash/values';
import clientifyUrl from '../utils/clientifyUrl';

// ----------------------------------------------
// Reused functions - think about moving it out to utils
const sortOrder = {
  italic: 0,
  underline: 0,
  strike: 1,
  subscript: 2,
  superscript: 3,
  bold: 4,
  internal_link: 9,
  inline_link: 10,
};

const sortAnnotations = (a, b) => sortOrder[b.name] - sortOrder[a.name];

const extractFields = fields =>
  fields.reduce((acc, field) => {
    // eslint-disable-next-line dot-notation
    switch (field['__typename']) {
      case 'StoryElementTextField':
        acc[field.name] = {
          rawText: field.value,
          annotations: field.annotations && field.annotations.slice().sort(sortAnnotations),
        };
        break;
      case 'StoryElementBooleanField':
        acc[field.name] = field.booleanValue;
        break;
      default:
        break;
    }
    return acc;
  }, {});

const extractRelation = element => {
  if (element.contentType === 'picture') {
    try {
      const { alttext, BASE_LANDSCAPE, description } = element.fields;
      // eslint-disable-next-line camelcase
      const href_full = pickFrom(BASE_LANDSCAPE, 'href_full');
      return {
        ...element,
        image: {
          alttext,
          description,
          // eslint-disable-next-line camelcase
          href: href_full,
        },
      };
    } catch (error) {
      console.error(error);
    }
  } else if (element.contentType === 'story' || element.contentType === 'storyline') {
    const href = pickFrom(element, 'teaserImage[0].content.fields.BASE_LANDSCAPE.href_full');
    const alttext = pickFrom(element, 'teaserImage[0].content.fields.alttext');
    const description = pickFrom(element, 'teaserImage[0].content.fields.description');
    if (href) {
      return {
        ...element,
        image: {
          alttext,
          description,
          href,
        },
      };
    }
  } else if (element.contentType === 'report') {
    let imageElement = element.coverImage && element.coverImage[0];
    if (!imageElement) {
      imageElement = element.teaserImage && element.teaserImage[0];
    }
    const href = pickFrom(imageElement, 'content.fields.BASE_LANDSCAPE.href_full');
    const alttext = pickFrom(imageElement, 'content.fields.alttext');
    const description = pickFrom(imageElement, 'content.fields.description');
    if (href) {
      return {
        ...element,
        image: {
          alttext,
          description,
          href,
        },
      };
    }
  }
  return element;
};

const extractRelationData = elements => {
  return elements.map(el => {
    try {
      if (el.type === 'relation') {
        const href = pickFrom(el, 'relation.href');
        return {
          relationData: {
            headline: pickFrom(el, 'fields.title.rawText', 'relation.title'),
            href: clientifyUrl(href),
          },
          ...el,
        };
      }
      if (el.type === 'internal_link') {
        const relation = pickFrom(el, 'relation');
        const href = pickFrom(relation, 'href');
        return {
          ...el,
          relation: {
            ...relation,
            href: clientifyUrl(href),
            // href: href?.replace(hostname, ''),
          },
        };
      }
    } catch (error) {
      console.error(error);
    }
    return el;
  });
};

const addAnnotationValueObject = storyline => {
  storyline.forEach(element => {
    _values(element.fields).forEach(field => {
      // eslint-disable-next-line no-unused-expressions
      field?.annotations?.forEach(annotation => {
        const valueItem = storyline.find(el => el.id === annotation.value);
        if (valueItem) {
          // eslint-disable-next-line no-param-reassign
          annotation.value = valueItem;
        }
      });
    });
  });
};

const filterSucceedingRightImages = elements => {
  return elements.reduce((acc, el) => {
    if (acc.length > 0) {
      const lastElement = acc[acc.length - 1];
      if (
        el.type === 'image' &&
        pickFrom(lastElement, 'fields.pushToRightColumnOnDesktop.rawText') === 'true' &&
        pickFrom(el, 'fields.pushToRightColumnOnDesktop.rawText') === 'true'
      ) {
        return acc;
      }
    }
    acc.push(el);
    return acc;
  }, []);
};

// getStructuredStorylineTreeWithHTML
const propsToAttributes = obj => {
  const keys = Object.keys(obj);
  return keys.reduce((acc, key) => (obj[key] ? `${acc} ${key}="${obj[key]}"` : acc), '').trim();
};

const generateHtmlElement = (text, annotation) => {
  switch (annotation.name) {
    case 'bold':
      return `<strong>${text}</strong>`;
    case 'internal_link':
    case 'inline_link': {
      const { fields, relation } = annotation.value;
      const linkProps = {
        target:
          (fields?.newWindow && '_blank') ||
          (annotation.name === 'inline_link' && fields?.newWindow === undefined && '_blank'),
      };
      const href =
        fields?.uri?.rawText ||
        (relation?.contentType === 'document' && relation?.binary?.href) ||
        relation?.image?.href ||
        relation?.href;

      if (href) {
        return `<a href="${href}" ${propsToAttributes(linkProps)}>${text}</a>`;
      }
      return text;
    }

    case 'strike':
      return `<del>${text}</del>`;
    case 'subscript':
      return `<sub>${text}</sub>`;
    case 'superscript':
      return `<sup>${text}</sup>`;
    default:
      return text;
  }
};

const generateHtml = (rawText, annotations) => {
  let html = rawText;
  try {
    // split string into chars and a data structure to work with
    const representation = rawText.split('').map(char => ({ char, annotation: undefined }));
    // add annotations to the elements in the data structure
    annotations.forEach(annotation => {
      for (let i = annotation.index; i < annotation.index + annotation.length; i += 1) {
        const repAnnotaton = representation[i].annotation;
        // only accept one per char
        if (!repAnnotaton) {
          representation[i].annotation = annotation;
        }
      }
    });

    const nodes = [];

    // group chars by annotations
    const lastAcc = representation.reduce((acc, rep) => {
      const lastItem = acc[acc.length - 1];
      // first item, start a new "annotation group"
      if (!lastItem) {
        acc.push(rep);
        return acc;
      }
      // if other annotation compared to last, end the annotation group and start a new
      if (lastItem.annotation !== rep.annotation) {
        nodes.push(acc);
        return [rep];
      }
      // else just keep pushing to annotation group
      acc.push(rep);
      return acc;
    }, []);

    // if last annotation group ain't added to the nodes array, also add it.
    if (lastAcc && lastAcc.length > 0) {
      nodes.push(lastAcc);
    }

    // generate html elements from nodes array
    html = nodes
      .map(node => {
        const { annotation } = node[0];
        let text = node.map(element => element.char).join('');
        text = text?.replace('\n', '<br />') || text; // insert soft line break
        // do this node have annotations? If it does generate html tag for it.
        if (annotation) {
          return generateHtmlElement(text, annotation);
        }
        return text;
      })
      .join('');
  } catch (error) {
    console.error(error);
  }

  return html;
};

const addHtml = elements => {
  return elements.map(element => {
    const { fields } = element;
    const { headline, 'lead-text': leadText, paragraph } = fields;
    // might be able to clean up this code, but pick text data to generate html for depending on field type.
    /*
    if (headline) {
      return {
        ...element,
        type: `${element.type}.special`,
        fields: {
          ...fields,
          headline: {
            ...headline,
            html: generateHtml(headline.rawText, headline.annotations),
          },
        },
      };
    } */

    if (headline) {
      return {
        ...element,
        fields: {
          ...fields,
          headline: {
            ...headline,
            html: generateHtml(headline.rawText, headline.annotations),
          },
        },
      };
    }
    if (leadText) {
      return {
        ...element,
        fields: {
          ...fields,
          'lead-text': {
            ...leadText,
            html: generateHtml(leadText.rawText, leadText.annotations),
          },
        },
      };
    }
    if (paragraph) {
      return {
        ...element,
        fields: {
          ...fields,
          paragraph: {
            ...paragraph,
            html: generateHtml(paragraph.rawText, paragraph.annotations),
          },
        },
      };
    }
    if (element.children) {
      return {
        ...element,
        children: addHtml(element.children),
      };
    }
    return element;
  });
};

const parseLinkButton = button => {
  const { fields, id, type, relation } = button;
  const href = relation ? relation.href : fields?.uri?.rawText;

  return {
    id,
    type,
    alignment: fields?.alignment?.rawText,
    text: fields?.text?.rawText,
    href: clientifyUrl(href),
    variant: fields?.variant?.rawText,
    color: fields?.color?.rawText,
  };
};

const handleLinkButtons = (storylineTree, hostname) => {
  // to be able to have both left and centered buttons:
  //   group only corresponding link_buttons(left buttons then center buttons)
  //   group on the same index as before(in same order)

  const parsedLinkButtons = storylineTree.map(el => {
    let parsedEl = el;
    if (el.type === 'link_button') {
      parsedEl = parseLinkButton(el, hostname);
    }

    return parsedEl;
  });

  const linkButtonGroups = {
    type: 'link_button_group',
    items: parsedLinkButtons.filter(({ type }) => type === 'link_button'),
  };

  const filteredStorylineTree = [...parsedLinkButtons.filter(({ type }) => type !== 'link_button')];

  if (linkButtonGroups.items.length > 0) filteredStorylineTree.push(linkButtonGroups);

  return filteredStorylineTree;
};

const generateStoryline = (box, hostname) => {
  let content;
  if (box?.special?.length > 0) {
    const special = box?.special;
    special.forEach(element => {
      if (element?.content?.contentType === 'special') {
        content = element?.content;
      }
    });
  }
  const { storyline, elements } = content;

  if (!storyline || !elements) return undefined;

  // -------------------------------------------
  // getStorylineSorted
  const sortedElements = elements.slice().sort((a, b) => storyline.indexOf(a.id) - storyline.indexOf(b.id));
  // --------------------------------------------
  // getStructuredStorylineTree
  let storylineElements = sortedElements.map(element => ({
    element,
    id: element.id,
    type: element.type,
    fields: extractFields(element.fields),
    relation: element.relation && extractRelation(element.relation),
    children: element.children,
  }));

  storylineElements = extractRelationData(storylineElements, hostname);

  const storylineElementsCopy = JSON.parse(JSON.stringify(storylineElements));

  storylineElementsCopy.forEach(element => {
    // eslint-disable-next-line no-param-reassign
    element.children = element?.children?.map(childId => {
      return storylineElementsCopy.find(el => el.id === childId);
    });

    if (element.type === 'relations') {
      // eslint-disable-next-line no-param-reassign
      element.type = 'special-relations';
    }
    return element;
  });

  addAnnotationValueObject(storylineElementsCopy);

  let firstLevelElements = storylineElementsCopy.filter(element => storyline.indexOf(element.id) >= 0);

  firstLevelElements = filterSucceedingRightImages(firstLevelElements);

  // -----------------------------------------
  // getStructuredStorylineTreeWithHTML
  const structuredStorylineHTML = addHtml(firstLevelElements);

  // -----------------------------------------
  // getGroupedStorylineTree

  const contentGroup = [];

  const groupedStructuredStorylineHTML = handleLinkButtons(structuredStorylineHTML, hostname);

  // basic grouping for presentation

  groupedStructuredStorylineHTML.forEach(element => {
    contentGroup.push(element);
  });

  return {
    contentGroup,
  };
};

const getBoxContentType = box => {
  let srcTyp;
  if (box?.special?.length > 0) {
    const special = box?.special;
    special.forEach(element => {
      if (element?.content?.contentType === 'picture') {
        srcTyp = element?.content?.contentType;
      }
      if (element?.content?.contentType === 'video') {
        srcTyp = element?.content?.contentType;
      }
    });
  }
  return srcTyp === undefined ? '' : srcTyp;
};

const getBoxSrc = box => {
  let src;
  if (box?.special?.length > 0) {
    const special = box?.special;
    special.forEach(element => {
      if (element?.content?.contentType === 'picture') {
        src = element?.content?.fields?.BASE_LANDSCAPE?.href_full;
      }
      if (element?.content?.contentType === 'video') {
        src = element?.content?.binary?.href;
      }
    });
  }
  return src === undefined ? '' : src;
};
const getGroupOptionsItem = (data, item) => {
  const groupOptions = pickFrom(data, 'groupOptions');
  if (groupOptions !== undefined) {
    const findItemValue = groupOptions.find(e => e.key === item);
    if (findItemValue !== undefined) {
      const itemValue = groupOptions.find(e => e.key === item).value;
      return itemValue;
    }
    return 'default';
  }
  return false;
};
// -------------------------------------
// getHeroStoryline
export default createSelector(cookSelectors.getData, locationSelectors.getHostname, (data, hostname) => {
  try {
    const micrositeBox = [];

    const hero = pickFrom(data, 'context.deskedContent.hero');
    hero.forEach(element => {
      if (element.type === 'micrositeBox') {
        micrositeBox.push(element);
      }
    });

    const micrositeBox1 = micrositeBox[0];
    const micrositeBox2 = micrositeBox[1];

    const showOutlineLogo1Value =
      micrositeBox1?.groupOptions?.length > 0 &&
      getGroupOptionsItem(micrositeBox[0], 'showOutlineLogo') === 'true';
    const showOutlineLogo2Value =
      micrositeBox2?.groupOptions?.length > 0 &&
      getGroupOptionsItem(micrositeBox[1], 'showOutlineLogo') === 'true';
    const textColorOneValue =
      micrositeBox1?.groupOptions?.length > 0 && getGroupOptionsItem(micrositeBox[0], 'textColor');
    const textColorTwoValue =
      micrositeBox2?.groupOptions?.length > 0 && getGroupOptionsItem(micrositeBox[1], 'textColor');

    const backgroundColorOneValue =
      micrositeBox1?.groupOptions?.length > 0 && getGroupOptionsItem(micrositeBox[0], 'backgroundColor');
    const backgroundColorTwoValue =
      micrositeBox2?.groupOptions?.length > 0 && getGroupOptionsItem(micrositeBox[1], 'backgroundColor');

    const verticalAlignmentOneValue =
      micrositeBox1?.groupOptions?.length > 0 && getGroupOptionsItem(micrositeBox[0], 'verticalAlignment');
    const verticalAlignmentTwoValue =
      micrositeBox2?.groupOptions?.length > 0 && getGroupOptionsItem(micrositeBox[1], 'verticalAlignment');

    const horizontalAlignmentOneValue =
      micrositeBox1?.groupOptions?.length > 0 && getGroupOptionsItem(micrositeBox[0], 'horizontalAlignment');
    const horizontalAlignmentTwoValue =
      micrositeBox2?.groupOptions?.length > 0 && getGroupOptionsItem(micrositeBox[1], 'horizontalAlignment');

    const marginsOneValue =
      micrositeBox1?.groupOptions?.length > 0 && getGroupOptionsItem(micrositeBox[0], 'margins');
    const marginsTwoValue =
      micrositeBox2?.groupOptions?.length > 0 && getGroupOptionsItem(micrositeBox[1], 'margins');

    const showOutlineLogo1 = showOutlineLogo1Value === false ? '' : showOutlineLogo1Value;
    const showOutlineLogo2 = showOutlineLogo2Value === false ? '' : showOutlineLogo2Value;

    const textColorOne = textColorOneValue === false ? '' : textColorOneValue;
    const textColorTwo = textColorTwoValue === false ? '' : textColorTwoValue;

    const backgroundColorOne = backgroundColorOneValue === false ? '' : backgroundColorOneValue;
    const backgroundColorTwo = backgroundColorTwoValue === false ? '' : backgroundColorTwoValue;

    const verticalAlignmentOne = verticalAlignmentOneValue === false ? '' : verticalAlignmentOneValue;
    const verticalAlignmentTwo = verticalAlignmentTwoValue === false ? '' : verticalAlignmentTwoValue;

    const horizontalAlignmentOne = horizontalAlignmentOneValue === false ? '' : horizontalAlignmentOneValue;
    const horizontalAlignmentTwo = horizontalAlignmentTwoValue === false ? '' : horizontalAlignmentTwoValue;

    const marginsOne = marginsOneValue === false ? '' : marginsOneValue;
    const marginsTwo = marginsTwoValue === false ? '' : marginsTwoValue;

    const srcTypeOne = getBoxContentType(micrositeBox1);
    const srcTypeTwo = getBoxContentType(micrositeBox2);
    const srcOne = getBoxSrc(micrositeBox1);
    const srcTwo = getBoxSrc(micrositeBox2);

    return {
      box1: micrositeBox1?.special?.length > 0 && generateStoryline(micrositeBox1, hostname),
      marginsOne,
      showOutlineLogo1,
      textColorOne,
      backgroundColorOne,
      verticalAlignmentOne,
      horizontalAlignmentOne,
      srcTypeOne,
      srcOne,
      box2: micrositeBox2?.special?.length > 0 && generateStoryline(micrositeBox2, hostname),
      marginsTwo,
      showOutlineLogo2,
      textColorTwo,
      backgroundColorTwo,
      verticalAlignmentTwo,
      horizontalAlignmentTwo,
      srcTypeTwo,
      srcTwo,
    };
  } catch (error) {
    console.error(error);
    return {};
  }
});
