// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SpecialSocialBall-socialBall-wqLr9{background-color:#33414e;border-radius:50%;display:inline-block;height:48px;opacity:.8;position:relative;-webkit-transition:all .2s ease-in-out;transition:all .2s ease-in-out;width:48px}.SpecialSocialBall-darkMode-3pJpy{background-color:#fff;opacity:.8}.SpecialSocialBall-darkMode-3pJpy .SpecialSocialBall-image-2H1kX,.SpecialSocialBall-socialBall-wqLr9 .SpecialSocialBall-image-2H1kX{left:50%;position:absolute;top:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.SpecialSocialBall-darkMode-3pJpy.SpecialSocialBall-email-1rGMw:hover,.SpecialSocialBall-socialBall-wqLr9.SpecialSocialBall-email-1rGMw:hover{background-color:#ea8f12}.SpecialSocialBall-darkMode-3pJpy.SpecialSocialBall-facebook-GDh34:hover,.SpecialSocialBall-socialBall-wqLr9.SpecialSocialBall-facebook-GDh34:hover{background-color:#1877f2}.SpecialSocialBall-darkMode-3pJpy.SpecialSocialBall-twitter-34zvQ:hover,.SpecialSocialBall-socialBall-wqLr9.SpecialSocialBall-twitter-34zvQ:hover{background-color:#1da1f2}.SpecialSocialBall-darkMode-3pJpy.SpecialSocialBall-linkedin-13JW8:hover,.SpecialSocialBall-socialBall-wqLr9.SpecialSocialBall-linkedin-13JW8:hover{background-color:#007bb5}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"socialBall": "SpecialSocialBall-socialBall-wqLr9",
	"darkMode": "SpecialSocialBall-darkMode-3pJpy",
	"image": "SpecialSocialBall-image-2H1kX",
	"email": "SpecialSocialBall-email-1rGMw",
	"facebook": "SpecialSocialBall-facebook-GDh34",
	"twitter": "SpecialSocialBall-twitter-34zvQ",
	"linkedin": "SpecialSocialBall-linkedin-13JW8"
};
module.exports = exports;
