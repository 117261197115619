import { useEffect } from 'react';

const GTMDataLayer = ({ data }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!window.dataLayer) window.dataLayer = [];
      window.dataLayer.push(data);
    }
  }, [data.pageId]);
  return null;
};

export default GTMDataLayer;
