import { SIZES } from './constants';

const hasSizes = option => SIZES.some(size => option?.[size]);

const defaultOptions = {
  useGenericValueAtBreakpoint: false,
};

const getCssSizesVars = ({ object, cssProperty, cssVarPrefix, options: inputOptions = {} }) => {
  const options = {
    ...defaultOptions,
    ...inputOptions,
  };

  const sizeVars = !object[cssProperty]
    ? {}
    : SIZES.reduce((acc, current) => {
        return {
          ...acc,
          ...(object[cssProperty][current]
            ? { [`${cssVarPrefix}${current}`]: object[cssProperty][current] }
            : {}),
        };
      }, {});

  if (options.useGenericValueAtBreakpoint && !hasSizes(object[cssProperty]) && object[cssProperty] !== '') {
    sizeVars[`${cssVarPrefix}${options.useGenericValueAtBreakpoint}`] = object[cssProperty];
  }

  return sizeVars;
};

export default getCssSizesVars;
