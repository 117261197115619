import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import ButtonSuccess from '../../../../atoms/buttons/ButtonSuccess/ButtonSuccess';
import ButtonPrimary from '../../../../atoms/buttons/ButtonPrimary/ButtonPrimary';
import ms from '../../GOAContainer.css';
import s from './ColumnFaktagranskning.css';

const ColumnFaktagranskning = ({
  reviewData,
  reviewedState,
  pendingReviewDate,
  reviewing,
  amITheLastReviewer,
  amITheCurrentReviewer,
  handleYesClicked,
  handleNoClicked,
  handleChangeReviewerClicked,
}) => {
  return (
    <>
      <Typography variant="h4" className={ms.headline}>
        Faktagranskning
      </Typography>
      {reviewedState === 'reviewed' && (
        <Typography variant="paragraphInfo">
          Tack för att du faktagranskat denna sida! Du har angett att innehållet på sidan är korrekt.
          <br />
          <br />
          Du kommer få ett nytt mejl när det är dags att granska sidan nästa gång ({pendingReviewDate}).
        </Typography>
      )}
      {reviewedState === 'notReviewed' && (
        <>
          <>
            {!reviewData?.reviewData?.article?.ongoingReviewStartDate && (
              <Typography variant="paragraphSmall">
                <strong>Är innehållet på denna sida korrekt och aktuellt?</strong>
              </Typography>
            )}
            {reviewData?.reviewData?.article?.ongoingReviewStartDate && (
              <Typography variant="paragraphInfo" gutterBottom>
                Ändringsförslag har skickats till redaktionen (
                {reviewData?.reviewData?.article?.ongoingReviewStartDate}) och de arbetar med att införa dem.
                Du kan komplettera med fler ändringsförslag nedan.
              </Typography>
            )}
            <div className={ms.btnContainer}>
              {!reviewData?.reviewData?.article?.ongoingReviewStartDate && (
                <>
                  <ButtonSuccess
                    disabled={
                      reviewing ||
                      reviewData?.reviewData?.article?.ongoingReviewStartDate ||
                      !reviewData?.reviewData?.article?.canBeReviewedByCurrentUser
                    }
                    loading={reviewing}
                    onClick={() => handleYesClicked()}
                  >
                    <span>Ja, allt stämmer</span>
                  </ButtonSuccess>
                  <br />
                  <ButtonPrimary
                    disabled={!reviewData?.reviewData?.article?.canBeReviewedByCurrentUser}
                    onClick={() => handleNoClicked()}
                  >
                    Nej, jag vill ändra något
                  </ButtonPrimary>
                </>
              )}
              {reviewData?.reviewData?.article?.ongoingReviewStartDate && (
                <ButtonSuccess onClick={() => handleNoClicked()}>
                  Komplettera med fler ändringsförslag
                </ButtonSuccess>
              )}
            </div>
          </>
          {!reviewData?.reviewData?.article?.ongoingReviewStartDate && (
            <>
              {!reviewData?.reviewData?.article?.canBeReviewedByCurrentUser && (
                <Typography variant="paragraphSmall" gutterBottom>
                  Du kan inte faktagranska denna sida eftersom nuvarande faktagranskare är{' '}
                  <strong>{reviewData?.reviewData?.currentReviewer?.name}.</strong>{' '}
                  <span onClick={handleChangeReviewerClicked} className={s.link}>
                    Byt faktagranskare
                  </span>
                </Typography>
              )}
              {reviewData?.reviewData?.reviews?.length > 0 && (
                <Typography variant="paragraphSmall">
                  Denna sida granskades senast {reviewData?.reviewData?.reviews[0]?.reviewDate} av{' '}
                  <strong>
                    {amITheLastReviewer ? 'dig' : reviewData?.reviewData?.reviews[0]?.reviewer?.name}.
                  </strong>
                </Typography>
              )}
              {reviewData?.reviewData?.reviews?.length === 0 && (
                <Typography variant="paragraphSmall">Denna sida har ännu inte granskats.</Typography>
              )}
              {pendingReviewDate && (
                <Typography variant="paragraphSmall">
                  Deadline för nästa granskning: {pendingReviewDate}.
                </Typography>
              )}
            </>
          )}
          {reviewData?.reviewData?.currentReviewer &&
            reviewData?.reviewData?.article?.canBeReviewedByCurrentUser && (
              <Typography variant="paragraphSmall">
                {amITheCurrentReviewer && (
                  <span>
                    <strong>Du</strong> är nuvarande faktagranskare{' '}
                  </span>
                )}
                {!amITheCurrentReviewer && (
                  <span>
                    Nuvarande faktagranskare är{' '}
                    <strong>{reviewData?.reviewData?.currentReviewer?.name}.</strong>{' '}
                  </span>
                )}
                {!reviewData?.reviewData?.article?.ongoingReviewStartDate && (
                  <span onClick={handleChangeReviewerClicked} className={s.link}>
                    Byt faktagranskare
                  </span>
                )}
              </Typography>
            )}
        </>
      )}
    </>
  );
};

export default withStyles(ms, s)(ColumnFaktagranskning);
