/* eslint-disable css-modules/no-undef-class */

/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import BusinessPanelForm from 'components/organisms/BusinessPanelForm';
import renderStorylineElement from 'utils/renderStorylineElement';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import boostrapGrid from 'style/bootstrap-grid.css';
import classnames from 'classnames';
import s from './BusinessPanelFormPage.css';

// eslint-disable-next-line no-unused-vars
const BusinessPanelFormPage = ({ pageData, groupedStorylineTree }) => (
  <div className={s.businessPanelFormPage}>
    <div className={classnames(boostrapGrid.container)}>
      <div className={classnames(boostrapGrid.row)}>
        <div className={getBootstrapColumns('22,1', '22,1', '12,6')}>
          {groupedStorylineTree?.headGroup.map(el => renderStorylineElement(el, false))}
          {groupedStorylineTree?.contentGroup.map(el => renderStorylineElement(el, false))}
          <BusinessPanelForm />
        </div>
      </div>
    </div>
  </div>
);

export default withStyles(s, boostrapGrid)(BusinessPanelFormPage);
