// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FocusAreasPage-focusAreasPage-1quwX .FocusAreasPage-focusAreasListSmall-2qQ_L{padding:2.5rem 0 0}.FocusAreasPage-focusAreasPage-1quwX .FocusAreasPage-focusAreasListBigContainer-aUML0{background-color:#dee4ea}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"focusAreasPage": "FocusAreasPage-focusAreasPage-1quwX",
	"focusAreasListSmall": "FocusAreasPage-focusAreasListSmall-2qQ_L",
	"focusAreasListBigContainer": "FocusAreasPage-focusAreasListBigContainer-aUML0"
};
module.exports = exports;
