import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';

const MAKE_TYPE_MICROSITE_TEMPORARILY_WHITE = true;

export default createSelector(cookSelectors.getData, data => {
  return (
    data?.context?.contentType === 'blogPost' ||
    data?.context?.rootGroup?.type === 'blog' ||
    (MAKE_TYPE_MICROSITE_TEMPORARILY_WHITE && data?.context?.rootGroup?.type === 'microsite')
  );
});
