import React from 'react';
import { useSelector } from 'react-redux';
import { getFlexibleSection, getMetadata } from './duck/selectors';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';
import FlexibleContent from './components/FlexibleContent/FlexibleContent';

const FlexibleSection = () => {
  const allHeros = useSelector(getFlexibleSection('hero'));
  const allAvsnitt = useSelector(getFlexibleSection('content'));
  const metadata = useSelector(getMetadata);

  const allItems = [...allHeros, ...allAvsnitt];

  return (
    <>
      <OGShareTags metadata={metadata} />
      {allItems.map((avsnitt, i) => (
        <FlexibleContent key={avsnitt?.id} avsnitt={avsnitt} zIndex={allItems.length - i} />
      ))}
    </>
  );
};

export default FlexibleSection;
