import { connect } from 'react-redux';
import ArticlePage from 'components/pages/ArticlePage';
import getPublishedDate from 'selectors/getPublishedDate';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getRelatedItems from 'selectors/getRelatedItems';
import getArticleAuthors from 'selectors/getArticleAuthors';
import getMetdata from 'selectors/metadata/getMetdata';
import getTags from 'selectors/getTags';
import getPageLabel from 'selectors/getPageLabel';
import getAlsoRead from 'selectors/getAlsoRead';
import getTranslation from '../../selectors/getTranslation';

const mapStateToProps = state => {
  return {
    publishedDate: getPublishedDate(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
    relatedItems: getRelatedItems(state),
    authors: getArticleAuthors(state),
    metadata: getMetdata(state),
    pageLabel: getPageLabel(state),
    tags: getTags(state),
    alsoRead: getAlsoRead(state),
    translation: getTranslation(state),
  };
};

const mapDispatchToProps = undefined;

const ArticlePageContainer = connect(mapStateToProps, mapDispatchToProps)(ArticlePage);

export default ArticlePageContainer;
