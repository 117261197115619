import { connect } from 'react-redux';
import InfoPage from 'components/pages/InfoPage';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getRelatedItems from 'selectors/getRelatedItems';
import getContactPersons from 'selectors/getContactPersons';
import getArticleAuthors from 'selectors/getArticleAuthors';
import getMetdata from 'selectors/metadata/getMetdata';
import getArticleTags from 'selectors/getArticleTags';

const mapStateToProps = state => {
  return {
    groupedStorylineTree: getGroupedStorylineTree(state),
    relatedItems: getRelatedItems(state),
    authors: getArticleAuthors(state),
    metadata: getMetdata(state),
    contactPersons: getContactPersons(state),
    tags: getArticleTags(state),
  };
};

const mapDispatchToProps = undefined;

const InfoPageContainer = connect(mapStateToProps, mapDispatchToProps)(InfoPage);

export default InfoPageContainer;
