import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import parseVideo from 'utils/parseVideo';
import parseVideoChat from 'utils/parseVideoChat';
import classnames from 'classnames';
import Typography from 'components/atoms/Typography';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './VideoBlock.css';

const VideoBlock = ({
  classes,
  chatDate,
  relatedVideo,
  uri,
  showVimeoChat,
  caption,
  pageVideo,
  stretched,
  inHeadGroup,
  twoColumnLayout,
  ...other
}) => {
  const [isChatWithinTimeFrame, setIsChatWithinTimeFrame] = useState(false);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  useEffect(() => {
    if (!isChatWithinTimeFrame) {
      setIsIframeLoaded(false);
    }
  }, [isChatWithinTimeFrame]);

  useEffect(() => {
    if (showVimeoChat) {
      const intervalId = setInterval(() => {
        const now = new Date();
        const isWithinTimeFrame = !!(
          chatDate.start &&
          chatDate.end &&
          now >= chatDate.start &&
          now <= chatDate.end
        );

        setIsChatWithinTimeFrame(isWithinTimeFrame);
        if (now > chatDate.end) {
          clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
      }, 5000);
    }
  }, []);

  const classNames = classnames(
    s.videoBlock,
    stretched && s.stretched,
    inHeadGroup && s.inHeadGroup,
    twoColumnLayout && s.twoColumnLayout,
    classes
  );
  const settings = {
    frameBorder: '0',
    webkitallowfullscreen: 'true',
    mozallowfullscreen: 'true',
    scrolling: 'no',
  };

  const renderStorylineVideo = () => {
    const videoObj = parseVideo(uri);

    let videoSrc = null;
    if (videoObj.type === 'youtube') {
      videoSrc = `//www.youtube.com/embed/${videoObj.id}`;
    } else if (videoObj.type === 'vimeo' && videoObj.id === 'event') {
      videoSrc = `//vimeo.com/event/${videoObj.idrecurringone}/embed/${videoObj.idrecurringtwo}`;
    } else {
      videoSrc = `//player.vimeo.com/video/${videoObj.id}`;
    }

    return (
      <>
        <div className={s.videoWrapper}>
          <iframe {...settings} src={videoSrc} title={caption || 'title'} allowFullScreen />
        </div>
        {caption && (
          <div className={s.textWrapper}>
            <Typography variant="contentTypeDate">{caption}</Typography>
          </div>
        )}
      </>
    );
  };

  const renderVideo = video => {
    const videoSrc =
      video.streamingProvider === 'youtube'
        ? `//www.youtube.com/embed/${video.id}`
        : `//player.vimeo.com/video/${video.id}`;
    return (
      <>
        <div className={s.videoWrapper}>
          <iframe {...settings} src={videoSrc} title={video.caption || 'title'} allowFullScreen />
        </div>
        {video.caption && (
          <div className={s.textWrapper}>
            <Typography variant="contentTypeDate">{video.caption}</Typography>
          </div>
        )}
      </>
    );
  };

  const renderChat = () => {
    const videoChatObj = parseVideoChat(uri);
    let videoSrc = null;
    if (videoChatObj.type === 'onetime') {
      videoSrc = `//vimeo.com/live-chat/${videoChatObj.onetimevideochatid}/${videoChatObj.onetimeprivatelink}`;
    } else if (videoChatObj.type === 'recurring') {
      videoSrc = `//vimeo.com/event/${videoChatObj.recurringvideochatid}/chat/${videoChatObj.recurringprivatelink}`;
    }
    return (
      <AnimatePresence>
        {showVimeoChat && isChatWithinTimeFrame && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: isIframeLoaded ? 1 : 0, height: isIframeLoaded ? 'auto' : 0 }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 1 }}
          >
            <div className={s.chatWrapper}>
              <iframe
                onLoad={() => setIsIframeLoaded(true)}
                src={videoSrc}
                {...settings}
                title={`${caption}.chat` || 'title.chat'}
                allowFullScreen
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  return (
    <div className={classNames} {...other}>
      {uri && renderStorylineVideo()}
      {relatedVideo && renderVideo(relatedVideo)}
      {pageVideo && renderVideo(pageVideo)}
      {renderChat()}
    </div>
  );
};

export default withStyles(s)(VideoBlock);
