import { format, isToday } from 'date-fns';
import { enGB, sv } from 'date-fns/locale';
import { selectors as cookSelectors } from 'ducks/cook';
import { createSelector } from 'reselect';
import getTranslation from './getTranslation';

export default createSelector(cookSelectors.getData, getTranslation, (data, { LANG }) => {
  const dateString = data?.context?.published;

  if (!dateString) {
    return undefined;
  }

  const date = new Date(dateString);
  const humanReadableDate = isToday(date)
    ? LANG === 'sv'
      ? 'Idag'
      : 'Today'
    : format(date, 'd MMMM yyyy', { locale: LANG === 'sv' ? sv : enGB });

  return {
    string: humanReadableDate,
    iso: dateString,
  };
});
