function validateOrganizationNumber(organizationNumber) {
  if (!organizationNumber.match(/^(\d{1})(\d{5})-(\d{4})$/)) {
    return false;
  }

  const group = RegExp.$1;
  const controldigits = RegExp.$3;
  const alldigits = group + RegExp.$2 + controldigits;

  let nn = '';

  let n;
  for (n = 0; n < alldigits.length; n++) {
    nn += (((n + 1) % 2) + 1) * alldigits.substring(n, n + 1);
  }

  let checksum = 0;
  for (n = 0; n < nn.length; n++) {
    checksum += nn.substring(n, n + 1) * 1;
  }

  return checksum % 10 === 0;
}

export default validateOrganizationNumber;
