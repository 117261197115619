import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import Link from 'containers/atoms/LinkContainer';
import boostrapGrid from 'style/bootstrap-grid.css';
import { format } from 'date-fns';
import { enGB, sv } from 'date-fns/locale';
import s from './WebinarBox.css';
import Translate from '../../../containers/atoms/TranslateContainer';
import PlayButtonImage from '../../atoms/PlayButtonImage';
import useTranslation from '../../../utils/hooks/useTranslation';

const WebinarBox = ({ href, headline, date, image }) => {
  const { IS_SWEDISH } = useTranslation();

  return (
    <div className={classnames(boostrapGrid.container)}>
      <div className={classnames(boostrapGrid.row, s.wrapper)}>
        <PlayButtonImage image={image} href={href} />
      </div>
      <div className={classnames(boostrapGrid.row, s.wrapper)}>
        <Typography variant="h5" gutterBottom darkMode component={Link} to={href} classes={s.link}>
          {headline}
        </Typography>
        <Typography variant="imageCaption" darkMode>
          <Translate>EVENTS.WAS_AIRED</Translate>:{' '}
          {format(new Date(date), 'yyyy-MM-dd', { locale: IS_SWEDISH ? sv : enGB })}
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(s, boostrapGrid)(WebinarBox);
