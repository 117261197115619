// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ArticlePage-article_container-1lzzF .ArticlePage-stickyContainer-1cAJN{position:relative}@media screen and (min-width:541px){.ArticlePage-article_container-1lzzF .ArticlePage-stickyContainer-1cAJN{min-height:21rem}}.ArticlePage-faq-2IIsb{display:none;padding-bottom:64px;padding-top:34px}.ArticlePage-alsoReadWrapper-1P1zi{background:#eff2f5;padding-bottom:24px;padding-top:40px}.ArticlePage-secondaryWrapper-oY2We{padding-left:0;padding-right:0}.ArticlePage-fadeOut-1XVQz{opacity:0;-webkit-transition:visibility 0s .5s,opacity .5s linear;transition:visibility 0s .5s,opacity .5s linear;visibility:hidden}.ArticlePage-fadeIn-Xi3WR{opacity:1;-webkit-transition:opacity .5s linear;transition:opacity .5s linear;visibility:visible}@media (min-width:541px){.ArticlePage-secondaryWrapper-oY2We{padding-left:56px;padding-right:56px}}@media (min-width:820px){.ArticlePage-secondaryWrapper-oY2We{padding-left:0;padding-right:0}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"article_container": "ArticlePage-article_container-1lzzF",
	"stickyContainer": "ArticlePage-stickyContainer-1cAJN",
	"faq": "ArticlePage-faq-2IIsb",
	"alsoReadWrapper": "ArticlePage-alsoReadWrapper-1P1zi",
	"secondaryWrapper": "ArticlePage-secondaryWrapper-oY2We",
	"fadeOut": "ArticlePage-fadeOut-1XVQz",
	"fadeIn": "ArticlePage-fadeIn-Xi3WR"
};
module.exports = exports;
