// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileCardWithModal-profileCardWithModal-2bqNx{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.ProfileCardWithModal-profileCardWithModal-2bqNx .ProfileCardWithModal-imageWrapper-3rd-R{-ms-flex-negative:0;border:1px solid #dee4ea;border-radius:50%;flex-shrink:0;height:90px;margin-right:24px;-webkit-mask-image:radial-gradient(#fff,#000);mask-image:radial-gradient(#fff,#000);overflow:hidden;position:relative;width:90px}.ProfileCardWithModal-profileCardWithModal-2bqNx .ProfileCardWithModal-imageWrapper-3rd-R.ProfileCardWithModal-noUser-4G0Xn{background-color:#eff2f5;color:#bcc3c9}.ProfileCardWithModal-profileCardWithModal-2bqNx .ProfileCardWithModal-imageWrapper-3rd-R>:first-child{left:50%;position:absolute;top:50%;-webkit-transform:translate3d(-50%,-50%,0);transform:translate3d(-50%,-50%,0)}.ProfileCardWithModal-profileCardWithModal-2bqNx .ProfileCardWithModal-name-338Lo{color:#1f272f;font-family:LabGrotesque-Bold,sans-serif;font-size:20px;line-height:28px;margin-bottom:4px}.ProfileCardWithModal-profileCardWithModal-2bqNx .ProfileCardWithModal-title-21TXD{color:#62717f;margin-bottom:4px}.ProfileCardWithModal-profileCardWithModal-2bqNx .ProfileCardWithModal-title-21TXD,.ProfileCardWithModal-profileCardWithModal-2bqNx .ProfileCardWithModal-viewContacts-1MwYK{font-family:LabGrotesque-Regular,sans-serif;font-size:16px;line-height:26px}.ProfileCardWithModal-profileCardWithModal-2bqNx .ProfileCardWithModal-viewContacts-1MwYK{background-color:inherit;border:0;color:#3568a7;cursor:pointer;padding:0;text-decoration:underline}.ProfileCardWithModal-profileCardWithModal-2bqNx .ProfileCardWithModal-viewContacts-1MwYK.ProfileCardWithModal-link-3OR2f{color:#000;text-decoration:none}@media screen and (min-width:820px){.ProfileCardWithModal-profileCardWithModal-2bqNx .ProfileCardWithModal-viewContacts-1MwYK.ProfileCardWithModal-link-3OR2f:hover{text-decoration:underline}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"profileCardWithModal": "ProfileCardWithModal-profileCardWithModal-2bqNx",
	"imageWrapper": "ProfileCardWithModal-imageWrapper-3rd-R",
	"noUser": "ProfileCardWithModal-noUser-4G0Xn",
	"name": "ProfileCardWithModal-name-338Lo",
	"title": "ProfileCardWithModal-title-21TXD",
	"viewContacts": "ProfileCardWithModal-viewContacts-1MwYK",
	"link": "ProfileCardWithModal-link-3OR2f"
};
module.exports = exports;
