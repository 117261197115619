// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MobileMenu-mobileMenu-8Pz4x{background-color:#faf9f7;height:calc(100% - var(--header-height));left:0;overflow-y:scroll;position:fixed;top:var(--header-height);width:100%;z-index:9999}@media (min-width:1169px){.MobileMenu-mobileMenu-8Pz4x{display:none}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"mobileMenu": "MobileMenu-mobileMenu-8Pz4x"
};
module.exports = exports;
