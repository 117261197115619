import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import s from './FilterBall.css';

const FilterBall = ({ classes, count, selected, selectedBlock, children, ...other }) => {
  const classNames = classnames(s.filterBall, selected && s.selected, classes);
  return (
    <>
      {!selectedBlock && (
        <button type="button" className={classNames} {...other}>
          {children}
          {count !== undefined && <span className={s.count}>{` (${count})`}</span>}
        </button>
      )}
      {selectedBlock && selected && (
        <button type="button" className={classNames} {...other}>
          {children}
          <FontAwesomeIcon
            icon={faTimes}
            className={s.removeIcon}
            style={{ width: 20, height: 20, marginLeft: '6px', marginBottom: '-3px' }}
          />
        </button>
      )}
    </>
  );
};

export default withStyles(s)(FilterBall);
