import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
// eslint-disable-next-line css-modules/no-unused-class
import classnames from 'classnames';
import boostrapGrid from 'style/bootstrap-grid.css';
import { useSelector } from 'react-redux';
import s from './PageContent.css';
import getRootGroupType from '../../../selectors/getRootGroupType';
import getShowSpecial from '../../../selectors/getShowSpecial';

const PageContent = ({ PageType, darkMode, darkModeType, isWhiteBg }) => {
  // Is Flexible Section
  const { type: rootGroupType } = useSelector(getRootGroupType);
  const isFlexibleSection = rootGroupType === 'flexibleSection';

  // Is Startpage and Showspecial is turned on
  const showSpecial = useSelector(getShowSpecial);

  const noPaddingTop = isFlexibleSection || showSpecial;

  return (
    <div
      className={classnames(s.pageContent, {
        [s.noPaddingTop]: noPaddingTop,
        [s.whiteBg]: isWhiteBg,
        [s.showMediaQuery]: process.env.NODE_ENV === 'development',
      })}
    >
      <PageType darkMode={darkMode} darkModeType={darkModeType} />
    </div>
  );
};

export default withStyles(boostrapGrid, s)(PageContent);
