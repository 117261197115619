import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import s from './PlayButtonImage.css';
import defaultImage from './defaultImage.png';
import LinkContainer from '../../../containers/atoms/LinkContainer';
import ResponsiveImage from '../ResponsiveImage';

const IconContainer = ({ children, noLink, href }) => {
  return noLink ? children : <LinkContainer to={href}>{children}</LinkContainer>;
};

const PlayButtonImage = ({ image, href, noLink }) => {
  // TODO: Add width and height to ResponsiveImage. Currently this breaks the layout, fix this component so it renders properly
  return (
    <div className={s.imageWrapper}>
      <div className={s.iconContainer}>
        <IconContainer noLink={noLink} href={href}>
          <div className={s.iconWrapper}>
            <FontAwesomeIcon icon={faCaretRight} className={s.icon} />
          </div>
        </IconContainer>
      </div>
      {image ? (
        <ResponsiveImage src={image?.href} alt={image?.alt} />
      ) : (
        <ResponsiveImage src={defaultImage} alt="default" />
      )}
    </div>
  );
};

export default withStyles(s)(PlayButtonImage);
