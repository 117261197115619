import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import Link from '../../../containers/atoms/LinkContainer';
import s from './EventItem.css';

const EventItem = props => {
  // eslint-disable-next-line no-unused-vars
  const { href, startDate, endDate, title, label, type } = props;

  return (
    <div className={s.eventItem}>
      <Typography className={s.label}>
        {label}
        {/* <LiveSplash /> */}
      </Typography>
      <Typography variant="readMoreItem" component={Link} to={href} classes={s.title} darkMode>
        {title}
      </Typography>
    </div>
  );
};

export default withStyles(s)(EventItem);
