import useAuth from './useAuth';
import useUser from './useUser';

const useAuthenticationLoading = () => {
  const { authQuery, acquireTokenSilentQuery } = useAuth();
  const { userQuery } = useUser();
  return userQuery.isLoading || acquireTokenSilentQuery.isLoading || authQuery.isLoading;
};

export default useAuthenticationLoading;
