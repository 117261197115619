import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import LinkContainer from 'containers/atoms/LinkContainer';
import Typography from 'components/atoms/Typography';
import s from './Author.css';
import useTranslate from '../../../utils/hooks/useTranslate';

const Author = ({ classes, authors, title, ...other }) => {
  const translate = useTranslate();

  if (!authors || authors.length === 0) return null;
  const classNames = classnames(s.author, classes);
  return (
    <div className={classNames} {...other}>
      <Typography variant="caption" classes={s.writtenBy}>
        {title || translate('AUTHOR')}
      </Typography>
      {authors.map(author =>
        author.href ? (
          <LinkContainer key={author.name} className={s.authorName} to={author.href}>
            {author.name}
          </LinkContainer>
        ) : (
          <span key={author.name} className={s.authorName}>
            {author.name}
          </span>
        )
      )}
    </div>
  );
};

export default withStyles(s)(Author);
