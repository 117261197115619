import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Balk.css';

const Balk = ({ color = '', children }) => {
  const getColorValue = () => {
    if (color === 'orange') {
      return '#ea8f12';
    }
    if (color === 'red') {
      return '#b12f26';
    }
    if (color === 'darkBlue') {
      return '#33414e';
    }
    if (color === 'steel') {
      return '#62717f';
    }
    if (color === 'lightBlue') {
      return '#eff2f5';
    }
    if (color === 'creamyWhite') {
      return '#faf9f7';
    }
    return '#b12f26';
  };

  const backgroundColorStyle = {
    backgroundColor: getColorValue(),
  };

  return (
    <div className={s.balk} style={backgroundColorStyle}>
      <div className={s.balkContent}>{children}</div>
    </div>
  );
};

export default withStyles(s)(Balk);
