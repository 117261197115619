import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import Typography from 'components/atoms/Typography';
import s from './AgendaItem.css';

const AgendaItem = ({ classes, time, headline, description, ...other }) => {
  const classNames = classnames(s.agendaItem, classes);
  return (
    <div className={classNames} {...other}>
      <div className={s.agendaTimeWrapper}>
        <FontAwesomeIcon icon={faClock} className={s.agendaItemIcon} />
        <Typography component="span" light>
          {time}
        </Typography>
      </div>
      <Typography variant="h3" gutterBottom={description !== undefined}>
        {headline}
      </Typography>
      {description && (
        <Typography variant="paragraphSmall" gutterBottom={description !== undefined}>
          {description}
        </Typography>
      )}
    </div>
  );
};

export default withStyles(s)(AgendaItem);
