import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from '../ducks/location';

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, data => {
  const resolutionSectionId = data?.resolution?.section?.id;

  return {
    resolutionSectionId,
  };
});
