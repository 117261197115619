import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';

export default createSelector(cookSelectors.getData, data => {
  const ignoredContentTypes = ['blogPost'];
  const ignoredPages = [
    'search',
    'material',
    'english_publications',
    'fokus-pa-skatterna-sokresultat',
    'kompetensforsorjning-sokresultat',
    'min-sida',
    'ert-valfardsskapande',
    'hantera-nyhetsbrev',
    'medlemsbevis',
    'medlemsportalen',
    'snmo-konto',
  ];
  const ignoredRootGroups = ['blog', 'flexibleSection', 'snStartPage'];

  try {
    const showBreadcrumbsInHero =
      data?.context?.rootGroup?.groupOptions?.find(opt => opt?.key === 'showBreadcrumbsInHero')?.value ===
      'true';

    if (showBreadcrumbsInHero) {
      return true;
    }

    return (
      !ignoredPages.includes(data?.resolution?.section?.uniqueName) &&
      !ignoredContentTypes.includes(data?.context?.contentType) &&
      !ignoredRootGroups.includes(data?.context?.rootGroup?.type)
    );
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
