// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RelatedItem-relatedItem-2u-5G{border-top:1px solid #dee4ea;color:#33414e;display:block;padding:16px;text-decoration:none;-webkit-transition:background-color .2s ease-in-out;transition:background-color .2s ease-in-out}@media (min-width:541px){.RelatedItem-relatedItem-2u-5G{padding:12px}}.RelatedItem-desktop-3xpnc{display:none}@media (min-width:541px){.RelatedItem-desktop-3xpnc{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}}.RelatedItem-mobile-27agG{display:block}@media (min-width:541px){.RelatedItem-mobile-27agG{display:none}}.RelatedItem-downloadButton-bfEOE{margin-top:12px}.RelatedItem-mobile-27agG .RelatedItem-upperWrapper-152ps{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.RelatedItem-relatedItem-2u-5G:hover{background-color:#f7f7f7}.RelatedItem-relatedItem-2u-5G.RelatedItem-noBorder-yXEw_{border-top:none}.RelatedItem-title-3UN7R{color:#33414e;font-family:LabGrotesque-Medium,sans-serif;line-height:26px;text-decoration:none}.RelatedItem-textWrapper-36bLp{-ms-flex-positive:1;flex-grow:1;margin-right:16px}.RelatedItem-fileIcon-l3qo-{color:#ea8f12;height:32px;margin-right:10px}@media (min-width:541px){.RelatedItem-fileIcon-l3qo-{height:26px;margin-right:0}}.RelatedItem-preImageWrapper-2vw1j{-ms-flex:0 0 53px;flex:0 0 53px;font-size:32px;text-align:center}@media (min-width:541px){.RelatedItem-preImageWrapper-2vw1j{-ms-flex:0 0 38px;flex:0 0 38px;font-size:26px;margin-right:16px}}.RelatedItem-fileIconDownload-3TXrI{color:#fff;height:14px;margin-right:8px;width:auto}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"relatedItem": "RelatedItem-relatedItem-2u-5G",
	"desktop": "RelatedItem-desktop-3xpnc",
	"mobile": "RelatedItem-mobile-27agG",
	"downloadButton": "RelatedItem-downloadButton-bfEOE",
	"upperWrapper": "RelatedItem-upperWrapper-152ps",
	"noBorder": "RelatedItem-noBorder-yXEw_",
	"title": "RelatedItem-title-3UN7R",
	"textWrapper": "RelatedItem-textWrapper-36bLp",
	"fileIcon": "RelatedItem-fileIcon-l3qo-",
	"preImageWrapper": "RelatedItem-preImageWrapper-2vw1j",
	"fileIconDownload": "RelatedItem-fileIconDownload-3TXrI"
};
module.exports = exports;
