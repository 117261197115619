// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ResponsiveImage-responsiveImage-3_mzB{display:block;height:auto;width:100%}.ResponsiveImage-hasError-3jUVF{height:0;visibility:hidden;width:0}.ResponsiveImage-responsiveProfileCardImage-gXpq0{display:block;height:66px;width:66px}.ResponsiveImage-svgContainer-3JvYF{overflow:hidden;position:relative;width:100%}.ResponsiveImage-svgContainer-3JvYF img{height:auto;left:50%;position:absolute;top:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);width:100%}", ""]);
// Exports
exports.locals = {
	"responsiveImage": "ResponsiveImage-responsiveImage-3_mzB",
	"hasError": "ResponsiveImage-hasError-3jUVF",
	"responsiveProfileCardImage": "ResponsiveImage-responsiveProfileCardImage-gXpq0",
	"svgContainer": "ResponsiveImage-svgContainer-3JvYF"
};
module.exports = exports;
