// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NewsletterItem-newsletterItem-2XP4g{border:1px solid #dee4ea;container-type:inline-size;margin-bottom:16px;padding:24px}.NewsletterItem-newsletterItem-2XP4g .NewsletterItem-subHeadline-4eCi_{color:#62717f}.NewsletterItem-headerWrapper-3Jpsz{display:-ms-flexbox;display:flex;margin-bottom:16px}@container (max-width: 500px){.NewsletterItem-headerWrapper-3Jpsz{-ms-flex-wrap:wrap;flex-wrap:wrap}}.NewsletterItem-logo-27HMK,.NewsletterItem-newsletterItem-2XP4g .NewsletterItem-subscribeButton-20h26{display:none}.NewsletterItem-subscribeButtonMobile-KETo8{margin:auto}@media (min-width:325px){.NewsletterItem-logo-27HMK{display:block;margin-right:16px;margin-top:2px}}@media (min-width:820px){.NewsletterItem-headline-1WIhf{margin-right:16px}.NewsletterItem-newsletterItem-2XP4g .NewsletterItem-subscribeButton-20h26{-ms-flex-item-align:center;align-self:center;display:-ms-flexbox;display:flex;height:0%;margin-left:auto;white-space:nowrap}.NewsletterItem-newsletterItem-2XP4g .NewsletterItem-subscribeButtonMobile-KETo8{display:none}.NewsletterItem-newsletterItem-2XP4g{padding-bottom:9px}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"newsletterItem": "NewsletterItem-newsletterItem-2XP4g",
	"subHeadline": "NewsletterItem-subHeadline-4eCi_",
	"headerWrapper": "NewsletterItem-headerWrapper-3Jpsz",
	"logo": "NewsletterItem-logo-27HMK",
	"subscribeButton": "NewsletterItem-subscribeButton-20h26",
	"subscribeButtonMobile": "NewsletterItem-subscribeButtonMobile-KETo8",
	"headline": "NewsletterItem-headline-1WIhf"
};
module.exports = exports;
