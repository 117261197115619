/* eslint-disable no-underscore-dangle */
import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import { selectors as locationSelectors } from 'ducks/location';
import _replace from 'lodash/fp/replace';
import pickFrom from 'utils/pickFrom';

export default createSelector(cookSelectors.getData, locationSelectors.getHostname, (data, hostname) => {
  const replaceHostname = _replace(hostname, '');

  if (data) {
    try {
      const focusAreaClustersObj =
        pickFrom(data, 'context.deskedContent.focusAreaClusters') ||
        pickFrom(data, 'focusAreas[0].homeSection.page.deskedContent.focusAreaClusters');
      const resolutionSectionId = data?.resolution?.section?.id;
      const focusAreaClusters = focusAreaClustersObj?.map(obj => {
        const value = pickFrom(obj, 'groupOptions[0].value');
        const focusAreasObj = pickFrom(obj, 'focusAreas');

        return {
          resolutionSectionId,
          focusAreaClusterTitle: value,
          focusAreas: focusAreasObj.map(area => {
            const id = pickFrom(area, 'content.id');
            const title = pickFrom(area, 'content.title');
            const href = pickFrom(area, 'content.href');
            const description = pickFrom(area, 'content.elements[0].fields[0].value');
            const tempAllIssues = pickFrom(area, 'content.homeSection.page.deskedContent.allIssues');
            const allIssues = [];
            if (tempAllIssues) {
              tempAllIssues.forEach(issue => {
                const issueContent = issue?.content;
                if (issueContent) {
                  allIssues.push({
                    ...issueContent,
                    href: replaceHostname(issueContent.href),
                  });
                }
              });
            }
            return {
              id,
              title,
              href: replaceHostname(href),
              description,
              allIssues,
            };
          }),
        };
      });
      return focusAreaClusters ? [...focusAreaClusters] : undefined;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }
  return undefined;
});
