import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faAt, faBuilding, faMap, faUsers, faHandshake } from '@fortawesome/pro-light-svg-icons';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from 'components/atoms/Typography';
import ContactLink from './components/ContactLink';
import s from './ContactItem.css';

const getIcon = (type = undefined) => {
  switch (type) {
    case 'Phone':
      return faMobile;
    case 'Email':
      return faAt;
    case 'MainOffice':
      return faBuilding;
    case 'RegionalOffice':
      return faMap;
    case 'ContactEmployee':
      return faUsers;
    case 'Membership':
      return faHandshake;
    case 'Press':
      return faMobile;
    default:
      return faMap;
  }
};

const ContactItem = ({ classes, data, ...other }) => {
  const classNames = classnames(s.contactItem, classes);

  const renderPre = () => (
    <div className={s.preImageWrapper}>
      <FontAwesomeIcon icon={getIcon(data.icon || data.type)} className={s.icon} />
    </div>
  );

  const renderText = variant => (
    <div className={s.textWrapper}>
      <Typography variant={variant} classes={s.title}>
        {data.title}
      </Typography>
      {data.optionals.subTitle && (
        <Typography variant="contentTypeDate" component="div">
          {data.optionals.subTitle}
        </Typography>
      )}
    </div>
  );

  return (
    <div className={classNames} {...other}>
      <div className={s.desktop}>
        {renderPre()}
        {renderText('h4')}
        <ContactLink type={data.type} to={data.link}>
          {data.linkText}
        </ContactLink>
      </div>
      <div className={s.mobile}>
        <div className={s.upperWrapper}>
          {renderPre()}
          {renderText('h5')}
        </div>
        <ContactLink type={data.type} to={data.link} mobile>
          {data.linkText}
        </ContactLink>
      </div>
    </div>
  );
};

export default withStyles(s)(ContactItem);
