import React, { useState } from 'react';
/* eslint-disable css-modules/no-undef-class */
import withStyles from 'isomorphic-style-loader/withStyles';
import ScrollTrigger from 'react-scroll-trigger';
import classnames from 'classnames';
import Author from 'components/molecules/Author';
import ShareBarContainer from 'containers/molecules/ShareBarContainer';
import RelatedContent from 'components/organisms/RelatedContent';
import Tag from 'components/atoms/Tag';
import ContactPersons from 'components/organisms/ContactPersons';
import VideoBlock from 'components/atoms/VideoBlock/VideoBlock';
import CenteredRow from 'components/templates/Grid/CenteredRow';
import pickFrom from 'utils/pickFrom';
import CenteredSecondaryRow from 'components/templates/Grid/CenteredSecondaryRow';
import renderStorylineElement from 'utils/renderStorylineElement';
// eslint-disable-next-line css-modules/no-unused-class
import boostrapGrid from 'style/bootstrap-grid.css';
import s from './InfoPage.css';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';

const InfoPage = ({ relatedItems, groupedStorylineTree, authors, metadata, contactPersons, tags }) => {
  if (!groupedStorylineTree) return undefined;
  const { headGroup, contentGroup } = groupedStorylineTree;
  const relatedVideo = relatedItems?.find(item => item.type === 'tv');
  const relItems = relatedItems?.filter(item => item.type !== 'tv');
  const [visible, setVisible] = useState(true);

  return (
    <>
      <OGShareTags metadata={metadata} />
      <div className={classnames(boostrapGrid.container)}>
        <div className={s.article_container}>
          <CenteredRow>
            <ScrollTrigger onEnter={() => setVisible(true)} onExit={() => setVisible(false)}>
              {headGroup && headGroup.map(renderStorylineElement)}
              {relatedVideo && (
                <VideoBlock
                  key={pickFrom(relatedVideo, 'content.fields.assetId')}
                  relatedVideo={{
                    streamingProvider: pickFrom(relatedVideo, 'content.fields.streamingProvider'),
                    caption: pickFrom(relatedVideo, 'content.fields.body'),
                    id: pickFrom(relatedVideo, 'content.fields.assetId'),
                  }}
                  stretched
                  inHeadGroup
                />
              )}
              <div className={classnames(visible === true ? s.fadeIn : s.fadeOut)}>
                <ShareBarContainer />
              </div>
              <div className={s.stickyContainer}>
                <ShareBarContainer hideMobile />
                {contentGroup && contentGroup.map(renderStorylineElement)}

                {tags?.map(tag => {
                  const href = `/${tag?.tagUri}`;
                  return (
                    <Tag key={href} href={href}>
                      {tag?.name}
                    </Tag>
                  );
                })}
              </div>
            </ScrollTrigger>
          </CenteredRow>
        </div>
      </div>
      <div className={s.secondaryWrapper}>
        <div className={classnames(boostrapGrid.container)}>
          <CenteredSecondaryRow>
            <Author authors={authors} />
            {relItems?.length > 0 && <RelatedContent items={relItems} />}
            {contactPersons?.length > 0 && <ContactPersons persons={contactPersons} />}
          </CenteredSecondaryRow>
        </div>
      </div>
    </>
  );
};

export default withStyles(boostrapGrid, s)(InfoPage);
