import { connect } from 'react-redux';
import TagPage from 'components/pages/TagPage';
import getGroupedStorylineTree from 'selectors/getGroupedStorylineTree';
import getHeroImage from 'selectors/getHeroImage';
import getInDepthItems from 'selectors/getInDepthItems';
import getUpcomingEvents from 'selectors/getUpcomingEvents';
import getContactPersons from 'selectors/getContactPersons';
import getSelectedContent from 'selectors/getSelectedContent';
import getLatestArticles from 'selectors/getLatestArticles';
import getTagHeadline from 'selectors/getTagHeadline';
import getMetdata from '../../selectors/metadata/getMetdata';
import getLatestWebinars from '../../selectors/getLatestWebinars';

const mapStateToProps = state => {
  const selectedContent = getSelectedContent(state);
  return {
    headline: getTagHeadline(state),
    upcomingEvents: getUpcomingEvents(state),
    groupedStorylineTree: getGroupedStorylineTree(state),
    inDepthItems: getInDepthItems(state),
    contactPersons: getContactPersons(state),
    latestWebinars: getLatestWebinars(state),
    firstSelectedContent: selectedContent && selectedContent.length > 0 && selectedContent[0],
    restSelectedContent: selectedContent && selectedContent.length > 0 && selectedContent.slice(1),
    latestArticles: getLatestArticles(state),
    heroImage: getHeroImage(state),
    metadata: getMetdata(state),
  };
};

const mapDispatchToProps = undefined;

const TagPageContainer = connect(mapStateToProps, mapDispatchToProps)(TagPage);

export default TagPageContainer;
