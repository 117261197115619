import { createSelector } from 'reselect';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';
import reducer from 'utils/reduceTeaserOptions';
import _sortBy from 'lodash/sortBy';
import clientifyUrl from '../utils/clientifyUrl';

const validateDate = date => date !== '' && date !== undefined;

export default createSelector(cookSelectors.getData, data => {
  try {
    const selectedEvents = data?.context?.deskedContent?.selectedEvents;
    const selectedEventsSubitems = selectedEvents.map(event => {
      const teaserOptions = reducer(event.teaserOptions);
      return {
        ...event.content,
        title: teaserOptions.useSummaryFields ? teaserOptions.title : pickFrom(event, 'content.title'),
      };
    });
    const sortedEvents = _sortBy(selectedEventsSubitems, event => event?.fields?.startDate);
    const validEvents = sortedEvents?.filter(
      event => validateDate(event.fields?.startDate) && validateDate(event.fields?.endDate)
    );
    const validUpcomingEvents = validEvents?.filter(event => new Date(event.fields?.endDate) > new Date());
    return validUpcomingEvents.map(event => ({
      ...event,
      href: clientifyUrl(event?.href),
    }));
  } catch (error) {
    console.error(error);
    return undefined;
  }
});
