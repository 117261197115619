import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import BlogPostListItem from '../BlogPostListItem/BlogPostListItem';
import s from './BlogPostList.css';

const BlogPostList = ({ blogPosts, showDividers }) => {
  return blogPosts ? (
    <ul className={s.blogPostList}>
      {blogPosts.map(blogPost => (
        <BlogPostListItem key={blogPost.id} blogPost={blogPost} showDividers={showDividers} />
      ))}
    </ul>
  ) : null;
};

export default withStyles(s)(BlogPostList);
