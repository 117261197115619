import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import bootstrapGrid from 'style/bootstrap-grid.css';
import { useSelector } from 'react-redux';
import BootstrapWrapper from '../BootstrapWrapper/BootstrapWrapper';
import HeroHeader from '../HeroHeader/HeroHeader';
import s from './BlogPage.css';
import getBlogPage from '../../../../selectors/getBlogPage';
import BlogPostList from '../BlogPostList/BlogPostList';
import SubscribeToBlog from '../SubscribeToBlog/SubscribeToBlog';
import PodBanner from '../PodBanner/PodBanner';
import WritersList from '../WritersList/WritersList';
import ButtonSecondaryGhost from '../../../atoms/buttons/ButtonSecondaryGhost';
import Link from '../../../../containers/atoms/LinkContainer';
import { getMetadata } from '../../FlexibleSection/duck/selectors';
import OGShareTags from '../../../molecules/OGShareTags/OGShareTags';

const BlogPage = () => {
  const {
    heroImage,
    latestBlogPosts,
    blogName,
    blogUniqueName,
    blogPageHeader,
    blogDescription,
    blogApsisMailingListId,
    blogMailjetMailingListId,
    blogMailjetAccount,
    writers,
  } = useSelector(getBlogPage);
  const viewAllPostsUrl = `/blogg/${blogUniqueName}/sokresultat/`;
  const metadata = useSelector(getMetadata);

  return (
    <>
      <OGShareTags metadata={metadata} />
      <div className={s.blogPage}>
        <HeroHeader dimmed fullWidthBg bgImage={heroImage}>
          <div className={s.headerInfo}>
            <span className={s.blogTitle}>BLOGG</span>
            <h1>{blogPageHeader.heading}</h1>
            <p>{blogPageHeader.description}</p>
          </div>
        </HeroHeader>
        <div className={classnames(s.content, bootstrapGrid.container)}>
          <div className={bootstrapGrid.row}>
            <BootstrapWrapper>
              <BlogPostList blogPosts={latestBlogPosts?.slice(0, 6)} />
              <ButtonSecondaryGhost className={s.viewAllPosts} component={Link} to={viewAllPostsUrl}>
                Visa alla inlägg
              </ButtonSecondaryGhost>
            </BootstrapWrapper>
          </div>
        </div>
        <SubscribeToBlog
          blogUniqueName={blogUniqueName}
          name={blogName}
          description={blogDescription}
          apsisMailingListId={blogApsisMailingListId}
          mailjetMailingListId={blogMailjetMailingListId}
          mailjetAccount={blogMailjetAccount}
        />
        {blogUniqueName === 'fokus-pa-skatterna' && <PodBanner />}
        {writers && <WritersList blogUniqueName={blogUniqueName} writers={writers} />}
      </div>
    </>
  );
};

export default withStyles(s)(BlogPage);
