// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChangeReviewer-container-1wjGP{margin:auto;max-width:1256px}.ChangeReviewer-container-1wjGP form{margin-bottom:10px}.ChangeReviewer-container-1wjGP .ChangeReviewer-comboBox-3X9q8{max-width:500px}.ChangeReviewer-container-1wjGP .ChangeReviewer-btnContainer-2FfK-{display:-ms-flexbox;display:flex}.ChangeReviewer-container-1wjGP .ChangeReviewer-backlink-1Mgso{text-decoration:underline}.ChangeReviewer-container-1wjGP .ChangeReviewer-backlink-1Mgso:hover{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"container": "ChangeReviewer-container-1wjGP",
	"comboBox": "ChangeReviewer-comboBox-3X9q8",
	"btnContainer": "ChangeReviewer-btnContainer-2FfK-",
	"backlink": "ChangeReviewer-backlink-1Mgso"
};
module.exports = exports;
