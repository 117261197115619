const getCorrespondingSection = (sections, coworker) => {
  return sections.find(section => section.id === coworker.surName.charAt(0).toUpperCase());
};

const getSortedCoworkers = coworkers => {
  if (coworkers) {
    try {
      const sections = [];
      coworkers.map(coworker => {
        if (sections.length <= 0) {
          sections.push({
            id: coworker?.surName?.charAt(0).toUpperCase(),
            coworkers: [coworker],
          });
        } else {
          const correspondingSection = getCorrespondingSection(sections, coworker);
          if (correspondingSection) {
            correspondingSection.coworkers.push(coworker);
            correspondingSection.coworkers.sort((a, b) =>
              a.surName.toUpperCase() > b.surName.toUpperCase() ? 1 : -1
            );
          } else {
            sections.push({
              id: coworker?.surName?.charAt(0).toUpperCase(),
              coworkers: [coworker],
            });
          }
        }
        return undefined;
      });

      return sections.sort((a, b) => (a.id > b.id ? 1 : -1));
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
};

export default getSortedCoworkers;
