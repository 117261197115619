import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { selectors as locationSelectors } from 'ducks/location';
import Typography from 'components/atoms/Typography';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import useBreakpoint from '../../../utils/hooks/useBreakpoint';
import { getFrontEndOrigin } from '../../../utils/apiUtil';
import ScrollToShowMore from '../../atoms/ScrollToShowMore/ScrollToShowMore';
import ColumnFaktagranskning from './components/ColumnFaktagranskning/ColumnFaktagranskning';
import Header from './components/Header/Header';
import ColumnArkivering from './components/ColumnArkivering/ColumnArkivering';
import ColumnGenerellt from './components/ColumnGenerellt/ColumnGenerellt';
import ColumnStatistik from './components/ColumnStatistik/ColumnStatistik';
import ReviewForm from './components/ReviewForm/ReviewForm';
import ChangeReviewer from './components/ChangeReviewer/ChangeReviewer';
import ReviewConfirmation from './components/ReviewConfirmation/ReviewConfirmation';
import s from './GOAContainer.css';

const GOA_VIEWS = {
  MAIN: 'main',
  REVIEW_FORM: 'reviewForm',
  CHANGE_REVIEWER: 'changeReviewer',
  REVIEW_CONFIRMATION: 'reviewConfirmation',
};

const GOAContainer = () => {
  const [goaView, setGoaView] = useState(GOA_VIEWS.MAIN);
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reviewing, setReviewing] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [shouldShowGOA, setShouldShowGOA] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const [reviewedState, setReviewedState] = useState('notReviewed');
  const [pendingReviewDate, setPendingReviewDate] = useState('');
  const [amITheLastReviewer, setAmITheLastReviewer] = useState(false);
  const [amITheCurrentReviewer, setAmITheCurrentReviewer] = useState(false);

  const fullPath = useSelector(locationSelectors.getFullPath);
  const breakPoint = useBreakpoint();
  const isMediumOrLG = ['md', 'lg'].includes(breakPoint);
  const isSmallScreen = ['xs', 'sm', 'md'].includes(breakPoint);

  const handleReviewCouldNotBeLoaded = msg => {
    setReviewData(null);
    setShouldShowGOA(false);
    toast.error(msg);
  };

  const getReviewData = async (articleId, articlePath) => {
    const endpointUrl = `${getFrontEndOrigin()}/backoffice/api/goa/review/${articleId}?failIfNotLoggedIn=true${
      articlePath ? `&articlePath=${articlePath}` : ''
    }`;
    let errorMessage = 'Faktagranskningen kunde inte laddas';
    try {
      const results = await axios.get(endpointUrl, { withCredentials: true });

      if (results?.data?.reviewData && results?.data?.apiRequest?.loggedIn) {
        setAmITheLastReviewer(
          results?.data?.reviewData?.reviews[0]?.reviewer.id ===
            results?.data?.apiRequest?.authenticatedUser?.id
        );
        setAmITheCurrentReviewer(
          results?.data?.reviewData?.currentReviewer.id === results?.data?.apiRequest?.authenticatedUser?.id
        );
        setReviewData(results?.data);
        setPendingReviewDate(results?.data?.reviewData?.article?.pendingReviewDate);
      } else {
        handleReviewCouldNotBeLoaded(errorMessage);
      }
    } catch (e) {
      console.error(e);
      if (e?.response?.status === 404) {
        return;
      }
      if (e?.response?.status === 401) {
        errorMessage = 'Du har inte access till denna sidan.';
      }
      handleReviewCouldNotBeLoaded(errorMessage);
    }
  };

  const refreshReviewData = async () => {
    await getReviewData(reviewData?.reviewData?.article?.id);
  };

  const markArticleAsReviewed = async () => {
    const articleId = reviewData?.reviewData?.article?.id;

    if (articleId) {
      setReviewing(true);

      try {
        const endpointUrl = `${getFrontEndOrigin()}/backoffice/api/goa/review/${articleId}`;
        const results = await axios.post(endpointUrl, { reviewed: true }, { withCredentials: true });
        await getReviewData(articleId);
        setPendingReviewDate(results?.data?.pendingReviewDate);
        toast.success('Faktagranskningen lyckades!');
        setReviewedState('reviewed');
      } catch (e) {
        toast.error('Misslyckades att spara granskningen');
      }
      setReviewing(false);
    }
  };

  const markArticleAsArchived = async () => {
    const articleId = reviewData?.reviewData?.article?.id;
    const title = reviewData?.reviewData?.article?.title;

    const msg = `Är du säker på att du vill arkivera "${title}"? \nDen försvinner då från sajten, men går fortfarande att komma åt genom att kontakta Redaktionen.`;

    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(msg);
    if (isConfirmed) {
      if (articleId) {
        setArchiving(true);

        try {
          const endpointUrl = `${getFrontEndOrigin()}/backoffice/api/goa/archiveArticles/?forceForegroundExecution=true`;
          const results = await axios.post(
            endpointUrl,
            { articleIds: [articleId] },
            { withCredentials: true }
          );
          await getReviewData(articleId);
          setPendingReviewDate(results?.data?.pendingReviewDate);
          toast.success('Artikel arkiverad!');
          setReviewedState('archived');
        } catch (e) {
          toast.error('Misslyckades att arkivera artikeln');
        }
        setArchiving(false);
      }
    }
  };

  useEffect(() => {
    return window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowScrollTopBtn(true);
      } else {
        setShowScrollTopBtn(false);
      }
    });
  }, []);

  const closeGOA = () => {
    window.history.pushState(null, '', window.location.href.split('?')[0]);
    setShouldShowGOA(false);
  };

  const initGOA = () => {
    const queryString = window.location.search;
    const { pathname: articlePath } = window.location;
    const urlParams = new URLSearchParams(queryString);
    const currentGoaView = urlParams.get('goaView');
    const hasArticleId = urlParams.get('articleId')?.length > 0;

    if (hasArticleId && currentGoaView) {
      const goaViews = Object.keys(GOA_VIEWS).map(view => GOA_VIEWS[view]);
      if (goaViews.includes(currentGoaView)) {
        setShouldShowGOA(true);
        getReviewData(urlParams.get('articleId'), articlePath).then(() => {
          setGoaView(currentGoaView);
          setLoading(false);
        });
      } else {
        toast.error('Felaktigt värde på parametern goaView');
        setShouldShowGOA(false);
      }
    } else {
      setShouldShowGOA(false);
    }
  };

  useEffect(() => {
    setShouldShowGOA(false);
    initGOA();
  }, [fullPath]);

  if (!shouldShowGOA) {
    return null;
  }

  return (
    <>
      <div className={s.container}>
        <Header
          user={reviewData?.apiRequest?.authenticatedUser}
          isSmallScreen={isSmallScreen}
          handleCloseClicked={() => closeGOA()}
        />
        {goaView === GOA_VIEWS.MAIN && (
          <main className={s.plate}>
            {loading && (
              <div className={s.spinner}>
                <FontAwesomeIcon style={{ width: 50, height: 50 }} icon={faLoader} color="black" spin />
              </div>
            )}
            {!loading && !reviewData && (
              <Typography variant="paragraph">Kunde inte ladda granskningsdata.</Typography>
            )}
            {!loading && reviewData && (
              <>
                <section className={s.reviewColumn}>
                  <ColumnFaktagranskning
                    reviewData={reviewData}
                    reviewedState={reviewedState}
                    pendingReviewDate={pendingReviewDate}
                    reviewing={reviewing}
                    amITheLastReviewer={amITheLastReviewer}
                    amITheCurrentReviewer={amITheCurrentReviewer}
                    handleYesClicked={() => markArticleAsReviewed()}
                    handleNoClicked={() => setGoaView(GOA_VIEWS.REVIEW_FORM)}
                    handleChangeReviewerClicked={() => setGoaView(GOA_VIEWS.CHANGE_REVIEWER)}
                  />
                </section>
                <section className={s.archiveColumn}>
                  <ColumnArkivering
                    reviewData={reviewData}
                    reviewedState={reviewedState}
                    archiving={archiving}
                    handleArchiveClicked={() => markArticleAsArchived()}
                  />
                </section>
                <section className={s.generalColumn}>
                  <ColumnGenerellt isMediumOrLG={isMediumOrLG} article={reviewData?.reviewData?.article} />
                </section>
                <section className={s.statisticsColumn}>
                  <ColumnStatistik reviewData={reviewData} isMediumOrLG={isMediumOrLG} />
                </section>
                {isSmallScreen && goaView === GOA_VIEWS.MAIN && (
                  <ScrollToShowMore
                    style={{
                      position: 'sticky',
                      bottom: '15px',
                      right: '10px',
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  />
                )}
              </>
            )}
          </main>
        )}
        {goaView === GOA_VIEWS.REVIEW_FORM && (
          <section className={s.plate}>
            <ReviewForm
              articleId={reviewData?.reviewData?.article?.id}
              handleCancelClick={() => setGoaView(GOA_VIEWS.MAIN)}
            />
          </section>
        )}
        {goaView === GOA_VIEWS.CHANGE_REVIEWER && (
          <section className={s.plate}>
            <ChangeReviewer
              reviewData={reviewData}
              handleCancelClicked={() => setGoaView(GOA_VIEWS.MAIN)}
              refreshReviewData={refreshReviewData}
              amITheCurrentReviewer={amITheCurrentReviewer}
            />
          </section>
        )}
        {goaView === GOA_VIEWS.REVIEW_CONFIRMATION && (
          <section className={s.plate}>
            <ReviewConfirmation reviewData={reviewData} />
          </section>
        )}
      </div>
      {showScrollTopBtn && (
        <div onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className={s.scrollUpBtn}>
          <FontAwesomeIcon style={{ width: 20 }} icon={faChevronUp} color="white" />
        </div>
      )}
    </>
  );
};

export default withStyles(s)(GOAContainer);
