// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BlogPage-blogPage-1PLiR .BlogPage-headerInfo-3bn_p{color:#fff;margin:0 4px}@media (min-width:541px){.BlogPage-blogPage-1PLiR .BlogPage-headerInfo-3bn_p{margin:0 44px}}@media (min-width:1169px){.BlogPage-blogPage-1PLiR .BlogPage-headerInfo-3bn_p{margin:0 56px}}.BlogPage-blogPage-1PLiR .BlogPage-headerInfo-3bn_p h1{font-family:LabGrotesque-Bold,sans-serif;font-size:1.75rem;margin:0 0 8px}.BlogPage-blogPage-1PLiR .BlogPage-headerInfo-3bn_p p{font-size:1.25rem;margin:0}.BlogPage-blogPage-1PLiR .BlogPage-content-3Ekgt{margin-top:430px}@media (min-width:541px){.BlogPage-blogPage-1PLiR .BlogPage-content-3Ekgt{margin-top:380px}}.BlogPage-blogPage-1PLiR .BlogPage-content-3Ekgt .BlogPage-viewAllPosts-36Jfj{margin:32px auto;width:-webkit-max-content;width:-moz-max-content;width:max-content}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"blogPage": "BlogPage-blogPage-1PLiR",
	"headerInfo": "BlogPage-headerInfo-3bn_p",
	"content": "BlogPage-content-3Ekgt",
	"viewAllPosts": "BlogPage-viewAllPosts-36Jfj"
};
module.exports = exports;
