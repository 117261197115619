import { useState, useEffect } from 'react';
import { formatDateWithYearAndTime } from '../dateUtil';
import useTranslate from './useTranslate';

const getDistanceToNow = countdownDate => countdownDate - new Date().getTime();

const formatDistance = (date, t, showShortSuffix) => {
  const days = Math.max(Math.floor(date / (1000 * 60 * 60 * 24)), 0);
  const hours = Math.max(Math.floor((date % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)), 0);
  const minutes = Math.max(Math.floor((date % (1000 * 60 * 60)) / (1000 * 60)), 0);
  const seconds = Math.max(Math.floor((date % (1000 * 60)) / 1000), 0);

  return {
    days: {
      value: days,
      suffix: showShortSuffix
        ? t('TIME_UNITS.DAY_SHORT')
        : days === 1
        ? t('TIME_UNITS.DAY')
        : t('TIME_UNITS.DAYS'),
    },
    hours: {
      value: hours,
      suffix: showShortSuffix
        ? t('TIME_UNITS.HOUR_SHORT')
        : hours === 1
        ? t('TIME_UNITS.HOUR')
        : t('TIME_UNITS.HOURS'),
    },
    minutes: {
      value: minutes,
      suffix: showShortSuffix
        ? t('TIME_UNITS.MINUTE_SHORT')
        : minutes === 1
        ? t('TIME_UNITS.MINUTE')
        : t('TIME_UNITS.MINUTES'),
    },
    seconds: {
      value: seconds,
      suffix: showShortSuffix
        ? t('TIME_UNITS.SECOND_SHORT')
        : seconds === 1
        ? t('TIME_UNITS.SECOND')
        : t('TIME_UNITS.SECONDS'),
    },
  };
};

/*
* interval:
  the interval the state will be updated and therefore will trigger a re-render

* showDateThreshold:
  the date threshold (in milliseconds) after which the date will be shown instead of the countdown
  set this to `false` to always show the countdown

* showShortSuffix:
  use y, m, d, h, m and s instead of year(s), month(s), day(s), hour(s), minute(s) and second(s)
*/
const defaultOptions = {
  interval: 1000,
  showDateThreshold: 1000 * 60 * 60 * 24, // 24 hours
  showShortSuffix: false,
};

const useCountDown = (countdownDate, inputOptions = {}) => {
  const options = {
    ...defaultOptions,
    ...inputOptions,
  };

  if (!countdownDate) {
    throw new Error('useCountDown: `countdownDate` is required');
  }

  const initialDistance = getDistanceToNow(countdownDate);
  const t = useTranslate();

  const humanReadableDate = formatDateWithYearAndTime(countdownDate);

  const [countdown, setCountdown] = useState(formatDistance(initialDistance, t, options.showShortSuffix));
  const [isDone, setIsDone] = useState(initialDistance <= 0);
  const [showDate, setShowDate] = useState(
    !!options.showDateThreshold && initialDistance >= options.showDateThreshold
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const distance = getDistanceToNow(countdownDate);

      if (distance <= options.interval) {
        clearInterval(intervalId);
        setIsDone(true);
      }
      setCountdown(formatDistance(distance, t, options.showShortSuffix));
      setShowDate(!!options.showDateThreshold && distance >= options.showDateThreshold);
    }, options.interval);

    return () => clearInterval(intervalId);
  }, [countdownDate]);

  return {
    isDone,
    countdown,
    showDate,
    humanReadableDate,
  };
};

export default useCountDown;
