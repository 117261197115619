import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faXTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Typography from 'components/atoms/Typography';
import FacebookButton from './components/FacebookButton';
import s from './FacebookNumbers.css';

const numberWithSpaces = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

const FacebookNumbers = ({ classes, count, ...other }) => {
  const classNames = classnames(s.facebookNumbers, classes);
  return (
    <div className={classNames} {...other}>
      <div className={s.count}>{numberWithSpaces(count)}</div>
      <Typography variant="lead" component="div" classes={s.areYouOneDesktop} gutterBottom darkMode>
        personer följer oss på Facebook - är du en av dem?
      </Typography>
      <Typography variant="lead" component="div" classes={s.areYouOneMobile} darkMode>
        personer följer oss på Facebook
      </Typography>
      <Typography variant="lead" component="div" classes={s.areYouOneMobile} gutterBottom darkMode>
        - är du en av dem?
      </Typography>
      <FacebookButton
        component="a"
        classes={s.followButton}
        href="https://www.facebook.com/svensktnaringsliv/"
        target="_blank"
      >
        <FontAwesomeIcon icon={faFacebookF} className={s.facebook} style={{ width: 10, height: 16 }} />
        Följ oss
      </FacebookButton>
      <div>
        <Typography darkMode component="span" classes={s.socialItem}>
          Vi finns även på
        </Typography>
        <span className={s.socialItem}>
          <Typography
            variant="link"
            component="a"
            href="https://twitter.com/svenaringsliv"
            darkMode
            target="_blank"
          >
            <FontAwesomeIcon icon={faXTwitter} className={s.twitter} style={{ width: 16, height: 16 }} />X
          </Typography>
        </span>
        <span className={s.socialItem}>
          <Typography
            variant="link"
            component="a"
            href="https://www.instagram.com/svensktnaringsliv"
            darkMode
            target="_blank"
          >
            <FontAwesomeIcon icon={faInstagram} className={s.instagram} style={{ width: 16, height: 16 }} />
            Instagram
          </Typography>
        </span>
        <span className={s.socialItem}>
          <Typography
            variant="link"
            component="a"
            href="https://www.linkedin.com/company/svenskt-n-ringsliv"
            darkMode
            target="_blank"
          >
            <FontAwesomeIcon icon={faLinkedin} className={s.linkedin} style={{ width: 16, height: 16 }} />
            LinkedIn
          </Typography>
        </span>
      </div>
    </div>
  );
};

export default withStyles(s)(FacebookNumbers);
