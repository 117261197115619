import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import s from './TextArea.css';

const TextArea = ({ classes, rows = 4, gutterBottom, round, errorMessage, register, ...other }) => {
  const classNames = classnames(s.textArea, gutterBottom && s.gutterBottom, round && s.round, classes);
  return (
    <div className={classnames(s.area, errorMessage && s.error)}>
      <textarea className={classNames} rows={rows} ref={register} {...other} />
      {errorMessage && (
        <div className={s.errorMessage}>
          <FontAwesomeIcon icon={faInfoCircle} className={s.infoCircle} />
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default withStyles(s)(TextArea);
