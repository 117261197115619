import parseBreakpoints from './parseBreakpoints';

const getKeyValue = (key, value) => {
  switch (key) {
    case 'gridTemplateColumns':
    case 'gridTemplateRows':
    case 'gridRowGap':
    case 'gridColumnGap':
      return [key, parseBreakpoints(value)];

    default:
      return [key, value];
  }
};

const getGridParentFields = fields => {
  return Object.entries(fields).reduce((acc, [currentKey, currentValue]) => {
    const [key, value] = getKeyValue(currentKey, currentValue);
    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

export default getGridParentFields;
