// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RelatedContent-relatedContent-2XMyu{border-top:1px solid #dee4ea;margin-bottom:56px;padding-top:24px}.RelatedContent-relatedContent-2XMyu .RelatedContent-itemsWrapper-1PHJm{border:1px solid #dee4ea;border-radius:2px;margin-top:16px}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"relatedContent": "RelatedContent-relatedContent-2XMyu",
	"itemsWrapper": "RelatedContent-itemsWrapper-1PHJm"
};
module.exports = exports;
